import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import TextField from '@material-ui/core/TextField';
import { reduxForm } from 'redux-form';
import { UserProps } from '../../../../shared/prop-types/ReducerProps';
import Alert from '../../../../shared/components/alerts/Alert';

class ModalComponent extends PureComponent {
  static propTypes = {
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    displayed: PropTypes.bool,
    check: PropTypes.func,
    user: UserProps.isRequired,
    toggleModal: PropTypes.func.isRequired,
    successCallback: PropTypes.func,
    pings: PropTypes.number,
  };

  static defaultProps = {
    colored: false,
    displayed: false,
    check: null,
    pings: 0,
    successCallback: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      invalid: false,
      pings: props.pings,
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { modal } = this.state;
    if (modal === false && newProps.displayed === true) {
      const { check } = this.props;
      if (check !== null) {
        check().then((data) => {
          this.setState({ errorMessage: data });
        });
      }
    }
    this.setState({
      modal: newProps.displayed,
      title: `Transfer pings to ${newProps.user.startup.name} ( ${newProps.user.firstName} ${newProps.user.lastName})`,
    });
  }

  transfer = () => {
    const { invalid, pings } = this.state;
    const { user, toggleModal, successCallback } = this.props;
    if (!invalid) {
      axios.post('broker/transfer_pings', {
        id: user.id,
        pings,
      })
        .then(() => {
          this.setState({ pings: 0 });
          toggleModal();
          if (typeof successCallback === 'function') {
            successCallback();
          }
        }).catch((error) => {
          if (error && error.response) {
            this.setState({ errorMessage: error.response.data.message });
          } else {
            this.setState({ errorMessage: 'Sorry, an error occurred, please try again.' });
          }
        });
    }
  }

  handleChange(event) {
    this.setState({ pings: event.target.value });
  }

  toggle(e) {
    e.preventDefault();

    this.setState({ pings: 0 });

    const { toggleModal } = this.props;
    toggleModal();
  }

  render() {
    const {
      color, colored, user,
    } = this.props;
    const {
      modal, invalid, errorMessage, title, pings,
    } = this.state;

    let notification = null;

    if (errorMessage !== '' && typeof errorMessage !== typeof undefined) {
      notification = (
        <Alert color="warning mt-3" className="alert--colored">
          <span className="bold-text">Warning! </span>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className="modal-dialog--primary modal-templates x-large"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            <h4>{title}</h4>
            <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
          </div>
          <div className="modal__body pt-5">
            <form className="material-form">
              <h4>Transfer your pings to this account in order to let this startup contact investors</h4>
              <h6 className="text-muted mt-3 mb-2">Available Pings to transfer: {user.pings}</h6>
              {notification}
              <div className="form__form-group mt-5">
                <span className="material-form__label">Pings to transfer</span>
                <TextField
                  className="material-form__field ml-5 mw-150"
                  name="pings"
                  value={pings}
                  type="number"
                  onChange={this.handleChange}
                />
              </div>
              <ButtonToolbar className="modal__footer float-right">
                <Button className="modal_cancel rounded" onClick={this.toggle}>Cancel</Button>{' '}
                {!invalid ? (
                  <Button
                    className="modal_ok rounded icon icon--right icon--blue"
                    type="button"
                    outline={colored}
                    color={color}
                    onClick={this.transfer}
                  >
                    Transfer <SendIcon />
                  </Button>
                ) : ''}
              </ButtonToolbar>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default reduxForm({
  form: 'transfer_pings_form', // a unique identifier for this form
})(ModalComponent);
