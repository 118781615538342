import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TopbarSidebarButton extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div>
        <button className="topbar__button topbar__button--desktop" type="button" onClick={changeSidebarVisibility}>
          <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="31" height="2" fill="#243143" />
            <rect y="7" width="31" height="2" fill="#243143" />
            <rect y="14" width="31" height="2" fill="#243143" />
          </svg>
        </button>
        <button className="topbar__button topbar__button--mobile" type="button" onClick={changeMobileSidebarVisibility}>
          <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="31" height="2" fill="#243143" />
            <rect y="7" width="31" height="2" fill="#243143" />
            <rect y="14" width="31" height="2" fill="#243143" />
          </svg>
        </button>
      </div>
    );
  }
}

export default TopbarSidebarButton;
