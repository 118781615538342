import React from 'react';
import {
  Badge, Card, CardBody,
} from 'reactstrap';

const PanelLocationCity = () => (
  <Card className="panel panel--divider card shadow mb-3">
    <CardBody className="panel__body">
      <div className="panel__title no-padding">
        <h5 className="bold-text">
          <Badge className="panel__label">Improvement</Badge>
          &nbsp;&nbsp;&nbsp;
          Mention where this investor is located
        </h5>
      </div>
      <div className="panel__content">
        <p className="typography-message">
          You should add the variable <strong>&#123;&#123;investorCity&#125;&#125;</strong> to your templates in
          order to mention where this investor is located.
        </p>
        <p>
          <strong>For example:</strong> I see that you actively invest in &#123;&#123;investorVertical&#125;&#125;
          &#123;% if investorCity %&#125; in &#123;&#123;investorCity&#125;&#125; &#123;% endif %&#125; and I would
          like to get in touch with you...
        </p>
        <p className="typography-message">
          We recommend using as much personalisation as possible to increase the likelihood of receiving a response
          back. Having analysed thousands of campaigns, our research demonstrated that templates using all 4 of our
          variables <strong><u>perform 75% better</u></strong> than campaigns that do not incorporate them.
        </p>
      </div>
    </CardBody>
  </Card>
);

export default PanelLocationCity;
