import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from 'reactstrap';
import axios from 'axios';
import queryString from 'query-string';
import Alert from '../../../../shared/components/alerts/Alert';
import LinkForm from './LinkForm';

class LinkModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      updated: false,
      saving: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  submitForm = (values) => {
    const { initialValues } = this.props;
    this.setState({ saving: true });
    if (initialValues.id !== null) {
      this.updateLink(initialValues.id, values);
    } else {
      this.createLink(values);
    }
  };

  updateLink = (id, values) => {
    const { reload } = this.props;
    axios.post(`/links/update/${id}`, queryString.stringify({
      name: values.name,
      email: values.email,
      protected: values.protected,
      password: values.password,
      deck: values.deck,
      materials: values.materials,
      financials: values.financials,
      active: values.active,
    })).then((response) => {
      if (response.data && response.data.success) {
        this.setState({ saving: false, updated: true }, () => this.hideAlert(1500, true));
        reload();
      }
    }).catch((error) => {
      if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
        // Do something error.response.data.message ?
        this.setState({
          saving: false, error: true, errorMessage: error.response.data.message,
        }, () => this.hideAlert(3500, false));
      } else {
        this.setState({
          saving: false, error: true, errorMessage: 'Sorry, an error occurred, please try again.',
        }, () => this.hideAlert(3500, false));
      }
    });
  };

  createLink = (values) => {
    const { reload, toggleModalUpgrade } = this.props;
    axios.post('/links/add', queryString.stringify({
      name: values.name,
      email: values.email,
      protected: values.protected,
      password: values.password,
      deck: values.deck,
      materials: values.materials,
      financials: values.financials,
      active: values.active,
    })).then((response) => {
      if (response.data && response.data.success) {
        this.setState({ saving: false, updated: true }, () => this.hideAlert(1500, true));
        reload();
      }
    }).catch((error) => {
      if (error && typeof error.response === 'object' && error.response.status === 402) {
        this.setState({ saving: false });
        this.toggle();
        toggleModalUpgrade();
      } else if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
        this.setState({
          saving: false, error: true, errorMessage: error.response.data.message,
        }, () => this.hideAlert(3500, false));
      } else {
        this.setState({
          saving: false, error: true, errorMessage: 'Sorry an error occurred. Please try again.',
        }, () => this.hideAlert(3500, false));
      }
    });
  };

  hideAlert = (delay, toggle) => {
    setTimeout(
      () => {
        this.setState({ updated: false, error: false });
        if (toggle) {
          this.toggle();
        }
      },
      delay,
    );
  };

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    toggleModal();
  }

  render() {
    const {
      modal, updated, error, errorMessage, saving,
    } = this.state;

    const {
      initialValues,
    } = this.props;

    let notification = null;
    const update = !!(initialValues && initialValues.id);

    if (updated) {
      notification = (
        <Alert color="info" className="alert--colored alert-modal" icon>
          <p><span className="bold-text">Success! </span></p>
        </Alert>
      );
    }
    if (error) {
      notification = (
        <Alert color="danger" className="alert--colored alert-modal" icon>
          <p><span className="bold-text">Warning! </span>
            {errorMessage}
          </p>
        </Alert>
      );
    }

    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary large"
      >
        <LinkForm
          onSubmit={this.submitForm}
          initialValues={initialValues}
          hasPasscode={false}
          active={false}
          toggle={this.toggle}
          saving={saving}
          update={update}
          enableReinitialize
        />
        {notification}
      </Modal>
    );
  }
}

LinkModal.propTypes = {
  reload: PropTypes.func.isRequired,
  displayed: PropTypes.bool.isRequired,
  toggleModalUpgrade: PropTypes.func,
  toggleModal: PropTypes.func,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.bool,
    protected: PropTypes.bool,
    password: PropTypes.string,
    deck: PropTypes.bool,
    financials: PropTypes.bool,
    materials: PropTypes.bool,
  }).isRequired,
};

LinkModal.defaultProps = {
  toggleModalUpgrade: null,
  toggleModal: null,
};

export default LinkModal;
