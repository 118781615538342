/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (!values.view) {
    errors.view = 'This field cannot be left empty';
  }
  if (!values.deck) {
    errors.deck = 'This field cannot be left empty';
  }
  if (!values.message) {
    errors.message = 'This field cannot be left empty';
  }
  if (!values.declined) {
    errors.declined = 'This field cannot be left empty';
  }
  if (!values.newsletter) {
    errors.newsletter = 'This field cannot be left empty';
  }
  if (!values.blog) {
    errors.blog = 'This field cannot be left empty';
  }

  return errors;
};

export default validate;
