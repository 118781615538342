import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarProfile from './TopbarProfile';
import TopbarMail from './TopbarMail';
import TopbarNotification from './TopbarNotification';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarNav from './tobar_nav/TopbarNav';

export default class TopbarWithNavigation extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    logo: PropTypes.string,
    type: PropTypes.string,
    impersonator: PropTypes.bool,
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      // eslint-disable-next-line react/no-unused-state
      iteration: 0,
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = () => {
    const { iteration } = this.state;
    if (JSON.parse(localStorage.getItem('user'))) {
      this.setState({ user: JSON.parse(localStorage.getItem('user')), iteration: 0 });
    } else {
      setTimeout(this.getUserData, 3000);
      this.setState({ iteration: iteration + 1 });
      if (iteration === 10) {
        // eslint-disable-next-line no-alert
        alert('Your session expired. Please log in again'
          + ' If you are unable to log in, please contact support: contact@angelspartners.com');
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/logout`;
      }
    }
  }

  render() {
    const {
      name, logo, type, changeMobileSidebarVisibility, changeSidebarVisibility, impersonator,
    } = this.props;
    const { user } = this.state;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link
              className="topbar__logo"
              to={type === 'investor' ? '/startups' : '/dashboard'}
            />
          </div>
          <TopbarNav />
          <div className="topbar__right">
            {user && user.type !== 'broker'
              && <TopbarMail new />
            }
            {user && user.type !== 'broker'
              && <TopbarNotification />
            }
            <svg
              width="2"
              height="35"
              viewBox="0 0 2 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="my-auto ml-4 mr-2 d-none d-lg-block"
            >
              <path d="M1 0V35" stroke="#BBC1C5" />
            </svg>
            <TopbarProfile
              name={name}
              logo={logo}
              type={type}
              impersonator={impersonator}
            />
          </div>
        </div>
      </div>
    );
  }
}

TopbarWithNavigation.defaultProps = {
  name: null,
  logo: null,
  type: null,
  impersonator: false,
};
