import React, { Component } from 'react';
import {
  Card, Row, Col,
} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import InvestorCard from './InvestorCard';
import StatsElement from '../../../../shared/components/dashboard/StatsElement';
import {
  FullWideNotification, BasicNotification,
} from '../../../../shared/components/notifications/Notification';


let notificationTC = null;
let notificationRU = null;
const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class ColumnsElement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalReport: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  toggleModalReport = () => {
    const { modalReport } = this.state;
    this.setState({ modalReport: !modalReport });
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  showNotifSuccess = message => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message={message}
    />,
    position: 'right-up',
  });

  render() {
    const {
      investors, stats,
    } = this.props;
    let scheduled = null;
    let completed = null;
    let followups = [];
    let replied = null;

    if (investors && investors.scheduled) {
      scheduled = investors.scheduled.map(
        (investor, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LazyLoad height={80} offset={1000} overflow resize key={`s-${investor.id}-${index}`}>
            <InvestorCard
              investor={investor}
              key={investor.id}
            />
          </LazyLoad>
        ),
      );
    }

    if (investors && investors.completed) {
      completed = investors.completed.map(
        (investor, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LazyLoad height={80} offset={1000} overflow resize key={`c-${investor.id}-${index}`}>
            <InvestorCard
              investor={investor}
              key={investor.id}
            />
          </LazyLoad>
        ),
      );
    }

    if (investors && investors.followups && Array.isArray(investors.followups) && investors.followups.length) {
      followups = investors.followups.map(
        (followup, key) => (
          followup.map(
            (investor, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <LazyLoad height={80} offset={1000} overflow resize key={`f-${investor.id}-${index}-${key}`}>
                <InvestorCard
                  investor={investor}
                  key={investor.id}
                />
              </LazyLoad>
            ),
          )
        ),
      );
    }

    if (investors && investors.replied) {
      replied = investors.replied.map(
        (investor, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LazyLoad height={80} offset={1000} overflow resize key={`o-${investor.id}-${index}`}>
            <InvestorCard
              investor={investor}
              key={investor.id}
            />
          </LazyLoad>
        ),
      );
    }

    return (
      <Card className="container crm-container">
        {replied && scheduled && completed && followups
        && replied.length === 0 && scheduled.length === 0 && completed.length === 0 && followups.length === 0
        && <div className="no-results">No results found. Try with different filters.</div>}
        <Row className="padding-right-80">
          <Col className="col-crm">
            <StatsElement
              id="scheduled"
              type="scheduled"
              name="Scheduled In Total"
              total={stats && stats.scheduled && stats.scheduled.total}
            />
            <div className="col-title">
              <h6>{`${scheduled ? scheduled.length : 0} Queued`}</h6>
            </div>
            <div className="inner-column" id="inner-scheduled">
              {scheduled}
            </div>
          </Col>
          {followups.map((followup, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col className="col-crm" key={`s-${index}`}>
              <StatsElement
                id={`followup${index}`}
                type="followup"
                name={`Follow Up #${index + 1}`}
                total={typeof stats.followups[index] !== 'undefined' ? stats.followups[index].total : 0}
                stat={typeof stats.followups[index] !== 'undefined' ? stats.followups[index].rate : 0}
              />
              <div className="col-title">
                <h6>{`${followup ? followup.length : 0} Queued`}</h6>
              </div>
              <div className="inner-column">
                {followup}
              </div>
            </Col>
          ))}
          <Col className="col-crm">
            <StatsElement
              id="contacted"
              type="contacted"
              name="Contacted So Far"
              total={stats && stats.completed && stats.completed.total}
              stat={stats && stats.completed && stats.completed.rate}
            />
            <div className="col-title">
              <h6>{`${completed ? completed.length : 0} Stopped Or Completed`}</h6>
            </div>
            <div className="inner-column">
              {completed}
            </div>
          </Col>
          <Col className="col-crm">
            <StatsElement
              id="replied"
              type="replied"
              name="Replied"
              total={stats && stats.replied && stats.replied.total}
              stat={stats && stats.replied && stats.replied.rate}
            />
            <div className="col-title opened-title">
              <h6>Replied</h6>
            </div>
            <div className="inner-column opened-col">
              {replied}
            </div>
          </Col>
          <img
            src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/gradient.png`}
            className="crm-gradient"
            alt="gradient"
          />
        </Row>
      </Card>
    );
  }
}

ColumnsElement.defaultProps = {
  investors: [],
  stats: {
    scheduled: {
      total: 0,
    },
    completed: {
      total: 0,
      rate: 0,
    },
    followups: [],
    replied: {
      total: 0,
      rate: 0,
    },
  },
};

ColumnsElement.propTypes = {
  investors: PropTypes.shape(PropTypes.shape({
    scheduled: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      connections: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        picture: PropTypes.string,
      })),
      picture: PropTypes.string,
      campaign: PropTypes.string,
      lastEmail: PropTypes.string,
      nextEmail: PropTypes.string,
      nbOpens: PropTypes.number,
      nbVisits: PropTypes.number,
      replied: PropTypes.bool,
      time: PropTypes.number,
      timeDeck: PropTypes.number,
      timeFinancials: PropTypes.number,
      countDeck: PropTypes.number,
      countFinancials: PropTypes.number,
      countMaterials: PropTypes.number,
      lastVisited: PropTypes.string,
      sent: PropTypes.number,
      toSend: PropTypes.number,
      stopping: PropTypes.bool,
    })),
    contacted: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      connections: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        picture: PropTypes.string,
      })),
      picture: PropTypes.string,
      campaign: PropTypes.string,
      lastEmail: PropTypes.string,
      nextEmail: PropTypes.string,
      nbOpens: PropTypes.number,
      nbVisits: PropTypes.number,
      replied: PropTypes.bool,
      time: PropTypes.number,
      timeDeck: PropTypes.number,
      timeFinancials: PropTypes.number,
      countDeck: PropTypes.number,
      countFinancials: PropTypes.number,
      countMaterials: PropTypes.number,
      lastVisited: PropTypes.string,
      sent: PropTypes.number,
      toSend: PropTypes.number,
      stopping: PropTypes.bool,
    })),
    followups: PropTypes.arrayOf(PropTypes.shape({
      followup: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        connections: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.string,
          picture: PropTypes.string,
        })),
        picture: PropTypes.string,
        campaign: PropTypes.string,
        lastEmail: PropTypes.string,
        nextEmail: PropTypes.string,
        nbOpens: PropTypes.number,
        nbVisits: PropTypes.number,
        replied: PropTypes.bool,
        time: PropTypes.number,
        timeDeck: PropTypes.number,
        timeFinancials: PropTypes.number,
        countDeck: PropTypes.number,
        countFinancials: PropTypes.number,
        countMaterials: PropTypes.number,
        lastVisited: PropTypes.string,
        sent: PropTypes.number,
        toSend: PropTypes.number,
        stopping: PropTypes.bool,
      })),
    })),
    replied: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      connections: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        picture: PropTypes.string,
      })),
      picture: PropTypes.string,
      campaign: PropTypes.string,
      lastEmail: PropTypes.string,
      nextEmail: PropTypes.string,
      nbOpens: PropTypes.number,
      nbVisits: PropTypes.number,
      replied: PropTypes.bool,
      time: PropTypes.number,
      timeDeck: PropTypes.number,
      timeFinancials: PropTypes.number,
      countDeck: PropTypes.number,
      countFinancials: PropTypes.number,
      countMaterials: PropTypes.number,
      lastVisited: PropTypes.string,
      sent: PropTypes.number,
      toSend: PropTypes.number,
      stopping: PropTypes.bool,
    })),
  })),
  stats: PropTypes.shape({
    scheduled: PropTypes.shape({
      total: PropTypes.number,
    }),
    contacted: PropTypes.shape({
      total: PropTypes.number,
      rate: PropTypes.number,
    }),
    followups: PropTypes.arrayOf(PropTypes.shape({
      total: PropTypes.number,
      rate: PropTypes.number,
    })),
    replied: PropTypes.shape({
      total: PropTypes.number,
      rate: PropTypes.number,
    }),
  }),
};

export default ColumnsElement;
