import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class SearchElement extends Component {
  static propTypes = {
    dataOperations: PropTypes.func.isRequired,
  };

  state = {
    keywords: '',
  };

  constructor(props) {
    super(props);
    this.updateKeywordsValue = this.updateKeywordsValue.bind(this);
  }

  updateKeywordsValue = (evt) => {
    const { dataOperations } = this.props;
    this.setState({ keywords: evt.target.value });
    dataOperations(evt.target.value.toLowerCase());
  };

  render() {
    const { keywords } = this.state;

    return (
      <div className="search-block dashboard-block">
        <div className="col-xs-12 col-sm-6 offset-sm-3">
          <div className="input-keywords mb-3 mr-sm-1 mr-lg-1 mr-xl-4 mb-lg-4">
            <div className="input-keywords">
              <input
                className="input-search w-100"
                name="keywords"
                type="text"
                value={keywords}
                placeholder="Search by connection and investor name"
                onChange={evt => this.updateKeywordsValue(evt)}
                onKeyDown={this.onKeyDownHandler}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(SearchElement);
