import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import ProfileTimeline from './components/ProfileTimeline';

class Notifications extends PureComponent {
  state = {
    notifications: [],
    loading: true,
    loaded: false,
    user: JSON.parse(localStorage.getItem('user')),
  };

  componentDidMount = () => {
    this.getNotifications();
  };

  getNotifications = () => {
    axios.get('conversations/notifications')
      .then((response) => {
        this.setState({ notifications: response.data.conversations, loading: false });
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
    // Remove all unread notifications from our list of conversations.
    axios.post('/conversations/notifications/unread/remove');
  };

  render = () => {
    const {
      loaded, loading, notifications, user,
    } = this.state;

    return (
      <Container>
        <Row className="mb-5">
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Notifications</h3>
          </Col>
          <Col xs={12} md={9} lg={7} xl={5}>
            <p className="page-description">
              These notifications are solely related to our <strong>investors community</strong> engagement.
              <br />To monitor the progress of your campaigns, please head over to your dashboard.
            </p>
          </Col>
        </Row>
        <div className="profile mt-5 pt-5">
          {!loaded
            ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            )
            : (
              <Row>
                <Col xs={12}>
                  <Row>
                    {notifications.length > 0 && <ProfileTimeline notifications={notifications} /> }
                    {notifications.length === 0
                      && (
                        <Card>
                          <CardBody>
                            No Notifications yet. Start interacting with the&nbsp;
                            {user.type === 'investor' ? 'founders' : 'investors'} on the platform and receive
                            notifications when they&nbsp;
                            {user.type === 'investor'
                              ? 'want to connect with you' : 'review your documents or profile'}.
                          </CardBody>
                        </Card>
                      )}
                  </Row>
                </Col>
              </Row>
            )
          }
        </div>
      </Container>
    );
  }
}

export default connect(state => ({
  user: state.user,
}))(Notifications);
