import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Row, DropdownItem, DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown,
} from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Link } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ModalCampaignForm from './components/ModalCampaignForm';
import Loader from './components/Loader';
import InvestorsList from './components/InvestorsList';
import ModalCampaignBody from './components/ModalCampaignBody';
import Alert from '../alerts/Alert';

// Used for the update modal & Edit modal
class ModalMultiUpdateExtInvestors extends PureComponent {
  static propTypes = {
    noIcon: PropTypes.bool,
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    showNotif: PropTypes.func.isRequired,
    showNotifSuccess: PropTypes.func.isRequired,
    showNotifWarning: PropTypes.func.isRequired,
    showNotifWarningNoLink: PropTypes.func,
    updateListOfInvestors: PropTypes.func,
    updateMultipleListOfInvestors: PropTypes.func,
    sendUpdate: PropTypes.bool,
    editCampaign: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    investorsContactInfo: PropTypes.arrayOf(
      PropTypes.shape({
        contactId: PropTypes.number,
        fullName: PropTypes.string,
        name: PropTypes.string,
        investorId: PropTypes.number,
        email: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
        ]),
        inCampaign: PropTypes.bool,
        imported: PropTypes.bool,
        campaignName: PropTypes.string,
        contacted: PropTypes.bool,
        contactedOn: PropTypes.string,
      }),
    ).isRequired,
  };

  static defaultProps = {
    displayed: false,
    sendUpdate: false,
    editCampaign: null,
    // dispatch: null,
    updateMultipleListOfInvestors: false,
    updateListOfInvestors: false,
    noIcon: false,
    showNotifWarningNoLink: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      user: JSON.parse(localStorage.getItem('user')),
      campaigns: null,
      loading: false,
      processing: false,
      loaded: false,
      activeTab: 0,
      formError: null,
      investorContactInfo: null,
      investorsCampaigns: null, // a list of all of the campaigns + templates for each investor
      investorsContactInfo: null,
      campaignId: null,
      showCreate: false,
      error: false,
    };
    this.formCampaign = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.addRecipientToCampaign = this.addRecipientToCampaign.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      modal: newProps.displayed,
      formError: null,
    });

    // Check if we have our list of investors
    if (newProps.displayed && newProps.investorsContactInfo) {
      const investorsContactInfoData = newProps.investorsContactInfo;
      const {
        investorsContactInfo,
      } = this.state;

      if (!investorsContactInfo) {
        const filteredInvestorsContactInfoData = this.filterOnlyUniqueInvestors(investorsContactInfoData);
        this.setState({
          investorsContactInfo: filteredInvestorsContactInfoData,
        });
        this.retrieveCampaigns(filteredInvestorsContactInfoData);
      }
    } else if (newProps.displayed && !newProps.investorsContactInfo) {
      const {
        showNotif,
        toggleModal,
      } = this.props;
      if (typeof showNotif === 'function') {
        showNotif('Sorry, an error occurred, please try again');
      }
      if (typeof toggleModal === 'function') {
        toggleModal();
      }
    }
  }

  filterOnlyUniqueInvestors = (investorsContactInfoData) => {
    // hack to filter unique by contactId
    let uniqueInvestorsContactInfo = investorsContactInfoData.filter(el => el.email !== false);
    // eslint-disable-next-line no-prototype-builtins,no-return-assign,max-len
    uniqueInvestorsContactInfo = uniqueInvestorsContactInfo.reduce((a, c) => {
      if (!a.find(v => v.contactId === c.contactId)) {
        a.push(c);
      }
      return a;
    }, []);

    // filter out the investors without contactId
    uniqueInvestorsContactInfo = uniqueInvestorsContactInfo.filter(investor => investor.contactId !== null);

    return uniqueInvestorsContactInfo;
  }

  // eslint-disable-next-line max-len
  generateCampaigns = (contacts, id) => axios.post(`/campaigns/generate/${id !== null ? id : 0}`, JSON.stringify(contacts));

  retrieveCampaigns = (investorsContactInfo) => {
    const contactsInvests = [];
    let investorContactInfo = null;
    const callbacks = [];
    let callbackContacts;
    const { editCampaign } = this.props;

    if (investorsContactInfo.length > 0) {
      investorContactInfo = {
        contactId: investorsContactInfo[0].contactId,
        fullName: investorsContactInfo[0].fullName || investorsContactInfo[0].name,
        investorId: investorsContactInfo[0].id,
        email: investorsContactInfo[0].email,
        inCampaign: investorsContactInfo[0].inCampaign !== undefined ? investorsContactInfo[0].inCampaign : true,
        campaignId: investorsContactInfo[0].campaignId,
        contacted: investorsContactInfo[0].contacted,
        imported: investorsContactInfo[0].imported,
        contactedOn: investorsContactInfo[0].contactedOn,
        campaignName: investorsContactInfo[0].campaignName || investorsContactInfo[0].campaign,
        sent: investorsContactInfo[0].sent ? investorsContactInfo[0].sent : 0,
        smartVC: investorsContactInfo[0].smartVC !== undefined ? investorsContactInfo[0].smartVC : 0,
        fund: investorsContactInfo[0].fund !== undefined ? investorsContactInfo[0].fund : 0,
      };
    }

    this.setState({
      loading: true,
      loaded: false,
      formError: null,
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const investorsContactInfoItem of investorsContactInfo) {
      const { contactId } = investorsContactInfoItem;
      const investorId = investorsContactInfoItem.id;
      const imported = investorsContactInfoItem.imported ? investorsContactInfoItem.imported : false;
      contactsInvests.push({
        investorId,
        contactId,
        imported,
      });
    }

    for (let i = 0; i < Math.ceil(contactsInvests.length / 25); i += 1) {
      callbackContacts = [];
      for (let j = i * 25; j < (i + 1) * 25; j += 1) {
        if (j in contactsInvests) {
          callbackContacts.push(contactsInvests[j]);
        }
      }

      // If we are editing a campaign, then we only want the emails for this campaign
      callbacks[i] = this.generateCampaigns(callbackContacts, editCampaign);
    }

    Promise.all(callbacks)
      .then((results) => {
        const investorsCampaigns = []; // a list of all of the campaigns + templates for each investor
        for (let i = 0; i < results.length; i += 1) {
          investorsCampaigns.push(...results[i].data);
        }

        this.setState({
          investorsCampaigns,
          campaigns: results[0].data[0].data,
          campaignId: results[0].data[0].data[0].id,
          investorContactInfo,
          loading: false,
          loaded: true,
          showCreate: false,
          processing: false,
        });
      }).catch((error) => {
        const { toggleModal } = this.props;
        if (error && typeof error.response === 'object' && error.response.status === 406) {
          // Do something error.response.data.message ?
          this.setState({ showCreate: true });
        } else if (typeof toggleModal === 'function') {
          toggleModal();
        }

        this.setState({
          campaigns: null,
          loading: false,
          loaded: true,
        });
      });
  };

  toggleTemplate = (investor, contact, fundInvestorId, isSubFund) => {
    const { investorsCampaigns } = this.state; // a list of all of the campaigns + templates for each investor

    const investorContactInfo = isSubFund ? {
      contactId: contact.id,
      fullName: contact.fullName,
      investorId: fundInvestorId,
      email: contact.email,
      inCampaign: contact.inCampaign,
      campaignId: contact.campaignId,
      campaignName: contact.campaignName,
      contacted: contact.contacted,
      imported: contact.imported,
      contactedOn: contact.contactedOn,
      sent: investor.sent ? investor.sent : 0,
      smartVC: investor.smartVC !== undefined ? investor.smartVC : 0,
      fund: investor.fund ? investor.fund : 0,
    } : {
      contactId: investor.contactId,
      fullName: investor.fullName || investor.name,
      investorId: investor.id,
      email: investor.email,
      inCampaign: investor.inCampaign || investor.campaign !== undefined,
      campaignId: investor.campaignId,
      campaignName: investor.campaignName || investor.campaign,
      contacted: investor.contacted || investor.contacted,
      imported: investor.imported,
      contactedOn: investor.contactedOn,
      sent: investor.sent ? investor.sent : 0,
      smartVC: investor.smartVC !== undefined ? investor.smartVC : 0,
      fund: investor.fund !== undefined ? investor.fund : 0,
    };
    const campaigns = isSubFund ? investorsCampaigns.find(el => el.id === contact.id)
      : investorsCampaigns.find(el => el.id === investor.contactId);

    this.setState({
      investorContactInfo,
      campaigns: campaigns !== undefined ? campaigns.data : [],
    });
  };

  toggle = () => {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      this.setState({
        loaded: false,
        campaigns: null,
        investorsCampaigns: null,
        investorsContactInfo: null,
      }, toggleModal);
    }
  };

  toggleTabs = (tab, campaignId) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        campaignId,
        formError: null,
      });
    }
  };

  addRecipientToCampaign = (fields, key) => {
    const { campaigns } = fields;
    const { templates } = campaigns[key];
    const {
      investorContactInfo,
      campaignId,
      investorsContactInfo,
    } = this.state;
    const { editCampaign } = this.props;

    if (investorContactInfo.campaignId !== campaignId || editCampaign) {
      const emails = [];
      if (typeof templates !== typeof undefined) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < templates.length; i++) {
          emails[`emails[${i}][title]`] = templates[i].title;
          emails[`emails[${i}][content]`] = templates[i].content;
          emails[`emails[${i}][position]`] = templates[i].position;
        }
      }

      this.setState({
        processing: true,
        formError: false,
      });

      axios.post('/campaigns/emails/add', queryString.stringify({
        campaignId: campaigns[key].id,
        contactId: investorContactInfo.contactId,
        investorId: investorContactInfo.investorId,
        importedInvestor: investorContactInfo.imported !== false ? 1 : 0,
        edit: editCampaign !== null ? 1 : 0,
        smartVC: investorContactInfo.smartVC !== undefined
          ? investorContactInfo.smartVC : false,
        ...emails,
      }))
        .then((response) => {
          if (response.data && response.data.success) {
            const investorsContactInfoRemaining = investorsContactInfo.filter(
              el => el.id !== investorContactInfo.investorId,
            );

            if (investorsContactInfo.length === 0) {
              this.toggle();
              this.setState({
                loaded: false,
                campaigns: null,
                investorsCampaigns: null,
                investorsContactInfo: null,
              });
            } else {
              this.adaptModalToNextInvestor(investorsContactInfoRemaining);
            }

            this.emailIsSent(campaigns[key].time, false, [investorContactInfo.investorId],
              investorContactInfo.name || investorContactInfo.fullName);
          } else {
            this.setState({ formError: 'Sorry, an error occurred, please try again.' });
          }
        })
        .catch((error) => {
          if (error && error.response) {
            this.setState({ formError: error.response.data.message });
          } else {
            this.setState({ formError: 'Sorry, an error occurred, please try again.' });
          }
        }).finally(() => {
          this.setState({
            processing: false,
          });
        });
    }
  };

  addAllRecipientsToCampaign = () => {
    const {
      investorsContactInfo, // the list of all the investors from that page added to the modal
      campaignId,
    } = this.state;

    const {
      sendUpdate,
      editCampaign,
      showNotifWarningNoLink,
    } = this.props;

    const data = []; // the data we are going to save on the server
    const investorsToContact = [];
    let message;
    let investorsContactInfoFiltered = null;

    // Ask for confirmation first
    if (sendUpdate) {
      message = 'You are about to add all these investors to your campaign at once. Are you sure?';
    } else if (editCampaign) {
      message = 'You are about to add all these investors to your campaign at once. Are you sure?';
    } else {
      message = 'You are about to add all the investors from this page to your campaign at once. Are you sure?';
    }

    // eslint-disable-next-line no-alert
    if (!window.confirm(message)) {
      return false;
    }

    if (editCampaign === null) {
      // Remove all the investors who are already in this campaign.
      investorsContactInfoFiltered = investorsContactInfo.filter(el => el.campaignId !== campaignId);
    } else {
      investorsContactInfoFiltered = investorsContactInfo;
    }

    if (investorsContactInfoFiltered.length === 0) {
      const warningMessages = [];
      warningMessages.push('You have already contacted all of these investors with this campaign. You can select a'
        + ' different campaign or search for other investors.');
      showNotifWarningNoLink(...warningMessages);
    }

    // If confirmed then let's go:
    // retrieve the necessary information to send to the server to save the outreach email
    for (let i = 0; i < investorsContactInfoFiltered.length; i += 1) {
      if (i in investorsContactInfoFiltered) {
        investorsToContact.push(investorsContactInfoFiltered[i].id);

        const result = this.retrieveDataToSave(investorsContactInfoFiltered, i);
        if (result) {
          data.push(result);
        }
      }
    }

    return this.saveListOfEmails(data, investorsToContact);
  }

  addMultipleRecipientsToCampaign = (investsCount) => {
    const {
      investorsContactInfo, // the list of all of the investors from that page added to the modal
      campaignId,
    } = this.state;
    const { editCampaign } = this.props;
    const investorsToContact = [];
    const data = []; // the data we are going to save on the server

    let count = investsCount; // how many investors do we add to the campaign
    let investorsContactInfoFiltered = null;
    let investorsContactInfoRemaining = [];

    // Ask for confirmation first
    // eslint-disable-next-line no-alert
    if (!window.confirm(`You are going to add ${count} investors to your campaign at once. Are you sure?`)) {
      return false;
    }

    if (editCampaign === null) {
      // Remove all the investors who are already in this campaign.
      investorsContactInfoFiltered = investorsContactInfo.filter(el => el.campaignId !== campaignId);
    } else {
      investorsContactInfoFiltered = investorsContactInfo;
    }

    if (investorsContactInfoFiltered.length > 0) {
      // Calculate how many investors will remain after we add the 'count' investors to the list of investors to contact
      if (count > investorsContactInfoFiltered.length) {
        count = investorsContactInfoFiltered.length;
      }

      // retrieve the necessary information to send to the server to save the outreach email
      for (let i = 0; i < count; i += 1) {
        if (i in investorsContactInfoFiltered) {
          investorsToContact.push(investorsContactInfoFiltered[i].id);

          const result = this.retrieveDataToSave(investorsContactInfoFiltered, i);
          if (result) {
            data.push(result);
          }
        }
      }

      // Only keep the investors outside of the 'count' (1, 10) left after we contacted the first 1 or 10
      // This is used to display the list of investors remaining on the left
      if (investorsContactInfoFiltered.length > count) {
        investorsContactInfoRemaining = investorsContactInfoFiltered.slice(count, investorsContactInfoFiltered.length);
      } else {
        investorsContactInfoRemaining = [];
      }
    }

    return this.saveListOfEmails(data, investorsToContact, false, investorsContactInfoRemaining);
  };

  retrieveDataToSave = (investorsContactInfoFiltered, i) => {
    const { activeTab, investorsCampaigns } = this.state;
    let data = null;

    const campaigns = investorsCampaigns.filter(el => el.id === investorsContactInfoFiltered[i].contactId)[0];

    if (campaigns && campaigns.data && campaigns.data.length >= 1) {
      // Find the one campaign we selected from the list of campaigns.
      const { templates } = campaigns.data[activeTab];
      const emails = [];
      if (typeof templates !== typeof undefined) {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < templates.length; j++) {
          emails[j] = {
            title: templates[j].title,
            content: templates[j].content,
            position: templates[j].position,
          };
        }
      }

      data = {
        campaignId: campaigns.data[activeTab].id,
        contactId: investorsContactInfoFiltered[i].contactId,
        investorId: investorsContactInfoFiltered[i].id,
        smartVC: investorsContactInfoFiltered[i].smartVC !== undefined
          ? investorsContactInfoFiltered[i].smartVC : false,
        emails,
      };
    }

    return data;
  }

  // eslint-disable-next-line max-len
  saveBatch = (editCampaign, data) => axios.post(`/campaigns/emails/${editCampaign !== null ? 'edit' : 'add'}/list`, JSON.stringify(data));

  saveListOfEmails = (data, investorsToContact, all = true, investorsContactInfoRemaining = null) => {
    const { editCampaign, showNotifWarningNoLink } = this.props;
    const callbacks = [];
    let callbackEmails;

    if (data.length > 0) {
      this.setState({ processing: true });

      for (let i = 0; i < Math.ceil(data.length / 25); i += 1) {
        callbackEmails = [];
        for (let j = i * 25; j < (i + 1) * 25; j += 1) {
          if (j in data) {
            callbackEmails.push(data[j]);
          }
        }

        // Handle individual batch error
        callbacks[i] = this.saveBatch(editCampaign, callbackEmails).catch(
          () => {
            const warningMessages = [];
            warningMessages.push('An error occurred while trying to add these investors to your campaign.'
              + ' Please try again or contact support.');
            this.setState({ error: true });
            showNotifWarningNoLink(...warningMessages);
          },
        );
      }

      Promise.all(callbacks)
        .then((response) => {
          // calculate the number of emails sent, duplicates, and found in other campaigns
          let added = 0;
          let duplicate = 0;
          let found = 0;
          for (let i = 0; i < response.length; i += 1) {
            if (response[i].data.added !== undefined) {
              added += response[i].data.added;
            }
            if (response[i].data.duplicate !== undefined) {
              duplicate += response[i].data.duplicate;
            }
            if (response[i].data.found !== undefined) {
              found += response[i].data.found;
            }
          }
          const { error } = this.state;
          // let's check there is an error from the batch above
          if (!error) {
            // Handle success case
            this.emailIsSent(null, true, investorsToContact, null, added, duplicate, found);
            if (all) {
              this.setState({ loaded: false, campaigns: null, processing: false }, () => this.toggle());
            } else {
              this.adaptModalToNextInvestor(investorsContactInfoRemaining);
            }
          } else {
            this.setState({ processing: false, error: false });
          }
        });
    }

    return true;
  }


  adaptModalToNextInvestor = (investorsContactInfoRemaining) => {
    const { investorsCampaigns } = this.state; // a list of all the campaigns + templates for each investor
    let nextInvestorContactInfo = {};

    if (investorsContactInfoRemaining.length > 0) {
      const investor = investorsContactInfoRemaining[0];
      nextInvestorContactInfo = {
        contactId: investor.contactId,
        fullName: investor.fullName || investor.name,
        investorId: investor.id,
        email: investor.email,
        inCampaign: investor.inCampaign || investor.campaign !== undefined,
        campaignId: investor.campaignId,
        contacted: investor.contacted,
        imported: investor.imported !== undefined ? investor.imported : false,
        contactedOn: investor.contactedOn,
        campaignName: investor.campaignName || investor.campaign,
        sent: investor.sent ? investor.sent : 0,
        smartVC: investor.smartVC !== undefined ? investor.smartVC : 0,
        fund: investor.fund !== undefined ? investor.fund : 0,
      };

      const campaigns = investorsCampaigns.filter(el => el.id === nextInvestorContactInfo.contactId);

      this.setState({
        investorContactInfo: nextInvestorContactInfo,
        investorsContactInfo: investorsContactInfoRemaining,
        campaigns: campaigns[0].data,
        processing: false,
      });
    } else {
      this.toggle();
      this.setState({
        loaded: false,
        campaigns: null,
        investorsCampaigns: null,
        investorsContactInfo: null,
      });
    }
  }

  emailIsSent = (
    time = null, multiple = false, contactedInvestors = [], investorName = null,
    added = null, duplicate = null, found = null,
  ) => {
    // the investorName is only used when we contact one investor to retrieve his/her name for the pop-up window
    const { user } = this.state;
    const {
      showNotifSuccess,
      showNotifWarning,
      updateMultipleListOfInvestors,
      updateListOfInvestors,
    } = this.props;

    const successMessages = [];
    const warningMessages = [];
    let notifType; // 1 = success; 2 = warning; 3 = all
    let setTimeOut = false;

    // Update the list in the parent component (investor list page)
    if (multiple && updateMultipleListOfInvestors) {
      updateMultipleListOfInvestors(null, true, contactedInvestors);
    } else if (updateListOfInvestors) {
      updateListOfInvestors(contactedInvestors[0], true);
    }

    this.updateUserInfo();

    if (user && !user.appSubmitted) {
      notifType = 3;
      setTimeOut = false;
      warningMessages.push('Your email will be sent out once you have finished completing your profile. '
        + 'Go to <strong><u>My Company</u></strong> to complete your application.', '/myProfile/company');

      if (multiple) {
        successMessages.push(`${added} investors have been successfully added to your campaign. ${
          duplicate > 0 ? `${duplicate} duplicates were found, they have not been added.` : ''
        }${found > 0 ? `${found} investors were in another campaign, they have not been added `
          + 'as per your campaign settings.' : ''
        }${found === 0 && duplicate === 0 ? 'An email will be sent to these investors as per your campaign settings.'
          : ''}`);
      } else {
        successMessages.push(`${investorName} has been successfully added to your campaign. An email will be sent `
          + `to this investor ${time ? `at ${time} UTC` : 'very soon'}`);
      }
    } else if (user && !user.mailboxConnected && user.isCustomer) {
      setTimeOut = true;
      notifType = 3;
      warningMessages.push('You must connect your mailbox to Angels Partners in order to contact investors. '
        + '<strong><u>Go to settings</u></strong> to get started.', '/myProfile/settings');

      if (multiple) {
        successMessages.push('These investors have been successfully added to your campaign. '
          + 'An email will be sent to these investors as soon as you connect your mailbox to the platform.');
      } else {
        successMessages.push(`${investorName} 
        has been successfully added to your campaign. An email will be sent to this investor as soon as you connect `
          + 'your mailbox to the platform.');
      }
    } else if (user && user.pings <= 0 && !user.isCustomer && user.countSend > 0 && user.mailboxConnected) {
      notifType = 3;
      setTimeOut = true;
      warningMessages.push('To start emailing investors, you need to subscribe to one of our subscription plans. '
        + '<strong><u>Upgrade Now!</u></strong>',
      '/ext/pricing');

      if (multiple) {
        successMessages.push('These investors have been successfully added to your campaign. '
          + 'An email will be sent to these investors as soon as you subscribe ot one of our plans.');
      } else {
        successMessages.push(`${investorName} 
        has been successfully added to your campaign. An email will be sent to this investor as soon as you subscribe `
          + 'to one of our plans.');
      }
    } else if (user && user.isCustomer && !user.isPayingCustomer) {
      notifType = 3;
      setTimeOut = true;
      warningMessages.push(
        'Your Campaigns Are On Pause',
        'You cannot contact investors until your trial ends. '
        + 'To start your campaign(s), you need to end your trial now, or wait until it ends.',
        'ext/payment/process',
      );

      if (multiple) {
        successMessages.push(`${added} investors have been successfully added to your campaign. ${
          duplicate > 0 ? `${duplicate} duplicates were found, they have not been added.` : ''
        }${found > 0 ? `${found} investors were in another campaign, they have not been added `
          + 'as per your campaign settings.' : ''
        }${found === 0 && duplicate === 0 ? 'An email will be sent to these investors as per your campaign settings.'
          : ''}`);
      } else {
        successMessages.push(`${investorName} has been successfully added to your campaign. An email will be sent `
          + `to this investor ${time ? `at ${time} UTC` : 'very soon'}`);
      }
    } else if (user && user.pings <= 0 && user.isCustomer && user.countSend > 0 && user.mailboxConnected) {
      notifType = 3;
      setTimeOut = true;
      warningMessages.push('You have no pings left. '
        + 'You should refill now if you want to resume your campaigns. '
        + 'All your campaigns are automatically paused at the moment and no emails will be sent. '
        + '<strong><u>Buy more pings</u></strong>', '/ext/pricing');

      if (multiple) {
        successMessages.push('These investors have been successfully added to your campaign. '
          + 'An email will be sent to these investors as soon as you refill your accounts with more pings.');
      } else {
        successMessages.push(`${investorName} 
        has been successfully added to your campaign. An email will be sent to this investor as soon as you refill `
          + 'your accounts with more pings.');
      }
    } else if (user && user.pings < user.countSend && user.isCustomer && user.mailboxConnected) {
      notifType = 3;
      setTimeOut = true;
      warningMessages.push('The number of messages to send is larger than the number of pings left on your account. '
        + 'Once your pings credit goes to 0, you will not be able to send automated messages through the platform '
        + 'anymore. <strong><u>Buy more pings.</u></strong>', '/ext/pricing');

      if (multiple) {
        successMessages.push('These investors have been successfully added to your campaign. '
          + 'An email will be sent to this investor as soon as you refill your account with more pings.');
      } else {
        successMessages.push(`${investorName} 
        has been successfully added to your campaign. An email will be sent to this investor as soon as you refill `
          + 'your account with more pings.');
      }
    } else if (multiple) {
      notifType = 1;
      setTimeOut = false;
      successMessages.push(`${added} investors have been successfully added to your campaign. ${
        duplicate > 0 ? `${duplicate} duplicates were found, they have not been added.` : ''
      }${found > 0 ? `${found} investors were in another campaign, they have not been added `
        + 'as per your campaign settings.' : ''
      }${found === 0 && duplicate === 0 ? 'An email will be sent to these investors as per your campaign settings.'
        : ''}`);
    } else {
      notifType = 1;
      setTimeOut = false;
      successMessages.push(`${investorName} has been successfully added to your campaign. An email will be sent to this`
        + ` investor ${time ? `at ${time} UTC` : 'very soon'}`);
    }

    if (notifType === 1) {
      showNotifSuccess(...successMessages);
    } else if (notifType === 2) {
      showNotifWarning(...warningMessages);
    } else {
      showNotifWarning(...warningMessages);
      if (setTimeOut) {
        setTimeout(() => showNotifSuccess(...successMessages), 1000);
      }
    }
  };

  updateUserInfo = () => {
    // Set up Loading
    const { state } = this;
    const user = { ...state.user };

    user.investorsAdded = true;
    user.countSend += 1;

    // Update the State
    this.setState({ user });
    localStorage.setItem('user', JSON.stringify(user));

    return true;
  };

  getModalTitle = () => {
    const {
      sendUpdate,
      editCampaign,
    } = this.props;
    const { investorsContactInfo } = this.state;
    if (editCampaign) {
      if (investorsContactInfo && investorsContactInfo.length > 1) {
        return `Edit emails for ${investorsContactInfo.length} investors from this campaign`;
      }
      if (investorsContactInfo && investorsContactInfo.length === 1) {
        return 'Edit emails for this investor from this campaign';
      }
      return 'Edit emails for these investors from this campaign';
    }
    if (sendUpdate) {
      if (investorsContactInfo && investorsContactInfo.length > 1) {
        return `Send an update to ${investorsContactInfo.length} investors`;
      }
      if (investorsContactInfo && investorsContactInfo.length === 1) {
        return 'Send an update to this investor';
      }
      return 'Send an update to these investors';
    }
    if (investorsContactInfo && investorsContactInfo.length > 1) {
      return `Contact ${investorsContactInfo.length} investors from this page`;
    }
    if (investorsContactInfo && investorsContactInfo.length === 1) {
      return 'Contact this investor from this page';
    }
    return 'Contact these investors from this page';
  }

  render() {
    const {
      modal,
      activeTab,
      campaigns,
      loaded,
      loading,
      processing,
      formError,
      user,
      investorContactInfo,
      investorsContactInfo,
      showCreate,
    } = this.state;
    const {
      sendUpdate,
      noIcon,
      editCampaign,
    } = this.props;

    const width = window.innerWidth;
    const campaignName = campaigns ? campaigns[activeTab].name : '';

    const campaignDropdownItems = Array.isArray(campaigns) ? campaigns.map((c, i) => (
      <DropdownItem
        key={c.id}
        onClick={() => this.toggleTabs(i, c.id)}
      >
        {c.name.length > 49 ? `${c.name.slice(0, 50)}...` : c.name}
      </DropdownItem>
    )) : null;

    const loader = processing ? (
      <div className="modal-loader">
        <img src="/img/loading.gif" alt="loading gif" />
      </div>
    ) : null;

    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        className={`modal-dialog--primary modal-campaigns ${loaded
          ? 'xxx-large multiple' : 'xx-large'}`}
      >
        {loader}
        <div className="modal__header mb-2">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4 className="modal__title">
            {this.getModalTitle()}
          </h4>
          <img src={`${process.env.PUBLIC_URL}/img/linePurple.svg`} className="line" alt="line" />
        </div>
        <ModalCampaignBody>
          {!loaded && <Loader loading={loading} />}
          {loaded
          && (
            <div
              className={`tabs ${width >= 800 ? 'tabs--vertical mt-0' : 'tabs--horizontal tabs--bordered-bottom'}`}
            >
              <div className="tabs__wrap">
                {campaigns && width < 800 && !editCampaign
                && (
                  <UncontrolledDropdown className="select-type mt-2">
                    <DropdownToggle
                      className="icon rounded icon--right w-100 icon--blue capitalize"
                      color="primary"
                    >
                      <p>{campaignName} <ChevronDownIcon /></p>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown__menu">
                      {campaignDropdownItems}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {investorsContactInfo && investorsContactInfo.length === 0
                && (
                  <Alert color="warning" className="alert--colored mt-5 w-100">
                    <div>
                      <p>
                        <span className="bold-text">There are no investors to contact from this page.</span><br />
                        Try on another page.
                      </p>
                    </div>
                  </Alert>
                )}
                {investorsContactInfo && investorContactInfo
                && (
                  <InvestorsList
                    noIcon={noIcon}
                    investorsContactInfo={investorsContactInfo}
                    investorContactInfo={investorContactInfo}
                    toggleTemplate={(investor, contact, fundInvestorId, isSubFund) => {
                      this.toggleTemplate(investor, contact, fundInvestorId, isSubFund);
                    }}
                  />
                )}
                {
                  campaigns
                    ? (
                      <div>
                        { width >= 800 && !editCampaign && (
                          <UncontrolledDropdown className="select-type campaign-select mb-5">
                            <DropdownToggle
                              className="icon rounded icon--right w-100 icon--blue capitalize"
                              color="primary"
                            >
                              <p>{campaignName} <ChevronDownIcon /></p>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                              {campaignDropdownItems}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                        <ModalCampaignForm
                          formRef={this.formCampaign}
                          user={user}
                          investorContactInfo={investorContactInfo}
                          initialValues={{ campaigns }}
                          campaigns={campaigns}
                          activeTab={activeTab}
                          sendUpdate={sendUpdate}
                          addMultipleRecipientsToCampaign={count => this.addMultipleRecipientsToCampaign(count)}
                          addAllRecipientsToCampaign={this.addAllRecipientsToCampaign}
                          onSubmit={fields => this.addRecipientToCampaign(fields, activeTab)}
                          formError={formError || ''}
                          editCampaign={!!editCampaign}
                          multiCampaigns
                        />
                      </div>
                    ) : showCreate
                    && (
                      <Row className="select-boxes one-box">
                        <Link
                          id="campaigns"
                          className="select-boxes-box"
                          to="/campaigns"
                        >
                          <h2 className="select-boxes-title">Create A Campaign</h2>
                          <p className="select-boxes-subtitle">
                            Create a campaign to automate your outreach to investors, monitor your progress and
                            raise funds efficiently.
                          </p>
                          <Button color="primary" className="rounded icon icon--right select-boxes-go">
                            Be Efficient <ChevronRightIcon />
                          </Button>
                        </Link>
                      </Row>
                    )
                }
              </div>
            </div>
          )
          }
        </ModalCampaignBody>
      </Modal>
    );
  }
}

export default ModalMultiUpdateExtInvestors;
