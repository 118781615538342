import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const CompletionInfoNotification = ({ appSubmitted, completion, completionInfo }) => {
  if (appSubmitted && completion < 100) {
    return (
      <Row>
        <Col xs={12} lg={{ size: 12 }}>
          <div className="dashboard-warnings">
            <div className="dashboard__step-slide dashboard__step-slide--warning">
              <h4>
                Fill out all the information on your profile and make sure your completion rate is at 100% to increase
                your ranking on our platform and maximise your chances of getting funded.
              </h4>
              <br />
              {Object.entries(completionInfo).map(([category, items]) => (
                <div key={category}>
                  <h4><strong>{category}:</strong></h4>
                  <ul>
                    {items.map(item => (
                      <li key={item.length}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  return null;
};

CompletionInfoNotification.propTypes = {
  appSubmitted: PropTypes.bool.isRequired,
  completion: PropTypes.number.isRequired,
  completionInfo: PropTypes.shape({
    info: PropTypes.arrayOf(PropTypes.string),
    team: PropTypes.arrayOf(PropTypes.string),
    market: PropTypes.arrayOf(PropTypes.string),
    traction: PropTypes.arrayOf(PropTypes.string),
    updates: PropTypes.arrayOf(PropTypes.string),
    logo: PropTypes.arrayOf(PropTypes.string),
    financials: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default CompletionInfoNotification;
