import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import EditIcon from 'mdi-react/EditIcon';
import PanelDocsend from '../../../containers/Campaigns/Panels/PanelDocsend';
import PanelAngelsPartners from '../../../containers/Campaigns/Panels/PanelAngelsPartners';
import PanelTrackingLink from '../../../containers/Campaigns/Panels/PanelTrackingLink';
import PanelLastDeal from '../../../containers/Campaigns/Panels/PanelLastDeal';
import PanelLocationCity from '../../../containers/Campaigns/Panels/PanelLocationCity';
import PanelVertical from '../../../containers/Campaigns/Panels/PanelVertical';
import PanelFirstName from '../../../containers/Campaigns/Panels/PanelFirstName';
import PanelTemplates from '../../../containers/Campaigns/Panels/PanelTemplates';
import PanelMessageLength from '../../../containers/Campaigns/Panels/PanelMessageLength';

class ModalConfirm extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    improvements: PropTypes.number.isRequired,
  };

  static defaultProps = {
    displayed: false,
  };

  docsend = 'DocSend';

  google = 'Google Drive';

  AngelsPartners = 'Angels Partners';

  website = 'website';

  investorFirstName = 'investorFirstName';

  investorVertical = 'investorVertical';

  investorCity = 'investorCity';

  lastDeal = 'lastDeal';

  trackingLink = 'trackingLink';

  messageLength = 'messageLength';

  templates = 'templates';

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  toggle = () => {
    const { toggleModal, onSuccess } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    toggleModal();
    onSuccess();
  }

  edit = () => {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    toggleModal();
  }

  render() {
    const { modal } = this.state;
    const { messages, improvements } = this.props;

    return (
      <Modal
        isOpen={modal}
        toggle={this.edit}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-dialog--header"
      >
        <div className="modal__header">
          <span className="lnr lnr-flag modal__title-icon" />
          <h4 className="text-modal modal__title">
            {improvements > 0
              ? `Increase your chances of getting a response by ${improvements}% by following these guidelines`
              : 'Before you continue, here is some feedback on your templates to increase your chances of getting '
              + 'meetings'
            }
          </h4>
        </div>
        <div className="modal__body">
          {messages.includes(this.templates)
            && <PanelTemplates />
          }
          {messages.includes(this.trackingLink)
            && <PanelTrackingLink />
          }
          { (messages.includes(this.docsend) || messages.includes(this.google))
            && <PanelDocsend type={messages.includes(this.docsend) ? this.docsend : this.google} />
          }
          {messages.includes(this.investorFirstName)
            && <PanelFirstName />
          }
          {messages.includes(this.investorVertical)
            && <PanelVertical />
          }
          {messages.includes(this.investorCity)
            && <PanelLocationCity />
          }
          {messages.includes(this.lastDeal)
            && <PanelLastDeal />
          }
          {(messages.includes(this.AngelsPartners) || messages.includes(this.website))
            && (
              <PanelAngelsPartners
                type={messages.includes(this.AngelsPartners) ? this.AngelsPartners : this.website}
              />
            )
          }
          {messages.includes(this.messageLength)
            && <PanelMessageLength />
          }
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel rounded" onClick={this.toggle}>Proceed Anyway</Button>{' '}
            <Button
              className="modal_ok rounded icon icon--right icon--blue"
              type="button"
              color="primary"
              onClick={this.edit}
            >
              Edit
              <EditIcon />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user,
}))(ModalConfirm);
