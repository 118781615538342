import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Button, Col, Container, Row,
} from 'reactstrap';
import axios from 'axios';
import SendIcon from 'mdi-react/SendIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import ChatIcon from 'mdi-react/CommentMultipleOutlineIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import DeclinedIcon from 'mdi-react/CloseCircleIcon';
import queryString from 'query-string';
import NotificationSystem from 'rc-notification';

import ProfileMain from './components/ProfileMain';
import ProfileContent from './components/ProfileContent';
import { FullWideNotification } from '../../shared/components/notifications/Notification';
import Modal from '../../shared/components/modals/Modal';
import ModalInput from '../../shared/components/modals/ModalInput';
import ModalTrial from '../../shared/components/modals/ModalTrial';

let notificationTC = null;

const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 5,
    closable: true,
    style: { top: 0, left: 0 },
    className: `${position} ${rtl}-support`,
  });
};

class InvestorProfile extends PureComponent {
  state = {
    investor: {},
    loading: true,
    loaded: false,
    modal: false,
    modalPing: false,
    modalTrial: false,
  };

  componentDidMount = () => {
    const { props } = this;
    this.init(props.match.params.id);
  };

  componentWillReceiveProps(nextProps) {
    const { state } = this;
    const prevStateId = parseInt(state.investor.id, 10);
    // eslint-disable-next-line react/prop-types
    const nextPropsId = parseInt(nextProps.match.params.id, 10);
    if (!Number.isNaN(prevStateId)
      && !Number.isNaN(nextPropsId)
      && nextPropsId !== prevStateId) {
      this.init(nextPropsId);
    }
    return null;
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  init = (id) => {
    axios.get(`investors/profile/${id}`)
      .then((response) => {
        this.setState({ investor: response.data, loading: false });
        setTimeout(() => this.setState({ loaded: true }), 500);
      }).catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateInvestor(id, false, false, null, false, null, false, null, false, null);
      });
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);


    window.addEventListener('popstate', () => {
      // The popstate event is fired each time when the current history entry changes.
      sessionStorage.setItem('scrollActiveInt', true);
    }, false);
  };

  pingInvestor = (message) => {
    const { investor } = this.state;
    const { id } = investor;
    const user = JSON.parse(localStorage.getItem('user'));

    if (user.isCustomer && !user.isPayingCustomer) {
      this.setState({ modalTrial: true });
    } else {
      // Set up Loading
      this.updateInvestor(id, false, null, false, true, false, null, false, null);

      // Post the Ping.
      axios.post('/investors/ping', queryString.stringify({ id, message }))
        .then((response) => {
          if (response) {
            // Remove the modal
            this.setState({ modalPing: false });
            this.updateInvestor(id, false, null, true, false, false, null, false, null);
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            if (error.response.status === 400) {
              this.showNotif(error.response.data.message);
            } else if (error.response.status === 402) {
              this.setState({ modal: true });
            } else if (error.response.status === 403) {
              this.showModal();
            }
          } else {
            this.showModal();
          }

          this.updateInvestor(id, false, null, false, false, false, null, false, null);
        });
    }
  };

  updateInvestor = (id, adding, favorite, pinged, pinging, isProspect, addingProspect, removing, notAFit) => {
    // Set up Loading
    const { investor } = this.state;

    // Sort out Favorite and Pinged
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (favorite === null) favorite = investor.favorite;

    // Sort out Favorite and Pinged
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (pinged === null) pinged = investor.pinged;

    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (isProspect === null) isProspect = investor.isProspect;

    // Sort out Not A Fit
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (notAFit === null) notAFit = investor.notAFit;

    // Update Investor
    const updatedInvestor = {
      ...investor, adding, favorite, pinged, pinging, isProspect, addingProspect, removing, notAFit,
    };

    // Update the State
    this.setState({ investor: updatedInvestor });

    return true;
  };

  showModal = () => {
    const { modal } = this.state;
    if (modal) {
      // We're adding this line in case the user clicks
      // on a different ping button in within the 10 seconds
      this.setState({ modal: false }, () => {
        this.setState({ modal: true });
      });
    } else {
      this.setState({ modal: true });
    }
    setTimeout(() => {
      // Resets the state of the modal
      this.setState({ modal: false });
    }, 10000);
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  toggleModalPing = () => {
    const { modalPing } = this.state;
    this.setState({ modalPing: !modalPing });
  };

  toggleModalTrial = () => {
    const { modalTrial } = this.state;
    this.setState({ modalTrial: !modalTrial });
  };

  goBack = () => {
    sessionStorage.setItem('scrollActiveInt', true);
    // eslint-disable-next-line react/prop-types
    const { history } = this.props;
    history.goBack();
  };

  renderPingButton = () => {
    const { investor } = this.state;
    if (investor.accepted && investor.conversation_id) {
      return (
        <Link to={investor.conversation_id ? `/inbox/${investor.conversation_id}` : '/inbox'}>
          <Button className="icon icon--right rounded text-white" color="purple">
              Go To Chat <ChatIcon />
          </Button>
        </Link>
      );
    } if (investor.accepted) {
      return (
        <Button className="icon icon--right icon--blue rounded" color="action">
          Connected <SendIcon />
        </Button>
      );
    } if (investor.declined) {
      return (
        <Button className="icon icon--right icon--blue rounded rounded icon btn" color="secondary">
          Not Interested <DeclinedIcon />
        </Button>
      );
    }
    if (investor.pinged) {
      return (
        <Button className="icon icon--right rounded rounded icon btn btn-primary" color="action">
          Sent <SendIcon />
        </Button>
      );
    } if (investor.pinging) {
      return (
        <Button
          className="icon icon--right icon--blue rounded rounded icon btn btn-primary"
          color="primary"
          outline
        >
          Pinging <LoadingIcon className="mdi-icon-spinning" />
        </Button>
      );
    }
    return (
      <Button
        className="icon icon--right icon--blue rounded rounded icon btn btn-primary"
        color="primary"
        onClick={() => this.toggleModalPing()}
      >
        Contact <SendIcon />
      </Button>
    );
  };

  addFavorites = (e) => {
    if (e) { e.preventDefault(); }
    const { investor } = this.state;
    const { id } = investor;

    // Set up Loading
    this.updateInvestor(id, true, false, null, false, false, null, false, null);

    // Post the Ping.
    axios.post('/investors/favorite/add', queryString.stringify({ id }))
      .then((response) => {
        if (response) {
          this.updateInvestor(id, false, true, null, false, false, null, false, null);
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateInvestor(id, false, false, null, false, false, null, false, null);
      });
  };

  removeFavorites = (e) => {
    if (e) { e.preventDefault(); }
    const { investor } = this.state;
    const { id } = investor;

    // Set up Loading
    this.updateInvestor(id, true, true, null, false, null, false, false, null);

    // Post the Ping.
    axios.post('/investors/favorite/remove', queryString.stringify({ id }))
      .then((response) => {
        if (response) {
          this.updateInvestor(id, false, false, null, false, null, false, false, null);
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateInvestor(id, false, true, null, false, null, false, false, null);
      });
  };

  clickNotAFit = (e) => {
    if (e) { e.preventDefault(); }
    // Set up Loading
    const { investor } = this.state;
    const { id } = investor;

    // Set up Loading
    this.updateInvestor(id, false, null, null, false, false, false, true, null);

    // Post the Ping.
    if (!investor.notAFit) {
      axios.post('/investors/notAFit/add', queryString.stringify({ id }))
        .then((response) => {
          if (response) {
            this.updateInvestor(id, false, null, null, false, false, true, false, true);
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
          this.updateInvestor(id, false, null, null, false, null, false, false, false);
        });
    } else {
      axios.post('/investors/notAFit/remove', queryString.stringify({ id }))
        .then((response) => {
          if (response) {
            this.updateInvestor(id, false, null, null, false, null, false, false, false);
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
          this.updateInvestor(id, false, null, null, false, false, false, false, true);
        });
    }
  };

  render = () => {
    const {
      investor, loading, loaded, modal, modalPing, errorMessage, modalTrial,
    } = this.state;

    return (
      <Container>
        {!loaded
          ? (
            <div className={`load${loading ? '' : ' loaded'} inload`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          )
          : (
            <div className="profile">
              <div className="background bg-alt">&nbsp;</div>
              <Row>
                <Col xs={12} lg={1}>
                  <span
                    role="presentation"
                    onMouseDown={e => this.goBack(e)}
                    onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { this.goBack(e); } }}
                    className="back-btn text-white"
                  >
                    <ChevronLeftIcon /> BACK
                  </span>
                </Col>
                <Col xs={12} lg={11}>
                  <ProfileMain
                    firstName={investor.firstName}
                    linkedin={investor.linkedin}
                    lastName={investor.lastName}
                    companyRole={investor.companyRole}
                    company={investor.company}
                    locationCity={investor.location_city}
                    locationCountry={investor.location_country}
                    picture={investor.picture}
                    investmentMin={investor.investmentMin}
                    investmentMax={investor.investmentMax}
                    region={investor.region}
                    visits={investor.visits}
                    sent={investor.sent}
                    opened={investor.opened}
                    registered={investor.registered}
                    adding={investor.adding}
                    favorite={investor.favorite}
                    removing={investor.removing}
                    notAFit={investor.notAFit}
                    renderPingButton={this.renderPingButton()}
                    addFavorites={e => this.addFavorites(e)}
                    removeFavorites={e => this.removeFavorites(e)}
                    clickNotAFit={e => this.clickNotAFit(e)}
                  />
                </Col>
              </Row>
              <ProfileContent
                description={investor.description}
                expertise={investor.expertise}
                investmentMin={investor.investmentMin}
                investmentMax={investor.investmentMax}
                categories={investor.categories}
                stages={investor.stages}
                region={investor.region}
                email={investor.email}
                linkedin={investor.linkedin}
                accepted={investor.accepted}
              />
            </div>
          )
        }
        <ModalTrial
          color="primary"
          title="Your campaigns will start at the end of your trial period"
          colored
          displayed={modalTrial}
          toggleModal={() => this.toggleModalTrial()}
          pings
          message={`You cannot contact investors until the end of your free trial.<br><br>
                 <strong><u>To contact this investor immediately</u></strong>, you can end your trial 
                 and start your paid subscription now.<br /><br>
                 Alternatively, you can wait until the end of your trial period.`}
        />
        <Modal
          color="primary"
          title="Out of Pings!"
          colored
          displayed={modal}
          pings
          message={`You do not have enough Pings left to contact this investor.<br><br>
           To send your deck to this investor's mailbox and pin your profile at the top
           of their dashboard, you need to buy more Pings.<br><br>
           To do so, please visit our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing'>Pricing Page</a>.`}
        />
        <ModalInput
          color="primary"
          title="Send a Message"
          header
          displayed={modalPing}
          toggleModal={() => this.toggleModalPing()}
          ping={val => this.pingInvestor(val)}
          errorMessage={errorMessage}
          investor={investor}
          questions={investor.questions}
          questionsEnabled={investor.questionsEnabled}
          isCustomer={investor.isCustomer}
        />
      </Container>
    );
  }
}

export default withRouter(InvestorProfile);
