import React from 'react';
import {
  Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';

const InfoElement = ({ info }) => (
  <Row className="dashboard-steps elements-cards">
    <Col xs={12} sm={6} lg={4} className="crm-steps">
      <div className="dashboard__step-slide rounded dashboard__step-slide--success default-cursor">
        <img
          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/sent_icon.svg`}
          alt="Sent Icon"
          className="crm-steps-icon"
          id="sent_icon"
        />
        <h2 className="dashboard__step-title">{info.sent}</h2>
        <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
        <h6 className={`dashboard__step-subtitle ${info.lastEmail && 'margin-0'}`}>Emails sent out so far</h6>
        {info.lastEmail
          && (
            <small>The last email was sent out on {info.lastEmail}</small>
          )}
      </div>
    </Col>
    <Col xs={12} sm={6} lg={4} className="crm-steps">
      <div className="dashboard__step-slide rounded dashboard__step-slide--current default-cursor">
        <img
          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/emailstack_icon.svg`}
          alt="Sent Icon"
          className="crm-steps-icon"
        />
        <h2 className="dashboard__step-title">{info.toSend}</h2>
        <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
        <h6 className={`dashboard__step-subtitle ${info.nextEmail && 'margin-0'}`}>Emails to be sent</h6>
        <br className="d-xl-none" />
        {info.nextEmail
        && (
          <small>The next email will be sent out on {info.nextEmail}</small>
        )}
      </div>
    </Col>
    <Col xs={12} sm={12} lg={4} className="crm-steps mt-4 mt-lg-0">
      <div className="dashboard__step-slide rounded dashboard__step-slide--next default-cursor">
        <img
          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/pings_icon.svg`}
          alt="Sent Icon"
          className="crm-steps-icon"
          id="mail_icon"
        />
        <h2 className="dashboard__step-title">{info.pings}</h2>
        <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
        <h6 className="dashboard__step-subtitle">Pings left on your account</h6>
      </div>
    </Col>
  </Row>
);


InfoElement.propTypes = {
  info: PropTypes.shape({
    sent: PropTypes.number,
    toSend: PropTypes.number,
    pings: PropTypes.number,
  }).isRequired,
};


export default InfoElement;
