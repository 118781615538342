/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import DeclineButton from '../../../shared/components/founders/DeclineButton';
import PingButton from '../../../shared/components/founders/PingButton';

const defaultLogo = `${process.env.PUBLIC_URL}/img/nologo.jpg`;

export default class FounderCard extends Component {
  static defaultProps = {
    founder: [],
    connect: null,
    ping: null,
    decline: null,
  };

  shouldComponentUpdate(nextProps) {
    const { founder } = this.props;
    return nextProps.founder !== founder;
  }

  handleClick = () => {
    const scrollYOffset = window.pageYOffset;
    sessionStorage.setItem('scrollYOffsetInt', scrollYOffset);
    sessionStorage.setItem('scrollActiveInt', false);
  }

  render() {
    const {
      ping, connect, founder, decline,
    } = this.props;

    return (
      <div className="col-sm-12 col-md-6 col-lg-4">
        <CardBody className="investor-item col-md-12 p-0" key={founder.id}>
          <Link
            className="investor-item__link"
            to={`/founder/${founder.id}`}
            onClick={this.handleClick}
          >
            {founder.new ? <div className="investor-item__label-new">New</div> : ''}
            <div className="investor-item__img-wrap">
              {/* eslint-disable-next-line max-len */}
              <img
                className="startup-item__img"
                src={founder.logo
                  ? process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_LOGOS_PATH + founder.logo
                  : defaultLogo}
                onError={(e) => { e.target.onerror = null; e.target.src = defaultLogo; }}
                alt="founder-item-img"
              />
            </div>
            <div className="investor-item__info">
              <h4 className="investor-item__title">{founder.name}</h4>
              <h5 className="investor-item__location">
                <MapMarkerIcon />
                {founder.location_city}, {founder.location_country}
              </h5>
            </div>
            <div className="investor-item__info">
              <p className="investor-item__description">{founder.short_description}</p>
            </div>
          </Link>
          <div className="investor-item__actions">
            <DeclineButton
              founder={founder}
              decline={decline}
            />
            <PingButton
              founder={founder}
              ping={ping}
              connect={connect}
            />
          </div>
          <Link
            className="investor-item__link-grey"
            to={`/founder/${founder.id}`}
            onClick={this.handleClick}
          >
            <div className="investor-item__investment-range">
              <p className="investor-item__values">
                {founder.industry} {founder.secondary_industry && `& ${founder.secondary_industry}`}
              </p>
            </div>
          </Link>
        </CardBody>
      </div>
    );
  }
}

FounderCard.propTypes = {
  founder: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    founder_name: PropTypes.string,
    short_description: PropTypes.string,
    logo: PropTypes.string,
    location_city: PropTypes.string,
    location_country: PropTypes.string,
    industry: PropTypes.string,
    raising: PropTypes.number,
    raised: PropTypes.number,
    minimum: PropTypes.number,
    new: PropTypes.bool,
    accepted: PropTypes.bool,
    declined: PropTypes.bool,
    conversation_id: PropTypes.number,
    pinging: PropTypes.bool,
    declining: PropTypes.bool,
    web_plan: PropTypes.bool,
  }),
  connect: PropTypes.func,
  decline: PropTypes.func,
  ping: PropTypes.func,
};
