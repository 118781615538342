import React from 'react';
import { Card } from '@material-ui/core';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import CampaignElement from './CampaignElement';

const ListElements = ({
  campaigns, pausing, archiving, infoLoaded, confirmPause, confirmArchive,
}) => (
  // eslint-disable-next-line no-nested-ternary
  (campaigns && campaigns.length > 0)
    ? (
      <Card elevation={0} className="pt-3">
        <Table responsive hover className="Campaigns__table">
          <thead>
            <tr>
              <th>Campaigns</th>
              <th>Emails Sent/Total</th>
              {/* <th className="d-none d-sm-table-cell">Completion</th> */}
              <th className="d-none d-sm-table-cell">Open Rate</th>
              <th className="d-none d-md-table-cell">Visit Rate</th>
            </tr>
          </thead>
          <tbody>
            { campaigns.map(
              campaign => (
                <CampaignElement
                  campaign={campaign}
                  key={campaign.id}
                  loading={pausing || archiving}
                  infoLoaded={infoLoaded}
                  pause={() => confirmPause(campaign.id)}
                  archive={() => confirmArchive(campaign.id)}
                />
              ),
            )}
          </tbody>
        </Table>
      </Card>
    ) : (
      (pausing || archiving)
        ? (
          <Card elevation={0} className="pt-3">
            <svg className="load__icon">
              <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </Card>
        ) : (
          <Card elevation={0} className="pt-3">
            <h3 className="mt-5 w-100 text-center">You have no campaign in this section.</h3>
          </Card>
        )
    )
);

ListElements.propTypes = {
  pausing: PropTypes.bool.isRequired,
  archiving: PropTypes.bool.isRequired,
  confirmPause: PropTypes.func.isRequired,
  confirmArchive: PropTypes.func.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    paused: PropTypes.bool,
    archived: PropTypes.bool,
    draft: PropTypes.bool,
    emails_sent: PropTypes.number,
    emails_total: PropTypes.number,
    visit_rate: PropTypes.number,
    open_rate: PropTypes.number,
  })).isRequired,
  infoLoaded: PropTypes.bool.isRequired,
};

export default ListElements;
