import React, { PureComponent } from 'react';
import {
  Col, Container,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import queryString from 'query-string';

import PageVisibility from 'react-page-visibility';
import PdfViewer from '../../../shared/components/pdfViewer/PdfViewer';
import ModalInput from '../../../shared/components/modals/SummaryModalInput';
import TopbarWithoutNavigation from '../../Layout/topbar_without_navigation/TopbarWithoutNavigation';


class SummaryDeckPage extends PureComponent {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      visitorId: cookies.get('visitorId') || null,
      loading: true,
      loaded: false,
      askEmail: false,
      url: false,
      focus: true,
      link: null,
      pdfLoaded: true,
      user: JSON.parse(localStorage.getItem('user')),
    };
    this.timer = null;
  }

  componentDidMount = () => {
    this.init();
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  init = () => {
    const { props } = this;
    const { id } = props.match.params;
    let { extInvId } = props.match.params;

    if (typeof extInvId === typeof undefined) {
      extInvId = 0;
    }

    axios.get(`summary/deck/${id}/${extInvId}`)
      .then((response) => {
        if (response.data.split('.').pop() === 'pdf') {
          this.setState({
            url: process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_DECKS_PATH + response.data,
            loading: false,
            link: id,
            error: null,
          });
          this.authenticateVisitor(response.data.authEmail);
        } else {
          this.setState({
            url: response.data.url,
            error: 'This link is not valid',
            loading: false,
          });
        }
        setTimeout(() => this.setState({ loaded: true }), 500);
      })
      .catch((error) => {
        if (error.response
          && error.response.data.message
          && error.response.data.message.length > 0) {
          this.setState({ error: error.response.data.message, loading: false });
        } else {
          this.setState({ error: 'This link is not valid', loading: false });
        }
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
  };

  handleVisibilityChange = (isVisible) => {
    this.setState({ focus: isVisible });
  };

  authenticateVisitor = (authEmail) => {
    const { visitorId, user } = this.state;

    if (visitorId !== null && visitorId !== 'null') {
      this.trackAction();
    } else if (user) {
      this.retrieveVisitorId(user.email);
    } else if (authEmail && !user) {
      // Ask for email
      this.setState({ askEmail: true });
    } else {
      this.retrieveVisitorId(null);
    }
  };

  retrieveVisitorId = (email) => {
    const { cookies } = this.props;
    this.setState({ askEmail: false }); // Hide modal
    axios.post('summary/visitor', queryString.stringify({
      email,
    })).then((response) => {
      if (response.data && response.data.success) {
        cookies.set('visitorId', response.data.id, { path: '/' });
        this.setState({ visitorId: response.data.id }, () => this.trackAction());
      }
    });
  };

  trackAction = () => {
    const { visitorId, link } = this.state;
    axios.post('summary/track/deck', queryString.stringify({
      visitor: visitorId,
      link,
    }));
    this.trackTime();
  };

  trackTime = () => {
    const { visitorId, link } = this.state;

    this.timer = setInterval(() => {
      const { focus, pdfLoaded } = this.state;
      if (focus && pdfLoaded) {
        axios.post('summary/track/timeDeck', queryString.stringify({
          visitor: visitorId,
          link,
        }));
      }
    }, 3000);
  };

  setPdfLoaded = (val) => {
    this.setState({ pdfLoaded: val });
  };

  render() {
    const {
      url, loading, loaded,
      askEmail, error,
    } = this.state;
    const { props } = this;
    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <div>
          <TopbarWithoutNavigation />
          <Container>
            <Link to={`/summary/${props.match.params.id}`}>
              <button
                type="button"
                className="rounded btn btn-outline-secondary back-btn back-btn-fixed"
              >
                <ChevronLeftIcon /> Summary
              </button>
            </Link>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!loaded
              ? (
                <div className={`load${loading ? '' : ' loaded'} inload`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              )
              : error
                ? (
                  <div className="load inload">
                    <div className="load__icon-wrap">
                      {error}
                    </div>
                  </div>
                )
                : (
                  <Col xs={12}>
                    {url
                      ? (
                        <PdfViewer url={url} pdfLoaded={val => this.setPdfLoaded(val)} />
                      )
                      : (
                        <div className="align-content-center">This link is not valid.</div>
                      )}
                  </Col>
                )
            }
            <ModalInput
              title="Please enter your email to review this deck"
              placeholder="john.doe@mail.com"
              displayed={askEmail}
              askEmail
              submit={email => this.retrieveVisitorId(email)}
            />
          </Container>
        </div>
      </PageVisibility>
    );
  }
}

export default withRouter(withCookies(SummaryDeckPage));
