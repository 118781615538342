import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import getPicture from '../../../shared/functions/getPicture';
import { ContactProps, MessageProps } from '../../../shared/prop-types/ChatProps';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

export default class ChatBubble extends PureComponent {
  static propTypes = {
    contact: ContactProps.isRequired,
    message: MessageProps.isRequired,
    active: PropTypes.bool,
  };

  static defaultProps = {
    active: false,
  };

  render() {
    const {
      contact, message, active,
    } = this.props;

    const bubbleClass = classNames({
      chat__bubble: true,
      'chat__bubble--active': active,
    });

    if (message.notification) {
      return (
        <div className={bubbleClass}>
          <div className="chat__bubble-notification-wrap">
            <p className="chat__bubble-message">{message.message}</p>
            <p className="chat__bubble-date">
              {moment(message.date).format('M/D/YY')}
              <br className="d-none d-xl-block" />
              <small className="d-none d-xl-block">
                {moment(message.date).format('LT')}
              </small>
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className={bubbleClass}>
        {active ? (
          <div className="chat__bubble-avatar">
            <img
              src={getPicture(contact)}
              onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
              alt="ava"
            />
          </div>
        ) : (
          <Link
            className="chat__bubble-avatar"
            to={contact.type === 'investor'
              ? `/investor/${contact.participant_id}`
              : `/startup/${contact.participant_id}`}
          >
            <img
              src={getPicture(contact)}
              onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
              alt="ava"
            />
          </Link>
        )}
        {message.file
          ? (
            <div className="chat__bubble-message-wrap chat__bubble-message-wrap--file">
              <p className="chat__bubble-contact-name">{contact.name}</p>
              <a
                href={
                  process.env.REACT_APP_AWS_ROOT
                  + process.env.REACT_APP_AWS_FILES_PATH
                  + encodeURI(message.message.replace('File Uploaded: ', ''))
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/file-icon.png`} alt={message.message} />
              </a>
              <p className="chat__bubble-date">
                {moment(message.date).format('M/D/YY')}
                <br className="d-none d-xl-block" />
                <small className="d-none d-xl-block">
                  {moment(message.date).format('LT')}
                </small>
              </p>
              <div className="chat__bubble-download-wrap">
                <p className="chat__bubble-file-name">
                  <span>{message.message}</span>
                  <span>({message.file.size})</span>
                </p>
                {/* <a className="chat__bubble-download" href="#" download>Download</a> */}
              </div>
            </div>
          )
          : (
            <div className="chat__bubble-message-wrap">
              <p className="chat__bubble-contact-name">{contact.name}</p>
              <p className="chat__bubble-message">{message.message}</p>
              <p className="chat__bubble-date">
                {moment(message.date).format('M/D/YY')}
                <br className="d-none d-xl-block" />
                <small className="d-none d-xl-block">
                  {moment(message.date).format('LT')}
                </small>
              </p>
            </div>
          )
         }
      </div>
    );
  }
}
