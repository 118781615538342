import React from 'react';
import {
  Badge, Card, CardBody,
} from 'reactstrap';

const PanelFirstName = () => (
  <Card className="panel panel--divider card shadow mb-3">
    <CardBody className="panel__body">
      <div className="panel__title no-padding">
        <h5 className="bold-text">
          <Badge className="panel__label">Improvement</Badge>
          &nbsp;&nbsp;&nbsp;
          Add the name of the investor to your email
        </h5>
        {/* <h5 className="subhead">{subhead}</h5> */}
      </div>
      <div className="panel__content">
        <p className="typography-message">
          You should add the variable <strong>&#123;&#123; investorFirstName &#125;&#125;</strong> to your templates in
          order to use the name of the investor in your email.
        </p>
        <p><strong>For Example: </strong> Hi &#123;&#123; investorFirstName &#125;&#125;...</p>
        <p className="typography-message">
          We recommend using as much personalisation as possible to increase the likelihood of receiving a response
          back. Having analysed thousands of campaigns, our research demonstrated that templates using all 4 of our
          variables <strong><u>perform 75% better</u></strong> than campaigns that do not incorporate them.
        </p>
      </div>
    </CardBody>
  </Card>
);

export default PanelFirstName;
