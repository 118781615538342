import React from 'react';
import { Card } from '@material-ui/core';
import {
  Button, Col, Row,
} from 'reactstrap';
import RightChevronIcon from 'mdi-react/ChevronRightIcon';
import PropTypes from 'prop-types';
import YoutubeEmbed from '../../../shared/components/YoutubeEmbed';

const FillerElement = ({ onClick }) => (
  <Card elevation={0} className="Campaigns__empty">
    <Row>
      <Col xs={12}>
        <h3 className="mb-4 mt-5 text-center">
          Connect with investors with <strong>Warm Introductions</strong> from your linkedin network.
          <br />
          We help you discover your Linkedin First degree connections you have, who can connect you to the right
          investors.
        </h3>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <div className="Campaigns__steps Campaigns__step-1">
              <h2>Step 1</h2>
              <h3>Select investors</h3>
              <p>
                Select the investors you would like to be introduced to.
                We will automatically find the best introduction path to these investors.
              </p>
              <Button onClick={onClick} className="icon icon--right rounded" color="white" outline>
                Get Started!
                <RightChevronIcon />
              </Button>
            </div>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <div className="Campaigns__steps Campaigns__step-2">
              <h2>Step 2</h2>
              <h3>Install & Run the plugin</h3>
              <p>
                Install the plugin and run it on your LinkedIn profile.
                It will scan your linkedin network and find the best introduction path to your selected investors.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 0 }}>
            <div className="Campaigns__steps Campaigns__step-3">
              <h2>Step 3</h2>
              <h3>Review Connections</h3>
              <p>
                Review the connections we found and select the ones you would like to contact for an introduction.
                We will automatically send them a message on linkedin via automated campaigns.
              </p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
        <YoutubeEmbed embedId="OSy6gMmUEDs" />
        <br />
      </Col>
    </Row>
  </Card>
);

FillerElement.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FillerElement;
