import React from 'react';
import { Card } from '@material-ui/core';
import {
  Button, Col, Row,
} from 'reactstrap';
import RightChevronIcon from 'mdi-react/ChevronRightIcon';
import { Link } from 'react-router-dom';
import YoutubeEmbed from '../../../shared/components/YoutubeEmbed';
// import YoutubeEmbed from '../../../shared/components/YoutubeEmbed';

const FillerElement = () => (
  <Card elevation={0} className="Campaigns__empty">
    <Row>
      <Col xs={12}>
        <h3 className="mb-4 mt-5 text-center">
          Angels Partners Founders Community is here to help you connect with other founders currently
          fundraising, so you can help each other with warm introductions.<br /><br />
        </h3>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <div className="Campaigns__steps Campaigns__step-1">
              <h2>Step 1</h2>
              <h3>Search For Relevant Introductions</h3>
              <p>
                <br />
                Search for <strong>relevant founders in your industry and location</strong>.
              </p>
              <p>
                You can search for companies in your industry who are not direct competitors, they might know investors
                who could be a good fit for you.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <div className="Campaigns__steps Campaigns__step-2">
              <h2>Step 2</h2>
              <h3>Talk to Startup Founders</h3>
              <p>
                <br /><br />
                Talk to startup founders and see if you can help each other with warm introductions.
              </p>
              <p>
                Be mindful and connect on a personal level via video or in person before you ask anything.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 0 }}>
            <div className="Campaigns__steps Campaigns__step-3">
              <h2>Start Now!</h2>
              <h3>Fill out your Founder Message</h3>
              <p>
                To start connecting with other founders, you need to fill out your Founder Message from your&nbsp;
                <Link to="/myProfile/founder" className="text-white">Profile &#62; Community</Link>.<br />
                This message will be shared with other founders to tell them about what type of introductions you are
                looking for and what you can offer in return.
              </p>
              <Link to="/myProfile/founder">
                <Button className="icon icon--right rounded" color="white" outline>
                  Get Started!
                  <RightChevronIcon />
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
        <YoutubeEmbed embedId="oVHbXg76Ee0" />
        <br />
      </Col>
    </Row>
  </Card>
);

export default FillerElement;
