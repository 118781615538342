import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, Nav, NavItem, NavLink, TabContent, TabPane, Alert,
} from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import ChevronRight from 'mdi-react/ChevronRightIcon';

class ModalTemplates extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    onTemplateSelected: PropTypes.func.isRequired,
  };

  static defaultProps = {
    displayed: false,
  };

  counter = 1;

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      templates: null,
      loading: false,
      loaded: false,
      activeTab: 0,
    };

    this.toggle = this.toggle.bind(this);
    this.onTemplateSelected = this.onTemplateSelected.bind(this);
  }

  componentDidMount() {
    this.retrieveTemplates();
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  onTemplateSelected = () => {
    const { onTemplateSelected } = this.props;
    const { activeTab, templates } = this.state;

    onTemplateSelected(templates[activeTab].title, templates[activeTab].content);
    this.toggle();
  }

  retrieveTemplates = () => {
    this.setState({ loading: true, loaded: false });
    axios.get('/campaigns/templates/default')
      .then((response) => {
        if (response.data && response.data.length > 0) {
          this.setState({
            templates: response.data,
            loading: false,
            loaded: true,
          });
        } else {
          this.setState({
            templates: null,
            loading: false,
            loaded: true,
          });
        }
      })
      .catch(() => {
        this.setState({
          templates: null,
          loading: false,
          loaded: true,
        });
      });
  }

  toggle = () => {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  };

  toggleTabs = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  buildSentence = () => {
    if (this.counter === 1) {
      this.counter += 1;
      return (<span><strong>First Follow Up</strong> Option 1</span>);
    }
    return (<span><strong>First Follow Up</strong> Option 2</span>);
  }

  render() {
    const {
      modal, activeTab, templates, loaded, loading,
    } = this.state;
    const width = window.innerWidth;

    this.counter = 1;
    const templatesTitles = templates ? templates.map((t, i) => (
      <NavItem key={t.id}>
        <NavLink
          className={classnames({ active: activeTab === i })}
          onClick={() => {
            this.toggleTabs(i);
          }}
        >
          {t.position === 0
            && (
              <span><strong>Initial Email</strong> - {t.campaign}</span>
            )}
          {t.position === 1 && this.buildSentence()}
          {t.position === 2 && (
            <strong>Second Follow Up</strong>
          )}
        </NavLink>
      </NavItem>
    )) : null;

    const templatesTabs = templates ? templates.map((t, i) => (
      <TabPane tabId={i} key={t.id} className="x-padding text-left">
        <div>
          <br />
          <br />
          <h4>{t.title}</h4>
          <br />
          <br />
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: t.content }} />
        </div>
      </TabPane>
    )) : null;

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className="modal-dialog--primary modal-templates xx-large"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            <h4>E-Mails Templates</h4>
            <img src={`${process.env.PUBLIC_URL}/img/line.svg`} alt="underline" className="header__line" />
          </div>
          <Alert className="alert--colored modal__template-alert" color="info">
            <p className="alert__content">
              These templates have been written by our co-founder and ex-VC Yoann Berno with the goal to catch the
              investor&#39;s interest, and maximise your response rate.
              <br /><br />
              <small>We recommend editing these templates to make them your own.</small>
              <br /><br />
              <small>
                <a
                  href="https://angelspartners.com/blog/how-to-write-an-email-investors-will-actually-read-in-10-steps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  For more info, please read this article
                </a>
              </small>
            </p>
          </Alert>
          <div className="modal__tabs">
            {!loaded
            && (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            )}
            {loaded && templates
            && (
              <div>
                <div className={`tabs ${width >= 800 ? 'tabs--vertical' : 'tabs--horizontal tabs--bordered-bottom'}`}>
                  <div className="tabs__wrap">
                    <Nav tabs>
                      {templatesTitles}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      {templatesTabs}
                    </TabContent>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal__footer">
            <Button
              className="rounded icon float-right"
              color="primary"
              onClick={() => this.onTemplateSelected()}
            >
              <p>
                Select
                <ChevronRight />
              </p>
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalTemplates;
