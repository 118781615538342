import React from 'react';
import {
  Container,
} from 'reactstrap';
import ManagedStartupsList from './components/List';

const ManagedStartups = () => (
  <Container>
    <ManagedStartupsList />
  </Container>
);

export default ManagedStartups;
