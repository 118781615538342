import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import HelpCircleIcon from 'mdi-react/HelpCircleIcon';
import timezones from '../../../shared/variables/timzones';
import renderSelectField from '../../../shared/components/form/Select';
import renderTimePickerField from '../../../shared/components/form/TimePicker';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import validate from '../formValidate/formThree';
import Alert from '../../../shared/components/alerts/Alert';
import Collapse from '../../../shared/components/Collapse';
import renderToggleButtonField from '../../../shared/components/form/ToggleButton';

const renderTextField = ({
  input, placeholder, type, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} value={input.value} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTextField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

const WizardFormThree = ({
  handleSubmit, previousPage, onSubmit, loading, initialValues,
}) => (
  <form
    className="form form--horizontal wizard__form wizard__form__campaign wizard__form__step-three"
    onSubmit={handleSubmit(onSubmit.bind(this))}
  >
    <div className="wizard__form__header">
      <h3 className="wizard__title">Schedule your campaign</h3>
      <p className="wizard__subtitle">
        Emails will be sent on a daily basis based on the numbers of follow ups and schedules,
        with a maximum of <strong>50 emails/day</strong> spaced out by a few minutes in order to maximize your
        deliverability rate and prevent spam.
      </p>
    </div>
    <div className="form__form-group mb-3 mt-3">
      <br />
      <div className="form__form-group time">
        <span className="form__form-group-label mb-5 mb-md-0">
          <h4 className="text-darker">PICK A TIME</h4>
        </span>
        <Field
          name="time"
          component={renderTimePickerField}
          timeMode
        />
      </div>
    </div>
    <hr className="my-4" />
    <br />
    <div className="form__form-group mb-3 mt-3">
      <br />
      <div className="form__form-group time">
        <span className="form__form-group-label mb-5 mb-md-0">
          <h4 className="text-darker">TIMEZONE</h4>
        </span>
        <Field
          name="timezone"
          component={renderSelectField}
          type="text"
          options={timezones}
          placeholder="Choose Timezone"
        />
      </div>
    </div>
    <hr className="my-4" />
    <div className="form__form-group">
      <span className="form__form-group-label medium">
        <h4 className="text-darker">SEND EMAILS<br />ON THESE DAYS</h4>
      </span>
      <ul className="list-inline days-list mb-5">
        <li className="list-inline-item">
          <div className="form__form-group-field days">
            <Field
              name="sendOnMonday"
              component={renderCheckBoxField}
              label="MON"
              defaultChecked={initialValues ? initialValues.sendOnMonday : 1}
              className="colored-click"
            />
          </div>
        </li>
        <li className="list-inline-item">
          <div className="form__form-group-field days">
            <Field
              name="sendOnTuesday"
              component={renderCheckBoxField}
              label="TUE"
              defaultChecked={initialValues ? initialValues.sendOnTuesday : 1}
              className="colored-click"
            />
          </div>
        </li>
        <li className="list-inline-item">
          <div className="form__form-group-field days">
            <Field
              name="sendOnWednesday"
              component={renderCheckBoxField}
              label="WED"
              defaultChecked={initialValues ? initialValues.sendOnWednesday : 1}
              className="colored-click"
            />
          </div>
        </li>
        <li className="list-inline-item">
          <div className="form__form-group-field days">
            <Field
              name="sendOnThursday"
              component={renderCheckBoxField}
              label="THU"
              defaultChecked={initialValues ? initialValues.sendOnThursday : 1}
              className="colored-click"
            />
          </div>
        </li>
        <li className="list-inline-item">
          <div className="form__form-group-field days">
            <Field
              name="sendOnFriday"
              component={renderCheckBoxField}
              label="FRI"
              defaultChecked={initialValues ? initialValues.sendOnFriday : 1}
              className="colored-click"
            />
          </div>
        </li>
        <li className="list-inline-item">
          <div className="form__form-group-field days">
            <Field
              name="sendOnSaturday"
              component={renderCheckBoxField}
              label="SAT"
              defaultChecked={initialValues ? initialValues.sendOnSaturday : 0}
              className="colored-click"
            />
          </div>
        </li>
        <li className="list-inline-item">
          <div className="form__form-group-field days">
            <Field
              name="sendOnSunday"
              component={renderCheckBoxField}
              label="SUN"
              defaultChecked={initialValues ? initialValues.sendOnSunday : 0}
              className="colored-click"
            />
          </div>
        </li>
      </ul>
      <Alert color="info" className="alert--colored more-info mt-3">
        <div>
          <p>
            <strong>Tip:</strong> Schedule your campaign early in the week and early in the day to maximize open
            rates (i.e Monday/Tuesday 8am-11am on the optimal timezone) <br />
            Avoid sending emails on weekends.
          </p>
        </div>
      </Alert>
      <hr className="my-4" />
      <Collapse title="Advanced Options" className="form__advanced-options float-right">
        <div className="form__form-group mt-4">
          <h4 className="text-darker">
            ENABLE EMAIL WARMUP &nbsp;&nbsp;
            <span id="warmup" className="text-black-50"><HelpCircleIcon /></span>
          </h4>
          <div className="form__form-group-field large">
            <Field
              name="emailWarmup"
              component={renderToggleButtonField}
              defaultChecked={initialValues ? initialValues.emailWarmup : false}
            />
          </div>
        </div>
        <UncontrolledTooltip target="warmup">
          Enable Email Warmup if your mailbox has been created recently, or if you send only a few emails each day.
          It is recommended to enable this option to increase your deliverability for large scale campaigns with
          hundreds of investors. We will start by sending 20 emails/day the first week, and increase this number
          progressively to 50 over 3 weeks.
        </UncontrolledTooltip>
      </Collapse>
      <div className="form__button-toolbar wizard__toolbar container-fluid">
        <Button className="next btn btn-success icon rounded float-right" type="submit">
          <p>
            SUBMIT CAMPAIGN
            {loading ? <img src="/img/loading.gif" alt="loading" className="loading" /> : <ChevronRightIcon />}
          </p>
        </Button>
        <Button
          color="primary"
          type="button"
          className="previous icon rounded float-left"
          onClick={previousPage}
        >
          <p>
            <ChevronLeftIcon />
            BACK
          </p>
        </Button>
      </div>
    </div>
  </form>
);

WizardFormThree.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    emailWarmup: false,
  }),
};

WizardFormThree.defaultProps = {
  initialValues: null,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(WizardFormThree);
