import React from 'react';
import {
  Card, Col, Container, Row,
} from 'reactstrap';
import InvestorsList from './components/List';
import SearchFilters from './components/SearchFilters';

const Investors = () => (
  <Container>
    <Row>
      <Col xs={12} md={3} xl={2}>
        <h3 className="page-title">Investor Community</h3>
      </Col>
      <Col xs={12} md={9} lg={8} xl={7}>
        <p className="page-description">
          <strong>Browse our community of investors</strong><br />
          The profiles listed in this section are investors who previously registered on our platform and are
          actively investing.<br />
          Investors, as well as startups, who did not connect on the platform for over a month are
          automatically deactivated to keep the community dynamic and alive.
        </p>
      </Col>
    </Row>
    <Row>
      <Col xs={12} lg={12} xl={11} className="mt-5 mt-md-4 mt-lg-3 p-0 p-md-3 p-lg-5 offset-md-05">
        <Card className="p-0">
          <div className="card__title mb-3">
            <h5 className="bold-text">Filter Investors</h5>
          </div>
          <SearchFilters />
        </Card>
      </Col>
    </Row>
    <InvestorsList />
  </Container>
);

export default Investors;
