import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import AvTimerIcon from 'mdi-react/AvTimerIcon';
import classNames from 'classnames';

class TimePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    timeMode: PropTypes.bool.isRequired,
  };

  state = {
    open: false,
  };

  setOpen = ({ open }) => {
    this.setState({ open });
  };

  toggleOpen = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render() {
    const {
      // eslint-disable-next-line react/prop-types
      name, onChange, timeMode, value,
    } = this.props;
    const { open } = this.state;
    const btnClass = classNames({
      'form__form-group-button': true,
      active: open,
    });

    const date = new Date(`2020/10/15 ${value || '9:00:00'}`);
    const time = moment.isMoment(value) ? value : moment(date);

    return (
      <div className="form__form-group-field">
        <TimePicker
          open={open}
          onOpen={this.setOpen}
          onClose={this.setOpen}
          name={name}
          onChange={onChange}
          showSecond={false}
          use12Hours={timeMode}
          defaultValue={value && value !== '' ? time : null}
          /* eslint-disable-next-line no-nested-ternary */
          value={value && moment.isMoment(value) ? value : value !== '' ? time : null}
          className="timePicker"
          // value={value}
        />
        <button
          className={btnClass}
          type="button"
          onClick={this.toggleOpen}
          style={{
            height: '50px',
            width: '50px',
            border: 'none',
            background: 'transparent',
            marginLeft: '-50px',
            zIndex: '80',
          }}
        >
          <AvTimerIcon
            style={{
              height: '20px',
              width: '20px',
              fill: '#67BAEB',
            }}
          />
        </button>
      </div>
    );
  }
}

const renderTimePickerField = (props) => {
  const {
    input, timeMode, meta: { touched, error },
  } = props;

  return (
    <div className="form__form-group-input-wrap time-input">
      <TimePickerField
        {...input}
        timeMode={timeMode}
      />
      {touched && error && <span className="form__form-group-error text-right">{error}</span>}
    </div>
  );
};

renderTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  timeMode: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTimePickerField.defaultProps = {
  timeMode: false,
  meta: null,
};

export default renderTimePickerField;
