import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {
  Row, Col, Button,
} from 'reactstrap';
import NotificationSystem from 'rc-notification';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import HandshakeIcon from 'mdi-react/HandshakeIcon';
import InvestorBox from './InvestorBox';
import EmptyResults from './EmptyResults';

import {
  FullWideNotification, BasicNotification, LinkNotification,
} from '../notifications/Notification';
import CustPag from '../pagination/Pagination';
import Modal from '../modals/Modal';
import ModalFundSelectInvestors from '../modals/ModalFundSelectInvestors';
import ModalCampaigns from '../modals/ModalSingleInvestorCampaign';
import ModalReport from '../modals/ModalReport';
import AccountWarnings from '../AccountWarnings';
import ModalMultiFundSelectInvestors from '../modals/ModalMultiFundSelectInvestors';
import ModalMultiUpdateExtInvestors from '../modals/ModalMultiUpdateExtInvestors';
import ModalFindIntro from '../modals/ModalFindIntro';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({
  notification,
  position,
}, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: {
          top: 30,
          left: 'calc(100vw - 100%)',
        },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: {
          top: 0,
          left: 0,
        },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class List extends Component {
  static propTypes = {
    closeTour: PropTypes.func,
    nextStep: PropTypes.func,
    getInvestorProspects: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    getInvestorProspects: null,
    nextStep: null,
    closeTour: null,
  };

  constructor(props) {
    super(props);
    const initialNumberOfInvestors = 1000;
    const initialRowsCount = 25;
    const initialPageNumber = 1;
    // eslint-disable-next-line react/prop-types
    const { location } = props;

    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page'), 10);

    this.state = {
      investors: [],
      funds: null,
      investorsContactInfo: [],
      error: null,
      pageOfItems: page && page > 1 ? page : initialPageNumber,
      itemsToShow: initialRowsCount,
      itemsCount: initialNumberOfInvestors,
      showPagination: false,
      loading: true,
      loaded: false,
      modalUpgrade: false,
      modalInvestors: false,
      modalMultiInvestors: false,
      modalCampaigns: false,
      modalMultiInvestorsCampaigns: false,
      modalReport: false,
      modalIntroChoice: false,
      selectedInvestor: null,
      extInvestorContactInfo: null,
      openNextModal: null,
      campaignId: null,
      user: null,
      smartVC: false,
      external: true,
      queryUrl: '',
    };
  }

  componentWillReceiveProps(newProps) {
    const { props } = this;
    // eslint-disable-next-line react/prop-types
    if (props.location.search !== newProps.location.search) {
      this.setState({
        investors: [],
        loading: true,
        loaded: false,
      }, () => this.getInvestors(true));
    }
  }

  componentDidMount = () => {
    this.getInvestors(false);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user }, () => this.updateUser(user));
  };

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  // eslint-disable-next-line no-unused-vars
  updateUser = (user) => {
    axios.get('user/numberOfActiveCampaigns')
      .then((response) => {
        if (response.data && response.data !== user.campaigns) {
          const updatedUser = { ...user, campaigns: response.data };
          localStorage.setItem('user', JSON.stringify(updatedUser));
          this.setState({ user: updatedUser });
        }
      });
  }

  getInvestors = (scrollToTop) => {
    const { props } = this;
    const { investors } = this.state;
    const {
      history,
      location,
    } = props;

    let query = null;
    if (typeof location.state !== typeof undefined && typeof location.state.investorId !== typeof undefined
      && typeof location.state.campaignId !== typeof undefined) {
      // If we are coming from the create a campaign flow after the user pre-selected an investor
      query = this.getQuery(
        null, location.state.pageOfItems, location.state.keywords, location.state.location,
        location.state.industry, location.state.isFirm, location.state.filters, location.state.stage,
        location.state.searchType,
      );
    } else {
      query = this.getQuery(new URLSearchParams(props.location.search));
    }

    if (investors.length === 0) {
      axios.get(`/investors-db/list?${query}`)
        .then((response) => {
          if (response.data.external) {
            const newInvestors = response.data.investors.map(investor => ({
              ...investor,
              adding: false,
            }));
            this.setState({
              investors: newInvestors,
              loading: false,
              error: null,
            }, () => {
              const scrollYOffsetExt = parseInt(sessionStorage.getItem('scrollYOffsetExt'), 10);
              const scrollActiveExt = JSON.parse(sessionStorage.getItem('scrollActiveExt'));

              if (scrollToTop) {
                window.scrollTo(0, 0);
              } else if (scrollYOffsetExt > 0 && scrollActiveExt) {
                sessionStorage.setItem('scrollYOffsetExt', 0);
                sessionStorage.setItem('scrollActiveExt', false);

                let x = 0;
                const refreshIntervalId = setInterval(() => {
                  x += 1;
                  if (x > 30) clearInterval(refreshIntervalId);
                  const newScrollYOffset = window.pageYOffset;
                  if (newScrollYOffset !== scrollYOffsetExt) {
                    window.scrollTo({ top: scrollYOffsetExt, behavior: 'auto' });
                    // clearInterval(refreshIntervalId);
                  }
                }, 50);
              }
            });
            setTimeout(() => this.setState({ loaded: true }, () => {
              if (typeof location.state !== typeof undefined && typeof location.state.investorId !== typeof undefined
                && typeof location.state.contactId !== typeof undefined
                && typeof location.state.campaignId !== typeof undefined
              ) {
                this.setState({
                  loaded: true,
                  campaignId: location.state.campaignId,
                }, () => {
                  // Add the investor previously selected to the campaign newly created
                  this.clickAddToCampaign(
                    location.state.investorId, location.state.contactId, null, null, location.state.campaignId,
                  );
                });
              }
            }), 500);

            if (window.location.pathname.indexOf('investors-db') === 0) {
              history.push(`/investors-db/?${query}`);
            }
          } else {
            this.setState({
              investors: null, error: response.data.message, loading: false, external: response.data.external,
            });
            setTimeout(() => this.setState({ loaded: true }), 500);
            if (scrollToTop) window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && error.response.status === 402) {
            this.toggleModalUpgrade();
          }
          if (typeof error.response === 'object' && typeof error.response.data === 'object') {
            this.setState({
              investors: null,
              error: error.response.data.message,
              loading: false,
            });
          }
          // Remove loading screen
          setTimeout(() => this.setState({ loaded: true }), 500);
          if (scrollToTop) window.scrollTo(0, 0);
        });
    }
    this.getInvestorsCount(query);
  };

  getInvestorsCount = (query) => {
    axios.get(`investors-db/count?${query}`)
      .then((response) => {
        this.setState({
          itemsCount: response.data,
          showPagination: true,
        });
      });
  };

  getQuery = (query, pageOfItems, keywords, location, industry, isFirm, filters, stage, searchType) => {
    const { props } = this;
    let queryUrl = '';

    if (pageOfItems > 0) {
      queryUrl += `page=${pageOfItems}`;
      this.setState({ pageOfItems });
    } else if (query.get('page')) {
      queryUrl += `page=${query.get('page')}`;
      this.setState({ pageOfItems: parseInt(query.get('page'), 10) });
    } else {
      queryUrl += 'page=1';
    }

    if (keywords) {
      queryUrl += `&keywords=${keywords}`;
    } else if (query && query.get('keywords')) {
      queryUrl += `&keywords=${query.get('keywords')}`;
    }
    if (filters) {
      queryUrl += `&filters=${filters}`;
    } else if (query && query.get('filters')) {
      queryUrl += `&filters=${query.get('filters')}`;
    }
    if (location) {
      queryUrl += `&location=${location}`;
    } else if (query && query.get('location')) {
      queryUrl += `&location=${query.get('location')}`;
    }
    if (industry) {
      queryUrl += `&industry=${industry}`;
    } else if (query && query.get('industry')) {
      queryUrl += `&industry=${query.get('industry')}`;
    }
    if (isFirm) {
      queryUrl += `&isFirm=${isFirm}`;
    } else if (query && query.get('isFirm')) {
      queryUrl += `&isFirm=${query.get('isFirm')}`;
    }
    if (stage) {
      queryUrl += `&stage=${stage}`;
    } else if (query && query.get('stage')) {
      queryUrl += `&stage=${query.get('stage')}`;
    }
    if (searchType) {
      queryUrl += `&searchType=${searchType}`;
    } else if (query && query.get('searchType')) {
      queryUrl += `&searchType=${query.get('searchType')}`;
    }

    if (props.location.pathname === '/warm-intro') {
      queryUrl += '&linkedin=1';
    }

    this.setState({ queryUrl });

    return queryUrl;
  };

  onChangePage = (pageOfItems) => {
    const { props } = this;

    const query = this.getQuery(new URLSearchParams(props.location.search), pageOfItems);
    this.setState({
      pageOfItems,
    }, () => props.history.push({
      pathname: props.location.pathname,
      search: `?${query}`,
    }));
  };

  goToIntPage = () => {
    const { props } = this;
    const query = this.getQuery(new URLSearchParams(props.location.search));
    this.setState({ pageOfItems: 1 },
      () => props.history.push({ pathname: '/investors', search: `?${query}` }));
  }

  findIntro = (id, e) => {
    if (e) { e.preventDefault(); }

    this.updateListOfInvestor(id, false, false, null, null, true);
    axios.post('/introductions/addOne', queryString.stringify({ id }))
      .then((response) => {
        if (response) {
          this.showNotifSuccess('This investor has been added to your prospects list');
          this.updateListOfInvestor(id, false, false, null, true, false);
        }
      })
      .catch((error) => {
        if (error && error.response) {
          // Do something error.response.data.message ?
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
      });
  }

  clickAddToCampaign = (id, contactId, email, e) => {
    if (e) { e.preventDefault(); }

    const { investors } = this.state;
    const { closeTour } = this.props;
    // find the index of this investor
    const investorIndex = investors.findIndex(i => i.id === id);
    if (typeof closeTour === 'function') closeTour();

    if (contactId) {
      this.setState(
        {
          extInvestorContactInfo:
            {
              contactId,
              fullName: investors[investorIndex].fullName,
              investorId: id,
              email: email || investors[investorIndex].email,
              picture: investors[investorIndex].picture,
              inCampaign: investors[investorIndex].inCampaign,
              campaignName: investors[investorIndex].campaignName,
              campaignId: investors[investorIndex].campaignId,
              contacted: investors[investorIndex].contacted,
              contactedOn: investors[investorIndex].contactedOn,
            },
        }, () => this.setState({ modalCampaigns: true }),
      );
    } else {
      this.setState({
        modalInvestors: true,
        openNextModal: 'modalCampaigns',
        investorsContactInfo: investors[investorIndex].connectedInvestors,
        selectedInvestor: investors[investorIndex].id,
        fundName: investors[investorIndex].fullName,
        fundPicture: investors[investorIndex].picture,
      });
    }
  };

  clickReportInvestor = (id, contactId, email, e) => {
    if (e) { e.preventDefault(); }

    const { investors } = this.state;
    const { closeTour } = this.props;
    // find the index of this investor
    const investorIndex = investors.findIndex(i => i.id === id);
    if (typeof closeTour === 'function') {
      closeTour();
    }

    if (contactId) {
      this.setState(
        {
          extInvestorContactInfo:
            {
              contactId,
              fullName: investors[investorIndex].fullName,
              investorId: id,
              email: email || investors[investorIndex].email,
              inCampaign: investors[investorIndex].inCampaign,
              campaignName: investors[investorIndex].campaignName,
              campaignId: investors[investorIndex].campaignId,
              contacted: investors[investorIndex].contacted,
              contactedOn: investors[investorIndex].contactedOn,
            },
        }, () => this.setState({ modalReport: true }),
      );
    } else {
      this.setState({
        modalInvestors: true,
        openNextModal: 'modalReport',
        investorsContactInfo: investors[investorIndex].connectedInvestors,
        selectedInvestor: investors[investorIndex].id,
      });
    }
  };

  clickFavorites = (id, e) => {
    if (e) { e.preventDefault(); }

    // Set up Loading
    const { state } = this;
    const investors = [...state.investors];

    // Set up Loading
    const objIndex0 = this.updateListOfInvestor(id, true, null, null, null, false);

    // Post the Ping.
    if (!investors[objIndex0].favorite) {
      axios.post('/investors-db/favorite/add', queryString.stringify({ id }))
        .then((response) => {
          if (response) {
            this.updateListOfInvestor(id, false, true, null, null, false);
          }
        })
        .catch((error) => {
          if (error && error.response) {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
          this.updateListOfInvestor(id, false, false, null, null, false);
        });
    } else {
      axios.post('/investors-db/favorite/remove', queryString.stringify({ id }))
        .then((response) => {
          if (response) {
            this.updateListOfInvestor(id, false, false, null, null, false);
          }
        })
        .catch((error) => {
          if (error && error.response) {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
          this.updateListOfInvestor(id, false, true, null, null, false);
        });
    }
  };

  updateMultipleListOfInvestors = (id, adding, favorite, inCampaign, success, contactedInvestors) => {
    // Set up Loading
    const { state } = this;
    // const investors = [...state.investors];
    const updatedInvestorsList = [...state.investors];
    // eslint-disable-next-line no-plusplus
    for (let invIdIndex = 0; invIdIndex < contactedInvestors.length; invIdIndex++) {
      // find the index of this investor
      let objIndex = updatedInvestorsList.findIndex(i => i.id === contactedInvestors[invIdIndex]);

      if (objIndex === -1) {
        objIndex = updatedInvestorsList.findIndex(
          i => i.connectedInvestors.findIndex(c => c.id === contactedInvestors[invIdIndex]) !== -1,
        );
      }

      // Sort out Favorite and InCampaign
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (favorite === null) favorite = updatedInvestorsList[objIndex].favorite;
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (inCampaign === null) inCampaign = updatedInvestorsList[objIndex].inCampaign;

      // Update Investor
      const updatedInvestor = {
        ...updatedInvestorsList[objIndex],
        adding,
        favorite,
        inCampaign,
      };
      updatedInvestorsList[objIndex] = updatedInvestor;
    }
    // Update the State
    this.setState({ investors: updatedInvestorsList });
  }

  updateListOfInvestor = (id, adding, favorite, inCampaign, isProspect, addingProspect) => {
    // Set up Loading
    const { state } = this;
    const investors = [...state.investors];
    let updatedInvestorsList = [];

    // find the index of this investor
    const objIndex = investors.findIndex(i => i.id === id);
    if (typeof investors[objIndex] !== typeof undefined) {
      // Sort out Favorite and InCampaign
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (favorite === null) favorite = investors[objIndex].favorite;
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (inCampaign === null) inCampaign = investors[objIndex].inCampaign;
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (isProspect === null) isProspect = investors[objIndex].isProspect;

      // Update Investor
      const updatedInvestor = {
        ...investors[objIndex],
        adding,
        favorite,
        inCampaign,
        isProspect,
        addingProspect,
      };

      // Make new list of investors
      updatedInvestorsList = [
        ...investors.slice(0, objIndex),
        updatedInvestor,
        ...investors.slice(objIndex + 1),
      ];

      // Update the State
      this.setState({ investors: updatedInvestorsList });

      return objIndex;
    }
    return null;
  };

  // This function updates le list of contact info for funds
  updateContactInfo = (selectedInvestor, idContact, inCampaign, smartVC, VCsInFund) => {
    // Set up Loading
    const { state } = this;
    const investors = [...state.investors];
    const { openNextModal } = state;

    // Check if this is a smart VC call or not
    if (openNextModal === 'modalCampaigns' && smartVC) {
      // if we selected smartVC
      this.setState({
        modalMultiInvestorsCampaigns: true,
        investorsContactInfo: VCsInFund,
        smartVC: true,
      });
    } else {
      // find the index of this investor
      const invIndex = investors.findIndex(i => i.id === selectedInvestor);

      // Retrieve the contacts of this investor
      const connectedInvestors = [...investors[invIndex].connectedInvestors];

      const contactIndex = connectedInvestors.findIndex(i => i.contactId === idContact);

      // Update Contact
      const updatedContacts = {
        ...connectedInvestors[contactIndex],
        inCampaign,
      };

      // Make new list of contacts
      const updatedContactsList = [
        ...connectedInvestors.slice(0, contactIndex),
        updatedContacts,
        ...connectedInvestors.slice(contactIndex + 1),
      ];

      const updatedInvestor = {
        ...investors[invIndex],
        connectedInvestors: updatedContactsList,
      };

      // Make new list of investors
      const updatedInvestorsList = [
        ...investors.slice(0, invIndex),
        updatedInvestor,
        ...investors.slice(invIndex + 1),
      ];

      // Update the State
      if (openNextModal === 'modalCampaigns') {
        this.setState({
          investors: updatedInvestorsList,
          smartVC,
        }, () => this.clickAddToCampaign(selectedInvestor, idContact, connectedInvestors[contactIndex].email));
      } else if (openNextModal === 'modalReport') {
        this.setState({
          investors: updatedInvestorsList,
          modalReport: true,
          smartVC,
        }, () => this.clickReportInvestor(selectedInvestor, idContact, connectedInvestors[contactIndex].email));
      }
    }
  };

  addInvestorToCampaign = (investor, contact) => {
    const { investorsContactInfo } = this.state;
    let updatedInvestorsContactInfo;

    // let's check if we need to add or remove this person
    if (contact.added === true) {
      updatedInvestorsContactInfo = investorsContactInfo.filter(inv => inv.contactId !== contact.contactId);
    } else {
      const addedInvestor = {
        ...investor,
        fullName: contact.fullName,
        contactId: contact.contactId,
        picture: contact.picture,
        email: contact.email,
        smartVC: contact.smartVC,
        fund: contact.fund,
      };

      updatedInvestorsContactInfo = [
        ...investorsContactInfo,
        addedInvestor,
      ];
    }

    this.setState({
      investorsContactInfo: updatedInvestorsContactInfo,
    }, () => this.updateModalMultiInvestors(investor, contact));
  }

  updateModalMultiInvestors = (investor, contact) => {
    const { funds } = this.state;
    let updatedFundsList = [];
    let connectedInvestors = [];
    let updatedFund = {};

    // Find this investor in the list
    const fundIndex = funds.findIndex(el => el.id === investor.id);

    if (fundIndex !== -1) {
      // we found the investor.
      // retrieve the investor info.
      const fundContacts = [...funds[fundIndex].connectedInvestors];
      const contactIndex = fundContacts.findIndex(el => el.contactId === contact.contactId);

      if (contactIndex !== -1) {
        // updating the contact from the fund first
        const added = fundContacts[contactIndex].added !== true;
        const updatedContact = {
          ...fundContacts[contactIndex],
          added,
        };

        // update the list of contact with the new updated contact from this fund
        connectedInvestors = [
          ...fundContacts.slice(0, contactIndex),
          updatedContact,
          ...fundContacts.slice(contactIndex + 1),
        ];

        // update the fund:
        updatedFund = {
          ...funds[fundIndex],
          connectedInvestors,
        };

        updatedFundsList = [
          ...funds.slice(0, fundIndex),
          updatedFund,
          ...funds.slice(fundIndex + 1),
        ];
      }

      this.setState({
        funds: updatedFundsList,
      });
    }
  };

  addInvestorsToCampaign = () => {
    const { investors } = this.state;
    const investorsContactInfo = investors.filter(el => !el.isFirm);
    const funds = investors.filter(el => el.isFirm);

    this.setState({
      investorsContactInfo,
    }, () => {
      if (funds.length > 0) {
        this.setState({
          funds,
          modalMultiInvestors: true,
        });
      } else {
        this.setState({ modalMultiInvestorsCampaigns: true });
      }
    });
  };

  findIntroToInvestors = () => {
    const { investors } = this.state;
    const investorsContactInfo = investors.filter(el => !el.isFirm);

    this.setState({
      investorsContactInfo,
    }, () => {
      this.setState({ modalIntroChoice: true });
    });
  }

  toggleModalUpgrade = () => {
    const { modalUpgrade } = this.state;
    this.setState({ modalUpgrade: !modalUpgrade });
  };

  toggleModalInvestor = () => {
    const { modalInvestors } = this.state;
    this.setState({ modalInvestors: !modalInvestors });
  };

  toggleModalMultiInvestors = () => {
    const { modalMultiInvestors } = this.state;
    this.setState({ modalMultiInvestors: !modalMultiInvestors });
  };

  toggleModalCampaigns = () => {
    const { modalCampaigns } = this.state;
    this.setState({ modalCampaigns: !modalCampaigns });
  };

  toggleModalMultiInvestorsCampaigns = () => {
    const { modalMultiInvestorsCampaigns } = this.state;
    this.setState({ modalMultiInvestorsCampaigns: !modalMultiInvestorsCampaigns });
  };

  toggleModalReport = () => {
    const { modalReport } = this.state;
    this.setState({ modalReport: !modalReport });
  };

  toggleModalIntro = () => {
    const { modalIntroChoice } = this.state;
    this.setState({ modalIntroChoice: !modalIntroChoice });
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  showNotifSuccess = message => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message={message}
    />,
    position: 'right-up',
  });

  showNotifWarning = (message, link) => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      link={link}
      message={message}
    />,
    position: 'right-up',
  });

  showNotifWarningNoLink = message => showNotification({
    notification: <BasicNotification
      color="warning"
      title="Warning!"
      message={message}
    />,
    position: 'right-up',
  });

  render() {
    const {
      itemsToShow,
      pageOfItems,
      itemsCount,
      user,
      showPagination,
      loading,
      loaded,
      error,
      campaignId,
      modalUpgrade,
      modalInvestors,
      modalCampaigns,
      modalMultiInvestorsCampaigns,
      modalReport,
      modalMultiInvestors,
      modalIntroChoice,
      investorsContactInfo,
      selectedInvestor,
      extInvestorContactInfo,
      funds,
      smartVC,
      fundName,
      fundPicture,
      external,
      queryUrl,
    } = this.state;
    const { location, nextStep, getInvestorProspects } = this.props;

    const state1 = this.state;
    let investors = null;

    if (state1.investors && state1.investors.length > 0 && typeof user !== typeof undefined) {
      investors = state1.investors.map(
        investor => (
          <InvestorBox
            investor={investor}
            clickFav={e => this.clickFavorites(investor.id, e)}
            clickMail={e => this.clickAddToCampaign(investor.id, investor.contactId, null, e)}
            findIntro={e => this.findIntro(investor.id, e)}
            clickReport={e => this.clickReportInvestor(investor.id, investor.contactId, null, e)}
            key={investor.id}
            user={user}
            location={location}
          />
        ),
      );
    }

    return (
      <Row>
        <Col xs={12} lg={11} className="mb-4 mt-4 offset-md-05">
          {showPagination && loaded && !loading
          && (
            <CustPag
              showCountOnly
              itemsCount={itemsCount}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          )}
          {!loaded
            ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            )
            : (
              <div className="extinvestor-list">
                {investors && investors.length > 0
                  ? (
                    <div>
                      {/* If we are on warm intro page */}
                      {location.pathname === '/warm-intro'
                        && (
                          <Button
                            id="button__add-all-investors"
                            className="rounded icon icon--right float-right icon--blue"
                            color="primary"
                            onClick={this.findIntroToInvestors}
                          >
                            Find intro paths to these Investors <HandshakeIcon />
                          </Button>
                        )
                      }
                      {/* If we are NOT on warm intro page */}
                      {user && user.campaigns > 0 && location.pathname !== '/warm-intro'
                        ? (
                          <Button
                            id="button__add-all-investors"
                            className="rounded icon icon--right float-right"
                            color="primary"
                            onClick={this.addInvestorsToCampaign}
                          >
                            Add these investors to a campaign <PlusCircleIcon />
                          </Button>
                        )
                        : (location.pathname !== '/warm-intro'
                          && (
                            <Link
                              id="campaigns"
                              className="select-boxes-box"
                              to="/campaigns"
                            >
                              <Button
                                id="button__add-all-investors"
                                className="rounded icon icon--right float-right"
                                color="primary"
                              >
                                Create a campaign <PlusCircleIcon />
                              </Button>
                            </Link>
                          )
                        )}
                      {investors}
                      {/* Add another button on page bottom to avoid scrolling back top */}
                      {location.pathname === '/warm-intro'
                      && (
                        <div className="mt-5">
                          <Button
                            id="button__add-all-investors"
                            className="rounded icon icon--right icon--blue float-right mt-1 mb-5"
                            color="primary"
                            onClick={this.findIntroToInvestors}
                          >
                            Find intro paths to these Investors <HandshakeIcon />
                          </Button>
                        </div>
                      )}
                    </div>
                  )
                  : (
                    <EmptyResults
                      error={error}
                      external={external}
                      goToIntPage={this.goToIntPage}
                    />
                  )
                }
              </div>
            )
          }
          {showPagination && loaded && !loading
          && (
            <CustPag
              itemsCount={itemsCount}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          )}
        </Col>
        <Modal
          color="primary"
          title="Upgrade Required!"
          colored
          displayed={modalUpgrade}
          toggleModal={() => this.toggleModalUpgrade()}
          message={`Please upgrade your account to our founder plan if you wish to review more investors.<br /><br />
                    Access hundreds of active investors within our online community and search through
                    120,000+ investors listed in our investor database with all their business information.<br /><br />
                    You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
                    Pricing Page</a>.`}
        />
        {
          funds
          && (
            <ModalMultiFundSelectInvestors
              displayed={modalMultiInvestors}
              user={user}
              funds={funds}
              addInvestorToCampaign={(investor, contact) => this.addInvestorToCampaign(investor, contact)}
              toggleModal={() => this.toggleModalMultiInvestors()}
              toggleMultiCampaignsModal={() => this.toggleModalMultiInvestorsCampaigns()}
            />
          )
        }
        <ModalFundSelectInvestors
          displayed={modalInvestors}
          user={user}
          investorId={selectedInvestor}
          investorsContactInfo={investorsContactInfo}
          fundPicture={fundPicture}
          fundId={selectedInvestor}
          fundName={fundName}
          updateContactInfo={
            (contactId, inCampaign, smartVCEnabled, VCsInFunds) => {
              this.updateContactInfo(selectedInvestor, contactId, inCampaign, smartVCEnabled, VCsInFunds);
            }
          }
          toggleModal={() => this.toggleModalInvestor()}
        />
        <ModalCampaigns
          investorContactInfo={extInvestorContactInfo}
          displayed={modalCampaigns}
          selectedCampaignId={campaignId}
          toggleModal={() => this.toggleModalCampaigns()}
          showNotif={message => this.showNotif(message)}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifWarning={(message, link) => this.showNotifWarning(message, link)}
          smartVC={smartVC}
          user={user}
          updateListOfInvestors={
            (investorId, success) => this.updateListOfInvestor(investorId, false, null, true, success, false)
          }
        />
        <ModalMultiUpdateExtInvestors
          noIcon
          investorsContactInfo={investorsContactInfo}
          displayed={modalMultiInvestorsCampaigns}
          toggleModal={() => this.toggleModalMultiInvestorsCampaigns()}
          showNotif={message => this.showNotif(message)}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifWarning={(message, link) => this.showNotifWarning(message, link)}
          showNotifWarningNoLink={message => this.showNotifWarningNoLink(message)}
          updateListOfInvestors={
            (investorId, success) => this.updateListOfInvestor(investorId, false, null, true, success, false)
          }
          updateMultipleListOfInvestors={
            (investorId, success, contactedInvestors) => {
              this.updateMultipleListOfInvestors(investorId, false, null, true, success, contactedInvestors);
            }
          }
        />
        <ModalReport
          investorContactInfo={extInvestorContactInfo}
          displayed={modalReport}
          toggleModal={() => this.toggleModalReport()}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifError={message => this.showNotif(message)}
        />
        <ModalFindIntro
          investorsContactInfo={investorsContactInfo}
          queryUrl={queryUrl}
          displayed={modalIntroChoice}
          toggleModal={() => this.toggleModalIntro()}
          nextStep={nextStep}
          getInvestorProspects={getInvestorProspects}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifWarning={message => this.showNotifWarningNoLink(message)}
          showNotifError={message => this.showNotif(message)}
        />
        <AccountWarnings />
      </Row>
    );
  }
}

export default withRouter(connect(null)(List));
