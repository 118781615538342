/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import validate from '../../formValidate/materials';


const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
};

class StartupMaterials extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
      materials: PropTypes.string,
      linkToMaterials: PropTypes.string,
    }).isRequired,
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;

    return (

      <form className="material-form" onSubmit={handleSubmit}>
        <h5 className="bold-text">ATTACH EXTRA MATERIALS TO YOUR SUMMARY</h5>
        <div className="margin10">
          <span className="material-form__label"><h5>Description of Materials</h5></span>
          <div className="material-form__form-group-field">
            <Field
              name="materials"
              component={renderTextField}
              placeholder="Give a short description of the extra materials provided"
              type="text"
            />
          </div>
        </div>
        <div>
          <span className="material-form__label"><h5>Insert a link to your materials</h5></span>
          <div className="material-form__form-group-field">
            <Field
              name="linkToMaterials"
              component={renderTextField}
              placeholder="Make sure your folder has public permission"
              type="text"
            />
          </div>
          <span className="form__form-group-description">
            To attach materials to your summary, you can provide a link from Google Drive,
            DropBox, WeTransfer and so on. <strong>Make sure your folder has public permission.</strong>
          </span>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button className="rounded icon icon--right icon--blue" color="primary" type="submit">
            Save
            <ChevronRightIcon />
          </Button>
          <Button className="rounded" type="button" onClick={reset} disabled={pristine || submitting}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}


export default reduxForm({
  form: 'startup_materials_form', // a unique identifier for this form
  validate,
})(StartupMaterials);
