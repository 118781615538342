import React, { PureComponent } from 'react';
import {
  Row, Col, Table, Card, CardBody, UncontrolledTooltip, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import ChairIcon from 'mdi-react/ChairIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import CoInvestorItem, { coInvestorProps } from './CoInvestorItem';

const getSimilarInvestors = (similarInvestors) => {
  const investors = [...new Map(similarInvestors.map(v => [v.id, v])).values()];
  if (investors && Array.isArray(investors) && investors.length > 0) {
    return (
      investors.map(investor => (
        <CoInvestorItem
          key={investor.id}
          {...investor}
        />
      ))
    );
  }

  return null;
};

const getConnectedInvestors = (connectedInvestors) => {
  if (connectedInvestors && Array.isArray(connectedInvestors) && connectedInvestors.length > 0) {
    return (
      connectedInvestors.map(investor => (
        <CoInvestorItem
          key={investor.id}
          {...investor}
        />
      ))
    );
  }


  return null;
};

export default class ProfileContent extends PureComponent {
  state = {
    showAllCoInv: false,
    showAllRounds: false,
  };

  static defaultProps = {
    fullName: null,
    company: null,
    description: null,
    expertise: null,
    investmentMax: null,
    investmentMin: null,
    investmentSweetSpot: null,
    investmentCountries: null,
    stages: null,
    categories: null,
    currency: '$',
    twitter: null,
    specialities: null,
    linkedinDescription: null,
    descriptionVC: null,
    experience: null,
    rounds: null,
    aum: '',
    fundType: null,
    investorType: null,
    isFirm: null,
    coInvestors: null,
    similarInvestors: null,
    connectedInvestors: null,
  };

  getRounds = (rounds, limit) => (rounds ? rounds.slice(0, limit).map(r => (
    <tr key={r.id}>
      <td id={`TooltipPitch${r.id}`} className="extinvestor__investments-cell-company">
        {r.link
          ? (
            <a href={r.link} className="text-dark"><strong>{r.company}</strong>&nbsp;&nbsp;<OpenInNewIcon /></a>
          ) : (
            <strong>{r.company}</strong>
          )}
        {r.pitch
          ? (
            <UncontrolledTooltip placement="bottom" target={`TooltipPitch${r.id}`}>
              {r.pitch}
            </UncontrolledTooltip>
          ) : ('')}
      </td>
      <td>
        {r.investmentSize
          ? (
            <span>
              <CurrencyFormat
                value={r.investmentSize / 1000000}
                displayType="text"
                thousandSeparator
                prefix="$"
                suffix="M"
                decimalScale={1}
              />
            </span>
          ) : null}
        {r.lead
          ? (
            <span>
              <UncontrolledTooltip placement="bottom" target={`TooltipRounds${r.id}-lead`}>
                Lead
              </UncontrolledTooltip>
              <StarOutlineIcon id={`TooltipRounds${r.id}-lead`} className="mr-2" />
            </span>
          )
          : ''}
        {r.board
          ? (
            <span>
              <UncontrolledTooltip placement="bottom" target={`TooltipRounds${r.id}-board`}>
                Board
              </UncontrolledTooltip>
              <ChairIcon id={`TooltipRounds${r.id}-board`} className="mr-2" />
            </span>
          )
          : ''}
      </td>
      {r.round
      && (
        <td>
          <strong>{r.round}</strong>
        </td>
      )}
    </tr>
  )) : null)

  goToAnchor = (anchor) => {
    const loc = document.location.toString().split('#')[0];
    document.location = `${loc}#${anchor}`;
    return false;
  };

  extractTwitterUserName = (twitterURL) => {
    const start = twitterURL.lastIndexOf('/') + 1;
    return twitterURL.substring(start, twitterURL.length);
  }

  getCoInvestors = (coInvestors) => {
    const { showAllCoInv } = this.state;
    let result = null;
    if (coInvestors && Array.isArray(coInvestors) && coInvestors.length > 0) {
      if (coInvestors.length > 10 && !showAllCoInv) {
        result = (
          coInvestors.slice(0, 10).map(investor => (
            <CoInvestorItem
              key={investor.id}
              {...investor}
            />
          ))
        );
      } else {
        result = (
          coInvestors.map(investor => (
            <CoInvestorItem
              key={investor.id}
              {...investor}
            />
          ))
        );
      }
    }

    return result;
  };

  showAllCoInvestors = () => {
    const { showAllCoInv } = this.state;
    this.setState({ showAllCoInv: !showAllCoInv });
  }

  showAllRounds = () => {
    const { showAllRounds } = this.state;
    this.setState({ showAllRounds: !showAllRounds });
  }

  render() {
    const {
      fullName, description, expertise, specialities, experience, linkedinDescription,
      investmentMax, investmentMin, investmentCountries, investmentSweetSpot, company,
      stages, categories, currency, fundType, aum, investorType, descriptionVC,
      twitter, coInvestors, connectedInvestors, rounds, similarInvestors, isFirm,
    } = this.props;
    const { showAllCoInv, showAllRounds } = this.state;

    return (
      <Row className="profile__card flex-column-reverse flex-lg-row">
        <Col xs={12} lg={6}>
          <Row>
            <Card className="table-shadowed mb-5 mb-lg-2">
              <CardBody>
                <Table responsive>
                  <tbody>
                    {investorType
                    && (
                      <tr>
                        <td>Investor Type</td>
                        <td>{investorType.slice(0, 10)}</td>
                      </tr>
                    )}
                    {fundType && isFirm
                    && (
                      <tr>
                        <td>Type of Fund</td>
                        <td>{fundType}</td>
                      </tr>
                    )}
                    {(expertise || categories)
                    && (
                      <tr>
                        <td>Industries</td>
                        <td dangerouslySetInnerHTML={{ __html: expertise || categories }} />
                      </tr>
                    )}
                    {stages
                    && (
                      <tr>
                        <td>Stages</td>
                        <td>{stages}</td>
                      </tr>
                    )}
                    {investmentCountries
                    && (
                      <tr>
                        <td>Investing</td>
                        <td dangerouslySetInnerHTML={{ __html: investmentCountries }} />
                      </tr>
                    )}
                    {specialities
                    && (
                      <tr>
                        <td>Specialities</td>
                        <td dangerouslySetInnerHTML={{ __html: specialities }} />
                      </tr>
                    )}
                    {investmentMin && investmentMax
                      ? (
                        <tr>
                          <td>Investment Range</td>
                          <td>
                            <CurrencyFormat
                              value={investmentMin}
                              displayType="text"
                              thousandSeparator
                              prefix={currency}
                            />
                            &nbsp;-&nbsp;
                            <CurrencyFormat
                              value={investmentMax}
                              displayType="text"
                              thousandSeparator
                              prefix={currency}
                            />
                          </td>
                        </tr>
                      ) : null}
                    {investmentSweetSpot
                      ? (
                        <tr>
                          <td>Investment Sweet Spot</td>
                          <td>
                            <CurrencyFormat
                              value={investmentSweetSpot}
                              displayType="text"
                              thousandSeparator
                              prefix={currency}
                            />
                          </td>
                        </tr>
                      ) : null
                    }
                    {aum
                      ? (
                        <tr>
                          <td>Assets Under Management</td>
                          <td>
                            <CurrencyFormat value={aum} displayType="text" thousandSeparator prefix={currency} />
                          </td>
                        </tr>
                      ) : null
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Row>
          {coInvestors
          && (
            <Row className="m-0 m-lg-3">
              <Col xs={12}>
                <Card className="bg-transparent">
                  <CardBody>
                    <h4>Investors who co-invest with {fullName}</h4>
                    {this.getCoInvestors(coInvestors)}
                    {coInvestors.length > 10
                    && (
                      <Button
                        className="rounded icon icon--right icon--blue mt-2 mr-auto ml-auto"
                        color="primary"
                        size="sm"
                        onClick={this.showAllCoInvestors}
                      >
                        {showAllCoInv
                          ? (
                            <>
                              Hide
                              <MinusIcon />
                            </>
                          ) : (
                            <>
                              See More
                              <PlusIcon />
                            </>
                          )}
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {connectedInvestors
          && (
            <Row className="m-0 m-lg-3">
              <Col xs={12}>
                <Card className="bg-transparent">
                  <CardBody>
                    <h4 className="my-3 mx-2">Investors who are part of this firm</h4>
                    {getConnectedInvestors(connectedInvestors)}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {rounds
          && (
            <Row className="extinvestor__investments">
              <Col xs={12}>
                <Card className="bg-transparent">
                  <CardBody>
                    <h4 id="rounds">{fullName}&#39;s investments on record</h4>
                    <table className="w-100">
                      <tbody>
                        {this.getRounds(rounds, showAllRounds ? 150 : 15)}
                        {rounds.length > 15
                        && (
                          <tr>
                            <td colSpan={3}>
                              <Button
                                className="rounded icon icon--right icon--blue mt-2 mr-auto ml-auto"
                                color="primary"
                                size="sm"
                                onClick={this.showAllRounds}
                              >
                                {showAllRounds
                                  ? (
                                    <>
                                      Hide
                                      <MinusIcon />
                                    </>
                                  ) : (
                                    <>
                                      See More
                                      <PlusIcon />
                                    </>
                                  )}
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
        <Col xs={12} lg={6}>
          {description
          && (
            <Row>
              <Col xs={12}>
                <Card className="bg-transparent">
                  <CardBody>
                    <h4>About { fullName }</h4>
                    <p className="text-justify text-muted" dangerouslySetInnerHTML={{ __html: description }} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {linkedinDescription
          && (
            <Row>
              <Col xs={12}>
                <Card className="bg-transparent">
                  <CardBody>
                    <h4>What they say</h4>
                    <p
                      className="text-justify text-muted"
                      dangerouslySetInnerHTML={{ __html: linkedinDescription }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {descriptionVC
          && (
            <Row className="d-none d-xl-block">
              <Col xs={12}>
                <div className="extinvestor__open-desc pre-wrap">
                  <h2>About { company || 'the Firm' }</h2>
                  <p>
                    { descriptionVC }
                  </p>
                </div>
              </Col>
            </Row>
          )}
          {experience
          && (
            <Row>
              <Col xs={12}>
                <Card className="bg-transparent">
                  <CardBody>
                    <h4 id="experience">Experience</h4>
                    <p className="text-justify text-muted" dangerouslySetInnerHTML={{ __html: experience }} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {twitter
          && (
            <Row>
              <Card className="card">
                <CardBody className="p-3 card-body">
                  <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName={this.extractTwitterUserName(twitter)}
                    options={{ height: 800 }}
                  />
                </CardBody>
              </Card>
            </Row>
          )}
          {similarInvestors && similarInvestors.length > 0
            && (
              <Row className="m-0 m-lg-3">
                <Col xs={12}>
                  <Card className="bg-transparent">
                    <CardBody>
                      <h4>Similar Investors</h4>
                      {getSimilarInvestors(similarInvestors)}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
        </Col>
      </Row>
    );
  }
}

ProfileContent.propTypes = {
  fullName: PropTypes.string,
  description: PropTypes.string,
  expertise: PropTypes.string,
  currency: PropTypes.string,
  company: PropTypes.string,
  investmentMax: PropTypes.number,
  investmentMin: PropTypes.number,
  investmentSweetSpot: PropTypes.number,
  investmentCountries: PropTypes.string,
  stages: PropTypes.string,
  categories: PropTypes.string,
  twitter: PropTypes.string,
  specialities: PropTypes.string,
  linkedinDescription: PropTypes.string,
  descriptionVC: PropTypes.string,
  experience: PropTypes.string,
  isFirm: PropTypes.bool,
  rounds: PropTypes.arrayOf(PropTypes.shape({
    company: PropTypes.string,
    investmentSize: PropTypes.number,
    round: PropTypes.string,
    lead: PropTypes.bool,
    board: PropTypes.bool,
  })),
  aum: PropTypes.number,
  fundType: PropTypes.string,
  investorType: PropTypes.string,
  coInvestors: PropTypes.arrayOf(PropTypes.shape({
    coInvestorProps,
  })),
  similarInvestors: PropTypes.arrayOf(PropTypes.shape({
    coInvestorProps,
  })),
  connectedInvestors: PropTypes.arrayOf(PropTypes.shape({
    coInvestorProps,
  })),
};
