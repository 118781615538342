import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import Action from './Action';

class List extends Component {
  static propTypes = {
    step: PropTypes.number.isRequired,
    toggleStep: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
  };

  render() {
    const { step, toggleStep } = this.props;

    return (
      <Col
        xs={12}
        md={{ size: 8, offset: 1 }}
        lg={{ size: 6, offset: 2 }}
        xl={{ size: 4, offset: 3 }}
        className="mt-5 p-0 p-md-1 p-lg-3 p-xl-4"
      >
        {step === 0 && (
          <>
            <Action step={step} value="import" toggleStep={val => toggleStep(val)} />
            <Action step={step} value="blacklist" toggleStep={val => toggleStep(val)} />
          </>
        )}
        {step === 1 && (
          <>
            <Action step={step} value="csv" toggleStep={val => toggleStep(val)} />
            <Action step={step} value="mail" toggleStep={val => toggleStep(val)} />
          </>
        )}
      </Col>
    );
  }
}

export default List;
