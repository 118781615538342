export const UPDATE_INVESTORS_ADDED = 'UPDATE_INVESTORS_ADDED';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_NAME = 'UPDATE_NAME';

export function updateUserInfo(user) {
  return {
    type: UPDATE_USER_INFO,
    value: user,
  };
}

export function updateInvestorsAdded(value) {
  return {
    type: UPDATE_INVESTORS_ADDED,
    value,
  };
}

export function updateAvatar(avatar) {
  return {
    type: UPDATE_AVATAR,
    value: avatar,
  };
}

export function updateName(firstName, lastName, email) {
  return {
    type: UPDATE_NAME,
    value: {
      name: `${firstName} ${lastName}`,
      email,
    },
  };
}
