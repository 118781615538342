import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import axios from 'axios';
import Visitors from './Visitors';

class VisitorsList extends PureComponent {
  state = {
    visitors: {},
    loading: true,
    message: 'loading',
  };

  componentDidMount() {
    this.retrieveVisitors();
  }

  retrieveVisitors = () => {
    axios.get('links/visitors')
      .then((response) => {
        if (response.data instanceof Array && response.data.length > 0) {
          this.setState({ visitors: response.data, loading: false });
        } else if (response.data instanceof Array && response.data.length === 0) {
          this.setState({ message: 'Share your links and start gauging investors interest.', loading: false });
        } else {
          this.setState({ message: 'An error occured, please try again', loading: false });
        }
      }).catch(() => {
        this.setState({ message: 'An error occurred, please try again or contact support', loading: false });
      });
  };

  render = () => {
    const { visitors, loading, message } = this.state;

    if (loading) {
      return (
        <Col xs={12} md={{ size: 10, offset: 1 }}>
          <Card>
            <CardBody>
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }

    return (
      <Row>
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Visitors</h5>
                <h5 className="subhead">
                  List of visitors who accessed the documents shared with your tracking links
                </h5>
              </div>
              { visitors
                ? (
                  <Visitors visitors={visitors} />
                )
                : (
                  { message }
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default VisitorsList;
