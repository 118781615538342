import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import MessageOutlineIcon from 'mdi-react/MessageOutlineIcon';


class TopbarWithoutNavigation extends Component {
  state = {
    contact: null,
  };

  componentDidMount = () => {
    const { props } = this;
    const { id } = props.match.params;
    axios.get(`/summary/contact/${id}`)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          this.setState({ contact: data.contact });
        }
      });
  };


  render() {
    const { contact } = this.state;

    return (
      <div className="topbar topbar--navigation">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <a
              className="topbar__logo"
              href={process.env.REACT_APP_DOMAIN_URL}
            >
              &nbsp;
            </a>
          </div>
          <div className="topbar__right">
            <div className="topbar__buttons">

              <a href={process.env.REACT_APP_DOMAIN_URL}>
                <div className="topbar__help">
                  <HelpCircleOutlineIcon />
                </div>
              </a>
              {contact
                && (
                <a href={`mailto:${contact.email}`}>
                  <div className="topbar__contact">
                    <MessageOutlineIcon />
                    Contact {contact.name}
                  </div>
                </a>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TopbarWithoutNavigation);
