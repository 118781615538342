import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { connect } from 'react-redux';
// import moment from 'moment';
import moment from 'moment-timezone';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import WizardForm from '../sharedComponents/WizardForm';
import { FullWideNotification } from '../../../shared/components/notifications/Notification';

let notificationTC = null;
const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 20,
    closable: true,
    style: { top: 0, left: 0 },
    className: `${position} ${rtl}-support`,
  });
};

// eslint-disable-next-line react/prefer-stateless-function
class CreateCampaign extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object.isRequired,
  };

  state = {
    createLoading: false,
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  onSubmit = (results, campaignId) => {
    const { history } = this.props;
    const { createLoading } = this.state;
    let time;

    if (!createLoading) {
      this.setState({ createLoading: true });

      if (typeof results.time !== 'string') {
        time = moment.tz(`2021-01-21 ${results.time.format('HH:mm')}`,
          results.timezone
            ? results.timezone.value
            : 'UTC');
        time = time.utc().format('HH:mm:ss');
      } else {
        time = moment.tz(`2021-01-21 ${results.time}`,
          results.timezone
            ? results.timezone.value
            : 'UTC');
        time = time.utc().format('HH:mm:ss');
      }
      // Post the Ping.
      axios.post('/campaigns/editSchedule', queryString.stringify({
        id: campaignId,
        time,
        datetime: results.datetime,
        emailWarmup: results.emailWarmup,
        timezoneValue: results.timezone.value,
        timezoneLabel: results.timezone.label,
        sendOnMonday: results.sendOnMonday,
        sendOnTuesday: results.sendOnTuesday,
        sendOnWednesday: results.sendOnWednesday,
        sendOnThursday: results.sendOnThursday,
        sendOnFriday: results.sendOnFriday,
        sendOnSaturday: results.sendOnSaturday,
        sendOnSunday: results.sendOnSunday,
      }))
        .then((response) => {
          this.setState({ createLoading: false });
          if (
            typeof response !== 'undefined' && typeof response.data !== 'undefined' && response.data.success === true
          ) {
            const { location } = this.props;
            const user = JSON.parse(localStorage.getItem('user'));

            // prevent he message from being displayed again
            window.onbeforeunload = null;

            user.campaigns = user.campaigns ? user.campaigns + 1 : 1;
            localStorage.setItem('user', JSON.stringify(user));
            if (typeof location.state !== 'undefined'
              && typeof location.state.investorId !== 'undefined'
              && typeof location.state.redirect !== 'undefined'
              && location.state.redirect === true) {
              let query = '';
              query += location.state.keywords ? `keywords=${location.state.keywords}` : '';
              query += location.state.location ? `&location=${location.state.location}` : '';
              query += location.state.industry ? `&industry=${location.state.industry}` : '';
              query += location.state.isFirm ? `&isFirm=${location.state.isFirm}` : '';
              query += location.state.pageOfItems ? `&page=${location.state.pageOfItems}` : '';

              history.push({
                pathname: '/investors-db',
                search: `?${query}`,
                state: {
                  investorId: location.state.investorId,
                  contactId: location.state.contactId,
                  campaignId: response.data.id,
                  keywords: location.state.keywords,
                  location: location.state.location,
                  industry: location.state.industry,
                  isFirm: location.state.isFirm,
                  filters: location.state.filters,
                  pageOfItems: location.state.pageOfItems,
                },
              });
            } else {
              history.push('/campaigns');
            }
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
        })
        .catch((error) => {
          this.setState({ createLoading: false });
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
        });
    }
  }

  render = () => {
    const { createLoading } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title"><small>Create a new<br />outreach campaign</small></h3>
          </Col>
          <Col xs={12} md={9} xl={7}>
            <p className="page-description">
              Create a campaign to automate your outreach to investors and raise funds effectively.
            </p>
          </Col>
        </Row>
        <Row>
          <WizardForm
            onSubmit={(results, campaignId) => this.onSubmit(results, campaignId)}
            loading={createLoading}
            reset
          />
        </Row>
      </Container>
    );
  }
}

export default withRouter(connect(null)(CreateCampaign));
