import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CardBody, Button } from 'reactstrap';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import LoadingIcon from 'mdi-react/LoadingIcon';
import ArchiveIcon from 'mdi-react/ArchiveIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import RestartIcon from 'mdi-react/RestartIcon';
import axios from 'axios';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import AddCircleIcon from 'mdi-react/AddCircleIcon';
import ModalTransferPings from './modals/ModalTransferPings';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

export default class ManagedUserCard extends PureComponent {
  static defaultProps = {
    user: [],
    successCallback: null,
    toggleFeedback: null,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      modalTransferPings: false,
    };
  }

  impersonate = (email) => {
    localStorage.setItem('user', null);
    window.open(`${process.env.REACT_APP_DOMAIN_URL}/logged_in?_want_to_be_this_user=${email}`);
  };

  clickArchive = (user) => {
    axios.post('broker/switch_block', { id: user.id })
      .then((response) => {
        if (response) {
          const { successCallback, toggleFeedback } = this.props;
          if (successCallback !== null) {
            successCallback();
          }
          toggleFeedback(user.id);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Error switching archive status', error);
      });
  }

  renderTransferPingsButton = currentPings => (
    <Button
      className="rounded btn btn-danger mr-0 m-2"
      color="purple"
      size="sm"
      onClick={e => this.toggleTransferPingsModal(e)}
      onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { this.toggleTransferPingsModal(e); } }}
    >
      {currentPings > 0
        ? (
          <small>
            {currentPings} PINGS
          </small>
        ) : (
          <small>
            Pings <AddCircleIcon />
          </small>
        )}
    </Button>
  )

  renderImpersonateButton = email => (
    <Button
      className="icon icon--right icon--blue rounded btn mr-0 m-2"
      color="primary"
      size="sm"
      onClick={() => this.impersonate(email)}
    >
      <small>Manage<ChevronRightIcon /></small>
    </Button>
  );

  renderArchiveButton = (user) => {
    if (user.unsubscribed) {
      return (
        <>
          <Button
            className="icon rounded icon-only btn float-right m-3"
            color="warning"
            onClick={() => this.clickArchive(user)}
            id={`TooltipResume${user.id}`}
          >
            <p><RestartIcon /></p>
          </Button>
          <UncontrolledTooltip placement="bottom" target={`TooltipResume${user.id}`}>
            Re-list this startup. It will be listed on the platform.
          </UncontrolledTooltip>
        </>
      );
    } if (user.adding) {
      return (
        <>
          <Button
            className="icon rounded icon-only btn float-right m-3"
            color="warning"
            outline
          >
            <p><LoadingIcon className="mdi-icon-spinning" /></p>
          </Button>
        </>
      );
    }
    return (
      <>
        <Button
          className="icon rounded icon-only btn float-right m-3"
          color="primary"
          onClick={() => this.clickArchive(user)}
          id={`TooltipArchive${user.id}`}
        >
          <p><ArchiveIcon /></p>
        </Button>
        <UncontrolledTooltip placement="bottom" target={`TooltipArchive${user.id}`}>
          Archive this startup. It will not be listed on the platform anymore.
        </UncontrolledTooltip>
      </>
    );
  }

  toggleTransferPingsModal = () => {
    const { modalTransferPings } = this.state;
    this.setState({ modalTransferPings: !modalTransferPings });
  };


  handleClick = () => {
    const scrollYOffset = window.pageYOffset;
    sessionStorage.setItem('scrollYOffsetInt', scrollYOffset);
    sessionStorage.setItem('scrollActiveInt', false);
  }

  render() {
    const { user, successCallback } = this.props;
    const { modalTransferPings } = this.state;

    const startup = user.startup || {};

    let image = '';

    if (startup.logo_img) {
      image = process.env.REACT_APP_AWS_ROOT + startup.logo_img.replace('/', '');
    } else {
      image = startup.logo && startup.logo.logoName ? process.env.REACT_APP_AWS_ROOT
          + process.env.REACT_APP_AWS_LOGOS_PATH + startup.logo.logoName : userIcon;
    }

    return (
      <div className="col-sm-12 col-md-6 col-lg-4">
        <CardBody className="investor-item col-md-12 p-0" key={user.id}>
          <div>
            { this.renderArchiveButton(user) }
          </div>
          <div className="investor-item__link">
            <div className="investor-item__img-wrap">
              {user.new ? <div className="investor-item__label-new">New</div> : ''}
              {/* eslint-disable-next-line max-len */}
              <img
                className="investor-item__img"
                src={image}
                onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
                alt="investor-item-img"
              />
            </div>
            <div className="investor-item__info">
              <h4 className="investor-item__title">{user.firstName || ''} {user.lastName || ''}, { startup.name }
              </h4>
              <h5 className="investor-item__location">
                <MapMarkerIcon />
                { startup.location_city } | { startup.location_country && startup.location_country.name }
              </h5>
            </div>
            <div className="investor-item__info">
              <p className="investor-item__description text-muted text-justify">{startup.short_description}</p>
            </div>
          </div>
          <div className="investor-item__actions">
            { this.renderTransferPingsButton(user.pings) }
            { this.renderImpersonateButton(user.email) }
          </div>
          <ModalTransferPings
            user={user}
            color="primary"
            displayed={modalTransferPings}
            toggleModal={this.toggleTransferPingsModal}
            successCallback={successCallback}
          />
          <Link className="investor-item__link-grey" to={`/startup/${startup.id}`}>
            <div className="investor-item__investment-range">
              Emails sent: {user.pingsUsed}
              &nbsp; - &nbsp;
              Pings: {user.pings}
            </div>
          </Link>
        </CardBody>
      </div>
    );
  }
}

ManagedUserCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    locationCity: PropTypes.string,
    locationCountry: PropTypes.string,
    picture: PropTypes.string,
    investmentMax: PropTypes.number,
    investmentMin: PropTypes.number,
    new: PropTypes.number,
    pinged: PropTypes.bool,
    pinging: PropTypes.bool,
    favorite: PropTypes.bool,
    adding: PropTypes.bool,
  }),
  successCallback: PropTypes.func,
  toggleFeedback: PropTypes.func,
};
