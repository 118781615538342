import React from 'react';
import { textarea } from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import PropTypes from 'prop-types';
import PaperclipIcon from 'mdi-react/PaperclipIcon';

const loading = '/img/loading.gif';

const ChatField = ({
  onSubmit, handleMessage, messageValue, showUpload, sending, isDisabled,
}) => (
  <div className="chat__text-field">
    <form className="chat__form" onSubmit={onSubmit}>
      <textarea
        className="chat__field-input"
        name="chatField"
        placeholder="Type here…"
        onChange={handleMessage}
        value={messageValue}
        disabled={isDisabled}
        hidden={isDisabled}
      />
      <div className="chat__field-controls">
        {sending
          ? (
            <button
              type="button"
              className="chat__icon-button"
            >
              <img src={loading} alt="Loading" />
            </button>
          )
          : (
            <button
              type="button"
              className="chat__icon-button"
              onClick={onSubmit}
            >
              <SendIcon />
            </button>
          )}
        <button
          type="button"
          className="chat__icon-button"
          onClick={showUpload}
        >
          <PaperclipIcon />
        </button>
      </div>
    </form>
  </div>
);

ChatField.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleMessage: PropTypes.func.isRequired,
  messageValue: PropTypes.string.isRequired,
  showUpload: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ChatField;
