import React, { Component } from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';

const logo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/startup.svg`;

class CardFundraisingServices extends Component {
  state = {
    modal: false,
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  render() {
    return (
      <Col md={6} xl={4} sm={12}>
        <Card>
          <CardBody className="dashboard-card dashboard-card--danger">
            <div className="dashboard-card__body">
              <img className="round dashboard-card__img-partnerships" src={logo} alt="" />
              <h3 className="dashboard-card__plan mt-4">Investment Manager</h3>
              <hr />
              <p className="dashboard-card__title">We work on your side to help you raise funds</p>
              <div className="dashboard-card__desc second-line text-justify">
                <p>
                  We partnered with professional bankers to help you raise funds for your business.
                </p>
                <p>
                  We can help you in your fundraising efforts, for selected startups, and reach out to investors.
                </p>
              </div>
              <Button
                className="dashboard-card__button"
                color="primary"
                onClick={() => { window.location = `${process.env.REACT_APP_DOMAIN_URL}/fundraising-services`; }}
              >
                Learn More
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default CardFundraisingServices;
