import React, { PureComponent } from 'react';
import {
  Card, Nav, NavItem, NavLink, Progress, TabContent, TabPane,
} from 'reactstrap';
import axios from 'axios';
import queryString from 'query-string';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NotificationSystem from 'rc-notification';
import FounderMessage from './tabs/FounderMessage';
import FounderCommunity from './tabs/FounderCommunity';
import { BasicNotification, LinkNotification } from '../../../shared/components/notifications/Notification';
// import showResults from './Show';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class ProfileTabs extends PureComponent {
  static defaultProps = {
    message: '',
    founderLinkedin: '',
    community: true,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      downloading: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      axios.post('/founder/trackSettings', queryString.stringify({ tab })).then();
    }
  };

  showNotifSuccess = () => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message="Your information has been updated successfully"
    />,
    position: 'right-up',
  });

  showNotifWarning = errorMessage => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      message={errorMessage}
    />,
    position: 'right-up',
  });

  updateMessage = (values) => {
    axios.post('/founder/message', queryString.stringify({
      message: values.message,
    })).then((response) => {
      if (response.data) {
        this.updateUser();
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  updateCommunity = (values) => {
    this.setState({ downloading: true });

    axios.post('/founder/community', queryString.stringify({
      community: values.community,
      founderLinkedin: values.founderLinkedin,
    })).then((response) => {
      if (response.data) {
        this.updateUser();
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      if (error.response.data) {
        this.setState({
          downloading: false,
        }, () => this.showNotifWarning(error.response.data.message));
      }
    });
  };

  updateUser = () => {
    // Unfortunately, we cannot use the redux store here yet, but we want to check the user auth here.
    axios.get('/user')
      .then((response) => {
        if (typeof response.data === 'object') {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
      });
  }

  render() {
    const { activeTab, downloading } = this.state;
    const { message, founderLinkedin, community } = this.props;

    const initialValuesCommunity = { community, founderLinkedin };
    const initialValuesMessage = { message };

    let downloadBlock = null;
    if (downloading) {
      downloadBlock = (
        <Progress animated value={100} className="progress-wrap--middle">
          Please wait..
        </Progress>
      );
    }

    return (
      <Card>
        <div className="tabs tabs--vertical">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  Message
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Community
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="pl-5">
              <TabPane tabId="1">
                <FounderMessage
                  onSubmit={val => this.updateMessage(val)}
                  initialValues={initialValuesMessage}
                  enableReinitialize
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="2">
                <FounderCommunity
                  onSubmit={val => this.updateCommunity(val)}
                  initialValues={initialValuesCommunity}
                  enableReinitialize
                />
                {downloadBlock}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    );
  }
}

export default connect(null)(ProfileTabs);

ProfileTabs.propTypes = {
  message: PropTypes.string,
  community: PropTypes.bool,
  founderLinkedin: PropTypes.string,
};
