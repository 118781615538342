import React, { PureComponent } from 'react';
import {
  Button, Col, Container, Row,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import PlusIcon from 'mdi-react/PlusIcon';
import axios from 'axios';
import PauseIcon from 'mdi-react/PauseIcon';
import PlayIcon from 'mdi-react/PlayIcon';
import PencilOutlineIcon from 'mdi-react/PencilOutlineIcon';
import ArchiveIcon from 'mdi-react/ArchiveIcon';
import PropTypes from 'prop-types';
import Modal from '../../../shared/components/modals/Modal';
import AccountWarnings from '../../../shared/components/AccountWarnings';
import FillerElement from './FillerElement';
import ListElements from './ListElements';

const tags = [
  { name: 'running', title: 'Running', icon: <PlayIcon /> },
  { name: 'paused', title: 'Paused', icon: <PauseIcon /> },
  { name: 'draft', title: 'Draft', icon: <PencilOutlineIcon /> },
  { name: 'archived', title: 'Archived', icon: <ArchiveIcon /> },
];

class Campaigns extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: true,
      loaded: false,
      error: false,
      campaigns: null,
      allCampaigns: null,
      pausing: false,
      archiving: false,
      modalUpgrade: false,
      infoLoaded: false,
      currentTag: 'running',
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.type !== 'startup') { window.location = '/startups'; }

    this.retrieveUserName();
    this.init();
  }

  init = () => {
    axios.get('/campaigns/basicInfo')
      .then((response) => {
        if (response.data && response.data.length > 0) {
          this.setState({
            allCampaigns: response.data, loading: false, infoLoaded: false, error: null,
          });
          this.selectActiveTab(response.data);
          this.getCampaignsDetails();
        } else {
          this.setState({ campaigns: null, loading: false, error: null });
        }
        setTimeout(() => {
          this.setState({ loaded: true });
        }, 500);
      }).catch((error) => {
        if (typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.setState({ error: error.response.data.message, loading: false });
        } else {
          this.setState({
            error: 'Sorry an error occurred. Please try again or contact support if the problem occurs again.',
            loading: false,
          });
        }
        // Remove loading screen
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
  }

  selectActiveTab = (allCampaigns) => {
    let filteredCampaigns = [];
    let tag = null;
    for (let i = 0; i < tags.length; i += 1) {
      if (filteredCampaigns.length === 0) {
        filteredCampaigns = allCampaigns.filter(t => t.status === tags[i].name);
        // eslint-disable-next-line prefer-destructuring
        tag = tags[i].name;
      }
    }

    this.setState({
      campaigns: filteredCampaigns,
      currentTag: tag,
    });
  }

  getCampaignsDetails = () => {
    const { currentTag } = this.state;
    axios.get('/campaigns/data')
      .then((response) => {
        if (response.data && response.data.length > 0) {
          this.setState({
            allCampaigns: response.data, loading: false, infoLoaded: true, error: null,
          });
          const filteredCampaigns = response.data.filter(t => t.status === currentTag);
          this.setState({
            campaigns: filteredCampaigns,
          });
        } else {
          this.setState({ campaigns: null, loading: false, error: null });
        }
        setTimeout(() => this.setState({ loaded: true }), 500);
      }).catch((error) => {
        if (typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.setState({ error: error.response.data.message, loading: false });
        } else {
          this.setState({
            error: 'Sorry an error occurred. Please try again or contact support if the problem occurs again.',
            loading: false,
          });
        }
        // Remove loading screen
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
  }

  retrieveUserName = () => {
    const userStorage = JSON.parse(localStorage.getItem('user'));

    if (userStorage !== null) {
      // We update redux store here because we couldn't do it from the APP.
      this.setState({ user: userStorage });
    } else {
      // Should never be called
      // eslint-disable-next-line no-alert
      alert('Session expired, please log in again.'
        + ' If you are unable to log in, please contact support: contact@angelspartners.com');
      window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/logout`;
    }
  };

  confirmPause = (id) => {
    const { state } = this;
    const campaigns = [...state.campaigns];

    // find the index of this investor
    const index = campaigns.findIndex(i => i.id === id);
    const { paused } = campaigns[index];

    const text = paused ? 'Are you sure you would like to resume this campaign?'
      : 'Are you sure you would like to pause this campaign?\nNo further messages will be sent until you resume it.';

    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm(text)) {
      this.togglePause(id);
    }
  }

  confirmArchive = (id) => {
    const { state } = this;
    const campaigns = [...state.campaigns];

    // find the index of this investor
    const index = campaigns.findIndex(i => i.id === id);
    const { archived } = campaigns[index];

    const text = archived ? 'Are you sure you would like to reactivate this campaign?'
      : 'Are you sure you would like to archive this campaign?\nNo further messages will be sent until you resume it.';

    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm(text)) {
      this.toggleArchive(id);
    }
  }

  togglePause = (id) => {
    this.setState({ pausing: true });
    axios.get(`/campaigns/pause/${id}`).then((response) => {
      if (response.data.success) {
        this.getCampaignsDetails();
        // Update the State
        this.setState({
          loading: false, error: null,
        });
        setTimeout(() => this.setState({ pausing: false }), 500);
      } else {
        this.setState({
          campaigns: null, loading: false, error: null, pausing: false,
        });
      }
    }).catch((error) => {
      if (typeof error.response === 'object' && typeof error.response.data === 'object') {
        this.setState({ error: error.response.data.message, loading: false, pausing: false });
      } else {
        this.setState({ pausing: false });
      }
    });
  }

  toggleArchive = (id) => {
    this.setState({ archiving: true });
    axios.get(`/campaigns/archive/${id}`).then((response) => {
      if (response.data.success) {
        this.getCampaignsDetails();
        // Update the State
        this.setState({
          loading: false, error: null,
        });
        setTimeout(() => this.setState({ archiving: false }), 500);
      } else {
        this.setState({
          allCampaigns: null, campaigns: null, loading: false, error: null, archiving: false,
        });
      }
    }).catch((error) => {
      if (typeof error.response === 'object' && typeof error.response.data === 'object') {
        this.setState({ error: error.response.data.message, loading: false, archiving: false });
      } else {
        this.setState({ archiving: false });
      }
    });
  }

  onFilter = (tag, e) => {
    const { allCampaigns } = this.state;
    e.preventDefault();
    if (tag === 'running') {
      const filteredCampaigns = allCampaigns.filter(t => t.status === tag);
      this.setState({
        campaigns: filteredCampaigns,
        currentTag: 'running',
      });
    } else {
      const filteredCampaigns = allCampaigns.filter(t => t.status === tag);
      this.setState({
        campaigns: filteredCampaigns,
        currentTag: tag,
      });
    }
  };

  toggleModalUpgrade = () => {
    const { modalUpgrade } = this.state;
    setTimeout(() => { this.setState({ modalUpgrade: !modalUpgrade }); }, 500);
  };

  createNewCampaign = () => {
    const { user } = this.state;
    const { history } = this.props;

    if (user.isCustomer) {
      history.push('/campaigns/create');
    } else {
      this.setState({ modalUpgrade: true });
    }
  }

  render = () => {
    const {
      user, loading, loaded, campaigns, error, pausing, modalUpgrade, infoLoaded, currentTag, allCampaigns, archiving,
    } = this.state;

    return (
      <Container>
        <AccountWarnings
          showModalTrial
        />
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Your Campaigns</h3>
          </Col>
          <Col xs={12} md={9} xl={7}>
            <p className="page-description">
              <strong>Automate your outreach to investors</strong><br />
              Contact investors in one click, schedule follow ups and gauge investors&#39; interest in no time in order
              to maximize the efficiency of your fundraising efforts.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={{ size: 10, offset: 1 }} className="mb-4 mt-5 mt-xl-4">
            {loaded && campaigns && campaigns.length > 0
            && (
              <Link to="/investors-db">
                <Button
                  className="icon float-right rounded ml-3"
                  color="primary"
                >
                  <p>
                    Add Investors
                    <PlusIcon />
                  </p>
                </Button>
              </Link>
            )}
            {allCampaigns && allCampaigns.length > 0
            && (
              <Button
                className="icon float-right rounded"
                outline
                color="primary"
                onClick={() => this.createNewCampaign()}
              >
                <p>
                  New Campaign
                  <PlusIcon />
                </p>
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ size: 11, offset: 1 }} xl={{ size: 10, offset: 1 }}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!loaded
              ? (
                <div className={`load${loading ? '' : ' loaded'} inload`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              )
              : allCampaigns && allCampaigns.length !== 0
                ? (
                  <div>
                    <div className="campaigns-filters">
                      <div className="campaigns-filters__btns">
                        {tags.map(btn => (
                          <button
                            type="button"
                            key={btn.name}
                            className={
                              `campaigns-filters__btn
                              ${btn.name === currentTag ? ' campaigns-filters__btn--active' : ''}`
                            }
                            onClick={e => this.onFilter(btn.name, e)}
                          >
                            {btn.icon}&nbsp;
                            <span>{btn.title}</span>
                            &nbsp;
                            <span className={`badge ${btn.name === currentTag ? 'badge-enabled' : 'badge-disabled'}`}>
                              {allCampaigns.filter(campaign => campaign.status === btn.name).length}
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>
                    <ListElements
                      confirmPause={id => this.confirmPause(id)}
                      archiving={archiving}
                      pausing={pausing}
                      confirmArchive={id => this.confirmArchive(id)}
                      infoLoaded={infoLoaded}
                      campaigns={campaigns}
                    />
                  </div>
                )
                : <FillerElement error={error} loaded={loaded} user={user} />
            }
          </Col>
        </Row>
        <Modal
          color="primary"
          title="Upgrade Required!"
          colored
          displayed={modalUpgrade}
          toggleModal={() => this.toggleModalUpgrade()}
          message={`Please upgrade your account to a paid plan if you wish to create more campaigns.
           You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
           Pricing Page</a>.`}
        />
      </Container>
    );
  };
}

export default withRouter(Campaigns);
