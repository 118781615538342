import React, { PureComponent, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import validate from './formValidate/formBlankEmail';
import renderTextFieldBasic from '../../form/TextFieldBasic';
import Alert from '../../alerts/Alert';

class BlankEmailForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      templates: PropTypes.object,
    }),
    investorContactInfo: PropTypes.shape({
      contactId: PropTypes.number,
      fullName: PropTypes.string,
      investorId: PropTypes.number,
      email: PropTypes.bool,
    }).isRequired,
    activeTab: PropTypes.number.isRequired,
    formError: PropTypes.string,
    user: PropTypes.shape({
      mailboxConnected: PropTypes.bool,
      completion: PropTypes.number,
    }).isRequired,
  };

  static defaultProps = {
    initialValues: null,
    formError: null,
  }

  renderQuill = (field) => {
    const { input } = field;
    const { touched, error } = field.meta;

    const modules = useMemo(() => ({
      toolbar: [
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
      ],
    }), []);

    // Use plain HTML value or fallback to an empty paragraph
    const sanitizedValue = input.value || '<p><br></p>';

    const handleEditorChange = (content, delta, source, editor) => {
      // Update form with sanitized HTML content
      input.onChange(editor.getHTML());
    };

    const handleEditorBlur = () => {
      // Call blur without modifying state, ensuring validation triggers without modifying content
      input.onBlur(input.value);
    };

    return (
      <div className="form__form-group-input-wrap campaign-quill-editor-wrapper">
        <ReactQuill
          {...input}
          value={sanitizedValue}
          onChange={handleEditorChange}
          onBlur={handleEditorBlur}
          modules={modules}
          className="campaign-quill-editor"
        />
        {touched && error && <span className="form__form-group-error">{error}</span>}
      </div>
    );
  };

  render() {
    const {
      handleSubmit, onSubmit, investorContactInfo, formError, user,
    } = this.props;

    return (
      <form
        className="form form--horizontal modal-campaigns"
        onSubmit={handleSubmit(onSubmit.bind(this))}
      >
        {!user.appSubmitted ? (
          <Alert color="warning" className="alert--colored mt-4 ml-lg-5">
            You can schedule this email now but your message will not be sent until you have completed your profile
          </Alert>
        ) : !user.mailboxConnected && (
          <Alert color="warning" className="alert--colored mt-4 ml-lg-5">
            You can schedule this email now but your message will not be sent until you have connected your mailbox.
          </Alert>
        )}

        {formError && (
          <Alert color="danger" className="alert--colored ml-4 ml-lg-5">
            <p><span className="bold-text">Error!</span> {formError}</p>
          </Alert>
        )}
        <div className="focus-form mt-5 w-100 pr-0 pl-0 pr-lg-5 pl-lg-5">
          <div className="form__form-group light">
            <Field
              name="title"
              component={renderTextFieldBasic}
              type="text"
              placeholder="Subject"
            />
          </div>
          <div className="form__form-group">
            <Field
              name="content"
              component={this.renderQuill}
            />
          </div>
        </div>
        {investorContactInfo && investorContactInfo.email && (
          <Button className="submit-campaign rounded icon icon--right icon--blue" color="primary" type="submit">
            Send Email
            <ChevronRightIcon />
          </Button>
        )}
      </form>
    );
  }
}

export default reduxForm({
  form: 'modalCampaigns',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
})(BlankEmailForm);
