import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import DeclineButton from '../../../shared/components/founders/DeclineButton';
import PingButton from '../../../shared/components/founders/PingButton';

const defaultLogo = `${process.env.PUBLIC_URL}/img/nologo.jpg`;

const FounderMain = ({
  startup, decline, ping, connect,
}) => (
  <Row className="startup__information">
    <Col className="startup__logo" xs={12} md={4} lg={2}>
      <img
        src={startup.logo
          ? process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_LOGOS_PATH + startup.logo
          : defaultLogo}
        onError={(e) => { e.target.onerror = null; e.target.src = defaultLogo; }}
        alt="avatar"
        className="m-5 m-sm-0"
      />
    </Col>
    <Col className="startup__data text-center text-md-left" xs={12} md={8} lg={6}>
      <h1>{ startup.name }</h1>
      <p className="startup__short-desc text-muted">
        { startup.short_description }
      </p>
      <p className="startup__location text-center text-md-left m-3 m-md-0 mb-4 mb-md-0">
        <MapMarkerIcon />
        { startup.location_city } | { startup.location_country }
      </p>
      <div className="startup__actions text-center text-md-left">
        <DeclineButton
          founder={startup}
          decline={decline}
        />
        <PingButton
          founder={startup}
          ping={ping}
          connect={connect}
        />
      </div>
    </Col>
    <Col className="startup__info text-center text-lg-left pt-5 pt-lg-0 mt-3 mt-lg-0" xs={12} lg={4}>
      {startup.category && (
        <p>
          Sector{startup.secondary_category && 's'}:&nbsp;&nbsp;
          <span className="label label-primary d-inline-block">{startup.category}</span>
          {startup.secondary_category && (
            <span className="label label-primary mt-2 d-inline-block">{startup.secondary_category}</span>
          )}
        </p>
      )}
      {startup.incorporated && (
        <p>
          Incorporated:&nbsp;&nbsp;
          <span>{startup.incorporated}</span>
        </p>
      )}
      {startup.types && (
        <p>
          Type of Business:&nbsp;&nbsp;
          <span>{startup.types}</span>
        </p>
      )}
      {startup.stage && (
        <p>
          Business Stage:&nbsp;&nbsp;
          <span>{startup.stage}</span>
        </p>
      )}
      <p className="startup_contact">
        Contact:&nbsp;&nbsp;
        <span>{startup.first_name} {startup.last_name}</span>
      </p>
      {startup.url && (
        <p>
          <a
            href={startup.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {startup.url}
          </a>
        </p>
      )}
    </Col>
  </Row>
);

export default FounderMain;

FounderMain.propTypes = {
  startup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    short_description: PropTypes.string,
    logo: PropTypes.string,
    incorporated: PropTypes.string,
    location_city: PropTypes.string,
    location_country: PropTypes.string,
    url: PropTypes.string,
    stage: PropTypes.string,
    category: PropTypes.string,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    new: PropTypes.bool,
  }).isRequired,
  ping: PropTypes.func.isRequired,
  connect: PropTypes.func.isRequired,
  decline: PropTypes.func.isRequired,
};
