import React from 'react';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderDropZoneField from '../../../shared/components/form/DropZone';

const FileUploadCustomHeight = ({ handleSubmit, reset }) => (
  <div>
    <div className="card__title">
      <h5 className="bold-text">Upload Your Photo</h5>
      <h5 className="subhead">
        Upload your photo to make your profile more attractive.
      </h5>
    </div>
    <form className="form" onSubmit={handleSubmit}>
      <Field
        name="files"
        component={renderDropZoneField}
        customHeight
      />
      <ButtonToolbar className="form__button-toolbar">
        <Button className="rounded" color="primary" type="submit">Submit</Button>
        <Button className="rounded" type="button" onClick={reset}>
          Cancel
        </Button>
      </ButtonToolbar>
    </form>
  </div>
);

FileUploadCustomHeight.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'file_upload_custom_height', // a unique identifier for this form
})(FileUploadCustomHeight);
