import React from 'react';
import { Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const ProfileBasic = ({
  firstName, lastName, picture, locationCity, locationCountry, planType, dateSubscribed, dateTrialCancelled,
  dateTrialEnd, datePlanEnd, willRenew, cancelSubscriptionToggle, isCustomer,
}) => (
  <Col md={12}>
    <div className="row settings__card">
      <div className="text-center col-12 col-md-6 col-xl-12">
        <img
          src={picture && picture !== ''
            ? process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_INVESTORS_PATH + picture
            : userIcon}
          onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
          alt="avatar"
        />
        <h4 className="profile__name">{ firstName } { lastName }</h4>
        <p className="card__location">
          <MapMarkerIcon />
          { locationCity } | { locationCountry }
        </p>
      </div>
      <div className="col-12 col-md-6 col-xl-12">
        <Table responsive>
          <tbody>
            <tr>
              <td>Account Creation</td>
              <td>{ dateSubscribed }</td>
            </tr>
            <tr>
              <td>Account Type</td>
              <td>{ planType }</td>
            </tr>
            {datePlanEnd
            && (
              <tr>
                <td>{willRenew ? 'Plan Renews On' : 'Plan Ends On' }</td>
                <td>{ datePlanEnd }</td>
              </tr>
            )}
            {dateTrialCancelled && !datePlanEnd
            && (
              <tr>
                <td>Trial Cancelled</td>
                <td>&nbsp;</td>
              </tr>
            )}
            {dateTrialEnd && !datePlanEnd && !dateTrialCancelled
            && (
              <tr>
                <td>Trial Ends On</td>
                <td>{ dateTrialEnd }</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="settings__actions ml-auto mr-auto mt-xl-5 mt-5">
        <div className="text-center">
          {isCustomer && willRenew
            && (
              <button
                type="button"
                className="text-danger border-0 bg-transparent"
                onClick={cancelSubscriptionToggle}
              >
                <small>CANCEL SUBSCRIPTION</small>
              </button>
            )}
        </div>
      </div>
    </div>
  </Col>
);

export default ProfileBasic;

ProfileBasic.defaultProps = {
  firstName: '',
  lastName: '',
  picture: '',
  locationCity: '',
  locationCountry: '',
  planType: '',
  dateSubscribed: '',
  willRenew: 0,
  datePlanEnd: null,
  dateTrialEnd: null,
  dateTrialCancelled: null,
};

ProfileBasic.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  picture: PropTypes.string,
  locationCity: PropTypes.string,
  locationCountry: PropTypes.string,
  planType: PropTypes.string,
  dateSubscribed: PropTypes.string,
  datePlanEnd: PropTypes.string,
  dateTrialEnd: PropTypes.string,
  dateTrialCancelled: PropTypes.string,
  willRenew: PropTypes.bool,
  isCustomer: PropTypes.bool.isRequired,
  cancelSubscriptionToggle: PropTypes.func.isRequired,
};
