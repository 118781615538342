/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (values.valuation === "") {
    errors.valuation = 'Please specify your valuation';
  } else if (values.valuation < 0) {
    errors.valuation = 'Your valuation must be positive';
  }
  if (values.raising === "") {
    errors.raising = 'Please specify how much you are raising';
  } else if (values.raising < 0) {
    errors.raising = 'This must be positive';
  }
  if (values.raised === "") {
    errors.raised = 'Please specify how much have you raised';
  } else if (values.raised < 0) {
    errors.raised = 'This must be positive';
  }
  if (values.minimum === "") {
    errors.minimum = 'Please specify the minimum investment for this round';
  } else if (values.minimum < 0) {
    errors.minimum = 'This must be positive';
  }

  if (values.rounds && values.rounds.length < 10) {
    errors.rounds = 'Your round description should be at least 10 characters long.';
  } else if (values.rounds && values.rounds.length > 1500) {
    errors.rounds = 'Your round description should be less than 1500 characters long.';
  }
  // if (!values.dateClose) {
  //   errors.dateClose = 'Please specify when do you plan to close your round';
  // }

  return errors;
};

export default validate;
