import React, { PureComponent } from 'react';
import {
  Col, TabContent, TabPane, NavItem, NavLink, Nav, Row, Button, Card, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactPlayer from 'react-player';
import { withRouter } from 'react-router';
import axios from 'axios';

import StartupRoundTab from './tabs/StartupRoundTab';
import Collapse from '../Collapse';
import SimStartupItem from '../../../containers/StartupPage/components/SimStartupItem';
import PdfViewer from '../pdfViewer/PdfViewer';

class StartupTabs extends PureComponent {
  static defaultProps = {
    link: null,
    similarStartups: null,
    id: 0,
    userId: 0,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      minHeight: 0,
      deckUrl: null,
    };
  }

  componentDidMount() {
    const { startup } = this.props;
    const video = document.getElementById('video');
    if (video) {
      this.setState({ minHeight: video.offsetHeight });
    }

    if (startup.deck && !startup.video) {
      this.getDeckUrl();
    }
  }

  getDeckUrl = () => {
    const { startup } = this.props;

    axios.get(`startup/deck/${startup.id}`)
      .then((response) => {
        if (response.data.split('.').pop() === 'pdf') {
          this.setState({
            deckUrl: process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_DECKS_PATH + response.data,
            // loadingVideo: false,
          });
        }
      });
  }

  openDeck = (e) => {
    e.preventDefault();
    const { startup, sendNotification, history } = this.props;
    const { link } = this.props;
    sendNotification('deck');

    if (startup.deck.split('.').pop() === 'pdf' && link) {
      history.push(`/summary/deck/${link}`);
    } else if (startup.deck.split('.').pop() === 'pdf') {
      history.push(`/startup/deck/${startup.id}`);
    } else {
      window.open(
        process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_DECKS_PATH + startup.deck,
        '_blank',
      );
    }
  };

  getSimilarStartups = (simStartups) => {
    if (simStartups && Array.isArray(simStartups) && simStartups.length > 0) {
      return (
        simStartups.map(startup => (
          <SimStartupItem
            key={startup.id}
            {...startup}
          />
        ))
      );
    }

    return null;
  };

  openFinancials = (e) => {
    e.preventDefault();
    const { startup, sendNotification, history } = this.props;
    const { link } = this.props;
    sendNotification('financials');

    if (startup.financials.split('.').pop() === 'pdf' && link) {
      history.push(`/summary/financials/${link}`);
    } else if (startup.financials.split('.').pop() === 'pdf') {
      history.push(`/startup/financials/${startup.id}`);
    } else {
      window.open(
        process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_FINANCIALS_PATH + encodeURI(startup.financials),
        '_blank',
      );
    }
  };

  openMaterials = (e) => {
    e.preventDefault();
    const { startup, sendNotification } = this.props;

    sendNotification('materials');
    window.open(
      startup.materials_url,
      '_blank',
    );
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const {
      activeTab, minHeight, deckUrl,
    } = this.state;
    const {
      startup, similarStartups, id, userId,
    } = this.props;

    let offsetD = 0;
    let offsetM = 0;
    let offsetF = 0;
    if (startup.financials && startup.materials && startup.deck) {
      offsetD = 0;
    } else if (startup.deck
      && ((startup.financials && !startup.materials) || (!startup.financials && startup.materials))) {
      offsetD = 2;
    } else if (startup.deck && !startup.financials && !startup.materials) {
      offsetD = 4;
    } else if (!startup.deck && startup.financials && startup.materials) {
      offsetF = 2;
    } else if (!startup.deck && !startup.financials && startup.materials) {
      offsetM = 4;
    } else if (!startup.deck && startup.financials && !startup.materials) {
      offsetF = 4;
    }

    return (
      <div>
        <Row className="startup__information">
          <Col xs={12} xl={8}>
            {startup.video
            && (
              <Row>
                <Col xs={12}>
                  <ReactPlayer
                    id="video"
                    className="mb-4"
                    url={startup.video}
                    width="100%"
                    light
                    controls
                  />
                </Col>
              </Row>
            )}
            {deckUrl && (!startup.video || startup.video === '')
            && (
              <div className="mb-4">
                <PdfViewer
                  url={deckUrl}
                  height="400px"
                  className="BoxedPDFViewer"
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={this.openDeck}
                  onKeyDown={this.openDeck}
                  className="PDFViewerWrapper"
                >
                  <div className="text-overlay">Click to see full presentation</div>
                </div>
              </div>
            )}
            <Row className="d-xl-none d-block"> {/* ONLY FOR MOBILE */}
              <Col xs={12}>
                <div className="startup__business mb-5">
                  <h2>Business Model</h2>
                  <p>
                    {startup.description}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="startup__information m-auto">
                <Row>
                  {startup.deck
                  && (
                    <Col xs={12} md={{ size: 4, offset: offsetD }}>
                      <Button
                        color="primary"
                        className="download-deck w-100"
                        onClick={this.openDeck}
                        onKeyDown={this.openDeck}
                      >
                        <img
                          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/deck-icon.png`}
                          alt="deck Icon"
                        />
                        <div className="break" />
                        <p>
                          Investor Presentation
                        </p>
                      </Button>
                    </Col>
                  )}
                  {startup.financials
                  && (
                    <Col xs={12} md={{ size: 4, offset: offsetF }}>
                      <Button
                        color="primary"
                        className="download-deck w-100"
                        onClick={this.openFinancials}
                        onKeyDown={this.openFinancials}
                      >
                        <img
                          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/financials-icon.png`}
                          alt="financials"
                        />
                        <div className="break" />
                        <p>
                          Financials Projections
                        </p>
                      </Button>
                    </Col>
                  )}
                  {startup.materials
                  && (
                    <Col xs={12} md={{ size: 4, offset: offsetM }}>
                      <Button
                        color="primary"
                        className="download-deck btn w-100"
                        onClick={this.openMaterials}
                        onKeyDown={this.openMaterials}
                      >
                        <img
                          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/materials-icon.png`}
                          alt="materials Icon"
                        />
                        <div className="break" />
                        <p>
                          Materials
                        </p>
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row className="d-block d-md-none">
              <Col xs={12}>
                <Collapse title="Team" className="with_border pre-wrap" isOpened>
                  <p>
                    {startup.team}
                  </p>
                </Collapse>
                {startup.market
                && (
                  <Collapse title="Market" className="with_border pre-wrap">
                    <p>
                      {startup.market}
                    </p>
                  </Collapse>
                )}
                {startup.traction
                && (
                  <Collapse title="Traction" className="with_border pre-wrap">
                    <p>
                      {startup.traction}
                    </p>
                  </Collapse>
                )}
                {startup.rounds
                && (
                  <Collapse title="Round" className="with_border pre-wrap">
                    <p>
                      {startup.rounds}
                    </p>
                  </Collapse>
                )}
                {startup.updates
                && (
                  <Collapse title="Update" className="with_border pre-wrap">
                    <p>
                      {startup.updates}
                    </p>
                  </Collapse>
                )}
              </Col>
            </Row>
            <Row className="d-none d-md-block">
              <Col xs={12}>
                <div className="tabs--justify tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '1' })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          Team
                        </NavLink>
                      </NavItem>
                      {startup.market
                      && (
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => {
                              this.toggle('2');
                            }}
                          >
                            Market
                          </NavLink>
                        </NavItem>
                      )}
                      {startup.traction
                        && (
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => {
                              this.toggle('3');
                            }}
                          >
                            Traction
                          </NavLink>
                        </NavItem>
                        )}
                      {startup.rounds
                        && (
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '4' })}
                            onClick={() => {
                              this.toggle('4');
                            }}
                          >
                            Previous Rounds
                          </NavLink>
                        </NavItem>
                        )}
                      {startup.updates
                        && (
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '5' })}
                            onClick={() => {
                              this.toggle('5');
                            }}
                          >
                            Updates
                          </NavLink>
                        </NavItem>
                        )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <p className="text-muted pre-wrap">
                          {startup.team}
                        </p>
                      </TabPane>
                      {startup.market
                      && (
                        <TabPane tabId="2">
                          <p className="text-muted pre-wrap">
                            {startup.market}
                          </p>
                        </TabPane>
                      )}
                      {startup.traction
                        && (
                          <TabPane tabId="3">
                            <p className="text-muted pre-wrap">
                              {startup.traction}
                            </p>
                          </TabPane>
                        )}
                      {startup.rounds
                        && (
                          <TabPane tabId="4">
                            <p className="text-muted pre-wrap">
                              {startup.rounds}
                            </p>
                          </TabPane>
                        )}
                      {startup.updates
                        && (
                          <TabPane tabId="5">
                            <p className="text-muted pre-wrap">
                              {startup.updates}
                            </p>
                          </TabPane>
                        )}
                    </TabContent>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={4}>
            <Row className="d-none d-xl-block">
              <Col xs={12}>
                <div className="startup__business pre-wrap" style={{ minHeight }}>
                  <h2>Business Model</h2>
                  <p>
                    {startup.description}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <StartupRoundTab
                  raising={startup.raising}
                  raised={startup.raised}
                  revenues={startup.revenues}
                  minimum={startup.minimum}
                  valuation={startup.valuation}
                  dateClose={startup.dateClose}
                  currency={startup.currency}
                />
              </Col>
            </Row>
            {id !== userId && similarStartups && similarStartups.length
              ? (
                <Row className="d-none d-xl-block">
                  <Col xs={12}>
                    <Card className="bg-transparent startup__similar">
                      <CardBody>
                        <h4>Similar Startups</h4>
                        {this.getSimilarStartups(similarStartups)}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(StartupTabs);

StartupTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  sendNotification: PropTypes.func.isRequired,
  link: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  similarStartups: PropTypes.array,
  id: PropTypes.number,
  userId: PropTypes.number,
  startup: PropTypes.shape({
    id: PropTypes.number,
    currency: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    updates: PropTypes.string,
    rounds: PropTypes.string,
    team: PropTypes.string,
    logo: PropTypes.string,
    deck: PropTypes.string,
    deck_update: PropTypes.string,
    financials: PropTypes.string,
    financials_update: PropTypes.string,
    materials: PropTypes.string,
    incorporated: PropTypes.string,
    location_city: PropTypes.string,
    location_country: PropTypes.string,
    valuation: PropTypes.number,
    raising: PropTypes.number,
    raised: PropTypes.number,
    minimum: PropTypes.number,
    revenues: PropTypes.number,
    dateClose: PropTypes.string,
    url: PropTypes.string,
    highlighted: PropTypes.bool,
    stage: PropTypes.string,
    category: PropTypes.string,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    new: PropTypes.bool,
    favorite: PropTypes.bool,
    pinging: PropTypes.bool,
    adding: PropTypes.bool,
    accepted: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
};
