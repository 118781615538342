/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card, CardBody, Row, Col, Button,
} from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import StarsIcon from 'mdi-react/StarsIcon';
import MailIcon from 'mdi-react/EmailOutlineIcon';
import MailCheckIcon from 'mdi-react/EmailCheckOutlineIcon';
import MailMultipleIcon from 'mdi-react/EmailMultipleOutlineIcon';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import FlagVariantOutlineIcon from 'mdi-react/FlagVariantOutlineIcon';
import CurrencyFormat from 'react-currency-format';
import HandshakeIcon from 'mdi-react/HandshakeIcon';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

export default class InvestorBox extends PureComponent {
  static defaultProps = {
    investor: [],
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  clickInsideLink = (e) => {
    e.stopPropagation();
  }

  handleClick = () => {
    const scrollYOffset = window.pageYOffset;
    sessionStorage.setItem('scrollYOffsetExt', scrollYOffset);
    sessionStorage.setItem('scrollActiveExt', false);
  }

  render() {
    const {
      investor, clickFav, clickMail, clickReport, location, findIntro,
    } = this.props;

    function renderMailButton() {
      if (location && location.pathname === '/warm-intro' && !investor.isProspect) {
        return (
          <Button
            className="icon extinvestor__mail-btn icon--right rounded"
            color="primary"
            onMouseDown={(e) => { e.preventDefault(); findIntro(e); }}
            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { e.preventDefault(); findIntro(e); } }}
          >
            <p>Find Intro <HandshakeIcon /></p>
          </Button>
        );
      }
      if (location && location.pathname === '/warm-intro' && investor.isProspect) {
        return (
          <Button
            className="icon extinvestor__mail-btn icon--right rounded"
            color="primary"
            disabled
          >
            <p>Introduced <HandshakeIcon /></p>
          </Button>
        );
      }
      if (investor.inCampaign && investor.contacted) {
        return (
          <Button
            className="icon extinvestor__mail-btn icon--right rounded"
            color="secondary"
            onClick={clickMail}
            outline
            id={`Tooltip3Left${investor.id}`}
          >
            <p>Contacted <MailCheckIcon /></p>
          </Button>
        );
      }
      if (investor.inCampaign && !investor.contacted) {
        return (
          <Button
            className="icon extinvestor__mail-btn icon--right rounded"
            color="secondary"
            onClick={clickMail}
            outline
            id={`Tooltip3Left${investor.id}`}
          >
            <p>In Campaign <MailMultipleIcon /></p>
          </Button>
        );
      } if (investor.email || investor.connectedInvestors) {
        return (
          <Button
            id={`Tooltip2Left${investor.id}`}
            className="icon extinvestor__mail-btn icon--right rounded"
            color="primary"
            onMouseDown={e => clickMail(e)}
            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { clickMail(e); } }}
          >
            <p>Contact <MailIcon /></p>
          </Button>
        );
      }
      return null;
    }

    function renderFavButton() {
      const top = !investor.email && !investor.connectedInvestors ? 'extinvestor__fav-btn-top' : '';
      if (investor.favorite) {
        return (
          <Button
            className={`icon rounded icon-only extinvestor__fav-btn ${top}`}
            color="warning"
            onMouseDown={(e) => { e.preventDefault(); clickFav(e); }}
            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { e.preventDefault(); clickFav(e); } }}
          >
            <p><StarsIcon /></p>
          </Button>
        );
      } if (investor.adding) {
        return (
          <Button className={`icon rounded icon-only extinvestor__fav-btn ${top}`} color="warning" outline>
            <p><LoadingIcon className="mdi-icon-spinning" /></p>
          </Button>
        );
      }
      return (
        <Button
          id={`TooltipLeft${investor.id}`}
          className="icon rounded icon-only extinvestor__fav-btn"
          color="primary"
          outline
          onMouseDown={(e) => { e.preventDefault(); clickFav(e); }}
          onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { e.preventDefault(); clickFav(e); } }}
        >
          <p><StarsIcon /></p>
        </Button>
      );
    }

    const rounds = investor.rounds && Array.isArray(investor.rounds) ? investor.rounds.slice(0, 7).map((r, x) => (
      <li key={x}>
        <strong>{r.company}</strong>
        {r.investmentSize
        && (
          <span>&nbsp; &bull; &nbsp;
            <CurrencyFormat value={r.investmentSize} displayType="text" thousandSeparator prefix="$" />
          </span>
        )}
        {r.round
        && (
          <span>&nbsp; &bull; &nbsp;{r.round}</span>
        )}
      </li>
    )) : null;

    return (
      <div className="extinvestor__item__wrapper">
        <div className="extinvestor__buttons">
          {renderMailButton()}
          {renderFavButton()}
        </div>
        <Link
          to={`/investor-db/${investor.id}`}
          onClick={() => this.handleClick()}
          // Bug with warm intro it clicks on the box even if clicking on a button
          // onMouseDown={() => this.handleClick()}
          // onKeyUp={(e) => {
          //   if ((e.keyCode === 13 || e.keyCode === 32)) {
          //     this.handleClick();
          //   }
          // }}
          className="extinvestor__item__link"
        >
          <Card>
            <CardBody className="extinvestor__item" key={investor.id} id={`investor_box_${investor.id}`}>
              <Row>
                <Col sm={4} lg={2}>
                  <div className="extinvestor__img-wrapper mb-5 mb-sm-0">
                    <img
                      src={
                        investor.picture
                          ? process.env.REACT_APP_AWS_ROOT
                          + process.env.REACT_APP_AWS_INVESTORS_PATH
                          + investor.picture
                          : userIcon
                      }
                      onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
                      className="extinvestor__img"
                      alt="Investor"
                    />
                    {investor.isFirm && <p className="extinvestor__firm">Firm</p>}
                    {investor.popular
                    && <p className="extinvestor__popular" id={`TooltipPopular${investor.id}`}>Popular</p>}
                  </div>
                </Col>
                <Col sm={8} lg={4} className="extinvestor__info">
                  <div className="border-right">
                    <h2>{investor.fullName}</h2>
                    {(investor.company || investor.companyRole)
                    && (
                      <p className="text-muted mt-0 extinvestor__companyRole">
                        {investor.investorType && investor.investorType.toLowerCase().includes('angel')
                        && investor.companyRole && investor.companyRole.toLowerCase() !== 'angel'
                          ? 'Angel & ' : ''}
                        {investor.companyRole}
                        {investor.companyRole && investor.company && ' at '}
                        {investor.company != null && investor.company.toLowerCase() !== 'angel' && investor.company}
                      </p>
                    )}
                    {investor.investorType && investor.investorType.toLowerCase().includes('angel')
                    && !investor.company && !investor.companyRole
                    && (
                      <p className="text-muted mt-0">
                        Angel
                      </p>
                    )}
                    <div className="extinvestor__additional">
                      {investor.website
                      && (
                        <a
                          href={investor.website}
                          rel="noreferrer noopener"
                          target="_blank"
                          onClick={this.clickInsideLink}
                        >
                          <img
                            src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/w-round.png`}
                            alt="website"
                            className="extinvestor-links mb-3"
                          />
                        </a>
                      )}
                      {investor.linkedin
                      && (
                        <a
                          href={investor.linkedin}
                          rel="noreferrer noopener"
                          target="_blank"
                          onClick={this.clickInsideLink}
                        >
                          <img
                            src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/li-round.png`}
                            alt="linkedin"
                            className=" extinvestor-links mb-3"
                          />
                        </a>
                      )}
                      {investor.crunchbase
                      && (
                        <a
                          href={investor.crunchbase}
                          rel="noreferrer noopener"
                          target="_blank"
                          onClick={this.clickInsideLink}
                        >
                          <img
                            src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/cb-round.png`}
                            alt="crunchbase"
                            className=" extinvestor-links mb-3"
                          />
                        </a>
                      )}
                      {investor.angellist
                      && (
                        <a
                          href={investor.angellist}
                          rel="noreferrer noopener"
                          target="_blank"
                          onClick={this.clickInsideLink}
                        >
                          <img
                            src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/al-round.png`}
                            alt="angellist"
                            className=" extinvestor-links mb-3"
                          />
                        </a>
                      )}
                      {investor.facebook
                      && (
                        <a
                          href={investor.facebook}
                          rel="noreferrer noopener"
                          target="_blank"
                          onClick={this.clickInsideLink}
                        >
                          <img
                            src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/fb-round.png`}
                            alt="facebook"
                            className=" extinvestor-links mb-3"
                          />
                        </a>
                      )}
                      {investor.twitter
                      && (
                        <a
                          href={investor.twitter}
                          rel="noreferrer noopener"
                          target="_blank"
                          onClick={this.clickInsideLink}
                        >
                          <img
                            src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/tw-round.png`}
                            alt="twitter"
                            className=" extinvestor-links mb-3"
                          />
                        </a>
                      )}
                      {investor.google
                      && (
                        <a
                          href={investor.google}
                          rel="noreferrer noopener"
                          target="_blank"
                          onClick={this.clickInsideLink}
                        >
                          <img
                            src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/g-round.png`}
                            alt="google"
                            className=" extinvestor-links mb-3"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="extinvestor__content">
                    <small
                      className="extinvestor__report"
                      role="presentation"
                      onMouseDown={e => clickReport(e)}
                      onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { clickReport(e); } }}
                    >
                      <FlagVariantOutlineIcon />
                    </small>
                    {investor.description
                    && (
                      <div>
                        <div
                          className="extinvestor__description-short text-muted mt-2"
                          dangerouslySetInnerHTML={{ __html: investor.description }}
                        />
                      </div>
                    )}
                    {!investor.description && investor.linkedinDescription
                    && (
                      <div>
                        <div
                          className="extinvestor__description-short text-muted mt-2"
                          dangerouslySetInnerHTML={{ __html: investor.linkedinDescription }}
                        />
                      </div>
                    )}
                    <p className="mt-4 mb-4">
                      <span className="text-muted">Operates In: </span>
                      {investor.locationCity ? investor.locationCity.replace('/n', '-') : ''}
                    </p>
                    {!investor.description && !investor.linkedinDescription && investor.rounds && rounds
                    && (
                      <div className="extinvestor__investments">
                        <span className="text-muted">Previous Investments: </span>
                        {rounds}
                      </div>
                    )}
                    {!investor.description && !investor.linkedinDescription && !investor.rounds && investor.categories
                    && (
                      <div>
                        <span className="text-muted">Invests In: </span>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div className="bottom-15" dangerouslySetInnerHTML={{ __html: investor.categories }} />
                      </div>
                    )}

                    {/* {investor.connectedInvestors */}
                    {/* && ( */}
                    {/*  <ul className="firms-contact-list bottom-15"> */}
                    {/*    {contacts} */}
                    {/*  </ul> */}
                    {/* )} */}
                    <Link
                      to={`/investor-db/${investor.id}`}
                      className="see-more float-right"
                      onClick={this.clickInsideLink}
                    >
                      Show More <ChevronRightIcon />
                    </Link>
                  </div>
                </Col>
              </Row>
            </CardBody>
            {!investor.adding && !investor.favorite
            && (
              <UncontrolledTooltip
                placement="bottom"
                target={`TooltipLeft${investor.id}`}
                onClick={this.clickInsideLink}
              >
                Add to your favorites
              </UncontrolledTooltip>
            )}
            {investor.popular
            && (
              <UncontrolledTooltip
                placement="bottom"
                target={`TooltipPopular${investor.id}`}
                onClick={this.clickInsideLink}
              >
                This investor is very popular and connected, being a plus to your round while also being less likely to
                respond.
              </UncontrolledTooltip>
            )}
            {!investor.inCampaign && (investor.email || investor.connectedInvestors)
            && !(location && location.pathname === '/warm-intro')
            && (
              <UncontrolledTooltip placement="left" target={`Tooltip2Left${investor.id}`}>
                Contact this Investor
              </UncontrolledTooltip>
            )}
            {investor.inCampaign && !(location && location.pathname === '/warm-intro')
            && (
              <UncontrolledTooltip placement="left" target={`Tooltip3Left${investor.id}`}>
                {investor.contacted ? 'This investor has already been contacted' : 'This investor will be contacted'}
                {investor.contactedOn && ` on ${investor.contactedOn}. ` }
                &nbsp;You can contact this investor again.
              </UncontrolledTooltip>
            )}
          </Card>
        </Link>
      </div>
    );
  }
}

InvestorBox.propTypes = {
  investor: PropTypes.shape({
    new: PropTypes.number,
    pinged: PropTypes.bool,
    pinging: PropTypes.bool,
    adding: PropTypes.bool,

    angellist: PropTypes.string,
    // aum: PropTypes.number,
    contacted: PropTypes.bool,
    contactedOn: PropTypes.string,
    campaignId: PropTypes.number,
    campaignName: PropTypes.string,
    // categories: PropTypes.string,
    cleanedLocationCity: PropTypes.string,
    company: PropTypes.string,
    companyRole: PropTypes.string,
    connectedInvestors: PropTypes.arrayOf(
      PropTypes.shape({
        campaignId: PropTypes.number,
        campaignName: PropTypes.string,
        contactId: PropTypes.number,
        email: PropTypes.string,
        fullName: PropTypes.string,
        id: PropTypes.number,
        inCampaign: PropTypes.bool,
        picture: PropTypes.string,
        recommended: PropTypes.bool,
        role: PropTypes.string,
      }),
    ),
    contactId: PropTypes.number,
    crunchbase: PropTypes.string,
    currency: PropTypes.string,
    description: PropTypes.string,
    email: PropTypes.bool,
    // experience: PropTypes.string,
    facebook: PropTypes.string,
    favorite: PropTypes.bool,
    fullName: PropTypes.string,
    google: PropTypes.string,
    id: PropTypes.number,
    inCampaign: PropTypes.bool,
    // investmentMax: PropTypes.number,
    // investmentMin: PropTypes.number,
    isFirm: PropTypes.bool,
    linkedin: PropTypes.string,
    linkedinDescription: PropTypes.string,
    locationCity: PropTypes.string,
    locationCountry: PropTypes.string,
    picture: PropTypes.string,
    popular: PropTypes.bool,
    registered: PropTypes.bool,
    // stages: PropTypes.string,
    // type: PropTypes.string,
    twitter: PropTypes.string,
    visits: PropTypes.number,
    website: PropTypes.string,
  }),
  user: PropTypes.shape({
    isCustomer: PropTypes.bool,
    plan: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  clickFav: PropTypes.func.isRequired,
  clickMail: PropTypes.func.isRequired,
  findIntro: PropTypes.func.isRequired,
  clickReport: PropTypes.func.isRequired,
};
