/* eslint-disable */
const validate = (values) => {

  const errors = {};
  const regExp = /\[(.*?)\]/;
  const regExp2 = /\{{(.*?)\}}/;

  if (typeof values !== typeof undefined)
  {
    if (!('title' in values) || !values.title) {
      errors.title = 'Please enter a title';
    } else if (typeof values.title !== 'undefined' && values.title.length > 250) {
      errors.title = 'Please keep your title below 250 characters';
    } else if (typeof values.title !== 'undefined' && values.title.length < 2) {
      errors.title = 'Please enter a title longer than 2 characters';
    } else if (('content' in values) && values.title && regExp.exec(values.title) !== null) {
      errors.title = 'Replace ' + regExp.exec(values.title)[0] + ' with your own data. All text in brackets must be replaced.';
    } else if (('title' in values) && values.title && values.title.match(regExp2)) {
      errors.title = 'Do not insert dynamic {{variable}} in your title. You can ONLY use the dynamic variables in your campaigns';
    }

    console.log(values.content);
    if (typeof values.content === 'undefined' || !values.content || values.content === '') {
      errors.content = 'Please enter some content';
    } else if ( ('content' in values) && values.content && values.content.replace( /(<([^>]+)>)/ig, '').length > 2000) {
      errors.content = 'Keep your email below 2000 characters, investors tend to skip long emails.';
    } else if (('content' in values) && values.content && values.content.length < 30) {
      errors.content = 'Try to write something longer than 30 characters.';
    } else if (('content' in values) && values.content && regExp.exec(values.content) !== null) {
      errors.content = 'Replace ' + regExp.exec(values.content)[0] + ' with your own data. All text in brackets must be replaced.';
    } else if ( ('content' in values) && values.content && values.content.indexOf('angelspartners.com/startup') !== -1) {
      errors.content = 'Replace your link to angelspartners.com/startup with a tracking link.';
    } else if (('content' in values) && values.content && values.content.match(regExp2)) {
      errors.content = 'Do not insert dynamic {{variable}} in a direct email. You can ONLY use the dynamic variables in your campaigns';
    }
  } else {
    errors.title = 'Please enter a title';
    errors.content = 'This field cannot be left empty';
  }

  return errors;
};

export default validate;
