import React, { Component } from 'react';
import {
  Button, ButtonToolbar,
  Col, Modal, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import SendIcon from 'mdi-react/SendIcon';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const renderTextField = ({
  input, label, error, errorText, select, placeholder, multiline, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={error}
    type={type}
    helperText={errorText}
    value={input.value}
    select={select}
    multiline={multiline}
    placeholder={placeholder}
    name={input.name}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  select: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  label: '',
  placeholder: '',
  select: false,
  error: false,
  errorText: '',
  type: 'text',
  multiline: false,
};

class ModalFeedback extends Component {
  static propTypes = {
    displayed: PropTypes.bool,
    check: PropTypes.func,
    toggleModal: PropTypes.func.isRequired,
    defaultErrorState: PropTypes.shape({}),
    userId: PropTypes.number,
  };

  static defaultProps = {
    displayed: false,
    check: null,
    userId: null,
    defaultErrorState: {
      countInvestorsConnect: {
        error: false,
        errorMessage: '',
      },
      isInvestorInterested: {
        error: false,
        errorMessage: '',
      },
      experience: {
        error: false,
        errorMessage: '',
      },
    }
    ,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      invalid: false,
      formErrors: props.defaultErrorState,
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { modal } = this.state;
    if (modal === false && newProps.displayed === true) {
      const { check } = this.props;
      if (check !== null) {
        check().then((data) => {
          this.setState({ errorMessage: data });
        });
      }
    }
    this.setState({
      modal: newProps.displayed,
      title: 'Feedback',
    });
  }


  finish = () => {
    const {
      invalid, countInvestorsConnect, isInvestorInterested, experience,
    } = this.state;
    const {
      toggleModal, userId,
    } = this.props;

    if (!invalid) {
      axios.post('user/feedback', {
        id: userId,
        countInvestorsConnect,
        isInvestorInterested,
        experience,
      }).then((response) => {
        if (response.data) {
          toggleModal();
        }
      }).catch((error) => {
        if (error && error.response && error.response.data) {
          const { errors } = error.response.data;
          const formErrors = {
            countInvestorsConnect: {},
            isInvestorInterested: {},
            experience: {},
          };
          errors.forEach((item) => {
            if (Object.prototype.hasOwnProperty.call(formErrors, item.field)) {
              formErrors[item.field].error = true;
              formErrors[item.field].errorMessage = item.message;
            }
          });
          this.setState({ formErrors });
        }
      });
    }
  }

  handleChange(event) {
    if (event.target.name === 'countInvestorsConnect') {
      this.setState({ countInvestorsConnect: event.target.value });
    }
    if (event.target.name === 'isInvestorInterested') {
      this.setState({ isInvestorInterested: event.target.value });
    }
    if (event.target.name === 'experience') {
      this.setState({ experience: event.target.value });
    }
  }

  toggle(e) {
    e.preventDefault();
    const { toggleModal } = this.props;
    toggleModal();
  }

  render() {
    const {
      displayed, toggleModal,
    } = this.props;

    const {
      invalid, formErrors, title, isInvestorInterested, countInvestorsConnect, experience, errorMessage,
    } = this.state;

    return (
      <Modal
        isOpen={displayed}
        toggle={toggleModal}
        className="modal-dialog--primary modal-feedback x-large"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4>{title}</h4>
          {errorMessage}
          <img src={`${process.env.PUBLIC_URL}/img/linePurple.svg`} className="line" alt="line" />
        </div>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <form className="material-form">
              <div className="numberOfInvestors mt-5 mb-5">
                <span className="material-form__label">
                  How many investors did you connect via Angels Partners?
                </span>
                <Field
                  name="countInvestorsConnect"
                  component={renderTextField}
                  value={countInvestorsConnect}
                  error={formErrors.countInvestorsConnect.error}
                  errorText={formErrors.countInvestorsConnect.errorMessage}
                  type="number"
                  onChange={this.handleChange}
                />
              </div>
              <div className="mt-5 mb-4">
                <span className="material-form__label">Did any investor <strong>commit to an investment</strong>
                  or are you <strong>pursuing discussions</strong> with some of these investors?
                </span>
                <RadioGroup
                  aria-label="isInvestorInterested"
                  name="isInvestorInterested"
                  className="mt-1 flex-direction-initial"
                  value={isInvestorInterested}
                  onChange={this.handleChange}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Yes" />
                  <FormControlLabel value="0" control={<Radio defaultChecked />} label="No" />
                </RadioGroup>
              </div>
              <div className="textarea-feedback">
                <span className="material-form__label">How was your experience on the platform?</span>
                <Field
                  name="experience"
                  component={renderTextField}
                  value={experience}
                  error={formErrors.experience.error}
                  errorText={formErrors.experience.errorMessage}
                  type="text"
                  onChange={this.handleChange}
                  multiline
                />
              </div>
              <ButtonToolbar className="modal__footer float-right">
                {!invalid ? (
                  <Button
                    className="modal_ok rounded icon icon--right icon--blue"
                    type="button"
                    color="primary"
                    onClick={this.finish}
                  >
                    Cancel Now <SendIcon />
                  </Button>
                ) : ''}
              </ButtonToolbar>
            </form>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default reduxForm({
  form: 'feedback_form', // a unique identifier for this form
})(ModalFeedback);
