import React from 'react';

const PageRefillPlugin = () => (
  <div className="w-100 text-center p-5">
    <h1>Please <strong>add more investors</strong> to your campaign.</h1>
    <p>
      Click on &#34;Select Investors&#34; to continue.
    </p>
  </div>
);

export default PageRefillPlugin;
