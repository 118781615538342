import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const rows = [
  {
    id: 'visitor', disablePadding: true, label: '',
  },
  {
    id: 'visits', disablePadding: false, label: 'Visits',
  },
  {
    id: 'time', disablePadding: false, label: 'Duration',
  },
  {
    id: 'deck', disablePadding: false, label: 'Deck',
  },
  {
    id: 'financials', disablePadding: false, label: 'Financials',
  },
  {
    id: 'materials', disablePadding: false, label: 'Materials',
  },
];

class MatTableHead extends PureComponent {
  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      order, orderBy,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(row => (
            <TableCell
              className="material-table__cell material-table__cell--sort material-table__cell-right"
              key={row.id}
              align="left"
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={this.createSortHandler(row.id)}
                className="material-table__sort-label"
                dir="ltr"
              >
                {row.label === 'Deck'
                  ? (
                    <span className="deck-head">
                     Deck
                      <small>
                        <br />
                       Time Spent
                      </small>
                    </span>
                  )
                  : (
                    row.label
                  )}
              </TableSortLabel>
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(MatTableHead);
