import React from 'react';
import {
  Badge, Card, CardBody,
} from 'reactstrap';

const PanelLastDeal = () => (
  <Card className="panel panel--divider card shadow mb-3">
    <CardBody className="panel__body">
      <div className="panel__title no-padding">
        <h5 className="bold-text">
          <Badge className="panel__label">Improvement</Badge>
          &nbsp;&nbsp;&nbsp;
          Mention the Last Deal this investor partook in.
        </h5>
        {/* <h5 className="subhead">{subhead}</h5> */}
      </div>
      <div className="panel__content">
        <p className="typography-message">
          You should add the variable <strong>&#123;&#123;lastDeal&#125;&#125;</strong> to your templates in
          order to mention the last deal this investor partook in. Wrap your sentence with &#123;% if
          lastDeal %&#125; &#123;% endif %&#125; in case we do not have this data available.
        </p>
        <p>
          <strong>Example:</strong> &#123;% if lastDeal %&#125;Congrats on your last investment in
          &#123;&#123;lastDeal&#125;&#125;! &#123;% endif %&#125;
        </p>
        <p className="typography-message">
          We recommend using as much personalisation as possible to increase the likelihood of receiving a response
          back. Having analysed thousands of campaigns, our research demonstrated that templates using all 4 of our
          variables <strong><u>perform 75% better</u></strong> than campaigns that do not incorporate them.
        </p>
      </div>
    </CardBody>
  </Card>
);

export default PanelLastDeal;
