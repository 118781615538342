import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

const Sidebar = ({
  changeMobileSidebarVisibility, changeSidebarVisibility, sidebar, type, name, logo,
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
  });

  return (
    <div className={sidebarClass}>
      <button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility} />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent
            onClick={changeSidebarVisibility}
            type={type}
            name={name}
            logo={logo}
            collapsed={sidebar.collapse}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onClick={changeMobileSidebarVisibility}
            type={type}
            name={name}
            logo={logo}
            collapsed={sidebar.collapse}
          />
        </div>
      </Scrollbar>
    </div>
  );
};

Sidebar.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  sidebar: SidebarProps.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
