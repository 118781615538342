/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import CurrencyFormat from 'react-currency-format';
import { Button, ButtonToolbar } from 'reactstrap';
import { MenuItem, Select as SelectField, TextField } from '@material-ui/core';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import YenIcon from 'mdi-react/YenIcon';
import EuroIcon from 'mdi-react/EuroIcon';
import PoundIcon from 'mdi-react/CurrencyGbpIcon';
import DollarIcon from 'mdi-react/DollarIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import axios from 'axios';
import Alert from '../../../../shared/components/alerts/Alert';
import validate from '../../formValidate/round';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import currencies from '../../../../shared/variables/currencies';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={(touched && (typeof error !== 'undefined' && error !== ''))}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

const renderSelectCurrencyField = ({
  input, label, meta: { touched, error }, children, placeholder, multiple, options, currency, updateCurrency,
}) => (
  <div className="form__form-group-input-wrap">
    <SelectField
      className="material-form__field form__form-group-select"
      label={label}
      error={(touched && (typeof error !== 'undefined' && error !== ''))}
      helperText={touched && error} // This will show the error message
      children={children}
      defaultValue={currency}
      value={currency}
      placeholder={placeholder}
      multiple={multiple}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
        updateCurrency(e.target.value);
      }}
    >
      {options.map(option => (
        currency === option.value
          ? <MenuItem key={option.value} value={option.value} selected>{option.label}</MenuItem>
          : <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
      ))}
    </SelectField>
  </div>
);

renderSelectCurrencyField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  currency: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.element),
  updateCurrency: PropTypes.func.isRequired,
};

renderSelectCurrencyField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  placeholder: '',
  multiple: false,
  options: [],
};

const renderSelectField = ({
  input, label, meta: { touched, error }, placeholder, multiple, options, children,
}) => (
  <div className="form__form-group-input-wrap">
    <SelectField
      className="material-form__field form__form-group-select"
      label={label}
      error={(touched && (typeof error !== 'undefined' && error !== ''))} // This will show the error message
      helperText={touched && error}
      placeholder={placeholder}
      multiple={multiple}
      children={children}
      value={input.value}
      defaultValue={input.value}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    >
      {options !== null && Array.isArray(options)
        ? options.map(option => (
          <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
        )) : (
          <MenuItem key="0" value="0">No Round available</MenuItem>
        )
      }
    </SelectField>
  </div>
);

renderSelectField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.element),
  children: PropTypes.arrayOf(PropTypes.element),
};

renderSelectField.defaultProps = {
  meta: null,
  label: '',
  placeholder: '',
  multiple: false,
  options: [],
  children: [],
};

class StartupRound extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    updateCurrency: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
      currency: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      valuation: PropTypes.number,
      raised: PropTypes.number,
      raising: PropTypes.number,
      rounds: PropTypes.string,
      minimum: PropTypes.number,
      dateClose: PropTypes.instanceOf(moment),
      startupRound: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      startupRoundsError: false,
      startupRounds: [],
    };
  }

  componentDidMount() {
    axios.get('/static/liststartuprounds').then((response) => {
      if (response.data) {
        this.setState({ startupRoundsError: false, startupRounds: response.data });
      }
    }).catch(() => {
      this.setState({
        startupRoundsError: true,
        startupRounds: null,
      });
    });
  }

  renderCurrencyIcon = (currency) => {
    switch (currency) {
      case '¥':
        return <YenIcon />;
      case '€':
        return <EuroIcon />;
      case '£':
        return <PoundIcon />;
      default:
        return <DollarIcon />;
    }
  }

  render() {
    const {
      handleSubmit, pristine, reset, submitting, initialValues, currency, updateCurrency,
    } = this.props;
    const { startupRounds, startupRoundsError } = this.state;

    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <Alert color="info" className="mb-5 alert--colored">
          <div>
            <span className="bold-text">Need Help with this section? </span>
            Refer to our resources to:
            <ul>
              <li>
                <a
                  href="https://angelspartners.com/blog/how-to-value-a-startup"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Learn how to value your startup
                </a>
              </li>
              <li>
                <a
                  href="https://angelspartners.com/blog/raising-funds-how-much-is-too-much-what-are-vcs-looking-for"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  How much should you raise?
                </a>
              </li>
              <li>
                <a
                  href="https://angelspartners.com/blog/how-to-raise-funds-effectively"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  How to Raise Funds Effectively?
                </a>
              </li>
            </ul>
          </div>
        </Alert>
        {startupRoundsError
        && (
          <Alert color="error" className="alert--neutral" icon>
            <div>
              There has been an issue retrieving the list of startup rounds. Please try again or contact support.
            </div>
          </Alert>
        )}
        <div>
          <span className="material-form__label"><h5>Currency</h5></span>
          <div className="material-form__form-group-field">
            <Field
              name="currency"
              type="text"
              options={currencies}
              component={renderSelectCurrencyField}
              currency={currency}
              updateCurrency={updateCurrency}
              placeholder="Choose currency"
            />
          </div>
        </div>
        <div>
          <span className="material-form__label"><h5>Pre-Money Valuation</h5></span>
          <div className="material-form__form-group-field">
            <div className="material-form__form-group-icon">
              {this.renderCurrencyIcon(currency)}
            </div>
            <Field
              name="valuation"
              component={renderTextField}
              type="number"
            />
          </div>
        </div>
        <div>
          <span className="material-form__label"><h5>Raising</h5></span>
          <div className="material-form__form-group-field">
            <div className="material-form__form-group-icon">
              {this.renderCurrencyIcon(currency)}
            </div>
            <Field
              name="raising"
              component={renderTextField}
              type="number"
            />
          </div>
        </div>
        <div>
          <span className="material-form__label"><h5>Raised</h5></span>
          <div className="material-form__form-group-field">
            <div className="material-form__form-group-icon">
              {this.renderCurrencyIcon(currency)}
            </div>
            <Field
              name="raised"
              component={renderTextField}
              type="number"
            />
          </div>
        </div>
        <div>
          <span className="material-form__label"><h5>Minimum Investment Per Investor</h5></span>
          <div className="material-form__form-group-field">
            <div className="material-form__form-group-icon">
              {this.renderCurrencyIcon(currency)}
            </div>
            <Field
              name="minimum"
              component={renderTextField}
              type="number"
            />
          </div>
        </div>
        <div className="form__form-group margin10">
          <span className="material-form__label"><h5>Date Round Close</h5></span>
          <div className="form__form-group-field">
            <Field
              name="dateClose"
              component={renderDatePickerField}
              startDate={initialValues.dateClose}
            />
            <div className="form__form-group-icon">
              <CalendarBlankIcon />
            </div>
          </div>
        </div>
        <div className="form__form-group margin10">
          <span className="material-form__label"><h5>What is your round?</h5></span>
          <div className="form__form-group-field">
            <Field
              name="startupRound"
              component={renderSelectField}
              placeholder="What is your round?"
              options={startupRounds}
              value={initialValues.startupRound}
            />
          </div>
        </div>
        <div>
          <span className="material-form__label"><h5>Previous Rounds</h5></span>
          <Field
            name="rounds"
            component={renderTextField}
            type="text"
            multiline
            placeholder="Please share details of your previous rounds including valuation, equity given,
                          amount raised and any other significant details.
                          If you have not yet raised funds, please mention it here."
          />
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
            Save
            <ChevronRightIcon />
          </Button>
          <Button className="rounded mt-5" type="button" onClick={reset} disabled={pristine || submitting}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'startup_round_form', // a unique identifier for this form
  validate,
})(StartupRound);
