import React, { Component } from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import ModalDesigner from '../modals/ModalDesigner';

const logo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/seo.svg`;

class CardDesigner extends Component {
  state = {
    modal: false,
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  render() {
    const { modal } = this.state;
    return (
      <Col md={6} xl={4} sm={12}>
        <Card>
          <CardBody className="dashboard-card dashboard-card--info">
            <div className="dashboard-card__body">
              <img className="round dashboard-card__img-partnerships" src={logo} alt="" />
              <h3 className="dashboard-card__plan mt-4">Investor Presentation Design Services</h3>
              <hr />
              <p className="dashboard-card__title mb-5">Impress your investors</p>
              <div className="dashboard-card__desc top-line text-justify mt-2 mb-5 pb-4">
                <p>
                  We offer world-class turnkey pitch deck creation services and other resources to take you from
                  Deck to Check.
                </p>
                <p>
                  Pitch decks designed by our team <strong>have raised millions of dollars</strong> in venture capital.
                </p>
              </div>
              <Button className="dashboard-card__button" color="primary" onClick={this.toggleModal}>Learn More</Button>
            </div>
          </CardBody>
        </Card>
        <ModalDesigner
          displayed={modal}
          toggleModal={this.toggleModal}
        />
      </Col>
    );
  }
}

export default CardDesigner;
