import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import axios from 'axios';
import queryString from 'query-string';
import ModalCancelContent from './components/ModalCancelContent';

class ModalCancelSubscription extends PureComponent {
  state = {
    user: JSON.parse(localStorage.getItem('user')),
    updateError: null,
    cancelledMessage: null,
    loading: false,
  };

  static propTypes = {
    displayed: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    toggleModalFeedback: PropTypes.func.isRequired,
    getMyInfo: PropTypes.func.isRequired,
  };

  cancelSubscription = () => {
    const { toggleModal, toggleModalFeedback, getMyInfo } = this.props;
    const { user } = this.state;

    this.setState({ loading: true, updateError: null });
    axios.get('/subscription/cancel')
      .then((response) => {
        if (response.data && response.data.success) {
          this.setState({ loading: false });
          toggleModal();
          if (user.isPayingCustomer) {
            toggleModalFeedback();
          }
          getMyInfo();
          // eslint-disable-next-line no-alert
          alert('We are sorry to see go! Your subscription has been successfully cancelled and your account '
            + 'will remain active until the end of your current subscription.');
        } else {
          this.setState({
            loading: false, updateError: response.data.error || 'Sorry, an error occurred, please try again.',
          });
        }
      })
      .catch((error) => {
        if (error && error.response) {
          this.setState({ updateError: error.response.data.error });
        }
        this.setState({ loading: false });
      });
  }

  bookCall = () => {
    const { toggleModal } = this.props;
    toggleModal();
    window.open('https://calendly.com/angelspartners/trial-cancellation', '_blank');
  }

  switchPlan = (plan) => {
    const { getMyInfo, toggleModal } = this.props;

    this.setState({ loading: true, updateError: null });
    axios.post('/subscription/switch', queryString.stringify({ plan }))
      .then((response) => {
        if (response.data && response.data.success) {
          this.setState({ loading: false });
          getMyInfo();
          toggleModal();
          // eslint-disable-next-line no-alert
          alert('You are all set!');
        } else {
          this.setState({
            loading: false,
            updateError: response.data.error || 'Sorry, an error occurred, please try again.',
          });
        }
      })
      .catch((error) => {
        if (error && error.response) {
          this.setState({ loading: false, updateError: error.response.data.error });
        } else {
          this.setState({ loading: false, updateError: 'Sorry, an error occurred, please try again.' });
        }
      });
  }

  render() {
    const { displayed, toggleModal } = this.props;
    const {
      user, loading, updateError, cancelledMessage,
    } = this.state;

    return (
      <div>
        <Modal
          isOpen={displayed}
          toggle={toggleModal}
          className="modal-dialog--primary xx-large modal-cancel-subscription"
        >
          {loading
            ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            ) : (
              <ModalCancelContent
                user={user}
                updateError={updateError}
                toggleModal={toggleModal}
                cancelledMessage={cancelledMessage}
                switchPlan={this.switchPlan}
                cancelSubscription={this.cancelSubscription}
                bookCall={this.bookCall}
              />
            )}
        </Modal>
      </div>
    );
  }
}

export default ModalCancelSubscription;
