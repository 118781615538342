import React, { PureComponent } from 'react';
import { ButtonToolbar } from 'reactstrap';
import { reduxForm, isPristine } from 'redux-form';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import ChevronRight from 'mdi-react/ChevronRightIcon';

class WizardFormOne extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    resetForm: PropTypes.bool.isRequired,
    isPristine: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    if (props.resetForm && props.isPristine) {
      props.destroy();
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form
        className="form form--horizontal wizard__form wizard__form__whitelist wizard__form__step-one"
        onSubmit={handleSubmit}
      >
        <div className="wizard__form__header mb-5">
          <h3 className="wizard__title">
            Whitelist Angels Partners in your Google Workspace account settings.
          </h3>
          <p className="wizard__subtitle">
            We strongly encourage you to whitelist Angels Partners in order to stop sending follow up messages when an
            investor responds to your email.
          </p>
          <p className="wizard__subtitle">
            Follow the steps below ONLY if you are using Google Workspace (previously known as Google Suite)
            It is important to note that this feature is not available for Gmail users. Please use another mailbox
            service to enable replies tracking.
          </p>
          <p className="wizard__subtitle">
            <u>Estimated Time : Less than 5 minutes</u>
          </p>
          <p className="wizard__subtitle">
            The sole purpose of this process is to track if an email received a response. We do not read nor write the
            content of this email and do not have access to any other information in your mailbox as per our terms of
            services. <strong>You can remove our application from your white list at anytime.</strong>
          </p>
        </div>
        <div className="form__form-group">
          <ButtonToolbar className="form__button-toolbar wizard__toolbar small-margins">
            {/* <Button color="primary" type="button" disabled className="previous">Back</Button> */}
            <button type="submit" className="btn btn-primary rounded icon icon--right icon--blue">
              <p>
                Let&#39;s Go
                <ChevronRight />
              </p>
            </button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

// eslint-disable-next-line no-class-assign
WizardFormOne = connect(state => ({
  isPristine: isPristine('wizard')(state),
}))(WizardFormOne);

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: true,
})(WizardFormOne);
