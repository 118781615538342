import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import axios from 'axios';
import queryString from 'query-string';
import ReportForm from './components/ReportForm';

class ModalComponent extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    showNotifError: PropTypes.func.isRequired,
    showNotifSuccess: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    investorContactInfo: PropTypes.shape({
      contactId: PropTypes.number,
      fullName: PropTypes.string,
      investorId: PropTypes.number,
      email: PropTypes.bool,
      inCampaign: PropTypes.bool,
    }),
  };

  static defaultProps = {
    displayed: false,
    investorContactInfo: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
    this.sendReport = this.sendReport.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  sendReport = (fields) => {
    const { investorContactInfo, showNotifError, showNotifSuccess } = this.props;
    const report = fields.message && fields.message !== '' ? fields.message : fields.report;

    axios.post('/investors-db/report', queryString.stringify({
      id: investorContactInfo.contactId,
      report,
    }))
      .then((response) => {
        if (response.data) {
          showNotifSuccess('Your report has been submitted. Thank you.');
        } else {
          showNotifError('Sorry, an error occurred, please try again.');
        }
      })
      .catch((error) => {
        if (error && error.response) {
          showNotifError(error.response.data.message);
        } else {
          showNotifError('Sorry, an error occurred, please try again.');
        }
      });
    this.toggle();
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    toggleModal();
  }

  render() {
    const { modal } = this.state;

    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-dialog--header"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <span className="lnr lnr-flag modal__title-icon mt-0" />
          <h4 className="text-modal  modal__title">
            Please let us know the reason why you are reporting this investor
          </h4>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
        </div>
        <ReportForm
          onSubmit={fields => this.sendReport(fields)}
          toggle={this.toggle}
        />
      </Modal>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user,
}))(ModalComponent);
