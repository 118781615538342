import React from 'react';
import PropTypes from 'prop-types';
import AutoRenewIcon from 'mdi-react/AutorenewIcon';
import QuestionMarkCircleIcon from 'mdi-react/QuestionMarkCircleIcon';
import { Button, UncontrolledTooltip } from 'reactstrap';
import Alert from '../../alerts/Alert';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const ModalInvestorsList = ({
  toggle,
  picture,
  fundName,
  contacts,
  smartVC,
}) => (
  <span>
    <div className="modal__header">
      <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
      <img
        src={picture
          ? process.env.REACT_APP_AWS_ROOT
          + process.env.REACT_APP_AWS_INVESTORS_PATH
          + picture
          : userIcon
        }
        alt="investor"
        className="fund-picture"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = userIcon;
        }}
      />
      <h4 className="text-modal  modal__title">
        Select Investors from {fundName || 'this firm'} to contact
      </h4>
    </div>
    {contacts && contacts.length > 0
      ? (
        <div className="modal__body">
          <p>
            It is advised to contact only one person at a time from the same firm.<br />
            if you wish to contact multiple investors from this firm, use the smart VC connect feature.
          </p>
          <Button
            color="purple"
            className="rounded mb-1 mt-4 mr-auto ml-auto"
            onClick={() => smartVC(null, true)}
            onKeyDown={() => smartVC(null, true)}
          >
            <AutoRenewIcon className="mr-2 ml-0" />
            <h5>Smart VC Contact</h5>
            <QuestionMarkCircleIcon id="smartVC" />
            <UncontrolledTooltip target="smartVC">
              Use this feature to contact investors from this fund, one at a time.
              If the first VC does not respond at the end of the campaign, the next VC will be contacted automatically.
            </UncontrolledTooltip>
          </Button>
          <ul className="modal__list p-0 pt-5">
            {contacts}
          </ul>
        </div>
      )
      : (
        <Alert color="info" className="alert--margin" icon>
          <p>
            We do not have any contact information for this fund, sorry.
          </p>
        </Alert>
      )}
  </span>
);

ModalInvestorsList.propTypes = {
  toggle: PropTypes.func.isRequired,
  picture: PropTypes.string.isRequired,
  fundName: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.element).isRequired,
  smartVC: PropTypes.func.isRequired,
};

export default ModalInvestorsList;
