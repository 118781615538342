import React, { PureComponent } from 'react';
import {
  Button,
  Card, CardBody, Col, Row,
} from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';

import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import CollapseLink from './CollapseLink';

class LinksList extends PureComponent {
  static propTypes = {
    modal: PropTypes.func.isRequired,
    reload: PropTypes.bool.isRequired,
    toggleReload: PropTypes.func.isRequired,
  };

  state = {
    links: {},
    loading: true,
    message: 'loading',
  };

  componentDidMount() {
    this.retrieveLinks();
  }

  componentWillReceiveProps(props) {
    const { reload, toggleReload } = props;
    if (reload) {
      this.retrieveLinks();
      toggleReload();
    }
  }

  retrieveLinks = () => {
    axios.get('links/')
      .then((response) => {
        if (response.data instanceof Array && response.data.length > 0) {
          this.setState({ links: response.data, loading: false });
        } else if (response.data instanceof Array && response.data.length === 0) {
          this.setState({
            message: 'Track opens and engagement across all of your documents, gauge investors interest and stay in '
              + 'control of your documents.\n \n'
              + 'See who’s interested with data analytics like click rate and read receipts on your profile and '
              + 'documents. Access to your documents can be protected by email or password and can be disabled at '
              + 'any time.',
            loading: false,
          });
        } else {
          this.setState({ message: 'An error occurred, please try again', loading: false });
        }
      }).catch(() => {
        this.setState({ message: 'An error occurred, please try again or contact support', loading: false });
      });
  };

  render = () => {
    const { links, loading, message } = this.state;
    const { modal } = this.props;
    let collapse = null;

    if (loading) {
      return (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }

    if (links.length > 0) {
      collapse = (
        <div>
          <Row>
            <Col className="d-none d-md-block text-visits" sm={{ size: 2, offset: 8 }}>VISITS</Col>
          </Row>
          {links.map(
            link => (
              <CollapseLink link={link} key={link.id} modal={modal} />
            ),
          )}
        </div>
      );
    } else {
      collapse = (
        <div className="text-center">
          <p>{ message }</p>
          <br />
          <br />
          <Button
            className="icon btn rounded"
            size="lg"
            color="primary"
            style={{ margin: 'auto' }}
            onClick={() => modal(null)}
          >
            <p>
              Create Your First Tracking Link
              <PlusCircleIcon />
            </p>
          </Button>
        </div>
      );
    }

    return (
      <Row>
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <Card>
            <CardBody className="pr-0 pl-0 pr-lg-2 pl-lg-2">
              <div className="card__title">
                <h5 className="bold-text">Tracking Links</h5>
                <h5 className="subhead">
                  <strong>Do not</strong> use these tracking links in your campaigns. Instead, use the
                  &#123;&#123;trackingLink&#125;&#125; variable.
                </h5>
              </div>
              { collapse }
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default LinksList;
