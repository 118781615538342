import React, { Component } from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  BasicNotification,
  FullWideNotification,
  LinkNotification,
} from '../../shared/components/notifications/Notification';
import WizardForm from './components/WizardForm';
import Modal from '../../shared/components/modals/Modal';
import FillerElement from './components/FillerElement';

let notificationTC = null;
const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 4,
    closable: true,
    style: { top: 30, left: 'calc(100vw - 100%)' },
    className: `${position} ${rtl}-support`,
  });
};

class LinkedinIntroPage extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
    history: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
    location: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
    actionLeft: null,
    prospects: [],
    modalUpgrade: false,
    buttonClicked: false,
    user: JSON.parse(localStorage.getItem('user')),
  }

  componentDidMount = () => {
    const { user } = this.state;

    if (typeof user !== typeof undefined && user !== null && user.type === 'investor') {
      window.location = '/startups';
    }
    if (typeof user !== typeof undefined && user !== null && user.type === 'broker') {
      window.location = '/managed_startups';
    }

    this.getInvestorProspects(false);

    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
  }

  componentWillUnmount = () => {
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  getInvestorProspects = (update) => {
    const { prospects, actionLeft } = this.state;
    if (!update) {
      // if this is an update, no need to put loading to false
      this.setState({ loading: true });
    }

    if (prospects.some(prospect => prospect.searched
      && prospect.investorConnections.length > 0
      && !prospect.introduced)) {
      // if we have found a few prospects, then no need to re-update
      clearInterval(this.interval);
    }
    axios.get('/introductions/')
      .then((response) => {
        if (response.data && response.data.success) {
          // if this is an update, we need to unmount and re-mount the component Form 2
          if ((update && (response.data.prospects !== prospects || actionLeft !== response.data.actionLeft))
            || (!update && prospects.length === 0 && actionLeft === null)) {
            this.setState({
              prospects: response.data.prospects,
              actionLeft: response.data.actionLeft,
            });
          }
          this.setState({ loading: false });
        } else {
          this.showNotifError(response.data.error || 'Sorry, an error occurred, please try again.');
        }
      })
      .catch((error) => {
        if (error && error.response) {
          this.showNotifError(error.response.data.error || 'Sorry, an error occurred, please try again.');
        }
        this.showNotifError('Sorry, an error occurred, please try again.');
      });
  }

  showNotif = (message) => {
    showNotification({
      notification: <FullWideNotification
        color="danger"
        message={message}
      />,
      position: 'full',
    }, 'ltr');
  }

  showNotifWarning = (message, link) => {
    showNotification({
      notification: <LinkNotification
        color="warning"
        title="Warning!"
        link={link}
        message={message}
      />,
      position: 'right-up',
    });
  }

  showNotifSuccess = (message) => {
    showNotification({
      notification: <BasicNotification
        color="primary"
        message={message}
      />,
      position: 'right-bottom',
    });
  }

  showNotifError = (message) => {
    showNotification({
      notification: <BasicNotification
        color="danger"
        message={message}
      />,
      position: 'right-bottom',
    });
  }

  moveToNextStep = () => {
    const { user } = this.state;
    if (user.plan.includes('prime') || user.plan.includes('managed')) {
      this.setState({ buttonClicked: true });
    } else {
      this.setState({ modalUpgrade: true });
    }
  }

  toggleModalUpgrade = () => {
    this.setState({ modalUpgrade: false });
  }

  render() {
    const {
      prospects, actionLeft, loading, modalUpgrade, user, buttonClicked,
    } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={3} lg={2} xl={2}>
            <h3 className="page-title">WARM INTRODUCTIONS</h3>
          </Col>
          {user.linkedinCampaign
            && (
              <Col xs={12} md={9} lg={6} xl={6}>
                <p className="page-description">
                  Connect with investors with <strong>warm introductions</strong> from your linkedin network.
                  <br />
                  We help you discover your Linkedin First degree connections you have, who can connect you to the right
                  investors.
                </p>
                <ol type="1" className="page-description">
                  <li>Select investors you would like to connect with</li>
                  <li>Select linked connections from your network to generate warm introductions</li>
                  <li>Run the plugin & Review progress!</li>
                </ol>
              </Col>
            )
          }
        </Row>
        <Row>
          {/* eslint-disable-next-line no-nested-ternary */}
          {user.linkedinCampaign || buttonClicked ? (
            loading ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            ) : (
              <WizardForm
                actionLeft={actionLeft}
                prospects={prospects}
                getInvestorProspects={update => this.getInvestorProspects(update)}
                showNotifSuccess={message => this.showNotifSuccess(message)}
                showNotifError={message => this.showNotifError(message)}
                reset
              />
            )
          ) : <FillerElement onClick={this.moveToNextStep} />
          }
        </Row>
        <Modal
          color="primary"
          title="Upgrade Required!"
          colored
          displayed={modalUpgrade}
          toggleModal={() => this.toggleModalUpgrade()}
          message={`This feature is only available for our customers under a <strong><u>PRIME PLAN</u></strong>.
           You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
           Pricing Page</a> or from your Settings under the "Payment Plan" tab.`}
        />
      </Container>
    );
  }
}

export default withRouter(LinkedinIntroPage);
