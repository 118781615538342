/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import validate from '../formValidate/formImport';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={(touched && (typeof error !== 'undefined' && error !== ''))}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
};

class ImportForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    chooseCampaign: PropTypes.func.isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.object),
    campaign: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  };

  static defaultProps = {
    campaign: null,
    campaigns: null,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting, type,
      campaign, campaigns, chooseCampaign,
    } = this.props;
    return (
      <form className="col-md-12 col-lg-7 offset-lg-1 material-form mt-5" onSubmit={handleSubmit}>
        <div>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <span className="material-form__label">
                <h5>First Name {type === 'import' ? <span className="text-danger">*</span> : ''}</h5>
              </span>
              <Field
                name="firstname"
                component={renderTextField}
                placeholder="Alexander"
              />
            </Col>
            <Col md={6} lg={6} xs={12}>
              <span className="material-form__label"><h5>Last Name</h5></span>
              <Field
                name="lastname"
                component={renderTextField}
                placeholder="Kennedy"
              />
            </Col>
          </Row>
        </div>
        <div>
          <span className="material-form__label"><h5>Email <span className="text-danger">*</span></h5></span>
          <Field
            name="email"
            component={renderTextField}
            placeholder="example@mail.com"
            type="email"
          />
        </div>
        <div>
          <span className="material-form__label"><h5>Fund Name</h5></span>
          <Field
            name="fund_name"
            component={renderTextField}
            placeholder="What company or fund is this investor currently working for?"
          />
        </div>
        <div>
          <span className="material-form__label"><h5>Last Deal</h5></span>
          <Field
            name="last_deal"
            component={renderTextField}
            placeholder="Name a previous investment this investor partook in to reference it in your template."
          />
        </div>
        <div>
          <span className="material-form__label"><h5>Industry</h5></span>
          <Field
            name="industry"
            component={renderTextField}
            placeholder="Education, Software, Advertising, ..."
          />
        </div>
        <div>
          <span className="material-form__label"><h5>Location</h5></span>
          <Field
            name="location"
            component={renderTextField}
            placeholder="Investor's city or country"
          />
        </div>
        {/* <div>
          <span className="material-form__label"><h5>Custom</h5></span>
          <Field
            name="custom"
            component={renderTextField}
            placeholder="Here you can add any other information you need, please separate your entries with commas"
          />
        </div> */}
        <hr className="m-5" />
        <div className="center-block m-auto">
          {type === 'import' && (
            <>
              <UncontrolledDropdown
                className="select-type col-xs-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 mt-2 p-0"
              >
                <DropdownToggle className="icon rounded icon--right w-100 icon--blue capitalize" color="primary">
                  {campaign && (
                    <p>{campaign.name} <ChevronDownIcon /></p>
                  )}
                  {!campaign && (
                    <p>Please select a campaign <ChevronDownIcon /></p>
                  )}
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu large">
                  {campaigns.map((campaignItem) => {
                    if (campaignItem.status !== 'archived' && campaignItem.status !== 'draft') {
                      return (
                        <DropdownItem
                          onClick={() => chooseCampaign(campaignItem)}
                          key={campaignItem.id}
                        >
                          {campaignItem.name}
                        </DropdownItem>
                      );
                    }
                    return null;
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
              <hr className="m-5" />
              <div className="form__form-group-field days">
                <Field
                  name="checkDuplicates"
                  component={renderCheckBoxField}
                  label="Check for duplicates across all campaigns"
                  className="colored-click m-auto"
                  defaultChecked
                />
              </div>
              <br />
            </>
          )}
          <br />
          <ButtonToolbar className="form__button-toolbar">
            <Button
              type="button"
              className="rounded ml-auto"
              onClick={reset}
              disabled={pristine || submitting}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="rounded icon icon--right icon--blue mr-5 mr-auto"
              type="submit"
            >
              {type === 'import' ? 'Import' : 'Blacklist'}
              <ChevronRightIcon />
            </Button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'manual_import_form', // a unique identifier for this form
  validate,
})(ImportForm);
