import React from 'react';
import {
  Badge, Card, CardBody,
} from 'reactstrap';

const PanelAngelsPartners = () => (
  <Card className="panel panel--divider card shadow mb-3">
    <CardBody className="panel__body">
      <div className="panel__title no-padding">
        <h5 className="bold-text">
          <Badge className="panel__label">Tip</Badge>
          &nbsp;&nbsp;&nbsp;
          Do not mention Angels Partners
        </h5>
      </div>
      <div className="panel__content">
        <p className="typography-message">
          We do not recommend mentioning Angels Partners in your email since the investors listed in the investors
          database are not registered on our platform. Mentioning Angels Partners might bring about confusion.
          Focus on your ask and a brief overview of your business.
        </p>
      </div>
    </CardBody>
  </Card>
);

export default PanelAngelsPartners;
