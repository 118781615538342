import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, UncontrolledTooltip } from 'reactstrap';

import CheckboxBlankCircleOutlineIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';
import CheckboxMarkedCircleOutlineIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import ModalInvestorsList from './components/ModalInvestorsList';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

// TODO: MERGE THIS FILE WITH MODAL MULTI FUND SELECT INVESTORS

class ModalFundSelectInvestors extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    updateContactInfo: PropTypes.func.isRequired,
    fundName: PropTypes.string.isRequired,
    fundPicture: PropTypes.string.isRequired,
    fundId: PropTypes.number.isRequired,
    user: PropTypes.shape({
      isCustomer: PropTypes.bool,
      plan: PropTypes.string,
    }).isRequired,
    investorsContactInfo: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        email: PropTypes.bool,
        picture: PropTypes.string,
        inCampaign: PropTypes.bool,
      }),
    ).isRequired,
  };

  static defaultProps = {
    displayed: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      contacts: null,
    };

    this.toggle = this.toggle.bind(this);
    this.selectInvestors = this.selectInvestors.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  componentDidUpdate(prevProps, prevState) {
    const { modal } = this.state;
    if (prevState.modal !== modal && modal) this.renderInvestors();
  }

  selectInvestors = (extContactId, smartVC) => {
    const { updateContactInfo } = this.props;
    const { contacts } = this.state;

    this.toggle();
    if (smartVC) {
      updateContactInfo(null, false, smartVC, this.filterInvestorsForResponse());
    } else {
      updateContactInfo(extContactId, false, smartVC, contacts);
    }
  }

  filterInvestorsForResponse = () => {
    const { modal } = this.state;
    const { investorsContactInfo, fundName, fundId } = this.props;

    let contacts = modal && typeof investorsContactInfo !== 'undefined'
      ? investorsContactInfo
        .sort((y, x) => x.recommended - y.recommended)
        .map(({
          email, fullName, contactId, inCampaign, id, campaignName, recommended,
        }) => {
          if (email && recommended) {
            return {
              contactId,
              fullName,
              id,
              email,
              inCampaign,
              campaignName,
              smartVC: fundId,
              fund: fundName,
            };
          }
          return null;
        }) : null;

    if (contacts && Array.isArray(contacts)) {
      contacts = contacts.filter(el => el != null);
    }

    if (contacts === null || contacts.length === 0) {
      // eslint-disable-next-line no-alert
      alert('None of the investors from this fund invest in your industry & Location. Try with another fund.');
    }

    return contacts;
  }

  renderInvestors = () => {
    const { modal } = this.state;
    const { investorsContactInfo, user } = this.props;

    let contacts = modal && typeof investorsContactInfo !== 'undefined'
      ? investorsContactInfo
        .sort((y, x) => x.recommended - y.recommended)
        .map(({
          email, fullName, role, contactId, inCampaign, picture, recommended,
        }) => {
          if (email) {
            return (
              <li key={contactId}>
                <Button
                  outline
                  onClick={() => this.selectInvestors(contactId, false)}
                  onKeyDown={() => this.selectInvestors(contactId, false)}
                  id={`TooltipReco${contactId}`}
                >
                  {inCampaign
                    ? <CheckboxMarkedCircleOutlineIcon /> : <CheckboxBlankCircleOutlineIcon />
                  }
                  <img
                    src={picture
                      ? process.env.REACT_APP_AWS_ROOT
                      + process.env.REACT_APP_AWS_INVESTORS_PATH
                      + picture
                      : userIcon
                    }
                    alt="investor"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = userIcon;
                    }}
                  />
                  {recommended
                  && <StarOutlineIcon className="tooltip-reco" />
                  }
                  <h5>{fullName}</h5>
                  <p className="text-muted">{role}</p>
                  <span>
                    {this.displayEmail(email, user.isCustomer, user.plan, inCampaign)}
                  </span>
                </Button>
                {recommended
                && (
                  <UncontrolledTooltip dir="left" placement="right" target={`TooltipReco${contactId}`}>
                    This investor invests in your industry
                  </UncontrolledTooltip>
                )}
              </li>
            );
          }
          return null;
        }) : null;

    if (contacts && Array.isArray(contacts)) {
      contacts = contacts.filter(el => el != null);
    }

    this.setState({ contacts });
  }

  displayEmail = (email, isCustomer, plan, inCampaign) => {
    if (email && inCampaign) {
      return 'Already in Campaign';
    }
    return null;
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const { modal, contacts } = this.state;
    const { fundPicture, fundName } = this.props;

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className="modal-dialog--primary large"
          id="modal_multi_investors"
        >
          <ModalInvestorsList
            toggle={this.toggle}
            picture={fundPicture}
            fundName={fundName}
            contacts={contacts}
            smartVC={() => this.selectInvestors(null, true)}
          />
        </Modal>
      </div>
    );
  }
}

export default ModalFundSelectInvestors;
