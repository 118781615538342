function getPicture(contact) {
  if (contact.type === 'investor' && contact.avatar !== null) {
    return process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_INVESTORS_PATH + contact.avatar;
  }
  if (contact.type === 'startup' && contact.avatar !== null) {
    return process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_LOGOS_PATH + contact.avatar;
  }
  return `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;
}

export default getPicture;
