import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import classNames from 'classnames';

class ModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    navigator: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    navigator: '',
    colored: false,
    header: false,
    displayed: false,
    toggleModal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  // eslint-disable-next-line class-methods-use-this
  getMessage(navigator) {
    let message = '';
    if (navigator !== 'Apple Computer, Inc.') {
      message = `
        <p><strong><u>A pop up will open to connect your mailbox.</u></strong></p><br/>
        <p>We do not pull emails, contact information nor any personal information from your mailbox. 
        We only integrate with your account to schedule emails on your behalf. 
        That way investors are receiving communication directly from you.</p>
        <p>As with any information you uploaded onto our platform, we are committed to keeping all data
        confidential and will not share it with any third party. You can read our
        <a href='${process.env.REACT_APP_DOMAIN_URL}/terms_and_conditions'>terms and conditions</a> &
        <a href='${process.env.REACT_APP_DOMAIN_URL}/privacy_policy'>privacy policy</a> if you wish to learn more.
        </p><p> If you would like to continue, you should click on <strong>continue with nylas.com</strong> in the
        pop up screen that has been prompted to link your account to Angels Partners.</p><p>For any questions,
        <a href='mailto:contact@angelspartners.com'>please reach out to us</a> and we would be more than happy to
        help.</p>
      `;
    } else {
      message = `
        <p><strong><u>The connection window may have been blocked by Safari.
        Please ensure you allow pop ups for this website on your browser</u></strong></p>
        <br/>
        <img src='${process.env.REACT_APP_DOMAIN_URL}/platform/images/mailboxes/SafariPopUp.png' />
        <br/><br/>
        <p>We do not pull emails, contact information nor any personal information from your mailbox. 
        We only integrate with your account to schedule emails on your behalf. 
        That way investors are receiving communication directly from you.</p>
        <p>As with any information you uploaded onto our platform, we are committed to keeping all data
        confidential and will not share it with any third party. You can read our
        <a href='${process.env.REACT_APP_DOMAIN_URL}/terms_and_conditions'>terms and conditions</a> &
        <a href='${process.env.REACT_APP_DOMAIN_URL}/privacy_policy'>privacy policy</a> if you wish to learn more.
        </p><p> If you would like to continue, you should click on <strong>continue with nylas.com</strong> in the
        pop up screen that has been prompted to link your account to Angels Partners.</p><p>For any questions,
        <a href='mailto:contact@angelspartners.com'>please reach out to us</a> and we would be more than happy to
        help.</p>
      `;
    }
    return message;
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const {
      color, title, colored, header, navigator,
    } = this.props;
    const { modal } = this.state;
    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'security':
        Icon = <span className="lnr lnr-lock modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className={`modal-dialog--${color} ${modalClass} x-large`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="text-modal modal__title">{title}</h4>
          </div>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
          <div className="modal__body" dangerouslySetInnerHTML={{ __html: this.getMessage(navigator) }} />
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(ModalComponent);
