/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Col, Row, Table, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CardTitle from 'reactstrap/lib/CardTitle';
import PlusIcon from 'mdi-react/PlusIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Alert from '../../../shared/components/alerts/Alert';

function GettingStartedTab(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <CardTitle>
        <Row>
          <Col xs={12} xl={8}>
            <h3>Use these documents to help you get started!</h3>
            <p className="text-muted mb-2 mt-4">
              Congratulations! Starting a company can be immensely rewarding and exciting...
              but it can also be confusing and time consuming. By using the resources below you can save time and get
              started quickly, build a solid foundation for your business and draw the first lines of
              the work to come.
            </p>
          </Col>
        </Row>
        <Alert color="purple" className="alert--colored mt-5">
          <div>
            <p>
              <span className="bold-text">Want to learn more? </span>
              Get a basic overview of the steps that should be taken in order to set up and position
              your startup properly:
              <br /><br />
              {/* eslint-disable-next-line max-len */}
              <a className="text-white" href={isCustomer ? 'https://angelspartners.com/blog/the-top-ten-our-ultimate-reading-list-for-early-stage-startups' : '#'} onClick={checkIsCustomer}>
                <strong>Our Ultimate Reading List for Early Stage Startups <ChevronRightIcon /></strong>
              </a>
            </p>
          </div>
        </Alert>
      </CardTitle>
      <Table responsive hover className="table-borderless">
        <tbody>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a className="resources__name" href={isCustomer ? 'https://docs.google.com/presentation/d/1P33X_ABV1Rz7x-SJx0oph1kN7l8FHY0ITaYwmWpYZcg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Business Model Canvas
              </a>
            </td>
          </tr>
          <tr>
            <td className="text-muted">
              A Business Model Canvas is a strategic management tool to quickly and easily define a business idea or
              concept. It helps visualize what is important and forces users to address key areas.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/presentation/d/1P33X_ABV1Rz7x-SJx0oph1kN7l8FHY0ITaYwmWpYZcg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button className="btn rounded icon btn-slides mt-4">
                  Open Slides
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a className="resources__name" href={isCustomer ? 'https://docs.google.com/document/d/1zSu846x8GK2UOdHuuWBJkMwMK-eUiyEoLA3LSJsCMbo/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Company Launch Announcement Template
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A great company launch email is one of your best opportunities to hook investors and insert
              yourself onto their radar.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1zSu846x8GK2UOdHuuWBJkMwMK-eUiyEoLA3LSJsCMbo/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1DQZQ2D-9HPABvGfqa8_1FQ2kIl8N-ekBWxDKtU3kcew/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Corporate Entity Choice Wizard
              </a>
            </td>
          </tr>
          <tr>
            <td>
              The business structure you choose influences everything from the day-to-day operations, to taxes, to how
              much of your personal assets are at risk. You should choose a business structure that gives you the
              right balance of legal protection but aso aligns with the investors&#39; standards. Use this document
              as a rule of thumb to guide you in the process.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1DQZQ2D-9HPABvGfqa8_1FQ2kIl8N-ekBWxDKtU3kcew/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="success" className="btn rounded icon mt-4">
                  Open Spreadsheet
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1boEiezL22TZTZnOmDaIZaAFzj0tJaODzMgIwXrJdbMc/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Executive Summary Template
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A good executive summary grabs your reader’s attention and lets them know what it is you do and why
              they should read the rest of your business plan or proposal.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1boEiezL22TZTZnOmDaIZaAFzj0tJaODzMgIwXrJdbMc/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1B1YoLyrKN1RkMfFo2aZzOxZ4ILh8lVerM3GuzRnWrN8/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Naming Your Company Worksheet
              </a>
            </td>
          </tr>
          <tr>
            <td>
              Naming a business tends to be one of the most difficult decisions when starting out.
              Take advantage of this sheet to help you choose a memorable and strong name!

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1B1YoLyrKN1RkMfFo2aZzOxZ4ILh8lVerM3GuzRnWrN8/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="success" className="btn rounded icon mt-4">
                  Open Spreadsheet
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1jLSYf2_nKN1GQOQQKU5dF1fSnJqVrZHdJTXKTpwX2WQ/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Pre-Funding Founders Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A Founders Agreement is a contract that governs the Founders&#39; business relationships.
              The Agreement lays out the rights, responsibilities, liabilities and obligations of each founder.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1jLSYf2_nKN1GQOQQKU5dF1fSnJqVrZHdJTXKTpwX2WQ/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

GettingStartedTab.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default GettingStartedTab;
