import React from 'react';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import renderDropZoneField from '../../../../shared/components/form/DropZone';

const FileUploadLogo = ({ handleSubmit, reset }) => (
  <div>
    <div className="card__title">
      <h5 className="bold-text">Upload Your Logo</h5>
      <h5 className="subhead">
        Keep your logo up to date and show investors that you have started working on your branding.
      </h5>
    </div>
    <form className="form" onSubmit={handleSubmit}>
      <Field
        name="files"
        component={renderDropZoneField}
        customHeight
      />
      <ButtonToolbar className="form__button-toolbar">
        <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
          Upload
          <ChevronRightIcon />
        </Button>
        <Button className="rounded mt-5" type="button" onClick={reset}>
          Cancel
        </Button>
      </ButtonToolbar>
    </form>
  </div>
);

FileUploadLogo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'file_upload_logo',
})(FileUploadLogo);
