import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'reactstrap';
import { div } from 'react-router-dom';
import CheckboxMarkedCircleOutlineIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import CheckboxBlankCircleOutlineIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';

const linkedInPluginPic = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/warm_intros/chromeStore.webp`;
const launchPluginPic = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/warm_intros/launchPlugin.webp`;

const RunPluginPage = ({ user }) => (
  <Card elevation={0} className="Plugin__install p-5 m-5 w-auto">
    <Row>
      <Col xs={12} md={6} xl={6}>
        <div className={`steps 
              ${(user && user.plugin) ? 'step_disabled' : 'step-1'}`}
        >
          {user && user.plugin
            ? (
              <CheckboxMarkedCircleOutlineIcon size={50} />
            ) : (
              <CheckboxBlankCircleOutlineIcon size={50} />
            )}
          <h2>Step 1</h2>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line pointer" alt="line" />
          <h3>Download the Plugin</h3>
          <p>
            <a
              href="https://chromewebstore.google.com/detail/angels-partners-warm-intr/mpapjhbenpenekbmlddecaibekmfdeji"
              className="text-white bold-text text-underlined"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to Download the plugin on the Chrome Web Store.
            </a>
          </p>
          <br />
          <a
            href={`${process.env.REACT_APP_DOMAIN_URL}/plugin/download`}
            className="pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedInPluginPic} className="w-100 img pt-2 mt-5 mb-5" alt="line" />
          </a>
        </div>
      </Col>
      <Col xs={12} md={6} xl={6}>
        <div>
          <div className={`steps ${user && user.plugin ? 'step_disabled' : 'step-2'}`}>
            {user && user.plugin
              ? (
                <CheckboxMarkedCircleOutlineIcon size={50} />
              ) : (
                <CheckboxBlankCircleOutlineIcon size={50} />
              )}
            <h2>Step 2</h2>
            <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
            <h3>Setup the plugin</h3>
            <p>
              To setup your plugin, simply <strong>Refresh this page</strong>.
              Then Launch the plugin from this page (angelspartners) to link it to your account,
              and wait until confirmation.
            </p>
            <img src={launchPluginPic} className="w-100 img mt-3" alt="line" />
          </div>
        </div>
      </Col>
      <Col xs={12} md={6} xl={6}>
        <div>
          <div className="steps step-3">
            <CheckboxBlankCircleOutlineIcon size={50} />
            <h2>Step 3</h2>
            <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
            <h3>Go to LinkedIn and start the plugin</h3>
            <p>
              Go to&nbsp;
              <a
                href="https://www.linkedin.com/"
                className="text-white bold-text text-underlined"
                target="_blank"
                rel="noopener noreferrer"
              >
              LinkedIn.com
              </a>
              &nbsp;and launch the plugin from your chrome extensions.
            </p>
          </div>
        </div>
      </Col>
    </Row>
  </Card>
);

RunPluginPage.propTypes = {
  user: PropTypes.shape({
    pluginStep: PropTypes.number,
  }).isRequired,
};

export default RunPluginPage;
