/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import Sidebar from './sidebar/Sidebar';
import {
  changeMobileSidebarVisibility, changeSidebarVisibility, closeSidebar,
} from '../../redux/actions/sidebarActions';
import getPicture from '../../shared/functions/getPicture';
import { updateUserInfo } from '../../redux/actions/userActions';
import { UserProps, SidebarProps } from '../../shared/prop-types/ReducerProps';


class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    user: UserProps.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
    };
    this.changeSidebarVisibility = this.changeSidebarVisibility.bind(this);
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    this.retrieveUserName();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const { collapse } = this.state;
    const { dispatch } = this.props;

    const w = window;
    const d = document;
    const { documentElement } = d;
    const body = d.getElementsByTagName('body')[0];
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;

    this.setState({ collapse: false });

    if ((width < 1500 || window.location.pathname.indexOf('/startup/deck/') !== -1) && !collapse) {
      dispatch(closeSidebar());
    }
  };

  retrieveUserName = () => {
    const { dispatch } = this.props;

    axios.get('/user')
      .then((response) => {
        const user = response.data;
        window.localStorage.setItem('user', JSON.stringify(user));
        dispatch(updateUserInfo(response.data));
        if (user.type === 'broker') {
          dispatch(closeSidebar());
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Session expired, please log in again.'
          + ' If you are unable to log in, please contact support: contact@angelspartners.com');
        window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/logout`;
      });
  };

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  closeSidebar = () => {
    const { dispatch } = this.props;
    const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    if (width < 1500) {
      dispatch(closeSidebar());
    }
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(closeSidebar());
    dispatch(changeMobileSidebarVisibility());
  };

  render() {
    const { user, sidebar } = this.props;
    const { collapse } = this.state;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': collapse || sidebar.collapse,
      'layout--top-navigation': false,
    });

    return (
      <div className={layoutClass}>
        <TopbarWithNavigation
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
          name={user && user.name ? user.name : 'Menu'}
          logo={getPicture(user)}
          type={user ? user.type : 'startup'}
          impersonator={user && typeof user.impersonator === 'object'}
        />
        <Sidebar
          sidebar={sidebar}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.closeSidebar}
          type={user && user.type ? user.type : ''}
          name={user && user.name ? user.name : 'Menu'}
          logo={getPicture(user)}
        />
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
  user: state.user,
}))(Layout));
