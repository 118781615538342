/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (!values.time) {
    errors.time = 'Select a time at which you will send the emails';
  }
  if (!values.timezone || !values.timezone.value || values.timezone.value === "") {
    errors.timezone = 'Select a timezone for your campaign';
  }

  return errors;
};

export default validate;
