import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
  Row, Col,
} from 'reactstrap';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import NonLinearSlider from '../../../shared/components/form/RangeSlider';
import { MultiSelectField } from '../../../shared/components/form/MultiSelect';
import { getIndustryFilters, filtersArray } from '../../../shared/functions/getFilters';

class SearchFilters extends Component {
  state = {
    keywords: '',
    filters: null,
    rangeMin: 1000,
    rangeMax: 9000000,
    location: '',
    industry: null,
    sortBy: null,
  };

  componentDidMount() {
    const { props } = this;
    const query = new URLSearchParams(props.location.search);

    if (query.get('keywords')) {
      this.setState({ keywords: query.get('keywords') });
    }
    if (query.get('filters')) {
      this.setState({ filters: query.get('filters') });
    }
    if (query.get('rangeMin')) {
      this.setState({ rangeMin: parseInt(query.get('rangeMin'), 10) });
    }
    if (query.get('rangeMax')) {
      this.setState({ rangeMax: parseInt(query.get('rangeMax'), 10) });
    }
    if (query.get('location')) {
      this.setState({ location: query.get('location') });
    }
    if (query.get('industry')) {
      this.setState({ industry: query.get('industry') });
    }
    if (query.get('sortBy')) {
      this.setState({ sortBy: query.get('sortBy') });
    }
  }

  updateKeywordsValue = (evt) => {
    this.setState({ keywords: evt.target.value });
  };

  updateLocationValue = (evt) => {
    this.setState({ location: evt.target.value });
  };

  updateRangeValue = (min, max) => {
    this.setState({ rangeMin: min, rangeMax: max });
  };

  updateIndustryValue = (values) => {
    let industries = null;
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
          industries += ',';
        }
        industries += values[i].value;
      }
    }
    this.setState({ industry: industries });
  };

  addFilter = (filter, value) => {
    this.setState({ [filter]: value });
  };

  // removeFilters = () => {
  //   this.setState({
  //     filters: null, range: null, location: null, industry: null, sortBy: null,
  //   }, this.applyFilters);
  // };

  getFilters = (filter) => {
    if (filter === 'pinged') {
      return 'Pinged';
    } if (filter === 'notpinged') {
      return 'Not Pinged';
    } if (filter === 'favorites') {
      return 'Favorites';
    } if (filter === 'discarded') {
      return 'Discarded';
    }
    return 'Filters';
  };

  getSortBy = (filter) => {
    if (filter === 'recent') {
      return 'Most Recent';
    } if (filter === 'active') {
      return 'Most Active';
    }
    // if (filter === 'responsive') {
    //   return 'Most Responsive';
    // }
    return 'Sort By';
  };

  onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      this.applyFilters();
    }
  }

  applyFilters() {
    const { props, state } = this;
    let query = 'page=1';

    if (state.keywords) {
      query += query !== '' ? '&' : '';
      query += `keywords=${state.keywords}`;
    }
    if (state.filters) {
      query += query !== '' ? '&' : '';
      query += `filters=${state.filters}`;
    }
    if (state.rangeMin) {
      query += query !== '' ? '&' : '';
      query += `rangeMin=${state.rangeMin}`;
    }
    if (state.rangeMax) {
      query += query !== '' ? '&' : '';
      query += `rangeMax=${state.rangeMax}`;
    }
    if (state.location) {
      query += query !== '' ? '&' : '';
      query += `location=${state.location}`;
    }
    if (state.industry) {
      query += query !== '' ? '&' : '';
      query += `industry=${state.industry}`;
    }
    if (state.sortBy) {
      query += query !== '' ? '&' : '';
      query += `sortBy=${state.sortBy}`;
    }

    props.history.push({ pathname: '/investors/', search: `?${query}` });
  }

  render() {
    const {
      keywords, filters, location, industry, sortBy, rangeMax, rangeMin,
    } = this.state;
    const industrySelected = [];

    if (industry) {
      for (let i = 0; i < String(industry).split(',').length; i += 1) {
        industrySelected.push({
          value: parseInt(String(industry).split(',')[i], 10),
          label: getIndustryFilters(parseInt(String(industry).split(',')[i], 10)),
        });
      }
    }

    return (
      <div className="search-block topbar__search-bordered">
        <Row>
          <Col xs={12} md={6} xl={4}>
            <div className="input-keywords mb-3 mr-sm-1 mr-lg-1 mr-xl-0 mb-lg-4">
              <input
                className="input-search container-fluid"
                name="keywords"
                type="text"
                value={keywords}
                placeholder="Keywords, Name, Description..."
                onChange={evt => this.updateKeywordsValue(evt)}
                onKeyDown={this.onKeyDownHandler}
              />
            </div>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <div className="input-location mb-3 mr-sm-1 mr-lg-1 mr-xl-0 mb-lg-4">
              <input
                className="input-search container-fluid"
                name="keywords"
                type="text"
                value={location}
                placeholder="Country, State or City"
                onChange={evt => this.updateLocationValue(evt)}
                onKeyDown={this.onKeyDownHandler}
              />
            </div>
          </Col>
          <Col
            xs={12}
            sm={{ size: 10, offset: 1 }}
            md={{ size: 9, offset: 2 }}
            lg={{ size: 8, offset: 3 }}
            xl={{ size: 4, offset: 0 }}
          >
            <div className="text-muted helper-search container-fluid pl-0 pt-0 pt-xl-2 pb-4 pb-xl-0 pl-xl-5 pr-xl-4">
              <InformationIcon />&nbsp;&nbsp;&nbsp;Separate your entries with commas&nbsp;
              <div className="d-none d-xl-inline">
                <br />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={8}>
            <Row>
              <Col xs={12} lg={6}>
                <div className="input-industry w-100 mb-4 mb-lg-0 mr-lg-1 mr-xl-4 mt-2">
                  <MultiSelectField
                    name="industry"
                    placeholder="INDUSTRY"
                    onChange={evt => this.updateIndustryValue(evt)}
                    value={industrySelected}
                    options={filtersArray}
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} lg={3}>
                <UncontrolledDropdown className="select-type mt-2">
                  {this.getFilters(filters) === 'Filters'
                    ? (
                      <DropdownToggle className="icon rounded icon--blue icon--right w-100" color="primary">
                        <p>{this.getFilters(filters)} <ChevronDownIcon /></p>
                      </DropdownToggle>
                    ) : (
                      <DropdownToggle className="icon rounded icon--blue icon--right w-100" color="primary">
                        <p>{this.getFilters(filters)} <ChevronDownIcon /></p>
                      </DropdownToggle>
                    )
                  }
                  <DropdownMenu className="dropdown__menu">
                    <DropdownItem
                      onClick={() => this.addFilter('filters', 'pinged')}
                    >
                      {this.getFilters('pinged')}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.addFilter('filters', 'notpinged')}
                    >
                      {this.getFilters('notpinged')}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.addFilter('filters', 'favorites')}
                    >
                      {this.getFilters('favorites')}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.addFilter('filters', 'discarded')}
                    >
                      {this.getFilters('discarded')}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => this.addFilter('filters', null)}>None</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col xs={12} sm={6} lg={3}>
                <UncontrolledDropdown className="select-type mt-2">
                  {this.getSortBy(sortBy) === 'Sort By'
                    ? (
                      <DropdownToggle className="icon rounded icon--blue icon--right w-100" color="primary">
                        <p>{this.getSortBy(sortBy)} <ChevronDownIcon /></p>
                      </DropdownToggle>
                    ) : (
                      <DropdownToggle className="icon rounded icon--blue icon--right w-100" color="primary">
                        <p>{this.getSortBy(sortBy)} <ChevronDownIcon /></p>
                      </DropdownToggle>
                    )
                  }
                  <DropdownMenu className="dropdown__menu">
                    <DropdownItem
                      onClick={() => this.addFilter('sortBy', 'recent')}
                    >
                      {this.getSortBy('recent')}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.addFilter('sortBy', 'active')}
                    >
                      {this.getSortBy('active')}
                    </DropdownItem>
                    {/* <DropdownItem */}
                    {/*  onClick={() => this.addFilter('sortBy', 'responsive')} */}
                    {/* > */}
                    {/*  {this.getSortBy('responsive')} */}
                    {/* </DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => this.addFilter('sortBy', null)}
                    >
                      None
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <div className="text-muted center-block text-center mt-4 mb-1">Investment Range</div>
              </Col>
              <Col xs={12} md={8}>
                <NonLinearSlider
                  initValues={[rangeMin, rangeMax]}
                  type="investors"
                  returnedValue={(min, max) => this.updateRangeValue(min, max)}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={4}>
            <div className="pl-md-5 pl-xl-5 mt-5 mt-xl-2 float-right float-xl-none">
              <Button
                className="rounded icon icon--right apply-filters w-100"
                color="action"
                onClick={() => this.applyFilters()}
              >
                <p>
                  Apply Filters
                  <ChevronRightIcon />
                </p>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(SearchFilters);
