/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (values.traction && values.traction.length < 10) {
    errors.traction = 'Your traction field should be at least 10 characters long.';
  } else if (values.traction && values.traction.length > 1500) {
    errors.traction = 'Your traction field should be less than 1500 characters long.';
  }

  return errors;
};

export default validate;
