import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Card, Col, DropdownItem, DropdownMenu,
  DropdownToggle, Row, UncontrolledDropdown,
} from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import SendIcon from 'mdi-react/SendIcon';
import { MultiSelectField } from '../../../shared/components/form/MultiSelect';

class SearchElement extends Component {
  static propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.string),
    dataOperations: PropTypes.func.isRequired,
    sendUpdate: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    campaigns: null,
  };

  state = {
    keywords: '',
    filters: null,
    sort: null,
    campaign: null,
  };

  constructor(props) {
    super(props);
    this.updateKeywordsValue = this.updateKeywordsValue.bind(this);
  }

  componentDidMount() {
    this.initCampaignFilter();
  }

  updateKeywordsValue = (evt) => {
    const { dataOperations } = this.props;
    const { filters, sort } = this.state;
    this.setState({ keywords: evt.target.value });
    dataOperations(evt.target.value.toLowerCase(), filters, sort, false);
  };

  updateFiltersValue = (values) => {
    const { dataOperations } = this.props;
    const { keywords, sort, campaign } = this.state;
    const filters = [];
    let search; let exact;

    // Sort out filter multi select
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i += 1) {
        filters.push(values[i].value);
      }
    }
    this.setState({ filters });

    // assign values for data Operations
    if (campaign) {
      search = campaign;
      exact = true;
    } else {
      search = keywords;
      exact = false;
    }

    dataOperations(search, filters, sort, exact);
  };

  addSort = (value) => {
    const { dataOperations } = this.props;
    const { filters, keywords, campaign } = this.state;
    this.setState({ sort: value });
    let search; let exact;

    if (campaign) {
      search = campaign;
      exact = true;
    } else {
      search = keywords;
      exact = false;
    }

    dataOperations(search, filters, value, exact);
  };

  addFilterCampaign = (value) => {
    const { dataOperations } = this.props;
    const { filters, sort } = this.state;
    this.setState({ campaign: value });
    dataOperations(value, filters, sort, true);
  };

  getFilter = (filter) => {
    if (filter === 'opened') {
      return 'Opened';
    } if (filter === 'notOpened') {
      return 'Not Opened';
    } if (filter === 'visited') {
      return 'Visited';
    } if (filter === 'notVisited') {
      return 'Not Visited';
    } if (filter === 'contacted') {
      return 'Contacted';
    } if (filter === 'notContacted') {
      return 'Not Contacted';
    } if (filter === 'replied') {
      return 'Replied';
    } if (filter === 'notReplied') {
      return 'Not Replied';
    }
    return 'Filters';
  };

  getCampaignFilter = (filter) => {
    if (filter !== 'Filter By Campaign' && filter !== null) {
      return filter;
    }
    return 'FILTER BY CAMPAIGN';
  };

  getSortBy = (sort) => {
    if (sort === 'alphabetical') {
      return 'Alphabetical';
    } if (sort === 'next') {
      return 'Next Follow Up';
    } if (sort === 'last') {
      return 'Recently Contacted';
    } if (sort === 'engagement') {
      return 'Most Interested';
    }
    return 'SORT';
  };

  onKeyDownHandler = (e) => {
    const { keywords } = this.state;
    const { dataOperations } = this.props;
    const { filters, sort } = this.state;
    this.setState({ keywords });

    if (e.keyCode === 13) {
      dataOperations(keywords, filters, sort, false);
    }
  };


  initCampaignFilter = () => {
    const { location } = this.props;
    const { dataOperations } = this.props;
    const { filters, sort } = this.state;
    const query = new URLSearchParams(location.search);
    const campaign = query.get('campaign');

    if (campaign) {
      this.setState({ campaign });
      setTimeout(() => dataOperations(campaign, filters, sort, false), 500);
    }
  };

  render() {
    const {
      keywords, filters, sort, campaign,
    } = this.state;
    const { campaigns, sendUpdate } = this.props;
    const filtersSelected = [];

    if (filters && filters.length) {
      for (let i = 0; i < filters.length; i += 1) {
        filtersSelected.push({
          value: filters[i],
          label: this.getFilter(filters[i]),
        });
      }
    }

    return (
      <Card className="p-4">
        <div className="search-block dashboard-block">
          <Row>
            <Col xs={12} sm={6}>
              <div className="input-keywords mb-3 mr-sm-1 mr-lg-1 mr-xl-4 mb-lg-4">
                <div className="input-keywords">
                  <input
                    className="input-search w-100"
                    name="keywords"
                    type="text"
                    value={keywords}
                    placeholder="Search by campaign or investor"
                    onChange={evt => this.updateKeywordsValue(evt)}
                    onKeyDown={this.onKeyDownHandler}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} xl={2}>
              <div className="w-100 mb-4 mb-md-0 mr-lg-1 mr-xl-4">
                <UncontrolledDropdown className="select-type w-100">
                  {this.getSortBy(sort) === 'SORT'
                    ? (
                      <DropdownToggle
                        className="icon icon--right icon--blue rounded w-100"
                        color="primary"
                      >
                        <p>{this.getSortBy(sort)} <ChevronDownIcon /></p>
                      </DropdownToggle>
                    ) : (
                      <DropdownToggle
                        className="icon icon--right icon--blue rounded w-100"
                        color="primary"
                      >
                        <p>{this.getSortBy(sort)} <ChevronDownIcon /></p>
                      </DropdownToggle>
                    )
                  }
                  <DropdownMenu className="dropdown__menu">
                    <DropdownItem
                      onClick={() => this.addSort('relevance')}
                    >
                      Relevance
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.addSort('alphabetical')}
                    >
                      Alphabetical
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.addSort('next')}
                    >
                      Next Follow Up
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.addSort('last')}
                    >
                      Recently Contacted
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.addSort('engagement')}
                    >
                      Most Interested
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={8}>
              <Row>
                <Col xs={12} sm={6}>
                  <UncontrolledDropdown className="select-type mt-2 w-100">
                    <DropdownToggle
                      className="icon icon--right icon--blue rounded w-100"
                      color="primary"
                    >
                      <p>{this.getCampaignFilter(campaign)} <ChevronDownIcon /></p>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown__menu w-100">
                      {campaigns.map(c => (
                        <DropdownItem
                          key={c}
                          onClick={() => this.addFilterCampaign(c)}
                        >
                          {c}
                        </DropdownItem>
                      ))}
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={() => this.addFilterCampaign(null)}
                      >
                        All Campaigns
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="input-industry w-100 mb-4 mb-md-0 mr-lg-1 mr-xl-4 mt-2">
                    <MultiSelectField
                      name="filters"
                      placeholder="FILTERS"
                      onChange={evt => this.updateFiltersValue(evt)}
                      value={filtersSelected}
                      options={[
                        { value: 'notReplied', label: 'Not Replied' },
                        { value: 'replied', label: 'Replied' },
                        { value: 'notVisited', label: 'Not Visited' },
                        { value: 'visited', label: 'Visited' },
                        { value: 'notOpened', label: 'Not Opened' },
                        { value: 'opened', label: 'Opened' },
                        { value: 'notContacted', label: 'Not Contacted' },
                        { value: 'contacted', label: 'Contacted' },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={4}>
              <div className="=mt-4 mt-lg-2 float-right float-lg-none">
                <Button className="rounded icon icon--right w-100" size="lg" color="purple" onClick={sendUpdate}>
                  Send an Update
                  <SendIcon />
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    );
  }
}


export default withRouter(SearchElement);
