import Tour from 'reactour';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import NotificationSystem from 'rc-notification';
import { showTour, updateTour } from './showTour';
import { BasicNotification } from '../notifications/Notification';

let notificationLU = null;

const showNotification = ({ notification, position }, rtl) => {
  notificationLU.notice({
    content: notification,
    duration: 20,
    closable: true,
    style: { top: 0, right: '0' },
    className: `${position} ${rtl}-support`,
  });
};

const disableBody = target => disableBodyScroll(target);
const enableBody = target => enableBodyScroll(target);

const settingsSteps = [
  {
    selector: '.mail-boxes',
    content: 'By connecting your email account to Angels Partners, you will be able to automatically send emails to \n'
      + 'investors via your own mailbox and fully automate your campaigns.',
    showButtons: false,
    showNumber: false,
    showNavigationNumber: false,
    showNavigation: false,
  },
];

const campaign1Steps = [
  {
    selector: '#campaign-name',
    content: 'Break down your campaigns by investor type, vertical and geography '
      + '(i.e "CleanTech New York investors", "AI San Francisco VCs", "Media LA VCs")',
    showButtons: false,
    showNumber: false,
    showNavigationNumber: false,
    showNavigation: false,
    action: (node) => {
      node.focus();
    },
  },
  {
    selector: '.next',
    content: 'You can leave the settings to default and move on to the next step where we will create the templates.',
    showButtons: false,
    showNumber: false,
    showNavigationNumber: false,
    showNavigation: false,
    action: (node) => {
      node.focus();
    },
  },
];

const campaign2Steps = [
  {
    selector: '.initial-field',
    content: 'Embed a "clear call to action" in your email (i.e "Are you available for a call next week?",'
      + ' "What is the best time to talk this week?")',
    action: (node) => {
      node.focus();
    },
  },
  {
    selector: '.initial-field',
    content: 'Drop a tracking link to direct investors to your startup profile'
      + ' (i.e "Here is our investor presentation {{ trackingLink }}")',
    action: (node) => {
      node.focus();
    },
  },
  {
    selector: '.btn__template',
    content: function DemoHelperComponent() {
      return (
        <div>
          <p>
            Find some inspiration from our pre-built templates.<br />
            We built highly efficient templates to help you connect with investors.
          </p>
          <p>
            We have spent months digging into what makes a good email with our data-driven approach.
          </p>
          <p>
            We looked at millions of data points around what pitch decks lead to first meetings and are
            making these templates available for our community.
          </p>
        </div>
      );
    },
  },
  {
    selector: '#followup-button',
    content: 'Investors are always busy, you should try to send at least 1 or 2 follow ups after the initial email.',
  },
  {
    selector: '.notification',
    content: 'Any questions? Check out these bullet points to get you up to speed in 2 minutes',
  },
];

const extInvestorSteps = [
  {
    selector: '',
    content: function DemoHelperComponent() {
      return (
        <div>
          <h4>Welcome on board!</h4>
          <p>
            Follow these simple steps to get you started in no time.<br />
            We are going to see how you can contact an investor for your company.
          </p>
        </div>
      );
    },
  },
  {
    selector: '.input-keywords',
    content: function DemoHelperComponent() {
      return (
        <div>
          <h4>Let&#39;s get Started!</h4>
          <p>
            Search for active investors in your vertical and in your geography (separate with commas)<br />
            (i.e &#34;Cleantech, San Francisco&#34;, &#34;BioTech, Boston&#34;, &#34;FinTech, New York&#34;
          </p>
        </div>
      );
    },
    action: (node) => {
      node.focus();
    },
  },
  {
    selector: '.select-type',
    content: function DemoHelperComponent() {
      return (
        <div>
          <p>
            Prioritize Private investors for smaller rounds (&#60;$250k).
          </p>
          <p>
            For bigger rounds, try to get in touch with the VC Partner that seems the most specialized in your
            vertical (to do so you can look at their LinkedIn profile and recent posts)
          </p>
        </div>
      );
    },
  },
  {
    selector: '.extinvestor__mail-btn',
    content: function DemoHelperComponent() {
      return (
        <div>
          <h4>Try it now!</h4>
          <p>
            Once you have found an investor who could be a good match for your round,<br />
            click on this button to add this person to your investment campaigns, so you can contact them via email.
          </p>
          <p>
            <small>Your message will not be sent right away.</small>
          </p>
        </div>
      );
    },
  },
];

const steps = {
  settings: settingsSteps,
  campaign1: campaign1Steps,
  campaign2: campaign2Steps,
  extInvestors: extInvestorSteps,
};

class TourPopup extends Component {
  static propTypes = {
    page: PropTypes.string.isRequired,
    disableFocusLock: PropTypes.bool,
    closeTour: PropTypes.bool,
  };

  static defaultProps = {
    disableFocusLock: null,
    closeTour: null,
  };

  state = {
    isTourOpen: false,
  }

  componentDidMount() {
    const { page } = this.props;
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 100 } }, n => notificationLU = n);

    setTimeout(() => {
      this.setState({ isTourOpen: showTour(page) });
      if (!showTour(page)) {
        this.showNotifTour();
      }
    }, 1500);
  }

  componentWillReceiveProps(newProps) {
    const { props } = this;
    // eslint-disable-next-line react/prop-types
    if (props.page !== newProps.page) {
      if (notificationLU) {
        // eslint-disable-next-line react/no-unused-state
        notificationLU.destroy();
      }
    }

    if (newProps.closeTour === true) {
      this.closeTour();
    }
    return props.page !== newProps.page
      || props.disableFocusLock !== newProps.disableFocusLock
      || props.closeTour !== newProps.closeTour;
  }

  componentWillUnmount() {
    const { page } = this.props;
    clearAllBodyScrollLocks();
    if (notificationLU && page !== 'extInvestors') {
      notificationLU.destroy();
    }
  }

  openTour = () => {
    this.setState({ isTourOpen: true });
  }

  closeTour = () => {
    const { page } = this.props;
    this.setState({ isTourOpen: false });

    if (showTour(page)) {
      this.showNotifTour();
      updateTour(page);
    }

    enableBody();
  }

  showNotifTour = (message, link) => showNotification({
    notification: (
      <div
        onClick={() => this.openTour()}
        onKeyDown={() => this.openTour()}
        role="presentation"
        className="cursor"
      >
        <BasicNotification
          color="primary"
          title="Resume Tour"
          link={link}
          message="Click here to get some tips."
        />
      </div>
    ),
    position: 'left-up',
  });

  render() {
    const { page, disableFocusLock } = this.props;
    const { isTourOpen } = this.state;
    const showNavigation = steps[page].length > 1;

    return (
      <Tour
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        steps={steps[page]}
        isOpen={isTourOpen}
        onRequestClose={this.closeTour}
        disableFocusLock={disableFocusLock}
        showButtons={showNavigation}
        showNavigation={showNavigation}
      />
    );
  }
}

export default TourPopup;
