import React, { PureComponent } from 'react';
import { Col, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import PageVisibility from 'react-page-visibility';
import PropTypes from 'prop-types';
import axios from 'axios';
import PdfViewer from './PdfViewer';

class PDFWrapper extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    urlRetrieveDoc: PropTypes.string.isRequired,
    urlTrackAction: PropTypes.string.isRequired,
    urlTrackTime: PropTypes.string.isRequired,
    baseDocUrl: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaded: false,
      url: false,
      pdfLoaded: true,
      showNotif: true,
      focus: true,
    };
    this.timer = null;
  }

  componentDidMount = () => {
    this.init();
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  init = () => {
    const { urlRetrieveDoc, baseDocUrl } = this.props;

    axios.get(urlRetrieveDoc)
      .then((response) => {
        if (response.data.split('.').pop() === 'pdf') {
          this.setState({
            url: baseDocUrl + response.data,
            loading: false,
            error: null,
          });
          this.trackAction();
        } else {
          this.setState({
            url: response.data.url,
            error: 'This link is not valid',
            loading: false,
          });
        }
        setTimeout(() => this.setState({ loaded: true }), 500);
      })
      .catch((error) => {
        if (error.response
          && error.response.data.message
          && error.response.data.message.length > 0) {
          this.setState({ error: error.response.data.message, loading: false });
        } else {
          this.setState({ error: 'This link is not valid', loading: false });
        }
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
  };

  trackAction = () => {
    const { urlTrackAction } = this.props;
    axios.get(urlTrackAction);
    this.trackTime();
  };

  trackTime = () => {
    this.timer = setInterval(() => {
      const { focus, pdfLoaded } = this.state;
      const { urlTrackTime } = this.props;
      if (focus && pdfLoaded) {
        axios.get(urlTrackTime);
      }
    }, 3000);
  };

  handleVisibilityChange = (isVisible) => {
    this.setState({ focus: isVisible });
  };

  setPdfLoaded = (val) => {
    this.setState({ pdfLoaded: val });
  };

  removeNotif = () => {
    this.setState({ showNotif: false });
  };

  goBack = () => {
    // eslint-disable-next-line react/prop-types
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const {
      url, loading, loaded, error, showNotif, pdfLoaded,
    } = this.state;

    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <Container>
          {showNotif && pdfLoaded
          && (
            <div className="notification notification--full-wide notification--primary notification-fixed">
              <p className="notification__message">
                Please wait while your document is loading, it can take a few seconds...
              </p>
            </div>
          )
          }
          <button
            type="button"
            className="rounded btn btn-outline-secondary back-btn back-btn-fixed"
            onClick={this.goBack}
          >
            <ChevronLeftIcon /> Summary
          </button>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!loaded
            ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            )
            : error
              ? (
                <div className="load inload">
                  <div className="load__icon-wrap">
                    {error}
                  </div>
                </div>
              )
              : (
                <Col xs={12}>
                  {url
                    ? (
                      <PdfViewer
                        url={url}
                        pdfLoaded={val => this.setPdfLoaded(val)}
                        docLoaded={() => this.removeNotif()}
                      />
                    )
                    : (
                      <div className="align-content-center">This link is not a valid link.</div>
                    )}
                </Col>
              )
          }
        </Container>
      </PageVisibility>
    );
  }
}

export default withRouter(PDFWrapper);
