/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button, Col, Row, Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CardTitle from 'reactstrap/lib/CardTitle';
import PlusIcon from 'mdi-react/PlusIcon';


function TinySummaries(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <Row>
        <Col xs={12}>
          <CardTitle>
            <Row>
              <Col xs={12} md={8}>
                <h3>24 Tiny Summaries of the most important books for Founders and Startups</h3>
                <p className="text-muted mt-4">
                  For us, great knowledge is practical, applicable and transferable. We believe that this type of
                  know-how empowers people by opening their minds to new possibilities, by helping them spot new
                  opportunities and solve problems faster.
                </p>
                <p className="text-muted">
                  We partnered with Tiny Summaries because we want to share sources of inspiration in a short easy to
                  read format. Each Tiny Summary is based on authors we admire because they have inspired us to act and
                  apply their advice.
                </p>
                <p className="text-muted mb-2">
                  From Eric Ries The Lean Startup to Martin Lindstrom you will find 24 summarizes of books to help you
                  in your professional and personal lives. This resource is now made free for all customers of Angels
                  Partners!
                </p>
              </Col>
            </Row>
          </CardTitle>
          <Table responsive hover>
            <tbody>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1wxBMfgMkxZRVKR_pacQRQLwpLhfIgMEJ/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    22 Immutable Laws of Marketing
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    From the moment we wake up to the moment we sleep, we are bombarded with brand images and thousands
                    of adverts for numerous products. As a marketer, what can you do to successfully communicate in
                    this crowded space?
                  </p>
                  <p>
                    Whether you are an entrepreneur who wants to launch their product to market, or at the head of a
                    multinational company with many competitors, or simply a curious person who wishes to know how
                    marketing affects every aspect of products and services, The 22 immutable laws of marketing is an
                    essential addition to your business book collection.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1wxBMfgMkxZRVKR_pacQRQLwpLhfIgMEJ/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1JScJARAj2ZpA1wKFylSUa_9aMqp9CKRe/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Business Adventures
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Are you a businessperson or do you dream of being one? Does running
                    your own business give you an adrenaline rush? Do you believe you
                    have the knack to start a successful business? Do you believe your
                    ideas are capable of revolutionizing the world? Welcome to
                    Business Adventures by John Brooks, a book that deals with stories
                    about Wall Street, the Mecca of business. Drama, adventure and
                    reality combine to give useful and fascinating insights into the world
                    of business. The book’s a must-read for anyone with passion or a
                    living in business.
                  </p>
                  <p>
                    You need grit, hard work, knowledge and a fresh, new perspective to understand the Stock Market. The
                    perspective of someone who has seen and understood the biggest events in the history of business in
                    the decades past is invaluable. It gives you an insider account of events that you would only have
                    heard about.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1JScJARAj2ZpA1wKFylSUa_9aMqp9CKRe/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/15ouo9-yq4Jjr8hf3lfrKdu-cQV2DFUkT/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Buyology
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Many consumers make purchasing decisions without really
                    thinking about why they are making them. They may tell themselves
                    that such decisions are based on some sort of research they have
                    done or because their friend bought the same product and loved it,
                    but at the end of the day, they could not tell you what drove them to
                    make a purchase. This is because most of our purchasing decisions
                    are made subconsciously, which is what neuro-marketing aims to
                    reveal.
                  </p>
                  <p>
                    While there are those who would argue against neuro-marketing, because of a deep fear of
                    manipulation that
                    many people have, it is in reality just a tool for businesses like any other, which can be used to
                    improve
                    marketing strategies and our understanding of the consumption-happy side of human experience.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/15ouo9-yq4Jjr8hf3lfrKdu-cQV2DFUkT/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1vU4lkpG6NFzYM3N7i4P6wB2i0lvauGah/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The Smart Way
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Every era sees changes, but the internet technology era has seen
                    more changes than most, as it has transformed the way that we do business, shop, communicate and
                    work. A click is all that it takes to reach millions of people and communicate with someone halfway
                    across the world.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1vU4lkpG6NFzYM3N7i4P6wB2i0lvauGah/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1-eVjcec2pHh0OrPAlvAuT-rV-3x_EfXA/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Enchantment - The Art of Changing Hearts, Minds and Actions
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    When you think of a positive business experience, what stays with
                    you? Is it a great product? A great experience? Both? What is the
                    driving force that keeps you coming back time and time again for
                    more products? Chances are, it’s a little thing called enchantment,
                    which Guy Kawasaki, the chief evangelist for Canva and past chief
                    evangelist for Apple, outlines in his book. Enchantment is all about
                    touching the customer’s heart and inspiring them cerebrally instead
                    of just influencing their purchasing decision with pragmatic reasoning.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1-eVjcec2pHh0OrPAlvAuT-rV-3x_EfXA/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/184zLiEJM9jqLmbC4mvi4i8czzIrtmbdL/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    HOOKED
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    From the minute you get up and brush your teeth, to the time you go
                    to sleep with a book in your hand, you use hundreds of products and see tons of advertisements. Most
                    of the products you use have become such a part of your everyday life, you do not even think about
                    them while using them. For example, your toothbrush, coffee or mobile are products that have slowly
                    become ‘habits’.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/184zLiEJM9jqLmbC4mvi4i8czzIrtmbdL/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1Sp0QIR2S0orqFs4IFHbwLMsP1RyvPAOF/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    How to win friends and influence people
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    A LEGEND IN THE MAKING
                    “How to Win Friends and Influence People“ is a classic self-help book that has sold more than 15
                    million copies and graced “The New York Times“ bestsellers list for a whopping ten years. The fact
                    that it is still one of the best loved self-help books, is the biggest testament to its greatness.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1Sp0QIR2S0orqFs4IFHbwLMsP1RyvPAOF/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1paTS9HLRiEdHz28IUKscTbUJbGqx2Qrl/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Influence: the psychology of persuasion
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Persuasion is a soft skill and science that is needed by all, both at work and in the community. It
                    is a way of getting what you want done, without offending the other person or using brute force. We
                    often say people are naturally charming or innately persuasive because this mysterious charisma is
                    seen as something that people are born with – either they have it, or they don’t. This is a myth.
                    The truth is, anybody can be an effective persuader. All you need to do is understand the tactics of
                    persuasion.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1paTS9HLRiEdHz28IUKscTbUJbGqx2Qrl/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1eCV5qRdWEuDFoVjCC5oLNJ87p250pQvO/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    One simple idea
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Everyone dreams of living a life where they follow their dreams, see the world, are well paid and do
                    something they love. But as people grow up they see this increasingly as a fairy tale, rather than
                    something that is possible, and come to believe that such a life is impractical.
                  </p>
                  <p>
                    This is the myth that Stephen Key’s book explodes. He shows that you can still live the life of your
                    dreams and you don’t have to spend long hours at your workstation every day. All you need is your
                    innovation and creativity to get out of a rut, as you can create your own work and do something that
                    you are passionate about. Key explains how licensing your ideas to companies is the new method of
                    entrepreneurship, and the best part of this is you can do this without leaving the security of your
                    day job.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1eCV5qRdWEuDFoVjCC5oLNJ87p250pQvO/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1uc4fbaMl_A9OzcOWbwPY3srPV0_W6noO/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    REWORK
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Are you an entrepreneur who has built his own
                    business from scratch? Do you run an already existing
                    company, but you are not happy with it? Is your work
                    culture suering from stagnancy? Is the standard
                    business advice not working out for you?
                  </p>
                  <p>
                    Whatever stage you are at now, the co-author of Rework, Jason Fried, has been there already. After
                    more than a decade of sustained protability, Fried brings his best advice to the table – and most of
                    it is unconventional. Fried is the co-founder of 37signals a famous software company. They started
                    with just sixteen employees and no marketing department. Today they are known for excellent products
                    like Highrise, Campre etc., and are worth millions. This book puts together the best ideas and
                    lessons from their experience, all applicable to business ventures in the twenty-rst century.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1uc4fbaMl_A9OzcOWbwPY3srPV0_W6noO/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/18vj-oMlK2Jj-xyUQPSTYWX5LZ1eEBdK7/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The $100 Startup
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    A UNIQUE READ
                  </p>
                  <p>
                    “The $100 Dollar Startup“ has a unique place amongst self-help books. There are books that tell you
                    how to excel professionally, make your startup a success and tell you how to live a fulfilling life,
                    but a book that combines both of these kinds of advice is rare. “The $100 Dollar Startup“ tells you,
                    as the subtitle of the book says, how to ‘reinvent the way you make a living, do what you love and
                    create a new future’.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/18vj-oMlK2Jj-xyUQPSTYWX5LZ1eEBdK7/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1d7XFZTkY6YafY0ihzpEENud82uX4Orjk/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The Four Hour WorkWeek
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Are you tired of working from 9 to 5 every day and looking for a way out? Are you looking to make
                    $80k a month? Are you looking for a method that teaches you how to do  ten hours
                    of work in one? If so, Timothy Ferriss’ book, ‘The 4-Hour Work Week’, is what you’ve been looking
                    for – a book that helps you to become independent, enjoy life and do more.
                  </p>
                  <p>
                    You don’t need to study at Harvard or come from a rich family to be successful. You can pass at an
                    ordinary college, come from a not-so-rich family and get fired from your jobs, even after a few
                    days, and still be successful. What you need are some strategies and a new way of looking at life.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1d7XFZTkY6YafY0ihzpEENud82uX4Orjk/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/15ySbFJpHPPR55_dz-GqnGTPvI-JHoWH9/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The Elements of Persuasion
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    In today’s world of automation, machines and gadgets, the human
                    touch still rules businesses. It is all about how you deal with a customer who has a complaint or
                    how you explain your cutting-edge technology product to a person who has no idea about gadgets.
                    Whatever business you are in, persuasion plays a great role. A master of persuasion can handle
                    business transactions effectively and communicate in a manner that rules out any misunderstandings.
                    This is a key skill that enables you to handle every arena of a business effectively. In fact, it is
                    what marks that small difference between a number one and a number two.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/15ySbFJpHPPR55_dz-GqnGTPvI-JHoWH9/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1XFH5Iqg8niYLyR2NmJN62LYRq-29AjsX/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The Hard Thing about Hard Things
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    There are a lot of self-help books out there that provide you with
                    ready-made recipes for success, whether it be for the perfect leader, perfect CEO or perfect
                    startup. These offer many generalities that claim to be iron-clad rules of ‘success’ , telling us to
                    do this and don’t do that. Sadly, the real world does not work like this and no real life crisis can
                    be such cleverly packaged advice. It is not surprising, therefore, that these kind of recipes and
                    guidelines have fallen into disuse.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1XFH5Iqg8niYLyR2NmJN62LYRq-29AjsX/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1GGZG9XVnsoMz7WZgAtm-0BADkBOhf4Z8/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The Innovator’s Dilemma
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    “The Innovator’s Dilemma: When New Technologies Cause Great Firms to Fail“ was written by Harvard’s
                    most famous professor, Clayton Christensen, over a decade ago. Hailed by The Financial Times as
                    ‘The Best Business Book of 1997’, it is not only one of the most famous books of the twentieth
                    century, it is also one of the best guides you can use to navigate the market.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1GGZG9XVnsoMz7WZgAtm-0BADkBOhf4Z8/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1ipBSDqwlNTn-J8AAqYbuOCupT24eAKd8/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The Lean Startup
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Are you an entrepreneur or dream of being one? Do you have a great idea that you think can change
                    the way a certain things done? Do Zuckerberg, Bill Gates and Ray Kroc inspire you? In that case,
                    The Lean Startup is the perfect book for you.
                  </p>
                  <p>
                    An idea is the root of a good start-up, but execution is what makes it a success or a failure. Most
                    people are either afraid of taking on the risk of failure or lack the know-how to be able to
                    translate their idea into a marketable product. Eric Ries proposes the lean startup method, which
                    eliminates the problem of having to look for enormous starting capital, while also shortening the
                    learning process and product testing time. This method has been successfully in Silicon Valley, and
                    has now reached global inuence. Today, Eric Ries’ teachings apply to everyone who can be called an
                    entrepreneur, whether you are tinkering in your garage or dreaming of launching your rst IPO one
                    day.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1ipBSDqwlNTn-J8AAqYbuOCupT24eAKd8/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1oF7X8DhA_UmK5qdN3qjBVU0_kXbLrB8I/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The Magic of Thinking Big
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Do you look at successful people and feel they are lucky to have an innate talent? Have you ever
                    wished that you were also born with that kind of ability? Have you given up on chasing your dreams
                    of success and, instead, settled down to a routine, ‘practical’ life? If so, “The Magic of Thinking
                    Big“ is the first thing you should buy today.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1oF7X8DhA_UmK5qdN3qjBVU0_kXbLrB8I/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1SL8QweLStd_CMaImr4jkaFdaNg3zeGVB/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The obstacle is the way
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    THE CHASE AFTER SUCCESS
                  </p>
                  <p>
                    One common thread that binds all human being together is the desire to succeed. Therefore, everyone
                    tries to look for that secret ‘ingredient’ to success. Is it luck, hard work, perseverance or a
                    God-given skill? There is no one answer, some say. But Ryan Holiday, the bestselling author of a
                    number of phenomenal self-help books, answers this very question in “The Obstacle is the Way“.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1SL8QweLStd_CMaImr4jkaFdaNg3zeGVB/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1PycKf-9j4FYls8nWmSVY-GToBOczucVh/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The One Thing: the surprisingly simple truth behind extraordinary results
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Time management is an issue that has been addressed since time immemorial. In daily life, people try
                    to get as much done as possible. However, in spite of working hard, we often ask ourselves where is
                    the success that should come along with it? Effectively, this is the question that “The One Thing:
                    the surprisingly simple truth behind extraordinary results“ tackles.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1PycKf-9j4FYls8nWmSVY-GToBOczucVh/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1n9eCPJ9vHA1JFMaeQ8DSRhMzLSIeSezT/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    The Promodoro Technique
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Time management is an elusive skill for most of us as we wish to
                    stop procrastinating and get a hold on this precious resource so
                    that we can use it to the best of our ability. It is easy to feel like we
                    are either chasing time, running from one appointment to the next,
                    or are wasting time when we should be doing something productive.
                    In order to truly maximise the resource of time and use it to our
                    advantage, we have to take ownership of these problems, and form
                    the determination to succeed at completing our tasks on time and
                    being aware of the decisions that we make about how we use the time we have available.
                  </p>
                  <p>
                    To make the most of your time, it is important to cut down on interruptions and the Pomodoro
                    Technique helps you to do this by using easy and effective methods to structure your day-to-day
                    activities. These have been refined over a number of years in order to provide the best possible
                    results.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1n9eCPJ9vHA1JFMaeQ8DSRhMzLSIeSezT/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1ScA68b-jI2ACQkhOdQTMYxzCVAZjEVMd/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    THINKING, FAST AND SLOW
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    DEFINING ‘THINKING’
                  </p>
                  <p>
                    Thinking is something that all of us do all of the time, but have you ever given a thought to the
                    process that leads you to think in a particular way or why you reach a particular conclusion? Daniel
                    Kahneman, winner of the Nobel Memorial Prize in Economics, answers the question and, in the process,
                    tells you how you can improve your thinking process and make better decisions.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/1ScA68b-jI2ACQkhOdQTMYxzCVAZjEVMd/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a target="_blank" rel="noopener noreferrer" href={isCustomer ? 'https://drive.google.com/file/d/12VphSpkOI34eOvkynAk4GUvzWV75zVDP/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    You Are Not So Smart
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    We go through our everyday lives without consciously thinking
                    about ourselves or the things around us, but imagine sitting down and taking the time to look at
                    what we humans have achieved, our wonderful inventions and discoveries, and what our minds are
                    capable of. Your belief in the rationality and scientific conduct of the human mind would grow
                    substantially as a result. The human mind seems like a wonderful computer that not only makes all
                    these amazing inventions possible, but is also a machine beyond compare, always correct and logical,
                    if it is applied properly.
                  </p>
                  {/* eslint-disable-next-line max-len */}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={isCustomer
                      ? 'https://drive.google.com/file/d/12VphSpkOI34eOvkynAk4GUvzWV75zVDP/view?usp=sharing'
                      : '#'}
                    onClick={checkIsCustomer}
                  >
                    <Button color="danger" className="btn rounded icon mt-4">
                      Open PDF
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

TinySummaries.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default TinySummaries;
