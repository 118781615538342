import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import App from './containers/App/App';

axios.defaults.baseURL = process.env.REACT_APP_DOMAIN_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(request => request,
  (error) => {
    if (error.response && !error.response.status) {
      // network error
      // eslint-disable-next-line no-alert
      alert('You need to log in again.');
      window.location.replace(`${process.env.REACT_APP_DOMAIN_URL}/logout`);
    }
    return Promise.reject(error);
  });

render(
  <App />,
  document.getElementById('root'),
);
