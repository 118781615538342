import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import axios from 'axios';

import ProfileSideBar from '../../shared/components/ProfileSidebar';
import ProfileBasic from '../MyInvestorProfile/components/ProfileBasic';
import SettingsTabs from './components/SettingsTabs';
import Mailboxes from './components/Mailboxes';
import Alert from '../../shared/components/alerts/Alert';
import ModalCancelSubscription from '../../shared/components/modals/ModalCancelSubscription';
// eslint-disable-next-line import/no-named-as-default
import ModalFeedback from '../../shared/components/modals/wizardFeedbackForm/ModalFeedback';

export default class Profile extends PureComponent {
  state = {
    startup: {},
    investor: {},
    profile: {},
    paymentPlans: {},
    loading: true,
    loaded: false,
    error: false,
    errorMessage: false,
    modalCancelSubscription: false,
    modalFeedback: false,
    isTrial: false,
    type: 'investor',
    user: JSON.parse(localStorage.getItem('user')),
  };

  componentDidMount = () => {
    this.getMyInfo();
    this.getPaymentPlansInfo();
    this.updateUser();
  };

  hideAlert = () => {
    setTimeout(
      () => {
        this.setState({ updated: false, error: false });
      },
      6000,
    );
  };

  updateUser = () => {
    axios.get('/user')
      .then((response) => {
        if (typeof response.data === 'object') {
          localStorage.setItem('user', JSON.stringify(response.data));
          this.setState({ type: response.data.type });
        }
      });
  };

  toggleModalCancelSubscription = () => {
    const { modalCancelSubscription } = this.state;
    this.setState({ modalCancelSubscription: !modalCancelSubscription });
  };

  toggleModalFeedback = () => {
    const { modalFeedback } = this.state;
    this.setState({ modalFeedback: !modalFeedback });
  };

  openContactForm = () => {
    window.open(`${process.env.REACT_APP_DOMAIN_URL}/contact`, '_blank');
  }

  getMyInfo = () => {
    axios.get('user/account')
      .then((response) => {
        const startup = {
          name: response.data.startup,
          logo: response.data.logo,
          location_city: response.data.location_city,
          location_country: response.data.location_country,
          shortDescription: response.data.short_description,
          description: response.data.description,
        };
        const investor = {
          investor_city: response.data.investor_city,
          investor_country: response.data.investor_country,
          picture: response.data.picture,
        };
        const profile = {
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          pings: response.data.pings,
          pingsUsed: response.data.pingsUsed,
          account: response.data.planType,
          view: response.data.view,
          deck: response.data.deck,
          isCustomer: response.data.isCustomer,
          isPayingCustomer: response.data.isPayingCustomer,
          id: response.data.id,
          message: response.data.message,
          declined: response.data.declined,
          newsletter: response.data.newsletter,
          blog: response.data.blog,
          instant: response.data.instant,
          weekly: response.data.weekly,
          monthly: response.data.monthly,
          dateSubscribed: response.data.dateSubscribed,
          datePlanEnd: response.data.datePlanEnd,
          dateTrialEnd: response.data.dateTrialEnd,
          dateTrialCancelled: response.data.dateTrialCancelled,
          paymentGateway: response.data.paymentGateway,
          signature: response.data.signature,
          willRenew: response.data.willRenew,
        };
        this.setState({
          startup,
          profile,
          investor,
          loading: false,
          isTrial: response.data.isCustomer && !response.data.isPayingCustomer && !response.data.isPaying,
        });
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
  };

  getPaymentPlansInfo = () => {
    axios.get('subscription/all')
      .then((response) => {
        this.setState({ paymentPlans: response.data, loading: false });
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
  };

  render = () => {
    const {
      type, startup, profile, investor, loaded, loading, updated, error, errorMessage,
      modalCancelSubscription, paymentPlans, modalFeedback, isTrial, user,
    } = this.state;

    let notification = null;
    if (updated) {
      notification = (
        <Alert color="info" className="alert--colored">
          <p><span className="bold-text">Success! </span>
            Your information has been updated successfully
          </p>
        </Alert>
      );
    }
    if (error) {
      notification = (
        <Alert color="danger" className="alert--colored">
          <p><span className="bold-text">Error! </span>
            {errorMessage}
          </p>
        </Alert>
      );
    }

    return (
      <Container className="settings">
        <Row>
          { notification }
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Account Settings</h3>
          </Col>
          <Col xs={12} md={9} lg={6} xl={5}>
            <p className="page-description">
              {type === 'startup' && user.isCustomer
                ? (
                  <span>
                    <strong>Connect your mailbox</strong><br />
                    to start sending emails and get the best out of Angels Partners.
                  </span>
                ) : 'Edit your account basic settings and your notifications preferences' }
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            {!loaded
              ? (
                <div className={`load${loading ? '' : ' loaded'} inload`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              )
              : (
                <div>
                  {user && user.type === 'startup' && <Mailboxes /> }
                  <Row>
                    <Col xs={12} xl={8} className="order-1 order-xl-0 p-0 p-lg-3">
                      <Row>
                        <SettingsTabs
                          firstName={profile.firstName}
                          lastName={profile.lastName}
                          email={profile.email}
                          notificationView={profile.view}
                          notificationDeck={profile.deck}
                          notificationMessage={profile.message}
                          notificationDeclined={profile.declined}
                          notificationBlog={profile.blog}
                          notificationNewsletter={profile.newsletter}
                          investorInstant={profile.instant}
                          investorWeekly={profile.weekly}
                          investorMonthly={profile.monthly}
                          paymentPlans={paymentPlans}
                          signature={profile.signature}
                          datePlanEnd={profile.datePlanEnd}
                          dateTrialEnd={profile.dateTrialEnd}
                          dateTrialCancelled={profile.dateTrialCancelled}
                          willRenew={profile.willRenew}
                          isTrial={isTrial}
                          planType={profile.account}
                          isPayingCustomer={profile.isPayingCustomer}
                          getMyInfo={this.getMyInfo}
                          isCustomer={profile.isCustomer}
                          onInfoUpdated={info => this.updateInfo(info)}
                        />
                      </Row>
                    </Col>
                    <Col xs={12} xl={4} className="order-0 order-xl-1">
                      {type !== 'investor'
                        && (
                        <ProfileSideBar
                          id={profile.id}
                          name={startup.name}
                          logo={startup.logo}
                          locationCity={startup.location_city}
                          locationCountry={startup.location_country}
                          description={startup.shortDescription}
                          pings={profile.pings}
                          pingsUsed={profile.pingsUsed}
                          account={profile.account}
                          datePlanEnd={profile.datePlanEnd}
                          dateTrialCancelled={profile.dateTrialCancelled}
                          dateTrialEnd={profile.dateTrialEnd}
                          isCustomer={profile.isCustomer}
                          willRenew={profile.willRenew}
                          cancelSubscriptionToggle={this.toggleModalCancelSubscription}
                        />
                        )
                      }
                      {type === 'investor'
                        && (
                          <ProfileBasic
                            firstName={profile.firstName}
                            lastName={profile.lastName}
                            picture={investor.picture}
                            locationCity={investor.investor_city}
                            locationCountry={investor.investor_country}
                            planType={profile.account}
                            dateSubscribed={profile.dateSubscribed}
                            dateTrialEnd={profile.dateTrialEnd}
                            datePlanEnd={profile.datePlanEnd}
                            dateTrialCancelled={profile.dateTrialCancelled}
                            willRenew={profile.willRenew}
                            isCustomer={profile.isCustomer}
                            cancelSubscriptionToggle={this.openContactForm}
                          />
                        )
                      }
                    </Col>
                  </Row>
                </div>
              )
            }
            <ModalCancelSubscription
              displayed={modalCancelSubscription}
              toggleModal={this.toggleModalCancelSubscription}
              getMyInfo={this.getMyInfo}
              toggleModalFeedback={this.toggleModalFeedback}
            />
            <ModalFeedback
              displayed={modalFeedback}
              toggleModal={this.toggleModalFeedback}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
