import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Card, CardBody,
} from 'reactstrap';

const PanelDocsend = ({ type }) => (
  <Card className="panel panel--divider card shadow mb-3">
    <CardBody className="panel__body">
      <div className="panel__title no-padding">
        <h5 className="bold-text">
          <Badge className="panel__label">Feature</Badge>
          &nbsp;&nbsp;&nbsp;
          {`Replace your ${type} link with a Tracking Link`}
        </h5>
        {/* <h5 className="subhead">{subhead}</h5> */}
      </div>
      <div className="panel__content">
        <p>
          <strong><u>Did you know that Angels Partners integrates our own document sharing feature?</u></strong>
        </p>
        <p className="typography-message">
          Simply replace your {type} link  with the variable <strong>&#123;&#123;trackingLink&#125;&#125;</strong>
          and our system will automatically generate a unique link to each investor letting you accurately track who
          visited your profile, gauge this investor&#39;s interest and automatically take action based on a visit.
        </p>
        <p>
          We strongly recommend using our own tracking link feature to get the best out of Angels Partners.
        </p>
      </div>
    </CardBody>
  </Card>
);

PanelDocsend.propTypes = {
  type: PropTypes.string.isRequired,
};

export default PanelDocsend;
