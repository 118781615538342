import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// import PropTypes from 'prop-types';
import { UserProps } from '../../shared/prop-types/ReducerProps';
import ModalPaywall from '../../shared/components/modals/ModalPaywall';

class List extends Component {
  static propTypes = {
    user: UserProps.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    // history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalPaywall: false,
      user: null,
    };
  }

  componentWillReceiveProps(newProps) {
    const { props } = this;

    // eslint-disable-next-line react/prop-types
    if (newProps.user.investorsAdded !== props.user.investorsAdded) {
      this.setState({ user: newProps.user }, () => this.displayPaywall());
    }
  }

  componentDidMount = () => {
    const { user } = this.props;
    this.setState({ user }, () => this.displayPaywall());
  };

  displayPaywall = () => {
    // const { history } = this.props;
    const { user } = this.state;

    if ((user.paywallGroup >= 2 && !user.isCustomer) || user.isTrialStarted) {
      // history.push('/ext/pricing');
    } else if (user.paywallGroup >= 1 && !user.isCustomer && user.investorsAdded) {
      this.setState({ modalPaywall: true });
    }
  }

  render() {
    const { modalPaywall } = this.state;

    return (
      <ModalPaywall
        displayed={modalPaywall}
        toggleModal={() => this.toggleModalPaywall()}
      />
    );
  }
}

export default withRouter(connect(state => ({
  user: state.user,
}))(List));
