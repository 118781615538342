/* eslint-disable */
const validate = (values, props) => {

  const errors = {};

  if (props.type === 'import') {
    if (!values.firstname) {
      errors.firstname = 'Please enter a firstname';
    }
  }

  if (!values.email) {
    errors.email = 'Email field should not be left empty';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

export default validate;
