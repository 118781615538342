/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (values.revenues === "") {
    errors.revenues = 'Please specify your revenues';
  } else if (values.revenues < 0) {
    errors.revenues = 'Your revenues must be positive';
  }
  if (values.revenues > 0 && values.nbCustomers < 0) {
    errors.nbCustomers = 'Your number of customers must be positive';
  }
  if (values.revenues > 0 && values.netProfit < 0) {
    errors.netProfit = 'Your net profit must be positive';
  }
  if (values.revenues > 0 && values.netProfit > 0 && values.netProfit > values.revenues) {
    errors.netProfit = 'Your net profit cannot be higher than your revenues';
    errors.revenues = 'Your net profit cannot be higher than your revenues';
  }

  return errors;
};

export default validate;
