import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';

import ResourcesTabs from './components/ResourcesTabs';

const Resources = () => (
  <Container className="page__resources">
    <Row>
      <Col xs={12} lg={2}>
        <h3 className="page-title">Resources</h3>
      </Col>
      <Col xs={12} lg={{ size: 8, offset: 1 }} xl={{ size: 5, offset: 0 }}>
        <p className="page-description">
          <strong>Here is a curated directory of tools and resources to build your startup.</strong><br />
          From legal documents to templates, financials documents or tips,
          use this collection of extremely helpful free resources to save time during your fundraising journey.
        </p>
      </Col>
    </Row>
    <ResourcesTabs />
  </Container>
);

export default Resources;
