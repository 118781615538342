/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (values.market && values.market.length < 10) {
    errors.market = 'Your market field should be at least 10 characters long.';
  } else if (values.market && values.market.length > 1500) {
    errors.market = 'Your market field must be less than 1500 characters long.';
  }

  return errors;
};

export default validate;
