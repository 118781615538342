import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PDFWrapper from '../../../shared/components/pdfViewer/pdfWrapper';
import { closeSidebar } from '../../../redux/actions/sidebarActions';


class StartupDeckPage extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(closeSidebar());
  }

  render() {
    const { props } = this;
    const { id } = props.match.params;

    return (
      <PDFWrapper
        baseDocUrl={process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_DECKS_PATH}
        urlRetrieveDoc={`startup/deck/${id}`}
        urlTrackAction={`startup/profile/track/deck/${id}`}
        urlTrackTime={`startup/profile/track/timeDeck/${id}`}
      />
    );
  }
}

export default connect(state => ({
  sidebar: state.sidebar,
}))(StartupDeckPage);
