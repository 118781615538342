/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import moment from 'moment';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { MenuItem, Select as SelectField } from '@material-ui/core';
import axios from 'axios';
import Alert from '../../../../shared/components/alerts/Alert';
import validate from '../../formValidate/info';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

const renderSelectField = ({
  input, label, meta: { touched, error }, placeholder, multiple, options, children,
}) => (
  <div className="form__form-group-input-wrap">
    <SelectField
      className="material-form__field form__form-group-select"
      label={label}
      error={(touched && (typeof error !== 'undefined' && error !== ''))} // This will show the error message
      helperText={touched && error}
      placeholder={placeholder}
      multiple={multiple}
      children={children}
      value={input.value}
      defaultValue={input.value}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    >
      {options !== null && Array.isArray(options)
        ? options.map(option => (
          <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
        )) : (
          <MenuItem key="0" value="0">No Country available</MenuItem>
        )
      }

    </SelectField>
  </div>
);

renderSelectField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.element),
  children: PropTypes.arrayOf(PropTypes.element),
};

renderSelectField.defaultProps = {
  meta: null,
  label: '',
  placeholder: '',
  multiple: false,
  options: [],
  children: [],
};


class StartupInformation extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
      shortDescription: PropTypes.string,
      description: PropTypes.string,
      updates: PropTypes.string,
      incorporated: PropTypes.instanceOf(moment),
      locationCity: PropTypes.string,
      locationCountry: PropTypes.string,
      category: PropTypes.string,
      secondaryCategory: PropTypes.string,
      stage: PropTypes.string,
      cac: PropTypes.number,
      ltv: PropTypes.number,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      countriesError: false,
      countries: [],
      startupCategoriesError: false,
      startupCategories: [],
      startupStages: [],
      startupStagesError: false,
    };
  }

  componentDidMount() {
    axios.get('/static/listcountries').then((response) => {
      if (response.data) {
        this.setState({ countriesError: false, countries: response.data });
      }
    }).catch(() => {
      this.setState({
        countriesError: true,
        countries: null,
      });
    });
    axios.get('/static/liststartupcategories').then((response) => {
      if (response.data) {
        this.setState({ startupCategoriesError: false, startupCategories: response.data });
      }
    }).catch(() => {
      this.setState({
        startupCategoriesError: true,
        startupCategories: null,
      });
    });
    axios.get('/static/liststartupstages').then((response) => {
      if (response.data) {
        this.setState({ startupStagesError: false, startupStages: response.data });
      }
    }).catch(() => {
      this.setState({
        startupStagesError: true,
        startupStages: null,
      });
    });
  }

  render() {
    const {
      handleSubmit, pristine, reset, submitting, initialValues,
    } = this.props;

    const {
      countries, countriesError, startupCategories, startupCategoriesError, startupStages, startupStagesError,
    } = this.state;
    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <Alert color="info" className="mb-5 alert--colored">
          <div>
            <span className="bold-text">Need Help with this section? </span>
            Refer to our resources to:
            <ul>
              <li>
                <a
                  href="https://angelspartners.com/blog/building-your-business-model"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Learn how to build your business model
                </a>
              </li>
              <li>
                <a
                  href="https://angelspartners.com/blog/pitch-perfect"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  How to best describe your company in a few words
                </a>
              </li>
            </ul>
          </div>
        </Alert>
        {countriesError
        && (
          <Alert color="error" className="alert--neutral" icon>
            <div>
              There has been an issue retrieving the list of countries. Please try again or contact support.
            </div>
          </Alert>
        )}
        {startupCategoriesError
        && (
          <Alert color="error" className="alert--neutral" icon>
            <div>
              There has been an issue retrieving the list of startup categories. Please try again or contact support.
            </div>
          </Alert>
        )}
        {startupStagesError
        && (
          <Alert color="error" className="alert--neutral" icon>
            <div>
              There has been an issue retrieving the list of startup stages. Please try again or contact support.
            </div>
          </Alert>
        )}
        <div className="margin10">
          <span className="material-form__label"><h5>Short Description</h5></span>
          <Field
            name="shortDescription"
            component={renderTextField}
            type="text"
          />
        </div>
        <div className="margin10">
          <span className="material-form__label"><h5>Business Model</h5></span>
          <Field
            name="description"
            component={renderTextField}
            type="text"
            multiline
          />
        </div>
        <div className="form__form-group margin10">
          <span className="material-form__label"><h5>Url to your website</h5></span>
          <Field
            name="url"
            component={renderTextField}
            placeholder="Enter the url of your product or landing page starting with http(s)"
          />
        </div>
        <div className="form__form-group margin10">
          <span className="material-form__label"><h5>Incorporated</h5></span>
          <div className="form__form-group-field">
            <Field
              name="incorporated"
              component={renderDatePickerField}
              startDate={initialValues.incorporated}
            />
            <div className="form__form-group-icon">
              <CalendarBlankIcon />
            </div>
          </div>
        </div>
        <div className="form__form-group margin10">
          <span className="material-form__label"><h5>Location city</h5></span>
          <div className="form__form-group-field">
            <Field
              name="locationCity"
              component={renderTextField}
            />
          </div>
        </div>
        <div className="form__form-group margin10">
          <span className="material-form__label"><h5>Location country</h5></span>
          <div className="form__form-group-field">
            <Field
              name="locationCountry"
              component={renderSelectField}
              placeholder="Choose country"
              options={countries}
            />
          </div>
        </div>
        <div className="form__form-group margin10">
          <span className="material-form__label"><h5>Primary Industry</h5></span>
          <div className="form__form-group-field">
            <Field
              name="category"
              component={renderSelectField}
              placeholder="Choose Industry"
              options={startupCategories}
            />
          </div>
        </div>
        <div className="form__form-group margin10">
          <span className="material-form__label"><h5>Secondary Industry</h5></span>
          <div className="form__form-group-field">
            <Field
              name="secondaryCategory"
              component={renderSelectField}
              placeholder="Choose Industry"
              options={startupCategories}
            />
          </div>
        </div>
        <div className="form__form-group margin10">
          <span className="material-form__label"><h5>Startup stage</h5></span>
          <div className="form__form-group-field">
            <Field
              name="stage"
              component={renderSelectField}
              placeholder="Choose stage"
              options={startupStages}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
            Save
            <ChevronRightIcon />
          </Button>
          <Button className="rounded mt-5" type="button" onClick={reset} disabled={pristine || submitting}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}


export default reduxForm({
  form: 'startup_information_form', // a unique identifier for this form
  validate,
})(StartupInformation);
