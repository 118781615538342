function getCountry(country) {
  if (country === 1) {
    return 'United States';
  } if (country === 2) {
    return 'United Kingdom';
  } if (country === 3) {
    return 'Canada';
  } if (country === 4) {
    return 'Australia';
  } if (country === 5) {
    return 'Germany';
  } if (country === 6) {
    return 'India';
  } if (country === 7) {
    return 'Japan';
  } if (country === 8) {
    return 'Other';
  } if (country === 9) {
    return 'France';
  } if (country === 10) {
    return 'Mexico';
  } if (country === 11) {
    return 'Italie';
  } if (country === 12) {
    return 'Spain';
  } if (country === 13) {
    return 'South Africa';
  } if (country === 14) {
    return 'Sweden';
  } if (country === 15) {
    return 'China';
  } if (country === 16) {
    return 'Switzerland';
  } if (country === 17) {
    return 'Netherlands';
  } if (country === 18) {
    return 'Denmark';
  } if (country === 19) {
    return 'Russia';
  } if (country === 20) {
    return 'Singapore';
  } if (country === 21) {
    return 'Hong Kong';
  } if (country === 22) {
    return 'United Arab Emirates';
  } if (country === 23) {
    return 'Portugal';
  } if (country === 24) {
    return 'Israel';
  } if (country === 25) {
    return 'Taiwan';
  } if (country === 26) {
    return 'Argentina';
  } if (country === 27) {
    return 'Brazil';
  } if (country === 28) {
    return 'Colombia';
  } if (country === 29) {
    return 'Latin America';
  } if (country === 30) {
    return 'Belgium';
  } if (country === 31) {
    return 'Austria';
  } if (country === 32) {
    return 'Europe';
  } if (country === 33) {
    return 'Estonia';
  } if (country === 34) {
    return 'Finland';
  } if (country === 35) {
    return 'Norway';
  } if (country === 36) {
    return 'Hungary';
  } if (country === 37) {
    return 'New Zealand';
  } if (country === 38) {
    return 'Poland';
  } if (country === 39) {
    return 'Turkey';
  } if (country === 40) {
    return 'Iceland';
  } if (country === 41) {
    return 'Korea';
  } if (country === 42) {
    return 'Lithuania';
  } if (country === 43) {
    return 'Ukraine';
  } if (country === 44) {
    return 'Middle East';
  } if (country === 45) {
    return 'Luxembourg';
  } if (country === 46) {
    return 'Asia';
  } if (country === 47) {
    return 'Ireland';
  } if (country === 48) {
    return 'Chile';
  } if (country === 49) {
    return 'Czech Republic';
  } if (country === 50) {
    return 'Philippines';
  } if (country === 51) {
    return 'Vietnam';
  } if (country === 52) {
    return 'Bulgaria';
  } if (country === 53) {
    return 'Romania';
  } if (country === 54) {
    return 'Slovakia';
  } if (country === 55) {
    return 'Slovenia';
  } if (country === 56) {
    return 'Sri Lanka';
  } if (country === 57) {
    return 'Thailand';
  } if (country === 58) {
    return 'Greece';
  } if (country === 59) {
    return 'Cyprus';
  } if (country === 60) {
    return 'Myanmar';
  } if (country === 61) {
    return 'Malaysia';
  } if (country === 62) {
    return 'Indonesia';
  } if (country === 63) {
    return 'Pakistan';
  } if (country === 64) {
    return 'Cambodia';
  }

  return 'Country';
}

export default getCountry;
