import React, { PureComponent } from 'react';
import {
  Col, TabContent, TabPane, NavItem, NavLink, Nav, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router';

import ReactPlayer from 'react-player';
import Collapse from '../../../shared/components/Collapse';

class FounderTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      minHeight: 0,
    };
  }

  componentDidMount() {
    const { startup } = this.props;
    const video = document.getElementById('video');
    if (video) {
      this.setState({ minHeight: video.offsetHeight });
    }

    if (startup.deck && !startup.video) {
      this.getDeckUrl();
    }
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { activeTab, minHeight } = this.state;
    const { startup } = this.props;

    return (
      <div>
        <Row className="startup__information">
          <Col xs={12} xl={8}>
            {startup.video
              && (
                <Row>
                  <Col xs={12}>
                    <ReactPlayer
                      id="video"
                      className="mb-4"
                      url={startup.video}
                      width="100%"
                      light
                      controls
                    />
                  </Col>
                </Row>
              )}
            <Row className="d-xl-none d-block"> {/* ONLY FOR MOBILE */}
              <Col xs={12}>
                <div className="startup__business mb-5">
                  <h2>Note from {startup.first_name}</h2>
                  <p>
                    {startup.message}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="d-block d-md-none">
              <Col xs={12}>
                <Collapse title="Team" className="with_border pre-wrap" isOpened>
                  <p>
                    {startup.team}
                  </p>
                </Collapse>
              </Col>
            </Row>
            <Row className="d-none d-md-block">
              <Col xs={12}>
                <div className="tabs--justify tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '1' })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          Team
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <p className="text-muted pre-wrap">
                          {startup.team}
                        </p>
                      </TabPane>
                      {startup.market
                        && (
                          <TabPane tabId="2">
                            <p className="text-muted pre-wrap">
                              {startup.market}
                            </p>
                          </TabPane>
                        )}
                    </TabContent>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={4}>
            <Row className="d-none d-xl-block">
              <Col xs={12}>
                <div className="startup__business pre-wrap" style={{ minHeight }}>
                  <h2>Note from {startup.first_name}</h2>
                  <p>
                    {startup.message
                      ? startup.message
                      : (
                        <div dangerouslySetInnerHTML={{
                          // eslint-disable-next-line max-len
                          __html: `<p><i>${startup.first_name} did not leave a message yet, but we invite you to reach out to this founder if you are looking to connect with investors in their sector and stage.</i></p><p><i>To benefit from mutual introductions to investors, we recommend you connect on a personal level with founders and see how you can help each other. It also means you could connect other founders with your investor network.</i>  </p>`,
                        }}
                        />
                      )
                    }
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(FounderTabs);

FounderTabs.propTypes = {
  startup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    short_description: PropTypes.string,
    introduction: PropTypes.string,
    team: PropTypes.string,
    logo: PropTypes.string,
    incorporated: PropTypes.string,
    location_city: PropTypes.string,
    location_country: PropTypes.string,
    url: PropTypes.string,
    stage: PropTypes.string,
    category: PropTypes.string,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    new: PropTypes.bool,
    favorite: PropTypes.bool,
    pinging: PropTypes.bool,
    adding: PropTypes.bool,
    accepted: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
};
