import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Modal, Nav, NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import queryString from 'query-string';

import { connect } from 'react-redux';
import ModalCampaignsForm from './components/ModalCampaignForm';
import SelectBoxes from './components/SelectBoxes';
import BlankEmail from './components/BlankEmailForm';
import Alert from '../alerts/Alert';
import { updateInvestorsAdded } from '../../../redux/actions/userActions';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

// Used for a single investor

class ModalSingleInvestorCampaign extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.array.isRequired,
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    selectedCampaignId: PropTypes.number,
    showNotif: PropTypes.func.isRequired,
    showNotifSuccess: PropTypes.func.isRequired,
    showNotifWarning: PropTypes.func.isRequired,
    updateListOfInvestors: PropTypes.func.isRequired,
    investorContactInfo: PropTypes.shape({
      contactId: PropTypes.number,
      fullName: PropTypes.string,
      picture: PropTypes.string,
      investorId: PropTypes.number,
      email: PropTypes.bool,
      inCampaign: PropTypes.bool,
      campaignName: PropTypes.string,
      contacted: PropTypes.bool,
      contactedOn: PropTypes.string,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    displayed: false,
    selectedCampaignId: null,
    investorContactInfo: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      campaigns: null,
      loading: false,
      loaded: false,
      activeTab: 0,
      choiceMade: false,
      chooseTemplates: null,
      formError: null,
    };

    this.toggle = this.toggle.bind(this);
    this.addRecipientToCampaign = this.addRecipientToCampaign.bind(this);
    this.sendPlainEmail = this.sendPlainEmail.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      modal: newProps.displayed, choiceMade: false, chooseTemplates: null, formError: null,
    });

    if (newProps.displayed && newProps.investorContactInfo) {
      const { investorContactInfo } = newProps;
      this.retrieveCampaigns(investorContactInfo);
    } else if (newProps.displayed && !newProps.investorContactInfo) {
      const { showNotif, toggleModal } = this.props;
      if (typeof showNotif === 'function') {
        showNotif('Sorry, an error occurred, please try again.');
      }
      if (typeof toggleModal === 'function') {
        toggleModal();
      }
    }
  }

  retrieveCampaigns = (investorContactInfo) => {
    const { selectedCampaignId } = this.props;

    this.setState({ loading: true, loaded: false, formError: null });
    axios.get(`/campaigns/generate/${investorContactInfo.contactId}/${investorContactInfo.investorId}`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          this.setState({
            campaigns: response.data,
            loading: false,
            loaded: true,
          }, () => {
            if (selectedCampaignId) {
              this.toggleChoiceMade(true);
            }
          });
        } else {
          this.setState({
            campaigns: null,
            loading: false,
            loaded: true,
          });
        }
      })
      .catch((error) => {
        const { toggleModal } = this.props;
        if (error && error.response && typeof showNotif === 'function') {
          // Do something error.response.data.message ?
          this.setState({ formError: 'Sorry, an error occurred, please try again.' });
        } else if (typeof showNotif === 'function') {
          this.setState({ formError: 'Sorry, an error occurred, please try again.' });
        }
        if (typeof toggleModal === 'function') {
          toggleModal();
        }
        this.setState({
          campaigns: null,
          loading: false,
          loaded: true,
        });
      });
  }

  toggle = () => {
    const scrollYOffset = window.pageYOffset;

    const { toggleModal } = this.props;
    if (typeof toggleModal === 'function') {
      toggleModal();
    }

    // make sure we scroll back to where we were on the page
    let x = 0;
    const refreshIntervalId = setInterval(() => {
      x += 1;
      if (x > 20) clearInterval(refreshIntervalId);
      const newScrollYOffset = window.pageYOffset;
      if (newScrollYOffset !== scrollYOffset) {
        window.scrollTo({ top: scrollYOffset, behavior: 'auto' });
        clearInterval(refreshIntervalId);
      }
    }, 50);
  };

  toggleTabs = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        formError: null,
      });
    }
  };

  addRecipientToCampaign = (fields, key) => {
    const { campaigns } = fields;
    const { templates } = campaigns[key];
    const { investorContactInfo } = this.props;

    const emails = [];
    if (typeof templates !== typeof undefined) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < templates.length; i++) {
        emails[`emails[${i}][title]`] = templates[i].title;
        emails[`emails[${i}][content]`] = templates[i].content;
        emails[`emails[${i}][position]`] = templates[i].position;
      }
    }

    axios.post('/campaigns/emails/add', queryString.stringify({
      campaignId: campaigns[key].id,
      contactId: investorContactInfo.contactId,
      investorId: investorContactInfo.investorId,
      ...emails,
    }))
      .then((response) => {
        if (response.data && response.data.success) {
          this.emailIsSent(campaigns[key].time);
        } else {
          this.setState({ formError: 'Sorry, an error occurred, please try again.' });
        }
      })
      .catch((error) => {
        if (error && error.response) {
          this.setState({ formError: error.response.data.message });
        } else {
          this.setState({ formError: 'Sorry, an error occurred, please try again.' });
        }
      });
  }

  sendPlainEmail = (title, content) => {
    const { investorContactInfo } = this.props;

    axios.post('/investors-db/email/send', queryString.stringify({
      title,
      content,
      contactId: investorContactInfo.contactId,
    }))
      .then((response) => {
        if (response.data && response.data.success) {
          this.emailIsSent(null);
        } else {
          this.setState({ formError: 'Sorry, an error occurred, please try again.' });
        }
      })
      .catch((error) => {
        if (error && error.response) {
          this.setState({ formError: error.response.data.message });
        } else {
          this.setState({ formError: 'Sorry, an error occurred, please try again.' });
        }
      });
  }

  emailIsSent = (time) => {
    const {
      showNotifSuccess, investorContactInfo, updateListOfInvestors, showNotifWarning, dispatch, user,
    } = this.props;

    this.toggle();
    this.updateUserInfo();
    updateListOfInvestors(investorContactInfo.investorId, true);
    dispatch(updateInvestorsAdded(true));

    if (user && !user.appSubmitted) {
      showNotifWarning('You email will be sent once you finish completing your profile. '
        + 'Go to <strong><u>My Company</u></strong> to complete your application.', '/myProfile/company');
    } else if (!user.isCustomer && !user.isPayingCustomer) {
      showNotifWarning('To start contacting investors, you must subscribe to one of our plans. '
        + '<strong><u>Select a Plan</u></strong> to get started.', '/ext/pricing');
    } else if (user.isCustomer && !user.isPayingCustomer) {
      showNotifWarning('Your campaign will start sending emails once your trial is over and your paid plan started.');
    } else if (!user.mailboxConnected && user.isPayingCustomer) {
      showNotifWarning('You must connect your mailbox to Angels Partners in order to contact investors. '
        + '<strong><u>Go to settings</u></strong> to get started.', '/myProfile/settings');
      setTimeout(() => {
        showNotifSuccess(`${investorContactInfo.fullName} has been successfully added to your campaign.
            An email will be sent to this investor as soon as you connect your mailbox to the platform.`);
      }, 3000);
    } else if (user.pings <= 0 && !user.isCustomer && user.countSend > 0 && user.mailboxConnected) {
      showNotifWarning(
        'To start emailing investors, you need to subscribe to one of our subscription plan. '
        + '<strong><u>Upgrade Now!</u></strong>',
        '/ext/pricing',
      );
      setTimeout(() => {
        showNotifSuccess(`${investorContactInfo.fullName} has been successfully added to your campaign.
            An email will be sent to this investor as soon as you subscribe ot one of our plan.`);
      }, 3000);
    } else if (user.isCustomer && !user.isPayingCustomer) {
      this.showNotifWarning(
        'Your Campaigns Are On Pause',
        'You cannot contact investors until your trial ends. '
        + 'To start your campaign(s), you need to end your trial now, or wait until it ends.',
        'ext/payment/process',
      );
      setTimeout(() => {
        showNotifSuccess(`${investorContactInfo.fullName} has been successfully added to your campaign.
            An email will be sent to this investor as soon as your trial is over.`);
      }, 3000);
    } else if (user.pings <= 0 && user.isCustomer && user.countSend > 0 && user.mailboxConnected) {
      showNotifWarning('You have no pings left. '
        + 'You should refill now if you want to resume your campaigns. '
        + 'All your campaigns are automatically paused at the moment and no emails will be sent. '
        + '<strong><u>Buy more pings</u></strong>', '/ext/pricing');
      setTimeout(() => {
        showNotifSuccess(`${investorContactInfo.fullName} has been successfully added to your campaign.
            An email will be sent to this investor as soon as you refill your accounts with more pings.`);
      }, 3000);
    } else if (user.pings < user.countSend && user.isCustomer && user.mailboxConnected) {
      showNotifWarning('The number of messages to send is larger than the number of pings left on your account. '
        + 'Once your pings credit goes to 0, you will not be able to send automated messages through the platform '
        + 'anymore. <strong><u>Buy more pings.</u></strong>', '/ext/pricing');
      setTimeout(() => {
        showNotifSuccess(`${investorContactInfo.fullName} has been successfully added to your campaign.
            An email will be sent to this investor as soon as you refill your accounts with more pings.`);
      }, 3000);
    } else {
      showNotifSuccess(`${investorContactInfo.fullName} has been successfully added to your campaign.
            An email will be sent to this investor ${time ? `at ${time} UTC` : 'shortly'}`);
    }
  }

  updateUserInfo = () => {
    // Set up Loading
    const { props } = this;
    const user = { ...props.user };

    user.investorsAdded = true;
    user.countSend += 1;

    // Update the State
    localStorage.setItem('user', JSON.stringify(user));

    return true;
  };

  toggleChoiceMade = (chooseTemplates) => {
    const { campaigns } = this.state;
    const { history } = this.props;
    const { investorContactInfo } = this.props;
    const { contactId, investorId } = investorContactInfo;

    if (chooseTemplates && campaigns === null) {
      history.push({
        pathname: '/campaigns/create',
        state: {
          contactId,
          investorId,
          redirect: true,
          keywords: this.getQueryVariable('keywords'),
          location: this.getQueryVariable('location'),
          industry: this.getQueryVariable('industry'),
          isFirm: this.getQueryVariable('isFirm'),
          filters: this.getQueryVariable('filters'),
          pageOfItems: this.getQueryVariable('page'),
        },
      });
    } else {
      this.setState({ choiceMade: true, chooseTemplates });
    }
  }

  getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) { return pair[1]; }
    }
    return false;
  }

  render() {
    const {
      modal, activeTab, campaigns, loaded, loading, choiceMade, chooseTemplates, formError,
    } = this.state;
    const { investorContactInfo, user } = this.props;
    const width = window.innerWidth;

    const campaignsTitles = Array.isArray(campaigns) ? campaigns.map((c, i) => (
      <NavItem key={c.id}>
        <NavLink
          /* eslint-disable-next-line eqeqeq */
          className={classnames({ active: activeTab === i })}
          onClick={() => {
            this.toggleTabs(i);
          }}
        >
          {c.name.length > 35 ? `${c.name.slice(0, 35)}...` : c.name}
        </NavLink>
      </NavItem>
    )) : null;

    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        className="modal-dialog--primary xx-large modal-campaigns"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <img
            src={
              investorContactInfo && investorContactInfo.picture
                ? process.env.REACT_APP_AWS_ROOT
                + process.env.REACT_APP_AWS_INVESTORS_PATH
                + investorContactInfo.picture
                : userIcon
            }
            onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
            className="modal__investor-pic"
            alt="investor"
          />
          <h4 className="modal__title">
            Contact {investorContactInfo ? investorContactInfo.fullName : 'this investor'}
          </h4>
          <img src={`${process.env.PUBLIC_URL}/img/linePurple.svg`} className="line" alt="line" />
        </div>
        {investorContactInfo && !investorContactInfo.email
          ? (
            <Alert color="warning" className="alert--colored mt-5 mb-2">
              <p className="text-left">
                <span className="bold-text">This investor email is unfortunately not available yet!</span><br />
                Come back later as we are constantly updating our database.
                You can always copy and paste the templates from your campaigns if you wish to contact this investor
                outside of Angels Partners.
              </p>
            </Alert>
          ) : (
            <div className="modal__tabs">
              {!loaded
              && (
                <div className={`load${loading ? '' : ' loaded'} inload`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              )}
              {loaded
              && (
                <div>
                  {!choiceMade && (
                    <SelectBoxes
                      onSelected={choice => this.toggleChoiceMade(choice)}
                      select={user.campaigns}
                    />
                  )}
                  {choiceMade && chooseTemplates === true
                  && (
                    <div
                      className={`tabs ${width >= 800 ? 'tabs--vertical' : 'tabs--horizontal tabs--bordered-bottom'}`}
                    >
                      <div className="tabs__wrap">
                        {campaigns
                        && (
                          <Nav tabs>
                            {campaignsTitles}
                          </Nav>
                        )}
                        <ModalCampaignsForm
                          user={user}
                          investorContactInfo={investorContactInfo}
                          initialValues={{ campaigns }}
                          campaigns={campaigns}
                          activeTab={activeTab}
                          onSubmit={fields => this.addRecipientToCampaign(fields, activeTab)}
                          formError={formError}
                        />
                      </div>
                    </div>
                  )}
                  {choiceMade && chooseTemplates === false
                  && (
                    <BlankEmail
                      user={user}
                      investorContactInfo={investorContactInfo}
                      onSubmit={fields => this.sendPlainEmail(fields.title, fields.content)}
                      formError={formError}
                    />
                  )}
                </div>
              )}
            </div>
          )}
      </Modal>
    );
  }
}

export default withRouter(connect(null)(ModalSingleInvestorCampaign));
