import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PauseIcon from 'mdi-react/PauseIcon';
import PlayIcon from 'mdi-react/PlayIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import { Button, UncontrolledTooltip } from 'reactstrap';
import ArchiveIcon from 'mdi-react/ArchiveIcon';

class CampaignElement extends PureComponent {
  static propTypes = {
    campaign: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      paused: PropTypes.bool,
      archived: PropTypes.bool,
      draft: PropTypes.bool,
      emails_sent: PropTypes.number,
      emails_total: PropTypes.number,
      visit_rate: PropTypes.number,
      open_rate: PropTypes.number,
    }).isRequired,
    pause: PropTypes.func.isRequired,
    archive: PropTypes.func.isRequired,
    infoLoaded: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const {
      campaign, pause, infoLoaded, archive, loading,
    } = this.props;

    return (
      <tr className={campaign.draft ? 'draft-campaign' : ''}>
        <td>
          <Link to={campaign.draft ? `/campaigns/edit/${campaign.id}` : `/?campaign=${campaign.name}`}>
            <h4 className={campaign.draft ? 'draft-campaign' : ''}><u>{campaign.name}</u></h4>
            {campaign.paused && !campaign.draft && <span className="paused">This campaign is on pause</span>}
            {campaign.draft && <span className="paused">This campaign is a draft</span>}
          </Link>
        </td>
        {!infoLoaded || loading
          ? (
            <td colSpan={3} className="text-center">
              {/* <td colSpan={4} className="text-center"> */}
              <svg className="load__icon">
                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </td>
          )
          : (
            <>
              <td>
                <strong>{campaign.emails_sent}</strong><small>/{campaign.emails_total}</small>
              </td>
              {/* <td> */}
              {/*  <strong>{campaign.completion_rate}</strong>% */}
              {/* </td> */}
              <td className="d-none d-sm-table-cell">
                <strong>{campaign.open_rate}</strong>
              </td>
              <td className="d-none d-md-table-cell">
                <strong>{campaign.visit_rate}</strong>
              </td>
            </>
          )
        }
        <td>
          <Link to={`/campaigns/edit/${campaign.id}`}>
            <Button className="rounded icon-only btn-purple mb-0 mt-n2 mr-0" id={`edit-${campaign.id}`}>
              <EditIcon className="cursor" />
            </Button>
          </Link>
          <UncontrolledTooltip dir="left" placement="bottom" target={`edit-${campaign.id}`}>
            Edit this campaign
          </UncontrolledTooltip>
        </td>
        <td>
          <Link to="/investors-db">
            <Button className="rounded icon-only btn-primary mb-0 mt-n2 mr-0" id={`recipients-${campaign.id}`}>
              <AccountPlusIcon className="cursor" />
            </Button>
          </Link>
          <UncontrolledTooltip dir="left" placement="bottom" target={`recipients-${campaign.id}`}>
            Add recipients to your campaign
          </UncontrolledTooltip>
        </td>
        <td>
          <div className={campaign.archived ? 'hidden' : ''}>
            <Button
              className="rounded icon-only btn-danger mb-0 mt-n2 mr-0"
              id={`archive-${campaign.id}`}
              onClick={archive}
            >
              <ArchiveIcon className="cursor" />
            </Button>
            <UncontrolledTooltip dir="left" placement="bottom" target={`archive-${campaign.id}`}>
              Archive this campaign
            </UncontrolledTooltip>
          </div>
          <div className={!campaign.archived ? 'hidden' : null}>
            <Button
              className="rounded icon-only btn-danger mb-0 mt-n2 mr-0"
              id={`reactivate-${campaign.id}`}
              onClick={archive}
            >
              <ArchiveIcon className="cursor" />
            </Button>
            <UncontrolledTooltip dir="left" placement="bottom" target={`reactivate-${campaign.id}`}>
              Reactivate this campaign
            </UncontrolledTooltip>
          </div>
        </td>
        <td>
          <div className={!campaign.paused ? 'hidden' : null}>
            <PlayIcon className="cursor play-icon mt-n2" onClick={pause} id={`pause-${campaign.id}`} />
            <UncontrolledTooltip dir="left" placement="bottom" target={`pause-${campaign.id}`}>
              Resume this campaign
            </UncontrolledTooltip>
          </div>
          <div className={campaign.paused ? 'hidden' : null}>
            <PauseIcon
              className="cursor play-icon mt-n2"
              onClick={pause}
              id={`unpause-${campaign.id}`}
            />
            <UncontrolledTooltip dir="left" placement="bottom" target={`unpause-${campaign.id}`}>
              Pause this campaign
            </UncontrolledTooltip>
          </div>
        </td>
      </tr>
    );
  }
}


export default CampaignElement;
