import React, { Component } from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import ModalDeckReview from '../modals/ModalDeckReview';

const rocket = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/tracking.svg`;

class CardDeckReview extends Component {
  state = {
    modal: false,
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  render() {
    const { modal } = this.state;
    return (
      <Col md={6} xl={4} sm={12}>
        <Card>
          <CardBody className="dashboard-card dashboard-card--warning" onClick={this.toggleModal}>
            <div className="dashboard-card__body">
              <img className="round dashboard-card__img-partnerships" src={rocket} alt="" />
              <h3 className="dashboard-card__plan mt-4">Deck Review</h3>
              <hr />
              <p className="dashboard-card__title">Have your decked reviewed by professionals</p>
              <div className="dashboard-card__desc top-line text-justify mb-5">
                <p>
                  Your deck will be reviewed by seasoned entrepreneurs, cumulating numbers of exits and IPOs, to give
                  you an honest review of your deck.
                </p>
                <br />
                <br /><br /><br />
              </div>
              <Button className="dashboard-card__button" color="primary">Learn More</Button>
            </div>
          </CardBody>
        </Card>
        <ModalDeckReview
          displayed={modal}
          toggleModal={this.toggleModal}
        />
      </Col>
    );
  }
}

export default CardDeckReview;
