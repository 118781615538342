/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button, Col, Row, Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CardTitle from 'reactstrap/lib/CardTitle';
import PlusIcon from 'mdi-react/PlusIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Alert from '../../../shared/components/alerts/Alert';


function RaisingTab(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <Row>
        <Col xs={12}>
          <CardTitle>
            <Row>
              <Col xs={12} lg={8}>
                <h3>Maximize your chances of triggering investor interest!</h3>
                <p className="text-muted mt-4">
                  First and foremost, your pitch should allow you to tell a story to your audience.<br />
                  Study the pitches of startups that inspire you: we recommend that you take inspiration from other
                  startups&apos; pitches and not hesitate to ask them for advice on how they have structured their
                  pitch.
                </p>
                <p className="text-muted">
                  It is not necessary to reinvent the wheel.
                </p>
                <p className="text-muted mb-2">
                  Read a lot of blogs (we have a tons of information on our blog as well), join the forums and facebook
                  groups for entrepreneurs, watch videos on the topic and follow entrepreneur-related content on
                  twitter.
                </p>
              </Col>
            </Row>
            <Alert color="purple" className="alert--colored mt-5">
              <div>
                <p>
                  <span className="bold-text">Want to learn more? </span>
                  Read our blog to learn more about
                  <br />
                  <br />
                  <a className="text-white" href="https://angelspartners.com/blog/startup-fundraising">
                    <strong>What an entrepreneur should know when raising funds <ChevronRightIcon /></strong>
                  </a>
                  <br />
                  <a className="text-white" href="https://angelspartners.com/blog/investors-pitch-deck">
                    <strong>How to perfectly pitch your startup and raise more funds <ChevronRightIcon /></strong>
                  </a>
                  <br />
                  <a
                    className="text-white"
                    href="https://angelspartners.com/blog/how-to-raise-funds-for-your-next-start-up"
                  >
                    <strong>How to raise funds for your next start-up <ChevronRightIcon /></strong>
                  </a>
                </p>
              </div>
            </Alert>
          </CardTitle>
          <Table responsive hover>
            <tbody>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1v0I0D_GiadyRdmpmW5cbI7KOQBDNjE803P6VTMswBQ0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Cap Table Builder
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  The capitalization table shows each investors&#39; equity capital stake in the business, which is
                  calculated by multiplying the share price by the number of shares owned, for example, when they sell
                  new shares, issue new shares, increase the option pool, or grant options to an employee. This template
                  lets you track all the changes in your cap table.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1v0I0D_GiadyRdmpmW5cbI7KOQBDNjE803P6VTMswBQ0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/10nBL_29hu-SJXwRtaw_81KFoEOMSUE80/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Elevator Pitch Template for Startups
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A good elevator pitch should last no longer than a short elevator ride of 20 to 30 seconds, hence the
                  name. They should be interesting, memorable, and succinct. They also need to explain what makes you
                  unique. Here is a template to get you started.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/10nBL_29hu-SJXwRtaw_81KFoEOMSUE80/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button className="btn rounded icon btn-slides mt-4">
                      Open Slides
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1l4RLBB9q7dN8DqdiPvPAWBq2MXzoDrQO/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Executive Summary Template
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A good executive summary grabs your reader’s attention and lets them know what it is you do and
                  why they should read the rest of your business plan or proposal.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1l4RLBB9q7dN8DqdiPvPAWBq2MXzoDrQO/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1bGLqzZePARndDr67HNyh0X4kW5V0fijCPyN3U8ZvZa4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Explanation of Terms by Fenwick
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Glossary of all the common terms used in fundraising.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1bGLqzZePARndDr67HNyh0X4kW5V0fijCPyN3U8ZvZa4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1CnAAmOlKH541ZmVuyPa9qgaO-vcAAyMQ/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Investor Pitch Deck Template from New Haircut 2
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Here is a pitch deck template that you can use to nail all the key elements required and craft a
                  compelling story about your business.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1CnAAmOlKH541ZmVuyPa9qgaO-vcAAyMQ/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button className="btn rounded icon btn-slides mt-4">
                      Open Slides
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1r3d-duTNU8-5DKHsmzVJD_GoafCusDqA/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Pitching Hacks from VentureHacks (AngelList)
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Investors don&apos;t invest in businesses. They invest in stories about businesses. Here are useful
                  pitching tips from VentureHacks to polish up a nice story.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1r3d-duTNU8-5DKHsmzVJD_GoafCusDqA/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/12B0I3XCWKy75wnzqzN91YmbQiDVOJSDM/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    SAFTE (Simple Agreement for Future Tokens) Term Sheet Template
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A Simple Agreement for Future Tokens (SAFT) is an agreement for the eventual transfer of tokens from
                  cryptocurrency developers to investors. A Simple Agreement for Future Equity (SAFE) allows startup
                  investors to convert their cash investment into equity at a point in the future. This template covers
                  both options.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/12B0I3XCWKy75wnzqzN91YmbQiDVOJSDM/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1VHN-jXuByrweSz6rIHOXRk6hzaKA74vA/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Sample Pitch Deck
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A template of a pitch deck for your reference.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1VHN-jXuByrweSz6rIHOXRk6hzaKA74vA/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button className="btn rounded icon btn-slides mt-4">
                      Open Slides
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1o6P5qC-kun4EYb9nsQbGJtgSYmLH9jTICGjHbY7M96E/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Sample VC Due Diligence Request List
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  One of the most common documents investors will ask for when performing a deeper look at a startup is
                  a due diligence. Here is a Due Diligence Request List highlighting the most frequently asked material
                  and questions.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1o6P5qC-kun4EYb9nsQbGJtgSYmLH9jTICGjHbY7M96E/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1D9cqtov7RKAUJMf8YSkHdQqpWnVe-I-Ymls-Q1QjlY4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Series Seed Term Sheet
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  The seed funding round is when investors, usually angel investors, provide funds before a startup
                  becomes operational. Here is a template of a legal document and the principal terms used by investors
                  with respect to a proposed Series Seed Preferred Stock of a company. (This is not legal advice.
                  For informational purposes only.)

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1D9cqtov7RKAUJMf8YSkHdQqpWnVe-I-Ymls-Q1QjlY4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1p0UzgOS7YWRUwcWXgdFbcL_-9tpeecQ1CWvAEh6zJj0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    TechStars Convertible Note Term Sheet
                  </a>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  A convertible note is short-term debt that converts into equity. In the context of a seed financing,
                  the debt automatically converts into shares of preferred stock upon the closing of a Series
                  A round of financing. Here is a template documenting the key legal terms.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1p0UzgOS7YWRUwcWXgdFbcL_-9tpeecQ1CWvAEh6zJj0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1Qyg2VzBVUR7J6hNotKF1dCxEvS2RAPiD0-b2E7YgHaM/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    VC Dilution Scenario Analyzer
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Dilution in startups is the decrease in ownership for existing shareholders that occurs when a company
                  issues new shares. So dilution decreases your ownership stake in your startup. This document lets you
                  build a dilution scenario.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1Qyg2VzBVUR7J6hNotKF1dCxEvS2RAPiD0-b2E7YgHaM/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1_wS2nwIovGe3oZAjjLrwpBeQA4PembhLoOw8BKdL0Ik/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    YCombinator SAFE term sheet with cap and discount
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A Simple Agreement for Future Equity is an agreement for the eventual transfer of shares to investors.
                  A Simple Agreement for Future Equity (SAFE) allows startup investors to convert their cash investment
                  into equity at a point in the future. This template covers both options.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1_wS2nwIovGe3oZAjjLrwpBeQA4PembhLoOw8BKdL0Ik/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1wTLqEJXy7KzcsYSEEENm96FDjVS7GH0VqWCGi8Gaysw/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Ycombinator Series AA Term Sheet
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A Simple Agreement for Future Equity is an agreement for the eventual transfer of shares to investors.
                  A Simple Agreement for Future Equity (SAFE) allows startup investors to convert their cash investment
                  into equity at a point in the future. This template covers both options for Series A.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1wTLqEJXy7KzcsYSEEENm96FDjVS7GH0VqWCGi8Gaysw/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

RaisingTab.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default RaisingTab;
