import React from 'react';
import {
  Badge, Card, CardBody,
} from 'reactstrap';

const PanelTrackingLink = () => (
  <Card className="panel panel--divider card shadow mb-3">
    <CardBody className="panel__body">
      <div className="panel__title no-padding">
        <h5 className="bold-text">
          <Badge className="panel__label">Feature</Badge>
          &nbsp;&nbsp;&nbsp;
          Use a tracking link to gauge investors&#39; interest.
        </h5>
        {/* <h5 className="subhead">{subhead}</h5> */}
      </div>
      <div className="panel__content">
        <p>
          <strong><u>Did you know that Angels Partners developed our own document sharing feature?</u></strong>
        </p>
        <p className="typography-message">
          Simply use the variable <strong>&#123;&#123;trackingLink&#125;&#125;</strong>&nbsp;
          and our system will automatically generate a unique link for each investor. These tracking links provide you
          with accurate data on who visited your profile, helping you gauge an investor&#39;s interest.
        </p>
        <p>
          We strongly recommend that you use our integrated document sharing feature, in order to get the best out of
          Angels Partners.
        </p>
      </div>
    </CardBody>
  </Card>
);

export default PanelTrackingLink;
