import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import LinkedinIcon from 'mdi-react/LinkedinIcon';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const ConnectionCard = ({
  connection, isSelectedConnection, selectConnection, connectionToContact,
}) => (
  <Button
    className={`icon icon--right icon--blue
              ${isSelectedConnection && 'btn-secondary'}`}
    outline
    onClick={() => selectConnection(connection)}
    onKeyDown={() => selectConnection(connection)}
    disabled={connection.contacted}
  >
    <img
      src={
        connection.picture
          ? process.env.REACT_APP_AWS_ROOT
          + process.env.REACT_APP_AWS_LINKEDININTRO_PATH
          + connection.picture.linkedinIntroPictureName
          : userIcon
      }
      className={`profile ${isSelectedConnection && 'selected'}`}
      alt="connection"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = userIcon;
      }}
    />
    {connectionToContact
    && <img className="tick" src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/blueTick.png`} alt="tick" />}
    <h5>
      {connection && connection.name ? connection.name : ''}
    </h5>
    {connection.contacted
    && <span className="text-muted"><small>CONTACTED</small></span>}
    <a href={connection.linkedinUrl} target="_blank" rel="noopener noreferrer">
      <LinkedinIcon />
    </a>
  </Button>
);

ConnectionCard.propTypes = {
  connection: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    linkedinUrl: PropTypes.string.isRequired,
    picture: PropTypes.shape({
      id: PropTypes.number.isRequired,
      linkedinIntroPictureName: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isSelectedConnection: PropTypes.bool.isRequired,
  selectConnection: PropTypes.func.isRequired,
  connectionToContact: PropTypes.bool.isRequired,
};

export default ConnectionCard;
