/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (values.linkToMaterials && !isValidUrl(values.linkToMaterials)) {
    errors.linkToMaterials = 'Please enter a valid URL.';
  }

  return errors;
};

const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

export default validate;
