import React from 'react';
import {
  Badge, Card, CardBody,
} from 'reactstrap';

const PanelMessageLength = () => (
  <Card className="panel panel--divider card shadow mb-3">
    <CardBody className="panel__body">
      <div className="panel__title no-padding">
        <h5 className="bold-text">
          <Badge className="panel__label">Improvement</Badge>
          &nbsp;&nbsp;&nbsp;
          Reduce the length of your templates
        </h5>
      </div>
      <div className="panel__content">
        <p className="typography-message">
          You should keep your templates short, below 2000 characters.
          Having analysed thousands of campaigns, our research demonstrated that templates using shorter messages
          <strong>perform 35% better</strong> than campaigns with longer ones.
          Long messages also impact your deliverability rate.
        </p>
      </div>
    </CardBody>
  </Card>
);

export default PanelMessageLength;
