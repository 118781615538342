import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';

class ModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    displayed: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
    askEmail: PropTypes.bool,
    wrongPassword: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
  };

  static defaultProps = {
    wrongPassword: false,
    askEmail: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      notAnEmail: false,
      message: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.triggerSubmit = this.triggerSubmit.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  triggerSubmit = () => {
    const { submit, askEmail } = this.props;
    const { message } = this.state;

    if (askEmail && !this.validateEmail(message)) {
      this.setState({ notAnEmail: true });
    } else {
      this.setState({ notAnEmail: false });
      submit(message);
    }
  };

  validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleChange(event) {
    this.setState({ message: event.target.value });
  }

  render() {
    const { title, wrongPassword, placeholder } = this.props;
    const { modal, message, notAnEmail } = this.state;

    return (
      <div>
        <Modal
          isOpen={modal}
          modalClassName="ltr-support"
          className="modal-dialog--primary modal-templates summary-modal x-large"
        >
          <div className="modal__header">
            <h4>{title}</h4>
            <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
          </div>
          <form
            className="material-form"
            onSubmit={(e) => {
              e.preventDefault();
              this.triggerSubmit();
            }}
          >
            <div className="modal__body">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <input
                    name="message"
                    className="w-100 p-2"
                    style={{ height: '40px' }}
                    placeholder={placeholder}
                    onChange={this.handleChange.bind(this)}
                    value={message}
                  />
                </div>
                {wrongPassword ? (
                  <div className="text-red">This password is invalid.</div>
                ) : ''}
                {notAnEmail ? (
                  <div className="text-red">Please enter a valid email address.</div>
                ) : ''}
              </div>
            </div>
          </form>
          <ButtonToolbar className="modal__footer float-right">
            <Button
              className="modal_ok rounded icon icon--right icon--blue"
              type="button"
              color="primary"
              onClick={() => this.triggerSubmit()}
            >
              Send!
              <SendIcon />
            </Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default ModalComponent;
