import React, { PureComponent } from 'react';
import PDFWrapper from '../../../shared/components/pdfViewer/pdfWrapper';


class StartupFinancialsPage extends PureComponent {
  render() {
    const { props } = this;
    const { id } = props.match.params;

    return (
      <PDFWrapper
        baseDocUrl={process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_FINANCIALS_PATH}
        urlRetrieveDoc={`startup/financials/${id}`}
        urlTrackAction={`startup/profile/track/financials/${id}`}
        urlTrackTime={`startup/profile/track/timeFinancials/${id}`}
      />
    );
  }
}

export default StartupFinancialsPage;
