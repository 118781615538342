import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ToggleContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

const ToggleBackground = styled.div`
  position: absolute;
  left: 0;
  background: #EEEEEE;
  border-radius: 50px;
  width: 64px;
  height: 33px;
  border: none;
  transition: 0.3s;
`;

const ToggleIcon = styled.div`
  z-index: 99;
  position: absolute;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  background-color: #FFFFFF;
  transition: 0.3s;
  &.active {
    left: 33px;
    background-color: #778DE5;
  }
  box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
`;

class ToggleSwitch extends Component {
  state = {
    active: false,
  }

  componentDidMount() {
    const { initialValue } = this.props;
    this.setState({ active: initialValue });
  }

  toggle = () => {
    const { active } = this.state;
    const { toggleHandler } = this.props;

    if (toggleHandler(!active) === true) {
      this.setState({ active: !active });
    }
  };

  render() {
    const { active } = this.state;

    return (
      <ToggleContainer onClick={this.toggle}>
        <ToggleIcon className={active ? 'active' : ''} />
        <ToggleBackground className={active ? 'active' : ''} />
      </ToggleContainer>
    );
  }
}

ToggleSwitch.propTypes = {
  toggleHandler: PropTypes.func.isRequired,
  initialValue: PropTypes.bool.isRequired,
};

export default ToggleSwitch;
