import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ChevronRight from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';

class WizardFormTwo extends PureComponent {
  componentDidMount() {
    // window.open('https://admin.google.com/', '_blank');
  }

  render = () => {
    const { previousPage, handleSubmit } = this.props;

    return (
      <form className="form wizard__form wizard__form__whitelist wizard__form__step-two" onSubmit={handleSubmit}>
        <div className="wizard__form__header">
          <h3 className="wizard__title">Connect to your mailbox</h3>
          <p className="wizard__subtitle">
            You will need to log in to your Google workspace (previously known as Google Suite) administrator account.
            This person is the only person who can whitelist Angels Partners for your organization.
            If the super administrator whitelists the application, anyone at your company will be able to sync his/her
            account into the application if needs be.
          </p>
        </div>
        <div className="form__form-group bottom-0 mt-5 wizard__whitelist">
          <h4 className="m-3"><span className="bullet">1</span>Sign in to your Google Admin Console:&nbsp;
            <a href="https://admin.google.com/" target="_blank" rel="noopener noreferrer">admin.google.com</a>&nbsp;
            from the Google Admin dashboard, go to “Security” and open up “Settings”.
          </h4>
          <img
            src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/whitelisting/step1.png`}
            className="whitelisting__steps mt-3"
            alt="White Listing process step 1"
          />
          <hr className="m-5" />
          <h4 className="m-3"><span className="bullet">2</span>Then, scroll down and click on “API Controls”</h4>
          <img
            src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/whitelisting/step2.png`}
            className="whitelisting__steps mt-3"
            alt="White Listing process step 2"
          />
          <hr className="m-5" />
          <h4 className="m-3"><span className="bullet">3</span>Click on “Manage Third-Party App Access”.</h4>
          <img
            src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/whitelisting/step3.png`}
            className="whitelisting__steps mt-3"
            alt="White Listing process step 3"
          />

          <ButtonToolbar className="form__button-toolbar wizard__toolbar d-flex flex-row align-items-center">
            <Button
              color="primary"
              type="button"
              className="previous btn icon rounded float-left"
              onClick={previousPage}
            >
              <p>
                <ChevronLeftIcon />
                BACK
              </p>
            </Button>
            <button
              type="submit"
              color="primary"
              className="next btn icon rounded icon--right icon--blue btn-outline-primary"
            >
              <p>
                Next
                <ChevronRight />
              </p>
            </button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

// eslint-disable-next-line no-class-assign
WizardFormTwo = connect(state => ({
  valuesForm: getFormValues('wizard')(state),
}))(WizardFormTwo);


export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
})(WizardFormTwo);
