/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter your full name';
  }
  if (!values.email) {
    errors.email = 'Email field should not be left empty';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(values.linkedin)) {
    errors.linkedin = "Invalid URL (should start with http://)";
  }

  if (!values.password) {
    errors.password = 'Password field should not be left empty';
  }
  if (!values.location_city) {
    errors.location_city = 'Please fill in your city of location';
  }
  if (!values.location_country) {
    errors.location_country = 'Please fill in your country of location';
  }

  if (values.new_password !== values.new_password_check) {
    errors.new_password = 'These two fields should be equal if you wish to change your password';
    errors.new_password_check = 'These two fields should be equal if you wish to change your password';
  }

  return errors;
};

export default validate;
