import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';

// Import the image uploader plugin and register it
import ImageUploader from 'quill-image-uploader';

ReactQuill.Quill.register('modules/imageUploader', ImageUploader);

const renderQuillCampaignField = ({
  // eslint-disable-next-line no-unused-vars
  input, meta: { error }, dispatched = false, campaignId, id, showWarningMessage,
}) => {
  const [touched, setTouched] = useState(false);
  const [saveData, setSaveData] = useState([]);
  const [templateId, setTemplateId] = useState(id);

  const handleImageUpload = file => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      axios.post('/campaigns/image/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        if (response) {
          const json = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
          if (!json || typeof json.location !== 'string') {
            reject(new Error('Invalid JSON'));
          }
          resolve(`${process.env.REACT_APP_AWS_ROOT}${process.env.REACT_APP_AWS_ATTACHMENTS_PATH}${json.location}`);
        }
      }).catch(() => {
        reject(new Error('Something went wrong, please try again.'));
      });
    } catch (uploadError) {
      reject(new Error('Something went wrong, please try again.'));
    }
  });

  const modules = useMemo(() => ({
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['link', 'image', 'video'],

      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],

      [{ size: ['small', false, 'large', 'huge'] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
    ],
    imageUploader: {
      upload: handleImageUpload,
    },
  }), []);

  // Sanitize the HTML input value before passing it to Quill
  const sanitizedValue = input.value || '<p><br></p>';

  const saveTemplateHandler = (data) => {
    if (data[0]) {
      const contentNoHtml = data[0].replace(/(<([^>]+)>)/ig, '');
      let title = document.getElementsByName(data[2].name.replace('content', 'title'))[0].value;
      const delay = document.getElementsByName(data[2].name.replace('content', 'delay'))[0]
        ? document.getElementsByName(data[2].name.replace('content', 'delay'))[0].value : 0;
      const varTemplates = [
        'investorFirstName',
        'investorLastName',
        'investorVertical',
        'investorCity',
        'investorFund',
        'startupName',
        'signature',
        'lastDeal',
        'trackingLink',
      ];

      varTemplates.forEach((varTemplate) => {
        if (data[0].toLowerCase().includes(varTemplate.toLowerCase())) {
          // eslint-disable-next-line no-param-reassign
          data[0] = data[0].replace(new RegExp(varTemplate, 'gi'), varTemplate);
        }
        if (title.toLowerCase().includes(varTemplate.toLowerCase())) {
          title = title.replace(new RegExp(varTemplate, 'gi'), varTemplate);
        }
      });

      if (contentNoHtml) {
        axios.post('campaigns/saveTemplate', {
          content: data[0],
          title,
          id: data[3] ? data[3] : templateId,
          delay,
          position: data[2].name.replace(/\D+/g, ''),
          campaign_id: data[1],
        }).then((response) => {
          if (response.data) {
            setTemplateId(response.data.templateId);
          }
        }).catch((response) => {
          if (response.data) {
            setTemplateId(response.data.templateId);
          }
        });
      }
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      saveTemplateHandler(saveData);
    }, 4000);

    return () => clearTimeout(delayDebounceFn);
  }, [saveData]);

  return (
    // eslint-disable-next-line max-len
    <div className="form__form-group-input-wrap campaign-quill-editor-wrapper">
      <ReactQuill
        value={sanitizedValue}
        onChange={(value) => {
          input.onChange(value);
          setSaveData([value, campaignId, input, id]);
          setTouched(true);
        }}
        onBlur={() => {
          input.onChange(sanitizedValue);
          setTouched(true);
        }}
        modules={modules}
        theme="snow"
        className="campaign-quill-editor"
      />
      {(dispatched || touched) && error && <span className="form__form-group-error error-large">{error}</span>}
    </div>
  );
};

renderQuillCampaignField.propTypes = {
  input: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  type: PropTypes.string,
  dispatched: PropTypes.bool,
  campaignId: PropTypes.number,
  showWarningMessage: PropTypes.func,
  id: PropTypes.number,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderQuillCampaignField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  dispatched: false,
  campaignId: 0,
  id: 0,
  showWarningMessage: null,
};

export default renderQuillCampaignField;
