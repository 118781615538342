import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import WizardForm from '../sharedComponents/WizardForm';
import {
  BasicNotification,
  FullWideNotification,
  LinkNotification,
} from '../../../shared/components/notifications/Notification';
import ModalMultiUpdateExtInvestors from '../../../shared/components/modals/ModalMultiUpdateExtInvestors';
import ModalConfirmation from '../../../shared/components/modals/ModalConfirmation';

let notificationTC = null;
const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 20,
    closable: true,
    style: { top: 0, left: 0 },
    className: `${position} ${rtl}-support`,
  });
};

// eslint-disable-next-line react/prefer-stateless-function
class EditCampaign extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    initialValues: null,
    editLoading: false,
    modalMultiInvestorsCampaigns: false,
    modalConfirmation: false,
    investorsContactInfo: [],
    id: null,
  };

  componentDidMount() {
    const { props } = this;
    const { id } = props.match.params;
    this.setState({ id: parseInt(id, 0) }, () => this.init());
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    if (notificationTC) {
      notificationTC.destroy();
    }
    dispatch(reset('wizard'));
  }

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  showNotifSuccess = message => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message={message}
    />,
    position: 'right-up',
  });

  showNotifWarning = (message, link) => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      link={link}
      message={message}
    />,
    position: 'right-up',
  });

  toggleModalMultiInvestorsCampaigns = () => {
    const { history } = this.props;
    const { modalMultiInvestorsCampaigns } = this.state;
    const { modalConfirmation } = this.state;

    if (modalConfirmation) this.toggleConfirmation();

    this.setState({ modalMultiInvestorsCampaigns: !modalMultiInvestorsCampaigns });
    if (modalMultiInvestorsCampaigns) {
      history.push('/campaigns');
    }
  };

  toggleConfirmation = () => {
    const { modalConfirmation } = this.state;

    this.setState({ modalConfirmation: !modalConfirmation });
  };

  moveOnToNextPage = () => {
    const { history } = this.props;
    history.push('/campaigns');
  };

  init = () => {
    const { id } = this.state;
    axios.get(`campaigns/${id}`)
      .then((response) => {
        if (response.data) {
          this.setState({
            initialValues: response.data,
            loading: false,
          });
          setTimeout(() => this.setState({ loaded: true }), 500);
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 402) {
          // Do something error.response.data.message ?
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
      });
  };

  onSubmit = (results) => {
    const { history } = this.props;
    const { id, editLoading } = this.state;
    let time;

    if (!editLoading) {
      this.setState({
        loaded: true,
        loading: true,
        editLoading: true,
      });

      const today = moment().format('YYYY-MM-DD');
      if (typeof results.time !== 'string') {
        time = moment.tz(`${today} ${results.time.format('HH:mm')}`,
          results.timezone
            ? results.timezone.value
            : 'UTC');
      } else {
        time = moment.tz(`${today} ${results.time}`, 'YYYY-MM-DD HH:mm:ssA',
          results.timezone
            ? results.timezone.value
            : 'UTC');
      }

      time = time.utc().format('HH:mm:ss');
      // Post the Ping.
      axios.post('/campaigns/editSchedule', queryString.stringify({
        id,
        time,
        emailWarmup: results.emailWarmup,
        datetime: results.datetime,
        timezoneValue: results.timezone.value,
        timezoneLabel: results.timezone.label,
        sendOnMonday: results.sendOnMonday,
        sendOnTuesday: results.sendOnTuesday,
        sendOnWednesday: results.sendOnWednesday,
        sendOnThursday: results.sendOnThursday,
        sendOnFriday: results.sendOnFriday,
        sendOnSaturday: results.sendOnSaturday,
        sendOnSunday: results.sendOnSunday,
      }))
        .then((response) => {
          this.setState({
            loading: false,
            editLoading: false,
          });
          setTimeout(() => this.setState({ loaded: true }), 500);

          if (response) {
            // prevent the message from being displayed again
            window.onbeforeunload = null;

            if (response.data.success && response.data.investorsContactInfo.length === 0) {
              history.push('/campaigns');
            } else if (response.data.success && response.data.investorsContactInfo.length > 0) {
              this.setState({
                investorsContactInfo: response.data.investorsContactInfo,
              }, () => {
                this.toggleConfirmation();
              });
            } else {
              this.showNotif('Sorry, an error occurred, please try again or contact support.');
            }
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            editLoading: false,
          });
          setTimeout(() => this.setState({ loaded: true }), 500);

          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
        });
    }
  }

  render = () => {
    const {
      initialValues,
      loaded,
      loading,
      editLoading,
      modalMultiInvestorsCampaigns,
      modalConfirmation,
      investorsContactInfo,
      id,
    } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title"><small>Edit your<br />outreach campaign</small></h3>
          </Col>
          <Col xs={12} md={9} xl={7}>
            <p className="page-description">
              <strong>{initialValues ? initialValues.name : null}</strong>
            </p>
          </Col>
        </Row>
        <Row>
          {!loaded
            ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            )
            : (
              <WizardForm
                loading={editLoading}
                initialValues={initialValues}
                onSubmit={results => this.onSubmit(results)}
              />
            )
          }
        </Row>
        <ModalMultiUpdateExtInvestors
          investorsContactInfo={investorsContactInfo}
          fundsContactInfo={[]}
          displayed={modalMultiInvestorsCampaigns}
          toggleModal={() => this.toggleModalMultiInvestorsCampaigns()}
          showNotif={message => this.showNotif(message)}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifWarning={(message, link) => this.showNotifWarning(message, link)}
          updateListOfInvestors={null}
          updateMultipleListOfInvestors={null}
          editCampaign={id}
          sendUpdate
        />
        <ModalConfirmation
          displayed={modalConfirmation}
          color="primary"
          confirm={() => this.toggleModalMultiInvestorsCampaigns()}
          cancel={() => this.moveOnToNextPage()}
          title="Edit Emails?"
          message="Do you want to review & edit the emails that have been previously scheduled in this campaign?"
        />
      </Container>
    );
  }
}

export default withRouter(connect(null)(EditCampaign));
