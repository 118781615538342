import React from 'react';
import {
  Button, ButtonToolbar, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import renderDropZoneField from '../../../shared/components/form/DropZone';

const CsvFileUpload = ({ getCsvFileHandler, handleSubmit, parsedData }) => (
  <Col
    xs={{ size: 10, offset: 1 }}
    md={{ size: 10, offset: 1 }}
    lg={{ size: 8, offset: 1 }}
    xl={{ size: 6, offset: 2 }}
    className="mt-5 p-0 p-md-1 p-lg-3 p-xl-4"
  >
    <div>
      <div className="card__title">
        <h5 className="bold-text">Upload CSV File</h5>
      </div>
      <form className="form csv-file-upload" onSubmit={handleSubmit}>
        <Field
          name="file"
          accept=".csv"
          component={renderDropZoneField}
          removable={false}
          onChange={getCsvFileHandler}
        />
      </form>
      <ButtonToolbar className={`form__button-toolbar m-auto ${parsedData.length > 0 ? 'hidden' : ''}`}>
        <Button
          className="rounded icon icon--right icon--blue mt-3 mr-auto ml-auto"
          color="primary"
          onClick={handleSubmit}
        >
          Upload CSV
          <ChevronRightIcon />
        </Button>
      </ButtonToolbar>
    </div>
  </Col>
);

CsvFileUpload.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  getCsvFileHandler: PropTypes.func.isRequired,
  parsedData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// export default CsvFileUpload;

export default reduxForm({
  form: 'import_file_form', // a unique identifier for this form
})(CsvFileUpload);
