import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const logo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/partnerships/trustVentureLogo.png`;

class ModalTrustVenture extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    displayed: false,
    toggleModal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-templates xx-large"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4>TrustVenture</h4>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
        </div>
        <div className="modal__body mt-5">
          <div className="section section-pad section-bg-btoa" id="bootcamp">
            <div className="container">

              <div className="row text-center">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 mb-5 mt-5">
                  <img className="w-100 mb-5 mt-5" alt="logo" src={logo} />
                </div>
              </div>

              <div className="col-md-10 offset-md-1 text-justify mb-4">
                <strong>
                  TrustVenture is a strategic partner for scaling high growth companies and advising investors in
                  transactions, financial execution, and valuation.
                </strong>
                <br />
                <br />
                <p>
                  Founded in 2020, TrustVenture has successfully completed more than 100 projects in 25 industries.
                  Along with 12+ due diligence and 30+ financial modelling projects, TrustVenture helped 16+ companies
                  to raise €550+ mm in investments. To foster introductions between founders and investors, TrustVenture
                  hosts networking events featuring pitching sessions as well as knowledge workshops and mentoring.
                </p>
                <br />
                <p>
                  Additionally, Trustventure team supports in creating reliable controlling and reporting processes and
                  other financial operations services from Investor relations to financial planning.
                </p>
              </div>
            </div>
          </div>

          <ButtonToolbar className="modal__footer float-right">
            <a
              href="https://trustventure.de/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className="modal_ok rounded icon icon--right icon--blue"
                color="primary"
                onClick={this.toggle}
              >
                Visit Website <ChevronRightIcon />
              </Button>
            </a>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default ModalTrustVenture;
