/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {
  Field, reduxForm,
} from 'redux-form';
import {
  Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import queryString from 'query-string';
import ToggleSwitch from '../../../shared/components/Toggle';
import validate from '../formValidate/formInfo';
import renderMultiSelectField from '../../../shared/components/form/MultiSelect';
import { BasicNotification, LinkNotification } from '../../../shared/components/notifications/Notification';
import Modal from '../../../shared/components/modals/Modal';

let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  notificationRU.notice({
    content: notification,
    duration: 30,
    closable: true,
    style: { top: 30, left: 'calc(100vw - 100%)' },
    className: `${position} ${rtl}-support`,
  });
};

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={(touched && (typeof error !== 'undefined' && error !== ''))}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
};

class InvestorInformation extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    isCustomer: PropTypes.bool.isRequired,
    questionsEnabled: PropTypes.bool.isRequired,
    categories: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      picture: PropTypes.string,
    }).isRequired,
    stages: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }).isRequired,
    countries: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalPaywall: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  showNotifWarning = (title, message, link) => showNotification({
    notification: <LinkNotification
      color="warning"
      title={title}
      link={link}
      message={message}
    />,
    position: 'right-up',
  });

  showNotifSuccess = () => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message="Your information have been updated successfully"
    />,
    position: 'right-up',
  });

  showErrorWarning = errorMessage => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      message={errorMessage}
    />,
    position: 'right-up',
  });

  onToggleQuestion = (value) => {
    const { isCustomer } = this.props;
    axios.post('/investors/question/activate', queryString.stringify({
      questionsEnabled: value,
    })).then((response) => {
      if (response.data && isCustomer) {
        this.showNotifSuccess();
      }
      return true;
    }).catch((error) => {
      this.showErrorWarning(error.response);
      return false;
    });
    if (!isCustomer) {
      this.setState({ modalPaywall: true });
      // this.showNotifWarning('Subscribe Now!',
      //   'You need to subscribe to our paid subscription to activate this feature.', '/ext/pricing/investor');
      return !value;
    }
    return true;
  }

  toggleModalUpgrade = () => {
    const { modalPaywall } = this.state;
    setTimeout(() => { this.setState({ modalPaywall: !modalPaywall }); }, 500);
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting,
      categories, stages, countries, questionsEnabled,
    } = this.props;
    const { modalPaywall } = this.state;

    const categoriesOptions = categories.map(category => ({ value: category.id, label: category.name }));
    const stagesOptions = stages.map(category => ({ value: category.id, label: category.name }));
    const countriesOptions = countries.map(category => ({ value: category.id, label: category.name }));

    return (
      <span>
        <form className="material-form" onSubmit={handleSubmit}>
          <div className="margin10">
            <span className="material-form__label">Which categories are you investing in?</span>
            <div className="form__form-group-field">
              <Field
                name="categories"
                component={renderMultiSelectField}
                options={categoriesOptions}
              />
            </div>
          </div>
          <div className="margin10">
            <span className="material-form__label">
              <h5>Select countries where you usually invest in</h5>
            </span>
            <Field
              name="investment_countries"
              component={renderMultiSelectField}
              options={countriesOptions}
            />
          </div>
          <div className="margin10">
            <span className="material-form__label"><h5>Which stages are you investing in?</h5></span>
            <Field
              name="stages"
              component={renderMultiSelectField}
              options={stagesOptions}
            />
          </div>
          <div className="margin10">
            <span className="material-form__label"><h5>Description</h5></span>
            <Field
              name="description"
              component="textarea"
              type="text"
            />
          </div>
          <div className="margin10">
            <span className="material-form__label"><h5>Expertise</h5></span>
            <Field
              name="expertise"
              component="textarea"
              type="text"
            />
          </div>
          <div>
            <Row>
              <Col md={6} lg={6} xs={12}>
                <span className="material-form__label"><h5>Investment Min</h5></span>
                <Field
                  name="investment_min"
                  component={renderTextField}
                  placeholder="What is the minimum you are looking to invest in a startup?"
                />
              </Col>
              <Col md={6} lg={6} xs={12}>
                <span className="material-form__label"><h5>Investment Max</h5></span>
                <Field
                  name="investment_max"
                  component={renderTextField}
                  placeholder="What is the maximum you are looking to invest in a startup?"
                />
              </Col>
            </Row>
          </div>
          <div className="margin10">
            <span className="material-form__label">
              <Row>
                <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                  <h5>
                    Ask a direct question to the founders.<br />
                    <small>This question will be displayed when someone try to contact you.</small>
                  </h5>
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                  <div className="mt-4">
                    <ToggleSwitch
                      onLabel="Activated"
                      offLabel="Activate"
                      toggleHandler={value => this.onToggleQuestion(value)}
                      initialValue={questionsEnabled}
                    />
                  </div>
                </Col>
              </Row>
            </span>
            <Field
              name="questions"
              component="textarea"
              type="text"
            />
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button className="rounded" color="primary" type="submit">Save Changes</Button>
            <Button className="rounded" type="button" onClick={reset} disabled={pristine || submitting}>
              Cancel
            </Button>
          </ButtonToolbar>
        </form>
        <Modal
          color="primary"
          title="Upgrade Required!"
          investor
          colored
          displayed={modalPaywall}
          toggleModal={() => this.toggleModalUpgrade()}
          message={`Please upgrade your account to a paid plan if you wish to activate this feature.
               You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing/investor' target="_blank">
               Pricing Page</a>.<br /><br />If you think this message is wrong, please reload the page and try again.`}
        />
      </span>
    );
  }
}


export default reduxForm({
  form: 'startup_information_form', // a unique identifier for this form
  validate,
})(InvestorInformation);
