import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Card } from '@material-ui/core';
import { CardBody, Col } from 'reactstrap';
import queryString from 'query-string';
import FounderCard from './FounderCard';
import CustPag from '../../../shared/components/pagination/Pagination';
import ModalInput from '../../../shared/components/modals/ModalInput';
import ModalNotActiveStartup from '../../../shared/components/modals/ModalNotActiveStartup';
import Modal from '../../../shared/components/modals/Modal';


class FoundersList extends Component {
  constructor(props) {
    super(props);
    const initialNumberOfFounders = 0;
    const initialRowsCount = 21;
    const initialPageNumber = 1;
    // eslint-disable-next-line react/prop-types
    const { location } = props;

    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page'), 10);

    this.state = {
      founders: [],
      pageOfItems: page && page > 1 ? page : initialPageNumber,
      itemsToShow: initialRowsCount,
      itemsCount: initialNumberOfFounders,
      showPagination: false,
      error: null,
      loading: true,
      loaded: false,
      modalPing: false,
      modal: false,
      modalNotActiveStartup: false,
      founderId: null,
      founderName: null,
    };
  }

  componentWillReceiveProps(newProps) {
    const { props } = this;
    // eslint-disable-next-line react/prop-types
    if (props.location.search !== newProps.location.search) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        props: newProps, founders: [], loading: true, loaded: false,
      }, () => this.getFounders(true));
    }
  }

  componentDidUpdate(prevProps) {
    const { stateOfList } = this.props;
    if (stateOfList !== prevProps.stateOfList) {
      this.updateListOfFounder(
        stateOfList.id,
        stateOfList.accepted,
        stateOfList.pinging,
        stateOfList.declining,
        stateOfList.declined,
        stateOfList.pinged,
      );
    }
  }

  componentDidMount = () => {
    this.getFounders(false);
  };

  getFounders = (scrollToTop) => {
    const { props } = this;
    const { history } = props;
    const { showNotif } = this.props;
    const { founders } = this.state;
    const query = this.getQuery(new URLSearchParams(props.location.search));

    if (founders.length === 0) {
      axios.get(`/founders/list?${query}`)
        .then((response) => {
          const newFounders = response.data.map(founder => ({
            ...founder, pinging: false, declining: false,
          }));
          // Remove loading screen
          this.setState({ founders: newFounders, loading: false });
          setTimeout(() => this.setState({ loaded: true }), 500);

          // Scroll to Top
          if (scrollToTop) window.scrollTo(0, 0);

          // Update url accordingly to the query
          history.push(`/founders/?${query}`);
        }).catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            showNotif(error.response.data.message);
            this.setState({ founders: [], error: error.response.data.message, loading: false });
          } else {
            showNotif('Sorry, an error occurred, please try again.');
            this.setState({ founders: [], error: 'Sorry, an error occurred, please try again.', loading: false });
          }

          // Remove loading screen
          setTimeout(() => this.setState({ loaded: true }), 500);
          if (scrollToTop) window.scrollTo(0, 0);
        });
    }
    this.getFoundersCount(query);
  };

  getFoundersCount = (query) => {
    axios.get(`founders/count?${query}`)
      .then((response) => {
        this.setState({ itemsCount: response.data, showPagination: true });
      });
  };

  getQuery = (query, pageOfItems) => {
    let queryUrl = '';

    if (pageOfItems) {
      queryUrl += `page=${pageOfItems}`;
    } else if (query.get('page')) {
      queryUrl += `page=${query.get('page')}`;
    } else {
      queryUrl += 'page=1';
    }

    if (query.get('keywords')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `keywords=${query.get('keywords')}`;
    }
    if (query.get('filters')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `filters=${query.get('filters')}`;
    }
    if (query.get('stage')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `stage=${query.get('stage')}`;
    }
    if (query.get('location')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `location=${query.get('location')}`;
    }
    if (query.get('industry')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `industry=${query.get('industry')}`;
    }
    if (query.get('type')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `type=${query.get('type')}`;
    }

    return queryUrl;
  };

  onChangePage = (pageOfItems) => {
    const { props } = this;
    const query = this.getQuery(new URLSearchParams(props.location.search), pageOfItems);
    this.setState({ pageOfItems }, () => props.history.push({ pathname: '/founders', search: `?${query}` }));
  };

  updateListOfFounder = (id, accepted, pinging, declining, declined, pinged) => {
    // Set up Loading
    const { state } = this;
    const founders = [...state.founders];

    // find the index of this founder
    const objIndex = founders.findIndex(i => i.id === id);
    if (objIndex >= 0) {
      // Sort out Pinged
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (accepted === null) accepted = founders[objIndex].accepted;

      // Update Founder
      const updatedFounder = {
        ...founders[objIndex], accepted, pinging, declining, declined, pinged,
      };
      // Make new list of founders
      const updatedFoundersList = [
        ...founders.slice(0, objIndex),
        updatedFounder,
        ...founders.slice(objIndex + 1),
      ];

      // Update the State
      this.setState({ founders: updatedFoundersList });

      return objIndex;
    }
    return false;
  };

  pingFounder = (message) => {
    const { showNotif } = this.props;
    const { founderId, founders } = this.state;

    // Remove the modal
    this.setState({ modalPing: false });

    // find the index of this founder to find out declined state
    let declined = false;
    const objIndex = founders.findIndex(i => i.id === founderId);
    if (objIndex >= 0) {
      // Sort out Pinged
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      declined = founders[objIndex].declined;
    }

    // Set up Loading
    this.updateListOfFounders(founderId, false, true, false, declined, false);

    // Post the Ping.
    axios.post('/founders/ping', queryString.stringify({ id: founderId, message }))
      .then((response) => {
        if (response) {
          this.updateListOfFounders(founderId, false, false, false, declined, true);
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          // Do something error.response.data.message ?
          showNotif(error.response.data.message);
        } else {
          showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateListOfFounders(founderId, false, false, false, declined, false);
      });
  };

  updateListOfFounders = (id, accepted, pinging, declining, declined, pinged) => {
    // Set up Loading
    const { state } = this;
    const founders = [...state.founders];

    // find the index of this founder
    const objIndex = founders.findIndex(i => i.id === id);
    if (objIndex >= 0) {
      // Sort out Pinged
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (accepted === null) accepted = founders[objIndex].accepted;

      // Update Founder
      const updatedFounder = {
        ...founders[objIndex], accepted, pinging, declining, declined, pinged,
      };
      // Make new list of founders
      const updatedFoundersList = [
        ...founders.slice(0, objIndex),
        updatedFounder,
        ...founders.slice(objIndex + 1),
      ];

      // Update the State
      this.setState({ founders: updatedFoundersList });

      return objIndex;
    }
    return false;
  };

  showModalPing = (id, name) => {
    // if (e) { e.preventDefault(); }
    // eslint-disable-next-line react/no-unused-state
    this.setState({ modalPing: true, founderId: id, founderName: name });
  };

  render() {
    const {
      itemsToShow, pageOfItems, itemsCount, showPagination, founderName,
      loading, loaded, error, modalPing, modal, modalNotActiveStartup,
    } = this.state;
    const {
      connectFounder, declineFounder,
    } = this.props;

    const state1 = this.state;
    let founders;
    let empty;

    if (state1.founders) {
      founders = state1.founders.map(
        founder => (
          <FounderCard
            founder={founder}
            ping={() => this.showModalPing(founder.id, founder.founder_name)}
            connect={() => connectFounder(founder.id)}
            decline={() => declineFounder(founder.id)}
            key={founder.id}
          />
        ),
      );
    }

    if (error && loaded) {
      empty = (
        <Card className="error-results">
          <CardBody>
            <h5>{error}</h5>
          </CardBody>
        </Card>
      );
    } else if (loaded) {
      empty = (
        <Card className="error-results">
          <CardBody>
            <svg className="load__icon">
              <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </CardBody>
        </Card>
      );
    } else {
      empty = (
        <Card className="error-results">
          <CardBody>
            <h5>Sorry we could not find any investor matching your request</h5>
          </CardBody>
        </Card>
      );
    }

    return (
      <Col md={12} lg={12} className="mb-4 mt-4">
        {showPagination
          && (
            <CustPag
              showCountOnly
              itemsCount={itemsCount}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          )}
        {!loaded
          ? (
            <div className={`load${loading ? '' : ' loaded'} inload`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          )
          : (
            <div className="investor-items__wrap">
              <div className="investor-items">
                {founders && founders.length > 0 ? founders : empty}
              </div>
            </div>
          )
        }
        {showPagination
        && (
          <CustPag
            itemsCount={itemsCount}
            itemsToShow={itemsToShow}
            pageOfItems={pageOfItems}
            onChangePage={this.onChangePage}
          />
        )}
        <ModalInput
          color="primary"
          title={`Send a Message to ${founderName}`}
          header
          displayed={modalPing}
          ping={val => this.pingFounder(val)}
        />
        <ModalNotActiveStartup
          color="primary"
          title="Fill Out Your Profile"
          colored
          displayed={modalNotActiveStartup}
          toggleModal={this.toggleModalNotActiveStartup}
          message={`
              You need to fill out your startup application first before you are able to contact our panel of investors.
              <br><br>
              Submit your application to be listed on our platform <strong>and connect with hundreds of
              investors</strong>, who are actively prospecting and investing on Angels Partners.<br><br>
              We review every application submitted within 2 to 5 working days.<br><br>
            `}
        />
        <Modal
          color="primary"
          title="Out of Pings!"
          colored
          displayed={modal}
          toggleModal={() => this.toggleModal()}
          pings
          message={`You do not have enough Pings left to contact this investor.<br><br>
             To send your deck to this investor's mailbox and pin your profile at the top
             of their dashboard, you need to buy more Pings.<br><br>
             To do so, please visit our <a href='${process.env.REACT_APP_DOMAIN_URL}/pings'>Pricing Page</a>.`}
        />
      </Col>
    );
  }
}
FoundersList.propTypes = {
  connectFounder: PropTypes.func.isRequired,
  declineFounder: PropTypes.func.isRequired,
  showNotif: PropTypes.func.isRequired,
  stateOfList: PropTypes.shape({
    id: PropTypes.bool,
    accepted: PropTypes.bool,
    pinging: PropTypes.bool,
    declining: PropTypes.bool,
    declined: PropTypes.bool,
  }).isRequired,
};

export default withRouter(FoundersList);
