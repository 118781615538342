import React from 'react';

const PageRunPluginMessage = () => (
  <div className="w-100 text-center p-5">
    <h1>Please run the plugin now to continue sending messages</h1>
    <p>
      We only process a limited number of actions per day to keep you LinkedIn account safe.<br />
      You need to run the plugin everyday until <strong>we send all the messages to your linkedin connections</strong>.
    </p>
  </div>
);

export default PageRunPluginMessage;
