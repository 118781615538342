/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Please enter your name';
  }
  if (!values.lastName) {
    errors.lastName = 'Please enter your name';
  }
  if (!values.location_city) {
    errors.location_city = 'Please fill out this field';
  }
  if (!values.location_country) {
    errors.location_country = 'Please fill out this field';
  }
  if (!values.company) {
    errors.company = 'Please fill out this field';
  }
  if (!values.companyRole) {
    errors.companyRole = 'Please fill out this field';
  }
  if (!values.email) {
    errors.email = 'Email field should not be left empty';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (values.linkedin && !isValidUrl(values.linkedin)) {
    errors.linkedin = 'Please enter a valid URL.';
  }
  if (!values.password) {
    errors.password = 'Please enter your password if you wish to change your account information.';
  }

  if (values.new_password !== values.new_password_check) {
    errors.new_password = 'These two fields should be equal if you wish to change your password';
    errors.new_password_check = 'These two fields should be equal if you wish to change your password';
  }

  return errors;
};

const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

export default validate;
