import React, { PureComponent } from 'react';
import {
  Col, Card, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import WizardFormOne from './WizardWhiteListOne';
import WizardFormTwo from './WizardWhiteListTwo';
import WizardFormThree from './WizardWhiteListThree';

export default class WizardForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      page: 1,
    };
  }

  nextPage = () => {
    this.setState(prevState => ({ page: prevState.page + 1 }));
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  };

  previousPage = () => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };

  render() {
    const { onSubmit } = this.props;
    const { page } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <span className="wizard_step_counter">01</span>
                <p>Info</p>
              </div>
              <svg
                className="wizard__steps_divider"
                width="549"
                height="2"
                viewBox="0 0 549 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 1H549" stroke="#8D9AA1" />
              </svg>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <span className="wizard_step_counter">02</span>
                <p>Settings</p>
              </div>
              <svg
                className="wizard__steps_divider"
                width="549"
                height="2"
                viewBox="0 0 549 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 1H549" stroke="#8D9AA1" />
              </svg>
              <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                <span className="wizard_step_counter">03</span>
                <p>WhiteList</p>
              </div>
            </div>
            <div className="wizard__form-wrapper">
              {page === 1
              && (
                <WizardFormOne
                  onSubmit={this.nextPage}
                />
              )}
              {page === 2
              && (
                <WizardFormTwo
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
              )}
              {page === 3
              && (
                <WizardFormThree
                  previousPage={this.previousPage}
                  onSubmit={onSubmit}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
