import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';

class ModalComponent extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      quantity: 1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed, quantity: 1 });
  }

  handleChange(event) {
    this.checkQuantityIsLowerThan10(event.target.value);
    this.setState({ quantity: event.target.value });
  }

  submit() {
    const { submit } = this.props;
    const { quantity, invalid } = this.state;
    if (!invalid) {
      submit(quantity);
    }
  }

  checkQuantityIsLowerThan10(number) {
    this.setState({ invalid: number > 10 });
  }

  toggle(e) {
    e.preventDefault();
    const { toggleModal } = this.props;
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const {
      modal, invalid, quantity,
    } = this.state;

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className="modal-dialog--primary modal-templates x-large"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            <h4>How many connections would you like to contact for each investors?</h4>
            <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
          </div>
          <form className="material-form">
            <div className="modal__body pt-0">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <p className="m-auto">
                    <input
                      type="number"
                      id="quantity"
                      name="quantity"
                      min="1"
                      max="10"
                      value={quantity}
                      onChange={this.handleChange.bind(this)}
                    />&nbsp;&nbsp;/ 10
                  </p>
                </div>
              </div>
              {invalid ? (
                <span className="text-red">You need to enter a number lower than 10.</span>
              ) : ''}
              <ButtonToolbar className="modal__footer float-right">
                <Button className="modal_cancel rounded" onClick={this.toggle}>Cancel</Button>{' '}
                {!invalid ? (
                  <Button
                    className="modal_ok rounded icon icon--right icon--blue"
                    type="button"
                    color="primary"
                    onClick={this.submit}
                  >
                    Submit <SendIcon />
                  </Button>
                ) : ''}
              </ButtonToolbar>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user,
}))(ModalComponent);
