/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CardBody, Button } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import StarsIcon from 'mdi-react/StarsIcon';
import ChatIcon from 'mdi-react/CommentMultipleOutlineIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import RemoveCircleIcon from 'mdi-react/RemoveCircleIcon';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

export default class InvestorCard extends PureComponent {
  static defaultProps = {
    investor: [],
    ping: null,
    clickFav: null,
    clickNot: null,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    const scrollYOffset = window.pageYOffset;
    sessionStorage.setItem('scrollYOffsetInt', scrollYOffset);
    sessionStorage.setItem('scrollActiveInt', false);
  }

  render() {
    const {
      investor, ping, clickFav, clickNot,
    } = this.props;

    function renderFavButton() {
      if (investor.favorite) {
        return (
          <Button
            className="icon rounded icon-only investor-item__icon-centered icon-only mr-0 m-2"
            color="warning"
            onClick={clickFav}
          >
            <p><StarsIcon /></p>
          </Button>
        );
      } if (investor.adding) {
        return (
          <Button className="icon rounded investor-item__icon-centered icon-only mr-0 m-2" color="warning" outline>
            <p><LoadingIcon className="mdi-icon-spinning" /></p>
          </Button>
        );
      }
      return (
        <Button
          className="icon rounded investor-item__icon-centered icon-only mr-0 m-2"
          color="primary"
          onMouseDown={e => clickFav(e)}
          onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { clickFav(e); } }}
        >
          <p><StarsIcon /></p>
        </Button>
      );
    }

    function renderNotButton() {
      if (investor.notAFit) {
        return (
          <Button
            className="icon rounded icon-only investor-item__icon-centered mr-0 m-2"
            color="danger"
            onClick={clickNot}
          >
            <p><RemoveCircleIcon /></p>
          </Button>
        );
      } if (investor.removing) {
        return (
          <Button className="icon rounded investor-item__icon-centered icon-only mr-0 m-2" color="danger" outline>
            <p><LoadingIcon className="mdi-icon-spinning" /></p>
          </Button>
        );
      }
      return (
        <Button
          className="icon rounded investor-item__icon-centered icon-only mr-0 m-2"
          color="primary"
          onMouseDown={e => clickNot(e)}
          onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { clickNot(e); } }}
        >
          <p><RemoveCircleIcon /></p>
        </Button>
      );
    }

    function renderPingButton() {
      if (investor.accepted && investor.conversation_id) {
        return (
          <>
            <Button
              className="icon icon-only investor-item__icon-centered
                          rounded mr-0 m-2 d-none d-md-inline-block d-xl-none"
              color="purple"
            >
              <Link to={investor.conversation_id ? `/inbox/${investor.conversation_id}` : '/inbox'}>
                <p><ChatIcon /></p>
              </Link>
            </Button>

            <Button
              className="icon icon--right rounded mr-0 m-2 d-inline-block d-md-none d-xl-inline-block"
              color="purple"
            >
              <Link to={investor.conversation_id ? `/inbox/${investor.conversation_id}` : '/inbox'}>
                <p>Chat <ChatIcon /></p>
              </Link>
            </Button>
          </>
        );
      } if (investor.accepted) {
        return (
          <Button className="icon icon--right rounded mr-0 m-2" color="action">
            <p>Connected <EmailOutlineIcon /></p>
          </Button>
        );
      } if (investor.declined) {
        return (
          <Button className="rounded mr-0 m-2" color="secondary">
            Declined
          </Button>
        );
      } if (investor.pinged) {
        return (
          <>
            <Button
              className="icon icon-only investor-item__icon-centered
                            rounded mr-0 m-2 d-none d-md-inline-block d-xl-none"
              color="action"
            >
              <p><EmailOutlineIcon /></p>
            </Button>

            <Button
              className="icon icon--right rounded mr-0 m-2 d-inline-block d-md-none d-xl-inline-block"
              color="action"
            >
              <p>Sent <EmailOutlineIcon /></p>
            </Button>
          </>
        );
      } if (investor.pinging) {
        return (
          <Button className="icon icon--right rounded mr-0 m-2" color="warning" outline>
            <p>Contacting <LoadingIcon className="mdi-icon-spinning" /></p>
          </Button>
        );
      }
      return (
        <>
          <Button
            className="icon icon-only investor-item__icon-centered
                        rounded mr-0 m-2 d-none d-md-inline-block d-xl-none"
            color="action"
            onMouseDown={e => ping(e)}
            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { ping(e); } }}
          >
            <p><EmailOutlineIcon /></p>
          </Button>
          <Button
            className="icon icon--right rounded mr-0 m-2 d-inline-block d-md-none d-xl-inline-block"
            color="action"
            onMouseDown={e => ping(e)}
            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { ping(e); } }}
          >
            <p>Contact <EmailOutlineIcon /></p>
          </Button>
        </>
      );
    }

    return (
      <div className="col-sm-12 col-md-6 col-lg-4">
        <CardBody className="investor-item col-md-12 p-0" key={investor.id}>
          <Link
            className="investor-item__link"
            to={`/investor/${investor.id}`}
            onClick={() => this.handleClick()}
          >
            <div className="investor-item__img-wrap">
              {investor.new ? <div className="investor-item__label-new">New</div> : ''}
              {/* eslint-disable-next-line max-len */}
              <img
                className="investor-item__img"
                src={investor.picture ? process.env.REACT_APP_AWS_PATH + investor.picture : userIcon}
                onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
                alt="investor-item-img"
              />
            </div>
            <div className="investor-item__info">
              <h4 className="investor-item__title">{investor.firstName} {investor.lastName}</h4>
              <h5 className="investor-item__location">
                <MapMarkerIcon />
                {investor.location_city} | {investor.location_country}
              </h5>
            </div>
            <div className="investor-item__info">
              <p className="investor-item__description text-muted text-justify">{investor.description}</p>
            </div>
          </Link>
          <div className="investor-item__actions">
            { renderPingButton() }
            { renderFavButton() }
            { renderNotButton() }
          </div>
          <Link
            className="investor-item__link-grey"
            to={`/investor/${investor.id}`}
            onClick={this.handleClick}
          >
            <div className="investor-item__investment-range">
              <CurrencyFormat value={investor.investmentMin} displayType="text" thousandSeparator prefix="$" />
              &nbsp; - &nbsp;
              <CurrencyFormat value={investor.investmentMax} displayType="text" thousandSeparator prefix="$" />
            </div>
          </Link>
        </CardBody>
      </div>
    );
  }
}

InvestorCard.propTypes = {
  investor: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    locationCity: PropTypes.string,
    locationCountry: PropTypes.string,
    picture: PropTypes.string,
    investmentMax: PropTypes.number,
    investmentMin: PropTypes.number,
    new: PropTypes.number,
    pinged: PropTypes.bool,
    pinging: PropTypes.bool,
    favorite: PropTypes.bool,
    adding: PropTypes.bool,
    notAFit: PropTypes.bool,
    fitting: PropTypes.bool,
  }),
  ping: PropTypes.func,
  clickFav: PropTypes.func,
  clickNot: PropTypes.func,
};
