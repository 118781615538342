import React from 'react';
import PropTypes from 'prop-types';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const SidebarPresentation = ({ name, logo }) => (
  <div className="presentation mb-5">
    <h4 className="name text-center mb-3">{name}</h4>
    <div className="picture-background mb-4">
      <span className="helper" />
      <img
        src={logo}
        onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
        alt="avatar"
      />
    </div>
  </div>
);

SidebarPresentation.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default SidebarPresentation;
