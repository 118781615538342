import React from 'react';
import {
  Button, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { Card } from '@material-ui/core';

const EmptyResults = ({
  error, internal, external, goToExtPage, goToIntPage,
}) => {
  if (error && !internal) {
    return (
      <Card className="error-results">
        <CardBody className="text-center">
          <h5 className="m-4">{error}</h5>
          <br />
          <Button
            className="rounded icon icon--right icon--blue m-auto"
            color="primary"
            onClick={goToExtPage}
          >
            Go to Investors Database List
            <ChevronRightIcon />
          </Button>
        </CardBody>
      </Card>
    );
  } if (error && !external) {
    return (
      <Card className="error-results">
        <CardBody className="text-center">
          <h5 className="m-4">{error}</h5>
          <br />
          <Button
            className="rounded icon icon--right icon--blue m-auto"
            color="primary"
            onClick={goToIntPage}
          >
            Go to Internal Investors List
            <ChevronRightIcon />
          </Button>
        </CardBody>
      </Card>
    );
  } if (error) {
    return (
      <Card className="error-results">
        <CardBody className="text-center">
          <h5 className="m-4">{error}</h5>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="error-results">
      <CardBody>
        <h5 className="m-4">Sorry we could not find any investor matching your request</h5>
      </CardBody>
    </Card>
  );
};

export default EmptyResults;

EmptyResults.defaultProps = {
  internal: null,
  external: null,
  goToExtPage: null,
  goToIntPage: null,
};

EmptyResults.propTypes = {
  error: PropTypes.string.isRequired,
  internal: PropTypes.number,
  external: PropTypes.number,
  goToExtPage: PropTypes.func,
  goToIntPage: PropTypes.func,
};
