import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Col, DropdownItem, DropdownMenu,
  DropdownToggle, Row, UncontrolledDropdown,
} from 'reactstrap';

import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import NonLinearSlider from '../../../shared/components/form/RangeSlider';
import { filtersArray, getIndustryFilters } from '../../../shared/functions/getFilters';
import { MultiSelectField } from '../../../shared/components/form/MultiSelect';
import getCountry from '../../../shared/functions/getCountry';

class SearchFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: null,
      stage: null,
      location: null,
      industry: null,
      type: null,
      rangeMin: 0,
      rangeMax: 0,
      sortBy: null,
      keywords: '',
    };
  }

  componentDidMount() {
    this.getFiltersValues();
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    // eslint-disable-next-line react/prop-types
    if (props.location.pathname !== prevProps.location.pathname
      // eslint-disable-next-line react/prop-types
      || props.location.search !== prevProps.location.search) {
      this.getFiltersValues();
    }
  }

  getFiltersValues = () => {
    const { props } = this;
    const query = new URLSearchParams(props.location.search);

    // eslint-disable-next-line no-restricted-syntax
    if (query.get('keywords')) {
      this.setState({ keywords: query.get('keywords') });
    }
    if (query.get('filters')) {
      this.setState({ filters: query.get('filters') });
    }
    if (query.get('stage')) {
      this.setState({ stage: query.get('stage') });
    }
    if (query.get('location')) {
      this.setState({ location: query.get('location') });
    }
    if (query.get('industry')) {
      this.setState({ industry: query.get('industry') });
    }
    if (query.get('type')) {
      this.setState({ type: query.get('type') });
    }
    if (query.get('rangeMin')) {
      this.setState({ rangeMin: parseInt(query.get('rangeMin'), 10) });
    }
    if (query.get('rangeMax')) {
      this.setState({ rangeMax: parseInt(query.get('rangeMax'), 10) });
    }
    if (query.get('sortBy')) {
      this.setState({ sortBy: query.get('sortBy') });
    }
  }

  updateRangeValue = (min, max) => {
    this.setState({ rangeMin: min, rangeMax: max });
  };

  updateKeywordsValue = (evt) => {
    this.setState({ keywords: evt.target.value });
  };

  onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      this.applyFilters();
    }
  }

  updateIndustryValue = (values) => {
    let industries = null;
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
          industries += ',';
        }
        industries += values[i].value;
      }
    }
    this.setState({ industry: industries });
  };

  updateLocationValue = (values) => {
    let locations = null;
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
          locations += ',';
        }
        locations += values[i].value;
      }
    }
    this.setState({ location: locations });
  };

  updateStageValue = (values) => {
    let stages = null;
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
          stages += ',';
        }
        stages += values[i].value;
      }
    }
    this.setState({ stage: stages });
  };

  updateTypeValue = (values) => {
    let types = null;
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
          types += ',';
        }
        types += values[i].value;
      }
    }
    this.setState({ type: types });
  };

  addFilter = (filter, value) => {
    this.setState({ [filter]: value });
  };

  getName = (filter) => {
    if (filter === 'declined') {
      return 'Declined';
    } if (filter === 'all') {
      return 'All (Default)';
    } if (filter === 'favorites') {
      return 'Favorites';
    }
    return 'Filters';
  };

  getStage = (filter) => {
    if (filter === 1) {
      return 'Idea';
    } if (filter === 2) {
      return 'Prototype';
    } if (filter === 3) {
      return 'MVP';
    } if (filter === 4) {
      return 'Revenues';
    } if (filter === 5) {
      return 'Break Even';
    } if (filter === 6) {
      return 'Profitable';
    } if (filter === 7) {
      return 'Other';
    }
    return 'Company Stage';
  };

  getLocation = filter => getCountry(filter);

  getType = (filter) => {
    if (filter === 1) {
      return 'E-Commerce';
    } if (filter === 2) {
      return 'Marketplace';
    } if (filter === 3) {
      return 'Saas';
    } if (filter === 4) {
      return 'Hardware';
    } if (filter === 5) {
      return 'Market Network';
    } if (filter === 6) {
      return 'Social Network';
    } if (filter === 7) {
      return 'B2C';
    } if (filter === 8) {
      return 'B2B';
    } if (filter === 9) {
      return 'B2B2C';
    } if (filter === 10) {
      return 'Other';
    }
    return 'None';
  };

  getSortBy = (filter) => {
    if (filter === 'recent') {
      return 'Most Recent';
    } if (filter === 'completion') {
      return 'Profile Completion';
    }
    return 'Sort By';
  };

  applyFilters() {
    const { props, state } = this;
    let query = '';

    if (state.keywords) {
      query += query !== '' ? '&' : '';
      query += `keywords=${state.keywords}`;
    }
    if (state.filters) {
      query += `filters=${state.filters}`;
    }
    if (state.stage) {
      query += query !== '' ? '&' : '';
      query += `stage=${state.stage}`;
    }
    if (state.location) {
      query += query !== '' ? '&' : '';
      query += `location=${state.location}`;
    }
    if (state.industry) {
      query += query !== '' ? '&' : '';
      query += `industry=${state.industry}`;
    }
    if (state.type) {
      query += query !== '' ? '&' : '';
      query += `type=${state.type}`;
    }
    if (state.rangeMin) {
      let num = state.rangeMin;
      if (state.rangeMin > 999 && state.rangeMin < 1000000) {
        num = Math.round(state.rangeMin / 1000) * 1000;
      } if (state.rangeMin >= 1000000) {
        num = Math.round(state.rangeMin / 1000000) * 1000000;
      }
      if (state.rangeMin !== 0) {
        query += query !== '' ? '&' : '';
        query += `rangeMin=${num}`;
      }
    }
    if (state.rangeMax) {
      let num = state.rangeMax;
      if (state.rangeMax > 999 && state.rangeMax < 1000000) {
        num = Math.round(state.rangeMax / 1000) * 1000;
      } if (state.rangeMax >= 1000000) {
        num = Math.round(state.rangeMax / 1000000) * 1000000;
      }
      if (state.rangeMax < 10000000) {
        query += query !== '' ? '&' : '';
        query += `rangeMax=${num}`;
      }
    }
    if (state.sortBy) {
      query += query !== '' ? '&' : '';
      query += `sortBy=${state.sortBy}`;
    }
    props.history.push({ pathname: '/startups/', search: `?${query}` });
  }

  render() {
    const {
      filters, stage, location, industry, type, sortBy, keywords,
    } = this.state;

    const industrySelected = [];
    if (industry) {
      for (let i = 0; i < String(industry).split(',').length; i += 1) {
        industrySelected.push({
          value: parseInt(String(industry).split(',')[i], 10),
          label: getIndustryFilters(parseInt(String(industry).split(',')[i], 10)),
        });
      }
    }

    const locationSelected = [];
    if (location) {
      for (let i = 0; i < String(location).split(',').length; i += 1) {
        locationSelected.push({
          value: parseInt(String(location).split(',')[i], 10),
          label: this.getLocation(parseInt(String(location).split(',')[i], 10)),
        });
      }
    }

    const stageSelected = [];
    if (stage) {
      for (let i = 0; i < String(stage).split(',').length; i += 1) {
        stageSelected.push({
          value: parseInt(String(stage).split(',')[i], 10),
          label: this.getStage(parseInt(String(stage).split(',')[i], 10)),
        });
      }
    }

    const typeSelected = [];
    if (type) {
      for (let i = 0; i < String(type).split(',').length; i += 1) {
        typeSelected.push({
          value: parseInt(String(type).split(',')[i], 10),
          label: this.getType(parseInt(String(type).split(',')[i], 10)),
        });
      }
    }

    return (
      <div className="search-block topbar__search-bordered">
        <Row>
          <Col xs={12} md={6} xl={6}>
            <div className="input-keywords mb-3 mr-sm-1 mr-lg-1 mr-xl-0 mb-lg-4">
              <input
                className="input-search container-fluid"
                name="keywords"
                type="text"
                value={keywords}
                placeholder="Keywords, Name, Description..."
                onChange={evt => this.updateKeywordsValue(evt)}
                onKeyDown={this.onKeyDownHandler}
              />
            </div>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <div className="text-muted helper-search container-fluid pl-0 pt-0 pt-xl-2 pb-4 pb-xl-0 pl-xl-5 pr-xl-4">
              <InformationIcon />&nbsp;&nbsp;&nbsp;Separate your entries with commas in the search&nbsp;
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={6}>
            <div className="input-industry w-100 mb-20 mb-md-0 mr-lg-1 mr-xl-4 mt-2">
              <MultiSelectField
                name="industry"
                placeholder="INDUSTRY"
                onChange={evt => this.updateIndustryValue(evt)}
                value={industrySelected}
                options={filtersArray}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6}>
            <div className="input-industry w-100 mb-20 mb-md-0 mr-lg-1 mr-xl-4 mt-2">
              <MultiSelectField
                name="location"
                placeholder="LOCATION"
                onChange={evt => this.updateLocationValue(evt)}
                value={locationSelected}
                options={[
                  { value: 26, label: this.getLocation(26) },
                  { value: 46, label: this.getLocation(46) },
                  { value: 4, label: this.getLocation(4) },
                  { value: 31, label: this.getLocation(31) },
                  { value: 30, label: this.getLocation(30) },
                  { value: 27, label: this.getLocation(27) },
                  { value: 52, label: this.getLocation(52) },
                  { value: 64, label: this.getLocation(64) },
                  { value: 3, label: this.getLocation(3) },
                  { value: 48, label: this.getLocation(48) },
                  { value: 15, label: this.getLocation(15) },
                  { value: 28, label: this.getLocation(28) },
                  { value: 59, label: this.getLocation(59) },
                  { value: 49, label: this.getLocation(49) },
                  { value: 18, label: this.getLocation(18) },
                  { value: 33, label: this.getLocation(33) },
                  { value: 32, label: this.getLocation(32) },
                  { value: 34, label: this.getLocation(34) },
                  { value: 9, label: this.getLocation(9) },
                  { value: 5, label: this.getLocation(5) },
                  { value: 58, label: this.getLocation(58) },
                  { value: 21, label: this.getLocation(21) },
                  { value: 36, label: this.getLocation(36) },
                  { value: 40, label: this.getLocation(40) },
                  { value: 6, label: this.getLocation(6) },
                  { value: 62, label: this.getLocation(62) },
                  { value: 47, label: this.getLocation(47) },
                  { value: 24, label: this.getLocation(24) },
                  { value: 11, label: this.getLocation(11) },
                  { value: 7, label: this.getLocation(7) },
                  { value: 41, label: this.getLocation(41) },
                  { value: 29, label: this.getLocation(29) },
                  { value: 42, label: this.getLocation(42) },
                  { value: 45, label: this.getLocation(45) },
                  { value: 61, label: this.getLocation(61) },
                  { value: 10, label: this.getLocation(10) },
                  { value: 44, label: this.getLocation(44) },
                  { value: 60, label: this.getLocation(60) },
                  { value: 17, label: this.getLocation(17) },
                  { value: 37, label: this.getLocation(37) },
                  { value: 35, label: this.getLocation(35) },
                  { value: 8, label: this.getLocation(8) },
                  { value: 63, label: this.getLocation(63) },
                  { value: 50, label: this.getLocation(50) },
                  { value: 38, label: this.getLocation(38) },
                  { value: 23, label: this.getLocation(23) },
                  { value: 53, label: this.getLocation(53) },
                  { value: 19, label: this.getLocation(19) },
                  { value: 20, label: this.getLocation(20) },
                  { value: 54, label: this.getLocation(54) },
                  { value: 55, label: this.getLocation(55) },
                  { value: 13, label: this.getLocation(13) },
                  { value: 12, label: this.getLocation(12) },
                  { value: 56, label: this.getLocation(56) },
                  { value: 14, label: this.getLocation(14) },
                  { value: 16, label: this.getLocation(16) },
                  { value: 25, label: this.getLocation(25) },
                  { value: 57, label: this.getLocation(57) },
                  { value: 39, label: this.getLocation(39) },
                  { value: 2, label: this.getLocation(2) },
                  { value: 1, label: this.getLocation(1) },
                  { value: 43, label: this.getLocation(43) },
                  { value: 22, label: this.getLocation(22) },
                  { value: 51, label: this.getLocation(51) },
                ]}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} xl={3}>
            <div className="input-industry w-100 mb-20 mb-md-0 mr-lg-1 mr-xl-4 mt-2">
              <MultiSelectField
                name="stage"
                placeholder="STAGE"
                onChange={evt => this.updateStageValue(evt)}
                value={stageSelected}
                options={[
                  { value: 1, label: this.getStage(1) },
                  { value: 2, label: this.getStage(2) },
                  { value: 3, label: this.getStage(3) },
                  { value: 4, label: this.getStage(4) },
                  { value: 5, label: this.getStage(5) },
                  { value: 6, label: this.getStage(6) },
                  { value: 7, label: this.getStage(7) },
                ]}
              />
            </div>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <div className="input-industry w-100 mb-20 mb-md-0 mr-lg-1 mr-xl-4 mt-2">
              <MultiSelectField
                name="type"
                placeholder="TYPE"
                onChange={evt => this.updateTypeValue(evt)}
                value={typeSelected}
                options={[
                  { value: 1, label: this.getType(1) },
                  { value: 2, label: this.getType(2) },
                  { value: 3, label: this.getType(3) },
                  { value: 4, label: this.getType(4) },
                  { value: 5, label: this.getType(5) },
                  { value: 6, label: this.getType(6) },
                  { value: 7, label: this.getType(7) },
                  { value: 8, label: this.getType(8) },
                  { value: 9, label: this.getType(9) },
                  { value: 10, label: this.getType(10) },
                  { value: null, label: this.getType(0) },
                ]}
              />
            </div>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <UncontrolledDropdown className="select-type mt-2">
              {this.getName(filters) === 'Filters'
                ? (
                  <DropdownToggle className="icon rounded icon--blue icon--right w-100" color="primary">
                    <p>{this.getName(filters)} <ChevronDownIcon /></p>
                  </DropdownToggle>
                ) : (
                  <DropdownToggle className="icon rounded icon--blue icon--right w-100" color="primary">
                    <p>{this.getName(filters)} <ChevronDownIcon /></p>
                  </DropdownToggle>
                )
              }
              <DropdownMenu className="dropdown__menu">
                <DropdownItem
                  onClick={() => this.addFilter('filters', 'all')}
                >
                  {this.getName('all')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.addFilter('filters', 'declined')}
                >
                  {this.getName('declined')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.addFilter('filters', 'favorites')}
                >
                  {this.getName('favorites')}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => this.addFilter('filters', null)}>None</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <UncontrolledDropdown className="select-type mt-2">
              {this.getSortBy(sortBy) === 'Sort By'
                ? (
                  <DropdownToggle className="icon rounded icon--blue icon--right w-100" color="primary">
                    <p>{this.getSortBy(sortBy)} <ChevronDownIcon /></p>
                  </DropdownToggle>
                ) : (
                  <DropdownToggle className="icon rounded icon--blue icon--right w-100" color="primary">
                    <p>{this.getSortBy(sortBy)} <ChevronDownIcon /></p>
                  </DropdownToggle>
                )
              }
              <DropdownMenu className="dropdown__menu">
                <DropdownItem
                  onClick={() => this.addFilter('sortBy', 'recent')}
                >
                  {this.getSortBy('recent')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.addFilter('sortBy', 'completion')}
                >
                  {this.getSortBy('completion')}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => this.addFilter('sortBy', null)}
                >
                  None
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className="text-muted center-block text-center mt-4 mb-1">Minimum Revenue</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5}>
            <NonLinearSlider
              returnedValue={(min, max) => this.updateRangeValue(min, max)}
            />
            <br />
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <div className="pl-md-5 pl-xl-5 mt-5 mt-xl-2 float-right float-xl-none">
              <Button
                className="rounded icon icon--right apply-filters w-100"
                color="action"
                onClick={() => this.applyFilters()}
              >
                Apply Filters
                <ChevronRightIcon />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(SearchFilters);
