/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button, Col, Row, Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CardTitle from 'reactstrap/lib/CardTitle';
import PlusIcon from 'mdi-react/PlusIcon';


function MarketingTab(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <CardTitle>
        <Row>
          <Col xs={12} xl={8}>
            <h3>Build a Solid Marketing Plan!</h3>
            <p className="text-muted mt-4">
              A marketing and brand strategy sets the direction and tone for all of your brand’s communications,
              effectively telling the world who your company is and what you’re all about. The strategy is comprised
              of two sections: a Description and a Plan of Action.
            </p>
            <p className="text-muted mb-2">
              The Branding Brief sets the voice of your brand, outlining a specific tone to be used in your
              messaging (something that should be unique and instantly recognizable). It is a “tonal” document - one
              that relies on verbiage and structure to convey the essence of the brand without having to explicitly
              describe values, missions, or business functions.
            </p>
          </Col>
        </Row>
      </CardTitle>
      <Table responsive hover>
        <tbody>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1QoYqSwt6SIVlcR3vJWGOXNx2-9UzRcGmM-QEtJd36R0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Company Launch Announcement Template
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A great company launch email is one of your best opportunities to hook investors and insert
              yourself onto their radar.
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1QoYqSwt6SIVlcR3vJWGOXNx2-9UzRcGmM-QEtJd36R0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1RwUdXYU2MrpIBAn_FKBRpejHzEwRu4Te2l2KqpPR7sE/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Customer Lifetime Value Calculator
              </a>
            </td>
          </tr>
          <tr>
            <td>
              Customer lifetime value (CLV) is the total worth for a business of a customer over the whole period of
              their relationship. This calculator lets you calculate your CLV.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1RwUdXYU2MrpIBAn_FKBRpejHzEwRu4Te2l2KqpPR7sE/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="success" className="btn rounded icon mt-4">
                  Open Spreadsheet
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://drive.google.com/file/d/1JZ5MGaU5Jefz1J9ALu_xlsZYljYLGRQb/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Sales Deck Template from New Haircut
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A sales deck is defined as a presentation that you can present or pitch to clients.
              Sales decks help prospects feel connected to your company, understand your product/services,
              get &#39;wowed&#39; by your results and ultimately end with a call to action.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://drive.google.com/file/d/1JZ5MGaU5Jefz1J9ALu_xlsZYljYLGRQb/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button className="btn rounded icon btn-slides mt-4">
                  Open Slides
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1RvZorWorHKv5dWBJo02gx3Kh-N5o8j1gWPKwfZDBNJg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Sample Press Release Outline
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A press release (also known as a news release) is a brief document that shares something newsworthy
              you have done with the press and other media outlets. It is usually sent to journalists and editors
              who may use the information to write a news article. Here is an outline of the key elements.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1RvZorWorHKv5dWBJo02gx3Kh-N5o8j1gWPKwfZDBNJg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1FFute2YEfICKFJ4KWo5B-zVPMNEuUKqqW-QGLEzlRXM/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Social Media Posting Schedule Example
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A social media posting schedule helps outline the messages and links for your posts to go out at
              certain times of the day on a regular basis, which helps you save time.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1FFute2YEfICKFJ4KWo5B-zVPMNEuUKqqW-QGLEzlRXM/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="success" className="btn rounded icon mt-4">
                  Open Spreadsheet
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

MarketingTab.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default MarketingTab;
