import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import Alert from '../../../shared/components/alerts/Alert';

const CampaignsWarnings = ({ hasLink }) => {
  if (!hasLink) {
    return (
      <Row>
        <Col md={12}>
          <Alert color="info" className="alert--colored mb-5">
            <p>
              <span className="bold-text">Did you know? </span><br />
              You can track an investor&#39;s engagement and take action based on their interest in your
              company.<br />
              Use a tracking link in your campaigns to monitor investor interaction with your documents,
              such as, the number of visits, the time spent on each document and much more.<br />
              To do so, go to your&nbsp;
              <Link to="campaigns/create" className="text-black-50">
                <u><strong>campaigns</strong></u>
              </Link> and add this variable directly into your templates like so:&nbsp;
              <strong>&#123;&#123; trackingLink &#125;&#125;</strong>
            </p>
          </Alert>
        </Col>
      </Row>
    );
  }
  return null;
};

CampaignsWarnings.propTypes = {
  hasLink: PropTypes.bool.isRequired,
};

export default withRouter(CampaignsWarnings);
