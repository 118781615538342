/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (!values.shortDescription) {
    errors.shortDescription = 'Please enter a short description';
  } else if (values.shortDescription.length < 10) {
    errors.shortDescription = 'Your short description should be at least 10 characters long.';
  } else if (values.shortDescription.length > 225) {
    errors.shortDescription = 'Your short description should be less than 225 characters long.';
  }

  if (!values.description) {
    errors.description = 'Please enter a description';
  } else if (values.description.length < 10) {
    errors.description = 'Your description should be at least 10 characters long.';
  } else if (values.description.length > 1500) {
    errors.description = 'Your description should be less than 1500 characters long.';
  } else if (values.description === values.shortDescription) {
    errors.description = 'Your short description should not be the same as your description.';
  }

  return errors;
};

export default validate;
