import React from 'react';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderDropZoneField from '../../../shared/components/form/DropZone';

const FileUploadCustomHeight = ({ handleSubmit, reset, hideUpload }) => (
  <form className="form__chat" onSubmit={handleSubmit}>
    <Field
      name="files"
      component={renderDropZoneField}
    />
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit">Submit</Button>
      <Button type="button" onClick={reset}>
        Reset
      </Button>
      <Button type="button" onClick={hideUpload}>
        Close
      </Button>
    </ButtonToolbar>
  </form>
);

FileUploadCustomHeight.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  hideUpload: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'file_upload_default', // a unique identifier for this form
})(FileUploadCustomHeight);
