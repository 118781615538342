import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Col, Container, Row,
} from 'reactstrap';
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import queryString from 'query-string';

import PageVisibility from 'react-page-visibility';
import StartupMain from '../../shared/components/startup/StartupMain';
import StartupTabs from '../../shared/components/startup/StartupTabs';
import ModalInput from '../../shared/components/modals/SummaryModalInput';
import TopbarWithoutNavigation from '../Layout/topbar_without_navigation/TopbarWithoutNavigation';
// import InvestorRegister from './components/InvestorRegister';


class StartupSummary extends PureComponent {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      visitorId: cookies.get('visitorId') || null,
      startup: {},
      loading: true,
      loaded: false,
      link: null,
      error: null,
      askPassword: false,
      wrongPassword: false,
      askEmail: false,
      focus: true,
      displayRegister: false,
      user: JSON.parse(localStorage.getItem('user')),
    };
    this.timer = null;
  }

  componentDidMount = () => {
    // const { cookies } = this.props;
    // cookies.set('visitorId', null, { path: '/' }); // tets
    this.init(null);
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  // componentWillReceiveProps(nextProps) {
  //   const { state } = this;
  //   const prevStateId = parseInt(state.startup.id, 10);
  //   // eslint-disable-next-line react/prop-types
  //   const nextPropsId = parseInt(nextProps.match.params.id, 10);
  //   if (!Number.isNaN(prevStateId)
  //     && !Number.isNaN(nextPropsId)
  //     && nextPropsId !== prevStateId) {
  //     this.init(null);
  //   }
  //   return null;
  // }

  handleVisibilityChange = (isVisible) => {
    this.setState({ focus: isVisible });
  };

  init = (password) => {
    const { props } = this;
    const { id } = props.match.params;
    let { extInvId } = props.match.params;

    if (typeof extInvId === typeof undefined) {
      extInvId = 0;
    }

    axios.get(password !== null ? (`/summary/${id}/${extInvId}/${password}`) : (`/summary/${id}/${extInvId}`))
      .then((response) => {
        const { data } = response;
        if (data.success && typeof data.startup === 'object' && data.startup !== null) {
          this.setState({
            startup: data.startup,
            loading: false,
            link: id,
            askPassword: false,
            error: null,
          });
          if (!data.is_founder) {
            // if the visitor is the founder, no need to track nor do we want to display the register box
            this.setState({ displayRegister: true });
            this.authenticateVisitor(data.authEmail, data.email);
          }
        } else if (data.protected) {
          this.setState({
            askPassword: true,
            loading: false,
            error: 'This summary is protected with a password',
            wrongPassword: !!password,
          });
        } else {
          this.setState({ error: 'This link is not valid', loading: false, askPassword: false });
        }
        setTimeout(() => this.setState({ loaded: true }), 500);
      }).catch((error) => {
        if (error.response
          && error.response.data.message
          && error.response.data.message.length > 0) {
          this.setState({ error: error.response.data.message, loading: false, askPassword: false });
        } else {
          this.setState({ error: 'This link is not valid', loading: false, askPassword: false });
        }
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
  };

  authenticateVisitor = (authEmail, email) => {
    const { visitorId, user } = this.state;

    if (visitorId !== null && visitorId !== 'null') {
      this.trackAction();
    } else if (email) {
      // We already have the email => The visitor is an external investor from a campaign.
      this.retrieveVisitorId(email);
    } else if (user) {
      this.retrieveVisitorId(user.email);
    } else if (authEmail && !user) {
      // Ask for email
      this.setState({ askEmail: true });
    } else {
      this.retrieveVisitorId(null);
    }
  };

  retrieveVisitorId = (email) => {
    const { cookies } = this.props;
    const { visitorId } = this.state;
    this.setState({ askEmail: false }); // Hide modal
    axios.post('summary/visitor', queryString.stringify({
      email,
      visitorId,
    })).then((response) => {
      if (response.data && response.data.success) {
        cookies.set('visitorId', response.data.id, { path: '/' });
        this.setState({ visitorId: response.data.id }, () => this.trackVisitor());
      }
    });
  };

  trackAction = (type) => {
    const { visitorId, link } = this.state;
    axios.post(`summary/track/${type}`, queryString.stringify({
      visitor: visitorId,
      link,
    }));
  };

  trackVisitor = () => {
    const { visitorId, link } = this.state;
    axios.post('summary/track/visits', queryString.stringify({
      visitor: visitorId,
      link,
    })).then((response) => {
      if (response.data.askEmail) {
        this.setState({ askEmail: true });
      }
    });
    this.trackTime();
  };

  trackTime = () => {
    const { visitorId, link } = this.state;

    this.timer = setInterval(() => {
      const { focus } = this.state;
      if (focus) {
        axios.post('summary/track/time', queryString.stringify({
          visitor: visitorId,
          link,
        }));
      }
    }, 3000);
  };

  render = () => {
    const {
      startup, loading, loaded, link, error, askPassword,
      wrongPassword, askEmail, displayRegister,
    } = this.state;

    const button = (
      <div />
    );

    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <div>
          <TopbarWithoutNavigation />
          <Container>
            <div className="profile startup">
              {/* eslint-disable-next-line no-nested-ternary */}
              {!loaded
                ? (
                  <div className={`load${loading ? '' : ' loaded'} inload`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                      </svg>
                    </div>
                  </div>
                )
                : error
                  ? (
                    <div className={`load${loading ? '' : ' loaded'} inload`}>
                      <div className="load__icon-wrap">
                        {error}
                      </div>
                    </div>
                  )
                  : (
                    <Row>
                      <Col xs={12}>
                        <StartupMain
                          displayRegister={displayRegister}
                          startup={startup}
                          renderConnectButton={button}
                        />
                        {/* {displayRegister */}
                        {/* && ( */}
                        {/*  <InvestorRegister */}
                        {/*    additionalClasses="margin-15 d-none d-lg-block" */}
                        {/*  /> */}
                        {/* )} */}
                        <StartupTabs
                          link={link}
                          startup={startup}
                          sendNotification={val => this.trackAction(val)}
                        />
                      </Col>
                      {/* {displayRegister */}
                      {/* && ( */}
                      {/*  <InvestorRegister */}
                      {/*    additionalClasses="center-block d-block d-lg-none" */}
                      {/*  /> */}
                      {/* )} */}
                    </Row>
                  )
              }
            </div>
            <ModalInput
              title="Please enter your email to review this deck"
              placeholder="john.doe@mail.com"
              displayed={askEmail}
              askEmail
              submit={email => this.retrieveVisitorId(email)}
            />
            <ModalInput
              title="This summary requires a password to be accessed"
              placeholder="Enter Password"
              wrongPassword={wrongPassword}
              displayed={askPassword}
              submit={val => this.init(val)}
            />
          </Container>
        </div>
      </PageVisibility>
    );
  }
}

export default withRouter(withCookies(StartupSummary));
