import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import CardDesigner from './components/CardDesigner';
import CardFundraisingServices from './components/CardFundraisingServices';
import CardFundraisingBootcamp from './components/CardFundraisingBootcamp';
import CardSeedLegals from './components/CardSeedLegals';
import CardAdvisors from './components/CardAdvisors';
import CardDeckReview from './components/CardDeckRev';
import CardLightster from './components/CardLightster';
import CardSearchVentures from './components/CardSearchVentures';
import CardTrustVenture from './components/CardTrustVenture';

const Partnerships = () => (
  <Container>
    <Row>
      <Col xs={12} md={3} xl={2}>
        <h3 className="page-title">Partnerships & Services</h3>
      </Col>
      <Col xs={12} md={9} lg={7} xl={5}>
        <p className="page-description">
          Take advantages of these partnerships we built for you, and boost your startup.
        </p>
      </Col>
    </Row>
    <Row>
      <Col md={12} className="mt-5 mb-4">
        <h3>Extra Services</h3>
      </Col>
    </Row>
    <Row>
      <CardFundraisingServices />
      <CardDeckReview />
      <CardDesigner />
    </Row>
    <Row>
      <Col md={12} className="mt-5 mb-4">
        <h3>Partnerships</h3>
      </Col>
    </Row>
    <Row>
      <CardFundraisingBootcamp />
      <CardAdvisors />
      <CardSeedLegals />
    </Row>
    <Row>
      <CardLightster />
      <CardSearchVentures />
      <CardTrustVenture />
    </Row>
  </Container>
);

export default Partnerships;
