import PropTypes from 'prop-types';

const {
  string, shape,
} = PropTypes;

export const UserProps = shape({
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  avatar: PropTypes.string,
  registered: PropTypes.any,
  accepted: PropTypes.any,
  isCustomer: PropTypes.any,
  type: PropTypes.string,
  mailboxConnected: PropTypes.any,
  completion: PropTypes.number,
  campaigns: PropTypes.number,
  appSubmitted: PropTypes.bool,
});

export const SidebarProps = shape({
  show: PropTypes.bool,
  collapse: PropTypes.bool,
});

export const ThemeProps = shape({
  className: string,
});
