/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (values.updates && values.updates.length < 10) {
    errors.updates = 'Your updates should be at least 10 characters long.';
  } else if (values.updates && values.updates.length > 1500) {
    errors.updates = 'Your updates should be less than 1500 characters long.';
  }

  return errors;
};

export default validate;
