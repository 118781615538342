import React from 'react';
import { Button } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';

const WizardFormThree = ({ handleSubmit, previousPage, onSubmit }) => (
  <form
    className="form form--horizontal wizard__form wizard__form__whitelist wizard__form__step-three"
    onSubmit={handleSubmit(onSubmit.bind(this))}
  >
    <div className="wizard__form__header">
      <h3 className="wizard__title">WhiteList Angels Partners</h3>
    </div>
    <div className="form__form-group bottom-0 mt-5 wizard__whitelist">
      <h4 className="m-3">
        <span className="bullet">4</span>Click on “Configure new app” and select “OAuth App Name Or Client ID”
      </h4>
      <img
        src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/whitelisting/step4.png`}
        className="whitelisting__steps mt-3"
        alt="White Listing process step 4"
      />
      <hr className="m-5" />

      <h4 className="m-3">
        <span className="bullet">5</span>
        Enter our client ID in the main input field, click on Search and select Angels Partners
      </h4>
      <h4 className="m-3">
        <strong>
          Our Client ID is: 649403847941-s91avaoi8l4154h1metnuhj44fcm66ao.apps.googleusercontent.com
        </strong>
      </h4>
      <img
        src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/whitelisting/step5.png`}
        className="whitelisting__steps mt-3"
        alt="White Listing process step 5"
      />
      <hr className="m-5" />

      <h4 className="m-3">
        <span className="bullet">6</span>
        Click on Select
      </h4>
      <img
        src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/whitelisting/step6.png`}
        className="whitelisting__steps mt-3"
        alt="White Listing process step 6"
      />
      <hr className="m-5" />

      <h4 className="m-3">
        <span className="bullet">7</span>
        Select Trusted and click on Save
      </h4>
      <img
        src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/whitelisting/step7.png`}
        className="whitelisting__steps mt-3"
        alt="White Listing process step 6"
      />
      <hr className="m-5" />

      <h4 className="m-3">Angels Partners will now appear in your list of “App Access Control”</h4>
      <h4 className="m-3">Click on Connect your Mailbox just below to connect your Mailbox with Angels Partners</h4>
    </div>
    <div className="form__form-group mb-3 mt-3">
      <div className="form__button-toolbar container-fluid">
        <Button className="next btn btn-success icon rounded float-right icon--right" type="submit">
          <p>
            Connect Mailbox
            <ChevronRightIcon />
          </p>
        </Button>
        <Button
          color="primary"
          type="button"
          className="previous btn btn-primary icon rounded float-left"
          onClick={previousPage}
        >
          <p>
            <ChevronLeftIcon />
            BACK
          </p>
        </Button>
      </div>
    </div>
  </form>
);

WizardFormThree.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormThree);
