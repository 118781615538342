import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import PropTypes from 'prop-types';

class ModalAdvisors extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    displayed: false,
    toggleModal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-templates xx-large"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4>Growth Mentor - 20% Off</h4>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
        </div>
        <div className="modal__body mt-5 mb-4">
          <div className="section section-pad section-bg-btoa" id="bootcamp">
            <div className="text-center">
              <h3 className="animated" data-animate="fadeInUp" data-delay=".1">
                <strong>NEVER FEEL LIKE YOU ARE ALONE AGAIN!</strong>
              </h3>
            </div>

            <div className="col-md-10 offset-md-1 text-justify mb-4">
              <p className="mt-4 mb-4">
                With 400+ growth mentor just a few clicks away you can:
                <ul>
                  <li>Get your ideas validated so you’re blazing with confidence</li>
                  <li>Get personalized advice on your growth strategy</li>
                  <li>Get clarity on things you’re struggling with</li>
                  <li>Get a virtual shoulder to sob on + practical advice on how to move forward</li>
                </ul>
              </p>
              <p className="mt-5 mb-4">
                Join GrowthMentor to get access to growth experts from amazing companies like:
                <img
                  src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/growthMentor.png`}
                  alt="growth mentor"
                  className="w-100"
                />
              </p>

              <p className="mt-5 mb-4">
                What if you could tap into the experience of 400+ startup and marketing mentors?
              </p>
              <p className="mt-5 mb-4">
                Talk with like-minded experts, get fresh growth ideas, and see the blind spots in your decision
                making.

                Use filters to quickly curate a pool of mentors with the skills, experience, and availability to help
                you with whatever you are working on and get the sort of insight you would never get by just watching
                a course or reading blog posts.
              </p>
            </div>

            <div className="col-md-10 offset-md-1 text-justify mb-5">
              <h4>
                <strong>Use this coupon code to get 20% off: <u>angelspartners</u></strong>
              </h4>
            </div>
          </div>

          <ButtonToolbar className="modal__footer float-right">
            <a href="https://www.growthmentor.com/" target="_blank" rel="noopener noreferrer">
              <Button
                className="modal_ok rounded icon icon--right icon--blue"
                type="button"
                color="primary"
                onClick={this.toggle}
              >
                Visit Website <SendIcon />
              </Button>
            </a>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default ModalAdvisors;
