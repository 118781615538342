/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button, Col, Row, Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CardTitle from 'reactstrap/lib/CardTitle';
import PlusIcon from 'mdi-react/PlusIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Alert from '../../../shared/components/alerts/Alert';


function LegalTab(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <CardTitle>
        <Row>
          <Col xs={12} xl={8}>
            <h3>Use these documents to help you get started!</h3>
            <p className="text-muted mt-4 mb-2">
              What is the most common mistake startup founders make during early growth? Not establishing a strong
              legal structure off the bat. While it’s tempting to dig into the vision for your company and start
              making your idea a reality, it’s important that founders pause and cover their legal bases. Below,
              we have listed the core legal documents that founders need to put into place to avoid costly legal
              battles down the road.
            </p>
          </Col>
        </Row>
        <Alert color="purple" className="alert--colored mt-5">
          <div>
            <p>
              <span className="bold-text">Want to learn more? </span>
              Get a basic overview of the legal sides that should be covered and set things straight from day 1
              with our blog resource:
              <br />
              <br />
              <a className="text-white" href="https://www.entrepreneur.com/article/253997">
                <strong>The Top 7 Legal Open Documents for Every Startup <ChevronRightIcon /></strong>
              </a>
            </p>
          </div>
        </Alert>
      </CardTitle>
      <Table responsive hover>
        <tbody>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1rvFhUdfMMi3EzcMNnL90MeCIPePMqtxserXX7pACW4Q/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Privacy Policy Template
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A Privacy Policy is a statement or a legal document that states how a company or website collects,
              handles and processes data of its customers and visitors. It explicitly describes whether that
              information is kept confidential, or is shared with or sold to third parties.
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1rvFhUdfMMi3EzcMNnL90MeCIPePMqtxserXX7pACW4Q/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1KjOC36wDqrvU-jVzmhtJXznXSno6-3bRWQj7BaOhcsg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Series Seed Investor Rights Agreement (v2)
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              The seed funding round is when investors, usually angel investors, provide funds before a startup
              becomes operational. Here is a template of a legal document and the principal terms used by investors
              with respect to a proposed Series Seed Preferred Stock of a company. (This is not legal advice.
              For informational purposes only.)

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1KjOC36wDqrvU-jVzmhtJXznXSno6-3bRWQj7BaOhcsg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1lijULckfe1HtPNHKixFlLeUOCPZ3909DHR5bQ4sl4xg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Series Seed Preferred Stock Purchase Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              The main difference between preferred and common stock is that preferred stock gives no voting rights
              to shareholders while common stocks do. Common stockholders are last in line when it comes to company
              assets, which means they will be paid out after creditors, bondholders and preferred shareholders.
              (This is not legal advice. For informational purposes only)

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1lijULckfe1HtPNHKixFlLeUOCPZ3909DHR5bQ4sl4xg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1F_YAVnyZIZ77wnXpD-K-GWivaEeyKmKOFJ4Zmsvj-gc/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Series Seed restated certificate of incorporation COI (v 2.0)
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              Restated articles of organization are simply a modified version of the original. Limited liability
              companies, also known as LLCs, are permitted to make changes to their articles of organization. When
              such changes are made, they are referred to as amendments. (This is not legal advice. For
              informational purposes only)

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1F_YAVnyZIZ77wnXpD-K-GWivaEeyKmKOFJ4Zmsvj-gc/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1yR6a9Y_VIp1vVv0OTsC6AHp6N1rUs8dzRf52psaAkAA/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Ycombinator AA Amended and Restated Certificate of Incorporation
              </a>
            </td>
          </tr>
          <tr>
            <td>
              YC version of restated articles of organization are simply a modified version of the original. Limited
              liability companies, also known as LLCs, are permitted to make changes to their articles of organization.
              When such changes are made, they are referred to as amendments.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'hhttps://docs.google.com/document/d/1yR6a9Y_VIp1vVv0OTsC6AHp6N1rUs8dzRf52psaAkAA/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1LPBg6k9P6Q204tB3CjDxledvRsEVqiTuo_kebXo9SU8/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                TechStars Convertible Note Purchase Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A convertible note is a short-term debt that converts into equity. In the context of a seed financing,
              the debt automatically converts into shares of preferred stock upon the closing of a Series
              A round of financing. Here is a template documenting the key legal terms.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1LPBg6k9P6Q204tB3CjDxledvRsEVqiTuo_kebXo9SU8/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1GxJ3pRRIVnvWS2BEvAoi3qNO9niW20F9Zn5Eqa4Ai5k/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Ycombinator AA Board Consent Form
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              Board Consent refers to the approval of a startup&#39;s board of directors. The Board Consent can take
              written form or can be effected at a meeting of the Board of Directors. (This is not legal advice.
              For informational purposes only)

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1GxJ3pRRIVnvWS2BEvAoi3qNO9niW20F9Zn5Eqa4Ai5k/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1vhL3VCBf0WgBPmYSsqXepAyONZDZnuAgYwSn3qwb038/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Ycombinator AA Investor Rights Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              An Investors Rights Agreement (IRA) is usually entered into by VCs, other investors and a startup
              during a VC financing. The IRA generally covers many different subjects including investor information
              rights, registration rights, contractual “rights of first refusal” or “pre-emptive” rights and
              various post-closing covenants of the company. (This is not legal advice. For informational purposes
              only)

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1vhL3VCBf0WgBPmYSsqXepAyONZDZnuAgYwSn3qwb038/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1a1DZpEjwHo8YlEDr7QKCrUde2QOfHcf0Rykkl5rN8xw/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Ycombinator AA Share Purchase Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              The share purchase agreement is the main document of a Series A investment. A share purchase
              agreement is a contract to transfer ownership of shares from the startup to the investor.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1a1DZpEjwHo8YlEDr7QKCrUde2QOfHcf0Rykkl5rN8xw/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/17UBI5QbUiBCJ53nCV6RiNA2ouHpdMfW_bx08TPgctyE/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Ycombinator AA Stockholder Consent form
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              The stockholders of a corporation may take any action that is permitted to be taken at a meeting by a
              written, signed and dated consent form. This template describes the terms in depth. (This is not legal
              advice. For informational purposes only)

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/17UBI5QbUiBCJ53nCV6RiNA2ouHpdMfW_bx08TPgctyE/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

LegalTab.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default LegalTab;
