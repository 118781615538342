import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

class ModalLightster extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    displayed: false,
    toggleModal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-templates xx-large"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4>SeedLegals</h4>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
        </div>
        <div className="modal__body mt-5">
          <div className="section section-pad section-bg-btoa" id="bootcamp">
            <div className="container">

              <div className="row text-center">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="section-head">
                    <h3 className="animated" data-animate="fadeInUp" data-delay=".1">
                      <strong>
                        Lightster is a user discovery and testing platform, helping companies connect with their
                        target audience to get feedback, insights, and test their product.
                      </strong>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="col-md-8 offset-md-2 mb-5 mt-3">
                <p className="lead">
                  <br />
                  <br />
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/urO68gpMURg "
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Fundraising Bootcamp Introduction"
                  />
                </p>
              </div>

              <div className="col-md-10 offset-md-1 text-justify mb-4">
                <h4>
                  Enjoy 50% off Lighster Subscription with this partnership. Use the code:
                </h4>
                <h3 className="mt-4 mb-3 text-center">ANGELS2024</h3>
                <p>
                  Startup founders use Lightster for customer discovery, prototype testing, and beta testing with
                  qualified leads as they prepare for launch or fundraising.
                  Founders have also built their early adopters community on Lightster.
                </p>
                <p>
                  From your first investment, to your Series A and beyond, SeedLegals gives you everything you need to
                  get it closed faster and more reliably than anywhere else.
                </p>
                <br />
                <ul>
                  <li>Find your audience</li>
                  <li>Gain early adopters</li>
                  <li>Get priceless insights</li>
                </ul>
              </div>
            </div>
          </div>

          <ButtonToolbar className="modal__footer float-right">
            <a
              href="https://www.lightster.co/lightster-perks/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className="modal_ok rounded icon icon--right icon--blue"
                color="primary"
                onClick={this.toggle}
              >
                Visit Website <ChevronRightIcon />
              </Button>
            </a>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default ModalLightster;
