import React, { Component } from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import ModalSearchVentures from '../modals/ModalSearchVentures';

const logo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/partnerships/searchVenturesLogo.png`;

class CardLightster extends Component {
  state = {
    modal: false,
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  render() {
    const { modal } = this.state;
    return (
      <Col md={6} xl={4} sm={12}>
        <Card>
          <CardBody className="dashboard-card dashboard-card--info" onClick={this.toggleModal}>
            <div className="dashboard-card__body">
              <img className="mt-3 mb-5 w-100" src={logo} alt="" />
              <h3 className="dashboard-card__plan">50% off</h3>
              <hr />
              <p className="dashboard-card__title">Find your target audience, fast.</p>
              <div className="dashboard-card__desc top-line text-justify mb-5 pb-4">
                <p>
                  SearchVentures is connecting the European startup market through data-driven insights and analysis.
                  Publishing a weekly newsletter read by 10,000+ investors and startup founders.
                </p>
              </div>
              <Button className="dashboard-card__button" color="primary">Discover</Button>
            </div>
          </CardBody>
        </Card>
        <ModalSearchVentures
          displayed={modal}
          toggleModal={this.toggleModal}
        />
      </Col>
    );
  }
}

export default CardLightster;
