import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import PropTypes from 'prop-types';

const logo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/partnerships/velocity.png`;

class ModalDesigner extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    displayed: false,
    toggleModal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  contact = () => {
    window.location = 'mailto:yohann@angelspartners.com?subject=Inquiry for Deck Re-Design!';
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-templates xx-large"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4>Investor Presentation Design Services</h4>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
        </div>
        <div className="modal__body mt-5">
          <div className="section section-pad section-bg-btoa" id="bootcamp">
            <div className="container">
              <div className="row text-center">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="section-head">
                    <h3 className="animated" data-animate="fadeInUp" data-delay=".1">
                      <strong>We partnered with Velocity Pitch Deck to work on your presentations.</strong>
                    </h3>
                  </div>
                </div>
                <div className="col-md-10 offset-md-1 mb-5 mt-5">
                  <div className="section-head">
                    <p className="lead animated" data-animate="fadeInUp" data-delay="0.2">
                      We work with the <strong>best designers</strong> to help you impress investors.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-10 offset-md-1 text-justify mb-4">
                <img src={logo} alt="logo" className="w-100 mt-3 mb-5" />
                <p>
                  As a startup founder, you face many obstacles and time is one of your most valuable assets.
                </p>
                <p>
                  We work with you as a strategic extension of your team following our 6-point Velocity Method to create
                  an investor-inspiring pitch deck that will save you time and greatly improve your chances of getting
                  funded, and on a faster timeline.
                </p>
                <p>
                  Our team of skilled writers and designer have helped raise over
                  $80M in funding. We are committed to your success and want to make you our next success story!
                </p>
              </div>

              <div className="col-md-10 offset-md-1 text-justify mb-4">
                <strong>Turnkey Pitch Deck Creation Services (done for you)</strong>
                <ul>
                  <li>$1500-3600, depending on scope of work</li>
                  <li>Complete writing &amp; design</li>
                  <li>Follows the Velocity Method</li>
                  <li>Multiple revisions</li>
                  <li>Ongoing support</li>
                  <li>Access to our resources and partners</li>
                </ul>

                <p>
                  We provide a 10% discount code on all of Velocity Pitch Deck services.
                  Use the code <strong>VPD10</strong>
                </p>
              </div>
            </div>
          </div>

          <ButtonToolbar className="modal__footer float-right">
            <a href="https://velocitypitchdecks.com/" target="_blank" rel="noopener noreferrer">
              <Button className="modal_cancel rounded" onClick={this.toggle}>
                Learn More
              </Button>
            </a>
            {' '}
            <Button
              className="modal_ok rounded icon icon--right icon--blue"
              type="button"
              color="primary"
              onClick={this.contact}
            >
              Contact Us <SendIcon />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default ModalDesigner;
