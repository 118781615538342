/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Table, Button,
} from 'reactstrap';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import InfoIcon from 'mdi-react/InformationOutlineIcon';
import PauseIcon from 'mdi-react/PauseIcon';
import PlayIcon from 'mdi-react/PlayIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import moment from 'moment';
import PlaySpeedIcon from 'mdi-react/PlaySpeedIcon';
import QuestionMarkCircleIcon from 'mdi-react/QuestionMarkCircleIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import StopIcon from 'mdi-react/StopIcon';
import FlagVariantOutlineIcon from 'mdi-react/FlagVariantOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

export default class InvestorCard extends PureComponent {
  state = {
    extend: false,
  }

  toggleCard = () => {
    const { extend } = this.state;
    this.setState({ extend: !extend });
  }

  renderCampaignActionsButton = (investor, index) => {
    const { toggleToSend } = this.props;
    if (investor.stopping) {
      return (
        <Button
          className="icon rounded icon--only btn-pause icon--blue"
          color="primary"
          outline
          size="sm"
        >
          <PlaySpeedIcon
            className="pause-action"
          />
        </Button>
      );
    }
    if (investor.toSend) {
      return (
        <Button
          className="icon rounded icon--only btn-pause icon--blue"
          color="primary"
          outline
          size="sm"
          onClick={() => toggleToSend(investor.contactId, 0, investor.campaignId)}
          id={`Tooltip${index}Pause${investor.id}`}
        >
          <PauseIcon />
        </Button>
      );
    }
    if (investor.email && investor.paused) {
      return (
        <Button
          className="icon rounded icon--only btn-pause icon--blue"
          color="primary"
          outline
          size="sm"
          id={`Tooltip${index}Play${investor.id}`}
          onClick={() => toggleToSend(investor.contactId, 1, investor.campaignId)}
        >
          <PlayIcon />
        </Button>
      );
    }
    return null;
  }

  render() {
    const {
      investor, clickReport, index, selectInvestorToReview,
    } = this.props;
    const { extend } = this.state;

    const extendState = extend ? 'extend' : '';

    const investorContactInfo = {
      contactId: investor.contactId,
      fullName: investor.name,
      picture: investor.picture,
      investorId: investor.id,
      campaignId: investor.campaignId,
      campaignName: investor.campaign,
      email: investor.email,
    };

    return (
      <Card>
        <CardBody
          /* eslint-disable-next-line max-len */
          className={`card-crm ${investor.nbOpens > 0 || investor.nbVisits > 0 || investor.replied ? 'extra-margin' : ''} ${extendState} ${!investor.email && 'no-email'}`}
          key={investor.id}
          onClick={() => this.toggleCard()}
        >
          <div className="card-mini-actions">
            <ChevronDownIcon className="chevron-down" />
          </div>
          <img
            src={
              investor.picture
                ? process.env.REACT_APP_AWS_ROOT
                + process.env.REACT_APP_AWS_INVESTORS_PATH
                + investor.picture
                : userIcon
            }
            onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
            alt="Investor"
            className="card-crm-pic"
          />
          <h6>
            {investor.name && investor.name.slice(0, 25)}{investor.name && investor.name.length > 25 && '..'}
          </h6>
          {investor.toSend > 0 && investor.sent > 0
            && (
              <span className="notification violet-bg notif-followup smaller">
                {investor.toSend} more followup{investor.toSend > 1 && 's'}
              </span>
            )}
          {investor.toSend > 0 && investor.sent === 0
            && (
              <span className="notification violet-bg notif-followup smaller">
                {investor.toSend} email{investor.toSend > 1 && 's'} scheduled
              </span>
            )}
          {investor.paused && investor.email
          && (
            <div className="paused-icon" id={`Tooltip${index}Paused${investor.id}`}>
              <PauseIcon />
            </div>
          )}
          {!investor.nextEmail && investor.email && !investor.paused
          && (
            <div className="paused-icon" id={`Tooltip${index}Finished${investor.id}`}>
              <StopIcon />
            </div>
          )}
          {!investor.email
          && (
            <div className="paused-icon" id={`Tooltip${index}Error${investor.id}`}>
              <CloseIcon />
            </div>
          )}
          <div className="hide card-extra">
            <div className="hide extra-info">
              <Table className="info-table">
                <tbody>
                  {investor.lastEmail
                    ? (
                      <tr>
                        <td>
                          Contacted On
                        </td>
                        <td>
                          {investor.lastEmail ? moment(investor.lastEmail).format('MM/DD') : null}
                        </td>
                      </tr>
                    ) : null}
                  {investor.nextEmail && investor.toSend
                    ? (
                      <tr>
                        <td>
                          {investor.lastEmail ? 'Next Follow up' : 'Will Be Contacted'}
                        </td>
                        <td>
                          {investor.nextEmail ? moment(investor.nextEmail).format('MM/DD') : null}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={2} className="text-center">
                          <strong>{investor.email ? 'No Follow up scheduled' : 'Invalid Email'}</strong>
                        </td>
                      </tr>
                    )}
                  {!investor.nextEmail && investor.error && investor.error.length > 0
                    && (
                      <tr>
                        <td colSpan={2} className="text-center">
                          <small>{investor.error}</small>
                        </td>
                      </tr>
                    )
                  }
                  {'time' in investor && investor.nbVisits > 0
                    ? (
                      <tr>
                        <td>
                          <span id={`Tooltip${index}TimeProfile${investor.id}`}>Profile</span>
                        </td>
                        <td>
                          {moment.utc(
                            moment.duration(investor.time, 'seconds').as('milliseconds'),
                          ).format('mm[\']:ss["]')}
                        </td>
                      </tr>
                    ) : null}
                  {'countDeck' in investor && investor.countDeck && investor.nbVisits > 0
                    ? (
                      <tr id={`Tooltip${index}Deck${investor.id}`}>
                        <td>
                          # Deck reviews
                        </td>
                        <td>
                          {investor.countDeck}
                        </td>
                      </tr>
                    ) : null}
                  {'timeDeck' in investor && investor.countDeck && investor.nbVisits > 0
                    ? (
                      <tr id={`Tooltip${index}TimeDeck${investor.id}`}>
                        <td>
                          Time spent on Deck
                        </td>
                        <td>
                          {moment.utc(
                            moment.duration(investor.timeDeck, 'seconds').as('milliseconds'),
                          ).format('mm[\']:ss["]')
                          }
                        </td>
                      </tr>
                    ) : null}
                  {'countFinancials' in investor && investor.countFinancials && investor.nbVisits > 0
                    ? (
                      <tr id={`Tooltip${index}Financials${investor.id}`}>
                        <td>
                          # Financials reviews
                        </td>
                        <td>
                          {investor.countFinancials}
                        </td>
                      </tr>
                    ) : null}
                  {'timeFinancials' in investor && investor.timeFinancials && investor.nbVisits > 0
                    ? (
                      <tr id={`Tooltip${index}TimeFinancials${investor.id}`}>
                        <td>
                          Time spent on Financials
                        </td>
                        <td>
                          {moment.utc(
                            moment.duration(investor.timeFinancials, 'seconds').as('milliseconds'),
                          ).format('mm[\']:ss["]')
                          }
                        </td>
                      </tr>
                    ) : null}
                  {'countMaterials' in investor && investor.countMaterials && investor.nbVisits > 0
                    ? (
                      <tr id={`Tooltip${index}Materials${investor.id}`}>
                        <td>
                          # Materials reviews
                        </td>
                        <td>
                          {investor.countMaterials}
                        </td>
                      </tr>
                    ) : null}
                  {investor.campaign
                    ? (
                      <tr>
                        <td colSpan={2} className="td-campaign">
                          <InformationIcon />&nbsp;&nbsp;&nbsp;
                          {investor.campaign.length <= 25 ? investor.campaign : `${investor.campaign.slice(0, 23)}..`}
                        </td>
                      </tr>
                    ) : null}
                  {investor.smartVC !== 0 && extend
                  && (
                    <span className="notification purple-bg notif-smartVC smaller" id={`smart${index}VC${investor.id}`}>
                      Smart VC <QuestionMarkCircleIcon />
                    </span>
                  )}
                </tbody>
              </Table>
            </div>
            {extend
            && (
              <div className="hide card-actions">
                <Button
                  className="icon rounded icon-only btn-review icon--blue"
                  color="primary"
                  outline
                  size="sm"
                  id={`Tooltip${index}Review${investor.id}`}
                  onClick={() => selectInvestorToReview(investorContactInfo)}
                >
                  <EmailOutlineIcon />
                </Button>
                { this.renderCampaignActionsButton(investor, index) }
                {/* <EmailIcon className="mail-action" id={`Tooltip${index}Mail${investor.id}`} /> */}
                <Button
                  className="icon rounded icon-only btn-review icon--blue mr-0"
                  color="primary"
                  outline
                  size="sm"
                  id={`Tooltip${index}Info${investor.id}`}
                  onClick={() => {
                    window.open(`${window.location.origin}/investor-db/${investor.id}`, '_blank');
                  }}
                >
                  <InfoIcon
                    className="info-icon"
                  />
                </Button>
                {investor.sent > 0
                && (
                  <small
                    className="icon rounded icon-only extinvestor__report"
                    role="presentation"
                    color="primary"
                    id={`Tooltip${index}Report${investor.id}`}
                    onMouseDown={e => clickReport(e)}
                    onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { clickReport(e); } }}
                  >
                    <FlagVariantOutlineIcon />
                  </small>
                )}
              </div>
            )}
          </div>
          {investor.nbOpens > 0 && investor.nbVisits === 0 && !investor.replied
          && (
            <div className="opened" id={`Tooltip${index}Open${investor.id}`}>
              <img src="/img/opened.svg" alt="opened" className="opened-img" />
              <span className="notification absolute green-bg">{investor.nbOpens}</span>
            </div>
          )}
          {investor.nbVisits > 0 && investor.replied === false
          && (
            <div className="opened-and-visited">
              <div className="opened-half" id={`Tooltip${index}Open${investor.id}`}>
                <img src="/img/opened.svg" alt="opened" className="opened-img" />
                <span className="notification absolute green-bg">{investor.nbOpens ? investor.nbOpens : 1}</span>
              </div>
              <div className="visited-half" id={`Tooltip${index}Visit${investor.id}`}>
                <img src="/img/visited.svg" alt="visited" className="visited-img" />
                <span className="notification absolute purple-bg">{investor.nbVisits}</span>
              </div>
            </div>
          )}
          {investor.replied === true
          && (
            <div className="replied">
              <div className="opened-third" id={`Tooltip${index}Open${investor.id}`}>
                <img src="/img/opened.svg" alt="opened" className="opened-img" />
                <span className="notification absolute green-bg">{investor.nbOpens}</span>
              </div>
              <div className="visited-third" id={`Tooltip${index}Visit${investor.id}`}>
                <img src="/img/visited.svg" alt="visited" className="visited-img" />
                <span className="notification absolute purple-bg">{investor.nbVisits}</span>
              </div>
              <div className="replied-third" id={`Tooltip${index}Replied${investor.id}`}>
                <img src="/img/replied.gif" alt="replied" className="replied-img" />
                <span className="notification absolute blue-bg">1</span>
              </div>
            </div>
          )}
        </CardBody>

        {extend
        && (
          <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Info${investor.id}`}>
            Click for more info
          </UncontrolledTooltip>
        )}
        {extend && investor.sent > 0
        && (
          <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Report${investor.id}`}>
            Report this investor
          </UncontrolledTooltip>
        )}

        {investor.nbOpens > 0
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Open${investor.id}`}>
              This investor opened your email and read it&nbsp;
              {investor.nbOpens === 1 && 'once'}{investor.nbOpens === 2 && 'twice'}
              {investor.nbOpens > 2 && `${investor.nbOpens} times`}.
            </UncontrolledTooltip>
          ) : ('')}
        {investor.nbVisits > 0
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Visit${investor.id}`}>
              This investor visited your profile&nbsp;
              {investor.nbVisits === 1 && 'once'}{investor.nbVisits === 2 && 'twice'}
              {investor.nbVisits > 2 && `${investor.nbVisits} times`}.
            </UncontrolledTooltip>
          ) : ('')}
        {investor.replied === true
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Replied${investor.id}`}>
              This investor replied to your email.
            </UncontrolledTooltip>
          ) : ('')}
        {investor.toSend && !investor.stopping && extend
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Pause${investor.id}`}>
              Do not contact this investor again.
            </UncontrolledTooltip>
          ) : ('')}
        {extend
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Review${investor.id}`}>
              Review this investor&#39;s campaign.
            </UncontrolledTooltip>
          ) : ('')}
        {!investor.nextEmail && investor.email && !investor.paused
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Finished${investor.id}`}>
              The campaign is over for this investor.
            </UncontrolledTooltip>
          ) : ('')}
        {!investor.email
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Error${investor.id}`}>
              This investor cannot be contacted
            </UncontrolledTooltip>
          ) : ('')}
        {!investor.toSend && !investor.stopping && investor.email && investor.paused && extend
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Play${investor.id}`}>
              Resume campaign for this investor.
            </UncontrolledTooltip>
          ) : ('')}

        {investor.paused && investor.email
          ? (
            <UncontrolledTooltip placement="top" target={`Tooltip${index}Paused${investor.id}`}>
              This investor is on pause and will not be contacted.
            </UncontrolledTooltip>
          ) : ('')}

        {'time' in investor && investor.nbVisits > 0
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}TimeProfile${investor.id}`}>
              This investor spent&nbsp;
              {moment.utc(moment.duration(investor.time, 'seconds').as('milliseconds')).format('mm[\']:ss["]')}
              &nbsp;visiting your profile
            </UncontrolledTooltip>
          ) : ('')}
        {'timeDeck' in investor && investor.countDeck && investor.nbVisits > 0
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}TimeDeck${investor.id}`}>
              This investor spent&nbsp;
              {moment.utc(moment.duration(investor.timeDeck, 'seconds').as('milliseconds')).format('mm[\']:ss["]')}
              &nbsp;visiting your Deck
            </UncontrolledTooltip>
          ) : ('')}
        {'timeFinancials' in investor && investor.timeFinancials && investor.nbVisits > 0
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}TimeFinancials${investor.id}`}>
              This investor spent&nbsp;
              {moment.utc(
                moment.duration(investor.timeFinancials, 'seconds').as('milliseconds'),
              ).format('mm[\']:ss["]')}
              &nbsp;visiting your Financials
            </UncontrolledTooltip>
          ) : ('')}
        {'countDeck' in investor && investor.countDeck && investor.nbVisits > 0
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Deck${investor.id}`}>
              This investor reviewed your deck {investor.countDeck === 1 && 'once'}
              {investor.countDeck === 2 && 'twice'}
              {investor.countDeck > 2 && `${investor.countDeck} times`}.
            </UncontrolledTooltip>
          ) : ('')}
        {'countMaterials' in investor && investor.countMaterials && investor.nbVisits > 0
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Materials${investor.id}`}>
              This investor reviewed your materials {investor.countMaterials === 1 && 'once'}
              {investor.countMaterials === 2 && 'twice'}
              {investor.countMaterials > 2 && `${investor.countMaterials} times`}.
            </UncontrolledTooltip>
          ) : ('')}
        {'countFinancials' in investor && investor.countFinancials && investor.nbVisits > 0
          ? (
            <UncontrolledTooltip placement="bottom" target={`Tooltip${index}Financials${investor.id}`}>
              This investor reviewed your financials {investor.countFinancials === 1 && 'once'}
              {investor.countFinancials === 2 && 'twice'}
              {investor.countFinancials > 2 && `${investor.countFinancials} times`}.
            </UncontrolledTooltip>
          ) : ('')}
        {investor.smartVC !== 0 && extend
          ? (
            <UncontrolledTooltip placement="bottom" target={`smart${index}VC${investor.id}`}>
              SmartVC is enabled for this investor. This investor and all other member of the VC firm will be contacted
              one after the other.
            </UncontrolledTooltip>
          ) : ('')}
      </Card>
    );
  }
}

InvestorCard.propTypes = {
  investor: PropTypes.shape({
    id: PropTypes.number,
    contactId: PropTypes.number,
    name: PropTypes.string,
    picture: PropTypes.string,
    campaign: PropTypes.string,
    lastEmail: PropTypes.string,
    nextEmail: PropTypes.string,
    replied: PropTypes.bool,
    nbOpens: PropTypes.number,
    nbVisits: PropTypes.number,
    time: PropTypes.number,
    timeDeck: PropTypes.number,
    timeFinancials: PropTypes.number,
    countDeck: PropTypes.number,
    countFinancials: PropTypes.number,
    countMaterials: PropTypes.number,
    lastVisited: PropTypes.string,
    sent: PropTypes.bool,
    toSend: PropTypes.number,
    stopping: PropTypes.bool,
  }).isRequired,
  toggleToSend: PropTypes.func.isRequired,
  clickReport: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  selectInvestorToReview: PropTypes.func.isRequired,
};
