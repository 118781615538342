/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';

class StartupRoundTab extends PureComponent {
  static propTypes = {
    valuation: PropTypes.number.isRequired,
    raising: PropTypes.number.isRequired,
    raised: PropTypes.number.isRequired,
    minimum: PropTypes.number,
    revenues: PropTypes.number.isRequired,
    dateClose: PropTypes.string,
    currency: PropTypes.string,
  };

  static defaultProps = {
    dateClose: null,
    minimum: null,
    currency: null,
  };

  render() {
    const {
      valuation, raised, currency,
      raising, minimum, revenues, dateClose,
    } = this.props;

    return (
      <div className="startup__round">
        <h2>About this round</h2>
        <Table className="table--bordered" responsive hover>
          <tbody>
            <tr>
              <td>Raising</td>
              <td>
                <CurrencyFormat
                  value={raising}
                  displayType="text"
                  thousandSeparator
                  prefix={`${currency}`}
                />
              </td>
            </tr>
            <tr>
              <td>Raised</td>
              <td>
                <CurrencyFormat
                  value={raised}
                  displayType="text"
                  thousandSeparator
                  prefix={`${currency}`}
                />
              </td>
            </tr>
            <tr>
              <td>TTM Revenues</td>
              <td>
                <CurrencyFormat
                  value={revenues}
                  displayType="text"
                  thousandSeparator
                  prefix={`${currency}`}
                />
              </td>
            </tr>
            {minimum > 0
              && (
                <tr>
                  <td>Minimum Investment</td>
                  <td>
                    <CurrencyFormat
                      value={minimum}
                      displayType="text"
                      thousandSeparator
                      prefix={`${currency}`}
                    />
                  </td>
                </tr>
              )}
            {valuation > 0
            && (
              <tr>
                <td>Pre-Money Valuation</td>
                <td>
                  <CurrencyFormat
                    value={valuation}
                    displayType="text"
                    thousandSeparator
                    prefix={`${currency}`}
                  />
                </td>
              </tr>
            )}
            {dateClose
              && (
                <tr>
                  <td>Round Closing on</td>
                  <td>{dateClose}</td>
                </tr>
              )}
          </tbody>
        </Table>
      </div>
    );
  }
}


export default StartupRoundTab;
