import React, { PureComponent } from 'react';
import {
  Col, Card, CardBody,
} from 'reactstrap';
import axios from 'axios';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import WizardFormThree from './WizardFormThree';
import ModalConfirm from '../../../shared/components/modals/ModalConfirm';
import Modal from '../../../shared/components/modals/Modal';

export default class WizardForm extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    reset: PropTypes.bool,
    initialValues: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      uniqueRecipients: PropTypes.bool,
      smartVC: PropTypes.bool,
      emailWarmup: PropTypes.bool,
      sendOnSaturday: PropTypes.bool,
      sendOnSunday: PropTypes.bool,
      sendOnFriday: PropTypes.bool,
      sendOnMonday: PropTypes.bool,
      sendOnThursday: PropTypes.bool,
      sendOnTuesday: PropTypes.bool,
      sendOnWednesday: PropTypes.bool,
      stopOnOpen: PropTypes.bool,
      stopOnResponse: PropTypes.bool,
      stopOnVisit: PropTypes.bool,
      time: PropTypes.string,
      timezoneLabel: PropTypes.string,
      timezoneValue: PropTypes.string,
      templates: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        delay: PropTypes.number,
        id: PropTypes.number,
        position: PropTypes.number,
        title: PropTypes.string,
      })),
    }),
  };

  static defaultProps = {
    initialValues: null,
    reset: false,
  };

  constructor() {
    super();
    this.state = {
      step: 0,
      page: 1,
      modalConfirm: false,
      modalUpgrade: false,
      messages: [],
      improvements: 0,
      campaignId: 0,
      dataTemplates: '',
      path: window.location.pathname,
      stateValues: null,
    };
  }

  componentDidMount() {
    const { path } = this.state;
    if (path.startsWith('/campaigns/edit')) {
      this.setState({ campaignId: parseInt(path.substring(16), 10) });
    }
    this.setState({ step: 0 });
    window.onbeforeunload = () => 'You have unsaved changes, are you sure you want to leave?'
                                          + 'To save you changes, submit your campaign on step 3.';
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  nextPage = (values) => {
    const {
      step, campaignId, dataTemplates,
    } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user.isCustomer && user.campaigns >= 1 && !window.location.href.includes('edit')) {
      this.setState({ modalUpgrade: true });
      return;
    }
    if (step === 0) {
      axios.post('campaigns/add',
        queryString.stringify({
          name: values.name,
          id: campaignId || null,
          stopOnResponse: values.stopOnResponse ? values.stopOnResponse : false,
          stopOnOpen: values.stopOnOpen,
          stopOnVisit: values.stopOnVisit,
          uniqueRecipients: values.uniqueRecipients,
          smartVC: 0,
        }))
        .then((response) => {
          if (response.data && response.data.success) {
            this.setState({ campaignId: response.data.campaignId, stateValues: values });
          }
        })
        .catch((error) => {
          if (error.response.status === 402) {
            this.setState({ modalUpgrade: true });
          }
        });
    } else if (step === 1) {
      // weird looking keys for symfony form
      const templates = {};
      const sentTemplates = dataTemplates || values.templates;
      if (typeof sentTemplates !== typeof undefined) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < sentTemplates.length; i++) {
          templates[`templates[${i}][title]`] = sentTemplates[i].title;
          templates[`templates[${i}][content]`] = sentTemplates[i].content.replace(/<\/?span[^>]*>/g, '');
          templates[`templates[${i}][position]`] = i;
          templates[`templates[${i}][delay]`] = i > 0 ? sentTemplates[i].delay : 0;
        }
      }
      axios.post('campaigns/editTemplates', queryString.stringify({ ...templates, id: campaignId }));
    }
    this.setState({ step: step + 1, modalConfirm: false });
    this.setState(prevState => ({ page: prevState.page + 1 }));
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  };

  previousPage = () => {
    const { campaignId } = this.state;
    this.setState(prevState => ({ page: prevState.page - 1, campaignId }));
  };

  replaceToCamelCase = (data, string) => {
    const regex = new RegExp(string, 'i');
    return data.replaceAll(
      data.match(regex)[0], string,
    );
  };

  verifyData = (values) => {
    const docsend = 'docsend.com';
    const google = 'google.com';
    const AngelsPartners = 'Angels Partners';
    const website = 'angelspartners.com';
    const investorFirstName = 'investorFirstName';
    const investorLastName = 'investorLastName';
    const investorVertical = 'investorVertical';
    const investorCity = 'investorCity';
    const investorFund = 'investorFund';
    const startupName = 'startupName';
    const signature = 'signature';
    const lastDeal = 'lastDeal';
    const trackingLink = 'trackingLink';
    const varTemplates = [
      investorFirstName,
      investorLastName,
      investorVertical,
      investorCity,
      investorFund,
      startupName,
      signature,
      lastDeal,
      trackingLink,
    ];
    const messages = [];
    let improvements = 0;
    let variables = false;
    let varInvestorFirstName = false;
    let varInvestorCity = false;
    let varLastDeal = false;
    let varInvestorVertical = false;

    for (let i = 0; i < values.templates.length; i += 1) {
      if (typeof values.templates[i] !== typeof undefined && typeof values.templates[i].content !== typeof undefined) {
        // Check if one of the template contains a docsend link or google drive
        if (values.templates[i].content.includes(docsend) !== false) {
          messages.push('DocSend');
        } else if (values.templates[i].content.includes(google) !== false) {
          messages.push('Google Drive');
        } else if (values.templates[i].content.toLowerCase().includes(trackingLink.toLowerCase()) === false) {
          // Check if one of the template contains {{trackingLink}}
          messages.push('trackingLink');
        }

        // Check if one of the template mentions Angels Partners
        if (values.templates[i].content.includes(AngelsPartners) !== false) {
          messages.push('Angels Partners');
        }

        // Check if one of the template contains a tracking link
        if (values.templates[i].content.includes(website) !== false) {
          messages.push('website');
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const varTemplate of varTemplates) {
          if (values.templates[i].content.toLowerCase().includes(varTemplate.toLowerCase()) !== false) {
            // eslint-disable-next-line no-param-reassign
            values.templates[i].content = this.replaceToCamelCase(values.templates[i].content, varTemplate);
          }
          if (values.templates[i].title.toLowerCase().includes(varTemplate.toLowerCase()) !== false) {
            // eslint-disable-next-line no-param-reassign
            values.templates[i].title = this.replaceToCamelCase(values.templates[i].title, varTemplate);
          }
        }

        // Check if one of the template content contains {{firstName}}
        if (values.templates[i].content.includes(investorFirstName) !== false) {
          varInvestorFirstName = true;
        }

        // Check if one of the template contains {{investorVertical}}
        if (values.templates[i].content.includes(investorVertical) !== false) {
          varInvestorVertical = true;
        }

        // Check if one of the template contains {{investorCity}}
        if (values.templates[i].content.includes(investorCity) !== false) {
          varInvestorCity = true;
        }

        // Check if one of the template contains {{lastDeal}}
        if (values.templates[i].content.includes(lastDeal) !== false) {
          varLastDeal = true;
        }

        // Check if one of the is too long
        if (values.templates[i].content.replace(/<[^>]*>?/gm, '').length > 2000) {
          messages.push('messageLength');
          improvements += 35;
        }
      }
    }

    // If some of the variables are missing, then ask the customer to add them:
    if (!varInvestorFirstName) {
      messages.push('investorFirstName');
      variables = true;
    }
    if (!varInvestorCity) {
      messages.push('investorCity');
      variables = true;
    }
    if (!varLastDeal) {
      messages.push('lastDeal');
      variables = true;
    }
    if (!varInvestorVertical) {
      messages.push('investorVertical');
      variables = true;
    }

    if (variables) {
      improvements += 75;
    }

    // Check if there are follow ups
    if (values.templates.length < 3) {
      messages.push('templates');
      improvements += 200;
    }

    this.setState({ dataTemplates: values.templates });

    if (messages.length === 0) {
      this.nextPage(values);
    } else {
      this.setState({
        improvements, messages, modalConfirm: true,
      });
    }
  }

  toggleModalConfirm = () => {
    this.setState({ modalConfirm: false });
  }

  toggleModalUpgrade = () => {
    this.setState({ modalUpgrade: false });
  }

  render() {
    const {
      onSubmit, initialValues, reset, loading,
    } = this.props;
    const {
      page, modalConfirm, messages, improvements, campaignId, modalUpgrade, stateValues,
    } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <span className="wizard_step_counter">01</span>
                <p>Basics</p>
              </div>
              <svg
                className="wizard__steps_divider"
                width="549"
                height="2"
                viewBox="0 0 549 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 1H549" stroke="#8D9AA1" />
              </svg>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <span className="wizard_step_counter">02</span>
                <p>Templates</p>
              </div>
              <svg
                className="wizard__steps_divider"
                width="549"
                height="2"
                viewBox="0 0 549 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 1H549" stroke="#8D9AA1" />
              </svg>
              <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                <span className="wizard_step_counter">03</span>
                <p>Schedules</p>
              </div>
            </div>
            <div className="wizard__form-wrapper">
              {page === 1
              && (
                <WizardFormOne
                  onSubmit={values => this.nextPage(values)}
                  initialValues={initialValues || stateValues}
                  campaignId={campaignId}
                  resetForm={reset && !campaignId}
                />
              )}
              {page === 2
              && (
                <WizardFormTwo
                  previousPage={this.previousPage}
                  onSubmit={values => this.verifyData(values)}
                  initialValues={initialValues}
                  campaignId={campaignId}
                />
              )}
              {page === 3
              && (
                <WizardFormThree
                  previousPage={this.previousPage}
                  loading={loading}
                  initialValues={initialValues}
                  onSubmit={values => onSubmit(values, campaignId)}
                />
              )}
            </div>
          </CardBody>
        </Card>
        <ModalConfirm
          messages={messages}
          improvements={improvements}
          onSuccess={this.nextPage}
          displayed={modalConfirm}
          toggleModal={this.toggleModalConfirm}
        />
        <Modal
          color="primary"
          title="Upgrade Required!"
          colored
          displayed={modalUpgrade}
          toggleModal={() => this.toggleModalUpgrade()}
          message={`Please upgrade your account to a paid plan if you wish to create more campaigns.
           You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
           Pricing Page</a>.`}
        />
      </Col>
    );
  }
}
