import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '@material-ui/core';
import {
  Button, CardBody, Col, Row,
} from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import PropTypes from 'prop-types';
import YoutubeEmbed from '../../../shared/components/YoutubeEmbed';

const FillerElement = ({ error, loaded, user }) => (
  (error && loaded)
    ? (
      <Card elevation={0} className="error-results text-center">
        <CardBody>
          <h5>{error}</h5>
          {!user.completion
          && (
            <div>
              <br />
              <a
                href={`${process.env.REACT_APP_DOMAIN_URL}/create/startup`}
                className="col-xs-12 d-block text-center"
              >
                <Button className="icon center" color="success">
                  <p>
                    <PlusIcon />
                    Create Startup Profile
                  </p>
                </Button>
              </a>
            </div>
          )}
        </CardBody>
      </Card>
    ) : (
      <Card elevation={0} className="Campaigns__empty">
        <Row>
          <Col xs={12} md={6} xl={4}>
            <div className="Campaigns__steps Campaigns__step-1">
              <h2>Step 1</h2>
              <h3>Create your campaign</h3>
              <p>
                Use our pre-made templates and set up your campaigns in details to maximize your chances of getting
                responses from investors.
              </p>
              <Link to="/campaigns/create">
                <Button className="icon icon--right rounded" color="white" outline>
                  New Campaign
                  <PlusIcon />
                </Button>
              </Link>
            </div>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <div className="Campaigns__steps Campaigns__step-2">
              <h2>Step 2</h2>
              <h3>Prospect for investors</h3>
              <p>
                Find investors who are a good fit for your startup based on attributes such as their preferences and
                deal history.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 0 }}>
            <div className="Campaigns__steps Campaigns__step-3">
              <h2>Step 3</h2>
              <h3>Contact & Automate</h3>
              <p>
                Automate your outreach workflow: Pitch & email investors, track engagement and manage more
                relationships with investors, all automated.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
            <h3 className="mb-4 mt-5 text-center">Learn how to make the best use of our automated campaigns</h3>
            <YoutubeEmbed embedId="sAL-kPH0xAM" />
            <br />
          </Col>
        </Row>
      </Card>
    )
);

FillerElement.propTypes = {
  error: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    completion: PropTypes.number.isRequired,
  }).isRequired,
};

export default FillerElement;
