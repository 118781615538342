import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import PauseIcon from 'mdi-react/PauseIcon';
import StopIcon from 'mdi-react/StopIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import PlayIcon from 'mdi-react/PlayIcon';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const InvestorsList = ({
  noIcon,
  investorsContactInfo,
  investorContactInfo,
  toggleTemplate,
}) => (
  <div className="modal__list__investors">
    <ul className="modal__list multiple">
      {investorsContactInfo && investorsContactInfo.map(investor => (
        <li key={`key${investor.id}`}>
          <Button
            className={`icon icon--right icon--blue 
                        ${investor.contactId === investorContactInfo.contactId ? 'btn-secondary' : ''}`}
            outline
            onClick={() => toggleTemplate(investor, null, null, false)}
            onKeyDown={() => toggleTemplate(investor, null, null, false)}
          >
            <img
              src={investor.picture
                ? process.env.REACT_APP_AWS_ROOT
                + process.env.REACT_APP_AWS_INVESTORS_PATH
                + investor.picture
                : userIcon
              }
              alt="investor"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userIcon;
              }}
            />
            {!investor.toSend && investor.nextEmail && investor.email && !noIcon
              ? (
                <div className="paused-icon" id={`TooltipPaused${investor.id}`}>
                  <PauseIcon className="list-multi" />
                </div>
              ) : ('')}
            {!investor.nextEmail && investor.email && !noIcon
              ? (
                <div className="paused-icon" id={`TooltipFinished${investor.id}`}>
                  <StopIcon className="list-multi" />
                </div>
              ) : ('')}
            {!investor.email
              ? (
                <div className="paused-icon" id={`TooltipError${investor.id}`}>
                  <CloseIcon className="list-multi" />
                </div>
              ) : ('')}
            {investor.email && investor.nextEmail && investor.toSend && !noIcon
              ? (
                <div className="paused-icon" id={`TooltipPlay${investor.id}`}>
                  <PlayIcon className="list-multi" />
                </div>
              ) : ('')}
            <h5>{investor.fullName ? investor.fullName.replace(' ', '\r\n') : investor.name.replace(' ', '\r\n')}</h5>
            <ChevronRightIcon />
          </Button>
          {!investor.nextEmail && investor.email && !noIcon
            ? (
              <UncontrolledTooltip placement="bottom" target={`TooltipFinished${investor.id}`}>
                The campaign is over for this investor.
              </UncontrolledTooltip>
            ) : ('')}
          {!investor.email
            ? (
              <UncontrolledTooltip placement="bottom" target={`TooltipError${investor.id}`}>
                This investor cannot be contacted
              </UncontrolledTooltip>
            ) : ('')}
          {!investor.toSend && investor.nextEmail && investor.email && !noIcon
            ? (
              <UncontrolledTooltip placement="top" target={`TooltipPaused${investor.id}`}>
                This investor will not be contacted again.
              </UncontrolledTooltip>
            ) : ('')}
          {investor.email && investor.nextEmail && investor.toSend && !noIcon
            ? (
              <UncontrolledTooltip placement="top" target={`TooltipPlay${investor.id}`}>
                This investor is currently in a campaign
              </UncontrolledTooltip>
            ) : ('')}
        </li>
      ))}
    </ul>
  </div>
);

InvestorsList.defaultProps = {
  noIcon: false,
};

InvestorsList.propTypes = {
  noIcon: PropTypes.bool,
  investorsContactInfo: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      fullName: PropTypes.string,
      email: PropTypes.bool,
      picture: PropTypes.string,
      inCampaign: PropTypes.bool,
    }),
  ).isRequired,
  investorContactInfo: PropTypes.shape({
    contactId: PropTypes.number,
    fullName: PropTypes.string,
    investorId: PropTypes.number,
    email: PropTypes.bool,
    inCampaign: PropTypes.bool,
    campaignName: PropTypes.string,
    contacted: PropTypes.bool,
    contactedOn: PropTypes.string,
  }).isRequired,
  toggleTemplate: PropTypes.func.isRequired,
};

export default InvestorsList;
