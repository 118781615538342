import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import InfoCircleOutlineIcon from 'mdi-react/InfoCircleOutlineIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

class ModalComponent extends PureComponent {
  static propTypes = {
    pings: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
    chat: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    message: '',
    displayed: false,
    toggleModal: null,
    pings: false,
    chat: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  endTrialNow = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    // eslint-disable-next-line no-alert
    if (window.confirm('You are going to be charged now. Are you sure?') === true) {
      window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/payment/${user.plan}?process=true`;
    }
  };

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const {
      title, message, pings, chat,
    } = this.props;
    const { modal } = this.state;

    let button = '';
    if (chat) {
      button = 'Message this investor now';
    } else if (pings) {
      button = 'Contact Investors Now';
    } else {
      button = 'Start Campaigns Now';
    }

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className="modal-dialog--colored modal-dialog--primary x-large"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            <InfoCircleOutlineIcon className="modal__title-icon" />
            <h4 className="text-modal modal__title">{title}</h4>
          </div>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
          <div className="modal__body" dangerouslySetInnerHTML={{ __html: message }} />
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel rounded" onClick={this.toggle}>Wait</Button>{' '}
            <Button className="modal_ok rounded icon icon--right icon--blue" color="white" onClick={this.endTrialNow}>
              {button}
              <ChevronRightIcon />
            </Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(ModalComponent);
