import React, { PureComponent } from 'react';
import {
  Card, Nav, NavItem, NavLink,
  Progress, TabContent, TabPane,
} from 'reactstrap';
import axios from 'axios';
import queryString from 'query-string';
import { stringify } from 'qs';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import NotificationSystem from 'rc-notification';
import InvestorInformation from './InvestorInformation';
import InvestorPicture from './InvestorPicture';
import AccountSettings from './AccountSettings';
import { updateAvatar, updateName } from '../../../redux/actions/userActions';
import { BasicNotification, LinkNotification } from '../../../shared/components/notifications/Notification';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class ProfileTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      downloading: false,
    };
  }

  componentDidMount = () => {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  };

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  showNotifSuccess = () => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message="Your information has been updated successfully"
    />,
    position: 'right-up',
  });

  showNotifWarning = errorMessage => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      message={errorMessage}
    />,
    position: 'right-up',
  });

  updateProfile = (values) => {
    const { dispatch, onInfoUpdated, history } = this.props;
    this.setState({ downloading: true });

    axios.post('/investors/myProfile', queryString.stringify({
      firstName: values.firstName,
      lastName: values.lastName,
      location_city: values.location_city,
      location_country: values.location_country,
      company: values.company,
      companyRole: values.companyRole,
      email: values.email,
      linkedin: values.linkedin,
      password: values.password,
      new_password: values.new_password,
      new_password_check: values.new_password_check,
    })).then((response) => {
      this.setState({ downloading: false });
      if (response.data && response.data.success) {
        this.showNotifSuccess();
        dispatch(updateName(values.firstName, values.lastName, values.email));

        // Dispatch the update to the site (profile basic)
        onInfoUpdated(values.firstName, values.lastName, values.location_city, values.location_country);

        // If we change the user's email, we have to logout login again.
        if (response.data.email_changed) {
          // eslint-disable-next-line no-alert
          window.alert('You have changed the email address associated with your account.'
            + 'For security reason, please log in again.');
          history.push('/logout');
        }
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  updateInformation = (values) => {
    this.setState({ downloading: true });

    axios.post('/investors/myInformation', stringify({
      description: values.description,
      expertise: values.expertise,
      questions: values.questions,
      investmentMin: values.investment_min,
      investmentMax: values.investment_max,
      stages: values.stages ? values.stages.map(e => e.value) : null,
      investment_countries: values.investment_countries ? values.investment_countries.map(e => e.value) : null,
      categories: values.categories ? values.categories.map(e => e.value) : null,
    })).then((response) => {
      this.setState({ downloading: false });
      if (response.data) {
        this.showNotifSuccess();
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  uploadPicture = (e) => {
    const { onPictureUpdated, dispatch } = this.props;

    const formData = new FormData();
    formData.append('picture', e.files[0]);
    this.setState({ downloading: true });

    axios.post('/investors/picture', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      this.setState({ downloading: false }, () => {
        if (response.data) {
          this.showNotifSuccess();

          // if everything went fine then update our sessionstorage
          const user = JSON.parse(localStorage.getItem('user'));
          user.avatar = response.data; // contains the logo src
          localStorage.setItem('user', JSON.stringify(user));

          // Dispatch the update to the site (top right hand corner + profile basic)
          onPictureUpdated(response.data);
          dispatch(updateAvatar(response.data));
        }
      });
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
    // window.alert(`You submitted:\n\n${JSON.stringify(e, null, 2)}`);
  };

  dateToYMD = (date) => {
    const d = date.getDate();
    const m = date.getMonth() + 1; // Month from 0 to 11
    const y = date.getFullYear();
    return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      axios.post('/investors/trackSettings', queryString.stringify({ tab })).then();
    }
  };

  render() {
    const {
      activeTab,
      downloading,
    } = this.state;
    const {
      profile, categories, stages, countries,
    } = this.props;

    const initialValuesAccount = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      location_city: profile.location_city,
      location_country: profile.location_country,
      email: profile.email,
      company: profile.company,
      companyRole: profile.companyRole,
      linkedin: profile.linkedin,
      password: '',
      new_password: '',
      new_password_check: '',
    };

    const initialValuesInformation = {
      description: profile.description,
      expertise: profile.expertise,
      questions: profile.questions,
      investment_min: profile.investment_min,
      investment_max: profile.investment_max,
      investment_countries: profile.investment_countries,
      stages: profile.stages,
      categories: profile.categories,
    };

    let downloadBlock = null;
    if (downloading) {
      downloadBlock = (
        <Progress animated value={100} className="progress-wrap--middle">
          Please wait..
        </Progress>
      );
    }

    return (
      <Card>
        <div className="tabs tabs--vertical">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  Account
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Information
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  Picture
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="pl-5">
              <TabPane tabId="1">
                <AccountSettings
                  onSubmit={val => this.updateProfile(val)}
                  initialValues={initialValuesAccount}
                  enableReinitialize
                />
                { downloadBlock }
              </TabPane>
              <TabPane tabId="2">
                <InvestorInformation
                  onSubmit={val => this.updateInformation(val)}
                  initialValues={initialValuesInformation}
                  stages={stages}
                  categories={categories}
                  isCustomer={profile.isCustomer}
                  questionsEnabled={profile.questionsEnabled}
                  countries={countries}
                  enableReinitialize
                />
                { downloadBlock }
              </TabPane>
              <TabPane tabId="3">
                <InvestorPicture
                  onSubmit={this.uploadPicture}
                  picture={profile.picture}
                />
                { downloadBlock }
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    );
  }
}

export default withRouter(connect(null)(ProfileTabs));

ProfileTabs.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    picture: PropTypes.string,
    locationCity: PropTypes.string,
    locationCountry: PropTypes.string,
    planType: PropTypes.string,
    isCustomer: PropTypes.bool,
    questionsEnabled: PropTypes.bool,
    description: PropTypes.string,
    expertise: PropTypes.string,
    company: PropTypes.string,
    companyRole: PropTypes.string,
    linkedin: PropTypes.string,
    location_city: PropTypes.string,
    location_country: PropTypes.string,
    investment_min: PropTypes.number,
    investment_max: PropTypes.number,
    investment_countries: PropTypes.array,
    investment_stages: PropTypes.array,
    categories: PropTypes.array,
  }).isRequired,
  categories: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    picture: PropTypes.string,
  }).isRequired,
  stages: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  countries: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  onPictureUpdated: PropTypes.func.isRequired,
  onInfoUpdated: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
