import PropTypes from 'prop-types';

const {
  string, arrayOf, shape, boolean, integer,
} = PropTypes;

export const CurrentUserProps = shape({
  avatar: string,
  name: string,
});

export const MessageProps = shape({
  notification: boolean,
  name: string,
  message: string,
  date: string,
});

export const ContactProps = shape({
  id: integer,
  avatar: string,
  name: string,
  post: string,
  started: string,
  participant_id: integer,
  messages: arrayOf(MessageProps),
});

export const ContactsProps = arrayOf(ContactProps);
