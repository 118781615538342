import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import PropTypes from 'prop-types';

class ModalSeedLegals extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    displayed: false,
    toggleModal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  contact = () => {
    window.location = 'mailto:yohann@angelspartners.com?subject=Inquiry for Fundraising Bootcamp!';
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-templates xx-large"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4>Fundraising Bootcamp</h4>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
        </div>
        <div className="modal__body mt-5">
          <div className="section section-pad section-bg-btoa" id="bootcamp">
            <div className="container">

              <div className="row text-center">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="section-head">
                    <h3 className="animated" data-animate="fadeInUp" data-delay=".1">
                      <strong>Would you prefer our full service option?</strong>
                    </h3>
                    <p className="lead animated" data-animate="fadeInUp" data-delay="0.2">
                      We partner with <strong>The Fundraising Bootcamp</strong> to get you the unfair advantage
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-8 offset-md-2 mb-5 mt-3">
                <p className="lead">
                  <br />
                  <br />
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/urO68gpMURg "
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Fundraising Bootcamp Introduction"
                  />
                </p>
              </div>

              <div className="col-md-10 offset-md-1 text-justify mb-4">
                <p>
                  We know fundraising is overwhelming. It&apos;s complicated, tedious, repetitive and extremely
                  frustrating; most times you don&apos;t even know what you&apos;re doing wrong.
                  But as the founder or CEO, this is your new job, so you might as well learn it properly and become a
                  pro at it, it&apos;ll save you months of repeat failures and pain.
                </p>
                <p>
                  That&apos;s why we partnered with the best in business. The Fundraising Bootcamp is a comprehensive,
                  action-packed programme run by experts
                  (all fundraising pros, tech CEOs, real investors) that gives you the practical tools and get the job
                  done: master the process, learn the skills and use
                  the tools used by pros. It&apos;s like no other programme and, just like you, it&apos;s focused on
                  closing that round fast.
                </p>
              </div>

              <div className="col-md-12 text-justify">
                <div className="row">
                  <div className="col-md-5 offset-md-1">
                    <h4 className="mb-3">You will learn:</h4>
                    <ul className="styled">
                      <li>
                        Not all investors and funds are worth your time. Learn how to assess them
                      </li>
                      <li>
                        How to control the timeline and accelerate your funding process
                      </li>
                      <li>
                        What investors never tell you, but expect you to know
                      </li>
                      <li>
                        Spot when you&apos;re the pawn and being used
                      </li>
                      <li>
                        The reasons investors say NO and why, and how you can avoid them
                      </li>
                      <li>
                        The top 5 term sheet clauses that will kill you
                      </li>
                      <li>
                        How to get to meet investors you can&apos;t get introduced to
                      </li>
                      <li>
                        ... and much more.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5 offset-md-1">
                    <h4 className="mb-3">You will get:</h4>
                    <ul className="styled">
                      <li>
                        The investor Insider knowledge you can&apos;t find online or in books
                      </li>
                      <li>
                        Investment-ready
                      </li>
                      <li>
                        Pitch perfect
                      </li>
                      <li>
                        Access the tools M&A bankers and pro fundraisers use
                      </li>
                      <li>
                        Tips and tricks of the trade
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ButtonToolbar className="modal__footer float-right">
            <a
              href="https://fundraising-bootcamp.com/programme?utm_campaign=Partners&utm_source=y74m99&utm_medium=link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="modal_cancel rounded" onClick={this.toggle}>
                Visit Website
              </Button>
            </a>
            {' '}
            <Button
              className="modal_ok rounded icon icon--right icon--blue"
              type="button"
              color="primary"
              onClick={this.contact}
            >
              Contact <SendIcon />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default ModalSeedLegals;
