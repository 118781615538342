import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const TopbarMenuLinks = ({
  title, icon, path, onClick, isNew,
}) => (
  <Link className="topbar__link" to={path} onClick={onClick}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <p className="topbar__link-title">{title}
      {isNew && <span className="topbar__link-new">NEW</span>}
    </p>
  </Link>
);

TopbarMenuLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

TopbarMenuLinks.defaultProps = {
  isNew: false,
};

export default TopbarMenuLinks;
