/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import validate from '../../formValidate/video';


const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
};

class StartupVideo extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    remove: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      video: PropTypes.string,
    }).isRequired,
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting, initialValues, remove,
    } = this.props;

    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <h5 className="bold-text">INSERT A VIDEO</h5>
        <div className="margin10">
          {initialValues.video && (
            <ReactPlayer
              className="profile__video"
              url={initialValues.video}
              width="100%"
              light
            />
          )}
          <span className="material-form__label"><h5>Insert Video Link Of Your Team (Recommended)</h5></span>
          <Field
            name="video"
            component={renderTextField}
            placeholder="Your video will be displayed on your summary. This is very attractive to investors."
            type="text"
            multiline
          />
          <span className="form__form-group-description">
            To embed a video, you must provide a link from
            YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, or DailyMotion.
          </span>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
            Save
            <ChevronRightIcon />
          </Button>
          <Button className="rounded mt-5 mr-5" type="button" onClick={reset} disabled={pristine || submitting}>
            Cancel
          </Button>
          <Button className="rounded mt-5 float-right text-red" type="button" onClick={remove}>
            Remove
            <CloseIcon />
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}


export default reduxForm({
  form: 'startup_video_form', // a unique identifier for this form
  validate,
})(StartupVideo);
