/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button, Col, Row, Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CardTitle from 'reactstrap/lib/CardTitle';
import PlusIcon from 'mdi-react/PlusIcon';

function AdvisoryTab(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <Row>
        <Col xs={12}>
          <CardTitle>
            <Row>
              <Col xs={12} lg={8}>
                <h3>Use these documents to help you get started!</h3>
                <p className="text-muted mt-4">
                  So, you have decided to build an Advisory Board for your Startup. Excellent!
                  An Advisory Board can be an effective way to gain insight, guidance and connections for relatively
                  little costs.
                </p>
                <p className="text-muted mb-2">
                  At Angels Partners, you can search for investments, but you can also find valuable advice and
                  connections if you know how to ask. Use the documents in this section to properly structure your
                  Advisory Board.
                </p>
              </Col>
            </Row>
          </CardTitle>
          <Table responsive hover>
            <tbody>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1dKGLeuypI0VQ8SZ_9HGO8VDNZ7tcURqQJt1LhUVS2Z8/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Advisor Request Email Template
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  An Advisor is an individual who provides time, expertise and connections for a startup (which may or
                  may not be a company yet). This template gives you an idea of an Advisor request email.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1dKGLeuypI0VQ8SZ_9HGO8VDNZ7tcURqQJt1LhUVS2Z8/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button className="btn rounded icon btn-slides mt-4">
                      Open Slides
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1EGGc502jWXG96O5HFj7xD-gdk7g5DMr8s6lXbRxvfoE/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Advisory Board Offer Letter Sample
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  This template gives you an outline of a board offer letter and its key legal terms.
                  (This is not legal advice, but for informational purposes only)
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1EGGc502jWXG96O5HFj7xD-gdk7g5DMr8s6lXbRxvfoE/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1ludQI8KuwGU0CU8AiA9iNjOZEs4gKJlgyTsG5P9tiQ4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Board Target List Worksheet
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  The personal traits of the Board Directors impact decision processes. It is important to
                  have different personality types on the Board and still be able to manage discussions, conflicts and
                  general interactions in an efficient way. They should, as a Board, collectively ensure that all voices
                  are heard.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1ludQI8KuwGU0CU8AiA9iNjOZEs4gKJlgyTsG5P9tiQ4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1kRxwswMEGK-qn1WJspTG9oiI-4TxmeB7VrK_OrUSFLw/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    FAST advisory board template
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  The Founder / Advisor Standard Template (&#39;FAST&#39;) was developed by the Founder Institute to
                  help aspiring entrepreneurs in startup launch programs to set-up advisory
                  boards and engage with mentors that they interact with throughout the program. This document is an
                  example of the legal terms used in this contract. (This is not legal advice, but for informational
                  purposes only).
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1kRxwswMEGK-qn1WJspTG9oiI-4TxmeB7VrK_OrUSFLw/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/10K0ZqdOCj2MqUS5uWVHlkEzmhqTnCs6YBmwah_kQ0e4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Warrant to Purchase Shares Sample
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  When an investor exercises a warrant, they purchase shares and the proceeds are a source of capital
                  for the company. A call warrant is the right to buy shares at a certain price in the future and a put
                  warrant is the right to sell back shares at a specific price in the future. Here is an example of a
                  legal Warrant document. (This is not legal advice, but for informational purposes only).

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/10K0ZqdOCj2MqUS5uWVHlkEzmhqTnCs6YBmwah_kQ0e4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

AdvisoryTab.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default AdvisoryTab;
