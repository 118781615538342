import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { MenuItem, Select as SelectField } from '@material-ui/core';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const capitalize = (string) => {
  const upperString = string.charAt(0).toUpperCase() + string.slice(1);
  return upperString.replace('-', ' ').replace('_', ' ');
};

const renderSelectField = ({
  input, label, meta: { touched, error }, placeholder, multiple, options,
}) => (
  <div className="form__form-group-input-wrap">
    <SelectField
      className="material-form__field form__form-group-select"
      label={label}
      error={(touched && (typeof error !== 'undefined' && error !== ''))} // This will show the error message
      helpertext={touched && error}
      placeholder={placeholder}
      multiple={multiple}
      value={input.value}
      displayEmpty
      renderValue={input.value !== '' ? null : () => 'Please select a column'}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    >
      {options !== null && Array.isArray(options)
        ? options
          .sort((a, b) => (a > b ? 1 : -1))
          .filter(option => (option !== 'custom')) // temporary hide custom option
          .map(option => (
            <MenuItem key={option} value={option}>
              <img
                className="importIcon mr-2"
                src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/importIcons/${option}.png`}
                alt={`Icon ${capitalize(option)}`}
              />
              {capitalize(option)}
            </MenuItem>
          )) : (
            <MenuItem key="0" value="0">No Table available</MenuItem>
        )
      }
      <MenuItem key="not" value="noImport">
        <img
          className="importIcon mr-2"
          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/importIcons/noImport.png`}
          alt="Icon Do Not Import"
        />
        Do not import
      </MenuItem>
    </SelectField>
  </div>
);

renderSelectField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.element),
};

renderSelectField.defaultProps = {
  meta: null,
  label: '',
  placeholder: '',
  multiple: false,
  options: [],
};

class TableComponent extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.element).isRequired,
    columnNames: PropTypes.arrayOf(PropTypes.element).isRequired,
    parsedData: PropTypes.arrayOf(PropTypes.object).isRequired,
    returnStep: PropTypes.func.isRequired,
    chooseCampaign: PropTypes.func.isRequired,
    submittingLoader: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.object),
    campaign: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  };

  static defaultProps = {
    campaign: null,
    campaigns: null,
  };

  render() {
    const {
      parsedData, columns, columnNames, returnStep, handleSubmit,
      campaigns, chooseCampaign, campaign, type, submittingLoader,
    } = this.props;

    return (
      <form className="material-form" onSubmit={values => handleSubmit(values)}>
        <Table responsive hover className="import-table">
          <thead>
            <tr>
              <th>Column Name</th>
              <th>Select Type</th>
              <th>Samples</th>
            </tr>
          </thead>
          <tbody>
            { columns.map(
              column => (
                <tr key={column}>
                  <td>{ column }</td>
                  <td>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name={column}
                          component={renderSelectField}
                          options={columnNames}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="colored-lines">
                    <span>{ parsedData[0] ? parsedData[0][column] : '' }<br /></span>
                    <span>{ parsedData[1] ? parsedData[1][column] : '' }<br /></span>
                    <span>{ parsedData[2] ? parsedData[2][column] : '' }<br /></span>
                    <span>{ parsedData[3] ? parsedData[3][column] : '' }<br /></span>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
        <hr className="m-5" />
        <div className="center-block m-auto">
          {type === 'import' && (
            <>
              <UncontrolledDropdown
                className="select-type col-xs-12 col-md-6 offset-md-3 col-xl-4 offset-xl-4 mt-2 p-0"
              >
                <DropdownToggle className="icon rounded icon--right w-100 icon--blue capitalize" color="primary">
                  {campaign && (
                    <p>{campaign.name} <ChevronDownIcon /></p>
                  )}
                  {!campaign && (
                    <p>Please select a campaign <ChevronDownIcon /></p>
                  )}
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu large">
                  {campaigns.map((campaignItem) => {
                    if (campaignItem.status !== 'archived' && campaignItem.status !== 'draft') {
                      return (
                        <DropdownItem
                          onClick={() => chooseCampaign(campaignItem)}
                          key={campaignItem.id}
                        >
                          {campaignItem.name}
                        </DropdownItem>
                      );
                    }
                    return null;
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
              <hr className="m-5" />
              <div className="form__form-group-field days">
                <Field
                  name="checkDuplicates"
                  component={renderCheckBoxField}
                  label="Check for duplicates across all campaigns"
                  className="colored-click m-auto"
                  defaultChecked
                />
              </div>
              <br />
            </>
          )}
          <p className="text-center">{parsedData.length} data rows detected</p>
          <br />
          <ButtonToolbar className="form__button-toolbar">
            <Button className="rounded mt-2 ml-4 ml-auto" type="button" onClick={returnStep}>
              Cancel
            </Button>
            {submittingLoader
              ? (
                <Button
                  className="rounded icon icon--right icon--blue mt-2 mr-4 pl-0 mr-auto submit-import"
                  color="primary"
                  disabled={submittingLoader}
                >
                  <LoadingIcon className="mdi-icon-spinning" />
                  &nbsp;Submitting
                </Button>
              ) : (
                <Button
                  className="rounded icon icon--right icon--blue mt-2 mr-4 mr-auto"
                  color="primary"
                  type="submit"
                  disabled={submittingLoader}
                >
                  {type === 'import' ? (
                    <span>Import</span>
                  ) : (
                    <span>Blacklist</span>
                  )}
                  <ChevronRightIcon />
                </Button>
              )}
          </ButtonToolbar>
        </div>
        <br />
      </form>
    );
  }
}

export default reduxForm({
  form: 'import_csv_form', // a unique identifier for this form
})(TableComponent);
