/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button, Col, Row, Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CardTitle from 'reactstrap/lib/CardTitle';
import PlusIcon from 'mdi-react/PlusIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Alert from '../../../shared/components/alerts/Alert';


function BusinessPlanTab(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <Row>
        <Col xs={12}>
          <CardTitle>
            <Row>
              <Col xs={12} lg={8}>
                <h3>Business Plan & Business Model Templates</h3>
                <p className="text-muted mt-4">
                  For every entrepreneur looking to raise funds, producing a pitch deck that is crisp and concise,
                  short and sweet, which details the fundamentals in a concise and bitesize manner, is not always a
                  straightforward task.
                </p>
                <p className="text-muted">
                  Convincing an investor of the credibility of your business model requires an explanation of the
                  assumptions detailed in your model, how it is affected by the consumers and how this might change
                  over time.
                </p>
                <p className="text-muted mb-2">
                  Fortunately, for the founders among you, we have collated documents and templates from top investors &
                  accelerators on how to produce a perfect pitch deck so that you can maximise your chances of raising
                  funds.
                </p>
              </Col>
            </Row>
            <Alert color="purple" className="alert--colored mt-5">
              <div>
                <p>
                  <span className="bold-text">Want to learn more? </span>
                  At Angels Partners, we have rounded up the best advice to help you form your Business Model
                  & Business Plan
                  <br /><br />
                  <a className="text-white" href="https://angelspartners.com/blog/building-your-business-model">
                    <strong>Building your Business Model <ChevronRightIcon /></strong>
                  </a>
                  <br />
                  <a
                    className="text-white"
                    href="https://angelspartners.com/blog/creating-your-business-plan-the-essentials"
                  >
                    <strong>Creating your Business Plan: The Essentials <ChevronRightIcon /></strong>
                  </a>
                  <br />
                  <a className="text-white" href="https://angelspartners.com/blog/pitch-perfect">
                    <strong>Pitch Perfect: Create Your Elevator Pitch <ChevronRightIcon /></strong>
                  </a>
                </p>
              </div>
            </Alert>
          </CardTitle>
          <Table responsive hover>
            <tbody>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1VHN-jXuByrweSz6rIHOXRk6hzaKA74vA/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Sample Pitch Deck
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A template of a pitch deck for your reference.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1VHN-jXuByrweSz6rIHOXRk6hzaKA74vA/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button className="btn rounded icon btn-slides mt-4">
                      Open Slides
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1boEiezL22TZTZnOmDaIZaAFzj0tJaODzMgIwXrJdbMc/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Executive Summary Template
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A good executive summary grabs your reader’s attention and lets them know what it is you do and why
                  they should read the rest of your business plan or proposal.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/document/d/1boEiezL22TZTZnOmDaIZaAFzj0tJaODzMgIwXrJdbMc/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="primary" className="btn rounded icon mt-4">
                      Open Document
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1USA7xqNDhNu3k_EHnRygwfap4CkTbfIBg9AXf2Vjozc/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Advertising Model from Michael Simpson
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  With pay-per-click advertising, the idea is to balance the cost per click that we’re willing to pay
                  with the profit per click that we expect to achieve. This financial model attempts to find the right
                  level of spending that maximizes the business profit. It is designed for businesses that make money
                  from page views. Examples include TechCrunch, Huffington Post etc.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1USA7xqNDhNu3k_EHnRygwfap4CkTbfIBg9AXf2Vjozc/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/presentation/d/1Qd4VDBHUdqUUsvoFsEOPFXAhTipkcYzKrtlpQdkyKdg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Business Model Canvas
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  It is a strategic management tool to quickly define and communicate a business idea or
                  concept. It helps visualize what is important and forces users to address key areas.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/presentation/d/1Qd4VDBHUdqUUsvoFsEOPFXAhTipkcYzKrtlpQdkyKdg/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button className="btn rounded icon btn-slides mt-4">
                      Open Slides
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1ZLjkGtv1F263Re5CTXwBlLpEsaUIUZN1ojp9JQiXO1s/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Customer Acquisition & Funnel Analysis from Andrew Chen
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A customer acquisition strategy defines the best mix of media and engagement tools (lead generation
                  and product offers) to gain new customers through targeting them and reaching them through online and
                  offline customer journeys. Here is a great template model to give you a head start.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1ZLjkGtv1F263Re5CTXwBlLpEsaUIUZN1ojp9JQiXO1s/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1_hGB2hnab_3sHS1dXlu1r2NckgX4RVGR/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Financial Model Hacks - General Assembly Class 2013
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A customer acquisition strategy defines the best mix of media and engagement tools (lead generation
                  and product offers) to gain new customers through targeting them and reaching them through online and
                  offline customer journeys. Here is a great template model to give you a head start.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://drive.google.com/file/d/1_hGB2hnab_3sHS1dXlu1r2NckgX4RVGR/view?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button className="btn rounded icon btn-slides mt-4">
                      Open Slides
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1GPR0SqtW2wVcXWWEygnzKtV5EmGma-Cq7qy69ZLO5a4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Hiring Planner and Burn Rate Analyzer
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Strategic hiring plans are more complex than just considering the headcount. They make business
                  leaders stop and think about next year’s goals and whether or not the organization’s existing talent
                  meets its needs. Here is a hiring plan spreadsheet with accompanying financials and headcounts.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1GPR0SqtW2wVcXWWEygnzKtV5EmGma-Cq7qy69ZLO5a4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1ZfYaL-Fs099IeHY0sDJboPfO-fggmQQOARwL3ST_Ia0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    SaaS Metrics Dashboard from Christoph Janz
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Here is a template detailing some of the most important metrics for SaaS businesses to measure and
                  track over time.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1ZfYaL-Fs099IeHY0sDJboPfO-fggmQQOARwL3ST_Ia0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/115y4M8eHXi9Q-FNprVVFJn8-IDwzrGBlp4dCT98WKOU/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Subscription Model from Michael Simpson
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Use this template as an illustrative guide to learning more about how you might think about modeling
                  your business. It covers the most important metrics for businesses based on a subscription revenue
                  model. Examples include Mixpanel, Optimizely, Kissmetrics.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/115y4M8eHXi9Q-FNprVVFJn8-IDwzrGBlp4dCT98WKOU/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1nnVDwvjCsHD6kT1g9T5_FfayGy8XZa7rw3PdLHQdqIo/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Transaction Fee Model from Michael Simpson
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Here is a template detailing some of the most important metrics for businesses that make money from
                  transaction fees to measure and track over time. Examples include AirBnb, Amazon and Zappos.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1nnVDwvjCsHD6kT1g9T5_FfayGy8XZa7rw3PdLHQdqIo/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1K_xRLBH7ut5-xjML02kmQUx9Atlzetoqq0Zivz6hs3g/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Mobile Model from Michael Simpson
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Here is a template detailing some of the most important metrics for businesses that make money from
                  mobile in-app purchases to measure and track over time. You can include paid advertising revenue
                  using the formulas in the advertising model. Examples include DJZ TxT and most social games.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1K_xRLBH7ut5-xjML02kmQUx9Atlzetoqq0Zivz6hs3g/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1MBCy6nMKz0Xv8spCaQgZi3tPWK-75wnztXwqPXJHXVQ/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    Very Basic Financial Model
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  A simplistic version of a Financial Model outlining profits & losses and the outcome for business
                  founders.

                  {/* eslint-disable-next-line max-len */}
                  <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1MBCy6nMKz0Xv8spCaQgZi3tPWK-75wnztXwqPXJHXVQ/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                    <Button color="success" className="btn rounded icon mt-4">
                      Open Spreadsheet
                      <PlusIcon />
                    </Button>
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

BusinessPlanTab.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default BusinessPlanTab;
