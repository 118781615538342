import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const defaultLogo = `${process.env.PUBLIC_URL}/img/nologo.jpg`;

const SimStartupItem = ({
  name, logo, id,
}) => (
  // eslint-disable-next-line max-len
  <Link to={id ? `/startup/${id}` : '#'} className="simstartup__link d-flex flex-row align-items-center px-2 py-1">
    <img
      className="simstartup__img rounded-circle img-thumbnail"
      src={logo
        ? process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_LOGOS_PATH + logo
        : defaultLogo}
      alt=""
    />
    <h5 className="ml-3">{name}</h5>
  </Link>
);

export default SimStartupItem;

SimStartupItem.defaultProps = {
  id: null,
  name: null,
  logo: null,
};

export const simStartupProps = {
  id: PropTypes.number,
  name: PropTypes.string,
  logo: PropTypes.string,
};

SimStartupItem.propTypes = simStartupProps;
