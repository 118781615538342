import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';
import CheckboxBlankCircleOutlineIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';
import CheckboxMarkedCircleOutlineIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import Alert from '../alerts/Alert';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

// TODO: MERGE THIS FILE WITH MODAL FUND SELECT INVESTORS

class ModalMultiFundSelectInvestors extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    toggleMultiCampaignsModal: PropTypes.func.isRequired,
    addInvestorToCampaign: PropTypes.func.isRequired,
    user: PropTypes.shape({
      isCustomer: PropTypes.bool,
      plan: PropTypes.string,
    }).isRequired,
    funds: PropTypes.arrayOf(
      PropTypes.shape({
        connectedInvestors: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            fullName: PropTypes.string,
            email: PropTypes.string,
            picture: PropTypes.string,
            inCampaign: PropTypes.bool,
            added: PropTypes.bool,
          }),
        ),
      }),
    ).isRequired,
  };

  static defaultProps = {
    displayed: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      currentFundIndex: 0,
      contacts: null,
    };
  }

  componentDidMount() {
    const { displayed } = this.props;
    this.setState({ modal: displayed });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  componentDidUpdate(prevProps, prevState) {
    const { modal } = this.state;
    const { funds } = this.props;
    if (modal && (prevState.modal !== modal || prevProps.funds !== funds)) this.renderInvestors();
  }

  toggle = () => {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }), () => {
      const { modal } = this.state;
      if (!modal) {
        this.setState({ currentFundIndex: 0 });
      }
    });
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  };

  displayEmail = (email, isCustomer, plan, inCampaign) => {
    if (email && inCampaign) {
      return 'Already in Campaign';
    }
    return null;
  };

  confirmFundSelection = () => {
    const { currentFundIndex } = this.state;
    const { funds, toggleMultiCampaignsModal } = this.props;

    // scroll modal up
    const modalRoot = document.getElementById('modal_multi_investors').closest('div.modal');
    modalRoot.scrollTo({ top: 0, behavior: 'smooth' });

    const nextFundIndex = currentFundIndex + 1;
    if (nextFundIndex < funds.length) {
      // Move on to the next fund
      this.setState({
        currentFundIndex: nextFundIndex,
      }, () => this.renderInvestors());
    } else {
      // We are done
      this.setState({
        currentFundIndex: 0,
      }, () => {
        this.toggle();
        toggleMultiCampaignsModal();
      });
    }
  }

  // TODO: remplacer ca par InvestorsList.jsx
  renderInvestors = () => {
    const { modal, currentFundIndex } = this.state;
    const { funds, user, addInvestorToCampaign } = this.props;
    let contacts = null;

    if (modal && typeof funds !== 'undefined' && funds[currentFundIndex].connectedInvestors !== undefined) {
      const investor = funds[currentFundIndex];
      contacts = funds[currentFundIndex].connectedInvestors
        .sort((y, x) => x.recommended - y.recommended)
        .map((contact) => {
          if (contact.email) {
            return (
              <li key={contact.id}>
                <Button
                  outline
                  onClick={() => addInvestorToCampaign(investor, contact)}
                  onKeyDown={() => addInvestorToCampaign(investor, contact)}
                >
                  {contact.added ? <CheckboxMarkedCircleOutlineIcon /> : <CheckboxBlankCircleOutlineIcon />}
                  <img
                    src={contact.picture
                      ? process.env.REACT_APP_AWS_ROOT
                      + process.env.REACT_APP_AWS_INVESTORS_PATH
                      + contact.picture
                      : userIcon
                    }
                    alt="investor"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = userIcon;
                    }}
                  />
                  <h5>{contact.fullName}</h5>
                  <p className="text-muted">{contact.role}</p>
                  <span>{this.displayEmail(contact.email, user.isCustomer, user.plan, contact.inCampaign)}</span>
                </Button>
              </li>
            );
          }
          return null;
        });
    }

    if (contacts && Array.isArray(contacts)) {
      contacts = contacts.filter(el => el != null);
    }

    this.setState({ contacts });
  };

  render() {
    const { modal, currentFundIndex, contacts } = this.state;
    const { funds } = this.props;

    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        className="modal-dialog--primary large"
        id="modal_multi_investors"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <img
            src={funds[currentFundIndex].picture
              ? process.env.REACT_APP_AWS_ROOT
              + process.env.REACT_APP_AWS_INVESTORS_PATH
              + funds[currentFundIndex].picture
              : userIcon
            }
            alt="investor"
            className="fund-picture"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = userIcon;
            }}
          />
          <h4 className="text-modal  modal__title">
            Select Investors to contact at { funds[currentFundIndex].fullName }
          </h4>
        </div>
        {contacts && contacts.length > 0
          ? (
            <div className="modal__body">
              <p>
                Please select an investor you would like to contact in this firm.
                It is advised to contact only one person at a time from the same firm.
              </p>
              <ul className="modal__list p-0 pt-5">
                {contacts}
              </ul>
            </div>
          )
          : (
            <Alert color="info" className="alert--margin" icon>
              <p>
                We do not have any contact information for this fund, sorry.
              </p>
            </Alert>
          )}
        <div className="text-center">
          <Button
            className="m-0 mr-1"
            color="primary"
            onClick={this.confirmFundSelection}
          >
            Done
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ModalMultiFundSelectInvestors;
