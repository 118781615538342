import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import HandshakeIcon from 'mdi-react/HandshakeIcon';

const SidebarLink = ({
  title, icon, newLink, route, onClick, location,
}) => {
  // Define active status based on the route and matching logic
  let isActive = false;

  if (route === '/investors-db') {
    isActive = location.pathname === '/investors-db' || location.pathname.startsWith('/investor-db/');
  } else if (route === '/investors') {
    isActive = location.pathname === '/investors' || location.pathname.startsWith('/investor/');
  } else if (route === '/founders') {
    isActive = location.pathname.startsWith('/founder');
  } else if (route === '/campaigns') {
    isActive = location.pathname === '/campaigns'
      || location.pathname.startsWith('/campaigns/edit/')
      || location.pathname === '/campaigns/create';
  } else {
    isActive = location.pathname === route;
  }

  return (
    <NavLink
      to={route}
      onClick={onClick}
      className={isActive ? 'sidebar__link-active' : ''}
    >
      <li className="sidebar__link">
        {icon && icon === 'handshake' ? (
          <span className="sidebar__link-icon"><HandshakeIcon /></span>
        ) : (
          icon && <span className={`sidebar__link-icon lnr lnr-${icon}`} />
        )}
        <p className="sidebar__link-title">
          {title}
          {newLink ? <span className="sidebar__link-badge">Free</span> : ''}
        </p>
      </li>
    </NavLink>
  );
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
};

// Export with withRouter to access location
export default withRouter(SidebarLink);
