import React, { PureComponent } from 'react';
import {
  Card, Col, Container, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import ExtInvestorsList from '../../shared/components/externalInvestors/List';
import SearchFilters from '../../shared/components/externalInvestors/SearchFilters';
import TourPopup from '../../shared/components/tours/TourPopup';
import Paywall from '../App/Paywall';

class ExternalInvestors extends PureComponent {
  constructor() {
    super();
    this.state = {
      triggerTour: false,
      closeTour: false,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.type === 'startup' && !user.investorsAdded) {
      this.setState({ triggerTour: true });
    } else if (user.type !== 'startup') {
      window.location = '/startups';
    }
  }

  closeTour = () => {
    this.setState({ closeTour: true });
  }

  render = () => {
    const { triggerTour, closeTour } = this.state;

    return (
      <Container>
        <Paywall />
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Investor Database</h3>
          </Col>
          <Col xs={12} md={9} xl={7}>
            <p className="page-description">
              This is our database of investors who are <strong>NOT</strong> registered on our platform.<br />
              To make use of this list &nbsp;
              <Link to="campaigns/create" className="text-black-50"><u><strong>create a campaign</strong></u></Link> and
              add investors to your list of recipients to share your deck and materials.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={11} className="mt-5 mt-md-4 mt-lg-3 p-0 p-md-3 p-lg-5 offset-md-05">
            <Card className="p-0">
              <div className="card__title mb3">
                <h5 className="bold-text">Filter Investors</h5>
              </div>
              <SearchFilters />
            </Card>
          </Col>
        </Row>
        <ExtInvestorsList
          closeTour={this.closeTour}
        />
        {triggerTour
        && (
          <TourPopup
            page="extInvestors"
            triggerTour={triggerTour}
            disableFocusLock
            closeTour={closeTour}
          />
        )}
      </Container>
    );
  }
}

export default (ExternalInvestors);
