/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { TextField } from '@material-ui/core';
// import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import { Field, reduxForm } from 'redux-form';
import YenIcon from 'mdi-react/YenIcon';
import EuroIcon from 'mdi-react/EuroIcon';
import PoundIcon from 'mdi-react/CurrencyGbpIcon';
import DollarIcon from 'mdi-react/DollarIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import validate from '../../formValidate/financials';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={(touched && (typeof error !== 'undefined' && error !== ''))}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

class StartupFinancials extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
      revenues: PropTypes.number,
    }).isRequired,
  };

  // constructor(props) {
  //   super(props);
  //   const { initialValues } = this.props;
  //   this.state = {
  //     revenues: initialValues.revenues,
  //   };
  // }

  renderCurrencyIcon = (currency) => {
    switch (currency) {
      case '¥':
        return <YenIcon />;
      case '€':
        return <EuroIcon />;
      case '£':
        return <PoundIcon />;
      default:
        return <DollarIcon />;
    }
  }

  // updateRevenue = (value) => {
  //   this.setState({ revenues: value });
  // }

  render() {
    const {
      // handleSubmit, pristine, reset, submitting, initialValues, currency,
      handleSubmit, pristine, reset, submitting, currency,
    } = this.props;
    // const { revenues } = this.state;

    return (
      <div>
        <div className="card__title">
          <h5 className="bold-text">Financials</h5>
        </div>
        <form className="material-form" onSubmit={handleSubmit}>
          <div>
            <span className="material-form__label"><h5>Revenues</h5></span>
            <div className="material-form__form-group-field">
              <div className="material-form__form-group-icon">
                {this.renderCurrencyIcon(currency)}
              </div>
              <Field
                name="revenues"
                component={renderTextField}
                // onChange={val => this.updateRevenue(val)}
                type="number"
                placeholder="What were your revenues over the last 12 months?"
              />
            </div>
          </div>
          {/* {(initialValues.revenues > 0 && revenues > 0) && (
            <>
              <div>
                <span className="material-form__label"><h5>Number of customers</h5></span>
                <div className="material-form__form-group-field">
                  <div className="material-form__form-group-icon">
                    <AccountMultipleIcon />
                  </div>
                  <Field
                    name="nbCustomers"
                    component={renderTextField}
                    type="number"
                    placeholder="How many customers do you have?"
                  />
                </div>
              </div>
              <div>
                <span className="material-form__label"><h5>Net Profit</h5></span>
                <div className="material-form__form-group-field">
                  <div className="material-form__form-group-icon">
                    {this.renderCurrencyIcon(currency)}
                  </div>
                  <Field
                    name="netProfit"
                    component={renderTextField}
                    type="number"
                    placeholder="What is you net profit?"
                  />
                </div>
              </div>
            </>
          )}
          <div className="form__form-group margin10">
            <span className="material-form__label"><h5>CAC</h5></span>
            <div className="form__form-group-field">
              <Field
                name="cac"
                placeholder="Leave blank if you don’t know"
                component={renderTextField}
                type="number"
              />
            </div>
          </div>
          <div className="form__form-group margin10">
            <span className="material-form__label"><h5>LTV</h5></span>
            <div className="form__form-group-field">
              <Field
                name="ltv"
                placeholder="Leave blank if you don’t know"
                component={renderTextField}
                type="number"
              />
            </div>
          </div> */}
          <ButtonToolbar className="form__button-toolbar">
            <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
              Save
              <ChevronRightIcon />
            </Button>
            <Button className="rounded mt-5" type="button" onClick={reset} disabled={pristine || submitting}>
              Cancel
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'startup_financials_form', // a unique identifier for this form
  validate,
})(StartupFinancials);
