/* eslint-disable */
let modal = false;
const validate = (values) => {

  const errors           = {};
  const regExp           = /\[(.*?)\]/;
  const regExp2          = /\{{(.*?)\}}/;
  const regExp3Emoticons = /\p{Extended_Pictographic}/u;

  if (typeof values.templates !== typeof undefined && Array.isArray(values.templates))
  {
    for (let i = 0; i < values.templates.length; i++) {

      // TITLE VALIDATIONS
      if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined'
          && (!('title' in values.templates[i]) || !values.templates[i].title)) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].title = 'Please enter a title';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined'
          && values.templates[i].title.length > 250) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].title = 'Please keep your title below 250 characters';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined'
          && values.templates[i].title.length < 2) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].title = 'Please enter a title longer than 2 characters';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('title' in values.templates[i]) && values.templates[i].title
          && values.templates[i].title.match(regExp2)) {
          let title = values.templates[i].title;
          while (title.match(regExp2)) {
            if (title.match(regExp2)[1].trim().toLowerCase() !== 'investorfirstname'
              && title.match(regExp2)[1].trim().toLowerCase() !== 'investorlastname'
              && title.match(regExp2)[1].trim().toLowerCase() !== 'investorvertical'
              && title.match(regExp2)[1].trim().toLowerCase() !== 'investorcity'
              && title.match(regExp2)[1].trim().toLowerCase() !== 'investorfund'
              && title.match(regExp2)[1].trim().toLowerCase() !== 'lastdeal'
              && title.match(regExp2)[1].trim().toLowerCase() !== 'startupname'
              && title.match(regExp2)[1].trim().toLowerCase() !== 'trackinglink'
              && title.match(regExp2)[1].trim().toLowerCase() !== 'signature') {
              if (!Array.isArray(errors.templates)) {
                errors.templates = [];
              }
              if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
                errors.templates[i] = {};
              }
              window.open('https://angelspartners.crisp.help/en/article/automate-your-investor-outreach-campaigns-2efw09/?1603294616535','popUpWindow','height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
              errors.templates[i].title = 'The variable ' + title.match(regExp2)[0] + ' does not exist. Please refer to the documentation in the pop up window for a list of all valid variables. Variables are case sensitive';
              title = '';
              modal = true;
            } else {
              title = title.replace(title.match(regExp2)[0],'');
            }
          }
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('title' in values.templates[i]) && values.templates[i].title
        && regExp.exec(values.templates[i].title) !== null) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].title = 'Replace ' + regExp.exec(values.templates[i].title)[0] + ' with your own data. All text in brackets must be replaced.';
      }

      // TEMPLATE CONTENT VALIDATIONS
      if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined'
        && (!('content' in values.templates[i]) || !values.templates[i].content) && typeof values.templates[i].title !== 'undefined') { // we are using title here as content is maybe not defined yet because of the wysiwig
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].content = 'Please enter some content';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('content' in values.templates[i]) && values.templates[i].content
        && values.templates[i].content.replace( /(<([^>]+)>)/ig, '').length > 2000) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].content = 'Keep your email below 2000 characters, investors tend to skip long emails.';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('content' in values.templates[i]) && values.templates[i].content
        && values.templates[i].content.length < 30) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].content = 'Try to write something longer than 30 characters.';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('content' in values.templates[i]) && values.templates[i].content
        && regExp.exec(values.templates[i].content) !== null) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].content = 'Replace ' + regExp.exec(values.templates[i].content)[0] + ' with your own data. All blue text in brackets must be replaced.';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('content' in values.templates[i]) && values.templates[i].content
        && values.templates[i].content.indexOf('angelspartners.com') !== -1) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].content = 'Replace your link to angelspartners.com with a tracking link like so: {{trackingLink}}';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('content' in values.templates[i]) && values.templates[i].content
        && values.templates[i].content.indexOf('angels.link') !== -1) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].content = 'Replace your link to angels.link with a tracking link like so: {{trackingLink}}';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('content' in values.templates[i]) && values.templates[i].content
        && regExp3Emoticons.test(values.templates[i].content)) {
        if (!Array.isArray(errors.templates)) {
          errors.templates = [];
        }
        if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
          errors.templates[i] = {};
        }
        errors.templates[i].content = 'Your template should not contains emoticons.';
      } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('content' in values.templates[i]) && values.templates[i].content
        && values.templates[i].content.match(regExp2)) {
        let template = values.templates[i].content;
        while (template.match(regExp2)) {
          if (template.match(regExp2)[1].trim().toLowerCase() !== 'investorfirstname'
            && template.match(regExp2)[1].trim().toLowerCase() !== 'investorlastname'
            && template.match(regExp2)[1].trim().toLowerCase() !== 'investorvertical'
            && template.match(regExp2)[1].trim().toLowerCase() !== 'investorcity'
            && template.match(regExp2)[1].trim().toLowerCase() !== 'investorfund'
            && template.match(regExp2)[1].trim().toLowerCase() !== 'lastdeal'
            && template.match(regExp2)[1].trim().toLowerCase() !== 'startupname'
            && template.match(regExp2)[1].trim().toLowerCase() !== 'trackinglink'
            && template.match(regExp2)[1].trim().toLowerCase() !== 'signature') {
            if (!Array.isArray(errors.templates)) {
              errors.templates = [];
            }
            if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
              errors.templates[i] = {};
            }
            window.open('https://angelspartners.crisp.help/en/article/automate-your-investor-outreach-campaigns-2efw09/?1603294616535','popUpWindow','height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
            errors.templates[i].content = 'The variable ' + template.match(regExp2)[0] + ' does not exist. Please refer to the documentation in the pop up window for a list of all valid variables. Variables are case sensitive';
            template = '';
            modal = true;
          } else {
            template = template.replace(template.match(regExp2)[0],'');
          }
        }
      }

      // DELAY VALIDATIONS
      if (i > 0) {
        if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && (!('delay' in values.templates[i]) || !values.templates[i].delay)) {
          if (!Array.isArray(errors.templates)) {
            errors.templates = [];
          }
          if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
            errors.templates[i] = {};
          }
          errors.templates[i].delay = 'This field cannot be left empty';
        } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('delay' in values.templates[i])  && values.templates[i].delay && values.templates[i].delay <= 2) {
          if (!Array.isArray(errors.templates)) {
            errors.templates = [];
          }
          if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
            errors.templates[i] = {};
          }
          errors.templates[i].delay = 'Please enter a value greater than 2';
        } else if (values.templates && values.templates.length > i && typeof values.templates[i] !== 'undefined' && ('delay' in values.templates[i]) && !values.templates[i].delay && values.templates[i].delay > 30) {
          if (!Array.isArray(errors.templates)) {
            errors.templates = [];
          }
          if (typeof errors.templates[i] !== 'object' || errors.templates[i] === null) {
            errors.templates[i] = {};
          }
          errors.templates[i].delay = 'Please enter a value lower than 30';
        }
      }
    }
  } else {
    errors.templates = [];
    errors.templates[0] = [];
    errors.templates[0].title = 'Please enter a title';
    errors.templates[0].content = 'This field cannot be left empty';
  }

  return errors;
};

export default validate;
