import React, { Component } from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import ModalFundraisingBootcamp from '../modals/ModalFundraisingBootcamp';

const logo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/FundraisingBootcamp.png`;

class CardFundraisingBootcamp extends Component {
  state = {
    modal: false,
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  render() {
    const { modal } = this.state;
    return (
      <Col md={6} xl={4} sm={12}>
        <Card>
          <CardBody className="dashboard-card dashboard-card--warning" onClick={this.toggleModal}>
            <div className="dashboard-card__body">
              <img className="mb-5" src={logo} alt="" />
              <h3 className="dashboard-card__plan mb-5">European Bootcamp</h3>
              <hr />
              <p className="dashboard-card__title">Acquire the unfair advantage</p>
              <div className="dashboard-card__desc top-line text-justify mt-4 mb-5">
                <p>
                  The Fundraising Bootcamp is a comprehensive, action-packed programme run by experts
                  (all fundraising pros, tech CEOs, real investors) that gives you the practical tools and get the job
                  done
                </p>
              </div>
              <Button className="dashboard-card__button" color="primary">Learn More</Button>
            </div>
          </CardBody>
        </Card>
        <ModalFundraisingBootcamp
          displayed={modal}
          toggleModal={this.toggleModal}
        />
      </Col>
    );
  }
}

export default CardFundraisingBootcamp;
