/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import 'react-quill/dist/quill.snow.css';
import TextField from '@material-ui/core/TextField';
import { Field, getFormValues, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {
  TabContent, TabPane,
} from 'reactstrap';
import { connect } from 'react-redux';
import renderTextFieldBasic from '../../form/TextFieldBasic';
import Alert from '../../alerts/Alert';
import ModalCampaignErrors from './ModalCampaignErrors';
import { ModalCampaignFormButton, ModalCampaignFormMultiButtons } from './ModalCampaignFormButtons';
import renderQuillEmailField from '../../form/renderQuillEmailField';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

class ModalCampaignForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    campaigns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      templates: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        position: PropTypes.number,
        delay: PropTypes.number,
        content: PropTypes.string,
        sent: PropTypes.bool,
        send: PropTypes.bool,
        sendDate: PropTypes.string,
        sendOn: PropTypes.string,
      })),
    })).isRequired,
    initialValues: PropTypes.shape({
      templates: PropTypes.object,
    }),
    investorContactInfo: PropTypes.shape({
      contactId: PropTypes.number,
      fullName: PropTypes.string,
      investorId: PropTypes.number,
      email: PropTypes.bool,
      inCampaign: PropTypes.bool,
      campaignName: PropTypes.string,
      contacted: PropTypes.bool,
      contactedOn: PropTypes.string,
      sent: PropTypes.number,
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
    valuesForm: PropTypes.object,
    activeTab: PropTypes.number.isRequired,
    formError: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    campaignError: PropTypes.string,
    editCampaign: PropTypes.bool,
    multiCampaigns: PropTypes.bool,
    sendUpdate: PropTypes.bool.isRequired,
    addMultipleRecipientsToCampaign: PropTypes.func.isRequired,
    addAllRecipientsToCampaign: PropTypes.func.isRequired,
    user: PropTypes.shape({
      mailboxConnected: PropTypes.bool,
      completion: PropTypes.number,
    }).isRequired,
    formRef: PropTypes.oneOfType([
      // Either a function
      PropTypes.func,
      // Or the instance of a DOM native element (see the note about SSR)
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
  };

  static defaultProps = {
    valuesForm: null,
    initialValues: null,
    formError: null,
    campaignError: null,
    formRef: null,
    multiCampaigns: false,
    editCampaign: false,
  };

  renderCampaignsTabs = () => {
    const {
      campaigns,
      investorContactInfo,
      editCampaign,
    } = this.props;

    return (
      campaigns && campaigns.map((c, i) => (
        <TabPane tabId={i} key={c.id}>
          <h2 className="mt-3 mb-2">{c.name}</h2>
          <ModalCampaignErrors
            campaign={c}
            investorContactInfo={investorContactInfo}
            editCampaign={editCampaign}
          />
          {c.templates.map((t, j) => (
            <div key={t.id}>
              {/* eslint-disable-next-line no-nested-ternary */}
              <h3 className={`${j === 0 ? 'first' : j === 2 ? 'third' : 'other'}-subject`}>Email #{j + 1}</h3>
              {
                (editCampaign && typeof investorContactInfo.sent !== typeof undefined
                  && investorContactInfo.sent < j + 1)
                || (editCampaign && typeof t.sent !== typeof undefined
                  && !t.sent)
                || !editCampaign
                  ? (
                    <span>
                      <div className="form__form-group light subject">
                        <Field
                          name={`campaigns[${i}][templates][${j}][title]`}
                          component={renderTextField}
                          type="text"
                          placeholder="Subject"
                        />
                      </div>
                      {editCampaign && typeof t.sendOn !== typeof undefined
                        && t.send && (
                          <div className="float-right disabled">
                            <small>This email is going to be sent out on {t.sendOn}</small>
                          </div>
                      )}
                      {editCampaign && !t.send && (
                        <div className="float-right disabled">
                          <small>This email is not going to be sent out</small>
                        </div>
                      )}
                      <div className="form__form-group">
                        <Field
                          name={`campaigns[${i}][templates][${j}][content]`}
                          component={renderQuillEmailField}
                        />
                      </div>
                      <Field
                        name={`campaigns[${i}][templates][${j}][delay]`}
                        component={renderTextFieldBasic}
                        type="hidden"
                      />
                      <Field
                        name={`campaigns[${i}][templates][${j}][position]`}
                        component={renderTextFieldBasic}
                        type="hidden"
                      />
                    </span>
                  ) : (
                    <div className="text-muted text-center m-5">This email has already been sent out.</div>
                  )}
            </div>
          ))}
        </TabPane>
      ))
    );
  };

  render() {
    const {
      handleSubmit,
      activeTab,
      onSubmit,
      investorContactInfo,
      formError,
      user,
      formRef,
      multiCampaigns,
      sendUpdate,
      editCampaign,
      addMultipleRecipientsToCampaign,
      addAllRecipientsToCampaign,
    } = this.props;

    return (
      <form
        ref={formRef}
        className="form form--horizontal"
        onSubmit={handleSubmit(onSubmit.bind(this))}
      >
        {multiCampaigns && (
          <ModalCampaignFormMultiButtons
            investorContactInfo={investorContactInfo}
            addMultipleRecipientsToCampaign={count => addMultipleRecipientsToCampaign(count)}
            addAllRecipientsToCampaign={addAllRecipientsToCampaign}
            sendUpdate={sendUpdate}
            multiCampaigns
            editCampaign={editCampaign}
          />
        )}
        {formError
        && (
          <Alert color="danger" className="alert--colored w-100">
            <p className="text-left">
              <span className="bold-text">Error!</span>&nbsp;
              {formError}
            </p>
          </Alert>
        )}
        {user && !user.appSubmitted
          ? (
            <Alert color="warning" className="alert--colored ml-5 mr-2">
              <p>
                You can schedule this campaign now but your message won’t be sent until you have completed your profile.
              </p>
            </Alert>
          ) : user && !user.mailboxConnected && (
            <Alert color="warning" className="alert--colored ml-5 mr-2">
              <p>
                You can schedule this campaign now but your message won’t be sent until you have connected your mailbox.
              </p>
            </Alert>
          )}
        {investorContactInfo.smartVC
          ? (
            <Alert color="info" className="alert--colored ml-5 mr-2">
              <p><span className="bold-text">Smart VC Contact: </span>
                This investor and all of the investor from {investorContactInfo.fund || 'this fund'} will be contacted
                one after the other. Once the campaign is over for the first investor from the list, the next investor
                will be contacted automatically.
              </p>
            </Alert>
          ) : null}
        <TabContent activeTab={activeTab}>
          {this.renderCampaignsTabs()}
        </TabContent>
        {!multiCampaigns && (
          <ModalCampaignFormButton
            investorContactInfo={investorContactInfo}
            sendUpdate={sendUpdate}
          />
        )}
      </form>
    );
  }
}

// eslint-disable-next-line no-class-assign
ModalCampaignForm = connect(state => ({
  valuesForm: getFormValues('modalCampaigns')(state),
}))(ModalCampaignForm);

export default reduxForm({
  form: 'modalCampaigns', //          <------ same form name
  destroyOnUnmount: true, //          <------ preserve form data
  forceUnregisterOnUnmount: true, //  <------ unregister fields on unmount
  enableReinitialize: true,
})(ModalCampaignForm);
