import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const followersMarks = [
  {
    value: 0,
    scaledValue: 0,
    label: '0',
  },
  {
    value: 25,
    scaledValue: 5000,
    label: '5k',
  },
  {
    value: 50,
    scaledValue: 10000,
    label: '10k',
  },
  {
    value: 75,
    scaledValue: 25000,
    label: '25k',
  },
  {
    value: 100,
    scaledValue: 50000,
    label: '50k',
  },
  {
    value: 125,
    scaledValue: 100000,
    label: '100k',
  },
  {
    value: 150,
    scaledValue: 250000,
    label: '250k',
  },
  {
    value: 175,
    scaledValue: 500000,
    label: '500k',
  },
  {
    value: 200,
    scaledValue: 1000000,
    label: '1M',
  },
  {
    value: 225,
    scaledValue: 5000000,
    label: '5M',
  },
  {
    value: 250,
    scaledValue: 10000000,
    label: '10M',
  },
];

const CustomSlider = withStyles({
  root: {
    color: '#67BAEB',
    height: 3,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '6px solid #243143',
    marginTop: -11,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    top: 30,
    left: 'calc(-50% - 3px)',
    '& *': {
      background: 'transparent',
      color: '#828E96',
    },
  },
  track: {
    height: 2,
    borderRadius: 2,
  },
  rail: {
    height: 2,
    borderRadius: 2,
  },
})(Slider);

class NonLinearSlider extends Component {
  static propTypes = {
    returnedValue: PropTypes.func.isRequired,
    initValues: PropTypes.arrayOf(PropTypes.number),
    type: PropTypes.string,
  };

  static defaultProps = {
    initValues: [null, null],
    type: '',
  };

  constructor(props) {
    super(props);

    const { type } = this.props;
    if (type === 'investors') {
      followersMarks[0] = {
        value: 0,
        scaledValue: 1000,
        label: '1k',
      };
    }
    this.state = {
      value: [0, 250],
    };
  }

  componentDidMount = () => {
    const { initValues } = this.props;

    const rangeMin = initValues[0] ? this.getNumber(initValues[0]) : 0;
    const rangeMax = initValues[1] ? this.getNumber(initValues[1]) : 250;

    if (rangeMin !== 0 || rangeMax !== 250) {
      this.setState({ value: [rangeMin, rangeMax] });
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    const { returnedValue } = this.props;

    let rangeMin = null;
    let rangeMax = null;

    if (Array.isArray(newValue) && typeof newValue[0] === 'number') {
      rangeMin = this.getScale(newValue[0]);
    }
    if (Array.isArray(newValue) && typeof newValue[1] === 'number') {
      rangeMax = this.getScale(newValue[1]);
    }
    returnedValue(rangeMin, rangeMax);
  };

  getScale = (value) => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = followersMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = followersMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };

  getNumber = (value) => {
    // Find wich scale are we one from the followersMark
    const mark = followersMarks.findIndex(element => element.scaledValue > value);
    const previousMark = mark - 1;

    // Find base value
    const valuePreviousMark = followersMarks[previousMark].value;
    const scaledValuePreviousMark = followersMarks[previousMark].scaledValue;
    const scaledValueMark = followersMarks[mark].scaledValue;

    // Calculate the reminder
    const baseScale = value - scaledValuePreviousMark;
    const reminder = baseScale * 25 / (scaledValueMark - scaledValuePreviousMark);

    // Final result
    const number = valuePreviousMark + reminder;

    return Math.floor(number);
  };

  // getNumber = value => Math.floor(value * 250 / 10000000);

  scale = (value) => {
    if (Array.isArray(value)) {
      return [this.getScale(value[0]), this.getScale(value[1])];
    }
    return this.getScale(value);
  };

  numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return `${Math.round(num / 1000).toFixed(0)}K`; // convert to K for number from > 1000 < 1 million
    } if (num >= 1000000) {
      return `${Math.round(num / 100000).toFixed(0) / 10}M`; // convert to M for number from > 1 million
    } if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
    return null;
  }

  render() {
    const { value } = this.state;
    return (
      <CustomSlider
        style={{ width: '100%' }}
        value={value}
        min={0}
        step={1}
        max={250}
        valueLabelDisplay="on"
        valueLabelFormat={this.numFormatter}
        scale={this.scale}
        onChange={this.handleChange}
        aria-labelledby="non-linear-slider"
        className="mt-4 mb-1"
      />
    );
  }
}

export default NonLinearSlider;
