/* eslint-disable */
export const showTour = (page) => {
  let tours = JSON.parse(localStorage.getItem('tours'));

  if (tours === null) {
    tours = {};
  }

  switch (page) {
    case 'dashboard':
      return !tours.dashboard;
    case 'extInvestors':
      return !tours.extInvestors;
    case 'modalCampaigns':
      return !tours.modalCampaign;
    case 'campaign1':
      return !tours.campaignStep1;
    case 'campaign2':
      return !tours.campaignStep2;
    case 'settings':
      return !tours.settings;
  }

  return false;
};

export const updateTour = (page) => {
  let tours = JSON.parse(localStorage.getItem('tours'));

  if (tours === null) {
    tours = {};
  }

  switch (page) {
    case 'dashboard':
      tours.dashboard = true;
      break;
    case 'extInvestors':
      tours.extInvestors = true;
      break;
    case 'modalCampaigns':
      tours.modalCampaign = true;
      break;
    case 'campaign1':
      tours.campaignStep1 = true;
      break;
    case 'campaign2':
      tours.campaignStep2 = true;
      break;
    case 'settings':
      tours.settings = true;
      break;
  }
  localStorage.setItem('tours', JSON.stringify(tours));

  return true;
};
