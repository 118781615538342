import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Card } from '@material-ui/core';
import {
  Button, CardBody, Col, Row,
} from 'reactstrap';
import EmailIcon from 'mdi-react/EmailIcon';
import NotificationSystem from 'rc-notification';
import {
  BasicNotification,
  LinkNotification,
} from '../../../shared/components/notifications/Notification';
import StartupCard from './StartupCard';
import CustPag from '../../../shared/components/pagination/Pagination';

let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  notificationRU.notice({
    content: notification,
    duration: 30,
    closable: true,
    style: { top: 30, left: 'calc(100vw - 100%)' },
    className: `${position} ${rtl}-support`,
  });
};

class StartupsList extends Component {
  constructor(props) {
    super(props);
    const initialNumberOfStartups = 0;
    const initialRowsCount = 21;
    const initialPageNumber = 1;
    // eslint-disable-next-line react/prop-types
    const { location } = props;

    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page'), 10);

    this.state = {
      startups: [],
      pageOfItems: page && page > 1 ? page : initialPageNumber,
      itemsToShow: initialRowsCount,
      itemsCount: initialNumberOfStartups,
      showPagination: false,
      error: null,
      loading: true,
      loaded: false,
      notifCustom: false,
      user: JSON.parse(localStorage.getItem('user')),
    };
  }

  componentWillReceiveProps(newProps) {
    if (window.location.pathname.startsWith('/startups')) {
      const { props } = this;
      // eslint-disable-next-line react/prop-types
      if (props.location.search !== newProps.location.search) {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          props: newProps, startups: [], loading: true, loaded: false,
        }, () => this.getStartups(true, false));
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { stateOfList } = this.props;
    if (stateOfList !== prevProps.stateOfList) {
      this.updateListOfStartup(
        stateOfList.id,
        stateOfList.adding,
        stateOfList.favorite,
        stateOfList.accepted,
        stateOfList.pinging,
        stateOfList.declining,
        stateOfList.declined,
      );
    }
  }

  componentDidMount = () => {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
    this.getStartups(false, true);
  };

  getStartups = (scrollToTop, firstLoad) => {
    const { props } = this;
    const { startups } = this.state;
    const { history } = props;
    const query = this.getQuery(new URLSearchParams(props.location.search), null, firstLoad);

    if (startups.length === 0) {
      this.getStartupsCount(query);
      axios.get(`/startups/list?${query}`)
        .then((response) => {
          const newStartups = response.data.map(startup => ({
            ...startup, adding: false, pinging: false, declining: false,
          }));
          // Remove loading screen
          this.setState({ startups: newStartups, loading: false });
          setTimeout(() => this.setState({ loaded: true }), 500);

          // Scroll to Top
          if (scrollToTop) window.scrollTo(0, 0);

          // Update url accordingly to the query
          if (window.location.pathname.startsWith('/startups')) {
            history.push(`/startups/?${query}`);
          }
        }).catch((error) => {
          if (error && error.response && error.response.status === 403 && firstLoad) {
            axios.get('/startups/list?page=1')
              .then((response) => {
                const newStartups = response.data.map(startup => ({
                  ...startup, adding: false, pinging: false, declining: false,
                }));
                // Remove loading screen
                this.setState({ startups: newStartups, loading: false });
                setTimeout(() => this.setState({ loaded: true }), 500);

                // Scroll to Top
                if (scrollToTop) window.scrollTo(0, 0);

                // Update url accordingly to the query
                if (window.location.pathname.startsWith('/startups')) {
                  history.push('/startups/?page=1');
                }
              }).catch((error2) => {
                if (error2 && error2.response && error2.response.status === 403) {
                  this.setState({ startups: [], error: error2.response.data.message, loading: false });
                  if (window.location.pathname.startsWith('/startups')) {
                    history.push('/startups/?page=1');
                  }
                }
                if (error2 && typeof error2.response === 'object' && typeof error2.response.data === 'object') {
                  this.setState({ startups: [], error: error2.response.data.message, loading: false });
                } else {
                  this.setState({ startups: [], error: 'Sorry an error occurred. Please try again.', loading: false });
                }
                // Remove loading screen
                setTimeout(() => this.setState({ loaded: true }), 500);
                if (scrollToTop) window.scrollTo(0, 0);
              });
          }
          if (error && error.response && error.response.status === 403 && !firstLoad) {
            this.setState({ startups: [], error: error.response.data.message, loading: false });
            if (window.location.pathname.startsWith('/startups')) {
              history.push(`/startups/?${query}`);
            }
          }
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            this.setState({ startups: [], error: error.response.data.message, loading: false });
          } else {
            this.setState({ startups: [], error: 'Sorry an error occurred. Please try again.', loading: false });
          }

          // Remove loading screen
          setTimeout(() => this.setState({ loaded: true }), 500);
          if (scrollToTop) window.scrollTo(0, 0);
        });
    }
  };

  getStartupsCount = (query) => {
    axios.get(`/startups/count?${query}`)
      .then((response) => {
        this.setState({ itemsCount: response.data, showPagination: true });
      });
  };

  getQuery = (query, pageOfItems, firstLoad) => {
    const { user } = this.state;
    let queryUrl = '';

    if (pageOfItems) {
      queryUrl += `page=${pageOfItems}`;
    } else if (query.get('page')) {
      queryUrl += `page=${query.get('page')}`;
      this.setState({ pageOfItems: parseInt(query.get('page'), 10) });
    } else {
      queryUrl += 'page=1';
      this.setState({ pageOfItems: parseInt('1', 10) });
    }

    // eslint-disable-next-line no-restricted-syntax
    if (query.get('keywords')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `keywords=${query.get('keywords')}`;
    }
    if (query.get('filters')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `filters=${query.get('filters')}`;
    }
    if (query.get('stage')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `stage=${query.get('stage')}`;
    } else if (firstLoad && user.investorStages && !query.get('location') && !query.get('industry')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `stage=${user.investorStages}`;
    }
    if (query.get('location')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `location=${query.get('location')}`;
    } else if (firstLoad && user.investorCountries && !query.get('stage') && !query.get('industry')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `location=${user.investorCountries}`;
    }
    if (query.get('industry')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `industry=${query.get('industry')}`;
    } else if (firstLoad && user.investorCategories && !query.get('stage') && !query.get('location')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `industry=${user.investorCategories}`;
    }
    if (query.get('type')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `type=${query.get('type')}`;
    }
    if (query.get('rangeMin') && !firstLoad) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `rangeMin=${query.get('rangeMin')}`;
    }
    if (query.get('rangeMax') && !firstLoad) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `rangeMax=${query.get('rangeMax')}`;
    }
    if (query.get('sortBy')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `sortBy=${query.get('sortBy')}`;
    }

    return queryUrl;
  };

  notifyMeThisFilters = () => {
    const { props } = this;
    const queryParams = this.getQuery(new URLSearchParams(props.location.search));

    axios.post(`/investors/notifications/add?${queryParams}`)
      .then((response) => {
        if (response) {
          // Set up Loading
          this.setState({ notifCustom: true });

          const user = JSON.parse(localStorage.getItem('user'));

          user.notifCustom = true;
          localStorage.setItem('user', JSON.stringify(user));

          this.showNotifSuccess('Weekly updates activated',
            'You will receive weekly notifications on recently subscribed companies that matches your criteria.'
            + ' You can edit the frequency of these notifications in your settings.',
            '/myProfile/settings?tab=notifications');
        }
      })
      .catch(() => {
        this.showNotifWarning('Sorry, an error occurred. Please try again later or contact support.');
      });
  }

  showNotifSuccess = (title, message, link) => showNotification({
    notification: <LinkNotification
      color="primary"
      title={title}
      link={link}
      message={message}
    />,
    position: 'right-up',
  });

  showNotifWarning = message => showNotification({
    notification: <BasicNotification
      color="warning"
      title="Warning!"
      message={message}
    />,
    position: 'right-up',
  });

  onChangePage = (pageOfItems) => {
    const { props } = this;
    const query = this.getQuery(new URLSearchParams(props.location.search), pageOfItems);
    this.setState({ pageOfItems }, () => props.history.push({ pathname: '/startups', search: `?${query}` }));
  };

  updateListOfStartup = (id, adding, favorite, accepted, pinging, declining, declined) => {
    // Set up Loading
    const { state } = this;
    const startups = [...state.startups];

    // find the index of this startup
    const objIndex = startups.findIndex(i => i.id === id);
    if (objIndex >= 0) {
      // Sort out Favorite and Pinged
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (favorite === null) favorite = startups[objIndex].favorite;

      // Sort out Favorite and Pinged
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (accepted === null) accepted = startups[objIndex].accepted;

      // Update Startup
      const updatedStartup = {
        ...startups[objIndex], adding, favorite, accepted, pinging, declining, declined,
      };
      // Make new list of startups
      const updatedStartupsList = [
        ...startups.slice(0, objIndex),
        updatedStartup,
        ...startups.slice(objIndex + 1),
      ];

      // Update the State
      this.setState({ startups: updatedStartupsList });

      return objIndex;
    }
    return false;
  };

  render() {
    const {
      itemsToShow, pageOfItems, itemsCount, showPagination,
      loading, loaded, error, user, notifCustom,
    } = this.state;
    const {
      addFavorites, removeFavorites, connectStartup, declineStartup,
    } = this.props;

    const state1 = this.state;
    let startups = null;
    let empty = null;

    if (state1.startups) {
      startups = state1.startups.map(
        startup => (
          <StartupCard
            startup={startup}
            addFavorites={e => addFavorites(startup.id, e)}
            removeFavorites={e => removeFavorites(startup.id, e)}
            connect={() => connectStartup(startup.id)}
            decline={() => declineStartup(startup.id)}
            key={startup.id}
          />
        ),
      );
    }

    if (error && loaded) {
      empty = (
        <Card className="error-results">
          <CardBody>
            <h5>{error}</h5>
          </CardBody>
        </Card>
      );
    } else if (loading && !loaded) {
      empty = (
        <Card className="error-results">
          <CardBody>
            <svg className="load__icon">
              <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </CardBody>
        </Card>
      );
    } else if (!loading && loaded) {
      empty = (
        <Card className="error-results">
          <CardBody>
            <h5>Sorry we could not find any startups matching your request</h5>
          </CardBody>
        </Card>
      );
    }

    return (
      <Row>
        <Col xs={12} md={{ size: 11, offset: 1 }} className="mb-4 mt-4">
          {showPagination && !loading
          && (
            <CustPag
              itemsCount={itemsCount}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
              showCountOnly
            />
          )}
          {!loaded
            ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            )
            : (
              <div>
                { startups && startups.length > 0
                  ? (
                    <div>
                      <Button
                        id="button__notify-startups"
                        className="rounded icon icon--right float-right"
                        color={user.notifCustom || notifCustom ? 'violet' : 'primary'}
                        onClick={evt => this.notifyMeThisFilters(evt)}
                      >
                        {user.notifCustom || notifCustom
                          ? 'You will be updated of new opportunities '
                          : 'Keep me updated of new opportunities '}
                        <EmailIcon />
                      </Button>
                      <div className="investor-items__wrap row">
                        <div className="investor-items">
                          {startups && startups.length > 0 ? startups : empty}
                        </div>
                      </div>
                    </div>
                  )
                  : empty
                }
              </div>
            )
          }
          {showPagination
          && (
            <CustPag
              itemsCount={itemsCount}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          )}
        </Col>
      </Row>
    );
  }
}
StartupsList.propTypes = {
  connectStartup: PropTypes.func.isRequired,
  declineStartup: PropTypes.func.isRequired,
  addFavorites: PropTypes.func.isRequired,
  removeFavorites: PropTypes.func.isRequired,
  stateOfList: PropTypes.shape({
    id: PropTypes.bool,
    adding: PropTypes.bool,
    favorite: PropTypes.bool,
    accepted: PropTypes.bool,
    pinging: PropTypes.bool,
    declining: PropTypes.bool,
    declined: PropTypes.bool,
  }).isRequired,
};

export default withRouter(StartupsList);
