import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const logo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/partnerships/searchVenturesLogo.png`;

class ModalLightster extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    displayed: false,
    toggleModal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-templates xx-large"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4>SeedLegals</h4>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
        </div>
        <div className="modal__body mt-5">
          <div className="section section-pad section-bg-btoa" id="bootcamp">
            <div className="container">

              <div className="row text-center">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="section-head">
                    <h3 className="animated" data-animate="fadeInUp" data-delay=".1">
                      <strong>
                        SearchVentures provides data-driven insights and analysis on the European startup market.
                      </strong>
                    </h3>
                  </div>
                  <img className="w-100" alt="logo" src={logo} />
                </div>
              </div>

              <div className="col-md-8 offset-md-2 mb-5 mt-3">
                <p className="lead">
                  <br />
                  <br />
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/urO68gpMURg "
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Fundraising Bootcamp Introduction"
                  />
                </p>
              </div>

              <div className="col-md-10 offset-md-1 text-justify mb-4">
                <p>
                  Our platform offers curated insights into funding rounds, market trends, and startup performance,
                  empowering investors with the knowledge needed for impactful investment decisions.
                </p>
                <p>
                  Through our targeted newsletter, we share exclusive updates and analysis, keeping our readers ahead
                  in a rapidly evolving market.
                </p>
                <br />
                <p>
                  Designed for precision and ease of use, SearchVentures is the
                  definitive resource, providing clarity on the European startup market.
                </p>
              </div>
            </div>
          </div>

          <ButtonToolbar className="modal__footer float-right">
            <a
              href="https://www.searchventures.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className="modal_ok rounded icon icon--right icon--blue"
                color="primary"
                onClick={this.toggle}
              >
                Visit Website <ChevronRightIcon />
              </Button>
            </a>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default ModalLightster;
