import React, { PureComponent } from 'react';
import {
  Collapse, Button, Row, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import LinkVariantIcon from 'mdi-react/LinkVariantIcon';
import SettingsIcon from 'mdi-react/CogOutlineIcon';
import LinksBoxVariantIcon from 'mdi-react/LinkBoxVariantIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Visitors from '../Visitors/Visitors';

export default class CollapseLink extends PureComponent {
  static propTypes = {
    modal: PropTypes.func.isRequired,
    link: PropTypes.shape({
      name: PropTypes.string,
      NDA: PropTypes.bool,
      code: PropTypes.string,
      url: PropTypes.string,
      countDeck: PropTypes.number,
      countFinancials: PropTypes.number,
      countMaterials: PropTypes.number,
      deck: PropTypes.bool,
      email: PropTypes.bool,
      financials: PropTypes.bool,
      id: PropTypes.number,
      materials: PropTypes.bool,
      password: PropTypes.string,
      protected: PropTypes.bool,
      time: PropTypes.number,
      visits: PropTypes.number,
      visitors: PropTypes.arrayOf(
        PropTypes.shape({
          NDASigned: PropTypes.bool,
          countDeck: PropTypes.number,
          countFinancials: PropTypes.number,
          countMaterials: PropTypes.number,
          email: PropTypes.string,
          id: PropTypes.number,
          lastVisited: PropTypes.string,
          time: PropTypes.number,
          visits: PropTypes.number,
        }),
      ),
    }),
  };

  static defaultProps = {
    link: {
      name: null,
      NDA: null,
      code: null,
      url: null,
      countDeck: 0,
      countFinancials: 0,
      countMaterials: 0,
      deck: null,
      email: null,
      financials: null,
      id: null,
      materials: null,
      password: null,
      protected: null,
      time: 0,
      visits: 0,
      visitor: [{
        NDASigned: null,
        countDeck: 0,
        countFinancials: 0,
        countMaterials: 0,
        email: null,
        id: null,
        lastVisited: null,
        time: 0,
        visits: 0,
      }],
    },
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
      status: 'closed',
      clipboardText: 'Copy To Clipboard',
    };
  }

  onEntering = () => {
    this.setState({ status: 'opening' });
  };

  onEntered = () => {
    this.setState({ status: 'opened' });
  };

  onExiting = () => {
    this.setState({ status: 'closing' });
  };

  onExited = () => {
    this.setState({ status: 'closed' });
  };

  onCopy = () => {
    this.setState({ clipboardText: 'Copied!' });
    setTimeout(() => {
      this.setState({ clipboardText: 'Copy To Clipboard' });
    }, 3000);
  };

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { link, modal } = this.props;
    const { collapse, status, clipboardText } = this.state;

    return (
      <div className={`collapse__wrapper links-collapse ${status}`} dir="ltr">
        <Row className={`collapse__title ${!link.active && ('link-inactive')}`}>
          <Col className="d-none d-lg-block" xs={0} lg={1}>
            <LinkVariantIcon />
          </Col>
          <Col className="link-name" xs={12} sm={4} md={4} lg={3}>
            {link.name} {!link.active && (<small>(Not Active)</small>)}
          </Col>
          <Col xs={12} sm={5} md={4} className="link mt-3 mb-2 mt-sm-0 mb-sm-0">
            <CopyToClipboard onCopy={this.onCopy} text={`http://${link.code}`}>
              <span>
                <div className="link__address">
                  <LinksBoxVariantIcon />
                  {link.code}
                </div>
                <div className="copy-to-clipboard">
                  {clipboardText}
                </div>
              </span>
            </CopyToClipboard>
          </Col>
          <Col className="d-none d-md-block text-center" xs={0} md={2}>
            {link.visits}
          </Col>
          <Col
            xs={{ size: 2, offset: 8 }}
            sm={{ size: 2, offset: 8 }}
            md={{ size: 1, offset: 0 }}
            className="link-buttons mt-3 mb-3 mt-lg-0 mb-lg-0"
          >
            <Button type="button" className="icon collapse__icon small rounded" outline onClick={() => modal(link)}>
              <p><SettingsIcon /></p>
            </Button>
          </Col>
          <Col xs={2} md={1} className="link-buttons mt-3 mb-3 mt-lg-0 mb-lg-0">
            <Button type="button" className="icon collapse__icon small rounded" outline onClick={this.toggle}>
              <p><ChevronDownIcon /></p>
            </Button>
          </Col>
        </Row>
        <Collapse
          isOpen={collapse}
          className="collapse__content"
          onEntering={this.onEntering}
          onEntered={this.onEntered}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <div>
            <Visitors visitors={link.visitors} />
          </div>
        </Collapse>
      </div>
    );
  }
}
