import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';

const renderTextField = ({
  input, label, error, errorText, select, placeholder,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={error}
    helperText={errorText}
    value={input.value}
    select={select}
    placeholder={placeholder}
    name={input.name}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  select: PropTypes.bool,
  placeholder: PropTypes.string,
};

renderTextField.defaultProps = {
  label: '',
  placeholder: '',
  select: false,
  error: false,
  errorText: '',
};

class ModalComponent extends PureComponent {
  static propTypes = {
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    displayed: PropTypes.bool,
    check: PropTypes.func,
    toggleModal: PropTypes.func.isRequired,
    successCallback: PropTypes.func,
    defaultErrorState: PropTypes.shape({}),
  };

  static defaultProps = {
    colored: false,
    displayed: false,
    check: null,
    successCallback: null,
    defaultErrorState: {
      email: {
        error: false,
        errorMessage: '',
      },
      firstName: {
        error: false,
        errorMessage: '',
      },
      lastName: {
        error: false,
        errorMessage: '',
      },
      plainPassword: {
        error: false,
        errorMessage: '',
      },
    }
    ,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      invalid: false,
      formErrors: props.defaultErrorState,
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { modal } = this.state;
    if (modal === false && newProps.displayed === true) {
      const { check } = this.props;
      if (check !== null) {
        check().then((data) => {
          this.setState({ errorMessage: data });
        });
      }
    }
    this.setState({
      modal: newProps.displayed,
      title: 'Create Startup',
    });
  }

  create = () => {
    const {
      invalid, email, firstName, lastName, plainPassword,
    } = this.state;
    const {
      toggleModal, successCallback,
    } = this.props;
    if (!invalid) {
      axios.post('broker/create_broker_startup', {
        email,
        firstName,
        lastName,
        plainPassword,
      }).then((response) => {
        toggleModal();
        if (typeof successCallback === 'function') {
          successCallback(response.data);
        }
        document.location = `${process.env.REACT_APP_DOMAIN_URL}/create/startup?_want_to_be_this_user=${email}`;
      }).catch((error) => {
        if (error && error.response && error.response.data) {
          const { errors } = error.response.data;
          const formErrors = {
            email: {},
            firstName: {},
            lastName: {},
            plainPassword: {},
          };

          errors.forEach((item) => {
            if (Object.prototype.hasOwnProperty.call(formErrors, item.field)) {
              formErrors[item.field].error = true;
              formErrors[item.field].errorMessage = item.message;
            }
          });
          this.setState({ formErrors });
        } else {
          // this.setState({errorMessage: 'Sorry, an error occurred, please try again.'});
        }
      });
      // this.setState(prevState => ({ modal: !prevState.modal }), () => {
      // });
    }
  }

  handleChange(event) {
    if (event.target.name === 'email') {
      this.setState({ email: event.target.value });
    }
    if (event.target.name === 'firstName') {
      this.setState({ firstName: event.target.value });
    }
    if (event.target.name === 'lastName') {
      this.setState({ lastName: event.target.value });
    }
    if (event.target.name === 'plainPassword') {
      this.setState({ plainPassword: event.target.value });
    }
  }

  toggle(e) {
    e.preventDefault();

    const { toggleModal } = this.props;
    toggleModal();
  }

  render() {
    const {
      color, colored,
    } = this.props;
    const {
      modal, invalid, formErrors, title, email, firstName, lastName, plainPassword, errorMessage,
    } = this.state;

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className="modal-dialog--primary modal-templates x-large"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            <h4>{title}</h4>
            {errorMessage}
            <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
          </div>
          <div className="modal__body">
            <form className="material-form">
              <div>
                <span className="material-form__label">Founder Email</span>
                <Field
                  name="email"
                  component={renderTextField}
                  value={email}
                  error={formErrors.email.error}
                  errorText={formErrors.email.errorMessage}
                  type="text"
                  placeholder="This founder will be able to log in with this email"
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <span className="material-form__label">Founder First Name</span>
                <Field
                  name="firstName"
                  component={renderTextField}
                  value={firstName}
                  error={formErrors.firstName.error}
                  errorText={formErrors.firstName.errorMessage}
                  type="text"
                  placeholder="Founder First Name"
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <span className="material-form__label">Founder Last Name</span>
                <Field
                  name="lastName"
                  component={renderTextField}
                  value={lastName}
                  error={formErrors.lastName.error}
                  errorText={formErrors.lastName.errorMessage}
                  type="text"
                  placeholder="Founder Last Name"
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <span className="material-form__label">Founder Password</span>
                <Field
                  name="plainPassword"
                  component={renderTextField}
                  error={formErrors.plainPassword.error}
                  errorText={formErrors.plainPassword.errorMessage}
                  value={plainPassword}
                  type="password"
                  placeholder="This founder will be able to log in with these credentials"
                  onChange={this.handleChange}
                />
              </div>
              <ButtonToolbar className="modal__footer float-right">
                <Button className="modal_cancel rounded" onClick={this.toggle}>Cancel</Button>{' '}
                {!invalid ? (
                  <Button
                    className="modal_ok rounded icon icon--right icon--blue"
                    type="button"
                    outline={colored}
                    color={color}
                    onClick={this.create}
                  >
                    Create <SendIcon />
                  </Button>
                ) : ''}
              </ButtonToolbar>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default reduxForm({
  form: 'create_startup_form', // a unique identifier for this form
})(ModalComponent);
