/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { Button } from 'reactstrap';
import getPicture from '../../../shared/functions/getPicture';

export default class TopbarMail extends PureComponent {
  state = {
    newMail: false,
    collapse: false,
    notifications: [],
  };

  componentDidMount = () => {
    this.getChats(false);
  };

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  getChats = () => {
    axios.get('conversations/latest')
      .then((response) => {
        if (response.data && Object.prototype.hasOwnProperty.call(response.data, 'conversations')) {
          this.setState({ notifications: response.data.conversations, newMail: response.data.unread });
        }
      }).catch(() => {});
  };

  render() {
    const { collapse, notifications, newMail } = this.state;
    return (
      <div className="topbar__collapse">
        <button
          className="topbar__btn topbar__btn--mail topbar__btn--new"
          type="button"
          onClick={this.toggle}
        >
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#8D9AA1" fill="none" />
            <path
              d="M16.4146 14L8 23.0909L15.561 19.1768L19.7073 23.0909L28 14L20.561 18.2929L16.4146 14Z"
              fill="#9DA7AC"
            />
          </svg>


          { newMail
          && (
            <div className="topbar__btn-new-label">
              <div />
            </div>
          )
          }
        </button>
        {
          collapse
          && (
            <>
              <button className="topbar__back" type="button" onClick={this.toggle} />
              <span className="topbar__collapse-arrow" />
              <div className="topbar__collapse-content">
                <div className="topbar__collapse-title-wrap">
                  <p className="topbar__collapse-title">Unread messages</p>
                </div>
                {notifications.map((notification, index) => (
                  <>
                    <div
                      className="topbar__collapse-item-divider
                      d-flex flex-row align-items-center justify-content-center"
                    >
                      <svg
                        width="221"
                        height="2"
                        viewBox="0 0 221 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 1C10.5 1 220.5 1 220.5 1" stroke="#EDEFF2" />
                      </svg>
                      <span className="topbar__collapse-date">
                        {notification.date}
                      </span>
                    </div>
                    <Link
                      className="topbar__collapse-item"
                      key={index}
                      to={`/inbox/${notification.conv_id}`}
                      onClick={this.toggle}
                    >
                      <div className="topbar__collapse-img-wrap">
                        <img
                          className="topbar__collapse-img"
                          src={getPicture(notification)}
                          alt="pic"
                        />
                      </div>
                      <div>
                        <p className="topbar__collapse-name">{notification.name}</p>
                        <p className="topbar__collapse-message topbar__collapse-message--mail">
                          {notification.message}
                        </p>
                      </div>
                    </Link>
                  </>
                ))}
                <Link to="/inbox" onClick={this.toggle}>
                  <Button
                    className="rounded icon icon--right icon--blue topbar__action-btn mb-3 mt-2"
                    color="primary"
                    size="sm"
                  >
                    See all messages <ChevronRightIcon />
                  </Button>
                </Link>
              </div>
            </>
          )
        }
      </div>
    );
  }
}
