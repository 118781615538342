import React, { Component, Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import { CookiesProvider } from 'react-cookie';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
// import Paywall from './Paywall';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaded: false,
      path: window.location.pathname,
    };
  }

  componentDidMount() {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '956556e3-58b5-44b5-a724-ba011a82a000';
    // eslint-disable-next-line func-names
    (function () {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1; d.getElementsByTagName('head')[0].appendChild(s);
    }());
    window.addEventListener('load', () => {
      this.checkLoginStatus();
    });
  }

  checkLoginStatus() {
    const { path } = this.state;
    const userStorage = JSON.parse(localStorage.getItem('user'));

    // Unfortunately, we cannot use the redux store here yet, but we want to check the user auth here.
    axios.get('/user')
      .then((response) => {
        if (typeof response.data === 'object') {
          // ALL GOOD
          localStorage.setItem('user', JSON.stringify(response.data));
          this.setState({ loading: false });
          setTimeout(() => this.startApp(response.data), 500);
        } else if (path.startsWith('/summary')) {
          // NO USER - ONLY FOR SUMMARY REVIEW
          this.setState({ loading: false });
          setTimeout(() => this.startApp(userStorage), 500);
        } else {
          // EVERYTHING ELSE
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/logout`;
        }
      })
      .catch(() => {
        if (path.startsWith('/summary')) {
          this.setState({ loading: false });
          setTimeout(() => this.startApp(userStorage), 500);
        } else {
          // eslint-disable-next-line no-alert
          alert('Your session expired. Please log in again'
            + ' If you are unable to log in, please contact support: contact@angelspartners.com');
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/logout`;
        }
      });
    this.setState({ loading: false });
    setTimeout(() => this.startApp(userStorage), 500);
  }

  startApp(user) {
    this.setState({ loading: false, loaded: true, user });
  }

  render() {
    const {
      loaded,
      loading,
      user,
    } = this.state;

    return (
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <PayPalScriptProvider options={{ 'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <BrowserRouter>
              <CookiesProvider>
                <ScrollToTop>
                  <Fragment>
                    {!loaded
                      ? (
                        <div className={`load${loading ? '' : ' loaded'}`}>
                          <div className="load__icon-wrap">
                            <svg className="load__icon">
                              <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                            </svg>
                          </div>
                        </div>
                      )
                      : (
                        <Router loggedIn={!!user} />
                      )
                    }
                  </Fragment>
                </ScrollToTop>
              </CookiesProvider>
            </BrowserRouter>
          </PayPalScriptProvider>
        </Elements>
      </Provider>
    );
  }
}

export default hot(module)(App);
