import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const picture = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/SeedLegals.png`;

class ModalSeedLegals extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    displayed: false,
    toggleModal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-templates xx-large"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4>SeedLegals</h4>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
        </div>
        <div className="modal__body mt-5">
          <div className="section section-pad section-bg-btoa" id="bootcamp">
            <div className="container">

              <div className="row text-center">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="section-head">
                    <h3 className="animated" data-animate="fadeInUp" data-delay=".1">
                      <strong>
                        SeedLegals automates the legals you need to grow your business,
                        with unlimited support along the way.
                      </strong>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="col-md-8 offset-md-2 mb-5 mt-3">
                <p className="lead">
                  <br />
                  <br />
                  <img src={picture} alt="SeedLegals" />
                </p>
              </div>

              <div className="col-md-10 offset-md-1 text-justify mb-4">
                <h4>
                  Enjoy £100 off ny SeedLegals one-off product or Annual Subscription with this partnership. Use the
                  code:
                </h4>
                <h3 className="mt-4 mb-3">PARTNER100</h3>
                <p>
                  SeedLegals automates the legals you need to grow your business, with unlimited support along the way.
                </p>
                <p>
                  From your first investment, to your Series A and beyond, SeedLegals gives you everything you need to
                  get it closed faster and more reliably than anywhere else.
                </p>
                <br />
                <ul>
                  <li>Term Sheet</li>
                  <li>Shareholder’s Agreement</li>
                  <li>Cap Table</li>
                  <li>The works</li>
                </ul>
              </div>
            </div>
          </div>

          <ButtonToolbar className="modal__footer float-right">
            <a
              href="https://fundraising-bootcamp.com/programme?utm_campaign=Partners&utm_source=y74m99&utm_medium=link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className="modal_ok rounded icon icon--right icon--blue"
                color="primary"
                onClick={this.toggle}
              >
                Visit Website <ChevronRightIcon />
              </Button>
            </a>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default ModalSeedLegals;
