import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { connect } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import Wizard from './WizardWhiteList';
import { FullWideNotification } from '../../shared/components/notifications/Notification';
import Modal from '../../shared/components/modals/ModalWhitelist';

let notificationTC = null;
const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 20,
    closable: true,
    style: { top: 0, left: 0 },
    className: `${position} ${rtl}-support`,
  });
};

// eslint-disable-next-line react/prefer-stateless-function
class WhitelistingPage extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
  };

  state = {
    modal: false,
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  setWhiteList = () => {
    this.setState({ modal: true });
    const user = JSON.parse(localStorage.getItem('user'));
    const provider = 'GSuite';

    user.mailboxWhitelisted = true;
    axios.post('/user/whitelisted', queryString.stringify({ provider }))
      .then((response) => {
        if (typeof response.data === 'object' && response.data.success === true) {
          this.connectMailbox(provider);
        } else {
          this.showNotif(
            'Sorry, an error occurred. Please try again or contact support if the problem occurs again.',
          );
        }
      })
      .catch((error) => {
        if (typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif(
            'Sorry, an error occurred. Please try again or contact support if the problem occurs again.',
          );
        }
      });
  }

  connectMailbox = (provider) => {
    // this.setState({ modal: true });
    axios.get(`/user/OAuth/${provider}`)
      .then((response) => {
        if (typeof response.data === 'object' && response.data.success === true) {
          // Open a new window
          window.open(response.data.url, '_blank', 'location=yes,height=800,width=620,scrollbars=yes,status=yes');
        } else {
          this.showNotif(
            'Sorry, an error occurred. Please try again or contact support if the problem occurs again.',
          );
        }
      })
      .catch((error) => {
        if (typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif(
            'Sorry, an error occurred. Please try again or contact support if the problem occurs again.',
          );
        }
      });
  }

  toggleModalConnect = () => {
    const { modal } = this.state;
    const { history } = this.props;

    if (modal) {
      history.push('/myProfile/settings');
    }
    this.setState({ modal: !modal });
  };

  render() {
    const { modal } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">WhiteList<br />Angels Partners</h3>
          </Col>
          <Col xs={12} md={9} xl={7}>
            <p className="page-description">
              Whitelisting Angels Partners enables our platform to track replies tracking in your campaigns.
              It&#39;s the best way to increase your response rate on Angels Partners.
            </p>
          </Col>
        </Row>
        <Row>
          <Wizard onSubmit={this.setWhiteList} />
        </Row>
        <Modal
          color="security"
          title="Before you continue"
          colored
          displayed={modal}
          toggleModal={this.toggleModalConnect}
          navigator={window.navigator.vendor}
        />
      </Container>
    );
  }
}

export default withRouter(connect(null)(WhitelistingPage));
