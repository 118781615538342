import React from 'react';
import {
  Button, Card, Col, Row,
} from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const StepsBlock = ({ toggleCreateStartupModal }) => (
  <Card elevation={0} className="Broker__empty">
    <Row>
      <Col xs={12} md={6} xl={4}>
        <div className="steps step-1">
          <h2>Step 1</h2>
          <h3>Create your first startup profile</h3>
          <p>
            Start by creating your first startup profile.
          </p>
          <Button
            className="icon icon--right rounded"
            color="white"
            outline
            onMouseDown={e => toggleCreateStartupModal(e)}
            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { toggleCreateStartupModal(e); } }}
          >
            New Startup
            <PlusIcon />
          </Button>
        </div>
      </Col>
      <Col xs={12} md={6} xl={4}>
        <div className="steps step-2">
          <h2>Step 2</h2>
          <h3>Subscribe to one of our plans</h3>
          <p>
            Subscribe to a plan and credit your accounts with pings.
          </p>
          <Link to="/ext/pricing">
            <Button
              className="icon icon--right rounded"
              color="white"
              outline
            >
              Subscribe
              <ChevronRightIcon />
            </Button>
          </Link>
        </div>
      </Col>
      <Col xs={12} lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 0 }}>
        <div className="steps step-3">
          <h2>Step 3</h2>
          <h3>Transfer your pings & start contacting investors</h3>
          <p>
            Assign Pings to the startups you manage by clicking on Add Pings and start contacting investors right away!
          </p>
        </div>
      </Col>
    </Row>
  </Card>
);

StepsBlock.propTypes = {
  toggleCreateStartupModal: PropTypes.func.isRequired,
};

export default StepsBlock;
