/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter a name for this link.';
  } else if (values.name && values.name.length < 3) {
    errors.name = 'The name of your link should be at least 3 characters long.';
  } else if (values.name && values.name.length > 100) {
    errors.name = 'The name of your link must be less than 100 characters long.';
  }

  if (values.protected && !values.password) {
    errors.password = 'If protected is selected, you need to enter a password';
  } else if (values.protected && values.password && values.password.length < 3) {
    errors.password = 'The password should be at least 3 characters long.';
  } else if (values.protected && values.password && values.password.length > 100) {
    errors.password = 'The password  must be less than 100 characters long.';
  }

  return errors;
};

export default validate;
