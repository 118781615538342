/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import validate from '../formValidate/formAccount';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={(touched && (typeof error !== 'undefined' && error !== ''))}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
};

class AccountSettings extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <div>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <span className="material-form__label"><h5>First Name</h5></span>
              <Field
                name="firstName"
                component={renderTextField}
                placeholder="Alexander"
              />
            </Col>
            <Col md={6} lg={6} xs={12}>
              <span className="material-form__label"><h5>Last Name</h5></span>
              <Field
                name="lastName"
                component={renderTextField}
                placeholder="Kennedy"
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <span className="material-form__label"><h5>Location City</h5></span>
              <Field
                name="location_city"
                component={renderTextField}
                placeholder="San Francisco"
              />
            </Col>
            <Col md={6} lg={6} xs={12}>
              <span className="material-form__label"><h5>Location Country</h5></span>
              <Field
                name="location_country"
                component={renderTextField}
                placeholder="U.S.A."
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <span className="material-form__label"><h5>Company</h5></span>
              <Field
                name="company"
                component={renderTextField}
                placeholder="Apple"
              />
            </Col>
            <Col md={6} lg={6} xs={12}>
              <span className="material-form__label"><h5>Company Role</h5></span>
              <Field
                name="companyRole"
                component={renderTextField}
                placeholder="Managing Director"
              />
            </Col>
          </Row>
        </div>
        <div>
          <span className="material-form__label"><h5>Email</h5></span>
          <Field
            name="email"
            component={renderTextField}
            placeholder="example@mail.com"
            type="email"
          />
        </div>
        <div>
          <span className="material-form__label"><h5>Your LinkedIn</h5></span>
          <Field
            name="linkedin"
            component={renderTextField}
            placeholder="Adding your LinkedIn increases your credibility and helps attracting the best startups"
            type="url"
          />
        </div>
        <div>
          <span className="material-form__label"><h5>Password (Optional)</h5></span>
          <Field
            name="new_password"
            component={renderTextField}
            placeholder="Fill out this field only if you want to change your password"
            type="password"
          />
        </div>
        <div>
          <span className="material-form__label"><h5>Password Check (Optional)</h5></span>
          <Field
            name="new_password_check"
            component={renderTextField}
            placeholder="For security reason, please re-enter your new password, if you wish to change it."
            type="password"
          />
        </div>
        <div>
          <span className="material-form__label"><h5>Current Password</h5></span>
          <Field
            name="password"
            component={renderTextField}
            placeholder="Enter your current password to confirm the changes"
            type="password"
          />
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button className="rounded" color="primary" type="submit">Update profile</Button>
          <Button className="rounded" type="button" onClick={reset} disabled={pristine || submitting}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'investor_account_form', // a unique identifier for this form
  validate,
})(AccountSettings);
