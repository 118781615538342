import React, { Component } from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import { connect } from 'react-redux';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import Papa from 'papaparse';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  BasicNotification,
  FullWideNotification,
  LinkNotification,
} from '../../shared/components/notifications/Notification';
import ActionsList from './components/List';
import CsvFileUpload from './components/CsvFileUpload';
import TableComponent from './components/TableComponent';
import ModalImport from '../../shared/components/modals/ModalImport';
import ModalMultiUpdateExtInvestors from '../../shared/components/modals/ModalMultiUpdateExtInvestors';
import ImportForm from './components/ImportForm';

let notificationTC = null;
const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 10,
    closable: true,
    style: { top: 30, left: 'calc(100vw - 100%)' },
    className: `${position} ${rtl}-support`,
  });
};
// Allowed extensions for input file
const allowedExtensions = ['csv'];

class ImportPage extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
  };

  state = {
    step: 0,
    type: '',
    method: '',
    parsedData: [],
    columns: [],
    file: null,
    columnNames: [],
    campaigns: [],
    campaign: null,
    modalImport: false,
    data: [],
    modalMultiInvestorsCampaigns: false,
    investorsContactInfo: null,
    investorContactInfo: null,
    submitting: false,
  }

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (typeof user !== typeof undefined && user !== null && user.type === 'investor') {
      window.location = '/startups';
    }
    if (typeof user !== typeof undefined && user !== null && user.type === 'broker') {
      window.location = '/managed_startups';
    }
    const event = new Event('reload');
    window.dispatchEvent(event);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);

    this.getColumnName();
    this.getCampaigns();
  }

  componentWillUnmount = () => {
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  getColumnName = () => {
    axios.get('/import/columnNames')
      .then((response) => {
        if (response.data && response.data.length > 0) {
          this.setState({ columnNames: response.data });
        }
      });
  }

  getCampaigns = () => {
    axios.get('/campaigns/basicInfo')
      .then((response) => {
        if (response.data && response.data.length > 0) {
          this.setState({ campaigns: response.data });
        }
      });
  }

  toggleStep = (val) => {
    const { step } = this.state;
    if (val === 'import' || val === 'blacklist') {
      this.setState({ type: val });
    } else {
      this.setState({ method: val });
    }
    this.setState({ step: step + 1 });
  }

  goBack = () => {
    const { step } = this.state;
    if (step === 2) {
      this.setState({ method: '' });
    } else {
      this.setState({ type: '', method: '' });
    }
    this.setState({
      step: step - 1, file: null, parsedData: [], columns: [],
    });
  }

  getCsvFile = (e) => {
    // Check if user has entered the file
    if (e[0] && e[0].type === 'text/csv') {
      const inputFile = e[0];

      // Check the file extensions, if it not included in the allowed extensions we show the error
      const fileExtension = inputFile.type.split('/')[1];
      if (!allowedExtensions.includes(fileExtension)) {
        this.showNotifError('Please input a CSV file.');
        return;
      }

      // If input type is correct set the state
      this.setState({ file: inputFile });
    }
  };

  readCsvFile = () => {
    const { file } = this.state;
    // If user clicks the parse button without a file we show a error
    if (!file) {
      this.showNotifError('Enter a valid CSV file.');
      return;
    }

    // Initialize a reader which allows user to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv.data;
      const columns = Object.keys(parsedData[0]);
      this.setState({ parsedData, columns });
    };
    reader.readAsText(file);
  };

  handleSubmit = (values) => {
    this.setState({ submitting: true }, () => {
      const {
        parsedData, campaign, type, file,
      } = this.state;
      axios.post('/import/csv', {
        values, parsedData, campaign, type, file,
      }).then((response) => {
        if (response.data && response.data.success) {
          this.setState({ data: response.data.data, modalImport: true, submitting: false });
        } else {
          this.setState({ submitting: false });
        }
      }).catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotifError(error.response.data.message);
        } else {
          this.showNotifError('Sorry, an error occurred, please try again.');
        }
        this.setState({ submitting: false });
      });
    });
  }

  reset = () => {
    this.setState({
      submitting: false, step: 0, method: null, type: null, parsedData: [], columns: [], file: null,
    });
  }

  importInvestor = (values) => {
    const { campaign, type } = this.state;
    axios.post('/import/manual', { values, type, campaign })
      .then((response) => {
        if (response.data && response.data.success) {
          const investors = [response.data.investor];
          if (type === 'import') {
            axios.post('/import/getContactInfo', { investors })
              .then((resp) => {
                if (resp.data && resp.data.success) {
                  this.setState({
                    investorsContactInfo: resp.data.investorsContactInfo,
                    investorContactInfo: resp.data.investorsContactInfo[0],
                    modalMultiInvestorsCampaigns: true,
                  });
                }
              })
              .catch((error) => {
                if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
                  this.showNotifError(error.response.data.message);
                } else {
                  this.showNotifError('Sorry, an error occurred, please try again.');
                }
              });
          } else {
            this.showNotifSuccess('Blacklisted !');
            this.goBack();
          }
          this.setState({ data: response.data.data, modalImport: true });
        }
      }).catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotifError(error.response.data.message);
        } else {
          this.showNotifError('Sorry, an error occurred, please try again.');
        }
      });
  }

  capitalize = (string) => {
    const upperString = string.charAt(0).toUpperCase() + string.slice(1);
    return upperString.replace('-', ' ').replace('_', ' ');
  }

  chooseCampaign = (value) => {
    this.setState({ campaign: value });
  }

  toggleModalImport = () => {
    const { modalImport, data } = this.state;
    if (data && data.imported > 0) {
      axios.post('/import/remove', { count: data.imported })
        .then((response) => {
          if (response.data && response.data.success) {
            this.showNotifSuccess('Import has been cancelled !');
          }
        })
        .catch(() => {
          this.showNotifError('Sorry, an error occurred, please try again.');
        });
    }
    this.setState({ modalImport: !modalImport });
  };

  importInvestorsCampaign = (number, investors) => {
    const { modalImport, type } = this.state;
    const { history } = this.props;
    this.setState({ modalImport: !modalImport });
    if (type === 'blacklist') {
      this.showNotifSuccess('All these investors have been blacklisted');
      this.reset();
      setTimeout(() => history.push('/dashboard'), 2000);
    } else if (type === 'import' && number > 0) {
      axios.post('/import/getContactInfo', { investors })
        .then((response) => {
          if (response.data && response.data.success) {
            this.setState({
              investorsContactInfo: response.data.investorsContactInfo,
              investorContactInfo: response.data.investorsContactInfo[0],
              modalMultiInvestorsCampaigns: true,
            });
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            this.showNotifError(error.response.data.message);
          } else {
            this.showNotifError('Sorry, an error occurred, please try again.');
          }
        });
    } else {
      this.showNotifWarning('We found 0 new investors to import.');
    }
  }

  toggleModalMultiInvestorsCampaigns = () => {
    const { modalMultiInvestorsCampaigns } = this.state;
    this.setState({ modalMultiInvestorsCampaigns: !modalMultiInvestorsCampaigns });
  };

  showNotif = (message) => {
    showNotification({
      notification: <FullWideNotification
        color="danger"
        message={message}
      />,
      position: 'full',
    }, 'ltr');
  }

  showNotifWarning = (message, link) => {
    showNotification({
      notification: <LinkNotification
        color="warning"
        title="Warning!"
        link={link}
        message={message}
      />,
      position: 'right-up',
    });
  }

  showNotifSuccess = (message) => {
    const { history } = this.props;
    showNotification({
      notification: <BasicNotification
        color="primary"
        message={message}
      />,
      position: 'right-bottom',
    });

    // Small hack to make sure we're going back to the dashboard
    if (message.includes('successfully')) {
      history.push({ pathname: '/dashboard' });
    }
  }

  showNotifError = (message) => {
    showNotification({
      notification: <BasicNotification
        color="danger"
        message={message}
      />,
      position: 'right-bottom',
    });
  }

  render() {
    const {
      step, method, type, parsedData, columns, columnNames, campaigns, campaign, submitting,
      modalImport, modalMultiInvestorsCampaigns, data, file, investorsContactInfo, investorContactInfo,
    } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={3} lg={2} xl={2}>
            <h3 className="page-title">{type && type === 'blacklist' ? 'BLACKLIST' : 'IMPORT'} INVESTORS</h3>
          </Col>
          <Col xs={12} md={9} lg={6} xl={6}>
            <p className="page-description">
              You can import investors into your campaigns in order to automate your
              outreach to investors who are outside of Angels Partners.
              <br />
              <u>Alternatively</u>, you can blacklist investors you already contacted outside
              of the platform, to ensure they won’t be contacted again.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={1} lg={1}>
            {step !== 0 && (
              <span
                role="presentation"
                onMouseDown={this.goBack}
                className="back-btn mt-2"
              >
                <ChevronLeftIcon /> BACK
              </span>
            )}
          </Col>
          {!method && (
            <ActionsList step={step} toggleStep={val => this.toggleStep(val)} goBack={this.goBack} />
          )}
          {step === 2 && method === 'csv' && (
            <CsvFileUpload
              getCsvFileHandler={this.getCsvFile}
              handleSubmit={this.readCsvFile}
              parsedData={parsedData}
            />
          )}
          {step === 2 && method === 'csv' && parsedData && parsedData.length > 0 && columns.length > 0 && file && (
            <TableComponent
              onSubmit={values => this.handleSubmit(values)}
              submittingLoader={submitting}
              columns={columns}
              columnNames={columnNames}
              parsedData={parsedData}
              returnStep={this.reset}
              campaigns={campaigns}
              campaign={campaign}
              type={type}
              chooseCampaign={value => this.chooseCampaign(value)}
            />
          )}
          {step === 2 && method === 'csv' && parsedData && parsedData.length > 0 && columns.length > 0 && file
          && data && (
            <ModalImport
              color="primary"
              title="CSV Summary Report"
              colored
              displayed={modalImport}
              type={type}
              toggleModal={() => this.toggleModalImport()}
              confirmAction={() => this.importInvestorsCampaign(data.imported, data.investors_contact)}
              message={`From ${parsedData.length} leads in ${file.name}:<br />
                <br/>
                <ul>
                  <li>${data.imported} leads will be 
                    ${type === 'import' ? 'added to this campaign' : 'blacklisted from your campaigns'}</li>
                  <br/>
                  <li>
                    ${data.invalid_email + data.duplicated_campaign + data.duplicated_csv}
                    &nbsp;leads will be ignored:
                    <ul>
                      <li>
                        ${data.duplicated_csv}&nbsp;
                        ${data.duplicated_csv > 1 ? 'leads' : 'lead'} are already in this campaign
                      </li>
                      <li>
                        ${data.invalid_email}&nbsp;
                        ${data.invalid_email > 1 ? 'Leads have' : 'Lead has'} invalid email
                      </li>
                      <li>
                        ${data.duplicated_campaign}&nbsp;
                        Duplicated ${data.duplicated_campaign > 1 ? 'leads' : 'lead'}
                        ${type === 'import' ? 'from another campaign' : 'already blacklisted'}
                      </li>
                    </ul>
                  </li>
                  <br/>
                </ul>
                <p>Do you still want to ${type === 'import' ? 'import' : 'blacklist'} these contacts?</p>
                `}
            />
          )}
          {step === 2 && method === 'mail' && (
            <ImportForm
              campaigns={campaigns}
              campaign={campaign}
              type={type}
              chooseCampaign={value => this.chooseCampaign(value)}
              onSubmit={values => this.importInvestor(values)}
            />
          )}
          <ModalMultiUpdateExtInvestors
            investorsContactInfo={investorsContactInfo}
            investorContactInfo={investorContactInfo}
            fundsContactInfo={[]}
            campaigns={campaigns}
            displayed={modalMultiInvestorsCampaigns}
            toggleModal={() => this.toggleModalMultiInvestorsCampaigns()}
            showNotif={message => this.showNotif(message)}
            showNotifSuccess={name => this.showNotifSuccess(name)}
            showNotifWarning={(message, link) => this.showNotifWarning(message, link)}
            editCampaign={campaign ? campaign.id : null}
          />
        </Row>
      </Container>
    );
  }
}

export default withRouter(connect(state => ({
  user: state.user,
}))(ImportPage));
