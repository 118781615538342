import React, { Component } from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import ModalAdvisors from '../modals/ModalAdvisors';

const logo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/growthMentorLogo.png`;

class CardAdvisors extends Component {
  state = {
    modal: false,
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  render() {
    const { modal } = this.state;
    return (
      <Col md={6} xl={4} sm={12}>
        <Card onClick={this.toggleModal}>
          <CardBody className="dashboard-card dashboard-card--info">
            <div className="dashboard-card__body">
              <img className="mt-4 mb-4" src={logo} alt="" />
              <h3 className="dashboard-card__plan mb-5">20% Off</h3>
              <hr />
              <p className="dashboard-card__title">Connect with the best advisors in your industry</p>
              <div className="dashboard-card__desc top-line text-justify mt-5 mb-5">
                <p>
                  Growth is hard. And it can hurt. But you do not have to go it alone with GrowthMentor.
                </p>
                <p>
                  Have 1-on-1 conversations about growth, marketing, and everything in between with the world s top
                  3% of startup and marketing mentors.
                </p>
              </div>
              <Button className="mt-3 dashboard-card__button" color="primary">
                Learn More
              </Button>
            </div>
          </CardBody>
        </Card>
        <ModalAdvisors
          displayed={modal}
          toggleModal={this.toggleModal}
        />
      </Col>
    );
  }
}

export default CardAdvisors;
