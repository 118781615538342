import React from 'react';
import {
  Row, Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import FlagVariantOutlineIcon from 'mdi-react/FlagVariantOutlineIcon';
import FavoriteButton from '../../../shared/components/buttons/FavoriteButton';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const ProfileMain = ({
  fullName, companyRole, company, locationCity, locationCountry,
  picture, renderMailButton, adding, favorite, fundId, addFavorites, removeFavorites,
  linkedin, twitter, facebook, website, crunchbase, angellist, google, clickReport,
}) => (
  <div className="profile__card profile__information">
    <Row>
      <Col xs={12} md={5} lg={4} xl={3}>
        <div className="profile__avatar">
          <img
            className="investor"
            src={picture ? process.env.REACT_APP_AWS_PATH + picture : userIcon}
            onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
            alt="avatar"
          />
        </div>
        <div className="profile__links">
          {website
          && (
            <a
              href={website}
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/w-round.png`}
                alt="website"
              />
            </a>
          )}
          {linkedin
          && (
            <a
              href={linkedin}
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/li-round.png`}
                alt="linkedin"
              />
            </a>
          )}
          {crunchbase
          && (
            <a
              href={crunchbase}
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/cb-round.png`}
                alt="crunchbase"
              />
            </a>
          )}
          {angellist
          && (
            <a
              href={angellist}
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/al-round.png`}
                alt="angellist"
              />
            </a>
          )}
          {facebook
          && (
            <a
              href={facebook}
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/fb-round.png`}
                alt="facebook"
              />
            </a>
          )}
          {twitter
          && (
            <a
              href={twitter}
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/tw-round.png`}
                alt="twitter"
              />
            </a>
          )}
          {google
          && (
            <a
              href={google}
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/g-round.png`}
                alt="google"
              />
            </a>
          )}
        </div>
      </Col>
      <Col xs={12} md={{ size: 7, offset: 0 }} lg={{ size: 7, offset: 1 }} xl={{ size: 8, offset: 1 }}>
        <div className="profile__data pt-md-5 mt-5 pl-md-3 pl-lg-0 mb-5 mb-md-0">
          <br className="d-none d-md-block" />
          <h1 className="profile__name">{ fullName }</h1>
          {
            company && fundId
            && (
              <p className="profile__work text-muted">
                {companyRole} at <strong><u><Link to={`/investor-db/${fundId}`}>{company}</Link></u></strong>
              </p>
            )
          }
          { company && !fundId && <p className="profile__work">{companyRole} at {company}</p> }
          <p className="profile__location">
            <MapMarkerIcon />
            {locationCity}&nbsp;{locationCountry && locationCity && '|'}&nbsp;{locationCountry}
          </p>
          <FavoriteButton
            addFavorites={e => addFavorites(e)}
            entity={{ adding, favorite }}
            removeFavorites={e => removeFavorites(e)}
            displayFavorites
          />
          {renderMailButton}
          <small
            className="extinvestor__report"
            role="presentation"
            onMouseUp={e => clickReport(e)}
            onKeyUp={(e) => { if (e.which === 13 || e.keyCode === 32) { clickReport(e); } }}
          >
            <FlagVariantOutlineIcon />
          </small>
        </div>
      </Col>
    </Row>
  </div>
);

export default ProfileMain;


ProfileMain.defaultProps = {
  fullName: '',
  companyRole: '',
  company: '',
  locationCity: '',
  locationCountry: '',
  picture: '',
  fundId: null,
  adding: false,
  favorite: false,
  linkedin: null,
  twitter: null,
  facebook: null,
  google: null,
  website: null,
  angellist: null,
  crunchbase: null,
};

ProfileMain.propTypes = {
  fullName: PropTypes.string,
  companyRole: PropTypes.string,
  company: PropTypes.string,
  locationCity: PropTypes.string,
  locationCountry: PropTypes.string,
  picture: PropTypes.string,
  adding: PropTypes.bool,
  favorite: PropTypes.bool,
  fundId: PropTypes.number,
  renderMailButton: PropTypes.oneOfType([PropTypes.object]).isRequired,
  addFavorites: PropTypes.func.isRequired,
  removeFavorites: PropTypes.func.isRequired,
  clickReport: PropTypes.func.isRequired,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  google: PropTypes.string,
  crunchbase: PropTypes.string,
  angellist: PropTypes.string,
  website: PropTypes.string,
};
