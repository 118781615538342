import React, { PureComponent } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import { UserProps } from '../../prop-types/ReducerProps';
import Alert from '../alerts/Alert';

class ModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    displayed: PropTypes.bool,
    ping: PropTypes.func,
    user: UserProps.isRequired,
    toggleModal: PropTypes.func.isRequired,
    investor: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
    }),
    isCustomer: PropTypes.bool,
    questionsEnabled: PropTypes.bool,
    questions: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    colored: false,
    displayed: false,
    ping: null,
    investor: null,
    isCustomer: false,
    questionsEnabled: false,
    questions: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      invalid: false,
      tooLong: false,
      variables: null,
      message: '',
      errorMessage: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.ping = this.ping.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { modal } = this.state;

    if (modal === false && newProps.displayed === true && newProps.investor !== null) {
      this.checkPingInvestor(newProps.investor);
    }
    this.setState({ modal: newProps.displayed, message: '' });
  }

  checkPingInvestor = (investor) => {
    axios.post('/investors/checkPing', queryString.stringify({ id: investor.id }))
      .then((response) => {
        if (response.data.error) {
          let errorMessage = 'This investor does not meet your investment criteria.';
          const data = response.data.message;
          if (data.find(el => el === 'stage')) {
            errorMessage = errorMessage.concat(' ', investor.firstName, ' does not invest in your stage.');
          }
          // if (data.find(el => el === 'category')) {
          //   errorMessage = errorMessage.concat(' ', investor.firstName, ' does not invest in your industry.');
          // }
          if (data.find(el => el === 'country')) {
            errorMessage = errorMessage.concat(' ', investor.firstName, ' does not invest in your country.');
          }
          if (data.find(el => el === 'minimum')) {
            errorMessage = errorMessage.concat(' ', investor.firstName, ' does not invest more than your minimum'
              + ' ticket size.');
          }
          this.setState({ errorMessage });
        } else {
          this.setState({ errorMessage: '' });
        }
      });
  }

  handleChange(event) {
    const { user } = this.props;
    if (!user.isCustomer) {
      // If user is in free plan then we do not let him share his email address.
      this.checkIfEmailInString(event.target.value);
    }
    this.checkMessageLength(event.target.value);
    this.checkForVariables(event.target.value);
    this.setState({ message: event.target.value });
  }

  ping() {
    const { ping } = this.props;
    const { message, invalid } = this.state;
    if (!invalid) {
      // this.setState(prevState => ({ modal: !prevState.modal }), () => {
      ping(message);
      // });
    }
  }

  checkIfEmailInString(text) {
    // eslint-disable-next-line no-useless-escape
    const re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    this.setState({ invalid: re.test(text) });
  }

  checkMessageLength(text) {
    this.setState({ tooLong: text.length > 740 });
  }

  checkForVariables(text) {
    // Define the regular expression to match variables enclosed in double curly braces
    const variablePattern = /{{(.*?)}}/g;
    const variables = [];
    let match;

    // Use the regular expression to find all matches in the text
    match = variablePattern.exec(text);
    while (match !== null) {
      // Extract the variable name and add it to the variables array
      variables.push(match[0].trim());
      match = variablePattern.exec(text);
    }

    if (variables.length === 0) {
      this.setState({ variables: null });
    } else {
      // Join the variables array into a single string separated by commas
      this.setState({ variables: variables.join(', ') });
    }
  }

  toggle(e) {
    e.preventDefault();

    const scrollYOffset = window.pageYOffset;

    const { toggleModal } = this.props;
    if (typeof toggleModal === 'function') {
      toggleModal();
    }

    this.setState(prevState => ({ modal: !prevState.modal }), () => {
      // make sure we scroll back to where we were on the page
      let x = 0;
      const refreshIntervalId = setInterval(() => {
        x += 1;
        if (x > 20) clearInterval(refreshIntervalId);
        const newScrollYOffset = window.pageYOffset;
        if (newScrollYOffset !== scrollYOffset) {
          window.scrollTo(0, scrollYOffset);
          clearInterval(refreshIntervalId);
        }
      }, 50);
    });
  }

  render() {
    const {
      color, title, colored, isCustomer, questionsEnabled, questions,
    } = this.props;
    const {
      modal, message, invalid, errorMessage, tooLong, variables,
    } = this.state;

    let notification = null;

    if (errorMessage !== '' && typeof errorMessage !== typeof undefined) {
      notification = (
        <Alert color="warning" className="alert--colored">
          <span className="bold-text">Warning! </span>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className="modal-dialog--primary modal-templates x-large"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            <h4>{title}</h4>
            <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
          </div>
          <form className="material-form">
            {notification}
            <div className="modal__body pt-0">
              {isCustomer && questionsEnabled && questions && questions.length > 0 ? (
                <div className="text-muted">
                  <p className="p-20">
                    <strong>Investor Question: &nbsp;</strong>
                    {questions}
                  </p>
                </div>
              ) : ''}
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <textarea
                    name="message"
                    style={{ minHeight: '130px' }}
                    className="mt-4 mb-4"
                    placeholder="You can provide a custom message here"
                    onChange={this.handleChange.bind(this)}
                    value={message}
                  />
                </div>
              </div>
              {invalid ? (
                <span className="text-red">You cannot share an email address with a free plan.</span>
              ) : ''}
              {tooLong ? (
                <span className="text-red">Investors do not read long messages. Stay below 700 chars.</span>
              ) : ''}
              {variables ? (
                <span className="text-red">
                  Dynamic variables should not be used when contacting our investor community,
                  please remove { variables }
                </span>
              ) : ''}
              <ButtonToolbar className="modal__footer float-right">
                <Button className="modal_cancel rounded" onClick={this.toggle}>Cancel</Button>{' '}
                {!invalid ? (
                  <Button
                    className="modal_ok rounded icon icon--right icon--blue"
                    type="button"
                    outline={colored}
                    color={color}
                    onClick={this.ping}
                  >
                    Send! <SendIcon />
                  </Button>
                ) : ''}
              </ButtonToolbar>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user,
}))(ModalComponent);
