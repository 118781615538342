/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (!values.message) {
    errors.message = 'Please describe what type of introductions you are looking for.';
  } else if (values.message.length < 10) {
    errors.message = 'Your message should be at least 10 characters long.';
  } else if (values.message.length > 1500) {
    errors.message = 'Your message should be less than 1500 characters long.';
  }

  return errors;
};

export default validate;
