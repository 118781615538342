/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import validate from '../../formValidate/link';
import renderTextField from '../../../../shared/components/form/TextFieldMaterials';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

class LinkForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    hasPasscode: PropTypes.bool,
    active: PropTypes.bool,
    saving: PropTypes.bool.isRequired,
    update: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.bool.isRequired,
      protected: PropTypes.bool.isRequired,
      password: PropTypes.string.isRequired,
      deck: PropTypes.bool.isRequired,
      financials: PropTypes.bool.isRequired,
      materials: PropTypes.bool.isRequired,
      active: PropTypes.bool.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    hasPasscode: false,
    active: false,
  };

  render() {
    const {
      handleSubmit, pristine, submitting, hasPasscode,
      toggle, saving, initialValues, update, active,
    } = this.props;

    const button = (
      update ? 'Update'
        : <span>Create Link <PlusIcon /></span>
    );

    const textCheckBoxActive = active ? 'Link Is Active' : 'Link Is Not Active';

    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
          <h4 className="text-modal  modal__title">Create Tracking Link</h4>
        </div>
        <div className="modal__body">
          <div className="mt-4 mb-5">
            Select security and access settings for each link that you create.
            Create a new link for one or many recipients to apply custom
            security settings for that or those viewers.
            Link settings can be updated even after it has been shared.
          </div>
          <div className={`form__form-group ${!update && ('hidden')}`}>
            <div className="form__form-group-field">
              <Field
                name="active"
                component={renderCheckBoxField}
                label={textCheckBoxActive}
                className="button"
                defaultChecked={initialValues.active}
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="name"
                component={renderTextField}
                type="text"
                label="Enter a name for this link (LinkedIn, Investors...)."
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="email"
                component={renderCheckBoxField}
                label="Require Email To View"
                radioValue="1"
                className="colored-click"
                defaultChecked={initialValues.email}
              />
            </div>
            <span className="form__form-group-description">
              Viewers must enter an email to view your summary.<br />
              Improves tracking capabilities (Recommended)
            </span>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="protected"
                component={renderCheckBoxField}
                label="Require Password To View"
                radioValue="1"
                className="colored-click"
                defaultChecked={initialValues.protected}
              />
            </div>
            <span className="form__form-group-description">
              Viewers must enter a passcode to view your summary.
            </span>
          </div>
          {hasPasscode
          && (
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="password"
                  component={renderTextField}
                  type="text"
                  placeholder="Enter Passcode"
                />
              </div>
            </div>
          )}
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="deck"
                component={renderCheckBoxField}
                label="Include Deck With Summary"
                radioValue="1"
                className="colored-click"
                defaultChecked={initialValues.deck}
              />
            </div>
            <span className="form__form-group-description">
              Viewers with this link can also review your deck.
            </span>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="financials"
                component={renderCheckBoxField}
                label="Include Financials With Summary"
                radioValue="1"
                className="colored-click"
                defaultChecked={initialValues.financials}
              />
            </div>
            <span className="form__form-group-description">
              Viewers with this link can also review your financials document.
            </span>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="materials"
                component={renderCheckBoxField}
                label="Include Materials With Summary"
                radioValue="1"
                className="colored-click"
                defaultChecked={initialValues.materials}
              />
            </div>
            <span className="form__form-group-description">
              Viewers with this link can also review your extra materials.
            </span>
          </div>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            className="modal_cancel rounded"
            onClick={toggle}
            disabled={pristine || submitting}
          >
            Cancel
          </Button>
          {' '}
          <Button
            className="modal_ok rounded icon--right icon--blue"
            type="submit"
            color="primary"
          >
            {saving ? 'Saving...' : button}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

// Decorate with connect to read form values
const selector = formValueSelector('link_form');
// eslint-disable-next-line no-class-assign
LinkForm = connect((state) => {
  // can select values individually
  const hasPasscode = selector(state, 'protected');
  const active = selector(state, 'active');
  return {
    hasPasscode,
    active,
  };
})(LinkForm);


export default reduxForm({
  form: 'link_form',
  validate,
})(LinkForm);
