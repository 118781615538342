import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

// Main Dashboard
import Dashboard from '../Dashboard';
import ImportPage from '../ImportPage';
import WhitelistingPage from '../WhitelistingPage';

// Campaigns
import CreateCampaignPage from '../Campaigns/Create';
import EditCampaignPage from '../Campaigns/Edit';
import CampaignsPage from '../Campaigns/List';

// Conversations
import ChatPage from '../Chat';

// Investor Base
import ExternalInvestorsList from '../ExternalInvestors';
import ExternalInvestorPage from '../ExternalInvestorPage';

// Brokers
import ManagedStartupsList from '../ManagedStartups';

// Startup Pages
import FinancialsPage from '../FinancialsPage/Summary';
import StartupDeckPage from '../DeckPage/Startup';
import StartupFinancialsPage from '../FinancialsPage/Startup';
import SummaryPage from '../SummaryPage';
import SummaryDeckPage from '../DeckPage/Summary';

// Investor Community
import InvestorsPage from '../InternalInvestors';
import InvestorPage from '../InternalInvestorPage';
import NotificationsPage from '../NotificationsPage';
import ProfileSettings from '../ProfileSettings';

// Investor Pages
import InvestorProfile from '../MyInvestorProfile';

// Tracking Links
import LinksPage from '../Links';

// Resources
import ResourcesPage from '../ResourcesPage';
import PartnershipsPage from '../PartnershipsPage';

// Startup Community
import StartupsList from '../Startups';
import StartupPage from '../StartupPage';
import StartupProfile from '../MyStartupProfile';

// Warm Introductions Page
import LinkedinIntroPage from '../LinkedinWarmIntroPage';

// Founders Community
import Founders from '../Founders';
import FounderPage from '../FounderPage';
import FounderProfile from '../MyFounderProfile';
// const FavoriteInvestorsPage = React.lazy(() => import('../FavoriteInvestors'));

const MyProfiles = () => (
  <Switch>
    <Route path="/myProfile/investor" component={InvestorProfile} />
    <Route path="/myProfile/company" component={StartupProfile} />
    <Route path="/myProfile/founder" component={FounderProfile} />
    <Route path="/myProfile/settings" component={ProfileSettings} />
  </Switch>
);

const WrappedCampaignsRoutes = () => (
  <Switch>
    <Route exact path="/campaigns/" component={CampaignsPage} />
    <Route path="/campaigns/create" component={CreateCampaignPage} />
    <Route path="/campaigns/edit/:id" component={EditCampaignPage} />
    {/* <Route path="/campaigns/:id" component={SummaryPage} /> */}
  </Switch>
);

const WrappedStartupRoutes = () => (
  <Switch>
    <Route path="/startup/deck/:id" component={StartupDeckPage} />
    <Route path="/startup/financials/:id" component={StartupFinancialsPage} />
    <Route path="/startup/:id" component={StartupPage} />
    <Route path="/startup/myCompany" component={StartupPage} />
  </Switch>
);

const WrappedFounderRoutes = () => (
  <Switch>
    <Route path="/founder/:id" component={FounderPage} />
    <Route path="/founder/myProfile" component={FounderPage} />
  </Switch>
);

const wrappedPrivateRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/import" component={ImportPage} />
        <Route path="/whitelisting" component={WhitelistingPage} />
        <Route path="/campaigns" component={WrappedCampaignsRoutes} />
        <Route path="/inbox/:id?" component={ChatPage} />
        <Route path="/investors" component={InvestorsPage} />
        <Route path="/investor/:id" component={InvestorPage} />
        <Route path="/myProfile" component={MyProfiles} />
        <Route path="/notifications" component={NotificationsPage} />
        <Route path="/investors-db" component={ExternalInvestorsList} />
        <Route path="/investor-db/:id" component={ExternalInvestorPage} />
        <Route path="/links" component={LinksPage} />
        <Route path="/resources" component={ResourcesPage} />
        <Route path="/partnerships" component={PartnershipsPage} />
        <Route path="/managed_startups" component={ManagedStartupsList} />
        <Route path="/startup" component={WrappedStartupRoutes} />
        <Route path="/founders" component={Founders} />
        <Route path="/founder" component={WrappedFounderRoutes} />
        <Route path="/startups" component={StartupsList} />
        <Route path="/warm-intro" component={LinkedinIntroPage} />
        <Route
          path="/logout"
          component={() => {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/logout`;
            return null;
          }}
        />
        <Route
          exact
          path="/"
          component={Dashboard}
        />
        <Route
          component={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/404`;
            return null;
          }}
        />
      </Switch>
    </div>
  </div>
);

const wrappedExternalRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route
          path="/ext/blog"
          component={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/blog`;
            return null;
          }}
        />
        <Route
          path="/ext/terms_and_conditions"
          component={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/terms_and_conditions`;
            return null;
          }}
        />
        <Route
          path="/ext/landing_page"
          component={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}`;
            return null;
          }}
        />
        <Route
          path="/ext/pricing"
          component={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/pricing`;
            return null;
          }}
        />
        <Route
          path="/ext/pricing/investor"
          component={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/pricing/investor`;
            return null;
          }}
        />
        <Route
          path="/ext/contact"
          component={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/contact`;
            return null;
          }}
        />
        <Route
          path="/ext/register/investor"
          component={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/register/investor`;
            return null;
          }}
        />
        <Route
          path="/ext/payment/process"
          component={() => {
            const user = JSON.parse(localStorage.getItem('user'));
            window.location.href = `/payment/${user.plan}?process=true`;
            return null;
          }}
        />
      </Switch>
    </div>
  </div>
);

const wrappedPublicRoutes = () => (
  <div className="layout layout__summary">
    <div className="container__wrap container__summary">
      <Switch>
        <Route path="/summary/deck/:id/:extInvId?" component={SummaryDeckPage} />
        <Route path="/summary/financials/:id/:extInvId?" component={FinancialsPage} />
        <Route path="/summary/:id/:extInvId?" component={SummaryPage} />
      </Switch>
    </div>
  </div>
);

const Router = loggedIn => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/summary" component={wrappedPublicRoutes} />
        <Route path="/ext" component={wrappedExternalRoutes} />
        {loggedIn && (
          <Route path="/" component={wrappedPrivateRoutes} />
        )}
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
