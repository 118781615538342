import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import InfoCircleOutlineIcon from 'mdi-react/InfoCircleOutlineIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

class ModalComponent extends PureComponent {
  static propTypes = {
    message: PropTypes.shape({
      campaign: null,
      displayed: false,
      firms: null,
      id: 0,
      investorReplied: null,
      restarted: false,
    }),
    type: PropTypes.string,
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
    resumeCampaign: PropTypes.func,
  };

  static defaultProps = {
    message: null,
    type: '',
    displayed: false,
    toggleModal: null,
    resumeCampaign: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  getTitle(message) {
    const { type } = this.props;
    if (type === 'choice') {
      return 'Please tell us why do you want to continue messaging this fund?';
    }
    return `SmartVC paused your campaign "${message ? message.campaign.name : ''}"`;
  }

  // eslint-disable-next-line class-methods-use-this
  getMessage(message) {
    const { type } = this.props;
    if (message) {
      if (type === 'info') {
        return `
        <div>
            <p>
                <strong><u>
                    ${message.investorReplied.fullName} from investment fund 
                    ${message.firms && message.firms[0] ? message.firms[0].contactInformation.fullName : ''}
                    responded to one of your email. The campaign has been paused for the other VCs of this fund.
                </u></strong>
            </p>
            <br/>
            <p>
                Would you like to stop the campaign for this fund or continue emailing the other investors of this fund?
            </p>
            <br/>
            <small>
                You are receiving this message because the option smartVC is activated for your campaign
                "${message.campaign.name}".
                <p>
                  For any questions,
                  <a href='mailto:contact@angelspartners.com'>please reach out to us</a> and we would be more than happy
                  to help.
                </p>
            </small>
         </div>
      `;
      }
      if (type === 'choice') {
        return `
        <input
          type="checkbox"
          class="m-3"
          id="bounce"
          onclick="if (this.checked) {
            document.getElementById('automated').checked = false;
            document.getElementById('other').checked = false;
            document.getElementById('not_working').checked = false;
            document.getElementById('otherField').disabled = true;
          }">
          &nbsp;The email sent to ${message.investorReplied.fullName} <strong>bounced back</strong>
        </input>
        <br/>
        <input
          type="checkbox"
          class="m-3"
          id="not_working"
          onclick="if (this.checked) {
            document.getElementById('automated').checked = false;
            document.getElementById('other').checked = false;
            document.getElementById('bounce').checked = false;
            document.getElementById('otherField').disabled = true;
          }">
          &nbsp;${message.investorReplied.fullName} does not work at this fund anymore
        </input>
        <br/>
        <input
          type="checkbox"
          class="m-3"
          id="automated"
          onclick="if (this.checked) {
            document.getElementById('bounce').checked = false;
            document.getElementById('other').checked = false;
            document.getElementById('not_working').checked = false;
            document.getElementById('otherField').disabled = true;
          }">
          &nbsp;It was an out of office automated response
        </input>
        <br/>
        <input
          type="checkbox"
          class="m-3"
          id="other"
          onclick="var input = document.getElementById('otherField');
          if (this.checked) {
            document.getElementById('bounce').checked = false;
            document.getElementById('not_working').checked = false;
            document.getElementById('automated').checked = false;
            input.disabled = false;
            input.focus();
          } else {
            input.disabled = true;
          }">
          &nbsp;Other...
        </input>
        <input id="otherField" name="otherField" disabled="disabled"/>
      `;
      }
    }
    return null;
  }

  sendChoice() {
    const { resumeCampaign } = this.props;
    let choice = '';
    if (document.getElementById('bounce') && document.getElementById('bounce').checked) {
      choice = 'bounce';
    } else if (document.getElementById('automated') && document.getElementById('automated').checked) {
      choice = 'automated';
    } else if (document.getElementById('not_working') && document.getElementById('not_working').checked) {
      choice = 'not_working';
    } else if (document.getElementById('other') && document.getElementById('other').checked) {
      choice = document.getElementById('otherField').value;
    }
    resumeCampaign(choice);
  }

  toggle() {
    const { toggleModal } = this.props;
    if (typeof toggleModal === 'function') {
      toggleModal();
    } else {
      this.setState(prevState => ({ modal: !prevState.modal }));
    }
  }

  render() {
    const {
      message, type,
    } = this.props;
    const { modal } = this.state;

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className={`modal-dialog--colored modal-dialog--primary ${type === 'info' ? 'x' : ''}x-large`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            <InfoCircleOutlineIcon className="modal__title-icon" />
            <h4 className="text-modal modal__title">{this.getTitle(message)}</h4>
          </div>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
          <div className="modal__body" dangerouslySetInnerHTML={{ __html: this.getMessage(message) }} />
          <ButtonToolbar className="modal__footer">
            { type === 'info'
            && (
              <Button className="modal_cancel rounded" onClick={this.toggle}>
                Stop campaign for this fund
              </Button>
            )}
            { type === 'info'
              ? (
                <Button
                  className="modal_ok rounded icon icon--right icon--blue"
                  color="white"
                  onClick={() => this.sendChoice()}
                >
                  Continue the campaign for this fund
                  <ChevronRightIcon />
                </Button>
              ) : (
                <Button
                  className="modal_ok rounded icon icon--right icon--blue"
                  color="white"
                  onClick={() => this.sendChoice()}
                >
                  Send
                  <ChevronRightIcon />
                </Button>
              )
            }
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(ModalComponent);
