import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, ButtonToolbar, Col, Modal, Row,
} from 'reactstrap';
import axios from 'axios';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import queryString from 'query-string';

class ModalComponent extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    showNotifError: PropTypes.func.isRequired,
    showNotifSuccess: PropTypes.func.isRequired,
    showNotifWarning: PropTypes.func.isRequired,
    queryUrl: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    getInvestorProspects: PropTypes.func.isRequired,
  };

  static defaultProps = {
    displayed: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
      adding: false,
      added: false,
      count: 0,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  addInvestors = (fullSearch = '') => {
    const {
      queryUrl, showNotifError, showNotifSuccess, showNotifWarning, nextStep, getInvestorProspects,
    } = this.props;
    this.setState({ adding: true });

    axios.post(`/introductions/add?${queryUrl}`, queryString.stringify({ fullSearch }))
      .then((response) => {
        if (response.data && response.data.success) {
          showNotifSuccess('These investors have been added to your introduction list. Thank you.');
          nextStep();
          getInvestorProspects(true);
        } else {
          showNotifWarning(response.data.error);
          if (response.data.count) {
            this.setState({ count: response.data.count });
          }
        }
        this.setState({ adding: false, added: true });
      })
      .catch((error) => {
        if (error && error.response) {
          showNotifError(error.response.data.message);
        } else {
          showNotifError('Sorry, an error occurred, please try again.');
        }
        this.setState({ adding: false, added: true });
      });
  }

  toggle(confirmation) {
    const { nextStep, toggleModal, getInvestorProspects } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (confirmation === true && nextStep && getInvestorProspects) {
      getInvestorProspects();
      nextStep();
    } else if (!confirmation && toggleModal !== null) {
      toggleModal();
    }
  }

  render() {
    const {
      modal, added, adding, count,
    } = this.state;
    const { toggleModal } = this.props;

    return (
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        modalClassName="ltr-support"
        className={`modal-dialog modal-find-intro ${added && !adding ? 'x-large' : 'xx-large'}`}
      >
        <div className="modal__header mb-2">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="modal__title">
            Please choose an option below
          </h4>
          <img src={`${process.env.PUBLIC_URL}/img/linePurple.svg`} className="line" alt="line" />
        </div>
        <div className="modal__tabs">
          {adding
          && (
            <div className={`small-load${adding ? '' : ' loaded'} inload`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          )}
          {!added && !adding && count < 1000
          && (
            <Row className="select-boxes">
              <Col md={6}>
                <div
                  id="email"
                  className="select-boxes-box"
                  role="button"
                  onClick={this.addInvestors}
                  onKeyDown={this.addInvestors}
                  tabIndex={0}
                >
                  <h2 className="select-boxes-title">This Page Only</h2>
                  <p className="select-boxes-subtitle">
                    Find an introduction to the investors from this one page only
                  </p>
                  <Button color="primary" className="rounded icon icon--right select-boxes-go">
                    ADD PAGE <ChevronRightIcon />
                  </Button>
                </div>
              </Col>
              <Col md={6} className="mt-5 mt-lg-0">
                <div
                  className="select-boxes-box"
                  id="email"
                  role="button"
                  onClick={() => this.addInvestors('true')}
                  onKeyDown={() => this.addInvestors('true')}
                  tabIndex={0}
                >
                  <h2 className="select-boxes-title">Entire Search</h2>
                  <p className="select-boxes-subtitle">
                    Find an introduction to all the investors from this search
                  </p>
                  <Button color="primary" className="rounded icon icon--right select-boxes-go">
                    ADD SEARCH <ChevronRightIcon />
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          {added && !adding && count < 1000
          && (
            <Col md={12} className="mt-5 mt-lg-0">
              <div className="modal__body">
                Do you want to find an introduction to more investors or are you ready to move on to the next step?
              </div>
              <ButtonToolbar className="modal__footer">
                <Button
                  className="modal_cancel btn btn-outline-secondary btn-rounded rounded"
                  onClick={() => this.toggle(false)}
                >
                  <ChevronLeftIcon />&nbsp;Add More
                </Button>
                {' '}
                <Button
                  className="modal_ok btn btn-primary btn-rounded text-white rounded"
                  onClick={() => this.toggle(true)}
                >
                  Go to next step <ChevronRightIcon />
                </Button>
              </ButtonToolbar>
            </Col>
          )}
          {!adding && count >= 1000
          && (
            <Col md={12} className="mt-5 mt-lg-0">
              <div className="modal__body">
                You already have 1000 investors in you Introductions List, you cannot add more. Please go to next step.
              </div>
              <ButtonToolbar className="modal__footer">
                <Button
                  className="modal_ok btn btn-primary btn-rounded text-white rounded"
                  onClick={() => this.toggle(true)}
                >
                  Go to next step <ChevronRightIcon />
                </Button>
              </ButtonToolbar>
            </Col>
          )}
        </div>
      </Modal>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user,
}))(ModalComponent);
