import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Col, Row } from 'reactstrap';
import StartupCard from './StartupCard';


class StartupsPingsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startups: [],
    };
  }

  componentDidMount = () => {
    this.getStartups();
  };

  componentDidUpdate(prevProps) {
    const { stateOfList } = this.props;
    if (stateOfList !== prevProps.stateOfList) {
      this.updateListOfStartup(
        stateOfList.id,
        stateOfList.adding,
        stateOfList.favorite,
        stateOfList.accepted,
        stateOfList.pinging,
        stateOfList.declining,
        stateOfList.declined,
      );
    }
  }

  getStartups = () => {
    const { startups } = this.state;

    if (startups.length === 0) {
      axios.get('/startups/pinged')
        .then((response) => {
          const newStartups = response.data.map(startup => ({
            ...startup, adding: false, pinging: false, declining: false,
          }));
          this.setState({ startups: newStartups });
        }).catch(() => {
          this.setState({ startups: null });
        });
    }
  };

  updateListOfStartup = (id, adding, favorite, accepted, pinging, declining, declined) => {
    // Set up Loading
    const { state } = this;
    const startups = Object.values({ ...state.startups });

    if (state.startups === null) {
      return null;
    }

    // find the index of this startup
    const objIndex = startups.findIndex(i => i.id === id);

    if (objIndex >= 0) {
      // Sort out Favorite and Pinged
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (favorite === null) favorite = startups[objIndex].favorite;

      // Sort out Favorite and Pinged
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (accepted === null) accepted = startups[objIndex].accepted;

      // Update Startup
      const updatedStartup = {
        ...startups[objIndex], adding, favorite, accepted, pinging, declining, declined,
      };

      // Make new list of startups
      const updatedStartupsList = [
        ...startups.slice(0, objIndex),
        updatedStartup,
        ...startups.slice(objIndex + 1),
      ];

      // Update the State
      this.setState({ startups: updatedStartupsList });

      return objIndex;
    }
    return false;
  };

  render() {
    const state1 = this.state;
    const {
      addFavorites, removeFavorites, connectStartup, declineStartup,
    } = this.props;
    let startups = null;

    if (state1.startups) {
      startups = state1.startups.map(
        startup => (
          <StartupCard
            startup={startup}
            addFavorites={e => addFavorites(startup.id, e)}
            removeFavorites={e => removeFavorites(startup.id, e)}
            connect={() => connectStartup(startup.id)}
            decline={() => declineStartup(startup.id)}
            key={startup.id}
          />
        ),
      );
    }

    if (startups !== null && startups.length > 0) {
      return (
        <div>
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <h3 className="page-title">
                {startups.length === 1 ? 'This startup' : 'These startups'} sent you a ping.
              </h3>
            </Col>
            <Col xs={{ size: 10, offset: 1 }}>
              <p className="page-description">
                After carefully reviewing your profile,&nbsp;
                {startups.length === 1 ? 'this' : 'these'} compan{startups.length === 1 ? 'y ' : 'ies '}
                think{startups.length === 1 ? 's' : ''} you could be a good fit for their round, and would like to
                connect with you.<br />
                Please answer {startups.length === 1 ? 'this ' : 'these '}
                request{startups.length === 1 ? '' : 's'} by accepting or declining their invitation to connect.
              </p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} md={{ size: 11, offset: 1 }} className="mb-4 mt-4">
              <div className="investor-items__wrap">
                <div className="investor-items startups-pinged">
                  {startups}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    return null;
  }
}
StartupsPingsList.propTypes = {
  connectStartup: PropTypes.func.isRequired,
  declineStartup: PropTypes.func.isRequired,
  addFavorites: PropTypes.func.isRequired,
  removeFavorites: PropTypes.func.isRequired,
  stateOfList: PropTypes.shape({
    id: PropTypes.number,
    adding: PropTypes.bool,
    favorite: PropTypes.bool,
    accepted: PropTypes.bool,
    pinging: PropTypes.bool,
    declining: PropTypes.bool,
    declined: PropTypes.bool,
  }).isRequired,
};

export default withRouter(StartupsPingsList);
