/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import validate from '../../formValidate/notifications';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import { UserProps } from '../../../../shared/prop-types/ReducerProps';
import Modal from '../../../../shared/components/modals/Modal';
import Alert from '../../../../shared/components/alerts/Alert';

class NotificationsTab extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      view: PropTypes.number,
      deck: PropTypes.number,
      declined: PropTypes.number,
      message: PropTypes.number,
      blog: PropTypes.number,
      newsletter: PropTypes.number,
      investorInstant: PropTypes.bool,
      investorWeekly: PropTypes.bool,
      investorMonthly: PropTypes.bool,
    }).isRequired,
    user: UserProps.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalPaywall: false,
    };
  }

  checkPaywall = (e) => {
    const { user } = this.props;
    if (e.target && e.target.name && !user.isCustomer) {
      e.preventDefault();
      this.setState({ modalPaywall: true });
    }
  }

  toggleModalUpgrade = () => {
    const { modalPaywall } = this.state;
    setTimeout(() => { this.setState({ modalPaywall: !modalPaywall }); }, 500);
  };

  render() {
    const {
      handleSubmit, initialValues, user,
    } = this.props;

    const { modalPaywall } = this.state;

    const defaultChecked = (value, type) => {
      // DO NOT MINIMISE
      if ((value && type) || (!value && !type)) {
        return true;
      }
      return false;
    };

    return (
      <span>
        <div className="card__title">
          <h5 className="bold-text">Configure your notification preferences</h5>
          {user.type === 'startup'
          && (
            <h5 className="subhead">You can opt in or out from receiving notifications sent via emails.</h5>
          )}
        </div>
        {user.type === 'investor'
        && (
          <Alert color="info" className="alert--margin mb-5">
            <p>
              The notifications you receive are matching your investor preferences by default. You can edit these
              settings from the <strong>Browse Startup Page</strong>, use the filters matching your investment criteria
              and click on the <strong>Keep me updated</strong> button.
            </p>
          </Alert>
        )}
        <form className="material-form overflow-hidden pl-3" onSubmit={handleSubmit}>
          {user.type !== 'investor'
            && (
              <div>
                <Row className="mb-3">
                  <Col xs={8}>
                    Receive a notification when an investor looks at your profile
                  </Col>
                  <Col xs={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="view"
                          component={renderRadioButtonField}
                          label="Yes"
                          radioValue="1"
                          defaultChecked={defaultChecked(initialValues.notificationView, 1)}
                        />
                        <Field
                          name="view"
                          component={renderRadioButtonField}
                          label="No"
                          radioValue="0"
                          defaultChecked={defaultChecked(initialValues.notificationView, 0)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={8}>
                    Receive a notification when an investor looks at your deck
                  </Col>
                  <Col xs={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="deck"
                          component={renderRadioButtonField}
                          label="Yes"
                          radioValue="1"
                          defaultChecked={defaultChecked(initialValues.notificationDeck, 1)}
                        />
                        <Field
                          name="deck"
                          component={renderRadioButtonField}
                          label="No"
                          radioValue="0"
                          defaultChecked={defaultChecked(initialValues.notificationDeck, 0)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={8}>
                    Receive a notification when an investor sends you a message
                  </Col>
                  <Col xs={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="message"
                          component={renderRadioButtonField}
                          label="Yes"
                          radioValue="1"
                          defaultChecked={defaultChecked(initialValues.notificationMessage, 1)}
                        />
                        <Field
                          name="message"
                          component={renderRadioButtonField}
                          label="No"
                          radioValue="0"
                          defaultChecked={defaultChecked(initialValues.notificationMessage, 0)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={8}>
                    Receive a notification when an investor declines your invitation to connect
                  </Col>
                  <Col xs={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="declined"
                          component={renderRadioButtonField}
                          label="Yes"
                          radioValue="1"
                          defaultChecked={defaultChecked(initialValues.notificationDeclined, 1)}
                        />
                        <Field
                          name="declined"
                          component={renderRadioButtonField}
                          label="No"
                          radioValue="0"
                          defaultChecked={defaultChecked(initialValues.notificationDeclined, 0)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />
                <br />
                <Row className="mb-3">
                  <Col xs={8}>
                    Subscribe to our newsletter (1 per week max)
                  </Col>
                  <Col xs={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="newsletter"
                          component={renderRadioButtonField}
                          label="Yes"
                          radioValue="1"
                          defaultChecked={defaultChecked(initialValues.notificationNewsletter, 1)}
                        />
                        <Field
                          name="newsletter"
                          component={renderRadioButtonField}
                          label="No"
                          radioValue="0"
                          defaultChecked={defaultChecked(initialValues.notificationNewsletter, 0)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={8}>
                    Receive an alert when we publish a new article
                  </Col>
                  <Col xs={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="blog"
                          component={renderRadioButtonField}
                          label="Yes"
                          radioValue="1"
                          defaultChecked={defaultChecked(initialValues.notificationBlog, 1)}
                        />
                        <Field
                          name="blog"
                          component={renderRadioButtonField}
                          label="No"
                          radioValue="0"
                          defaultChecked={defaultChecked(initialValues.notificationBlog, 0)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          }
          {user.type === 'investor'
            && (
              <div>
                <Row className="mb-3 violet-bg pt-4 pb-4">
                  <Col xs={8}>
                    <span className="font-italic">Get an edge and be the first one to know<br /></span>
                    Receive <strong>instant</strong> notifications when a new startup is listed on the platform.
                  </Col>
                  <Col xs={4}>
                    <div className="form__form-group mt-2">
                      <div className="form__form-group-field">
                        <Field
                          name={`${!user.isCustomer
                            ? 'investorInstantNoCustomer'
                            : 'investorInstant'}`}
                          component={renderRadioButtonField}
                          onChange={e => this.checkPaywall(e)}
                          label="Yes"
                          radioValue="1"
                          defaultChecked={defaultChecked(initialValues.investorInstant, 1)}
                        />
                        <Field
                          name="investorInstant"
                          component={renderRadioButtonField}
                          label="No"
                          radioValue="0"
                          defaultChecked={defaultChecked(initialValues.investorInstant, 0)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="mb-3">
                  <Col xs={8}>
                    Receive weekly notifications about new startups
                  </Col>
                  <Col xs={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="investorWeekly"
                          component={renderRadioButtonField}
                          label="Yes"
                          radioValue="1"
                          defaultChecked={defaultChecked(initialValues.investorWeekly, 1)}
                        />
                        <Field
                          name="investorWeekly"
                          component={renderRadioButtonField}
                          label="No"
                          radioValue="0"
                          defaultChecked={defaultChecked(initialValues.investorWeekly, 0)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={8}>
                    Receive monthly notifications about new startups
                  </Col>
                  <Col xs={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="investorMonthly"
                          component={renderRadioButtonField}
                          label="Yes"
                          radioValue="1"
                          defaultChecked={defaultChecked(initialValues.investorMonthly, 1)}
                        />
                        <Field
                          name="investorMonthly"
                          component={renderRadioButtonField}
                          label="No"
                          radioValue="0"
                          defaultChecked={defaultChecked(initialValues.investorMonthly, 0)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          }
          <ButtonToolbar className="form__button-toolbar mt-4">
            <Button
              color="primary"
              className="rounded icon--right icon icon--blue"
              type="submit"
            >
              Update profile
              <ChevronRightIcon />
            </Button>
          </ButtonToolbar>
        </form>
        <Modal
          color="primary"
          title="Upgrade Required!"
          investor={user.type === 'investor'}
          colored
          displayed={modalPaywall}
          toggleModal={() => this.toggleModalUpgrade()}
          message={`Please upgrade your account to a paid plan if you wish to be notified instantly.
           You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing/investor' target="_blank">
           Pricing Page</a>.<br /><br />If you think this message is wrong, please reload the page and try again.`}
        />
      </span>
    );
  }
}

export default reduxForm({
  form: 'startup_notifications_form', // a unique identifier for this form
  validate,
})(NotificationsTab);
