import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import {
  Card, Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import SearchFilters from './components/SearchFilters';
import StartupsPingsList from './components/PingsList';
import StartupsList from './components/List';
import { FullWideNotification } from '../../shared/components/notifications/Notification';
import ModalInput from '../../shared/components/modals/ModalInput';

let notificationTC = null;
const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 5,
    closable: true,
    style: { top: 0, left: 0 },
    className: `${position} ${rtl}-support`,
  });
};

class Startups extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.connectStartup = this.connectStartup.bind(this);
    this.declineStartup = this.declineStartup.bind(this);
    this.addFavorites = this.addFavorites.bind(this);
    this.removeFavorites = this.removeFavorites.bind(this);

    this.state = {
      modalPing: false,
      stateOfList: {
        id: null,
        adding: false,
        favorite: false,
        accepted: false,
        pinging: false,
        declining: false,
        declined: false,
      },
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || user.type !== 'investor') {
      window.location = '/';
    }
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  connectStartup = (startupId) => {
    // eslint-disable-next-line no-unused-vars
    const { history } = this.props;

    // Set up Loading
    this.setState({
      stateOfList: {
        id: startupId, adding: false, favorite: null, accepted: false, pinging: true, declining: false, declined: false,
      },
    });

    // Post the Ping.
    axios.post('/startups/connect', queryString.stringify({ id: startupId }))
      .then((response) => {
        if (response) {
          // Set up Loading
          this.setState({
            stateOfList: {
              // eslint-disable-next-line max-len
              id: startupId, adding: false, favorite: null, accepted: true, pinging: false, declining: false, declined: false,
            },
          }, () => {
            if (response.data) {
              history.push(response.data ? `/inbox/${response.data}` : '/inbox');
            } else {
              this.showNotif('We could not connect you with this startup at this time.'
                + 'Please try again later or contact support.');
              this.setState({
                stateOfList: {
                  // eslint-disable-next-line max-len
                  id: startupId, adding: false, favorite: null, accepted: false, pinging: false, declining: false, declined: false,
                },
              });
            }
          });
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred. Please try again later or contact support.');
        }
        this.setState({
          stateOfList: {
            // eslint-disable-next-line max-len
            id: startupId, adding: false, favorite: null, accepted: false, pinging: false, declining: false, declined: false,
          },
        });
      });
  };

  declineStartup = (message) => {
    const { startupId } = this.state;
    // Remove the modal
    // Set up Loading
    this.setState({
      modalPing: false,
      stateOfList: {
        id: startupId, adding: false, favorite: null, accepted: false, pinging: false, declining: true, declined: false,
      },
    }, () => {
      // Post the Ping
      axios.post('/startups/decline', queryString.stringify({ id: startupId, message }))
        .then((response) => {
          if (response && response.data) {
            // Set up Loading
            this.setState({
              stateOfList: {
                // eslint-disable-next-line max-len
                id: startupId, adding: false, favorite: null, accepted: false, pinging: false, declining: false, declined: true,
              },
            });
          } else {
            this.showNotif('We could not connect you with this startup at this time.'
              + 'Please try again later or contact support.');
            this.setState({
              stateOfList: {
                // eslint-disable-next-line max-len
                id: startupId, adding: false, favorite: null, accepted: false, pinging: false, declining: false, declined: false,
              },
            });
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, Please try again later or contact support.');
          }
          // Set up Loading
          this.setState({
            stateOfList: {
              // eslint-disable-next-line max-len
              id: startupId, adding: false, favorite: null, accepted: false, pinging: false, declining: false, declined: false,
            },
          });
        });
    });
  };

  addFavorites = (id, e) => {
    if (e) { e.preventDefault(); }

    // Set up Loading
    this.setState({
      stateOfList: {
        id, adding: true, favorite: false, accepted: false, pinging: false, declining: false, declined: false,
      },
    });

    // Post the Ping.
    axios.post('/startups/favorite/add', queryString.stringify({ id }))
      .then((response) => {
        if (response) {
          this.setState({
            stateOfList: {
              id,
              adding: false,
              favorite: true,
              accepted: false,
              pinging: false,
              declining: false,
              declined: false,
            },
          });
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.setState({
          stateOfList: {
            id,
            adding: false,
            favorite: false,
            accepted: false,
            pinging: false,
            declining: false,
            declined: false,
          },
        });
      });
  };

  removeFavorites = (id, e) => {
    if (e) { e.preventDefault(); }

    // Set up Loading
    this.setState({
      stateOfList: {
        id, adding: true, favorite: false, accepted: false, pinging: false, declining: false, declined: false,
      },
    });

    // Post the Ping.
    axios.post('/startups/favorite/remove', queryString.stringify({ id }))
      .then((response) => {
        if (response) {
          this.setState({
            stateOfList: {
              id,
              adding: false,
              favorite: false,
              accepted: false,
              pinging: false,
              declining: false,
              declined: false,
            },
          });
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.setState({
          stateOfList: {
            id,
            adding: false,
            favorite: true,
            accepted: false,
            pinging: false,
            declining: false,
            declined: false,
          },
        });
      });
  };

  toggleModalPing = (id) => {
    const { modalPing } = this.state;
    this.setState({ modalPing: !modalPing, startupId: id });
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  render() {
    const {
      modalPing, stateOfList,
    } = this.state;

    return (
      <Container className="dashboard">
        <StartupsPingsList
          connectStartup={startupId => this.connectStartup(startupId)}
          declineStartup={startupId => this.toggleModalPing(startupId)}
          addFavorites={startupId => this.addFavorites(startupId)}
          removeFavorites={startupId => this.removeFavorites(startupId)}
          stateOfList={stateOfList}
        />
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Browse Startups</h3>
          </Col>
          <Col xs={12} md={9} lg={8} xl={7}>
            <p className="page-description">
              <strong>Browse our community of startups</strong><br />
              The profiles listed in this section are startups who previously registered on our platform and are
              actively fundraising.<br />
              Startups who did not connect on the platform for over a month are
              automatically deactivated to keep the community dynamic and alive.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} xl={{ size: 11, offset: 1 }} className="mt-5 mt-md-4 mt-lg-3 p-0 p-md-3 p-lg-4 p-xl-5">
            <Card className="p-0">
              <div className="card__title mb-3">
                <h5 className="bold-text">Filter Startups</h5>
                <h5 className="subhead">Filter startups actively fundraising in your location.<br />
                </h5>
              </div>
              <SearchFilters />
            </Card>
          </Col>
        </Row>
        <StartupsList
          connectStartup={startupId => this.connectStartup(startupId)}
          declineStartup={startupId => this.toggleModalPing(startupId)}
          addFavorites={startupId => this.addFavorites(startupId)}
          removeFavorites={startupId => this.removeFavorites(startupId)}
          stateOfList={stateOfList}
        />
        <ModalInput
          color="primary"
          title="Send a Message"
          displayed={modalPing}
          ping={val => this.declineStartup(val)}
          toggleModal={() => this.toggleModalPing(0)}
        />
      </Container>
    );
  }
}

export default Startups;
