import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const SelectBoxes = ({ onSelected, select }) => (
  <Row className="select-boxes">
    <Col xs={12} md={12} lg={8}>
      <div
        id="campaigns"
        className="select-boxes-box"
        onClick={() => onSelected(true)}
        role="button"
        tabIndex={0}
        onKeyDown={() => onSelected(true)}
      >
        <h2 className="select-boxes-title">{select > 0 ? 'Select' : 'Create'} A Campaign</h2>
        <p className="select-boxes-subtitle">
          {select > 0
            ? 'Automate your fundraising with your campaigns and monitor your progress.'
            : 'Create a campaign to automate your outreach to investors, monitor your progress and raise funds '
            + 'efficiently.'}
        </p>
        <Button color="primary" className="rounded icon icon--right select-boxes-go">
          Be Efficient <ChevronRightIcon />
        </Button>
      </div>
    </Col>
    <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 0 }} className="mt-5 mt-lg-0">
      <div
        className="select-boxes-box"
        id="email"
        onClick={() => onSelected(false)}
        role="button"
        tabIndex={0}
        onKeyDown={() => onSelected(false)}
      >
        <h2 className="select-boxes-title">Via Email</h2>
        <p className="select-boxes-subtitle">
          Get in touch now with a simple email.
        </p>
        <Button color="primary" className="rounded icon icon--right select-boxes-go">
          Go Simple <ChevronRightIcon />
        </Button>
      </div>
    </Col>
  </Row>
);

SelectBoxes.propTypes = {
  onSelected: PropTypes.func.isRequired,
  select: PropTypes.number.isRequired,
};

export default (SelectBoxes);
