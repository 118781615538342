import React from 'react';
import PropTypes from 'prop-types';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';


const scheduled = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/scheduled.svg`;
const contacted = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/contacted.svg`;
const followup = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/followup.svg`;
const opened = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/opened.svg`;
const visited = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/tracking.svg`;
const replied = '/img/replied.gif';

const StatsElement = ({
  total, stat, type, name, id,
}) => (
  <div className="crm-stats-container">
    <div className={`crm-stats 
      ${type === 'opened' ? 'opened-col' : ''}
      ${type === 'visited' ? 'visited-col' : ''} 
      ${type === 'replied' ? 'replied-col' : ''}`}
    >
      {type === 'scheduled' && <img src={scheduled} alt={name} />}
      {type === 'contacted' && <img src={contacted} alt={name} />}
      {type === 'followup' && <img src={followup} alt={name} className="followup" />}
      {type === 'opened' && <img src={opened} alt={name} />}
      {type === 'visited' && <img src={visited} alt={name} />}
      {type === 'replied' && <img src={replied} alt={name} className="replied-img" />}
      <h3>{total} <small>{name}</small></h3>
      {type !== 'scheduled'
       && (
         <div>
           <h4 className={type} id={`TooltipStat${id}`}>{stat}%</h4>
           <UncontrolledTooltip placement="bottom" target={`TooltipStat${id}`}>
             {type === 'contacted' && `You have contacted ${stat}% of the investors in your campaigns`}
             {type === 'followup'
                && `${stat}% of the investors who should have received this follow up did.`}
             {type === 'opened' && `${stat}% of the emails sent have been opened`}
             {type === 'visited' && `${stat}% of the investors who received emails have visited your profile`}
             {type === 'replied' && `${stat}% of the investors who received messages have replied`}
           </UncontrolledTooltip>
         </div>
       )}
    </div>
  </div>
);

StatsElement.propTypes = {
  total: PropTypes.number,
  stat: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number,
};

StatsElement.defaultProps = {
  total: null,
  stat: null,
  id: null,
  type: '',
  name: '',
};

export default StatsElement;
