import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

function getButtonAction(edit, update) {
  if (edit) {
    return 'Update';
  } if (update) {
    return 'Update';
  }
  return 'Add';
}
function getButtonActionAll(edit, update) {
  if (edit) {
    return 'Update All';
  } if (update) {
    return 'Update All';
  }
  return 'Add all';
}

export const ModalCampaignFormMultiButtons = ({
  investorContactInfo,
  multiCampaigns,
  sendUpdate,
  addMultipleRecipientsToCampaign,
  addAllRecipientsToCampaign,
  editCampaign,
}) => (
  <Row className="w-100 ml-4 mt-3 mb-3">
    <div className="col-12 col-lg-4">
      {investorContactInfo && investorContactInfo.email
      && (
        <Button
          className={`${!multiCampaigns ? 'submit-campaign' : ''} rounded icon icon--right icon--blue w-100`}
          color="primary"
          id="TooltipOne"
          type="submit"
        >
          {getButtonAction(editCampaign, sendUpdate)} This Investor
          {!editCampaign ? <PlusIcon /> : <EditIcon />}
        </Button>
      )}
    </div>
    <div className="col-12 col-md-6 col-lg-4">
      <Button
        className="rounded icon icon--right icon--blue w-100"
        color="primary"
        id="TooltipTen"
        onClick={() => addMultipleRecipientsToCampaign(10)}
      >
        {getButtonAction(editCampaign, sendUpdate)} 10 Investors
        {!editCampaign ? <PlusIcon /> : <EditIcon />}
      </Button>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
      <Button
        className="rounded icon icon--right icon--blue w-100"
        color="primary"
        id="TooltipAll"
        onClick={addAllRecipientsToCampaign}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {getButtonActionAll(editCampaign, sendUpdate)} Investors
        {!editCampaign ? <PlusIcon /> : <EditIcon />}
      </Button>
    </div>
    {investorContactInfo && investorContactInfo.email
    && (
      <UncontrolledTooltip placement="bottom" target="TooltipOne">
        {editCampaign ? 'Update' : 'Add'} <strong>this</strong> investor from {editCampaign ? 'here' : 'this page'}
        &nbsp;to the selected campaign
      </UncontrolledTooltip>
    )}
    <UncontrolledTooltip placement="bottom" target="TooltipTen">
      {sendUpdate
        ? (
          <>
            Update your top listed <strong>10</strong> investors from here to the selected campaign
          </>
        ) : (
          <>
            Add the first <strong>10</strong> investors from this page to your campaign
          </>
        )
      }
    </UncontrolledTooltip>
    <UncontrolledTooltip placement="bottom" target="TooltipAll">
      <>
        {getButtonAction(editCampaign, sendUpdate)} <strong>ALL</strong>
        &nbsp;the investors from this page to the selected campaign
      </>
    </UncontrolledTooltip>
  </Row>
);

ModalCampaignFormMultiButtons.defaultProps = {
  multiCampaigns: false,
  editCampaign: false,
  sendUpdate: false,
  addMultipleRecipientsToCampaign: null,
  addAllRecipientsToCampaign: null,
};

ModalCampaignFormMultiButtons.propTypes = {
  investorContactInfo: PropTypes.shape({
    contactId: PropTypes.number,
    fullName: PropTypes.string,
    investorId: PropTypes.number,
    email: PropTypes.bool,
    inCampaign: PropTypes.bool,
    campaignName: PropTypes.string,
    contacted: PropTypes.bool,
    contactedOn: PropTypes.string,
    sent: PropTypes.number,
  }).isRequired,
  multiCampaigns: PropTypes.bool,
  editCampaign: PropTypes.number,
  sendUpdate: PropTypes.bool,
  addMultipleRecipientsToCampaign: PropTypes.func,
  addAllRecipientsToCampaign: PropTypes.func,
};


export const ModalCampaignFormButton = ({
  investorContactInfo, sendUpdate,
}) => (
  <>
    {investorContactInfo && investorContactInfo.email && !sendUpdate
    && (
      <Button
        size="lg"
        className="submit-campaign rounded icon icon--right icon--blue"
        color="primary"
        type="submit"
      >
        Add This Investor To Your Campaign
        <PlusIcon />
      </Button>
    )}
    {investorContactInfo && investorContactInfo.email && sendUpdate
    && (
      <Button
        size="lg"
        className="submit-campaign rounded icon icon--right icon--blue"
        color="primary"
        type="submit"
      >
        Edit This Investor&#39;s Campaign
        <PlusIcon />
      </Button>
    )}
  </>
);

ModalCampaignFormButton.propTypes = {
  investorContactInfo: PropTypes.shape({
    contactId: PropTypes.number,
    fullName: PropTypes.string,
    investorId: PropTypes.number,
    email: PropTypes.bool,
    inCampaign: PropTypes.bool,
    campaignName: PropTypes.string,
    contacted: PropTypes.bool,
    contactedOn: PropTypes.string,
    sent: PropTypes.number,
  }).isRequired,
  sendUpdate: PropTypes.bool.isRequired,
};
