import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';

// Import the image uploader plugin and register it
import ImageUploader from 'quill-image-uploader';

ReactQuill.Quill.register('modules/imageUploader', ImageUploader);

const renderQuillSignatureField = ({ input, meta: { error }, dispatched }) => {
  const [touched, setTouched] = useState(false);

  // Function to handle image upload
  const handleImageUpload = file => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      axios.post('/campaigns/image/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        if (response) {
          const json = JSON.parse(response.data);
          if (!json || typeof json.location !== 'string') {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Invalid JSON');
          }

          resolve(process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_ATTACHMENTS_PATH + json.location);
        }
      });
    } catch (uploadError) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('Something went wrong, please try again.');
    }
  });

  const modules = useMemo(() => ({
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['blockquote'],
      ['link', 'image', 'video'],

      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],

      [{ size: ['small', false, 'large', 'huge'] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
    ],
    imageUploader: {
      upload: handleImageUpload,
    },
  }), []);

  // Sanitize the HTML input value before passing it to Quill
  const sanitizedValue = DOMPurify.sanitize(input.value || '<p><br></p>');

  return (
    <div className="form__form-group-input-wrap">
      <ReactQuill
        value={sanitizedValue}
        onChange={value => input.onChange(value)}
        onBlur={() => { setTouched(true); }}
        modules={modules}
        theme="snow"
        className="signature-quill-editor"
      />
      {(dispatched || touched) && error && <span className="form__form-group-error error-large">{error}</span>}
    </div>
  );
};

renderQuillSignatureField.propTypes = {
  input: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  type: PropTypes.string,
  dispatched: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderQuillSignatureField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  dispatched: false,
};

export default renderQuillSignatureField;
