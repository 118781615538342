/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import ChatIcon from 'mdi-react/CommentMultipleOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';

export default function PingButton(props) {
  const { founder, ping, connect } = props;
  if (founder.accepted && founder.conversation_id) {
    return (
      <Button className="icon icon--right rounded mr-0 m-2" color="purple">
        <Link to={founder.conversation_id ? `/inbox/${founder.conversation_id}` : '/inbox'}>
          <p>Go To Chat <ChatIcon /></p>
        </Link>
      </Button>
    );
  } if (founder.accepted) {
    return (
      <Button className="icon icon--right rounded mr-0 m-2" color="action">
        <p>Connected <SendIcon /></p>
      </Button>
    );
  } if (founder.conversation_id && !founder.accepted && !founder.accepted && !founder.pinged && !founder.declined) {
    return (
      <Button
        className="icon icon--right rounded mr-0 m-2"
        color="primary"
        outline
        onMouseDown={e => connect(e)}
        onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { connect(e); } }}
      >
        <p>Connect <SendIcon /></p>
      </Button>
    );
  } if (founder.pinged) {
    return (
      <Button className="icon icon--right rounded mr-0 m-2" color="secondary">
        <p>Sent <EmailOutlineIcon /></p>
      </Button>
    );
  } if (founder.pinging) {
    return (
      <Button className="icon icon--right rounded mr-0 m-2" color="warning" outline>
        <p>Contacting <LoadingIcon className="mdi-icon-spinning" /></p>
      </Button>
    );
  }
  if (!founder.declined) {
    return (
      <Button
        className="icon icon--right rounded mr-0 m-2"
        color="action"
        onMouseDown={e => ping(e)}
        onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { ping(e); } }}
      >
        <p>Contact <SendIcon /></p>
      </Button>
    );
  }
  return null;
}

PingButton.propTypes = {
  founder: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    short_description: PropTypes.string,
    logo: PropTypes.string,
    location_city: PropTypes.string,
    location_country: PropTypes.string,
    industry: PropTypes.string,
    raising: PropTypes.number,
    raised: PropTypes.number,
    minimum: PropTypes.number,
    new: PropTypes.bool,
    accepted: PropTypes.bool,
    declined: PropTypes.bool,
    conversation_id: PropTypes.number,
    pinging: PropTypes.bool,
    declining: PropTypes.bool,
    web_plan: PropTypes.bool,
  }).isRequired,
  connect: PropTypes.func.isRequired,
  ping: PropTypes.func.isRequired,
};
