import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import axios from 'axios';

import ProfileBasic from './components/ProfileBasic';
import ProfileTabs from './components/ProfileTabs';

export default class Profile extends PureComponent {
  state = {
    profile: {},
    categories: {},
    stages: {},
    countries: {},
    loading: true,
    loaded: false,
  };

  componentDidMount = () => {
    this.getMyInfo();
  };

  getMyInfo = () => {
    axios.get('investors/myProfile')
      .then((response) => {
        this.setState({
          profile: response.data.profile,
          categories: response.data.categories,
          countries: response.data.countries,
          stages: response.data.stages,
          loading: false,
        }, () => {
          this.setState({ loading: false });
          setTimeout(() => this.setState({ loaded: true }), 500);
        });
      });
  };

  openContactForm = () => {
    window.open(`${process.env.REACT_APP_DOMAIN_URL}/contact`, '_blank');
  }

  updatePicture = (picture) => {
    const { profile } = this.state;

    const updatedProfile = {
      ...profile, picture,
    };

    // Update the State
    this.setState({ profile: updatedProfile });
  };

  updateInfo = (firstName, lastName, locationCity, locationCountry) => {
    const { profile } = this.state;

    const updatedProfile = {
      ...profile, firstName, lastName, location_city: locationCity, location_country: locationCountry,
    };

    // Update the State
    this.setState({ profile: updatedProfile });
  };

  render = () => {
    const {
      profile, loaded, loading, categories, stages, countries,
    } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Your Profile</h3>
          </Col>
          <Col xs={12} md={9} lg={6} xl={5}>
            <p className="page-description">
              <strong>Provide information in your profile</strong><br />
              Let startup know what you are looking for in order to receive efficient matching.
            </p>
          </Col>
        </Row>
        <div className="profile">
          {!loaded
            ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            )
            : (
              <Row className="company">
                <Col xs={12} lg={8} className="order-1 order-lg-0 p-0 p-lg-3">
                  <Row>
                    <ProfileTabs
                      profile={profile}
                      categories={categories}
                      stages={stages}
                      countries={countries}
                      onPictureUpdated={logo => this.updatePicture(logo)}
                      onInfoUpdated={
                        (firstName,
                          lastName,
                          locationCity,
                          locationCountry) => this.updateInfo(firstName, lastName, locationCity, locationCountry)
                      }
                    />
                  </Row>
                </Col>
                <Col xs={12} lg={4} className="order-0 order-lg-1">
                  <ProfileBasic
                    firstName={profile.firstName}
                    lastName={profile.lastName}
                    picture={profile.picture}
                    locationCity={profile.location_city}
                    locationCountry={profile.location_country}
                    planType={profile.planType}
                    dateSubscribed={profile.dateSubscribed}
                    dateTrialEnd={profile.dateTrialEnd}
                    datePlanEnd={profile.datePlanEnd}
                    dateTrialCancelled={profile.dateTrialCancelled}
                    willRenew={profile.willRenew}
                    isCustomer={profile.isCustomer}
                    cancelSubscriptionToggle={this.openContactForm}
                  />
                </Col>
              </Row>
            )
          }
        </div>
      </Container>
    );
  }
}
