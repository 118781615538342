import React, { PureComponent } from 'react';
import {
  Card, Nav, NavItem, NavLink, Progress, TabContent, TabPane,
} from 'reactstrap';
import axios from 'axios';
import queryString from 'query-string';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import NotificationSystem from 'rc-notification';
import StartupInformation from './tabs/StartupInformation';
import StartupTeam from './tabs/StartupTeam';
import StartupUpdates from './tabs/StartupUpdates';
import StartupTraction from './tabs/StartupTraction';
import StartupMarket from './tabs/StartupMarket';
import StartupRound from './tabs/StartupRound';
import StartupPictures from './tabs/StartupPictures';
import StartupVideo from './tabs/StartupVideo';
import StartupMaterials from './tabs/StartupMaterials';
import StartupDeck from './tabs/StartupDeck';
import StartupFinancialsProjections from './tabs/StartupFinancialsProjections';
import StartupFinancials from './tabs/StartupFinancials';
import { updateAvatar } from '../../../redux/actions/userActions';
import { BasicNotification, LinkNotification } from '../../../shared/components/notifications/Notification';
// import showResults from './Show';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class ProfileTabs extends PureComponent {
  static defaultProps = {
    shortDescription: '',
    currency: '',
    description: '',
    team: '',
    market: '',
    traction: '',
    updates: '',
    incorporated: '',
    raising: '',
    raised: '',
    minimum: '',
    dateClose: '',
    rounds: '',
    valuation: '',
    revenues: '',
    logo: null,
    deck: null,
    deckTimestamp: '',
    financials: null,
    financialsTimestamp: '',
    url: '',
    video: '',
    materials: '',
    linkToMaterials: '',
    pictures: [],
    locationCity: '',
    locationCountry: '',
    category: '',
    secondaryCategory: '',
    stage: '',
    numberOfStaff: '',
    ltv: '',
    cac: '',
    startupRound: '',
    nbCustomers: 0,
    netProfit: 0,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      updatedDeck: false,
      updatedFinancials: false,
      downloading: false,
      currency: '$',
    };
  }

  componentDidMount() {
    const { currency } = this.props;
    this.setState({
      currency,
    });
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  updateStartup = (values) => {
    const { onInfoUpdated, getMyInfo } = this.props;
    this.setState({ downloading: true });

    axios.post('/startup/info', queryString.stringify({
      shortDescription: values.shortDescription,
      description: values.description,
      updates: values.updates,
      url: values.url,
      incorporated: this.dateToYMD(values.incorporated),
      location_city: values.locationCity,
      location_country: values.locationCountry,
      category: values.category,
      secondary_category: values.secondaryCategory,
      stage: values.stage,
    })).then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());

        // Dispatch the update to the site (profile basic)
        onInfoUpdated(values.shortDescription);
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  showNotifSuccess = () => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message="Your information has been updated successfully"
    />,
    position: 'right-up',
  });

  showNotifWarning = errorMessage => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      message={errorMessage}
    />,
    position: 'right-up',
  });

  updateTeam = (values) => {
    this.setState({ downloading: true });
    const { getMyInfo } = this.props;

    axios.post('/startup/team', queryString.stringify({
      team: values.team,
      numberOfStaff: values.numberOfStaff,
    })).then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  updateMarket = (values) => {
    this.setState({ downloading: true });
    const { getMyInfo } = this.props;

    axios.post('/startup/market', queryString.stringify({
      market: values.market,
    })).then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  updateTraction = (values) => {
    this.setState({ downloading: true });
    const { getMyInfo } = this.props;

    axios.post('/startup/traction', queryString.stringify({
      traction: values.traction,
    })).then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  updateUpdates = (values) => {
    this.setState({ downloading: true });
    const { getMyInfo } = this.props;

    axios.post('/startup/updates', queryString.stringify({
      updates: values.updates,
    })).then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  updateRound = (values) => {
    this.setState({ downloading: true });
    const { getMyInfo } = this.props;

    axios.post('/startup/round', queryString.stringify({
      currency: values.currency.value,
      valuation: values.valuation,
      raised: values.raised,
      raising: values.raising,
      rounds: values.rounds,
      minimum: values.minimum,
      startupRound: values.startupRound,
      dateClose: this.dateToYMD(values.dateClose),
    })).then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      if (error.response.data) {
        this.setState({
          downloading: false,
        }, () => this.showNotifWarning(error.response.data.message));
      }
    });
  };

  updateFinancials = (values) => {
    this.setState({ downloading: true });
    const { getMyInfo } = this.props;

    axios.post('/startup/financialsInfo', queryString.stringify({
      revenues: values.revenues,
      ltv: values.ltv,
      cac: values.cac,
      nbCustomers: values.revenues > 0 ? values.nbCustomers : 0,
      netProfit: values.revenues > 0 ? values.netProfit : 0,
    })).then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  uploadLogo = (e) => {
    const { onLogoUpdated, dispatch, getMyInfo } = this.props;
    const formData = new FormData();

    formData.append('logo', e.files[0], e.files[0].name.replace(/[^a-zA-Z0-9.]/g, ''));
    this.setState({ downloading: true });

    axios.post('/startup/logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // onUploadProgress: (progressEvent) => {
      //   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //   console.log(percentCompleted);
      // },
    }).then((response) => {
      this.setState({ downloading: false }, () => {
        if (response.data) {
          getMyInfo();
          this.showNotifSuccess();

          // if everything went fine then update our local storage
          const user = JSON.parse(localStorage.getItem('user'));
          user.avatar = response.data; // contains the logo src
          localStorage.setItem('user', JSON.stringify(user));

          // Dispatch the update to the site (top right hand corner + profile basic)
          onLogoUpdated(response.data);
          dispatch(updateAvatar(response.data));
        }
      });
    }).catch((error) => {
      const message = error.response && error.response.data
        ? error.response.data.message : 'An error occured. Please try again.';
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(message));
    });
  };

  updateVideo = (values) => {
    const { onVideoUpdated, getMyInfo } = this.props;
    this.setState({ downloading: true });

    axios.post('/startup/video', queryString.stringify({
      video: values.video,
    })).then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());

        // Dispatch the update to the tab
        onVideoUpdated(values.video);
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  removeVideo = () => {
    const { onVideoUpdated, getMyInfo } = this.props;
    this.setState({ downloading: true });

    axios.get('/startup/video/remove').then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());

        // Dispatch the update to the tab
        onVideoUpdated('');
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  updateMaterials = (values) => {
    this.setState({ downloading: true });
    const { getMyInfo } = this.props;

    axios.post('/startup/materials', queryString.stringify({
      name: values.materials,
      url: values.linkToMaterials,
    })).then((response) => {
      if (response.data) {
        getMyInfo();
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  uploadDeck = (e) => {
    const formData = new FormData();
    const { getMyInfo } = this.props;
    if (typeof e !== typeof undefined && Array.isArray(e.files)) {
      formData.append('deck', e.files[0], e.files[0].name.replace(/[^a-zA-Z0-9.]/g, ''));
      this.setState({ downloading: true });

      axios.post('/startup/deck', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.setState({ downloading: false }, () => {
          if (response.data) {
            this.setState({ updatedDeck: response.data }, () => this.showNotifSuccess());
            getMyInfo();
          }
        });
      }).catch((error) => {
        this.setState({
          downloading: false,
        }, () => this.showNotifWarning(error.response.data.message));
      });
    }
  };

  uploadFinancialsProjections = (e) => {
    const formData = new FormData();
    const { getMyInfo } = this.props;
    if (typeof e !== typeof undefined && Array.isArray(e.files)) {
      formData.append('financials', e.files[0], e.files[0].name.replace(/[^a-zA-Z0-9.]/g, ''));
      this.setState({ downloading: true });

      axios.post('/startup/financials', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.setState({ downloading: false }, () => {
          if (response.data) {
            this.setState({ updatedFinancials: response.data },
              () => this.showNotifSuccess());
            getMyInfo();
          }
        });
      }).catch((error) => {
        this.setState({
          downloading: false,
        }, () => this.showNotifWarning(error.response.data.message));
      });
    }
  };


  openDeck = () => {
    const { deck } = this.props;
    const { updatedDeck } = this.state;
    const deckLink = updatedDeck || deck;

    window.open(
      process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_DECKS_PATH + deckLink,
      '_blank',
    );
  };


  openFinancials = () => {
    const { financials } = this.props;
    const { updatedFinancials } = this.state;
    const financialsLink = updatedFinancials || financials;

    window.open(
      process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_FINANCIALS_PATH + financialsLink,
      '_blank',
    );
  };

  removeFinancials = () => {
    const { removeFinancials, getMyInfo } = this.props;
    axios.post('/startup/financials/remove').then((response) => {
      this.setState({ downloading: false }, () => {
        if (response.data) {
          getMyInfo();
          this.setState({ updatedFinancials: null }, () => this.showNotifSuccess());
          removeFinancials();
        }
      });
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  removeDeck = () => {
    const { removeDeck, getMyInfo } = this.props;
    axios.post('/startup/deck/remove').then((response) => {
      this.setState({ downloading: false }, () => {
        if (response.data) {
          getMyInfo();
          this.setState({ updatedDeck: null }, () => this.showNotifSuccess());
          removeDeck();
        }
      });
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  dateToYMD = (date) => {
    if (date && date instanceof moment) {
      return date.format('YYYY/MM/DD');
    }
    if (date) {
      const d = date.getDate();
      const m = date.getMonth() + 1; // Month from 0 to 11
      const y = date.getFullYear();
      return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
    }
    return null;
  };

  updateCurrency = (currency) => {
    this.setState({
      currency,
    });
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      axios.post('/startup/trackSettings', queryString.stringify({ tab })).then();
    }
  };

  render() {
    const {
      activeTab,
      downloading,
      updatedDeck,
      updatedFinancials,
      currency,
    } = this.state;
    const {
      shortDescription, updates, description, team, revenues, raised, raising, rounds, valuation, url,
      market, traction, logo, pictures, deck, deckTimestamp, incorporated, minimum, dateClose, video,
      financials, financialsTimestamp, materials, linkToMaterials, locationCity, locationCountry, category,
      secondaryCategory, stage, numberOfStaff, ltv, cac, nbCustomers, netProfit, startupRound,
    } = this.props;

    const deckLink = updatedDeck || deck;
    const financialsLink = updatedFinancials || financials;

    const initialValuesRound = {
      currency: {
        label: currency,
        value: currency,
      },
      valuation,
      raised,
      raising,
      rounds,
      minimum,
      startupRound,
      dateClose: dateClose && moment(dateClose),
    };

    const initialValuesStartup = {
      shortDescription,
      description,
      incorporated: incorporated && moment(incorporated),
      url,
      locationCity,
      locationCountry,
      category,
      secondaryCategory,
      stage,
    };

    const initialValuesMaterials = {
      materials,
      linkToMaterials,
    };

    const initialValuesFinancials = {
      revenues,
      nbCustomers,
      netProfit,
      ltv,
      cac,
    };

    const initialValuesMarket = { market };
    const initialValuesTraction = { traction };
    const initialValuesUpdates = { updates };
    const initialValuesTeam = { team, numberOfStaff };
    const initialValuesVideo = { video };

    let downloadBlock = null;
    if (downloading) {
      downloadBlock = (
        <Progress animated value={100} className="progress-wrap--middle">
          Please wait..
        </Progress>
      );
    }

    return (
      <Card>
        <div className="tabs tabs--vertical">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  Business Information
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Team
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  Market
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => {
                    this.toggle('4');
                  }}
                >
                  Traction
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => {
                    this.toggle('5');
                  }}
                >
                  The Round
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '6' })}
                  onClick={() => {
                    this.toggle('6');
                  }}
                >
                  Updates
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '7' })}
                  onClick={() => {
                    this.toggle('7');
                  }}
                >
                  Logo & Video
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '8' })}
                  onClick={() => {
                    this.toggle('8');
                  }}
                >
                  Deck
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '9' })}
                  onClick={() => {
                    this.toggle('9');
                  }}
                >
                  Financials
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '10' })}
                  onClick={() => {
                    this.toggle('10');
                  }}
                >
                  Materials
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="pl-5">
              <TabPane tabId="1">
                <StartupInformation
                  onSubmit={val => this.updateStartup(val)}
                  initialValues={initialValuesStartup}
                  enableReinitialize
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="2">
                <StartupTeam
                  onSubmit={val => this.updateTeam(val)}
                  initialValues={initialValuesTeam}
                  enableReinitialize
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="3">
                <StartupMarket
                  onSubmit={val => this.updateMarket(val)}
                  initialValues={initialValuesMarket}
                  enableReinitialize
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="4">
                <StartupTraction
                  onSubmit={val => this.updateTraction(val)}
                  initialValues={initialValuesTraction}
                  enableReinitialize
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="5">
                <StartupRound
                  onSubmit={val => this.updateRound(val)}
                  initialValues={initialValuesRound}
                  currency={currency}
                  updateCurrency={c => this.updateCurrency(c)}
                  enableReinitialize
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="6">
                <StartupUpdates
                  onSubmit={val => this.updateUpdates(val)}
                  initialValues={initialValuesUpdates}
                  enableReinitialize
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="7">
                <StartupVideo
                  enableReinitialize
                  onSubmit={val => this.updateVideo(val)}
                  initialValues={initialValuesVideo}
                  remove={() => this.removeVideo()}
                />
                <hr className="mt-5 mb-5 pb-5" />
                <StartupPictures
                  onSubmit={this.uploadLogo}
                  logo={logo}
                  pictures={pictures}
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="8">
                <StartupDeck
                  onSubmit={this.uploadDeck}
                  openDeck={this.openDeck}
                  removeDeck={this.removeDeck}
                  /* eslint-disable-next-line no-undef */
                  deck={deckLink}
                  deckTimestamp={deckTimestamp}
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="9">
                <StartupFinancials
                  onSubmit={val => this.updateFinancials(val)}
                  initialValues={initialValuesFinancials}
                  currency={currency}
                />
                <hr className="mt-5 mb-5 pb-5" />
                <StartupFinancialsProjections
                  onSubmit={this.uploadFinancialsProjections}
                  openFinancials={this.openFinancials}
                  removeFinancials={this.removeFinancials}
                  /* eslint-disable-next-line no-undef */
                  financials={financialsLink}
                  financialsTimestamp={financialsTimestamp}
                />
                {downloadBlock}
              </TabPane>
              <TabPane tabId="10">
                <StartupMaterials
                  onSubmit={this.updateMaterials}
                  /* eslint-disable-next-line no-undef */
                  initialValues={initialValuesMaterials}
                />
                {downloadBlock}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    );
  }
}

export default connect(null)(ProfileTabs);

ProfileTabs.propTypes = {
  shortDescription: PropTypes.string,
  currency: PropTypes.string,
  description: PropTypes.string,
  team: PropTypes.string,
  market: PropTypes.string,
  traction: PropTypes.string,
  updates: PropTypes.string,
  incorporated: PropTypes.string,
  revenues: PropTypes.number,
  minimum: PropTypes.number,
  dateClose: PropTypes.string,
  raising: PropTypes.number,
  raised: PropTypes.number,
  rounds: PropTypes.string,
  valuation: PropTypes.number,
  logo: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  pictures: PropTypes.array,
  deck: PropTypes.string,
  deckTimestamp: PropTypes.string,
  financials: PropTypes.string,
  financialsTimestamp: PropTypes.string,
  url: PropTypes.string,
  video: PropTypes.string,
  materials: PropTypes.string,
  linkToMaterials: PropTypes.string,
  onLogoUpdated: PropTypes.func.isRequired,
  onInfoUpdated: PropTypes.func.isRequired,
  onVideoUpdated: PropTypes.func.isRequired,
  removeDeck: PropTypes.func.isRequired,
  removeFinancials: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  locationCity: PropTypes.string,
  locationCountry: PropTypes.number,
  category: PropTypes.number,
  secondaryCategory: PropTypes.number,
  stage: PropTypes.number,
  numberOfStaff: PropTypes.string,
  ltv: PropTypes.string,
  cac: PropTypes.string,
  nbCustomers: PropTypes.number,
  netProfit: PropTypes.string,
  startupRound: PropTypes.number,
  getMyInfo: PropTypes.func.isRequired,
};
