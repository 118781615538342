/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import DeclinedIcon from 'mdi-react/CloseCircleIcon';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

export default function DeclineButton(props) {
  const { founder, decline } = props;
  if (founder.declined) {
    return (
      <Button className="rounded mr-0 m-2" color="danger">
        <p>Declined</p>
      </Button>
    );
  }
  if (founder.declining) {
    return (
      <Button className="icon icon--right rounded mr-0 m-2" color="danger" outline>
        Declining
        <LoadingIcon className="mdi-icon-spinning" />
      </Button>
    );
  }
  if (founder.conversation_id && !founder.pinged && !founder.accepted && !founder.declined) {
    return (
      <Button
        className="icon icon-only rounded mr-0 m-2"
        color="danger"
        onMouseDown={e => decline(e)}
        onKeyUp={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            decline(e);
          }
        }}
        id="Decline"
      >
        <p>
          <DeclinedIcon />
        </p>
        <UncontrolledTooltip placement="top" target="Decline">
          Decline this invitation to connect
        </UncontrolledTooltip>
      </Button>
    );
  }
  return null;
}

DeclineButton.propTypes = {
  founder: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    short_description: PropTypes.string,
    logo: PropTypes.string,
    location_city: PropTypes.string,
    location_country: PropTypes.string,
    industry: PropTypes.string,
    raising: PropTypes.number,
    raised: PropTypes.number,
    minimum: PropTypes.number,
    new: PropTypes.bool,
    accepted: PropTypes.bool,
    declined: PropTypes.bool,
    conversation_id: PropTypes.number,
    pinging: PropTypes.bool,
    declining: PropTypes.bool,
    web_plan: PropTypes.bool,
  }).isRequired,
  decline: PropTypes.func.isRequired,
};
