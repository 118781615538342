import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ExtInvestorsList from '../../../../shared/components/externalInvestors/List';
import SearchFilters from '../../../../shared/components/externalInvestors/SearchFilters';

class WizardFormOne extends PureComponent {
  static propTypes = {
    nextStep: PropTypes.func.isRequired,
    getInvestorProspects: PropTypes.func.isRequired,
  };

  render() {
    const { nextStep, getInvestorProspects } = this.props;

    return (
      <div>
        <Row>
          <Col
            xs={12}
            xl={{ size: 11, offset: 1 }}
            className="mt-5 mt-md-4 mt-lg-3 p-0 p-md-3 p-lg-5"
            style={{ width: '1200px' }}
          >
            <Card className="p-0">
              <SearchFilters noFirms />
            </Card>
          </Col>
        </Row>
        <ExtInvestorsList
          nextStep={nextStep}
          getInvestorProspects={getInvestorProspects}
        />
      </div>
    );
  }
}

export default withRouter(WizardFormOne);
