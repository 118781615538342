import React from 'react';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import renderDropZoneField from '../../../../shared/components/form/DropZone';

const FileUploadFinancials = ({
  handleSubmit, reset, financials, financialsTimestamp, openFinancials, removeFinancials,
}) => (
  <div>
    {financials && financials !== '' && (
      <div>
        <div
          role="button"
          tabIndex={0}
          className="review-deck"
          onClick={openFinancials}
          onKeyDown={openFinancials}
        >
          <img
            src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/financials.png`}
            className="pic-deck-review"
            alt="Download Financials Logo"
          />
          <div className="text-deck-review">
            Review Financials
            <br />
            <span>Last updated: {financialsTimestamp || 'Just Now'}</span>
          </div>
        </div>
        <Button
          onClick={removeFinancials}
          className="float-right"
          color="danger"
          outline
          type="submit"
          size="sm"
        >
          Remove Financials
        </Button>
        <br /><br />
      </div>
    )}
    <div>
      <div className="card__title">
        <h5 className="bold-text">Update Your Financials Projections</h5>
        <h5 className="subhead">
          Keep your financials up to date and show investors you have a solid plan.
        </h5>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <Field
          name="files"
          component={renderDropZoneField}
          customHeight
        />
        <ButtonToolbar className="form__button-toolbar">
          <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
            Upload Financials
            <ChevronRightIcon />
          </Button>
          <Button className="rounded mt-5" type="button" onClick={reset}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  </div>
);

FileUploadFinancials.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  financials: PropTypes.string,
  financialsTimestamp: PropTypes.string,
  openFinancials: PropTypes.func.isRequired,
  removeFinancials: PropTypes.func.isRequired,
};

FileUploadFinancials.defaultProps = {
  financials: null,
  financialsTimestamp: null,
};

export default reduxForm({
  form: 'file_upload_financials', // a unique identifier for this form
})(FileUploadFinancials);
