/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (!values.team) {
    errors.team = 'Please describe your team.';
  } else if (values.team.length < 10) {
    errors.team = 'Your team description should be at least 10 characters long.';
  } else if (values.team.length > 1500) {
    errors.team = 'Your team description should be less than 1500 characters long.';
  }

  return errors;
};

export default validate;
