import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import {
  Alert,
  Card, Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import { Link } from 'react-router-dom';
import SearchFilters from './components/SearchFilters';
import FoundersPingsList from './components/PingsList';
import FoundersList from './components/List';
import { FullWideNotification } from '../../shared/components/notifications/Notification';
import ModalInput from '../../shared/components/modals/ModalInput';
import FillerElement from './components/FillerElement';

let notificationTC = null;
const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 5,
    closable: true,
    style: { top: 0, left: 0 },
    className: `${position} ${rtl}-support`,
  });
};

class Founders extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.connectFounder = this.connectFounder.bind(this);
    this.declineFounder = this.declineFounder.bind(this);

    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      modalDecline: false,
      stateOfList: {
        id: null,
        accepted: false,
        pinging: false,
        declining: false,
        declined: false,
      },
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notificationTC = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  connectFounder = (founderId) => {
    // eslint-disable-next-line no-unused-vars
    const { history } = this.props;

    // Set up Loading
    this.setState({
      stateOfList: {
        id: founderId, accepted: false, pinging: true, declining: false, declined: false,
      },
    });

    // Post the Ping.
    axios.post('/founders/connect', queryString.stringify({ id: founderId }))
      .then((response) => {
        if (response) {
          // Set up Loading
          this.setState({
            stateOfList: {
              // eslint-disable-next-line max-len
              id: founderId, accepted: true, pinging: false, declining: false, declined: false,
            },
          }, () => {
            if (response.data) {
              history.push(response.data ? `/inbox/${response.data}` : '/inbox');
            } else {
              this.showNotif('We could not connect you with this founder at this time. Please try again.');
              this.setState({
                stateOfList: {
                  // eslint-disable-next-line max-len
                  id: founderId, accepted: false, pinging: false, declining: false, declined: false,
                },
              });
            }
          });
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          // Do something error.response.data.message ?
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.setState({
          stateOfList: {
            // eslint-disable-next-line max-len
            id: founderId, accepted: false, pinging: false, declining: false, declined: false,
          },
        });
      });
  };

  declineFounder = (message) => {
    const { founderId } = this.state;
    // Remove the modal
    // Set up Loading
    this.setState({
      modalDecline: false,
      stateOfList: {
        id: founderId, accepted: false, pinging: false, declining: true, declined: false,
      },
    }, () => {
      // Post the Ping
      axios.post('/founders/decline', queryString.stringify({ id: founderId, message }))
        .then((response) => {
          if (response.data && response) {
            // Set up Loading
            this.setState({
              stateOfList: {
                // eslint-disable-next-line max-len
                id: founderId, accepted: false, pinging: false, declining: false, declined: true,
              },
            });
          } else {
            this.showNotif('We could not connect you with this founder at this time. Please try again.');
            this.setState({
              stateOfList: {
                // eslint-disable-next-line max-len
                id: founderId, accepted: false, pinging: false, declining: false, declined: false,
              },
            });
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
          // Set up Loading
          this.setState({
            stateOfList: {
              // eslint-disable-next-line max-len
              id: founderId, accepted: false, pinging: false, declining: false, declined: false,
            },
          });
        });
    });
  };

  showModalDecline = (id) => {
    // if (e) { e.preventDefault(); }
    // eslint-disable-next-line react/no-unused-state
    this.setState({ modalDecline: true, founderId: id });
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  render() {
    const {
      modalDecline, stateOfList, user,
    } = this.state;

    return (
      <Container id="warm_intros">
        {user.founderMessageSet
          && (
            <FoundersPingsList
              connectFounder={founderId => this.connectFounder(founderId)}
              declineFounder={founderId => this.showModalDecline(founderId)}
              stateOfList={stateOfList}
            />
          )}
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Founders Community</h3>
          </Col>
          {user.founderMessageSet
            && (
              <Col xs={12} md={9} lg={8} xl={7}>
                <p className="page-description">
                  Angels Partners Founders Community is here to help you connect with other founders currently
                  fundraising, so you can help each other with warm introductions.<br />
                  Search for <strong>relevant founders in your industry and location</strong> and start&nbsp;
                  <strong><u>exchanging introductions</u></strong>. Be mindful and connect on a personal level via
                  visio or in person before you ask anything.<br />
                </p>
              </Col>
            )}
          {!user.community
            && (
              <Col xs={12} md={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
                <Alert color="warning" className="alert--margin p-4" icon>
                  <p>
                    <strong>You are not part of the community.</strong> To become listed in the community and start
                    connecting with other founders, you need to list your startup from your&nbsp;
                    <Link to="/myProfile/founder">Profile &#62; Community</Link>.
                  </p>
                </Alert>
              </Col>
            )}
        </Row>
        {user.founderMessageSet
          ? (
            <>
              <Row>
                <Col xs={12} lg={12} xl={11} className="mt-5 mt-md-4 mt-lg-3 p-0 p-md-3 p-lg-5 offset-md-05">
                  <Card className="p-0">
                    <div className="card__title mb-3">
                      <h5 className="bold-text">Filter Startups</h5>
                    </div>
                    <SearchFilters />
                  </Card>
                </Col>
              </Row>
              <FoundersList
                showNotif={message => this.showNotif(message)}
                connectFounder={founderId => this.connectFounder(founderId)}
                declineFounder={founderId => this.showModalDecline(founderId)}
                stateOfList={stateOfList}
              />
              <ModalInput
                color="primary"
                title="Send a Message"
                header
                displayed={modalDecline}
                ping={val => this.declineFounder(val)}
              />
            </>
          ) : <FillerElement onClick={this.moveToNextStep} />
        }
      </Container>
    );
  }
}

export default Founders;
