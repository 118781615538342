import React, { PureComponent } from 'react';
import {
  Row, Col, Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import NotificationSystem from 'rc-notification';
import LoadingIcon from 'mdi-react/LoadingIcon';
import Mailbox from './Mailbox';
import Alert from '../../../shared/components/alerts/Alert';
import { updateUserInfo } from '../../../redux/actions/userActions';
import Modal from '../../../shared/components/modals/Modal';
import ModalWhiteList from '../../../shared/components/modals/ModalWhitelist';
import TourPopup from '../../../shared/components/tours/TourPopup';
import AccountWarnings from '../../../shared/components/AccountWarnings';
import { LinkNotification } from '../../../shared/components/notifications/Notification';

let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  notificationRU.notice({
    content: notification,
    duration: 30,
    closable: true,
    style: { top: 30, left: 'calc(100vw - 100%)' },
    className: `${position} ${rtl}-support`,
  });
};

class Mailboxes extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
    history: PropTypes.object.isRequired,
  };

  state = {
    user: null,
    mailboxError: null,
    modal: false,
    error: null,
    errorProvider: null,
    triggerTour: false,
    closeTour: false,
    modalUpgrade: false,
    loading: false,
  }

  constructor(props) {
    super(props);
    this.timer = null;
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);

    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({
      mailboxError: user.mailboxError,
      user,
    });
    if (!user.mailboxConnected) {
      this.setState({ triggerTour: true });
    }
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  toggleModalUpgrade = () => {
    const { modalUpgrade } = this.state;
    this.setState({ modalUpgrade: !modalUpgrade });
  }

  onProviderSelected = (provider) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { history } = this.props;

    this.setState({ closeTour: true });

    if (provider === 'Gmail') {
      if (user.email.toLowerCase().indexOf('gmail') !== -1 || user.mailboxWhitelisted) {
        // is there is Gmail in the name,then no whitelisting.
        this.connectMailbox(provider);
      } else if (!user.mailboxWhitelisted) {
        // is it is a Google Workspace account then go to whitelisting
        history.push('/whitelisting');
      }
    } else {
      // if it is not a google service, then no need to whitelist.
      this.setWhiteList(provider);
    }
  }

  setWhiteList = (provider) => {
    const user = JSON.parse(localStorage.getItem('user'));
    axios.post('/user/whitelisted', queryString.stringify({ provider }))
      .then((response) => {
        if (typeof response.data === 'object' && response.data.success === true) {
          this.connectMailbox(provider);
          user.mailboxWhitelisted = true;
        } else {
          // EVERYTHING ELSE
          this.setState({
            errorProvider: provider,
            error: 'Sorry, an error occurred. Please try again or contact support if the problem occurs again.',
          });
        }
      })
      .catch((error) => {
        if (typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.setState({
            errorProvider: provider,
            error: error.response.data.message,
          });
        } else {
          this.setState({
            errorProvider: provider,
            error: 'Sorry, an error occurred. Please try again or contact support if the problem occurs again.',
          });
        }
        setTimeout(() => this.setState({
          errorProvider: null,
          error: null,
        }), 5000);
      });
  }

  connectMailbox = (provider) => {
    this.setState({ modal: true });
    axios.get(`/user/OAuth/${provider}`)
      .then((response) => {
        if (typeof response.data === 'object' && response.data.success === true) {
          // Open a new window
          window.open(response.data.url, '_blank', 'location=yes,height=800,width=620,scrollbars=yes,status=yes');
          this.checkMailboxConnection(provider);
        } else {
          // EVERYTHING ELSE
          this.setState({
            errorProvider: provider,
            error: 'Sorry, an error occurred. Please try again or contact support if the problem occurs again.',
          });
        }
      })
      .catch((error) => {
        if (typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.setState({
            errorProvider: provider,
            error: error.response.data.message,
          });
        } else {
          this.setState({
            errorProvider: provider,
            error: 'Sorry, an error occurred. Please try again or contact support if the problem occurs again.',
          });
        }
        setTimeout(() => this.setState({
          errorProvider: null,
          error: null,
        }), 5000);
      });
  }

  checkMailboxConnection = () => {
    this.timer = setInterval(() => {
      axios.get('user')
        .then((response) => {
          if (response.data instanceof Object && 'mailboxConnected' in response.data
            && response.data.mailboxConnected) {
            this.updateUser(response.data);
          }
        });
    }, 4000);
  }

  toggleModalConnect = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  goToPricingPage = () => {
    window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/pricing`;
  };

  updateUser = (user) => {
    const { dispatch } = this.props;

    dispatch(updateUserInfo(user));
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({
      mailboxError: null,
      user,
    });
    clearInterval(this.timer);
  }

  unlinkAccount = () => {
    this.setState({ loading: true }); // Show loading icon when button is clicked
    const user = JSON.parse(localStorage.getItem('user'));
    axios.get('/user/unlinkMailbox')
      .then((response) => {
        if (response.data instanceof Object && 'success' in response.data && response.data.success) {
          user.mailboxConnected = 0;
          this.updateUser(user);
          this.showNotifSuccess('Account unlinked', 'Your account has been unlinked successfully');
        }
      })
      .catch((error) => {
        this.showNotifError('Error unlinking your account', error.response.data.message);
      })
      .finally(() => {
        this.setState({ loading: false }); // Hide loading icon after the call completes
      });
  }

  showNotifError = (title, message) => showNotification({
    notification: <LinkNotification
      color="danger"
      title={title}
      message={message}
    />,
    position: 'right-up',
  });

  showNotifSuccess = (title, message) => showNotification({
    notification: <LinkNotification
      color="primary"
      title={title}
      message={message}
    />,
    position: 'right-up',
  });

  render() {
    const {
      triggerTour, modal, error, errorProvider, closeTour, mailboxError,
      user, modalUpgrade, loading,
    } = this.state;

    if (user && user.mailboxConnected) {
      return (
        <Row>
          <Col xs={12}>
            <Alert color="info" className="alert--margin">
              <p>
                Your mailbox is linked to Angels Partners.
                Start adding investors to your campaigns to automate investor outreach and save time on your
                fundraising!
                <br />
                <Button
                  role="button"
                  onClick={() => this.unlinkAccount()}
                  size="sm"
                  color="primary"
                  className={'{loading && "icon icon--right"} rounded bottom-0 float-right mt-4'}
                >
                  Unlink{loading && 'ing'} Account
                  {loading && <LoadingIcon className="mdi-icon-spinning" />}
                </Button>
              </p>
            </Alert>
          </Col>
        </Row>
      );
    }
    return (
      <div>
        {user
          ? (
            <>
              <Row>
                <Col xs={12}>
                  {mailboxError
                    && (
                      <Alert color="warning" className="alert--margin">
                        <p>
                          <span className="bold-text">Warning: Please reconnect your account.</span><br />
                          {mailboxError}
                        </p>
                      </Alert>
                    )}
                  {user && user.isCustomer
                    ? (
                      <Alert color="info" className="alert--margin">
                        <p>
                          <span className="bold-text">Information: </span>
                          By connecting your account to Angels Partners, you will be able to automatically send emails
                          to our panel of investors via your own mailbox and fully automate your campaigns.
                        </p>
                      </Alert>
                    ) : (
                      <Alert color="warning" className="alert--margin">
                        <p>
                          <span className="bold-text">Information: </span>
                          You need to subscribe to one of our plans to connect your mailbox to Angels Partners and start
                          sending emails to investors.
                        </p>
                      </Alert>
                    )}
                </Col>
              </Row>
              <Row className="mail-boxes">
                <Col xs={12} sm={6} xl={3}>
                  <Mailbox
                    allowed={user && user.isCustomer}
                    provider="Gmail"
                    name="Gmail"
                    error={error && errorProvider === 'Gmail' ? error : false}
                    onProviderSelected={() => this.onProviderSelected('Gmail')}
                    triggerModalUpdate={this.toggleModalUpgrade}
                  />
                </Col>
                <Col xs={12} sm={6} xl={3}>
                  <Mailbox
                    allowed={user && user.isCustomer}
                    provider="Outlook"
                    name="Outlook"
                    error={error && errorProvider === 'Outlook' ? error : false}
                    onProviderSelected={() => this.onProviderSelected('Outlook')}
                    triggerModalUpdate={this.toggleModalUpgrade}
                  />
                </Col>
                <Col xs={12} sm={6} xl={3}>
                  <Mailbox
                    allowed={user && user.isCustomer}
                    provider="Exchange"
                    name="Exchange & Office 360"
                    error={error && errorProvider === 'Exchange' ? error : false}
                    onProviderSelected={() => this.onProviderSelected('Exchange')}
                    triggerModalUpdate={this.toggleModalUpgrade}
                  />
                </Col>
                <Col xs={12} sm={6} xl={3}>
                  <Mailbox
                    allowed={user && user.isCustomer}
                    provider="SMTP"
                    name="SMTP or IMAP"
                    error={error && errorProvider === 'SMTP' ? error : false}
                    onProviderSelected={() => this.onProviderSelected('SMTP')}
                    triggerModalUpdate={this.toggleModalUpgrade}
                  />
                </Col>
              </Row>
              {triggerTour
                && (
                  <TourPopup
                    page="settings"
                    triggerTour={triggerTour}
                    closeTour={closeTour}
                  />
                )}
              <ModalWhiteList
                color="security"
                title="Before you continue"
                colored
                displayed={modal}
                toggleModal={this.toggleModalConnect}
                navigator={window.navigator.vendor}
              />
            </>
          ) : (
            <AccountWarnings
              showModalTrial
            />
          )}
        <Modal
          color="primary"
          title="Upgrade Required!"
          colored
          displayed={modalUpgrade}
          toggleModal={() => this.toggleModalUpgrade()}
          message={`Please upgrade your account to a paid plan if you wish to connect your mailbox.
           You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
           Pricing Page</a>.`}
        />
      </div>
    );
  }
}

export default withRouter(connect(null)(Mailboxes));
