import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'reactstrap';
import axios from 'axios';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRighIcon from 'mdi-react/ChevronRightIcon';
import queryString from 'query-string';
import NotificationSystem from 'rc-notification';

import FounderMain from './components/FounderMain';
import FounderTabs from './components/FounderTabs';
import { FullWideNotification } from '../../shared/components/notifications/Notification';
import ModalInput from '../../shared/components/modals/ModalInput';
import Alert from '../../shared/components/alerts/Alert';

let notificationTC = null;

const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 5,
    closable: true,
    style: { top: 0, left: 0 },
    className: `${position} ${rtl}-support`,
  });
};

class FounderProfile extends PureComponent {
  state = {
    founder: {},
    loading: true,
    loaded: false,
    modalPing: false,
    modalDecline: false,
  };

  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    const { props } = this;
    const { userId } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));

    if (props.match.url === '/founder/myProfile') {
      if (user) {
        this.init(user.id);
      } else {
        this.init(userId);
      }
    } else {
      this.init(props.match.params.id);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { state } = this;
    const prevStateId = parseInt(state.founder.id, 10);
    // eslint-disable-next-line react/prop-types
    const nextPropsId = parseInt(nextProps.match.params.id, 10);
    if (!Number.isNaN(prevStateId)
      && !Number.isNaN(nextPropsId)
      && nextPropsId !== prevStateId) {
      this.init(nextPropsId);
    }
    return null;
  }

  init = (id) => {
    axios.get(`founders/profile/${id}`)
      .then((response) => {
        this.setState({ founder: response.data, loading: false });
        setTimeout(() => this.setState({ loaded: true }), 500);
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
      });
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  showModalPing = () => {
    const { founder } = this.state;
    // eslint-disable-next-line react/no-unused-state
    this.setState({ modalPing: true, founderId: founder.id });
  };

  pingFounder = (message) => {
    const { founder } = this.state;

    // Remove the modal
    this.setState({ modalPing: false });

    // Set up Loading
    this.updateFounder(false, true, false, false, false);

    // Post the Ping.
    axios.post('/founders/ping', queryString.stringify({ id: founder.id, message }))
      .then((response) => {
        if (response) {
          this.updateFounder(false, false, false, false, true);
          this.setState({ modalPing: false });
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          // Do something error.response.data.message ?
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateFounder(false, false, false, false, false);
      });
  };

  connectFounder = () => {
    // eslint-disable-next-line no-unused-vars
    const { founder } = this.state;
    const { history } = this.props;

    // Set up Loading
    this.updateFounder(false, true, false, false, false);

    // Post the Ping.
    axios.post('/founders/connect', queryString.stringify({ id: founder.id }))
      .then((response) => {
        if (response) {
          // Set up Loading
          this.updateFounder(true, false, false, false, false);
          if (response.data) {
            history.push(response.data ? `/inbox/${response.data}` : '/inbox');
          } else {
            this.showNotif('We could not connect you with this founder at this time. Please try again.');
            this.updateFounder(false, false, false, false, false);
          }
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          // Do something error.response.data.message ?
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateFounder(false, false, false, false, false);
      });
  };

  declineFounder = (message) => {
    const { founder } = this.state;
    // Remove the modal
    // Set up Loading
    this.updateFounder(false, false, true, false, false);

    // Post the Ping
    axios.post('/founders/decline', queryString.stringify({ id: founder.id, message }))
      .then((response) => {
        if (response.data && response) {
          // Set up Loading
          this.updateFounder(false, false, false, true, false);
          this.setState({ modalDecline: false });
        } else {
          this.showNotif('We could not connect you with this founder at this time. Please try again.');
          this.updateFounder(false, false, false, false, false);
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          // Do something error.response.data.message ?
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        // Set up Loading
        this.updateFounder(false, false, false, false, false);
      });
  };

  updateFounder = (accepted, pinging, declining, declined, pinged) => {
    // Set up Loading
    const { founder } = this.state;

    // Update Founder
    const updatedFounder = {
      ...founder, accepted, pinging, declining, declined, pinged,
    };

    // Update the State
    this.setState({ founder: updatedFounder });

    return true;
  };

  showModalDecline = () => {
    this.setState({ modalDecline: true });
  };

  goBack = () => {
    // eslint-disable-next-line react/prop-types
    const { history } = this.props;
    history.goBack();
  };

  render = () => {
    const {
      founder, loading, loaded, modalPing, modalDecline,
    } = this.state;

    return (
      <Container>
        {!loaded
          ? (
            <div className={`load${loading ? '' : ' loaded'} inload`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          )
          : (
            <div className="profile startup founder">
              <Row>
                <Col xs={12} lg={1} className="mb-4">
                  <span
                    role="presentation"
                    onMouseDown={e => this.goBack(e)}
                    onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { this.goBack(e); } }}
                    className="back-btn"
                  >
                    <ChevronLeftIcon /> BACK
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={11}>
                  {founder.ping
                    ? (
                      <Alert color="info" className="alert--margin alert--colored mt-5 mt-md-0 mb-5">
                        <h4 className="mb-4">{`${founder.first_name} sent you a message`}</h4>
                        <p>
                          {founder.ping}
                        </p>
                      </Alert>
                    ) : (
                      <Alert color="info" className="alert--margin">
                        <div>
                          <span className="bold-text">How to contact founders? </span>
                          You can ask for warm introductions within our community. Warm intros are done by founders at
                          their discretion. It is usually best to connect with founder within your industry with whom
                          you can also refer to people in your network. It is a share of contact information
                          <br />
                          <a href="https://angelspartners.com/blog">
                            <ChevronRighIcon /> Learn more
                          </a>
                        </div>
                      </Alert>
                    )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={11}>
                  <FounderMain
                    startup={founder}
                    ping={() => this.showModalPing()}
                    connect={() => this.connectFounder()}
                    decline={() => this.showModalDecline()}
                  />
                </Col>
              </Row>
              <FounderTabs
                startup={founder}
              />
            </div>
          )
        }
        <ModalInput
          color="primary"
          title="Send a Message"
          header
          displayed={modalPing}
          ping={val => this.pingFounder(val)}
        />
        <ModalInput
          color="primary"
          title="Send a Message"
          header
          displayed={modalDecline}
          ping={val => this.declineFounder(val)}
        />
      </Container>
    );
  }
}

export default withRouter(FounderProfile);
