import React, { PureComponent } from 'react';
import {
  Card, Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CheckboxMarkedCircleOutlineIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import CheckboxBlankCircleOutlineIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';

class StepByStep extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      mailboxConnected: PropTypes.bool,
      completion: PropTypes.number,
      campaigns: PropTypes.number,
    }).isRequired,
  };

  render() {
    const { user } = this.props;

    return (
      <Card elevation={0} className="Dashboard__empty m-5 p-5 pt-4 mt-4 w-auto">
        <Row>
          <Col xs={12} md={6} xl={6}>
            <Link to="/myProfile/company" className="pointer">
              <div className={`steps 
                ${(user && user.completion >= 100) ? 'step_disabled' : 'step-1'}`}
              >
                {user && user.completion >= 100
                  ? (
                    <CheckboxMarkedCircleOutlineIcon size={50} />
                  ) : (
                    <CheckboxBlankCircleOutlineIcon size={50} />
                  )}
                <h2>Step 1</h2>
                <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
                <h3>Fill out your Profile to 100%</h3>
                <p>
                  Fill out all the information on your profile and make sure your completion rate is at 100%
                  to increase your ranking on our platform and maximise your chances of getting funded.
                </p>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <Link to="/campaigns/create">
              <div className={`steps 
                ${(user && user.campaigns > 0) ? 'step_disabled' : 'step-2'}`}
              >
                {user && user.campaigns > 0
                  ? (
                    <CheckboxMarkedCircleOutlineIcon size={50} />
                  ) : (
                    <CheckboxBlankCircleOutlineIcon size={50} />
                  )}
                <h2>Step 2</h2>
                <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
                <h3>Create your first campaign</h3>
                <p>
                  Use our pre-made templates and set up your campaigns in details to maximize your chances of
                  getting responses from investors.
                </p>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <Link to="/myProfile/settings">
              <div className={`steps 
                ${user && user.mailboxConnected ? 'step_disabled' : 'step-3'}`}
              >
                {user && user.mailboxConnected
                  ? (
                    <CheckboxMarkedCircleOutlineIcon size={50} />
                  ) : (
                    <CheckboxBlankCircleOutlineIcon size={50} />
                  )}
                <h2>Step 3</h2>
                <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
                <h3>Connect your mailbox</h3>
                <p>
                  By connecting your email account to Angels Partners, you will be able to automatically send
                  emails to investors via your own mailbox and fully automate your campaigns.
                </p>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <Link to="/investors-db">
              <div className="steps step-1">
                <CheckboxBlankCircleOutlineIcon size={50} />
                <h2>Step 4</h2>
                <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
                <h3>Add Investors to your Campaign</h3>
                <p>
                  Add some investors to your campaigns to share your deck and materials, track their visits
                  and time spent on your deck and gauge their interest.
                </p>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <Link to="/investors">
              <div className="steps step-2">
                <CheckboxBlankCircleOutlineIcon size={50} />
                <h2>Step 5</h2>
                <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
                <h3>Start Contacting Internal Investors</h3>
                <p>
                  Browse our community of investors registered on our platform and actively investing.
                </p>
              </div>
            </Link>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default StepByStep;
