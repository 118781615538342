import React from 'react';
import {
  Badge, Card, CardBody,
} from 'reactstrap';

const PanelTemplates = () => (
  <Card className="panel panel--divider card shadow mb-3">
    <CardBody className="panel__body">
      <div className="panel__title no-padding">
        <h5 className="bold-text">
          <Badge className="panel__label">Improvement</Badge>
          &nbsp;&nbsp;&nbsp;
          Add 2 Follow ups to your initial email
        </h5>
      </div>
      <div className="panel__content">
        <p className="typography-message">
          Having analysed thousands of campaigns, our research demonstrated that campaigns perform better with 2 follow
          up emails, scheduled after the initial email.<br />
          You can <strong>boost your response rate <u>by up to 100%</u></strong> by adding 2 follow ups to your initial
          email.
        </p>
      </div>
    </CardBody>
  </Card>
);

export default PanelTemplates;
