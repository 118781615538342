import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';
import LinkedinTemplatesForm from './LinkedinTemplatesForm';

class LinkedinTemplates extends PureComponent {
  constructor() {
    super();
    this.state = {
      templates: null,
      loading: true,
      loaded: false,
      campaignId: null,
    };
  }

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates = () => {
    const { showNotifError } = this.props;
    axios.get('/introductions/getTemplates')
      .then((response) => {
        if (response.data && response.data.success) {
          this.setState({
            templates: { templates: response.data.templates }, // formatting values for the redux form
            loading: false,
            campaignId: response.data.campaignId,
          }, () => {
            setTimeout(() => this.setState({ loaded: true }), 500);
          });
        } else {
          showNotifError('Sorry, an error occurred, please try again.');
        }
      })
      .catch((error) => {
        if (error && error.response) {
          showNotifError(error.response.data.error || 'Sorry, an error occurred, please try again.');
        }
        showNotifError('Sorry, an error occurred, please try again.');
      });
  }

  saveTemplates = (values) => {
    const {
      getInvestorProspects, showNotifSuccess, showNotifError,
    } = this.props;
    const { campaignId } = this.state;

    const templates = {};
    if (typeof values.templates !== typeof undefined) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < values.templates.length; i++) {
        templates[`templates[${i}][title]`] = 'Linkedin Campaign';
        templates[`templates[${i}][content]`] = values.templates[i].content;
        templates[`templates[${i}][position]`] = i;
        templates[`templates[${i}][delay]`] = i > 0 ? values.templates[i].delay : 0;
      }
    }

    axios.post('introductions/saveTemplates', queryString.stringify({ ...templates, id: campaignId }))
      .then((response) => {
        if (response.data && response.data.success) {
          showNotifSuccess('Templates saved successfully');
          this.getTemplates();
          getInvestorProspects();
        } else {
          showNotifError(response.data.error);
        }
      }).catch((error) => {
        if (error && error.response) {
          showNotifError(error.response.data.error || 'Sorry, an error occurred, please try again.');
        }
        showNotifError('Sorry, an error occurred, please try again.');
      });
  }


  render = () => {
    const { templates, loading, loaded } = this.state;
    const { cancel } = this.props;

    return (
      <div className="ml-auto mr-auto mt-5 pt-5 w-100">
        <div className="wizard__form__header">
          <h3 className="wizard__title">Compose Messages in this campaign</h3>
          <p className="wizard__subtitle">
            Start drafting the first message of the campaign and add follow ups to increase your chances of
            getting responses.
          </p>
        </div>
        {!loaded
          ? (
            <div className={`load${loading ? '' : ' loaded'} inload`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          ) : (
            <LinkedinTemplatesForm
              onSubmit={val => this.saveTemplates(val)}
              initialValues={templates}
              cancel={cancel}
            />
          )
        }
      </div>
    );
  }
}

LinkedinTemplates.propTypes = {
  showNotifSuccess: PropTypes.func.isRequired,
  showNotifError: PropTypes.func.isRequired,
  getInvestorProspects: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};


export default LinkedinTemplates;
