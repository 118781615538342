import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import PropTypes from 'prop-types';
import validate from './formValidate/report';
import renderRadioButtonField from '../../form/RadioButton';

const renderTextarea = ({
  input, placeholder, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <textarea {...input} placeholder={placeholder} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderTextarea.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTextarea.defaultProps = {
  placeholder: '',
  meta: null,
};

class ReportForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
  };

  toggle = () => {
    const { toggle, reset } = this.props;
    reset();
    toggle();
  }

  render() {
    const {
      handleSubmit, pristine, submitting, onSubmit,
    } = this.props;
    return (
      <div>
        <div className="modal__body">
          <form className="form form-report" onSubmit={handleSubmit(onSubmit.bind(this))}>
            <div className="form__form-group form__form-group-field">
              <Field
                name="report"
                component={renderRadioButtonField}
                label="Invalid Email Address"
                radioValue="Invalid Email Address"
              />
            </div>
            <div className="form__form-group form__form-group-field">
              <Field
                name="report"
                component={renderRadioButtonField}
                label="Wrong Person"
                radioValue="Wrong Person"
              />
            </div>
            <div className="form__form-group form__form-group-field">
              <Field
                name="report"
                component={renderRadioButtonField}
                label="Does not work at this fund anymore"
                radioValue="Does not work at this fund anymore"
              />
            </div>
            <div className="form__form-group form__form-group-field">
              <Field
                name="report"
                component={renderRadioButtonField}
                label="Other"
                radioValue="Other"
              />
            </div>
            <div className="form__form-group form__form-group-field mt-5 mb-3">
              <Field
                name="message"
                component={renderTextarea}
                placeholder="Please tell us the reason why are you reporting this investor"
              />
            </div>
            <ButtonToolbar className="modal__footer">
              <Button
                className="modal_cancel rounded"
                onClick={this.toggle}
                disabled={pristine || submitting}
              >
                Cancel
              </Button>
              {' '}
              <Button
                className="modal_ok rounded icon icon--right icon--blue"
                type="submit"
                color="primary"
                disabled={submitting}
              >
                Submit
                <SendIcon />
              </Button>
            </ButtonToolbar>
          </form>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'report_form', // a unique identifier for this form
  validate,
})(ReportForm);
