import {
  UPDATE_INVESTORS_ADDED,
  UPDATE_USER_INFO,
  UPDATE_AVATAR,
  UPDATE_NAME,
} from '../actions/userActions';

const initialState = {
  id: null,
  name: null,
  email: null,
  avatar: null,
  registered: 0,
  isCustomer: 0,
  accepted: 0,
  type: null,
  mailboxConnected: 0,
  completion: 0,
  campaigns: 0,
  investorsAdded: 0,
  paywallGroup: 0,
  impersonator: null,
  appSubmitted: false,
  completionInfo: null,
  countSend: 0,
  isTrialStarted: false,
  mailboxError: null,
  mailboxWhitelisted: false,
  paymentGateway: null,
  pings: 0,
  plan: null,
  plugin: 0,
  startupName: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return {
        id: action.value.id,
        name: action.value.name,
        email: action.value.email,
        avatar: action.value.avatar,
        registered: action.value.registered,
        isCustomer: action.value.isCustomer,
        isPayingCustomer: action.value.isPayingCustomer,
        accepted: action.value.accepted,
        type: action.value.type,
        mailboxConnected: action.value.mailboxConnected,
        completion: action.value.completion,
        campaigns: action.value.campaigns,
        investorsAdded: action.value.investorsAdded,
        paywallGroup: action.value.paywallGroup,
        impersonator: action.value.impersonator,
        appSubmitted: action.value.appSubmitted,
        completionInfo: action.value.completionInfo,
        countSend: action.value.countSend,
        isTrialStarted: action.value.isTrialStarted,
        mailboxError: action.value.mailboxError,
        mailboxWhitelisted: action.value.mailboxWhitelisted,
        paymentGateway: action.value.paymentGateway,
        pings: action.value.pings,
        plan: action.value.plan,
        plugin: action.value.plugin,
        startupName: action.value.startupName,
      };
    case UPDATE_AVATAR:
      return {
        ...state,
        avatar: action.value,
      };
    case UPDATE_INVESTORS_ADDED:
      return {
        ...state,
        investorsAdded: action.value,
      };
    case UPDATE_NAME:
      return {
        ...state,
        name: action.value.name,
        email: action.value.email,
      };
    default:
      return state;
  }
}
