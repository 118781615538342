import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import ProfileBasic from '../../shared/components/ProfileSidebar';
import ProfileTabs from './components/ProfileTabs';

class Profile extends PureComponent {
  state = {
    startup: {},
    profile: {},
    founder: {},
    loading: true,
    loaded: false,
    redirect: null,
  };

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user === null) {
      this.setRedirect('/');
    } else {
      this.getMyInfo();
    }
  };

  setRedirect = (redirect) => {
    this.setState({
      redirect,
    }, () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  };

  getMyInfo = () => {
    axios.get('founder/myProfile')
      .then((response) => {
        const startup = {
          name: response.data.startup,
          logo: response.data.logo,
          location_city: response.data.location_city,
          location_country: response.data.location_country,
        };

        const profile = {
          id: response.data.id,
          pings: response.data.pings,
          pingsUsed: response.data.pingsUsed,
          account: response.data.planType,
          dateTrialEnd: response.data.dateTrialEnd,
          datePlanEnd: response.data.datePlanEnd,
          dateTrialCancelled: response.data.dateTrialCancelled,
          willRenew: response.data.willRenew,
          isCustomer: response.data.isCustomer,
        };

        const founder = {
          message: response.data.message,
          community: response.data.community,
          founderLinkedin: response.data.founderLinkedin,
        };

        this.setState({
          startup, profile, founder, loading: false,
        });
        setTimeout(() => this.setState({ loaded: true }), 500);
      });
  };

  render = () => {
    const {
      startup, profile, loaded, loading, redirect, founder,
    } = this.state;

    return (
      <Container>
        {redirect !== null && <Redirect to={redirect} />}
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Founder Profile</h3>
          </Col>
          <Col xs={12} md={9} lg={6} xl={5}>
            <div className="page-description">
              <strong>Your Founder Profile is shared with other founders on the platform</strong><br />
              You can use your founder profile to connect with other founders and benefit from mutual investors&#39;
              introductions.
            </div>
          </Col>
        </Row>
        {!loaded
          ? (
            <div className={`load${loading ? '' : ' loaded'} inload`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          )
          : (
            <Row className="company mt-5">
              <Col xs={12} xl={8} className="order-1 order-xl-0 p-0 p-lg-3">
                <ProfileTabs
                  message={founder.message}
                  community={founder.community}
                  founderLinkedin={founder.founderLinkedin}
                  getMyInfo={() => this.getMyInfo()}
                />
              </Col>
              <Col xs={12} xl={4} className="order-0 order-xl-1">
                <ProfileBasic
                  id={profile.id}
                  name={startup.name}
                  logo={startup.logo}
                  locationCity={startup.location_city}
                  locationCountry={startup.location_country}
                  pings={profile.pings}
                  pingsUsed={profile.pingsUsed}
                  account={profile.account}
                  dateTrialEnd={profile.dateTrialEnd}
                  datePlanEnd={profile.datePlanEnd}
                  dateTrialCancelled={profile.dateTrialCancelled}
                  willRenew={profile.willRenew}
                  isCustomer={profile.isCustomer}
                  founder
                />
              </Col>
            </Row>
          )
        }
      </Container>
    );
  }
}

export default connect(state => ({
  user: state.user,
}))(Profile);
