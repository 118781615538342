import React, { PureComponent } from 'react';
import { ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, isPristine } from 'redux-form';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import ChevronRight from 'mdi-react/ChevronRightIcon';
// import HelpCircleIcon from 'mdi-react/HelpCircleIcon';
import validate from '../formValidate/formOne';
import renderToggleButtonField from '../../../shared/components/form/ToggleButton';
import renderTextFieldBasic from '../../../shared/components/form/TextFieldBasic';
import TourPopup from '../../../shared/components/tours/TourPopup';

class WizardFormOne extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    resetForm: PropTypes.bool.isRequired,
    isPristine: PropTypes.bool.isRequired,
    campaignId: PropTypes.number,
    initialValues: PropTypes.shape({
      dateCreated: PropTypes.string,
      description: PropTypes.string,
      draft: PropTypes.string,
      hasLink: PropTypes.bool,
      id: PropTypes.number,
      name: PropTypes.string,
      nbEmailsOpen: PropTypes.number,
      nbEmailsOpenNylas: PropTypes.number,
      nbEmailsReplied: PropTypes.number,
      nbEmailsSent: PropTypes.number,
      paused: PropTypes.bool,
      sendOnFriday: PropTypes.bool,
      sendOnMonday: PropTypes.bool,
      sendOnThursday: PropTypes.bool,
      sendOnTuesday: PropTypes.bool,
      sendOnWednesday: PropTypes.bool,
      stopOnOpen: PropTypes.bool,
      stopOnResponse: PropTypes.bool,
      stopOnVisit: PropTypes.bool,
      uniqueRecipients: PropTypes.bool,
      smartVC: PropTypes.bool,
    }),
  };

  static defaultProps = {
    campaignId: 0,
    initialValues: PropTypes.shape({
      dateCreated: '',
      description: null,
      draft: null,
      hasLink: true,
      id: 0,
      name: '',
      nbEmailsOpen: 0,
      nbEmailsOpenNylas: 0,
      nbEmailsReplied: 0,
      nbEmailsSent: 0,
      paused: false,
      sendOnFriday: false,
      sendOnMonday: false,
      sendOnThursday: false,
      sendOnTuesday: false,
      sendOnWednesday: false,
      stopOnOpen: false,
      stopOnResponse: true,
      stopOnVisit: false,
      uniqueRecipients: false,
      smartVC: false,
    }),
  };

  state = {
    triggerTour: false,
    mailboxWhitelisted: null,
  }

  constructor(props) {
    super(props);
    if ((props.isPristine || props.resetForm) && !props.initialValues && !props.campaignId) {
      props.destroy();
    }
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));

    // If this user has no previous campaigns created, we guide him through this process.
    if (!user.campaigns) {
      this.setState({ triggerTour: true });
    }
    this.setState({ mailboxWhitelisted: user.mailboxWhitelisted });
  }


  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { triggerTour, mailboxWhitelisted } = this.state;
    const { handleSubmit, initialValues } = this.props;

    return (
      <form
        className="form form--horizontal wizard__form wizard__form__campaign wizard__form__step-one"
        onSubmit={handleSubmit}
      >
        <div className="wizard__form__header mb-4">
          <h3 className="wizard__title">Let&apos;s get started</h3>
        </div>
        <div className="form__form-group mb-5 mt-5" id="campaign-name">
          <span className="form__form-group-label medium">Name your campaign</span>
          <div className="form__form-group-field medium">
            <Field
              name="name"
              component={renderTextFieldBasic}
              type="text"
              placeholder="Investors outreach"
            />
          </div>
        </div>
        <div className={`form__form-group ${!mailboxWhitelisted && 'hidden'}`}>
          <span className="form__form-group-label large">
            Stop sending emails when an investor <strong>responds to an email.</strong>
          </span>
          <div className="form__form-group-field large">
            <Field
              name="stopOnResponse"
              component={renderToggleButtonField}
              defaultChecked={initialValues ? initialValues.stopOnResponse : true}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label large">
            Stop sending emails when an investor <strong>opens an email.</strong>
          </span>
          <div className="form__form-group-field large">
            <Field
              name="stopOnOpen"
              component={renderToggleButtonField}
              defaultChecked={initialValues ? initialValues.stopOnOpen : false}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label large">
            Stop sending emails when an investor <strong>visits your profile.</strong>
          </span>
          <div className="form__form-group-field large">
            <Field
              name="stopOnVisit"
              component={renderToggleButtonField}
              defaultChecked={initialValues ? initialValues.stopOnVisit : false}
            />
          </div>
        </div>
        <hr />
        <div className="form__form-group">
          <span className="form__form-group-label large">
            Only add investors who are not part of any other campaign.
          </span>
          <div className="form__form-group-field large">
            <Field
              name="uniqueRecipients"
              component={renderToggleButtonField}
              defaultChecked={initialValues ? initialValues.uniqueRecipients : false}
            />
          </div>
        </div>
        {/* <div className="form__form-group"> */}
        {/*  <span className="form__form-group-label large"> */}
        {/*    Only contact one VC per Fund at a time.&nbsp;&nbsp;&nbsp; */}
        {/*    <span id="smartVC" className="text-black-50"><small><HelpCircleIcon /></small></span> */}
        {/*  </span> */}
        {/*  <div className="form__form-group-field large"> */}
        {/*    <Field */}
        {/*      name="smartVC" */}
        {/*      component={renderToggleButtonField} */}
        {/*      defaultChecked={initialValues ? initialValues.smartVC : false} */}
        {/*    /> */}
        {/*  </div> */}
        {/*  <UncontrolledTooltip target="smartVC"> */}
        {/* eslint-disable-next-line max-len */}
        {/*    If you add multiple VCs from the same fund, they will be contacted one after the other, in order to prevent */}
        {/*    you from contacting multiple investors from the same fund at the same time. If you add more than one */}
        {/* eslint-disable-next-line max-len */}
        {/*    investor from a fund to your campaign, the next investor will be contacted once the campaign for the first */}
        {/*    investor is over. */}
        {/*  </UncontrolledTooltip> */}
        {/* </div> */}
        <div className="form__form-group">
          <ButtonToolbar className="form__button-toolbar wizard__toolbar">
            {/* <Button color="primary" type="button" disabled className="previous">Back</Button> */}
            <button type="submit" className="btn btn-primary rounded icon next">
              <p>
                Next
                <ChevronRight />
              </p>
            </button>
          </ButtonToolbar>
        </div>
        {triggerTour
        && (
          <TourPopup
            page="campaign1"
            triggerTour={triggerTour}
            disableFocusLock
          />
        )}
      </form>
    );
  }
}

// eslint-disable-next-line no-class-assign
WizardFormOne = connect(state => ({
  isPristine: isPristine('wizard')(state),
}))(WizardFormOne);

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: true,
  validate,
})(WizardFormOne);
