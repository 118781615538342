import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row,
} from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Link from 'react-router-dom/Link';
import Alert from '../../alerts/Alert';
import getPicture from '../../../functions/getPicture';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const ModalCancelContent = ({
  user, updateError, cancelledMessage, toggleModal, switchPlan, cancelSubscription, bookCall,
}) => (
  <div>
    {(user.isPayingCustomer && user.paymentGateway === 'stripe') || (!user.isPayingCustomer && user.isCustomer) ? (
      <>
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <img
            className="modal__investor-pic"
            src={getPicture(user)}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = userIcon;
            }}
            alt="avatar"
          />
          <h4 className="modal__title">
            {(user.isPayingCustomer && user.paymentGateway === 'stripe') && ('Cancel Your Subscription')}
            {(!user.isPayingCustomer && user.isCustomer) && ('Cancel Your Trial')}
          </h4>
          <img src={`${process.env.PUBLIC_URL}/img/linePurple.svg`} className="line" alt="line" />
          {updateError && (
            <Alert color="danger" className="alert--colored w-100 mb-5">
              <p className="text-left">
                <span className="bold-text">Error!</span>&nbsp;
                {updateError}
              </p>
            </Alert>
          )}
          {cancelledMessage && (
            <Alert color="primary" className="alert--colored w-100 mb-5">
              <p className="text-left">
                <span className="bold-text">Success!</span>&nbsp;
                {cancelledMessage}
              </p>
            </Alert>
          )}
        </div>
        <Row className="subscription-boxes">
          <Col xs={12} md={12} lg={8}>
            <div
              id="switch-plan"
              className="subscription-boxes-box"
            >
              {(user.isPayingCustomer && user.paymentGateway === 'stripe') && (
                <>
                  <h2 className="subscription-boxes-title">
                    Remain listed & update investors
                  </h2>
                  <p className="subscription-boxes-subtitle mb-3">
                    Switch to our cheapest plan at $19.99/month in order to:
                  </p>
                  <ul className="subscription-boxes-bullets">
                    <li>Stay listed on the platform, investors can still contact you</li>
                    <li>Send updates to investors you previously contacted</li>
                    <li>Keep using your tracking links</li>
                    <li>Keep access to our legal documents</li>
                    <li>Keep the pings left on your account</li>
                  </ul>
                  <Button
                    color="primary"
                    className="rounded icon icon--right select-boxes-go"
                    onClick={() => switchPlan('alt_web_plan')}
                  >
                    Switch Plan <ChevronRightIcon />
                  </Button>
                </>
              )}
              {(!user.isPayingCustomer && user.isCustomer) && (
                <>
                  <h2 className="subscription-boxes-title">Book a Call</h2>
                  <p className="subscription-boxes-subtitle mb-3">
                    <strong>We would love to personally help you in your fundraising journey.</strong>
                  </p>
                  <ul className="subscription-boxes-subtitle mb-3">
                    We would like to offer a quick call to discuss how we could make you successful before the end of
                    your trial. We can talk tactics to best leverage the tools and features to help you in your quest
                    for funding, and answer any questions you might have.
                  </ul>
                  <Button
                    color="primary"
                    className="rounded icon icon--right select-boxes-go"
                    onClick={bookCall}
                  >
                    Book a Call <ChevronRightIcon />
                  </Button>
                </>
              )}
            </div>
          </Col>
          <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 0 }} className="mt-5 mt-lg-0">
            <div
              className="subscription-boxes-box"
              id="unsubscribe"
            >
              <h2 className="subscription-boxes-title">Unsubscribe</h2>
              <p className="subscription-boxes-subtitle">
                {(user.isPayingCustomer && user.paymentGateway === 'stripe') && (
                  'You will lose your pings, all of your data will be removed from the platform and you will not be '
                  + 'listed to our panel of investors. You will not be able to contact investors anymore.'
                )}
                {(!user.isPayingCustomer && user.isCustomer) && (
                  'You will not be charged after the end of your trial and we will un-list your profile from the '
                  + 'platform. You will not be able to contact investors anymore.'
                )}
              </p>
              <br />
              <Button
                color="primary"
                className="rounded icon icon--right select-boxes-go"
                onClick={cancelSubscription}
              >
                Cancel Now <ChevronRightIcon />
              </Button>
            </div>
          </Col>
        </Row>
      </>
    ) : (
      <>
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="modal__title">
            Something Unexpected Happened.
            { /* Click on the Paypal Link to continue with Paypal */ }
          </h4>
          <img src={`${process.env.PUBLIC_URL}/img/linePurple.svg`} className="line" alt="line" />
        </div>
        <div className="mt-5" align="center">
          Please contact customer support.
          <br />
          <Link to="/ext/contact" onClick={toggleModal}>
            Contact Us
          </Link>
          {/* <PayPalButtons style={{ layout: 'horizontal' }} /> */}
        </div>
      </>
    )}
  </div>
);

ModalCancelContent.propTypes = {
  user: PropTypes.shape({
    isPayingCustomer: PropTypes.bool,
    paymentGateway: PropTypes.string,
    willRenew: PropTypes.bool,
    isCustomer: PropTypes.bool,
  }).isRequired,
  updateError: PropTypes.string.isRequired,
  cancelledMessage: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  switchPlan: PropTypes.func.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  bookCall: PropTypes.func.isRequired,
};

export default ModalCancelContent;
