import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import Chat from './Chat';
import { FullWideNotification } from '../../../shared/components/notifications/Notification';

let notificationTC = null;

const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 5,
    closable: true,
    style: { top: 0, left: 0 },
    className: `${position} ${rtl}-support`,
  });
};

export default class ChatCard extends PureComponent {
  constructor() {
    super();
    this.state = {
      contacts: [],
      currentUser: JSON.parse(localStorage.getItem('user')),
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getChats(false);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
  };

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  getChats = () => {
    axios.get('conversations')
      .then((response) => {
        this.setState({ contacts: response.data, loading: false });
        axios.post('/conversations/unread/remove');
      }).catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          // Do something error.response.data.message ?
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again or contact support.');
        }
      });
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  render() {
    const { contacts, currentUser, loading } = this.state;
    const { id } = this.props;

    if (loading) {
      return (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            {contacts.length > 0 ? (
              <Chat
                currentChat={id}
                contacts={contacts}
                currentUser={{
                  avatar: currentUser.avatar,
                  name: currentUser.name,
                  registered: currentUser.registered,
                  type: currentUser.type,
                }}
                showNotification={
                  ({ notification, position }) => showNotification({ notification, position }, 'ltr')
                }
              />
            ) : (
              <div>You do not have any on going conversations. <br />
                Ping & connect with startups or investors first and return here once they have accepted your invitation
                to connect
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

ChatCard.propTypes = {
  id: PropTypes.number,
};

ChatCard.defaultProps = {
  id: null,
};
