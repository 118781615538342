import React, { Component } from 'react';
import {
  Button,
  Col, Container, Row,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ModalLink from '../../shared/components/modals/ModalLink';
import DashboardElements from './components/DashboardElements';
import {
  BasicNotification,
  FullWideNotification,
  LinkNotification,
} from '../../shared/components/notifications/Notification';
import ModalSmartVC from '../../shared/components/modals/ModalSmartVC';
import StepByStep from './components/StepByStep';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class Dashboard extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
  };

  state = {
    loading: true,
    loaded: false,
    data: null,
    modal: false,
    modalNotifSmartVC: false,
    typeModalSmartVC: 'info',
    notifSmartVC: null,
    stepByStep: false,
    user: JSON.parse(localStorage.getItem('user')),
    campaigns: null,
  };

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user: JSON.parse(localStorage.getItem('user')) });

    if (typeof user !== typeof undefined && user !== null && user.type === 'investor') {
      window.location = '/startups';
    }
    if (typeof user !== typeof undefined && user !== null && user.type === 'broker') {
      window.location = '/managed_startups';
    }
    if (user) {
      this.setState({ campaigns: user.campaigns });
    }
    const event = new Event('reload');
    window.dispatchEvent(event);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
    this.retrieveDashboardInfo();
  }

  retrieveDashboardInfo = () => {
    const { user } = this.state;
    // const user = JSON.parse(sessionStorage.getItem('user'));

    axios.get('/dashboard/')
      .then((response) => {
        if (!response.data || response.data.length > 0) {
          this.toggleModalUpgrade();
        }
        if (!response.data || response.data.length === 0 || response.data.filter(m => !m.archived).length === 0) {
          this.setState({ stepByStep: true });
          this.showNotifCampaigns('All of your campaigns are archived and won’t be displayed onto your dashboard. '
            + '<strong><u>Go to yours campaigns</u></strong> to un-archive them or create new campaigns.',
          '/campaigns');
        }
        this.setState({
          data: response.data && response.data.length > 0
            ? response.data : null,
          loading: false,
        }, () => {
          setTimeout(() => this.setState({ loaded: true }), 500);
        });
      }).catch((error) => {
        if (typeof error.response === 'object' && typeof error.response.data === 'object'
          && user && user.type !== 'startup') {
          this.showNotif(
            `Sorry, an error occurred, please try again.(${error.response.data.message})`,
            'danger',
          );
        }
        this.setState({
          loading: false,
          modal: true,
        }, () => {
          this.toggleModalUpgrade();
          setTimeout(() => this.setState({ loaded: true }), 500);
        });
      });

    if (user && user.campaigns && user.campaigns > 0) {
      axios.get('/campaigns/notifSmartVC')
        .then((response) => {
          if (response.data && response.data.notif) {
            this.setState({ modalNotifSmartVC: true, notifSmartVC: response.data.notif[0] });
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
        });
    }
  }

  updateDashboard = () => {
    this.retrieveDashboardInfo();
  };

  goToImportPage = () => {
    const { history } = this.props;
    history.push('/import');
  }

  toggleModalUpgrade = () => {
    const { modalUpgrade } = this.state;
    this.setState({ modalUpgrade: !modalUpgrade });
  };

  toggleModalSmartVC = () => {
    const { modalNotifSmartVC, notifSmartVC } = this.state;
    axios.post('/campaigns/stop', queryString.stringify({
      campaignId: notifSmartVC.campaign.id,
      notifSmartVCId: notifSmartVC.id,
    })).then((response) => {
      if (response.data && response.data.success) {
        this.showNotifSuccess(
          'The investors from this fund will not be contacted anymore.',
        );
        this.setState({ modalNotifSmartVC: !modalNotifSmartVC });
      }
    }).catch(() => {
      this.showNotifWarning(
        'Something went wrong, please try again or contact support.',
      );
    });
  };

  resumeCampaign = (value) => {
    const { modalNotifSmartVC, notifSmartVC, typeModalSmartVC } = this.state;
    axios.post('/campaigns/resume', queryString.stringify({
      campaignId: notifSmartVC.campaign.id,
      notifSmartVCId: notifSmartVC.id,
      choice: value,
    })).then((response) => {
      if (response.data && response.data.success) {
        this.showNotifSuccess(
          'Your campaign has been resumed for investors from this fund',
        );
        if (typeModalSmartVC === 'choice') {
          this.setState({ modalNotifSmartVC: !modalNotifSmartVC });
        } else {
          this.setState({ typeModalSmartVC: 'choice' });
        }
      }
    }).catch(() => {
      this.showNotifWarning(
        'Something went wrong, please try again or contact support.',
      );
    });
  };

  componentWillUnmount = () => {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  showNotif = (message, type) => showNotification({
    notification: <FullWideNotification
      color={type}
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  showNotifSuccess = message => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message={message}
    />,
    position: 'right-up',
  });

  showNotifWarning = message => showNotification({
    notification: <BasicNotification
      color="warning"
      title="Warning!"
      message={message}
    />,
    position: 'right-up',
  });

  showNotifCampaigns = (message, link) => showNotification({
    notification: <LinkNotification
      color="warning"
      title="No Active Campaigns"
      message={message}
      link={link}
    />,
    position: 'right-up',
  });

  render() {
    const {
      loading, loaded, data, modal, campaigns, modalNotifSmartVC, notifSmartVC, stepByStep, user, typeModalSmartVC,
    } = this.state;

    return (
      <Container>
        {stepByStep
          ? (
            <Row className="dashboard_empty">
              <Col md={12} className="mt-5">
                <h1 className="center d-block text-center">Welcome to Angels Partners</h1>
                <h3 className="center d-block text-center page-subhead subhead">
                  You have access to a number of tools and services to help you during your fundraising.
                </h3>
              </Col>
              <StepByStep user={user} />
            </Row>
          ) : (
            <>
              <Row>
                <Col xs={12} md={3} lg={2} xl={2}>
                  <h3 className="page-title">Dashboard</h3>
                </Col>
                <Col xs={12} md={9} lg={6} xl={6}>
                  <p className="page-description">
                    Monitor your campaigns and gauge investors&#39; interest.
                  </p>
                </Col>
                {user && campaigns && campaigns > 0
                  ? (
                    <Col xs={12} md={12} lg={4} xl={4}>
                      <Button
                        className="btn-import-investor d-block mr-auto ml-auto rounded"
                        outline
                        color="primary"
                        onClick={this.goToImportPage}
                      >
                        Import Investors
                      </Button>
                    </Col>
                  ) : ''}
              </Row>
              <Row>
                <Col
                  xs={12}
                  lg={11}
                  className="mt-5 mt-md-4 mt-lg-3 p-0 pt-5 p-md-1 p-lg-3 p-xl-4 offset-md-05"
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {!loaded
                  && (
                    <div className={`load${loading ? '' : ' loaded'} inload`}>
                      <div className="load__icon-wrap">
                        <svg className="load__icon">
                          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                      </div>
                    </div>
                  )
                  }
                  {loaded
                  && (
                    <DashboardElements
                      data={data}
                      modal={modal}
                      updateDashboard={() => this.updateDashboard()}
                    />
                  )}
                  <ModalLink
                    color="primary"
                    title="Nothing there yet!"
                    colored
                    displayed={modal}
                    link="/investors-db"
                    toggleModal={() => this.toggleModalUpgrade()}
                    message="To get started, add investors to your campaigns and schedule your outreach emails."
                  />
                  <ModalSmartVC
                    color="primary"
                    type={typeModalSmartVC}
                    displayed={modalNotifSmartVC}
                    toggleModal={() => this.toggleModalSmartVC()}
                    resumeCampaign={values => this.resumeCampaign(values)}
                    message={notifSmartVC}
                  />
                </Col>
              </Row>
            </>
          )}
      </Container>
    );
  }
}

export default withRouter(connect(state => ({
  user: state.user,
}))(Dashboard));
