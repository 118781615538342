/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button, Col, Row, Table,
} from 'reactstrap';
import CardTitle from 'reactstrap/lib/CardTitle';
import PropTypes from 'prop-types';
import PlusIcon from 'mdi-react/PlusIcon';


function HiringTab(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <CardTitle>
        <Row>
          <Col xs={12} xl={8}>
            <h3>Hire the right way!</h3>
            <p className="text-muted mt-4 mb-2">
              You’re hiring? Congratulations! It&apos;s great to hear your startup is growing.
              These resources will help you organize a set of hiring documents for your new employees. <br />
              This work will also help you prepare for due diligence by investors and / or acquirers.
            </p>
          </Col>
        </Row>
      </CardTitle>
      <Table responsive hover>
        <tbody>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/177Af1GrTKO0TifH05btkDJUIu41xmvh5xrEorwl7zh4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Confidential Information and Invention Assignment Agreement For Consultants
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              A confidentiality agreement is a legally binding contract between two or more parties, in this case,
              an employer and a consultant, in which at least one of the parties agrees not to disclose certain
              information. These are also known as a NDA or non-disclosure agreement. (This is not legal advice.
              For informational purposes only).
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/177Af1GrTKO0TifH05btkDJUIu41xmvh5xrEorwl7zh4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1puMrKw1OVJGQpvnBIWdUKXb57rxxOz1W_XKLD3pCYPY/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Confidential Information and Invention Assignment Agreement for Employees
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A confidentiality agreement is a legally binding contract between two or more parties, in this case,
              an employer and employee, in which at least one of the parties agrees not to disclose certain
              information. These are also known as a NDA or non-disclosure agreement. (This is not legal advice.
              For informational purposes only).

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1puMrKw1OVJGQpvnBIWdUKXb57rxxOz1W_XKLD3pCYPY/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/16cYq0p2TXRKObfPutXH8ljLLmYROkkKpE2a1LVdXuNw/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Consulting Agreement (between Company and Consultant) from Orrick
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              A confidentiality agreement is a legally binding contract between two or more parties, in this case,
              an employer and a consultant, in which at least one of the parties agrees not to disclose certain
              information. These are also known as a NDA or non-disclosure agreement. (This is not legal advice.
              For informational purposes only).

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/16cYq0p2TXRKObfPutXH8ljLLmYROkkKpE2a1LVdXuNw/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1dJmIcr_EljMJYOBizVR9F2kOoZDFKjuzIURonvzgPss/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                CXO Employment Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              An executive employment contract is a written employment agreement, usually made between a highly
              compensated executive and an employer, that contains more expansive terms and conditions than an
              ordinary employment agreement.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1dJmIcr_EljMJYOBizVR9F2kOoZDFKjuzIURonvzgPss/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1-MgJDX5sJW1BRXhKN4GrBGntaY_kvUMIixqTqjIbWR0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Employee Handbook Example from VentureLoopHR 2013
              </a>
            </td>
          </tr>
          <tr>
            <td>
              An employee handbook, sometimes also known as an employee manual, staff handbook or company policy
              manual, is a book given to employees by an employer. The employee handbook can be used to bring
              together employment and job-related information which employees need to know.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1-MgJDX5sJW1BRXhKN4GrBGntaY_kvUMIixqTqjIbWR0/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/18W-MMQ2bbDWbR_QvW7oMpAzPjB-Od5xVhmmoUznS6I4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Employment Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              The employment agreement spells out the rules, rights and responsibilities for both the employer and
              the employee, and includes any special obligations undertaken that are unique in a specific hiring
              situation.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/18W-MMQ2bbDWbR_QvW7oMpAzPjB-Od5xVhmmoUznS6I4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/17UHwj6HP8wr9WlXK-Vrp0x_ZFnZeG_7MhbsgjmBpkbQ/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Mutual NDA
              </a>
            </td>
          </tr>
          <tr>
            <td>
              The employment agreement spells out the rules, rights and responsibilities for both the employer and
              the employee, and includes any special obligations undertaken that are unique in a specific hiring
              situation. (This is not legal advice. For informational purposes only).

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/17UHwj6HP8wr9WlXK-Vrp0x_ZFnZeG_7MhbsgjmBpkbQ/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

HiringTab.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default HiringTab;
