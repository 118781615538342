import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import MatTableHead from './MatTable/MatTableHead';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class Visitors extends PureComponent {
  static propTypes = {
    visitors: PropTypes.arrayOf(
      PropTypes.shape({
        NDASigned: PropTypes.bool,
        countDeck: PropTypes.number,
        countFinancials: PropTypes.number,
        countMaterials: PropTypes.number,
        email: PropTypes.string,
        id: PropTypes.number,
        lastVisited: PropTypes.string,
        time: PropTypes.number,
        timeDeck: PropTypes.number,
        visits: PropTypes.number,
        link: PropTypes.string,
      }),
    ),
  };

  static defaultProps = {
    visitors: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: new Map([]),
      page: 0,
      rowsPerPage: 10,
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { visitors } = this.props;
      const newSelected = new Map();
      visitors.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  getCountSentence = (count) => {
    if (count === 1) {
      return 'once';
    }
    if (count === 2) {
      return 'twice';
    }
    return `${count} times`;
  };

  render() {
    const {
      order, orderBy, selected, rowsPerPage, page,
    } = this.state;
    const { visitors } = this.props;

    if (visitors && visitors.length > 0) {
      return (
        <div>
          <div className="material-table__wrap Links__visitor-table">
            <Table className="material-table">
              <MatTableHead
                numSelected={[...selected].filter(el => el[1]).length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={visitors.length}
              />
              <TableBody>
                {visitors
                  .sort(getSorting(order, orderBy))
                  .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                  .map((v) => {
                    const lastVisited = moment(v.lastVisited);
                    return (
                      <TableRow
                        className="material-table__row"
                        onClick={event => this.handleClick(event, v.id)}
                        tabIndex={-1}
                        key={v.id}
                      >
                        <TableCell
                          className="material-table__cell material-table__cell-right Links__visitor-cell"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <AccountCircleIcon className="Links__visitor-icon d-none d-lg-block" />
                          <div>{v.email ? v.email : 'Visitor'}</div>
                          <span className="Links__visitor-link">{v.link}</span>
                          &nbsp;·&nbsp;
                          <span id={`time-${v.id}`}>
                            {lastVisited instanceof moment
                              ? (
                                moment(lastVisited).fromNow()
                              )
                              : (
                                lastVisited
                              )
                            }
                          </span>
                          <UncontrolledTooltip dir="left" placement="bottom" target={`time-${v.id}`}>
                            {lastVisited instanceof moment
                              ? (
                                lastVisited.format('MMM Do YYYY hh:mm A')
                              )
                              : (
                                lastVisited
                              )
                            }
                          </UncontrolledTooltip>
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{v.visits}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{moment.utc(moment.duration(v.time, 'seconds').as('milliseconds')).format('mm:ss')}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          id={`deck-${v.id}`}
                        >
                          {v.countDeck}
                          <small className="text-muted">
                            <br />
                            {moment.utc(moment.duration(v.timeDeck, 'seconds').as('milliseconds')).format('mm:ss')}
                          </small>
                          <UncontrolledTooltip dir="left" placement="bottom" target={`deck-${v.id}`}>
                            This visitor reviewed your deck {this.getCountSentence(v.countDeck)}.
                          </UncontrolledTooltip>
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          id={`financials-${v.id}`}
                        >{v.countFinancials}
                          <UncontrolledTooltip dir="left" placement="bottom" target={`financials-${v.id}`}>
                            This visitor reviewed your financials {this.getCountSentence(v.countFinancials)}.
                          </UncontrolledTooltip>
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          id={`materials-${v.id}`}
                        >{v.countMaterials}
                          <UncontrolledTooltip dir="left" placement="bottom" target={`materials-${v.id}`}>
                            This visitor reviewed your materials {this.getCountSentence(v.countMaterials)}.
                          </UncontrolledTooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            className="material-table__pagination"
            count={visitors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous' }}
            nextIconButtonProps={{ 'aria-label': 'Next' }}
            onChangePage={this.handleChangePage}
            rowsPerPageOptions={[rowsPerPage]}
            dir="ltr"
          />
        </div>
      );
    }

    return (
      <div className="text-center Links__visitors-empty">
        No Visitors Yet. Share this link with investors outside of Angels Partners and track their visits here.
      </div>
    );
  }
}

export default Visitors;
