import React, { PureComponent } from 'react';
import {
  Col, TabContent, TabPane, NavItem, NavLink, Nav, Row,
} from 'reactstrap';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import GettingStartedTab from './GettingStartedTab';
import BusinessPlanTab from './BusinessPlanTab';
import InvestorsTab from './InvestorsTab';
import MarketingTab from './MarketingTab';
import AdvisoryBoardTab from './AdvisoryBoardTab';
import LegalTab from './LegalTab';
import HiringTab from './HiringTab';
import PartnershipsTab from './PartnershipsTab';
import RaisingTab from './RaisingTab';
import Modal from '../../../shared/components/modals/Modal';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
import TinySummaries from './TinySummaries';

class ResourcesTabs extends PureComponent {
  static propTypes = {
    user: UserProps.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      modal: false,
    };
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  goToAnchor = (anchor) => {
    const loc = document.location.toString().split('#')[0];
    document.location = `${loc}#${anchor}`;
    return false;
  };

  checkIsCustomer(e) {
    const { user } = this.props;

    if (!user.isCustomer) {
      e.preventDefault();
      this.setState({ modal: true });
    }
  }

  render() {
    const { activeTab, modal } = this.state;
    const { user } = this.props;
    const width = window.innerWidth;

    return (
      <div>
        <Row>
          <Col xs={12} lg={{ offset: 3, size: 8 }} xl={{ offset: 2, size: 8 }}>
            <p className="mb-5 mt-5 text-muted">
              <strong>To make use of these resources</strong>, open the resource on google suite,
              and make a copy of the file into your own google drive.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {/* eslint-disable-next-line max-len */}
            <div className={`redesigned__tabs tabs ${width >= 992 ? 'tabs--vertical' : 'tabs--horizontal tabs--bordered-bottom'}`}>
              <div className="tabs__wrap">
                <Nav tabs className="d-none d-lg-inline-flex">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      Getting Started
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      Business Plan & Model
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      Investors Outreach
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => {
                        this.toggle('4');
                      }}
                    >
                      Raising Capital
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '5' })}
                      onClick={() => {
                        this.toggle('5');
                      }}
                    >
                      Marketing & PR
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '6' })}
                      onClick={() => {
                        this.toggle('6');
                      }}
                    >
                      Legal Documents
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '7' })}
                      onClick={() => {
                        this.toggle('7');
                      }}
                    >
                      Advisory Board
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '8' })}
                      onClick={() => {
                        this.toggle('8');
                      }}
                    >
                      Hiring Employees
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '9' })}
                      onClick={() => {
                        this.toggle('9');
                      }}
                    >
                      Partnerships & Competition
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '10' })}
                      onClick={() => {
                        this.toggle('10');
                      }}
                    >
                      <span className="new">NEW</span>
                      Tiny Summaries
                    </NavLink>
                  </NavItem>
                </Nav>
                <Nav tabs className="d-inline-flex d-inline-flex d-lg-none">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      Getting Started
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      Business Plan & Model
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      Investors Outreach
                    </NavLink>
                  </NavItem>
                </Nav>
                <Nav tabs className="d-inline-flex d-inline-flex d-lg-none">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => {
                        this.toggle('4');
                      }}
                    >
                      Raising Capital
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '5' })}
                      onClick={() => {
                        this.toggle('5');
                      }}
                    >
                      Marketing & PR
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '6' })}
                      onClick={() => {
                        this.toggle('6');
                      }}
                    >
                      Legal Documents
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '7' })}
                      onClick={() => {
                        this.toggle('7');
                      }}
                    >
                      Advisory Board
                    </NavLink>
                  </NavItem>
                </Nav>
                <Nav tabs className="d-inline-flex d-inline-flex d-lg-none">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '8' })}
                      onClick={() => {
                        this.toggle('8');
                      }}
                    >
                      Hiring Employees
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '9' })}
                      onClick={() => {
                        this.toggle('9');
                      }}
                    >
                      Partnerships & Competition
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '10' })}
                      onClick={() => {
                        this.toggle('10');
                      }}
                    >
                      <span className="new">NEW</span>
                      Tiny Summaries
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <GettingStartedTab isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                  <TabPane tabId="2">
                    <BusinessPlanTab isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                  <TabPane tabId="3">
                    <InvestorsTab isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                  <TabPane tabId="4">
                    <RaisingTab isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                  <TabPane tabId="5">
                    <MarketingTab isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                  <TabPane tabId="6">
                    <LegalTab isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                  <TabPane tabId="7">
                    <AdvisoryBoardTab isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                  <TabPane tabId="8">
                    <HiringTab isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                  <TabPane tabId="9">
                    <PartnershipsTab isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                  <TabPane tabId="10">
                    <TinySummaries isCustomer={user.isCustomer} checkIsCustomer={e => this.checkIsCustomer(e)} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </Col>
          <Modal
            color="primary"
            title="Upgrade Required!"
            colored
            displayed={modal}
            toggleModal={this.toggleModal}
            message={`Please upgrade your account to our paid plans if you wish to access this document.
             You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
             Pricing Page</a>.`}
          />
        </Row>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  user: state.user,
}))(ResourcesTabs));
