/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import { Badge, Table } from 'reactstrap';
import PropTypes from 'prop-types';

class InvoicesTab extends PureComponent {
  static propTypes = {
    invoices: PropTypes.shape({
      date: PropTypes.string,
      plan: PropTypes.string,
      price: PropTypes.string,
      invoice: PropTypes.string,
    }).isRequired,
    downloading: PropTypes.bool.isRequired,
  };

  render() {
    const { invoices, downloading } = this.props;

    return (
      <span>
        {invoices && invoices.length > 0
          && (
            <Table className="table--bordered" responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Plan</th>
                  <th className="text-right">Price</th>
                  <th className="al-center">Invoice</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((inv, key) => (
                  <tr id={key}>
                    <td>{inv.datetime}</td>
                    <td>{inv.plan}</td>
                    <td className="text-right">{inv.price}</td>
                    <td className="al-center">
                      {(inv.platform === 'Paypal' || !inv.invoice)
                        && (
                          <Badge color="warning">Unavailable</Badge>
                        )}
                      {inv.platform === 'Stripe' && inv.invoice
                        && (
                          <Badge color="success">
                            <a href={inv.invoice} className="color-white">
                              Download
                            </a>
                          </Badge>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        {invoices && invoices.length === 0 && !downloading
          && (
            <p>We could not find any invoices for your account.</p>
          )}
      </span>
    );
  }
}

export default InvoicesTab;
