import React from 'react';
import {
  Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';

const StatElements = ({ prospects }) => (
  <Row className="dashboard-steps elements-cards mb-5 mt-5">
    <Col xs={12} sm={6} lg={4} className="crm-steps">
      <div className="dashboard__step-slide rounded dashboard__step-slide--success default-cursor">
        <img
          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/sent_icon.svg`}
          alt="Sent Icon"
          className="crm-steps-icon"
        />
        <h2 className="dashboard__step-title">
          {prospects
            ? prospects.filter(prospect => !prospect.searched && !prospect.error).length
            : (
              <svg className="load__icon">
                <path fill="#fff" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            )}
        </h2>
        <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
        <h6 className="dashboard__step-subtitle margin-0">Investors left to process</h6>
        {prospects && prospects.filter(prospect => !prospect.searched && !prospect.error).length > 0
        && (
          <small>Please run the plugin to process them</small>
        )}
      </div>
    </Col>
    <Col xs={12} sm={6} lg={4} className="crm-steps">
      <div className="dashboard__step-slide rounded dashboard__step-slide--current default-cursor">
        <img
          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/emailstack_icon.svg`}
          alt="Sent Icon"
          className="crm-steps-icon"
        />
        <h2 className="dashboard__step-title">
          {prospects
            ? prospects.flatMap(prospect => prospect.investorConnections).length
            : (
              <svg className="load__icon">
                <path fill="#fff" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            )}
        </h2>
        <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
        <h6 className="dashboard__step-subtitle margin-0">
          Introduction path discovered to&nbsp;
          {prospects && prospects.filter(prospect => prospect.investorConnections.length > 0).length}
          &nbsp;investors
        </h6>
      </div>
    </Col>
    <Col xs={12} sm={12} lg={4} className="crm-steps mt-4 mt-lg-0">
      <div className="dashboard__step-slide rounded dashboard__step-slide--next default-cursor">
        <img
          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/pings_icon.svg`}
          alt="Sent Icon"
          className="crm-steps-icon"
        />
        <h2 className="dashboard__step-title">
          {prospects
            ? prospects.filter(prospect => (prospect.searched && prospect.investorConnections.length > 0
              && prospect.contacted && prospect.investorConnections.some(connection => connection.selected))).length
            : (
              <svg className="load__icon">
                <path fill="#fff" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            )}
        </h2>
        <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="line" alt="line" />
        <h6 className="dashboard__step-subtitle">Introduction requests sent<br />
          {prospects
            ? `over ${prospects.filter(prospect => (prospect.searched && prospect.investorConnections.length > 0
              && prospect.investorConnections.some(connection => connection.selected))).length} requests in total`
            : (
              <svg className="load__icon">
                <path fill="#fff" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            )}
        </h6>
      </div>
    </Col>
    {/* <div>
      {prospects && prospects.filter(prospect => !prospect.searched).length > 0
      && (
        <Alert color="error" className="alert--colored" icon>
          <div>
            There has been an issue retrieving the list of countries. Please try again or contact support.
          </div>
        </Alert>
      )}
    </div> */}
  </Row>
);


StatElements.propTypes = {
  prospects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    investor: PropTypes.shape({
      id: PropTypes.number,
      contactInformation: PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        picture: PropTypes.shape({
          id: PropTypes.number,
          pictureName: PropTypes.string,
        }),
      }),
    }),
    investorConnections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      error: PropTypes.bool,
      linkedinUrl: PropTypes.string,
      selected: PropTypes.bool,
      name: PropTypes.string,
      contacted: PropTypes.bool,
      picture: PropTypes.shape({
        id: PropTypes.number,
        linkedinIntroPictureName: PropTypes.string,
      }),
    })),
    searched: PropTypes.bool,
    introduced: PropTypes.bool,
  })).isRequired,
};


export default StatElements;
