import React from 'react';
import {
  Button, Col, Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const defaultLogo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/default-logo.gif`;

const ProfileSidebar = ({
  id, name, logo, locationCity, locationCountry, isCustomer, dateTrialEnd, dateTrialCancelled,
  pings, pingsUsed, account, datePlanEnd, willRenew, founder, cancelSubscriptionToggle,
}) => (
  <Col md={12}>
    <div className="row settings__card">
      <div className="text-center col-12 col-md-6 col-xl-12">
        <img
          src={logo && logo !== ''
            ? process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_LOGOS_PATH + logo : defaultLogo}
          alt="avatar"
        />
        <h4>{ name }</h4>
        <p className="card__location">
          <MapMarkerIcon />
          { locationCity } | { locationCountry }
        </p>
      </div>
      <div className="col-12 col-md-6 col-xl-12">
        <Table responsive>
          <tbody>
            <tr>
              <td>Pings Left</td>
              <td>{ pings }</td>
            </tr>
            <tr>
              <td>Pings Used</td>
              <td>{ pingsUsed }</td>
            </tr>
            <tr>
              <td>Subscription</td>
              <td>{ account }</td>
            </tr>
            {datePlanEnd
            && (
              <tr>
                <td>{willRenew ? 'Plan Renews On' : 'Plan Ends On' }</td>
                <td>{ datePlanEnd }</td>
              </tr>
            )}
            {dateTrialCancelled && !datePlanEnd
            && (
              <tr>
                <td>Trial Cancelled</td>
                <td>&nbsp;</td>
              </tr>
            )}
            {dateTrialEnd && !datePlanEnd && !dateTrialCancelled
            && (
              <tr>
                <td>Trial Ends On</td>
                <td>{ dateTrialEnd }</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="settings__actions ml-auto mr-auto mt-xl-5 mt-5">
        <div className="text-center">
          {((isCustomer && willRenew) || (isCustomer && !willRenew && !dateTrialCancelled))
            && cancelSubscriptionToggle
          && (
            <button
              type="button"
              className="text-danger border-0 p-3 bg-transparent"
              onClick={cancelSubscriptionToggle}
            >
              <small>CANCEL SUBSCRIPTION</small>
            </button>
          )}
          {!isCustomer
            && (
              <Button
                color="purple"
                className="rounded icon icon--right text-white m-auto"
                onClick={() => window.open(`${process.env.REACT_APP_DOMAIN_URL}/pricing`, '_blank')}
              >
                Upgrade Now
                <ChevronRightIcon />
              </Button>
            )}
        </div>
      </div>
      {id && !founder
        ? (
          <div className="mb-5 mb-xl-0 mt-5 mt-xl-4 profile__review-block">
            <div className="profile__review-tile">
              <Link to="/startup/myCompany">
                <Button className="rounded icon icon--right icon--blue m-auto" color="primary" size="lg">
                  Review Startup Profile
                  <ChevronRightIcon />
                </Button>
              </Link>
            </div>
            <div className="text-center center-block mt-3">
              <h6>This is how investors will see your profile.</h6>
              <small className="text-muted">
                Do NOT share this page with investors, instead, use a <Link to="/links">tracking link</Link>
              </small>
            </div>
          </div>
        ) : (
          <div className="mb-5 mb-xl-0 mt-5 mt-xl-4 profile__review-block">
            <div className="profile__review-tile">
              <Link to="/founder/myProfile">
                <Button className="rounded icon icon--right icon--blue m-auto" color="primary" size="lg">
                  Review Founder Profile
                  <ChevronRightIcon />
                </Button>
              </Link>
            </div>
            <div className="text-center center-block mt-3">
              <h6>This is how other founders will see your profile.</h6>
            </div>
          </div>
        )}
    </div>
  </Col>
);

export default ProfileSidebar;

ProfileSidebar.defaultProps = {
  id: null,
  name: '',
  logo: '',
  locationCity: '',
  locationCountry: '',
  pings: 0,
  pingsUsed: 0,
  willRenew: 0,
  account: '',
  datePlanEnd: null,
  dateTrialEnd: null,
  dateTrialCancelled: null,
  founder: false,
  cancelSubscriptionToggle: null,
};

ProfileSidebar.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  logo: PropTypes.string,
  locationCity: PropTypes.string,
  locationCountry: PropTypes.string,
  pings: PropTypes.number,
  pingsUsed: PropTypes.number,
  account: PropTypes.string,
  datePlanEnd: PropTypes.string,
  dateTrialEnd: PropTypes.string,
  dateTrialCancelled: PropTypes.string,
  willRenew: PropTypes.bool,
  isCustomer: PropTypes.bool.isRequired,
  founder: PropTypes.bool,
  cancelSubscriptionToggle: PropTypes.func,
};
