/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button, Col, Row, Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CardTitle from 'reactstrap/lib/CardTitle';
import PlusIcon from 'mdi-react/PlusIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Alert from '../../../shared/components/alerts/Alert';


function InvestorsTab(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <CardTitle>
        <Row>
          <Col xs={12} xl={8}>
            <h3>Use these documents to reach investors!</h3>
            <p className="text-muted mt-4 mb-2">
              Raising funds is not only a challenging time for founders, but it is incredibly time consuming and,
              in general, highly inefficient. Securing capital in the traditional sense is binary: either you spend
              a considerable amount of time traveling and pitching to a potential investor and secure a deal,
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              &nbsp;or you don't, and it's all been for nothing. With this in mind, we gathered a number of documents
              to facilitate your interactions with investors.
            </p>
          </Col>
        </Row>
        <Alert color="purple" className="alert--colored mt-5">
          <div>
            <p>
              <span className="bold-text">Want to learn more? </span>
              Finding business angels can be made easy by using platforms, such as LinkedIn or Angels Partners.
              <br />
              Learn how to make the best use of it:
              <br />
              <br />
              <a
                className="text-white"
                href="https://angelspartners.com/blog/how-to-raise-funds-for-your-next-start-up"
              >
                <strong>How to raise funds for your next start-up <ChevronRightIcon /></strong>
              </a>
              {/* eslint-disable-next-line max-len */}
              <a className="text-white" href="https://angelspartners.com/blog/taking-fundraising-into-the-digital-era-7-reasons-to-raise-capital-online" onClick={checkIsCustomer}>
                <strong>
                  Taking fundraising into the digital era: 7 reasons to raise capital online <ChevronRightIcon />
                </strong>
              </a>
            </p>
          </div>
        </Alert>
      </CardTitle>
      <Table responsive hover>
        <tbody>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/presentation/d/12D-oJwzhz8P9E1S070QqsPYsNhfnK9R5vZAearFoVCY/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Board Meeting Deck from IA Ventures
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A board meeting is a meeting of a company&#39;s board of directors, held usually at certain times of
              the year, to discuss company-wide policies or issues. Here is a template highlighting the key elements
              required to execute an effective board meeting.
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/presentation/d/12D-oJwzhz8P9E1S070QqsPYsNhfnK9R5vZAearFoVCY/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button className="btn rounded icon btn-slides mt-4">
                  Open Slides
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1E1Q2qWpdAwUUi2LZkxrxW5995ye6fdaNpZfHwVzvpvE/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Investor Request Email/Ping Template
              </a>
            </td>
          </tr>
          <tr>
            <td>
              Highly efficient investor email templates, to increase your conversion rate.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1E1Q2qWpdAwUUi2LZkxrxW5995ye6fdaNpZfHwVzvpvE/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1sXv77BV2uDpCTaHW-Pb32HtOgkMQxvgEeOyYH8qG1Vs/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                SaaS MRR reporting template from Bessemer
              </a>
            </td>
          </tr>
          <tr>
            <td>
              Monthly Recurring Revenue (MRR) is all of your recurring revenue normalized into a monthly amount.
              It&#39;s a metric usually used among subscription and SaaS companies. Here is a template guiding you
              through a simple MRR calculation.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1sXv77BV2uDpCTaHW-Pb32HtOgkMQxvgEeOyYH8qG1Vs/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="success" className="btn rounded icon mt-4">
                  Open Spreadsheet
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1TKHg7yJWGabLyc7GNilFbqVyRnjQ61SYQyrj1ojtbl4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Simple Board Minutes Template from Brad Feld of Foundry Group
              </a>
            </td>
          </tr>
          <tr>
            <td>
              Board meeting minutes are more than a general accounting of board discussions; they serve as an
              official and legal record of the meeting of the Board of Directors. Minutes are used in a variety of
              ways including tracking progress, detailing future plans, and serving as a reference point.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1TKHg7yJWGabLyc7GNilFbqVyRnjQ61SYQyrj1ojtbl4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

InvestorsTab.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default InvestorsTab;
