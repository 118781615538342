import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import FounderCard from './FounderCard';


class FoundersPingsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      founders: [],
    };
  }

  componentDidMount = () => {
    this.getFounders();
  };

  componentDidUpdate(prevProps) {
    const { stateOfList } = this.props;
    if (stateOfList !== prevProps.stateOfList) {
      this.updateListOfFounder(
        stateOfList.id,
        stateOfList.accepted,
        stateOfList.pinging,
        stateOfList.declining,
        stateOfList.declined,
      );
    }
  }

  getFounders = () => {
    const { founders } = this.state;

    if (founders.length === 0) {
      axios.get('/founders/pinged')
        .then((response) => {
          const newFounders = response.data.map(founder => ({
            ...founder, pinging: false, declining: false,
          }));
          this.setState({ founders: newFounders });
        }).catch(() => {
          this.setState({ founders: null });
        });
    }
  };

  updateListOfFounder = (id, accepted, pinging, declining, declined) => {
    // Set up Loading
    const { state } = this;
    const founders = Object.values({ ...state.founders });

    if (state.founders === null) {
      return null;
    }

    // find the index of this founder
    const objIndex = founders.findIndex(i => i.id === id);

    if (objIndex >= 0) {
      // Sort out Pinged
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      if (accepted === null) accepted = founders[objIndex].accepted;

      // Update Founder
      const updatedFounder = {
        ...founders[objIndex], accepted, pinging, declining, declined,
      };

      // Make new list of founders
      const updatedFoundersList = [
        ...founders.slice(0, objIndex),
        updatedFounder,
        ...founders.slice(objIndex + 1),
      ];

      // Update the State
      this.setState({ founders: updatedFoundersList });

      return objIndex;
    }
    return false;
  };

  render() {
    const state1 = this.state;
    const {
      connectFounder, declineFounder,
    } = this.props;
    let founders = null;

    if (state1.founders) {
      founders = state1.founders.map(
        founder => (
          <FounderCard
            founder={founder}
            connect={() => connectFounder(founder.id)}
            decline={() => declineFounder(founder.id)}
            key={founder.id}
          />
        ),
      );
    }

    return (
      <Row>
        { founders !== null && founders.length > 0
          && (
            <Col md={12}>
              <Card id="pinged">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">
                      {founders.length === 1 ? 'This founder has' : 'These founders have'} sent you a ping.
                    </h5>
                    <h5 className="subhead">
                      After carefully reviewing your profile,
                      they have decided you could be a good fit and are trying to connect with you.<br />
                      Please answer {founders.length === 1 ? 'its' : 'their'}
                      requests by accepting of declining their ping.<br />
                    </h5>
                  </div>
                  <Col md={12} lg={12}>
                    <div className="investor-items__wrap">
                      <div className="investor-items founders-pinged">
                        {founders}
                      </div>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          )}
      </Row>
    );
  }
}
FoundersPingsList.propTypes = {
  connectFounder: PropTypes.func.isRequired,
  declineFounder: PropTypes.func.isRequired,
  stateOfList: PropTypes.shape({
    id: PropTypes.number,
    accepted: PropTypes.bool,
    pinging: PropTypes.bool,
    declining: PropTypes.bool,
    declined: PropTypes.bool,
  }).isRequired,
};

export default withRouter(FoundersPingsList);
