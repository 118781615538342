import React from 'react';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Alert from '../../../../shared/components/alerts/Alert';
import renderDropZoneField from '../../../../shared/components/form/DropZone';

const FileUploadDeck = ({
  handleSubmit, reset, deck, deckTimestamp, openDeck, removeDeck,
}) => (
  <div>
    <Alert color="info" className="mb-5 alert--colored">
      <div>
        <span className="bold-text">Need Help with this section? </span>
        Refer to our resources to:
        <ul>
          <li>
            <a
              href="https://angelspartners.com/blog/creating-your-business-plan-the-essentials"
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn how to build a great business plan
            </a>
          </li>
        </ul>
      </div>
    </Alert>
    {deck && (
      <div>
        <div
          role="button"
          tabIndex={0}
          className="review-deck"
          onClick={openDeck}
          onKeyDown={openDeck}
        >
          <img
            src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/deck.png`}
            className="pic-deck-review"
            alt="Download Deck Logo"
          />
          <div className="text-deck-review">
            Review Deck
            <br />
            <span>Last updated: {deckTimestamp || 'Just Now'}</span>
          </div>
        </div>
        <Button
          onClick={removeDeck}
          className="float-right"
          color="danger"
          outline
          type="submit"
          size="sm"
        >
          Remove Deck
        </Button>
        <br /><br />
      </div>
    )}
    <div>
      <div className="card__title">
        <h5 className="bold-text">Update Your Deck</h5>
        <h5 className="subhead">
          Keep your deck up to date and show investors you are making progress.
        </h5>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <Field
          name="files"
          component={renderDropZoneField}
          customHeight
        />
        <ButtonToolbar className="form__button-toolbar">
          <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
            Upload Deck
            <ChevronRightIcon />
          </Button>
          <Button className="rounded mt-5" type="button" onClick={reset}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  </div>
);

FileUploadDeck.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  deck: PropTypes.string,
  deckTimestamp: PropTypes.string,
  openDeck: PropTypes.func.isRequired,
  removeDeck: PropTypes.func.isRequired,
};

FileUploadDeck.defaultProps = {
  deck: null,
  deckTimestamp: null,
};

export default reduxForm({
  form: 'file_upload_deck', // a unique identifier for this form
})(FileUploadDeck);
