function getIndustryFilters(filter) {
  if (filter === 1) {
    return 'Software & Web (Marketplaces, Saas..)';
  } if (filter === 2) {
    return 'PropTech';
  } if (filter === 3) {
    return 'Other';
  } if (filter === 4) {
    return 'IT & TMT';
  } if (filter === 5) {
    return 'Hospitality & Events';
  } if (filter === 6) {
    return 'Media';
  } if (filter === 7) {
    return 'Education';
  } if (filter === 8) {
    return 'BioTech';
  } if (filter === 9) {
    return 'AgroTech';
  } if (filter === 10) {
    return 'Businesses Solutions';
  } if (filter === 11) {
    return 'FinTech & Financials services';
  } if (filter === 12) {
    return 'Hardware & Manufacturing';
  } if (filter === 13) {
    return 'Energy';
  } if (filter === 14) {
    return 'Retail & E-Commerce';
  } if (filter === 15) {
    return 'Sales & Marketing';
  } if (filter === 16) {
    return 'Cosmetics & Fashion';
  } if (filter === 17) {
    return 'Entertainment & Sports';
  } if (filter === 18) {
    return 'IoT & Wearables';
  } if (filter === 19) {
    return 'CleanTech';
  } if (filter === 20) {
    return 'A.I. & Big Data';
  } if (filter === 21) {
    return 'HealthTech & Fitness';
  } if (filter === 22) {
    return 'Cannabis';
  } if (filter === 23) {
    return 'Blockchain & Cryptos';
  } if (filter === 24) {
    return 'Web Security';
  } if (filter === 25) {
    return 'Gaming & eSports';
  } if (filter === 26) {
    return 'Advertising';
  } if (filter === 27) {
    return 'Analytics';
  } if (filter === 28) {
    return 'AR/VR';
  } if (filter === 29) {
    return 'Cars & autonomous vehicles';
  } if (filter === 30) {
    return 'Cloud services & infrastructure';
  } if (filter === 31) {
    return 'Developer tools';
  } if (filter === 32) {
    return 'Future of Work';
  } if (filter === 33) {
    return 'Food & Beverage';
  } if (filter === 34) {
    return 'GovTech';
  } if (filter === 35) {
    return 'Human capital';
  } if (filter === 36) {
    return 'Impact';
  } if (filter === 37) {
    return 'Insurance & InsurTech';
  } if (filter === 38) {
    return 'Local commerce';
  } if (filter === 39) {
    return 'Logistics & Distribution';
  } if (filter === 40) {
    return 'Material science';
  } if (filter === 41) {
    return 'Hospital Services & Medical Devices';
  } if (filter === 42) {
    return 'Messaging';
  } if (filter === 43) {
    return 'Payments';
  } if (filter === 44) {
    return 'Robotics';
  } if (filter === 45) {
    return 'Mobile Apps';
  } if (filter === 46) {
    return 'Aerospace & Defense';
  } if (filter === 47) {
    return 'Real Estate & Construction';
  } if (filter === 48) {
    return 'SaaS';
  } if (filter === 49) {
    return 'DeepTech';
  } if (filter === 50) {
    return 'FoodTech';
  } if (filter === 51) {
    return 'Healthcare & Wellness';
  } if (filter === 52) {
    return 'Incubators';
  } if (filter === 53) {
    return 'Music';
  } if (filter === 54) {
    return 'Investment Management';
  } if (filter === 55) {
    return 'Legal Tech';
  } if (filter === 56) {
    return 'Life Science';
  } if (filter === 57) {
    return 'Mobile';
  } if (filter === 58) {
    return 'Mobility';
  } if (filter === 59) {
    return 'Nanotechnology';
  } if (filter === 60) {
    return 'Nonprofits';
  } if (filter === 61) {
    return 'Oncology';
  } if (filter === 62) {
    return 'Pets';
  } if (filter === 63) {
    return 'Online Social';
  } if (filter === 64) {
    return 'Consumer';
  } if (filter === 65) {
    return 'Pharmaceutical & Medicine';
  } if (filter === 66) {
    return 'Restaurants';
  } if (filter === 68) {
    return 'Oil, Gas & Mining';
  } if (filter === 69) {
    return 'Woman Focused';
  } if (filter === 70) {
    return 'Industrials';
  } if (filter === 71) {
    return 'Film Production & Distribution';
  } if (filter === 72) {
    return 'Semiconductors';
  } if (filter === 73) {
    return '3D Printing';
  }

  return 'Industry';
}

export { getIndustryFilters };

const filtersArray = [
  { value: 73, label: getIndustryFilters(73) },
  { value: 20, label: getIndustryFilters(20) },
  { value: 26, label: getIndustryFilters(26) },
  { value: 46, label: getIndustryFilters(46) },
  { value: 9, label: getIndustryFilters(9) },
  { value: 27, label: getIndustryFilters(27) },
  { value: 28, label: getIndustryFilters(28) },
  { value: 29, label: getIndustryFilters(29) },
  { value: 8, label: getIndustryFilters(8) },
  { value: 23, label: getIndustryFilters(23) },
  { value: 10, label: getIndustryFilters(10) },
  { value: 22, label: getIndustryFilters(22) },
  { value: 19, label: getIndustryFilters(19) },
  { value: 30, label: getIndustryFilters(30) },
  { value: 64, label: getIndustryFilters(64) },
  { value: 16, label: getIndustryFilters(16) },
  { value: 49, label: getIndustryFilters(49) },
  { value: 31, label: getIndustryFilters(31) },
  { value: 7, label: getIndustryFilters(7) },
  { value: 13, label: getIndustryFilters(13) },
  { value: 17, label: getIndustryFilters(17) },
  { value: 71, label: getIndustryFilters(71) },
  { value: 11, label: getIndustryFilters(11) },
  { value: 33, label: getIndustryFilters(33) },
  { value: 50, label: getIndustryFilters(50) },
  { value: 32, label: getIndustryFilters(32) },
  { value: 25, label: getIndustryFilters(25) },
  { value: 34, label: getIndustryFilters(34) },
  { value: 12, label: getIndustryFilters(12) },
  { value: 51, label: getIndustryFilters(51) },
  { value: 21, label: getIndustryFilters(21) },
  { value: 5, label: getIndustryFilters(5) },
  { value: 35, label: getIndustryFilters(35) },
  { value: 36, label: getIndustryFilters(36) },
  { value: 52, label: getIndustryFilters(52) },
  { value: 70, label: getIndustryFilters(70) },
  { value: 37, label: getIndustryFilters(37) },
  { value: 54, label: getIndustryFilters(54) },
  { value: 18, label: getIndustryFilters(18) },
  { value: 4, label: getIndustryFilters(4) },
  { value: 55, label: getIndustryFilters(55) },
  { value: 56, label: getIndustryFilters(56) },
  { value: 38, label: getIndustryFilters(38) },
  { value: 39, label: getIndustryFilters(39) },
  { value: 40, label: getIndustryFilters(40) },
  { value: 6, label: getIndustryFilters(6) },
  { value: 41, label: getIndustryFilters(41) },
  { value: 42, label: getIndustryFilters(42) },
  { value: 57, label: getIndustryFilters(57) },
  { value: 45, label: getIndustryFilters(45) },
  { value: 58, label: getIndustryFilters(58) },
  { value: 53, label: getIndustryFilters(53) },
  { value: 59, label: getIndustryFilters(59) },
  { value: 60, label: getIndustryFilters(60) },
  { value: 68, label: getIndustryFilters(68) },
  { value: 61, label: getIndustryFilters(61) },
  { value: 67, label: getIndustryFilters(67) },
  { value: 63, label: getIndustryFilters(63) },
  { value: 3, label: getIndustryFilters(3) },
  { value: 43, label: getIndustryFilters(43) },
  { value: 62, label: getIndustryFilters(62) },
  { value: 65, label: getIndustryFilters(65) },
  { value: 2, label: getIndustryFilters(2) },
  { value: 47, label: getIndustryFilters(47) },
  { value: 66, label: getIndustryFilters(66) },
  { value: 14, label: getIndustryFilters(14) },
  { value: 44, label: getIndustryFilters(44) },
  // { value: 48, label: getIndustryFilters(48) },
  { value: 15, label: getIndustryFilters(15) },
  { value: 72, label: getIndustryFilters(72) },
  { value: 1, label: getIndustryFilters(1) },
  { value: 24, label: getIndustryFilters(24) },
  { value: 69, label: getIndustryFilters(69) },
];

export { filtersArray };
