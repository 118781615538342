import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../alerts/Alert';

const ModalCampaignErrors = ({
  editCampaign,
  investorContactInfo,
  campaign,
}) => (
  <div>
    {editCampaign ? (
      <p className="text-muted">
        You can review these emails before they are going to be updated with your latest edits.
      </p>
    ) : (
      <p className="text-muted">
        You can edit these emails here before they are scheduled and sent.
      </p>
    )}
    {investorContactInfo.inCampaign && investorContactInfo.contacted && investorContactInfo.campaignId === campaign.id
      && !editCampaign
    && (
      <Alert color="danger" className="alert--colored w-100 mt-3">
        <p className="text-left">
          <span className="bold-text">
            This investor has already been contacted!
          </span>
          <br />
          You have already contacted this investor with this campaign
          {investorContactInfo.contactedOn && ` on ${investorContactInfo.contactedOn}`}
        </p>
      </Alert>
    )
    }
    {investorContactInfo.inCampaign && !investorContactInfo.contacted && investorContactInfo.campaignId === campaign.id
      && !editCampaign
    && (
      <Alert color="danger" className="alert--colored w-100 mt-3">
        <p className="text-left">
          <span className="bold-text">
            This investor is already scheduled to be contacted with this campaign
            {investorContactInfo.contactedOn && ` on ${investorContactInfo.contactedOn}`}.
          </span>
          <br />
          Our system prevents you from contacting investors twice with the same campaign, in order to avoid
          duplicates.
        </p>
      </Alert>
    )
    }
    {investorContactInfo.inCampaign && investorContactInfo.contacted && investorContactInfo.campaignId !== campaign.id
      && !editCampaign
    && (
      <Alert color="warning" className="alert--colored w-100 mt-3">
        <p className="text-left">
          <span className="bold-text">
            This investor has already been contacted!
          </span>
          <br />
          You have already contacted this investor with the campaign&nbsp;
          <b>
            {investorContactInfo.campaignName}
          </b>.
        </p>
      </Alert>
    )
    }
    {investorContactInfo.inCampaign && !investorContactInfo.contacted && investorContactInfo.campaignId !== campaign.id
      && !editCampaign
    && (
      <Alert color="warning" className="alert--colored w-100 mt-3">
        <p className="text-left">
          <span className="bold-text">
            This investor is already scheduled to be contacted with another campaign!
          </span>
          <br />
          This investor will be contacted with the campaign&nbsp;
          <b>
            {investorContactInfo.campaignName}
          </b>
          {investorContactInfo.contactedOn && ` on ${investorContactInfo.contactedOn}`}.
        </p>
      </Alert>
    )
    }
    {campaign.error
    && (
      <Alert color="danger" className="alert--colored w-100 mt-3">
        <p className="text-left">
          <span className="bold-text">Careful!</span>&nbsp;
          {campaign.error}
        </p>
      </Alert>
    )}
    <br /><br />
  </div>
);

ModalCampaignErrors.propTypes = {
  editCampaign: PropTypes.number.isRequired,
  investorContactInfo: PropTypes.shape({
    contactId: PropTypes.number,
    fullName: PropTypes.string,
    investorId: PropTypes.number,
    email: PropTypes.bool,
    inCampaign: PropTypes.bool,
    campaignName: PropTypes.string,
    contacted: PropTypes.bool,
    contactedOn: PropTypes.string,
  }).isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    templates: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      position: PropTypes.number,
      delay: PropTypes.number,
      content: PropTypes.string,
    })),
  }).isRequired,
};

export default ModalCampaignErrors;
