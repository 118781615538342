/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import MagnifyPlusIcon from 'mdi-react/MagnifyPlusIcon';

class BlacklistTab extends PureComponent {
  static propTypes = {
    blacklist: PropTypes.shape({
      datetime: PropTypes.string,
      filename: PropTypes.string,
      number: PropTypes.string,
      invoice: PropTypes.string,
    }).isRequired,
    downloading: PropTypes.bool.isRequired,
    removeBlacklist: PropTypes.func.isRequired,
    detailBlacklist: PropTypes.func.isRequired,
  };

  render() {
    const {
      blacklist, downloading, removeBlacklist, detailBlacklist,
    } = this.props;

    return (
      <span>
        <Link to="/import">
          <Button
            className="d-block mr-auto ml-auto rounded"
            outline
            color="primary"
          >
            Import Investors
          </Button>
        </Link>
        {blacklist && blacklist.length > 0 && !downloading
          && (
            <Table className="table--bordered" responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th className="table-filename">Filename</th>
                  <th>Blacklisted</th>
                  <th className="al-center">Details</th>
                  <th className="al-center">Remove</th>
                </tr>
              </thead>
              <tbody>
                {blacklist.map((list, key) => (
                  <tr id={key} key={list.filename}>
                    <td>{list.datetime}</td>
                    <td className="table-filename">{list.filename.substring(14)}</td>
                    <td>{list.number > 1 ? `${list.number} investors` : `${list.number} investor` }</td>
                    <td className="al-center">
                      <button
                        type="button"
                        className="btn-details-blacklist"
                        onClick={() => detailBlacklist(list.filename)}
                      >
                        <MagnifyPlusIcon size={24} />
                      </button>
                    </td>
                    <td className="al-center">
                      <button
                        type="button"
                        className="btn-remove-blacklist"
                        onClick={() => removeBlacklist(list.filename)}
                      >
                        <DeleteForeverIcon size={24} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        {blacklist && blacklist.length === 0 && !downloading
          && (
            <p className="al-center">We could not find any blacklisted investor for your account.</p>
          )}
      </span>
    );
  }
}

export default BlacklistTab;
