import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from 'reactstrap';
import axios from 'axios';
import SendIcon from 'mdi-react/SendIcon';
import ChatIcon from 'mdi-react/CommentMultipleOutlineIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import queryString from 'query-string';
import NotificationSystem from 'rc-notification';
import PageVisibility from 'react-page-visibility';

import DeclinedIcon from 'mdi-react/CloseCircleIcon';
import StartupMain from '../../shared/components/startup/StartupMain';
import StartupTabs from '../../shared/components/startup/StartupTabs';
import { FullWideNotification } from '../../shared/components/notifications/Notification';
import Alert from '../../shared/components/alerts/Alert';
import ModalInput from '../../shared/components/modals/ModalInput';

let notificationTC = null;

const showNotification = ({ notification, position }, rtl) => {
  notificationTC.notice({
    content: notification,
    duration: 5,
    closable: true,
    style: { top: 0, left: 0 },
    className: `${position} ${rtl}-support`,
  });
};

class StartupProfile extends PureComponent {
  state = {
    startup: {
      is_ping: false,
    },
    loading: true,
    loaded: false,
    focus: true,
    userId: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).id : 0,
    id: null,
    startupId: null,
    modalPing: false,
    declining: false,
    declined: false,
    similarStartups: [],
    unauthorized: '',
  };

  timer = null;

  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    const { props } = this;
    const { userId } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));
    if (props.match.url === '/startup/myCompany') {
      if (user) {
        this.init(user.id);
      } else {
        this.init(userId);
      }
    } else {
      this.init(props.match.params.id);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { state } = this;
    const prevStateId = parseInt(state.startup.id, 10);
    // eslint-disable-next-line react/prop-types
    const nextPropsId = parseInt(nextProps.match.params.id, 10);
    if (!Number.isNaN(prevStateId)
      && !Number.isNaN(nextPropsId)
      && nextPropsId !== prevStateId) {
      this.init(nextPropsId);
    }
    return null;
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    clearInterval(this.timer);
  }

  init = (id) => {
    axios.get(`startup/profile/${id}`)
      .then((response) => {
        this.setState({
          userId: id,
          startup: response.data,
          loading: false,
          unauthorized: '',
        });
        setTimeout(() => this.setState({ loaded: true }), 500);
        this.sendNotification('viewed');
        this.trackTime();

        // Get Similar Startups
        axios.get(`startup/similarStartups/${id}`)
          .then((res) => {
            this.setState({ similarStartups: res.data });
          });
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          if (error.response.status === 401) {
            this.setState({ unauthorized: error.response.data.message, loading: false });
            setTimeout(() => this.setState({ loaded: true }), 500);
          }
        }
      });
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    this.setState({ id: parseInt(id, 10) });
  };

  sendNotification = (type) => {
    const { props } = this;
    const { userId } = this.state;
    if (props.match.url === '/startup/myCompany') {
      axios.get(`startup/notifications/${type}/${userId}`)
        .catch(() => {});
    } else {
      axios.get(`startup/notifications/${type}/${props.match.params.id}`)
        .catch(() => {});
    }
  };

  trackTime = () => {
    const { props } = this;
    this.timer = setInterval(() => {
      const { focus } = this.state;
      if (focus && window.location.href.indexOf('localhost') === -1 && props.match.url !== '/startup/myCompany') {
        axios.get(`startup/profile/track/time/${props.match.params.id}`)
          .catch(() => {});
      }
    }, 3000);
  };

  connectStartup = () => {
    const { startup } = this.state;
    // eslint-disable-next-line no-unused-vars
    const { history } = this.props;
    const { id } = startup;

    // Set up Loading
    this.updateStartup(id, false, null, false, true);

    // Post the Ping.
    axios.post('/startups/connect', queryString.stringify({ id }))
      .then((response) => {
        if (response.status === 200) {
          this.updateStartup(id, false, null, true, false);
          history.push(response.data ? `/inbox/${response.data}` : '/inbox');
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateStartup(id, false, null, false, false);
      });
  };

  updateStartup = (id, adding, favorite, accepted, pinging) => {
    // Set up Loading
    const { startup } = this.state;

    // Sort out Favorite and Pinged
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (favorite === null) favorite = startup.favorite;

    // Sort out Favorite and Pinged
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (accepted === null) accepted = startup.accepted;

    // Update Startup
    const updatedStartup = {
      ...startup, adding, favorite, accepted, pinging,
    };

    // Update the State
    this.setState({ startup: updatedStartup });

    return true;
  };

  addFavorites = (e) => {
    if (e) { e.preventDefault(); }
    const { startup } = this.state;
    const { id } = startup;

    // Set up Loading
    this.updateStartup(id, true, false, null, false);

    // Post the Ping.
    axios.post('/startups/favorite/add', queryString.stringify({ id }))
      .then((response) => {
        if (response) {
          this.updateStartup(id, false, true, null, false);
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateStartup(id, false, false, null, false);
      });
  };

  removeFavorites = (e) => {
    if (e) { e.preventDefault(); }
    const { startup } = this.state;
    const { id } = startup;

    // Set up Loading
    this.updateStartup(id, true, true, null, false);

    // Post the Ping.
    axios.post('/startups/favorite/remove', queryString.stringify({ id }))
      .then((response) => {
        if (response) {
          this.updateStartup(id, false, false, null, false);
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateStartup(id, false, true, null, false);
      });
  };

  handleVisibilityChange = (isVisible) => {
    this.setState({ focus: isVisible });
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  goBack = () => {
    // eslint-disable-next-line react/prop-types
    const { history } = this.props;
    history.goBack();
  };

  renderConnectButton = () => {
    const { startup } = this.state;
    if (startup.accepted) {
      return (
        <Link to={startup.conversation_id ? `/inbox/${startup.conversation_id}` : '/inbox'}>
          <Button className="icon icon--right rounded icon btn btn-primary connect" color="primary">
            <p>Go To Conversation <ChatIcon /></p>
          </Button>
        </Link>
      );
    } if (startup.pinging) {
      return (
        <Button className="icon icon--right rounded icon btn btn-primary connect" color="primary" outline>
          <p>Connecting <LoadingIcon className="mdi-icon-spinning" /></p>
        </Button>
      );
    }
    return (
      <Button
        className="icon icon--right rounded icon btn btn-primary connect"
        color="primary"
        onClick={() => this.connectStartup()}
      >
        <p>Connect <SendIcon /></p>
      </Button>
    );
  };

  toggleModalPing = (id) => {
    const { modalPing } = this.state;
    this.setState({ modalPing: !modalPing, startupId: id });
  };

  declineStartup = (message) => {
    const { startupId } = this.state;
    // Remove the modal
    // Set up Loading
    this.setState({
      declining: true,
      modalPing: false,
    }, () => {
      // Post the Ping
      axios.post('/startups/decline', queryString.stringify({ id: startupId, message }))
        .then((response) => {
          if (response && response.data) {
            this.setState({
              declining: false,
              declined: true,
            });
          } else {
            this.showNotif('We could not connect you with this startup at this time.'
              + 'Please try again later or contact support.');
          }
        })
        .catch((error) => {
          this.setState({
            declining: false,
          });
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, Please try again later or contact support.');
          }
        });
    });
  };

  renderDeclineButton = () => {
    const { startup, declining, declined } = this.state;
    if (startup.declined || declined) {
      return (
        <Button className="icon icon--right rounded" color="danger">
          <p>DECLINED <DeclinedIcon /></p>
        </Button>
      );
    } if (startup.declining || declining) {
      return (
        <Button className="icon icon--right rounded" color="danger" outline>
          <p>DECLINING <LoadingIcon className="mdi-icon-spinning" /></p>
        </Button>
      );
    }
    if (startup.is_ping && !startup.accepted && !startup.declined) {
      return (
        <Button
          className="icon icon--right rounded"
          color="danger"
          onMouseDown={() => this.toggleModalPing(startup.id)}
          // onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { decline(e); } }}
        >
          <p>Decline <DeclinedIcon /></p>
        </Button>
      );
    }
    return null;
  }

  render = () => {
    const {
      startup, loading, loaded, id, userId, modalPing, similarStartups, unauthorized,
    } = this.state;
    const { props } = this;

    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <Container>
          <div className="profile startup">
            <Row>
              <Col xs={12} md={1}>
                <span
                  role="presentation"
                  onMouseDown={e => this.goBack(e)}
                  onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { this.goBack(e); } }}
                  className="back-btn"
                >
                  <ChevronLeftIcon /> BACK
                </span>
              </Col>
              <Col xs={12} md={{ size: 10, offset: 1 }} xl={{ size: 11, offset: 0 }}>
                {id === userId
                && (
                  <Alert color="danger" className="alert--margin mt-5 mt-md-0 mb-5">
                    <p>
                      <span className="bold-text">Do not share this link with investors!</span>&nbsp;&nbsp;
                      Instead, use a <Link className="bold-text" to="/links">tracking link</Link>.
                      This page can only be seen by you.
                    </p>
                  </Alert>
                )}
                {unauthorized !== ''
                && (
                  <Alert color="danger" className="alert--margin mt-5 mt-md-0 mb-5">
                    <p>
                      <span className="bold-text">{unauthorized}</span>
                    </p>
                  </Alert>
                )}
                {startup.message && (
                  <Alert color="info" className="alert--margin alert--colored mt-5 mt-md-0 mb-5">
                    <h4 className="mb-4">{`${startup.first_name} sent you a message`}</h4>
                    <p>
                      {startup.message}
                    </p>
                  </Alert>
                )}
              </Col>
            </Row>
            {!loaded
              ? (
                <div className={`load${loading ? '' : ' loaded'} inload`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              )
              : unauthorized === ''
              && (
                <Row>
                  <Col xs={12} xl={{ size: 11, offset: 1 }}>
                    {(props.match.url === '/startup/myCompany' || userId === parseInt(props.match.params.id, 10)) && (
                      <StartupMain
                        startup={startup}
                      />
                    )}
                    {props.match.url !== '/startup/myCompany' && userId !== parseInt(props.match.params.id, 10) && (
                      <StartupMain
                        startup={startup}
                        displayFavorites
                        renderDeclineButton={this.renderDeclineButton()}
                        renderConnectButton={this.renderConnectButton()}
                        addFavorites={e => this.addFavorites(e)}
                        removeFavorites={e => this.removeFavorites(e)}
                      />
                    )}
                    <StartupTabs
                      startup={startup}
                      sendNotification={val => this.sendNotification(val)}
                      similarStartups={similarStartups}
                      id={id}
                      userId={userId}
                    />
                  </Col>
                </Row>
              )
            }
          </div>
          <ModalInput
            color="primary"
            title="Send a Message"
            displayed={modalPing}
            ping={val => this.declineStartup(val)}
            toggleModal={() => this.toggleModalPing(0)}
          />
        </Container>
      </PageVisibility>
    );
  }
}

export default withRouter(StartupProfile);
