import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ConnectionCard from './ConnectionCard';
import MessagesForm from './MessagesForm';
import Alert from '../../../../shared/components/alerts/Alert';

class ConnectionsList extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    selectedConnectionId: PropTypes.number.isRequired,
    initialValues: PropTypes.shape({
      templates: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        delay: PropTypes.number,
      })),
    }).isRequired,
    selectedProspect: PropTypes.shape({
      id: PropTypes.number,
      investorConnections: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
        contacted: PropTypes.bool.isRequired,
        linkedinUrl: PropTypes.string.isRequired,
        picture: PropTypes.shape({
          id: PropTypes.number,
          linkedinIntroPictureName: PropTypes.string,
        }),
      })),
      searched: PropTypes.bool,
      introduced: PropTypes.bool,
    }).isRequired,
    connectionsToContact: PropTypes.arrayOf(PropTypes.number).isRequired,
    selectConnection: PropTypes.func.isRequired,
    updateTemplates: PropTypes.func.isRequired,
  };

  renderWarning = () => {
    const { selectedConnectionId, selectedProspect } = this.props;
    const connection = selectedProspect.investorConnections.filter(c => c.id === selectedConnectionId)[0];
    if (typeof connection !== 'undefined' && connection.selected) {
      return (
        <Alert color="warning" className="alert--colored alert--chat d-inline-block mb-5">
          <p className="text-left">
            <span className="bold-text">This connection has already been asked for an introduction</span><br />
            You can ask this connection an extra introduction using the templates below.
          </p>
        </Alert>
      );
    }
    return null;
  }

  render = () => {
    const {
      selectedConnectionId, initialValues, handleSubmit, selectedProspect, selectConnection, connectionsToContact,
      updateTemplates,
    } = this.props;

    return (
      <div className="wizard__form__body">
        <div className="mt-3">
          {connectionsToContact.length === 0
            ? <h3>Select Connections</h3>
            : <h3>{connectionsToContact.length} Connection{connectionsToContact.length > 1 && 's'} Selected</h3>
          }
          <p className="text-muted">
            Select connections you have on LinkedIn and&nbsp;
            <strong>
              <u>ask them for an introduction to</u>&nbsp;
              {selectedProspect && selectedProspect.investor.contactInformation.fullName}
            </strong>
          </p>
          <div className="list__connections">
            {selectedProspect && selectedProspect.investorConnections
            && selectedProspect.investorConnections.map(connection => (
              <ConnectionCard
                connection={connection}
                connectionToContact={connectionsToContact.includes(connection.id)}
                isSelectedConnection={selectedConnectionId === connection.id}
                selectConnection={selected => selectConnection(selected)}
                key={connection.id}
              />
            ))}
          </div>
          <p className="text-muted mt-5 mb-2">
            You can <strong>edit these messages</strong> here before they are scheduled and sent out.
          </p>
        </div>
        {this.renderWarning()}
        <div className="wizard__form__emails align-items-center">
          <MessagesForm
            updateTemplates={updateTemplates}
            connectionsToContact={connectionsToContact}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    );
  }
}

export default ConnectionsList;
