/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Collapse from '../Collapse';

export class BasicNotification extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    color: '',
    title: '',
  };

  render() {
    const {
      color, title, message,
    } = this.props;

    return (
      <div>
        <div className={`notification notification--${color}`}>
          <h5 className="notification__title bold-text">{title}</h5>
          <p className="notification__message">{message}</p>
        </div>
      </div>
    );
  }
}

export class CollapsibleNotification extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    message: PropTypes.object.isRequired,
  };

  static defaultProps = {
    color: '',
    title: '',
  };

  render() {
    const {
      color, title, message,
    } = this.props;

    return (
      <div className={`notification notification--collapse notification--${color} position-relative`}>
        <Collapse title={title} className="with-shadow position-relative">
          { message }
        </Collapse>
      </div>
    );
  }
}

export class LinkNotification extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    color: '',
    title: '',
    link: null,
  };

  render() {
    const {
      color, title, link, message,
    } = this.props;

    return (
      <div
        className={`notification notification--${color}`}
        onClick={() => { window.location.href = link; }}
        role="button"
        tabIndex={0}
        onKeyDown={() => { window.location.href = link; }}
      >
        <h5 className="notification__title bold-text">{title}</h5>
        <p className="notification__message" dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    );
  }
}

export class HtmlNotification extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    color: '',
    title: '',
  };

  render() {
    const { color, title, message } = this.props;

    return (
      <div className={`notification notification--${color}`}>
        <h5 className="notification__title bold-text">{title}</h5>
        <p className="notification__message" dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    );
  }
}

export class ImageNotification extends PureComponent {
  static propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    title: '',
  };

  render() {
    const { img, title, message } = this.props;

    return (
      <div className="notification notification--image">
        <div className="notification__image">
          <img src={img} alt="" />
        </div>
        <h5 className="notification__title bold-text">{title}</h5>
        <p className="notification__message">{message}</p>
      </div>
    );
  }
}

export class FullWideNotification extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    color: '',
  };

  render() {
    const { color, message } = this.props;

    return (
      <div className={`notification notification--full-wide notification--${color}`}>
        <p className="notification__message">{message}</p>
      </div>
    );
  }
}
