import React, { Component } from 'react';
import {
  Card, Row, Col,
} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import InvestorCard from './InvestorCard';
import StatsElement from '../../../shared/components/dashboard/StatsElement';
import {
  FullWideNotification, BasicNotification,
} from '../../../shared/components/notifications/Notification';
import ModalReport from '../../../shared/components/modals/ModalReport';


let notificationTC = null;
let notificationRU = null;
const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class ColumnsElement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalReport: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  clickReportInvestor = (id, contactId, email, e) => {
    if (e) { e.preventDefault(); }

    const { investors } = this.props;
    // find the index of this investor
    let investor = null;
    if (investors.contacted.filter(i => i.id === id).length !== 0) {
      investor = investors.contacted.filter(i => i.id === id);
    } else if (investors.followups.filter(i => i.id === id).length !== 0) {
      investor = investors.followups.filter(i => i.id === id);
    } else if (investors.opened.filter(i => i.id === id).length !== 0) {
      investor = investors.opened.filter(i => i.id === id);
    } else if (investors.scheduled.filter(i => i.id === id).length !== 0) {
      investor = investors.scheduled.filter(i => i.id === id);
    } else {
      investor = investors.visited.filter(i => i.id === id);
    }

    if (contactId) {
      this.setState(
        {
          extInvestorContactInfo:
            {
              contactId,
              fullName: investor.fullName,
              investorId: id,
              email: email || investor.email,
              inCampaign: investor.inCampaign,
              campaignName: investor.campaignName,
              campaignId: investor.campaignId,
              contacted: investor.contacted,
              contactedOn: investor.contactedOn,
            },
        }, () => this.setState({ modalReport: true }),
      );
    }
  };


  toggleModalReport = () => {
    const { modalReport } = this.state;
    this.setState({ modalReport: !modalReport });
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  showNotifSuccess = message => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message={message}
    />,
    position: 'right-up',
  });

  render() {
    const {
      investors, stats, toggleToSend, selectInvestorToReview,
    } = this.props;
    const {
      extInvestorContactInfo, modalReport,
    } = this.state;
    let scheduled = null;
    let completed = null;
    let followups = [];
    let opened = null;
    let visited = null;

    if (investors && investors.scheduled) {
      scheduled = investors.scheduled.map(
        (investor, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LazyLoad height={80} offset={1000} overflow resize key={`s-${investor.id}-${index}`}>
            <InvestorCard
              investor={investor}
              key={investor.id}
              index={index}
              toggleToSend={(contactId, toSend, campaignId) => toggleToSend(contactId, toSend, campaignId)}
              clickReport={e => this.clickReportInvestor(investor.id, investor.contactId, null, e)}
              selectInvestorToReview={selectInvestorToReview}
            />
          </LazyLoad>
        ),
      );
    }

    if (investors && investors.completed) {
      completed = investors.completed.map(
        (investor, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LazyLoad height={80} offset={1000} overflow resize key={`c-${investor.id}-${index}`}>
            <InvestorCard
              investor={investor}
              key={investor.id}
              index={index}
              toggleToSend={(contactId, toSend, campaignId) => toggleToSend(contactId, toSend, campaignId)}
              clickReport={e => this.clickReportInvestor(investor.id, investor.contactId, null, e)}
              selectInvestorToReview={selectInvestorToReview}
            />
          </LazyLoad>
        ),
      );
    }

    if (investors && investors.followups && Array.isArray(investors.followups) && investors.followups.length) {
      followups = investors.followups.map(
        (followup, key) => (
          followup.map(
            (investor, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <LazyLoad height={80} offset={1000} overflow resize key={`f-${investor.id}-${index}-${key}`}>
                <InvestorCard
                  investor={investor}
                  key={investor.id}
                  index={index}
                  toggleToSend={(contactId, toSend, campaignId) => toggleToSend(contactId, toSend, campaignId)}
                  clickReport={e => this.clickReportInvestor(investor.id, investor.contactId, null, e)}
                  selectInvestorToReview={selectInvestorToReview}
                />
              </LazyLoad>
            ),
          )
        ),
      );
    }

    if (investors && investors.opened) {
      opened = investors.opened.map(
        (investor, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LazyLoad height={80} offset={1000} overflow resize key={`o-${investor.id}-${index}`}>
            <InvestorCard
              investor={investor}
              key={investor.id}
              index={index}
              toggleToSend={(contactId, toSend, campaignId) => toggleToSend(contactId, toSend, campaignId)}
              clickReport={e => this.clickReportInvestor(investor.id, investor.contactId, null, e)}
              selectInvestorToReview={selectInvestorToReview}
            />
          </LazyLoad>
        ),
      );
    }

    if (investors && investors.visited) {
      visited = investors.visited.map(
        (investor, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LazyLoad height={80} offset={1000} overflow resize key={`v-${investor.id}-${index}`}>
            <InvestorCard
              investor={investor}
              key={investor.id}
              index={index}
              toggleToSend={(contactId, toSend, campaignId) => toggleToSend(contactId, toSend, campaignId)}
              clickReport={e => this.clickReportInvestor(investor.id, investor.contactId, null, e)}
            />
          </LazyLoad>
        ),
      );
    }

    return (
      <Card className="container crm-container">
        {opened && scheduled && completed && followups && visited
          && opened.length === 0 && scheduled.length === 0 && completed.length === 0
          && followups.length === 0 && visited.length === 0 && opened.length === 0
          && <div className="no-results">No results found. Try with different filters.</div>}
        <Row className="padding-right-80">
          <Col className="col-crm">
            <StatsElement
              id="contacted"
              type="contacted"
              name="Contacted So Far"
              total={stats.contacted.total}
              stat={stats.contacted.rate}
            />
            <div className="col-title">
              <h6>{`${scheduled ? scheduled.length : 0} Queued`}</h6>
            </div>
            <div className="inner-column" id="inner-scheduled">
              {scheduled}
            </div>
          </Col>
          {followups.map((followup, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col className="col-crm" key={`s-${index}`}>
              <StatsElement
                id={`followup${index}`}
                type="followup"
                name={`Follow Up #${index + 1}`}
                total={typeof stats.followups[index] !== 'undefined' ? stats.followups[index].total : 0}
                stat={typeof stats.followups[index] !== 'undefined' ? stats.followups[index].rate : 0}
              />
              <div className="col-title">
                <h6>{`${followup ? followup.length : 0} Queued`}</h6>
              </div>
              <div className="inner-column">
                {followup}
              </div>
            </Col>
          ))}
          <Col className="col-crm">
            <StatsElement
              id="scheduled"
              type="scheduled"
              name="Scheduled In Total"
              total={stats.scheduled.total}
            />
            <div className="col-title">
              <h6>{`${completed ? completed.length : 0} Stopped Or Completed`}</h6>
            </div>
            <div className="inner-column">
              {completed}
            </div>
          </Col>
          <Col className="col-crm">
            <StatsElement
              id="opened"
              type="opened"
              name="Opened"
              total={stats.opened.total}
              stat={stats.opened.rate}
            />
            <div className="col-title opened-title">
              <h6>Opened</h6>
            </div>
            <div className="inner-column opened-col">
              {opened}
            </div>
          </Col>
          <Col className="col-crm">
            <StatsElement
              id="visited"
              type="visited"
              name="Visited"
              total={stats.visited.total}
              stat={stats.visited.rate}
            />
            <div className="col-title visited-title">
              <h6>Visited</h6>
            </div>
            <div className="inner-column visited-col">
              {visited}
            </div>
          </Col>
          <img
            src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/dashboard/gradient.png`}
            className="crm-gradient"
            alt="gradient"
          />
        </Row>
        <ModalReport
          investorContactInfo={extInvestorContactInfo}
          displayed={modalReport}
          toggleModal={() => this.toggleModalReport()}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifError={message => this.showNotif(message)}
        />
      </Card>
    );
  }
}

ColumnsElement.defaultProps = {
  investors: [],
  stats: {
    scheduled: {
      total: 0,
    },
    contacted: {
      total: 0,
      rate: 0,
    },
    followups: [],
    opened: {
      total: 0,
      rate: 0,
    },
    visited: {
      total: 0,
      rate: 0,
    },
  },
};

ColumnsElement.propTypes = {
  investors: PropTypes.shape(PropTypes.shape({
    scheduled: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      picture: PropTypes.string,
      campaign: PropTypes.string,
      lastEmail: PropTypes.string,
      nextEmail: PropTypes.string,
      nbOpens: PropTypes.number,
      nbVisits: PropTypes.number,
      replied: PropTypes.bool,
      time: PropTypes.number,
      timeDeck: PropTypes.number,
      timeFinancials: PropTypes.number,
      countDeck: PropTypes.number,
      countFinancials: PropTypes.number,
      countMaterials: PropTypes.number,
      lastVisited: PropTypes.string,
      sent: PropTypes.number,
      toSend: PropTypes.number,
      stopping: PropTypes.bool,
    })),
    contacted: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      picture: PropTypes.string,
      campaign: PropTypes.string,
      lastEmail: PropTypes.string,
      nextEmail: PropTypes.string,
      nbOpens: PropTypes.number,
      nbVisits: PropTypes.number,
      replied: PropTypes.bool,
      time: PropTypes.number,
      timeDeck: PropTypes.number,
      timeFinancials: PropTypes.number,
      countDeck: PropTypes.number,
      countFinancials: PropTypes.number,
      countMaterials: PropTypes.number,
      lastVisited: PropTypes.string,
      sent: PropTypes.number,
      toSend: PropTypes.number,
      stopping: PropTypes.bool,
    })),
    followups: PropTypes.arrayOf(PropTypes.shape({
      followup: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        picture: PropTypes.string,
        campaign: PropTypes.string,
        lastEmail: PropTypes.string,
        nextEmail: PropTypes.string,
        nbOpens: PropTypes.number,
        nbVisits: PropTypes.number,
        replied: PropTypes.bool,
        time: PropTypes.number,
        timeDeck: PropTypes.number,
        timeFinancials: PropTypes.number,
        countDeck: PropTypes.number,
        countFinancials: PropTypes.number,
        countMaterials: PropTypes.number,
        lastVisited: PropTypes.string,
        sent: PropTypes.number,
        toSend: PropTypes.number,
        stopping: PropTypes.bool,
      })),
    })),
    opened: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      picture: PropTypes.string,
      campaign: PropTypes.string,
      lastEmail: PropTypes.string,
      nextEmail: PropTypes.string,
      nbOpens: PropTypes.number,
      nbVisits: PropTypes.number,
      replied: PropTypes.bool,
      time: PropTypes.number,
      timeDeck: PropTypes.number,
      timeFinancials: PropTypes.number,
      countDeck: PropTypes.number,
      countFinancials: PropTypes.number,
      countMaterials: PropTypes.number,
      lastVisited: PropTypes.string,
      sent: PropTypes.number,
      toSend: PropTypes.number,
      stopping: PropTypes.bool,
    })),
    visited: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      picture: PropTypes.string,
      campaign: PropTypes.string,
      lastEmail: PropTypes.string,
      nextEmail: PropTypes.string,
      nbOpens: PropTypes.number,
      nbVisits: PropTypes.number,
      replied: PropTypes.bool,
      time: PropTypes.number,
      timeDeck: PropTypes.number,
      timeFinancials: PropTypes.number,
      countDeck: PropTypes.number,
      countFinancials: PropTypes.number,
      countMaterials: PropTypes.number,
      lastVisited: PropTypes.string,
      sent: PropTypes.number,
      toSend: PropTypes.number,
      stopping: PropTypes.bool,
    })),
  })),
  toggleToSend: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    scheduled: PropTypes.shape({
      total: PropTypes.number,
    }),
    contacted: PropTypes.shape({
      total: PropTypes.number,
      rate: PropTypes.number,
    }),
    followups: PropTypes.arrayOf(PropTypes.shape({
      total: PropTypes.number,
      rate: PropTypes.number,
    })),
    opened: PropTypes.shape({
      total: PropTypes.number,
      rate: PropTypes.number,
    }),
    visited: PropTypes.shape({
      total: PropTypes.number,
      rate: PropTypes.number,
    }),
  }),
  selectInvestorToReview: PropTypes.func.isRequired,
};

export default ColumnsElement;
