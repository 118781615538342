import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Card, Col, Row } from 'reactstrap';
import moment from 'moment';
import NotificationSystem from 'rc-notification';
import queryString from 'query-string';

import SearchElement from './SearchElement';
import ColumnsElement from './ColumnsElement';
import InfoElement from './InfoElement';
import AccountWarnings from '../../../shared/components/AccountWarnings';
import CampaignsWarnings from './CampaignsWarning';
import ModalMultiUpdateExtInvestors from '../../../shared/components/modals/ModalMultiUpdateExtInvestors';
import {
  BasicNotification,
  FullWideNotification,
  LinkNotification,
} from '../../../shared/components/notifications/Notification';
import ModalConfirmation from '../../../shared/components/modals/ModalConfirmation';
import ModalSingleInvestorReview from '../../../shared/components/modals/ModalSingleInvestorReview';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({
  notification,
  position,
}, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: {
          top: 30,
          left: 'calc(100vw - 100%)',
        },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: {
          top: 0,
          left: 0,
        },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class DashboardElements extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      blank: PropTypes.bool,
      campaign: PropTypes.string,
      campaignId: PropTypes.number,
      contactId: PropTypes.number,
      countDeck: PropTypes.number,
      countFinancials: PropTypes.number,
      countMaterials: PropTypes.number,
      email: PropTypes.bool,
      error: PropTypes.string,
      hasLink: PropTypes.bool,
      id: PropTypes.number,
      lastEmail: PropTypes.string,
      lastEmailHour: PropTypes.string,
      lastVisited: PropTypes.string,
      name: PropTypes.string,
      nbOpens: PropTypes.number,
      nbVisits: PropTypes.number,
      nextEmail: PropTypes.string,
      nextEmailHour: PropTypes.string,
      picture: PropTypes.string,
      sent: PropTypes.number,
      time: PropTypes.number,
      timeDeck: PropTypes.number,
      timeFinancials: PropTypes.number,
      toSend: PropTypes.number,
    })),
    updateDashboard: PropTypes.func.isRequired,
    modal: PropTypes.bool,
  };

  static defaultProps = {
    data: null,
    modal: false,
  };

  state = {
    list: [],
    recipients: null,
    // hasRecipients: false,
    loading: true,
    hasLink: true,
    keywords: null,
    filters: null,
    sort: null,
    info: {
      sent: 0,
      toSend: 0,
      pings: 0,
      lastEmail: null,
      nextEmail: null,
    },
    stats: {
      scheduled: {
        total: 0,
      },
      contacted: {
        total: 0,
        rate: 0,
      },
      followups: [],
      opened: {
        total: 0,
        rate: 0,
      },
      visited: {
        total: 0,
        rate: 0,
      },
    },
    campaigns: [],
    investorsContactInfo: [],
    extInvestorContactInfo: {},
    modalMultiInvestorsCampaigns: false,
    modalConfirmation: false,
    contactId: null,
    toSend: null,
    campaignId: null,
    ModalReview: false,
  }

  componentDidMount = () => {
    const { data } = this.props;
    if (data) {
      this.setState({ list: data });
      this.sort('relevance');
      this.sortDataByColumn(data);
    } else {
      this.setState({ loading: false });
    }

    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  // Toogle States data
  toggleToSend = (answer) => {
    const { contactId, toSend, campaignId } = this.state;
    const pause = !toSend;

    this.setState({
      modalConfirmation: false,
      campaignId: answer ? 0 : campaignId,
    });
    this.toggleToSendState(contactId, toSend, true);

    axios.post('/campaigns/recipients/pause', queryString.stringify({
      campaignId: answer ? 0 : campaignId,
      contactId,
      pause: pause ? 1 : 0,
    })).then((response) => {
      if (response.data.success) {
        this.toggleToSendState(contactId, pause, false);
      } else {
        this.toggleToSendState(contactId, toSend, false);
      }
    }).catch((error) => {
      if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
        // Do something error.response.data.message ?
        this.showNotif(error.response.data.message);
      } else {
        this.showNotif('Sorry, an error occurred, please try again or contact support.');
      }
      this.toggleToSendState(contactId, toSend, false);
    });
  }

  toggleToSendState = (contactId, toSend, stopping) => {
    const { props } = this;
    const {
      keywords, filters, sort, campaignId,
    } = this.state;
    const recipients = [...props.data];
    let updatedRecipientsList = [...recipients];

    // find the index of this investor
    let indexes;
    if (campaignId !== 0) {
      indexes = recipients.map(
        (r, index) => (r.contactId === contactId && r.campaignId === campaignId ? index : 'no'),
      ).filter(String);
    } else {
      indexes = recipients.map((r, index) => (r.contactId === contactId ? index : 'no')).filter(String);
    }

    // Update Contacts
    for (let i = 0; i < indexes.length; i += 1) {
      if (Number.isInteger(indexes[i])) {
        recipients[indexes[i]].toSend = !toSend;
        recipients[indexes[i]].paused = toSend;
        recipients[indexes[i]].stopping = stopping;

        const updatedRecipient = {
          ...recipients[indexes[i]],
        };

        // Make new list of recipients
        updatedRecipientsList = [
          ...updatedRecipientsList.slice(0, indexes[i]),
          updatedRecipient,
          ...updatedRecipientsList.slice(indexes[i] + 1),
        ];
      }
    }

    // Update the State
    this.sortDataByColumn(updatedRecipientsList);
    this.dataOperations(keywords, filters, sort);
  }

  selectInvestorToReview = (extInvestorContactInfo) => {
    this.setState({
      extInvestorContactInfo,
      ModalReview: true,
    });
  }

  // Search
  search = (keywords, filters, exact) => {
    const { list } = this.state;
    const recipients = {
      scheduled: [],
      contacted: [],
      followups: [],
      completed: [],
      opened: [],
      visited: [],
    };
    const stats = {
      scheduled: {
        total: 0,
      },
      contacted: {
        total: 0,
        rate: 0,
      },
      followups: [],
      completed: {
        total: 0,
        rate: 0,
      },
      opened: {
        total: 0,
        rate: 0,
      },
      visited: {
        total: 0,
        rate: 0,
      },
    };
    const info = {
      sent: 0,
      toSend: 0,
      pings: 0,
      nextEmail: null,
      lastEmail: null,
    };

    const date = moment().format('YYYY/MM/DD h:m a');
    const tz = moment.tz.guess();

    // construct a moment object
    const nowUtc = moment.tz(date, 'YYYY/MM/DD h:m a', tz);

    // convert it to utc
    nowUtc.utc();

    // format it for output
    const now = nowUtc.format('YYYY/MM/DD [at] h:m a');

    if (!keywords) {
      this.sortDataByColumn(list);
    } else {
      for (let i = 0; i < list.length; i += 1) {
        // eslint-disable-next-line no-mixed-operators
        if ((exact && (list[i].campaign.toLowerCase() === keywords.toLowerCase()) // search for campaign
          // eslint-disable-next-line no-mixed-operators
          || !exact && (list[i].name.toLowerCase().indexOf(keywords.toLowerCase()) !== -1 // if we search for keyword
            || list[i].campaign.toLowerCase().indexOf(keywords.toLowerCase()) !== -1))
          && (this.checkFilter(filters, list[i]) || !filters)) // if we use the filters
        // eslint-disable-next-line brace-style
        {
          let datetime = null;
          let completed = 0;
          stats.scheduled.total += 1;
          info.toSend += list[i].toSend;
          if (!list[i].email || !list[i].toSend || !list[i].nextEmail) {
            // completed
            recipients.completed.push(list[i]);
            stats.completed.total += 1;
            completed = 1;
          }
          if (list[i].sent === 0) {
            // scheduled
            if (!completed) {
              recipients.scheduled.push(list[i]);
            }

            // Check when is the next email to be sent out
            if (list[i].nextEmailHour && list[i].nextEmail) {
              datetime = list[i].nextEmail;
            }
            if ((info.nextEmail === null || datetime < info.nextEmail) && datetime >= now) {
              info.nextEmail = datetime;
            }
          } else {
            stats.contacted.total += 1;
            info.sent += list[i].sent;

            if (typeof stats.followups[list[i].sent - 2] === typeof undefined && list[i].sent - 2 >= 0) {
              stats.followups[list[i].sent - 2] = {
                total: 0,
                rate: 0,
                left: 0,
              };
            }

            if (!completed) {
              if (typeof recipients.followups[list[i].sent - 1] === typeof undefined) {
                recipients.followups[list[i].sent - 1] = [];
              }
              recipients.followups[list[i].sent - 1].push(list[i]);
            }

            if (list[i].sent > 1) {
              // followups
              let x = 2;
              while (list[i].sent - x >= 0) {
                if (typeof stats.followups[list[i].sent - x] === typeof undefined && list[i].sent - x >= 0) {
                  stats.followups[list[i].sent - x] = {
                    total: 0,
                    rate: 0,
                    left: 0,
                  };
                }
                stats.followups[list[i].sent - x].total += 1;
                x += 1;
              }
              if (list[i].toSend > 0 && list[i].sent - 2 >= 0) {
                stats.followups[list[i].sent - 2].left += 1;
              }
            }

            if (list[i].nbOpens >= 1) {
              // opened
              recipients.opened.push(list[i]);
              stats.opened.total += 1;
            }
            if (list[i].nbVisits >= 1) {
              // visited
              recipients.visited.push(list[i]);
              stats.visited.total += 1;
            }

            // Check when is the las email that has been sent ou
            if (list[i].lastEmailHour && list[i].lastEmail) {
              datetime = list[i].lastEmail;
            }
            if (info.lastEmail === null || datetime > info.lastEmail) {
              info.lastEmail = datetime;
            }
          }
        }
        stats.contacted.rate = stats.scheduled.total > 0
          ? Number((stats.contacted.total * 100 / stats.scheduled.total).toFixed(0))
          : 0;
        stats.opened.rate = stats.contacted.total
          ? Number((stats.opened.total * 100 / stats.contacted.total).toFixed(0))
          : 0;
        stats.visited.rate = stats.contacted.total
          ? Number((stats.visited.total * 100 / stats.contacted.total).toFixed(0))
          : 0;
        stats.completed.rate = stats.contacted.total
          ? Number((stats.completed.total * 100 / stats.contacted.total).toFixed(0))
          : 0;

        let y = 0;
        for (let x = 0; x < stats.followups.length; x += 1) {
          if (typeof stats.followups[x] !== 'undefined') {
            if (y === 0) {
              stats.followups[x].rate = Number((stats.followups[x].total * 100 / stats.contacted.total).toFixed(0));
            } else {
              stats.followups[x].rate = Number((stats.followups[x].total * 100
                / (stats.followups[y].left + stats.followups[x].total)).toFixed(0));
            }
            y = x;
          }
        }
      }
      // Last data for info
      const user = JSON.parse(localStorage.getItem('user'));
      info.pings = user && user.pings;

      this.setState({ recipients, stats, info });
    }
  }

  filter = (filters) => {
    const { list } = this.state;

    const recipients = {
      scheduled: [],
      contacted: [],
      followups: [],
      completed: [],
      opened: [],
      visited: [],
    };

    const stats = {
      scheduled: {
        total: 0,
      },
      contacted: {
        total: 0,
        rate: 0,
      },
      followups: [],
      completed: {
        total: 0,
        rate: 0,
      },
      opened: {
        total: 0,
        rate: 0,
      },
      visited: {
        total: 0,
        rate: 0,
      },
    };

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].archived) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (this.checkFilter(filters, list[i])) {
        stats.scheduled.total += 1;
        let completed = 0;

        if (!list[i].email || !list[i].toSend || !list[i].nextEmail) {
          // completed
          recipients.completed.push(list[i]);
          stats.completed.total += 1;
          completed = 1;
        }

        if (list[i].sent === 0) {
          // scheduled
          if (!completed) {
            recipients.scheduled.push(list[i]);
          }
        } else {
          stats.contacted.total += 1;

          if (typeof stats.followups[list[i].sent - 2] === typeof undefined && list[i].sent - 2 >= 0) {
            stats.followups[list[i].sent - 2] = {
              total: 0,
              rate: 0,
              left: 0,
            };
          }

          if (list[i].sent > 1) {
            // followups
            let x = 2;
            while (list[i].sent - x >= 0) {
              if (typeof stats.followups[list[i].sent - x] === typeof undefined && list[i].sent - x >= 0) {
                stats.followups[list[i].sent - x] = {
                  total: 0,
                  rate: 0,
                  left: 0,
                };
              }
              stats.followups[list[i].sent - x].total += 1;
              x += 1;
            }
            if (list[i].toSend > 0 && list[i].sent - 2 >= 0) {
              stats.followups[list[i].sent - 2].left += 1;
            }
          }

          if (!completed) {
            if (typeof recipients.followups[list[i].sent - 1] === typeof undefined) {
              recipients.followups[list[i].sent - 1] = [];
            }
            recipients.followups[list[i].sent - 1].push(list[i]);
          }

          if (list[i].nbOpens >= 1) {
            // opened
            recipients.opened.push(list[i]);
            stats.opened.total += 1;
          }
          if (list[i].nbVisits >= 1) {
            // visited
            recipients.visited.push(list[i]);
            stats.visited.total += 1;
          }
        }
      }
    }

    stats.contacted.rate = stats.scheduled.total > 0
      ? Number((stats.contacted.total * 100 / stats.scheduled.total).toFixed(0))
      : 0;
    stats.opened.rate = stats.contacted.total
      ? Number((stats.opened.total * 100 / stats.contacted.total).toFixed(0))
      : 0;
    stats.visited.rate = stats.contacted.total
      ? Number((stats.visited.total * 100 / stats.contacted.total).toFixed(0))
      : 0;
    stats.completed.rate = stats.contacted.total
      ? Number((stats.completed.total * 100 / stats.contacted.total).toFixed(0))
      : 0;

    let y = 0;
    for (let i = 0; i < stats.followups.length; i += 1) {
      if (typeof stats.followups[i] !== 'undefined') {
        if (y === 0) {
          stats.followups[i].rate = Number((stats.followups[i].total * 100 / stats.contacted.total).toFixed(0));
        } else {
          stats.followups[i].rate = Number((stats.followups[i].total * 100
            / (stats.followups[y].left + stats.followups[i].total)).toFixed(0));
        }
        y = i;
      }
    }

    this.setState({ recipients, stats });
  }

  checkFilter = (filters, element) => {
    if (!filters || filters.length === 0) {
      return true;
    }

    let validation = 0;

    for (let i = 0; i < filters.length; i += 1) {
      if ((filters[i] === 'contacted' && element.sent > 0)
        || (filters[i] === 'notContacted' && element.sent === 0)
        || (filters[i] === 'opened' && element.nbOpens > 0)
        || (filters[i] === 'notOpened' && element.nbOpens === 0)
        || (filters[i] === 'visited' && element.nbVisits > 0)
        || (filters[i] === 'notVisited' && element.nbVisits === 0)
        || (filters[i] === 'replied' && element.replied === true)
        || (filters[i] === 'notReplied' && element.replied === false)) {
        validation += 1;
      }
    }
    return validation === filters.length;
  }

  sort = (sort) => {
    const { data } = this.props;

    if (sort) {
      if (sort === 'alphabetical') {
        data.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      }
      if (sort === 'next') {
        data.sort((a, b) => {
          if (a.nextEmail > b.nextEmail) { return -1; }
          if (a.nextEmail < b.nextEmail) { return 1; }
          return 0;
        });
      }
      if (sort === 'last') {
        data.sort((a, b) => {
          if (a.lastEmail > b.lastEmail) { return -1; }
          if (a.lastEmail < b.lastEmail) { return 1; }
          return 0;
        });
      }
      if (sort === 'engagement') {
        data.sort((a, b) => {
          if (a.nbVisits + a.nbOpens > b.nbVisits + b.nbOpens) { return -1; }
          if (a.nbVisits + a.nbOpens < b.nbVisits + b.nbOpens) { return 1; }
          return 0;
        });
      }
      if (sort === 'relevance') {
        data.sort((a, b) => {
          if (a.toSend > b.toSend) { return -1; }
          if (a.toSend < b.toSend) { return 1; }
          return 0;
        });
        data.sort((a, b) => {
          if (a.nextEmail === null && b.nextEmail !== null) { return 1; }
          if (a.nextEmail !== null && b.nextEmail == null) { return -1; }
          return 0;
        });
        data.sort((a, b) => {
          if (a.email === true && b.email === false) { return -1; }
          if (a.email === false && b.email === true) { return 1; }
          return 0;
        });
      }
    }

    this.setState({ list: data });
  }

  // This function combines sort + search + filters
  dataOperations = (keywords, filters, sort, exact) => {
    this.sort(sort);
    this.setState({ keywords, filters, sort });
    const availFilters = [
      'opened', 'notOpened', 'visited', 'notVisited', 'contacted', 'notContacted', 'replied', 'notReplied',
    ];

    // we can sort and do the operations:
    if (!keywords && filters && availFilters.some(r => filters.includes(r))) {
      this.filter(filters);
    } else if (keywords && (!filters || !availFilters.some(r => filters.includes(r)))) {
      this.search(keywords, null, exact);
    } else if (keywords && filters && availFilters.some(r => filters.includes(r))) {
      this.search(keywords, filters, exact);
    } else {
      // No filters
      const { list } = this.state;
      this.sortDataByColumn(list);
    }
  }

  sortDataByColumn = (data) => {
    const recipients = {
      scheduled: [],
      contacted: [],
      followups: [],
      completed: [],
      opened: [],
      visited: [],
    };
    const stats = {
      scheduled: {
        total: 0,
      },
      contacted: {
        total: 0,
        rate: 0,
      },
      followups: [],
      completed: {
        total: 0,
        rate: 0,
      },
      opened: {
        total: 0,
        rate: 0,
      },
      visited: {
        total: 0,
        rate: 0,
      },
    };
    const info = {
      sent: 0,
      toSend: 0,
      pings: 0,
      nextEmail: null,
      lastEmail: null,
    };

    const { campaigns } = this.state;
    const date = moment().format('YYYY/MM/DD h:m a');
    const tz = moment.tz.guess();

    // construct a moment object
    const nowUtc = moment.tz(date, 'YYYY/MM/DD h:m a', tz);

    // convert it to utc
    nowUtc.utc();

    // format it for output
    const now = nowUtc.format('YYYY/MM/DD [at] h:m a');

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].archived) {
        if (data[i].sent > 0) {
          info.sent += data[i].sent;
        }
        // eslint-disable-next-line no-continue
        continue;
      }
      let datetime = null;
      let completed = 0;
      stats.scheduled.total += 1;
      info.toSend += data[i].toSend;

      if (!data[i].email || !data[i].toSend || !data[i].nextEmail) {
        // completed
        recipients.completed.push(data[i]);
        stats.completed.total += 1;
        completed = 1;
      }
      if (data[i].sent === 0) {
        // scheduled
        if (!completed) {
          recipients.scheduled.push(data[i]);
        }

        // Check when is the next email to be sent out
        if (data[i].nextEmailHour && data[i].nextEmail) {
          datetime = data[i].nextEmail;
        }

        if ((info.nextEmail === null || datetime < info.nextEmail)
          && (`${datetime} at ${data[i].nextEmailHour}`) >= now) {
          info.nextEmail = datetime;
        }
      } else {
        stats.contacted.total += 1;
        info.sent += data[i].sent;

        if (typeof stats.followups[data[i].sent - 2] === typeof undefined && data[i].sent - 2 >= 0) {
          stats.followups[data[i].sent - 2] = {
            total: 0,
            rate: 0,
            left: 0,
          };
        }

        // follow ups
        if (!completed) {
          if (typeof recipients.followups[data[i].sent - 1] === typeof undefined) {
            recipients.followups[data[i].sent - 1] = [];
          }
          recipients.followups[data[i].sent - 1].push(data[i]);
        }

        if (data[i].sent > 1) {
          // followups
          let x = 2;
          while (data[i].sent - x >= 0) {
            if (typeof stats.followups[data[i].sent - x] === typeof undefined && data[i].sent - x >= 0) {
              stats.followups[data[i].sent - x] = {
                total: 0,
                rate: 0,
                left: 0,
              };
            }
            stats.followups[data[i].sent - x].total += 1;
            x += 1;
          }
          if (data[i].toSend > 0 && data[i].sent - 2 >= 0) {
            stats.followups[data[i].sent - 2].left += 1;
          }
        }

        if (data[i].nbOpens >= 1) {
          // opened
          recipients.opened.push(data[i]);
          stats.opened.total += 1;
        }
        if (data[i].nbVisits >= 1) {
          // visited
          recipients.visited.push(data[i]);
          stats.visited.total += 1;
        }

        // Check when is the las email that has been sent ou
        if (data[i].lastEmailHour && data[i].lastEmail) {
          datetime = data[i].lastEmail;
        }
        if (info.lastEmail === null || datetime > info.lastEmail) {
          info.lastEmail = datetime;
        }
      }
      // Add campaign to list
      if (campaigns.indexOf(data[i].campaign) === -1) {
        campaigns.push(data[i].campaign);
      }
      // Check if a campain does not have a tracking link
      const { hasLink } = this.state;

      if (!data[i].hasLink && hasLink) {
        this.setState({ hasLink: false });
      }
    }

    stats.contacted.rate = stats.scheduled.total > 0
      ? Number((stats.contacted.total * 100 / stats.scheduled.total).toFixed(0))
      : 0;
    stats.opened.rate = stats.contacted.total
      ? Number((stats.opened.total * 100 / stats.contacted.total).toFixed(0))
      : 0;
    stats.visited.rate = stats.contacted.total
      ? Number((stats.visited.total * 100 / stats.contacted.total).toFixed(0))
      : 0;
    stats.completed.rate = stats.contacted.total
      ? Number((stats.completed.total * 100 / stats.contacted.total).toFixed(0))
      : 0;

    let y = 0;
    for (let i = 0; i < stats.followups.length; i += 1) {
      if (typeof stats.followups[i] !== 'undefined') {
        if (y === 0) {
          stats.followups[i].rate = Number((stats.followups[i].total * 100 / stats.contacted.total).toFixed(0));
        } else {
          stats.followups[i].rate = Number((stats.followups[i].total * 100
            / (stats.followups[y].left + stats.followups[i].total)).toFixed(0));
        }
        y = i;
      }
    }

    // Last data for info
    const user = JSON.parse(localStorage.getItem('user'));
    info.pings = user && user.pings;

    this.setState({
      recipients, stats, info, loading: false,
    });
  }

  // Updates Modal
  sendUpdate = () => {
    const { recipients } = this.state;
    const {
      contacted, scheduled, followups, completed,
    } = recipients;
    const investorsContactInfo = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const contactedElement of contacted) {
      const exist = investorsContactInfo.find(el => el.id === contactedElement.id);
      if (!exist && contactedElement.email) {
        investorsContactInfo.push(contactedElement);
      }
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const scheduledElement of scheduled) {
      const exist = investorsContactInfo.find(el => el.id === scheduledElement.id);
      if (!exist && scheduledElement.email) {
        investorsContactInfo.push(scheduledElement);
      }
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const followupElements of followups) {
      if (followupElements) {
        // eslint-disable-next-line no-restricted-syntax
        for (const followup of followupElements) {
          const exist = investorsContactInfo.find(el => el.id === followup.id);
          if (!exist && followup.email) {
            investorsContactInfo.push(followup);
          }
        }
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const completedElement of completed) {
      const exist = investorsContactInfo.find(el => el.contactId === completedElement.contactId);
      if (!exist && completedElement.email) {
        investorsContactInfo.push(completedElement);
      }
    }

    for (let i = 0; i < investorsContactInfo.length; i += 1) {
      if (investorsContactInfo[i].name && investorsContactInfo[i].email) {
        investorsContactInfo[i].fullName = investorsContactInfo[i].name;
      }
    }

    this.setState({
      investorsContactInfo,
      modalMultiInvestorsCampaigns: true,
    });
  };

  // Toggle Modals
  toggleModalMultiInvestorsCampaigns = () => {
    const { modalMultiInvestorsCampaigns } = this.state;
    const { updateDashboard } = this.props;

    if (modalMultiInvestorsCampaigns) {
      updateDashboard();
    }

    this.setState({ modalMultiInvestorsCampaigns: !modalMultiInvestorsCampaigns });
  };

  toggleModalReview = () => {
    const { ModalReview } = this.state;
    this.setState({ ModalReview: !ModalReview });
  };

  // Notifications
  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  showNotifSuccess = message => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message={message}
    />,
    position: 'right-up',
  });

  showNotifWarning = (message, link) => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      link={link}
      message={message}
    />,
    position: 'right-up',
  });

  shouldDisplayWarning = (contactId, toSend, campaignId) => {
    const { props } = this;
    const recipients = [...props.data];

    const investorCampaign = recipients.filter(r => r.contactId === contactId);

    this.setState({
      contactId,
      toSend,
      campaignId,
    }, () => {
      if (investorCampaign.length > 1) {
        this.setState({
          modalConfirmation: true,
        });
      } else {
        this.toggleToSend(1);
      }
    });
  }

  render() {
    const {
      recipients, stats, campaigns, info, loading, hasLink, toSend,
      investorsContactInfo, modalMultiInvestorsCampaigns, modalConfirmation, ModalReview,
      extInvestorContactInfo,
    } = this.state;
    const { modal } = this.props;

    return (
      <div>
        {!modal && (
          <AccountWarnings
            showModalTrial
          />
        )}
        <CampaignsWarnings
          hasLink={hasLink}
        />
        <SearchElement
          campaigns={campaigns}
          dataOperations={(keywords, filters, sort, exact) => this.dataOperations(keywords, filters, sort, exact)}
          sendUpdate={this.sendUpdate}
        />
        <InfoElement
          info={info}
        />
        {loading ? (
          <Card className="container crm-container">
            <Row>
              <Col xs={12}>
                Loading ...
              </Col>
            </Row>
          </Card>
        ) : (
          <ColumnsElement
            investors={recipients}
            stats={stats}
            selectInvestorToReview={extInvContactInfoId => this.selectInvestorToReview(extInvContactInfoId)}
            toggleToSend={
              (contactId, toSendResp, campaignId) => this.shouldDisplayWarning(contactId, toSendResp, campaignId)
            }
          />
        )}
        <ModalMultiUpdateExtInvestors
          investorsContactInfo={investorsContactInfo}
          fundsContactInfo={[]}
          displayed={modalMultiInvestorsCampaigns}
          toggleModal={() => this.toggleModalMultiInvestorsCampaigns()}
          showNotif={message => this.showNotif(message)}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifWarning={(message, link) => this.showNotifWarning(message, link)}
          updateListOfInvestors={null}
          updateMultipleListOfInvestors={null}
          sendUpdate
        />
        <ModalSingleInvestorReview
          investorContactInfo={extInvestorContactInfo}
          displayed={ModalReview}
          toggleModal={() => this.toggleModalReview()}
          showNotif={message => this.showNotif(message)}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifWarning={(message, link) => this.showNotifWarning(message, link)}
          updateListOfInvestors={() => {}} // we are not doing anything here
        />
        <ModalConfirmation
          displayed={modalConfirmation}
          color="primary"
          confirm={() => this.toggleToSend(true)}
          cancel={() => this.toggleToSend(false)}
          title="This investor is in another campaign"
          message={`Do you also want to ${toSend ? 'resume' : 'pause'} all the other campaigns for this investor?`}
        />
      </div>
    );
  }
}


export default DashboardElements;
