import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const InvestorProspectsList = ({
  prospectsList,
  selectedProspect,
  toggleProspect,
}) => (
  <div className="list__investors">
    <ul className="list multiple">
      {prospectsList && prospectsList.map(prospect => (
        <li key={`key${prospect.investor.id}`}>
          <Button
            className={`icon icon--right icon--blue 
                        ${prospect.investor.id === selectedProspect.investor.id ? 'btn-secondary' : ''}`}
            outline
            onClick={() => toggleProspect(prospect)}
            onKeyDown={() => toggleProspect(prospect)}
          >
            <img
              src={prospect.investor.contactInformation && prospect.investor.contactInformation.picture
                ? process.env.REACT_APP_AWS_ROOT
                + process.env.REACT_APP_AWS_INVESTORS_PATH
                + prospect.investor.contactInformation.picture.pictureName
                : userIcon
              }
              alt="investor"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userIcon;
              }}
            />
            <h5>
              {prospect.investor.contactInformation && prospect.investor.contactInformation.fullName
                ? prospect.investor.contactInformation.fullName.replace(' ', '\r\n')
                : prospect.investor.name && prospect.investor.name.replace(' ', '\r\n')}
            </h5>
            <ChevronRightIcon />
          </Button>
        </li>
      ))}
    </ul>
  </div>
);

InvestorProspectsList.propTypes = {
  prospectsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      connections: PropTypes.string,
      searched: PropTypes.bool,
      introduced: PropTypes.bool,
      contactInformation: PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        email: PropTypes.bool,
        picture: PropTypes.string,
        inCampaign: PropTypes.bool,
      }),
    }),
  ).isRequired,
  selectedProspect: PropTypes.shape({
    contactId: PropTypes.number,
    fullName: PropTypes.string,
    investorId: PropTypes.number,
    email: PropTypes.bool,
    inCampaign: PropTypes.bool,
    campaignName: PropTypes.string,
    contacted: PropTypes.bool,
    contactedOn: PropTypes.string,
  }).isRequired,
  toggleProspect: PropTypes.func.isRequired,
};

export default InvestorProspectsList;
