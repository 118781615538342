import React, { PureComponent } from 'react';
import { Button, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { Link } from 'react-router-dom';
import TopbarMenuLink from './TopbarMenuLink';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

export default class TopbarProfile extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    logo: PropTypes.string,
    type: PropTypes.string,
    impersonator: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;
    const {
      name, logo, type, impersonator,
    } = this.props;
    let collapseType;
    const collapseStartup = (
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="My Company"
            icon="store"
            path="/myProfile/company"
            onClick={this.toggle}
          />
          <TopbarMenuLink
            title="Founder Profile"
            icon="user"
            path="/myProfile/founder"
            onClick={this.toggle}
            isNew
          />
          <TopbarMenuLink
            title="Settings"
            icon="cog"
            path="/myProfile/settings"
            onClick={this.toggle}
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Pricing"
            icon="question-circle"
            path="/ext/pricing"
            onClick={this.toggle}
          />
          <TopbarMenuLink
            title="Contact Us"
            icon="bubble"
            path="/ext/contact"
            onClick={this.toggle}
          />
          <div className="topbar__menu-divider" />
          {impersonator
            ? (
              <a
                href={`${process.env.REACT_APP_DOMAIN_URL}/logged_in?_want_to_be_this_user=_exit`}
                onClick={this.toggle}
              >
                <Button
                  className="rounded center-block topbar__action-btn"
                  color="primary"
                  size="sm"
                >
                  Manage Startups
                </Button>
              </a>
            )
            : (
              <Link to="/logout" onClick={this.toggle}>
                <Button
                  className="rounded icon icon--right icon--blue center-block topbar__action-btn"
                  color="primary"
                  size="sm"
                >
                  Log Out
                  <ChevronRightIcon />
                </Button>
              </Link>
            )}
        </div>
      </Collapse>
    );

    const collapseInvestor = (
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="Settings"
            icon="cog"
            path="/myProfile/settings"
            onClick={this.toggle}
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Pricing"
            icon="question-circle"
            path="/ext/pricing/investor"
            onClick={this.toggle}
          />
          <TopbarMenuLink
            title="Contact Us"
            icon="bubble"
            path="/ext/contact"
            onClick={this.toggle}
          />
          <Link to="/logout" onClick={this.toggle}>
            <Button
              className="rounded icon icon--right icon--blue center-block topbar__action-btn"
              color="primary"
              size="sm"
            >
              Log Out
              <ChevronRightIcon />
            </Button>
          </Link>
        </div>
      </Collapse>
    );

    const collapseBroker = (
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="Pricing"
            icon="question-circle"
            path="/ext/pricing"
            onClick={this.toggle}
          />
          <TopbarMenuLink
            title="Contact Us"
            icon="bubble"
            path="/ext/contact"
            onClick={this.toggle}
          />
          <div className="topbar__menu-divider" />
          <Link to="/logout" onClick={this.toggle}>
            <Button
              className="rounded icon icon--right icon--blue center-block topbar__action-btn"
              color="primary"
              size="sm"
            >
              Log Out
              <ChevronRightIcon />
            </Button>
          </Link>
        </div>
      </Collapse>
    );

    if (type === 'investor') {
      collapseType = collapseInvestor;
    } else if (type === 'broker') {
      collapseType = collapseBroker;
    } else {
      collapseType = collapseStartup;
    }

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <img
            className="topbar__avatar-img"
            src={logo}
            onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
            alt="avatar"
          />
          <p className="topbar__avatar-name">{ name }</p>
          <svg
            width="20"
            height="12"
            viewBox="0 0 20 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="topbar__icon"
          >
            <path d="M1 1L10 10L19 1" stroke="#243143" strokeWidth="2" fill="none" />
          </svg>
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        {collapseType}
      </div>
    );
  }
}

TopbarProfile.defaultProps = {
  name: null,
  logo: null,
  type: null,
  impersonator: false,
};
