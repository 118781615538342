import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import RemoveCircleIcon from 'mdi-react/RemoveCircleIcon';
import PropTypes from 'prop-types';

const NotAFitButton = ({
  entity, clickNotAFit, className, displayNotAFit, onlyIcon,
}) => {
  if (entity.notAFit && displayNotAFit && onlyIcon) {
    return (
      <Button
        className={`icon rounded investor-item__icon-centered icon-only ${className}`}
        color="danger"
        id="notAFit"
        onMouseDown={e => clickNotAFit(e)}
        onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { clickNotAFit(e); } }}
      >
        <RemoveCircleIcon />
        <UncontrolledTooltip placement="top" target="notAFit">
          Remove from your list of discarded investors
        </UncontrolledTooltip>
      </Button>
    );
  } if (entity.notAFit && displayNotAFit) {
    return (
      <Button
        className={`icon icon--right rounded investor-item__icon-centered ${className}`}
        color="danger"
        id="notAFit"
        outline
        onMouseDown={e => clickNotAFit(e)}
        onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { clickNotAFit(e); } }}
      >
        <p>Not A Fit <RemoveCircleIcon /></p>
        <UncontrolledTooltip placement="top" target="notAFit">
          Remove from your list of discarded investors
        </UncontrolledTooltip>
      </Button>
    );
  } if (entity.removing && displayNotAFit && onlyIcon) {
    return (
      <Button
        className={`icon rounded investor-item__icon-centered icon-only ${className}`}
        color="danger"
        outline
      >
        <LoadingIcon className="mdi-icon-spinning" />
      </Button>
    );
  } if (entity.removing && displayNotAFit) {
    return (
      <Button
        className={`icon icon--right rounded investor-item__icon-centered ${className}`}
        color="danger"
        outline
      >
        <p>Discarding.. <LoadingIcon className="mdi-icon-spinning" /></p>
      </Button>
    );
  } if (displayNotAFit && onlyIcon) {
    return (
      <Button
        className={`icon rounded investor-item__icon-centered icon-only ${className}`}
        color="primary"
        id="notAFit"
        onMouseDown={e => clickNotAFit(e)}
        onKeyUp={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            clickNotAFit(e);
          }
        }}
      >
        <RemoveCircleIcon />
        <UncontrolledTooltip placement="top" target="notAFit">
          Mark as not a good fit
        </UncontrolledTooltip>
      </Button>
    );
  } if (displayNotAFit) {
    return (
      <Button
        className={`icon icon--right rounded investor-item__icon-centered ${className}`}
        color="primary"
        onMouseDown={e => clickNotAFit(e)}
        onKeyUp={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            clickNotAFit(e);
          }
        }}
      >
        <p>Not A Fit <RemoveCircleIcon /></p>
      </Button>
    );
  }
  return null;
};

NotAFitButton.propTypes = {
  entity: PropTypes.shape({
    notAFit: PropTypes.bool,
    removing: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
  displayNotAFit: PropTypes.bool,
  clickNotAFit: PropTypes.func.isRequired,
  onlyIcon: PropTypes.bool,
};

NotAFitButton.defaultProps = {
  className: null,
  displayNotAFit: false,
  onlyIcon: false,
};

export default NotAFitButton;
