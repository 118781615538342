import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarPresentation from './SidebarPresentation';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    collapsed: PropTypes.bool.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const {
      type, name, logo, collapsed,
    } = this.props;

    const contentStartup = (
      <div className="sidebar__content">
        {!collapsed && <SidebarPresentation logo={logo} name={name} /> }
        <ul className="sidebar__block">
          <SidebarLink
            title="Dashboard"
            icon="home"
            route="/dashboard"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Investors Database"
            icon="database"
            route="/investors-db"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Investors Community"
            icon="list"
            route="/investors"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Founders Community"
            icon="users"
            route="/founders"
            onClick={this.hideSidebar}
            newLink
          />
          <SidebarLink
            title="Campaigns"
            icon="rocket"
            route="/campaigns"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Warm Introductions"
            icon="handshake"
            route="/warm-intro"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Tracking Links"
            icon="link"
            route="/links"
            onClick={this.hideSidebar}
          />
          <SidebarCategory title="My Favorites" icon="star">
            <SidebarLink
              title="Investors Database"
              icon="database"
              route="/investors-db/?page=1&filters=3"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Investors Community"
              icon="list"
              route="/investors/?page=1&filters=favorites"
              onClick={this.hideSidebar}
            />
          </SidebarCategory>
          <SidebarLink
            title="Resources"
            icon="book"
            route="/resources"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Partnerships"
            icon="rocket"
            route="/partnerships"
            onClick={this.hideSidebar}
          />
        </ul>
      </div>
    );

    const contentInvestor = (
      <div className="sidebar__content">
        {!collapsed && <SidebarPresentation logo={logo} name={name} /> }
        <ul className="sidebar__block">
          <SidebarLink
            title="Browse Startups"
            icon="list"
            route="/startups"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Favorites"
            icon="star"
            route="/startups/?page=1&filters=favorites"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="My Activity"
            icon="alarm"
            route="/notifications"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="My Profile"
            icon="user"
            route="/myProfile/investor"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Inbox"
            icon="inbox"
            route="/inbox"
            onClick={this.hideSidebar}
          />
        </ul>
      </div>
    );

    switch (type) {
      case 'investor':
        return contentInvestor;
      case 'startup':
        return contentStartup;
      default:
        return '';
    }
  }
}

export default SidebarContent;
