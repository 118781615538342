/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter a name for your campaign';
  } else if (values.name.length < 2) {
    errors.name = 'The name of your campaign should be at least 2 characters long.';
  } else if (values.name.length > 160) {
    errors.name = 'The name of your campaign should be less than 160 characters long.';
  }

  return errors;
};

export default validate;
