/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import getPicture from '../../../shared/functions/getPicture';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

class TopbarNotification extends PureComponent {
  state = {
    newNotifs: false,
    collapse: false,
    notifications: [],
  };

  componentDidMount = () => {
    this.getNotifications(false);
  };

  getNotifications = () => {
    axios.get('conversations/notifications/5')
      .then((response) => {
        this.setState({ notifications: response.data.conversations, newNotifs: response.data.unread });
      });
  };

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }), () => {
      const { collapse } = this.state;
      if (collapse) {
        // Remove all unread notifications from our list of conversations.
        axios.post('/conversations/notifications/unread/remove')
          .then((response) => {
            if (response.data) this.setState({ newNotifs: false });
          });
      }
    });
  };

  render() {
    const {
      collapse, notifications, newNotifs,
    } = this.state;

    return (
      <div className="topbar__collapse">
        <button
          className="topbar__btn topbar__btn--new"
          type="button"
          onClick={this.toggle}
        >
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#8D9AA1" fill="none" />
            <path d="M10 14H26" stroke="#8D9AA1" strokeWidth="1.1" />
            <path d="M10 18H26" stroke="#8D9AA1" strokeWidth="1.1" />
            <path d="M10 22H26" stroke="#8D9AA1" strokeWidth="1.1" />
          </svg>

          { newNotifs
            && (
              <div className="topbar__btn-new-label">
                <div />
              </div>
            )
          }
        </button>
        {
          collapse
          && (
            <>
              <button className="topbar__back" type="button" onClick={this.toggle} />
              <span className="topbar__collapse-arrow" />
              <div
                className="topbar__collapse-content"
              >
                <div className="topbar__collapse-title-wrap">
                  <p className="topbar__collapse-title">Notifications</p>
                </div>
                {notifications
                  && notifications.map((notification, index) => (
                    <div key={index}>
                      <div
                        className="topbar__collapse-item-divider
                        d-flex flex-row align-items-center justify-content-center"
                      >
                        <svg
                          width="221"
                          height="2"
                          viewBox="0 0 221 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 1C10.5 1 220.5 1 220.5 1" stroke="#EDEFF2" />
                        </svg>
                        <span className="topbar__collapse-date">
                          {notification.date}
                        </span>
                      </div>
                      <Link
                        className="topbar__collapse-item"
                        to={notification.type === 'investor'
                          ? `/investor/${notification.id}` : `/startup/${notification.id}`
                        }
                        onClick={this.toggle}
                        key={index}
                      >
                        <div className="topbar__collapse-img-wrap">
                          <img
                            className="topbar__collapse-img"
                            src={getPicture(notification)}
                            onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
                            alt="user"
                          />
                        </div>
                        <p className="topbar__collapse-message">
                          <span className="topbar__collapse-name">{notification.name}</span> {notification.message}
                          {notification.response
                          && (
                            <span className="response-declined">
                              <br />
                              &quot;{
                              notification.response.length > 75
                                ? `${notification.response.substring(0, 75)}...`
                                : notification.response
                              }&quot;
                            </span>
                          )}
                        </p>
                      </Link>
                    </div>
                  ))}
                <Link to="/notifications" onClick={this.toggle}>
                  <Button
                    className="rounded icon icon--right icon--blue center-block topbar__action-btn"
                    color="primary"
                    size="sm"
                  >
                    See all notifications
                    <ChevronRightIcon />
                  </Button>
                </Link>
              </div>
            </>
          )
        }
      </div>
    );
  }
}

export default withRouter(TopbarNotification);
