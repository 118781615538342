import React from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import PropTypes from 'prop-types';

const ChatSearch = ({ search }) => (
  <div className="chat__search">
    <input className="chat__search-input" placeholder="Search" onChange={search} />
    <MagnifyIcon />
  </div>
);

ChatSearch.propTypes = {
  search: PropTypes.func.isRequired,
};

export default ChatSearch;
