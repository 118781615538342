import React from 'react';
import {
  Button,
  Card, CardBody,
} from 'reactstrap';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';

const Mailbox = ({
  allowed, provider, comingSoon, name, onProviderSelected, error, loading, triggerModalUpdate,
}) => (
  <Card
    className={`${allowed ? 'pointer' : 'forbidden'} panel mt-5 mt-xl-0 p-4 p-md-0`}
    onClick={allowed ? onProviderSelected : triggerModalUpdate}
  >
    <CardBody className="panel__body">
      {loading ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
      <div className="mailbox__img-container">
        <img
          src={`${process.env.REACT_APP_DOMAIN_URL}/platform/images/mailboxes/${provider}.png`}
          alt={`${provider} Integration Icon`}
        />
      </div>
      {error
        ? (
          <div className="mailbox__info">
            <h5>Integrate with {name}</h5>
            <br />
            <p className="text-red">{error}</p>
          </div>
        )
        : (
          <div className="mailbox__info">
            <h5>Integrate with {name}</h5>
            <p className="text-muted">
              {comingSoon
                ? 'This option is coming soon.'
                : `Connect your own ${name} account to Angels Partners and start sending messages to investors
                   directly from the platform.`
              }
            </p>
            {!comingSoon
              ? (
                <Button
                  color="primary"
                  size="sm"
                >
                  Connect
                </Button>
              ) : (
                <Button
                  disabled
                  size="sm"
                  color="secondary"
                >
                  Connect
                </Button>
              )}
          </div>
        )}
    </CardBody>
  </Card>
);

Mailbox.propTypes = {
  allowed: PropTypes.bool.isRequired,
  provider: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  comingSoon: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onProviderSelected: PropTypes.func.isRequired,
  triggerModalUpdate: PropTypes.func.isRequired,
};

export default withRouter(Mailbox);
