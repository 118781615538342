/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import {
  Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Alert from '../../../../shared/components/alerts/Alert';
import validate from '../../formValidate/message';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

class FounderCommunity extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
      founderLinkedin: PropTypes.string,
      community: PropTypes.bool,
    }).isRequired,
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting, initialValues,
    } = this.props;

    const defaultChecked = (value, type) => {
      // DO NOT MINIMISE
      if ((value && type) || (!value && !type)) {
        return true;
      }
      return false;
    };

    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <Alert color="info" className="mb-5 alert--colored">
          <div>
            If you do not want to be listed in the community, you can opt out by clicking the button below.<br />
            Adding your linkedin profile with other founders will help you increase your reputation in the community.
          </div>
        </Alert>
        <div className="margin10">
          <span className="material-form__label"><h5>Your LinkedIn Profile</h5></span>
          <Field
            name="founderLinkedin"
            component={renderTextField}
            type="text"
          />
        </div>
        <Row className="mb-3">
          <Col xs={8}>
            List me in the founder community
          </Col>
          <Col xs={4}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="community"
                  component={renderRadioButtonField}
                  label="Yes"
                  radioValue="1"
                  defaultChecked={defaultChecked(initialValues.community, 1)}
                />
                <Field
                  name="community"
                  component={renderRadioButtonField}
                  label="No"
                  radioValue="0"
                  defaultChecked={defaultChecked(initialValues.community, 0)}
                />
              </div>
            </div>
          </Col>
        </Row>
        <ButtonToolbar className="form__button-toolbar">
          <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
            Save
            <ChevronRightIcon />
          </Button>
          <Button className="rounded mt-5" type="button" onClick={reset} disabled={pristine || submitting}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}


export default reduxForm({
  form: 'founder_community_form', // a unique identifier for this form
  validate,
})(FounderCommunity);
