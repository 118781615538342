import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import ChevronRight from 'mdi-react/ChevronRightIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import PropTypes from 'prop-types';
import { Field, getFormValues, reduxForm } from 'redux-form';
import RemoveIcon from 'mdi-react/CloseIcon';
import TextField from '@material-ui/core/TextField';
import { Prompt } from 'react-router';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import { connect } from 'react-redux';
import renderTextFieldBasic from '../../../../shared/components/form/TextFieldBasic';
import validate from '../../formValidate/linkedinTemplates';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field template-content"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rows={2}
      rowsMax={5}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    >
      {children}
    </TextField>
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

class LinkedinTemplatesForm extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    valuesForm: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      templates: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        delay: PropTypes.number,
        id: PropTypes.number,
        position: PropTypes.number,
      })),
    }).isRequired,
  };

  static defaultProps = {
    valuesForm: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      nbEmails: 2,
    };

    this.incrementEmail = this.incrementEmail.bind();
    this.decrementEmail = this.decrementEmail.bind();
  }

  componentDidMount() {
    const { initialValues } = this.props;
    this.setState({ nbEmails: initialValues.templates.length });
  }

  renderTemplates = (initialValues) => {
    const { nbEmails } = this.state;
    const followUps = [];

    for (let i = 0; i < nbEmails; i += 1) {
      let button = null;
      if (i > 0) {
        button = (
          <button
            type="button"
            className="btn__remove__email"
            onClick={() => this.decrementEmail(i)}
          >
            <RemoveIcon size={24} />
          </button>
        );
      }
      followUps.push(this.renderTemplateField(
        initialValues, button, i,
      ));
    }
    return followUps;
  }

  renderTemplateField = (value, button, i) => (
    <div key={i} className="mt-4 pt-4 mt-xl-3 wizard__form__email linkedin wizard__form__email-added">
      {button}
      <div className="form__form-group">
        <div className="d-flex flex-row align-items-center">
          <span className={`wizard__badge ${i % 2 === 0 ? 'wizard__badge-violet' : 'wizard__badge-green'}`}>
            Message 0{i + 1}
          </span>
        </div>
      </div>
      <div className="form__form-group initial-field">
        <Field
          component={renderTextField}
          id={0}
          name={`templates[${i}].content`}
          multiline
          i={i}
          value={value}
        />
      </div>
      <div className="day__followup__field-wrapper d-flex flex-row align-items-center justify-content-between">
        {i > 0
        && (
          <div
            className="form__form-group-field day__followup__field form__form-group
                      mb-0 w-auto d-flex flex-row align-items-center"
          >
            <span className="form__form-group-label">Day till follow up</span>
            <Field
              name={`templates[${i}].delay`}
              component={renderTextFieldBasic}
              type="number"
              placeholder="in Days"
            />
          </div>
        )}
      </div>
    </div>
  );

  incrementEmail = () => {
    const { nbEmails } = this.state;

    // eslint-disable-next-line react/no-unused-state
    this.setState({ nbEmails: nbEmails + 1 });
    // this.props.dispatch(change('wizard', `templates[${nbEmails + 1}].delay`, 8));
  }

  decrementEmail = (index) => {
    const { props } = this;
    const { nbEmails } = this.state;
    const { valuesForm } = this.props;
    valuesForm.templates.splice(index, 1);

    props.change('valuesForm', undefined);

    if (nbEmails > 0) {
      this.setState({ nbEmails: nbEmails - 1 });
    }
  }

  render = () => {
    const { handleSubmit, initialValues, cancel } = this.props;
    const { nbEmails } = this.state;

    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <Prompt
          when={nbEmails > 0}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="wizard__form__body pl-5 pr-5">
          <div className="wizard__form__emails d-flex flex-row align-items-center flex-wrap">
            {this.renderTemplates(initialValues)}
            <div className="wizard__form__followup linkedin mt-5">
              <button
                id="followup-button"
                type="button"
                className="wizard__form__followup-add d-flex flex-row align-items-center justify-content-center"
                onClick={() => this.incrementEmail()}
              >
                <PlusIcon /> Add Follow up
              </button>
            </div>
          </div>
          <ButtonToolbar
            className="form__button-toolbar wizard__toolbar"
          >
            <Button
              color="primary"
              type="button"
              className="btn btn-primary icon rounded icon--left float-left"
              onClick={cancel}
            >
              <p>
                <ChevronLeftIcon />
                Cancel
              </p>
            </Button>
            <button
              type="submit"
              className="btn btn-primary icon icon--right rounded float-right"
            >
              <p>
                Save Templates
                <ChevronRight />
              </p>
            </button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

// eslint-disable-next-line no-class-assign
LinkedinTemplatesForm = connect(state => ({
  valuesForm: getFormValues('linkedin_templates_form')(state),
}))(LinkedinTemplatesForm);

export default reduxForm({
  form: 'linkedin_templates_form', // a unique identifier for this form
  validate,
})(LinkedinTemplatesForm);
