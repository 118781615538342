import React, { PureComponent } from 'react';
import {
  Col, Container, Row, Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PlusIcon from 'mdi-react/PlusIcon';
import LinksList from './components/LinksList/LinksList';
import VisitorsList from './components/Visitors/VisitorsList';
import LinkModal from './components/Add/LinkModal';
import Modal from '../../shared/components/modals/Modal';
import ModalNotActiveStartup from '../../shared/components/modals/ModalNotActiveStartup';


class LinksPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultLink: {
        id: null,
        name: '',
        email: true,
        protected: false,
        password: '',
        deck: true,
        financials: true,
        materials: true,
        active: true,
      },
      modal: false,
      modalUpgrade: false,
      reload: false,
      modalNotActiveStartup: false,
    };
  }

  componentDidMount() {
    this.retrieveUserName(); // Is this really necessary?
  }

  retrieveUserName = () => {
    const userStorage = JSON.parse(localStorage.getItem('user'));

    if (userStorage !== null) {
      if (!userStorage.appSubmitted) {
        this.setState({ modalNotActiveStartup: true });
      }
    } else {
      // Should never be called
      // eslint-disable-next-line no-alert
      alert('Your session expired, please log in again.'
        + ' If you are unable to login, please contact support: contact@angelspartners.com');
      window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/logout`;
    }
  };

  toggleModal = (lnk) => {
    const { defaultLink } = this.state;
    this.setState(prevState => ({ modal: !prevState.modal, link: lnk || defaultLink }));
  };

  toggleModalUpgrade = () => {
    const { modalUpgrade } = this.state;
    setTimeout(() => { this.setState({ modalUpgrade: !modalUpgrade }); }, 500);
  };

  toggleModalNotActiveStartup = () => {
    const { modalNotActiveStartup } = this.state;
    this.setState({ modalNotActiveStartup: !modalNotActiveStartup });
  };

  toggleReload = () => {
    this.setState(prevState => ({ reload: !prevState.reload }));
  };

  render = () => {
    const {
      modal, link, reload, defaultLink, modalUpgrade, modalNotActiveStartup,
    } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Tracking Links</h3>
          </Col>
          <Col xs={12} md={9} xl={7}>
            <p className="page-description">
              <strong>Stay in control of your documents & gauge investor interest</strong><br />
              Share your tracking links with investors from the investors database, outside of Angels Partners,
              and monitor traction and engagement on your summary, deck and financials.
              You can gauge an investor&#39;s interest and decide to follow up later.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={11} className="mb-4 mt-5 mt-xl-4">
            <Button
              className="btn btn-primary rounded icon float-right"
              color="primary"
              onClick={() => this.toggleModal(null)}
            >
              <p>
                New Link
                <PlusIcon />
              </p>
            </Button>
          </Col>
        </Row>
        <LinksList
          modal={this.toggleModal}
          reload={reload}
          toggleReload={this.toggleReload}
        />
        <VisitorsList />
        <LinkModal
          initialValues={link || defaultLink}
          displayed={modal}
          toggleModal={() => this.toggleModal()}
          toggleModalUpgrade={() => this.toggleModalUpgrade()}
          reload={this.toggleReload}
        />
        <Modal
          color="primary"
          title="Upgrade Required!"
          colored
          displayed={modalUpgrade}
          toggleModal={() => this.toggleModalUpgrade()}
          message={`Please upgrade your account to one of our paid plans if you wish to create more links.
           You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
           Pricing Page</a>.`}
        />
        <ModalNotActiveStartup
          color="primary"
          title="Fill Out Your Profile"
          colored
          displayed={modalNotActiveStartup}
          toggleModal={this.toggleModalNotActiveStartup}
          message={`
            You need to fill out your startup application first before you can create your first Tracking Link.
            <br /><br />
            Share your tracking links with investors listed in the investors database,
            and <strong>monitor traction and engagement</strong>.
            <br /><br />
            You can <strong>gauge an investor&#39;s interest</strong> and decide to follow up with data analytics, such
            as, click rate and read receipts on your profile and on all of your documents.
          `}
        />
      </Container>
    );
  };
}

export default withRouter(LinksPage);
