import React, { PureComponent } from 'react';
import {
  Col, Container, Row, Progress,
} from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import ProfileBasic from '../../shared/components/ProfileSidebar';
import ProfileTabs from './components/ProfileTabs';
import Modal from '../../shared/components/modals/ModalNotActiveStartup';
import CompletionInfoNotification from '../../shared/components/notifications/CompletionInfoNotification';
import ModalCancelSubscription from '../../shared/components/modals/ModalCancelSubscription';
// eslint-disable-next-line import/no-named-as-default
import ModalFeedback from '../../shared/components/modals/wizardFeedbackForm/ModalFeedback';

class Profile extends PureComponent {
  state = {
    startup: {},
    profile: {},
    loading: true,
    loaded: false,
    modal: false,
    redirect: null,
    modalCancelSubscription: false,
    modalFeedback: false,
  };

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user === null) {
      this.setRedirect('/');
    } if (user.appSubmitted) {
      this.getMyInfo();
    } else {
      window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/create/startup`;
    }
  };

  setRedirect = (redirect) => {
    this.setState({
      redirect,
    }, () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  };

  getMyInfo = () => {
    axios.get('startup/myProfile')
      .then((response) => {
        const startup = {
          name: response.data.startup,
          currency: response.data.currency,
          logo: response.data.logo,
          location_city: response.data.location_city,
          location_country: response.data.location_country,
          shortDescription: response.data.short_description,
          description: response.data.description,
          incorporated: response.data.incorporated,
          team: response.data.team,
          market: response.data.market,
          traction: response.data.traction,
          raised: response.data.raised,
          raising: response.data.raising,
          revenues: response.data.revenues,
          valuation: response.data.valuation,
          updates: response.data.updates,
          rounds: response.data.rounds,
          minimum: response.data.minimum,
          dateClose: response.data.dateClose,
          deck: response.data.deck,
          deckTimestamp: response.data.deckTimestamp,
          financials: response.data.financials,
          financialsTimestamp: response.data.financialsTimestamp,
          url: response.data.url,
          video: response.data.video,
          materials: response.data.materials,
          linkToMaterials: response.data.linkToMaterials,
          completion: response.data.completion,
          completionInfo: response.data.completionInfo,
          appSubmitted: response.data.appSubmitted,
          pictures: [],
          locationCity: response.data.location_city,
          locationCountry: response.data.location_country_id,
          category: response.data.category,
          secondaryCategory: response.data.secondary_category,
          stage: response.data.stage,
          numberOfStaff: response.data.numberOfStaff,
          cac: response.data.cac,
          ltv: response.data.ltv,
          startupRound: response.data.startupRound,
          netProfit: response.data.netProfit,
          nbCustomers: response.data.nbCustomers,
        };

        const profile = {
          id: response.data.id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          pings: response.data.pings,
          pingsUsed: response.data.pingsUsed,
          account: response.data.planType,
          dateTrialEnd: response.data.dateTrialEnd,
          datePlanEnd: response.data.datePlanEnd,
          dateTrialCancelled: response.data.dateTrialCancelled,
          willRenew: response.data.willRenew,
          isCustomer: response.data.isCustomer,
        };
        this.setState({ startup, profile, loading: false });
        setTimeout(() => this.setState({ loaded: true }), 500);
      });

    this.getUser();
  };

  getUser = () => {
    axios.get('/user')
      .then((response) => {
        if (typeof response.data === 'object') {
          // ALL GOOD
          localStorage.setItem('user', JSON.stringify(response.data));
        } else {
          // EVERYTHING ELSE
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/logout`;
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Your session expired. Please log in again'
          + ' If you are unable to log in, please contact support: contact@angelspartners.com');
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/logout`;
      });
  }

  updateLogo = (logo) => {
    const { startup } = this.state;

    const updatedStartup = {
      ...startup, logo,
    };

    // Update the page
    this.getMyInfo();
    // Update the State
    this.setState({ startup: updatedStartup });
  };

  updateInfo = (shortDescription) => {
    const { startup } = this.state;

    const updatedStartup = {
      ...startup, shortDescription,
    };

    // Update the page
    this.getMyInfo();
    // Update the State
    this.setState({ startup: updatedStartup });
  };

  updateVideo = (video) => {
    const { startup } = this.state;

    const updatedStartup = {
      ...startup, video,
    };

    // Update the page
    this.getMyInfo();
    // Update the State
    this.setState({ startup: updatedStartup });
  };

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  }

  toggleModalCancelSubscription = () => {
    const { modalCancelSubscription } = this.state;
    this.setState({ modalCancelSubscription: !modalCancelSubscription });
  };

  toggleModalFeedback = () => {
    const { modalFeedback } = this.state;
    this.setState({ modalFeedback: !modalFeedback });
  };

  removeDeck = () => {
    const { startup } = this.state;
    const deck = null;

    const updatedStartup = {
      ...startup, deck,
    };

    // Update the page
    this.getMyInfo();
    // Update the State
    this.setState({ startup: updatedStartup });
  }

  removeFinancials = () => {
    const { startup } = this.state;
    const financials = null;

    const updatedStartup = {
      ...startup, financials,
    };

    // Update the page
    this.getMyInfo();
    // Update the State
    this.setState({ startup: updatedStartup });
  }

  render = () => {
    const {
      startup, profile, loaded, loading, modal, redirect, modalCancelSubscription, modalFeedback,
    } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));

    return (
      <Container>
        {redirect !== null && <Redirect to={redirect} />}
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Company Information</h3>
          </Col>
          <Col xs={12} md={9} lg={6} xl={5}>
            <div className="page-description">
              <div className="progress-wrap progress-wrap--violet">
                <Progress value={startup.completion} />
              </div>
              <strong>{startup.completion}% Profile Completion</strong><br />
              The higher your score, the higher you rank when investors are looking for startups.
            </div>
          </Col>
        </Row>
        {startup.completion < 100
          && (
            <CompletionInfoNotification
              appSubmitted={user.appSubmitted}
              completion={startup.completion}
              completionInfo={startup.completionInfo}
            />
          )}
        {!loaded
          ? (
            <div className={`load${loading ? '' : ' loaded'} inload`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          )
          : (
            <Row className="company">
              <Col xs={12} xl={8} className="order-1 order-xl-0 p-0 p-lg-3">
                <ProfileTabs
                  team={startup.team}
                  numberOfStaff={startup.numberOfStaff}
                  currency={startup.currency}
                  market={startup.market}
                  traction={startup.traction}
                  shortDescription={startup.shortDescription}
                  description={startup.description}
                  raised={startup.raised}
                  raising={startup.raising}
                  revenues={startup.revenues}
                  valuation={startup.valuation}
                  rounds={startup.rounds}
                  firstName={profile.firstName}
                  lastName={profile.lastName}
                  email={profile.email}
                  logo={startup.logo}
                  pictures={startup.pictures}
                  updates={startup.updates}
                  minimum={startup.minimum}
                  dateClose={startup.dateClose}
                  incorporated={startup.incorporated}
                  locationCity={startup.locationCity}
                  locationCountry={startup.locationCountry}
                  category={startup.category}
                  secondaryCategory={startup.secondaryCategory}
                  stage={startup.stage}
                  deck={startup.deck}
                  deckTimestamp={startup.deckTimestamp}
                  financials={startup.financials}
                  financialsTimestamp={startup.financialsTimestamp}
                  url={startup.url}
                  video={startup.video}
                  materials={startup.materials}
                  signature={startup.signature}
                  linkToMaterials={startup.linkToMaterials}
                  ltv={startup.ltv}
                  cac={startup.cac}
                  netProfit={startup.netProfit}
                  nbCustomers={startup.nbCustomers}
                  startupRound={startup.startupRound}
                  notificationView={profile.view}
                  notificationDeck={profile.deck}
                  notificationMessage={profile.message}
                  notificationDeclined={profile.declined}
                  notificationBlog={profile.blog}
                  notificationNewsletter={profile.newsletter}
                  onLogoUpdated={logo => this.updateLogo(logo)}
                  onInfoUpdated={info => this.updateInfo(info)}
                  onVideoUpdated={info => this.updateVideo(info)}
                  removeDeck={() => this.removeDeck()}
                  removeFinancials={() => this.removeFinancials()}
                  getMyInfo={() => this.getMyInfo()}
                />
              </Col>
              <Col xs={12} xl={4} className="order-0 order-xl-1">
                <ProfileBasic
                  id={profile.id}
                  name={startup.name}
                  logo={startup.logo}
                  locationCity={startup.location_city}
                  locationCountry={startup.location_country}
                  pings={profile.pings}
                  pingsUsed={profile.pingsUsed}
                  account={profile.account}
                  dateTrialEnd={profile.dateTrialEnd}
                  datePlanEnd={profile.datePlanEnd}
                  dateTrialCancelled={profile.dateTrialCancelled}
                  willRenew={profile.willRenew}
                  isCustomer={profile.isCustomer}
                  cancelSubscriptionToggle={this.toggleModalCancelSubscription}
                />
              </Col>
            </Row>
          )
        }
        <Modal
          color="primary"
          title="Fill Out Your Profile"
          colored
          displayed={modal}
          toggleModal={() => this.toggleModal()}
          message={`You need to fill out your startup application first.<br><br>
         Submit your application and be listed on our platform <strong>to connect with hundreds of potential
         investors</strong>, actively prospecting and investing on the platform.<br><br>
         We review every application submitted within 1 to 3 days.<br><br>
         <strong>You will be redirected to the startup creation page now.</strong>`}
        />
        <ModalCancelSubscription
          displayed={modalCancelSubscription}
          toggleModal={this.toggleModalCancelSubscription}
          toggleModalFeedback={this.toggleModalFeedback}
          getMyInfo={this.getMyInfo}
        />
        <ModalFeedback
          displayed={modalFeedback}
          toggleModal={this.toggleModalFeedback}
        />
      </Container>
    );
  }
}

export default connect(state => ({
  user: state.user,
}))(Profile);
