import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, NavLink, Row, Card,
} from 'reactstrap';
import FileExcelIcon from 'mdi-react/FileExcelIcon';
import UserAddOutlineIcon from 'mdi-react/UserAddOutlineIcon';
import UserRemoveOutlineIcon from 'mdi-react/UserRemoveOutlineIcon';
import MailIcon from 'mdi-react/EmailOutlineIcon';

class Action extends Component {
  static propTypes = {
    toggleStep: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  renderCardAction = (value) => {
    if (value === 'import') {
      return (
        <Row>
          <Col xs={2} className="ml-auto mr-0 p-0 flex">
            <div className="import-icons">
              <UserAddOutlineIcon />
            </div>
          </Col>
          <Col xs={10}>
            <small>Investors</small>
            <h3><strong>IMPORT</strong></h3>
          </Col>
        </Row>
      );
    } if (value === 'blacklist') {
      return (
        <Row>
          <Col xs={2} className="ml-auto mr-0 p-0 flex">
            <div className="import-icons">
              <UserRemoveOutlineIcon />
            </div>
          </Col>
          <Col xs={10}>
            <small>Investors</small>
            <h3><strong>BLACKLIST</strong></h3>
          </Col>
        </Row>
      );
    } if (value === 'csv') {
      return (
        <Row>
          <Col xs={2} className="ml-auto mr-0 p-0 flex">
            <div className="import-icons green">
              <FileExcelIcon />
            </div>
          </Col>
          <Col xs={10}>
            <small>UPLOAD</small>
            <h3><strong>CSV</strong></h3>
          </Col>
        </Row>
      );
    } if (value === 'mail') {
      return (
        <Row>
          <Col xs={2} className="ml-auto mr-0 p-0 flex">
            <div className="import-icons">
              <MailIcon />
            </div>
          </Col>
          <Col xs={10}>
            <small>ENTER</small>
            <h3><strong>Emails Manually</strong></h3>
          </Col>
        </Row>
      );
    }
    return '';
  }

  render() {
    const { toggleStep, value } = this.props;

    return (
      <NavLink
        className="p-0 cursor-pointer"
        onClick={() => {
          toggleStep(value);
        }}
      >
        <Card className="shadow mb-3 p-3 card-action">
          { this.renderCardAction(value) }
        </Card>
      </NavLink>
    );
  }
}

export default Action;
