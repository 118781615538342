/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CardBody, Button } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import StarsIcon from 'mdi-react/StarsIcon';
import ChatIcon from 'mdi-react/CommentMultipleOutlineIcon';
import DeclinedIcon from 'mdi-react/CloseCircleIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

const defaultLogo = `${process.env.PUBLIC_URL}/img/nologo.jpg`;

export default class StartupCard extends Component {
  static defaultProps = {
    startup: [],
    connect: null,
    decline: null,
    addFavorites: null,
    removeFavorites: null,
  };

  shouldComponentUpdate(nextProps) {
    const { startup } = this.props;
    return nextProps.startup !== startup;
  }

  render() {
    const {
      addFavorites, removeFavorites, connect, startup, decline,
    } = this.props;

    function renderFavButton() {
      if (startup.favorite && !startup.declined && !startup.pinged && !startup.accepted) {
        return (
          <Button
            className="icon rounded investor-item__icon-centered icon-only mr-0 m-2"
            color="warning"
            onMouseDown={e => removeFavorites(e)}
            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { removeFavorites(e); } }}
          >
            <p><StarsIcon /></p>
          </Button>
        );
      } if (startup.adding) {
        return (
          <Button className="icon rounded investor-item__icon-centered icon-only mr-0 m-2" color="warning" outline>
            <p><LoadingIcon className="mdi-icon-spinning" /></p>
          </Button>
        );
      }
      if (!startup.declined && !startup.pinged && !startup.accepted && !startup.conversation_id) {
        return (
          <Button
            className="icon rounded investor-item__icon-centered icon-only mr-0 m-2"
            color="primary"
            onMouseDown={e => addFavorites(e)}
            onKeyUp={(e) => {
              if (e.keyCode === 13 || e.keyCode === 32) {
                addFavorites(e);
              }
            }}
          >
            <p><StarsIcon /></p>
          </Button>
        );
      }
      return null;
    }

    function renderPingButton() {
      if (startup.accepted && startup.conversation_id) {
        return (
          <Button className="icon icon--right rounded mr-0 m-2" color="action">
            <Link to={startup.conversation_id ? `/inbox/${startup.conversation_id}` : '/inbox'}>
              <p>Go To Chat <ChatIcon /></p>
            </Link>
          </Button>
        );
      } if (startup.accepted) {
        return (
          <Button className="icon icon--right rounded mr-0 m-2" color="action">
            <p>Connected <EmailOutlineIcon /></p>
          </Button>
        );
      } if (startup.pinging) {
        return (
          <Button className="icon icon--right rounded mr-0 m-2" color="warning" outline>
            <p>Connecting <LoadingIcon className="mdi-icon-spinning" /></p>
          </Button>
        );
      }
      if (!startup.declined && !startup.declining) {
        return (
          <Button
            className="icon icon--right rounded mr-0 m-2"
            color="primary"
            outline
            onMouseDown={e => connect(e)}
            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { connect(e); } }}
          >
            <p>
              Contact
              <EmailOutlineIcon />
            </p>
          </Button>
        );
      }
      return null;
    }

    function renderDescription() {
      if (startup.short_description.trim().length > 120) {
        return (
          <div className="investor-item__info">
            <p className="investor-item__description text-muted text-justify" id={`description${startup.id}`}>
              {startup.short_description.substring(0, 117)} ...
            </p>
          </div>
        );
      }
      return (
        <div className="investor-item__info">
          <p className="investor-item__description text-muted text-justify">
            {startup.short_description}
          </p>
        </div>
      );
    }

    function renderDeclineButton() {
      if (startup.declined) {
        return (
          <Button className="icon icon--right rounded mr-0 m-2" color="danger">
            <p>Declined <DeclinedIcon /></p>
          </Button>
        );
      } if (startup.declining) {
        return (
          <Button className="icon icon--right rounded mr-0 m-2" color="danger" outline>
            Declining
            <LoadingIcon className="mdi-icon-spinning" />
          </Button>
        );
      }
      if (startup.conversation_id && !startup.accepted && !startup.declined) {
        return (
          <Button
            className="icon icon-only rounded mr-0 m-2"
            color="danger"
            onMouseDown={e => decline(e)}
            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { decline(e); } }}
            id="Decline"
          >
            <p>
              <DeclinedIcon />
            </p>
            <UncontrolledTooltip placement="top" target="Decline">
              Decline this invitation to connect
            </UncontrolledTooltip>
          </Button>
        );
      }
      return null;
    }

    function nFormatter(num) {
      if (num >= 1000000000) {
        return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}B`;
      }
      if (num >= 1000000) {
        return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
      }
      if (num >= 1000) {
        return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
      }
      return num;
    }

    return (
      <div className="col-sm-12 col-md-6 col-lg-4">
        <CardBody className="investor-item col-md-12 p-0" key={startup.id}>
          <Link className="investor-item__link" to={`/startup/${startup.id}`}>
            <div className="investor-item__img-wrap">
              {startup.new ? <div className="investor-item__label-new">New</div> : ''}
              {/* eslint-disable-next-line max-len */}
              <img
                className="startup-item__img"
                src={startup.logo
                  ? process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_LOGOS_PATH + startup.logo
                  : defaultLogo}
                onError={(e) => { e.target.onerror = null; e.target.src = defaultLogo; }}
                alt="startup-item-img"
              />
            </div>
            <div className="investor-item__info">
              <h4 className="investor-item__title">{startup.name}</h4>
              <h5 className="investor-item__location ellipsis" id={`location${startup.id}`}>
                <MapMarkerIcon />
                {startup.location_city} | {startup.location_country}
              </h5>
            </div>
            { renderDescription() }
          </Link>
          <div className="investor-item__actions">
            { renderPingButton() }
            { renderDeclineButton() }
            { renderFavButton() }
          </div>
          <Link className="investor-item__link-grey" to={`/startup/${startup.id}`}>
            <div className="investor-item__investment-range">
              <p className="investor-item__values">Raising ${nFormatter(startup.raising)}
                &nbsp; - &nbsp;
                Min Ticket ${nFormatter(startup.minimum)}
              </p>
            </div>
          </Link>
        </CardBody>
      </div>
    );
  }
}

StartupCard.propTypes = {
  startup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    short_description: PropTypes.string,
    logo: PropTypes.string,
    location_city: PropTypes.string,
    location_country: PropTypes.string,
    raising: PropTypes.number,
    raised: PropTypes.number,
    minimum: PropTypes.number,
    new: PropTypes.bool,
    accepted: PropTypes.bool,
    declined: PropTypes.bool,
    conversation_id: PropTypes.number,
    pinging: PropTypes.bool,
    favorite: PropTypes.bool,
    adding: PropTypes.bool,
    declining: PropTypes.bool,
    web_plan: PropTypes.bool,
  }),
  connect: PropTypes.func,
  decline: PropTypes.func,
  addFavorites: PropTypes.func,
  removeFavorites: PropTypes.func,
};
