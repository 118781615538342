import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const CoInvestorItem = ({
  fullName, company, picture, id,
}) => (
  // eslint-disable-next-line max-len
  <Link to={id ? `/investor-db/${id}` : '#'} className="coinvestor__link d-flex flex-row align-items-center px-2 py-1">
    <img
      className="coinvestor__img rounded-circle img-thumbnail"
      src={picture ? process.env.REACT_APP_AWS_PATH + picture : userIcon}
      alt=""
    />
    <h5 className="ml-3">{fullName} &nbsp;{company && company !== '' && company !== ' ' && '|'}&nbsp; {company}</h5>
  </Link>
);

export default CoInvestorItem;

CoInvestorItem.defaultProps = {
  id: null,
  fullName: null,
  picture: null,
  company: null,
};

export const coInvestorProps = {
  id: PropTypes.number,
  fullName: PropTypes.string,
  picture: PropTypes.string,
  company: PropTypes.string,
};

CoInvestorItem.propTypes = coInvestorProps;
