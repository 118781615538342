/* eslint-disable */
const validate = (values) => {

  const errors = {};
  if (!values.report) {
    errors.message = 'Please select a reason why you are reporting this investor';
  }
  if (values.report && values.report === 'Other' && (!values.message || values.message === '')) {
    errors.message = 'Please tell us why you are reporting this investor';
  }

  return errors;
};

export default validate;
