import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Row, Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
// import LinkIcon from 'mdi-react/LinkIcon';
// import SendIcon from 'mdi-react/SendIcon';

export default class ProfileTabs extends PureComponent {
  static defaultProps = {
    description: null,
    expertise: null,
    investmentMax: null,
    investmentMin: null,
    stages: null,
    categories: null,
    region: null,
    // email: null,
    // linkedin: null,
    // accepted: null,
  };

  goToAnchor = (anchor) => {
    const loc = document.location.toString().split('#')[0];
    document.location = `${loc}#${anchor}`;
    return false;
  };

  render() {
    const {
      description, expertise, investmentMax, investmentMin,
      stages, categories, region,
      // email, linkedin, accepted,
    } = this.props;
    // const { user } = this.state;

    return (
      <Row className="profile__card">
        <Col xs={12} lg={6}>
          <Row>
            <Card className="table-shadowed">
              <CardBody>
                <Table responsive>
                  <tbody>
                    {categories
                    && (
                      <tr>
                        <td>Industries</td>
                        <td>{categories}</td>
                      </tr>
                    )}
                    {stages
                    && (
                      <tr>
                        <td>Stages</td>
                        <td>{stages}</td>
                      </tr>
                    )}
                    {region
                    && (
                      <tr>
                        <td>Investing</td>
                        <td>{region}</td>
                      </tr>
                    )}
                    {investmentMin && investmentMax
                      ? (
                        <tr>
                          <td>Investment Range</td>
                          <td>
                            <CurrencyFormat
                              value={investmentMin}
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                            &nbsp;-&nbsp;
                            <CurrencyFormat
                              value={investmentMax}
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                        </tr>
                      ) : null}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Row>
          {expertise
          && (
            <Row>
              <Col xs={12}>
                <Card className="bg-transparent">
                  <CardBody>
                    <h4>Expertise</h4>
                    <p className="text-justify text-muted" dangerouslySetInnerHTML={{ __html: expertise }} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
        <Col xs={12} lg={6}>
          <Row>
            <Card className="bg-transparent">
              <CardBody>
                <h4>About Me</h4>
                <p className="text-justify text-muted" dangerouslySetInnerHTML={{ __html: description }} />
              </CardBody>
            </Card>
          </Row>
          {/* <Row> */}
          {/*  <Card> */}
          {/*    <CardBody> */}
          {/*      <Table responsive> */}
          {/*        <tbody> */}
          {/*          <tr> */}
          {/*            <td>Linkedin</td> */}
          {/*            <td> */}
          {/*              {!accepted */}
          {/*                && 'You must connect with this investor first to see his/her LinkedIn' */}
          {/*              } */}
          {/*              {accepted && !linkedin */}
          {/*              && 'This investor did not provide his/her LinkedIn information' */}
          {/*              } */}
          {/*              {accepted && linkedin && !user.isCustomer */}
          {/*              && 'You must subscribe to one of our paid plan to see this investor\'s details' */}
          {/*              } */}
          {/*              {accepted && linkedin && user.isCustomer */}
          {/*              && ( */}
          {/*                <span> */}
          {/*                  {linkedin} */}
          {/*                  <a */}
          {/*                    className="project-member__btn btn btn-outline-primary btn-sm icon-only rounded" */}
          {/*                    href={linkedin} */}
          {/*                    target="_blank" */}
          {/*                    rel="noopener noreferrer" */}
          {/*                  ><LinkIcon /> */}
          {/*                  </a> */}
          {/*                </span> */}
          {/*              )} */}
          {/*            </td> */}
          {/*          </tr> */}
          {/*          <tr> */}
          {/*            <td>Email</td> */}
          {/*            <td> */}
          {/*              {accepted */}
          {/*                ? email || 'This investor chose not to display his/her email publicly.' */}
          {/*                : 'You must connect with this investor first to see his/her contact information' */}
          {/*              } */}
          {/*              {accepted && email && !user.isCustomer */}
          {/*                && 'You must subscribe to one of our paid plan to see this investor\'s details' */}
          {/*              } */}
          {/*              {accepted && email && user.isCustomer */}
          {/*              && ( */}
          {/*                <a */}
          {/*                  className="project-member__btn btn btn-primary btn-sm icon icon--right rounded" */}
          {/*                  href={`mailto:${email}`} */}
          {/*                  target="_blank" */}
          {/*                  rel="noopener noreferrer" */}
          {/*                > */}
          {/*                  Email this investor */}
          {/*                  <SendIcon /> */}
          {/*                </a> */}
          {/*              )} */}
          {/*            </td> */}
          {/*          </tr> */}
          {/*        </tbody> */}
          {/*      </Table> */}
          {/*    </CardBody> */}
          {/*  </Card> */}
          {/* </Row> */}
        </Col>
      </Row>
    );
  }
}

ProfileTabs.propTypes = {
  description: PropTypes.string,
  expertise: PropTypes.string,
  investmentMax: PropTypes.number,
  investmentMin: PropTypes.number,
  stages: PropTypes.string,
  categories: PropTypes.string,
  region: PropTypes.string,
  // linkedin: PropTypes.string,
  // email: PropTypes.string,
  // accepted: PropTypes.bool,
};
