/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Table, Button,
} from 'reactstrap';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import InfoIcon from 'mdi-react/InformationOutlineIcon';
import PauseIcon from 'mdi-react/PauseIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import moment from 'moment';
import StopIcon from 'mdi-react/StopIcon';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

export default class InvestorCard extends PureComponent {
  state = {
    extend: false,
  }

  toggleCard = () => {
    const { extend } = this.state;
    this.setState({ extend: !extend });
  }

  renderInvestorConnections = connections => connections.map(connection => (
    <tr
      key={connection.id}
    >
      <td className="td-campaign" colSpan={2}>
        <a href={connection.link} target="_blank" rel="noopener noreferrer">
          <div className="d-flex">
            <img
              src={
                connection.picture
                  ? process.env.REACT_APP_AWS_ROOT
                  + process.env.REACT_APP_AWS_LINKEDININTRO_PATH
                  + connection.picture
                  : userIcon
              }
              onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
              alt="Connection"
              className="card-crm-pic"
            />
            {connection.name && connection.name.slice(0, 20)}
            {connection.name && connection.name.length > 20 && '..'}
            <br />is {connections.length > 1 ? 'an' : 'the'} intro path
          </div>
        </a>
      </td>
    </tr>
  ))

  render() {
    const { investor } = this.props;
    const { extend } = this.state;

    const extendState = extend ? 'extend' : '';

    return (
      <Card
        key={investor.id}
      >
        <CardBody
          /* eslint-disable-next-line max-len */
          className={`card-crm ${investor.replied ? 'extra-margin' : ''} ${extendState}`}
          onClick={() => this.toggleCard()}
        >
          <div className="card-mini-actions">
            <ChevronDownIcon className="chevron-down" />
          </div>
          <img
            src={
              investor.picture
                ? process.env.REACT_APP_AWS_ROOT
                + process.env.REACT_APP_AWS_INVESTORS_PATH
                + investor.picture
                : userIcon
            }
            onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
            alt="Investor"
            className="card-crm-pic"
          />
          <h6>
            {investor.name && investor.name.slice(0, 25)} {investor.name && investor.name.length > 25 && '..'}
          </h6>
          {investor.toSend > 0 && investor.sent > 0
            && (
              <span className="notification violet-bg notif-followup smaller">
                {investor.toSend} more followup{investor.toSend > 1 && 's'}
              </span>
            )}
          {investor.toSend > 0 && investor.sent === 0
            && (
              <span className="notification violet-bg notif-followup smaller">
                {investor.toSend} email{investor.toSend > 1 && 's'} scheduled
              </span>
            )}
          {investor.paused
          && (
            <div className="paused-icon" id={`TooltipPaused${investor.id}`}>
              <PauseIcon />
            </div>
          )}
          {!investor.nextEmail && !investor.paused
          && (
            <div className="paused-icon" id={`TooltipFinished${investor.id}`}>
              <StopIcon />
            </div>
          )}
          <div className="hide card-extra">
            <div className="hide extra-info">
              <Table className="info-table">
                <tbody>
                  {investor.lastEmail
                    ? (
                      <tr>
                        <td>
                          Contacted On
                        </td>
                        <td>
                          {investor.lastEmail ? moment(investor.lastEmail).format('MM/DD') : null}
                        </td>
                      </tr>
                    ) : null}
                  {investor.nextEmail && investor.toSend
                    && (
                      <tr>
                        <td>
                          {investor.lastEmail ? 'Next Follow up' : 'Will Be Contacted'}
                        </td>
                        <td>
                          {investor.nextEmail ? moment(investor.nextEmail).format('MM/DD') : null}
                        </td>
                      </tr>
                    )}
                  {!investor.nextEmail && investor.error && investor.error.length > 0
                    && (
                      <tr>
                        <td colSpan={2} className="text-center">
                          <small>{investor.error}</small>
                        </td>
                      </tr>
                    )
                  }
                  {this.renderInvestorConnections(investor.connections)}
                </tbody>
              </Table>
            </div>
            {extend
            && (
              <div className="hide card-actions">
                {/* <EmailIcon className="mail-action" id={`TooltipMail${investor.id}`} /> */}
                <Button
                  className="icon rounded icon-only btn-pause icon--blue"
                  color="primary"
                  outline
                  size="sm"
                  id={`TooltipInfo${investor.id}`}
                  onClick={() => {
                    window.open(`${window.location.origin}/investor-db/${investor.id}`, '_blank');
                  }}
                >
                  <InfoIcon
                    className="info-icon"
                  />
                </Button>
              </div>
            )}
          </div>
          {investor.replied === true
          && (
            <div className="replied">
              <div className="replied-third" id={`TooltipReplied${investor.id}`}>
                <img src="/img/replied.gif" alt="replied" className="replied-img" />
                <span className="notification absolute blue-bg">1</span>
              </div>
            </div>
          )}
        </CardBody>

        {extend
        && (
          <UncontrolledTooltip placement="bottom" target={`TooltipInfo${investor.id}`}>
            Click for more info
          </UncontrolledTooltip>
        )}
        {investor.replied === true
          ? (
            <UncontrolledTooltip placement="bottom" target={`TooltipReplied${investor.id}`}>
              This investor replied to your message.
            </UncontrolledTooltip>
          ) : ('')}
        {!investor.nextEmail && !investor.paused
          ? (
            <UncontrolledTooltip placement="bottom" target={`TooltipFinished${investor.id}`}>
              The campaign is over for this investor.
            </UncontrolledTooltip>
          ) : ('')}
        {!investor.toSend && !investor.stopping && investor.paused && extend
          ? (
            <UncontrolledTooltip placement="bottom" target={`TooltipPlay${investor.id}`}>
              Resume campaign for this investor.
            </UncontrolledTooltip>
          ) : ('')}
        {investor.paused
          ? (
            <UncontrolledTooltip placement="top" target={`TooltipPaused${investor.id}`}>
              This investor is on pause and will not be contacted.
            </UncontrolledTooltip>
          ) : ('')}
      </Card>
    );
  }
}

InvestorCard.propTypes = {
  investor: PropTypes.shape({
    id: PropTypes.number,
    contactId: PropTypes.number,
    name: PropTypes.string,
    connections: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
      picture: PropTypes.string,
    })),
    picture: PropTypes.string,
    campaign: PropTypes.string,
    lastEmail: PropTypes.string,
    nextEmail: PropTypes.string,
    replied: PropTypes.bool,
    sent: PropTypes.bool,
    toSend: PropTypes.number,
    stopping: PropTypes.bool,
  }).isRequired,
};
