/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import validate from '../../formValidate/updates';


const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      children={children}
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

class StartupUpdates extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
      updates: PropTypes.string,
    }).isRequired,
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (

      <form className="material-form" onSubmit={handleSubmit}>
        <div className="margin10">
          <span className="material-form__label"><h5>Updates</h5></span>
          <Field
            name="updates"
            component={renderTextField}
            type="text"
            multiline
            placeholder="Share updates on your recent progress, milestones and achievements"
          />
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
            Save
            <ChevronRightIcon />
          </Button>
          <Button className="rounded mt-5" type="button" onClick={reset} disabled={pristine || submitting}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}


export default reduxForm({
  form: 'startup_updates_form', // a unique identifier for this form
  validate,
})(StartupUpdates);
