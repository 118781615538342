/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button, Col, Row, Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CardTitle from 'reactstrap/lib/CardTitle';
import PlusIcon from 'mdi-react/PlusIcon';


function PartnershipsTab(props: { isCustomer: any, checkIsCustomer: any }) {
  const { isCustomer, checkIsCustomer } = props;
  return (
    <div className="resources">
      <CardTitle>
        <Row>
          <Col xs={12} xl={8}>
            <h3>Build solid partnerships with our list of documents</h3>
            <p className="text-muted mt-4 mb-2">
              Business is a competition. Competition is a business.
              As a startup, it may not seem like the competitive nature of the industry is a good thing for you.
              But actually, it is. Competition pushes you to be innovative, focused and persevering.
              To succeed, you will need to make partnerships with other businesses. Use our resources to set solid
              partnerships.
            </p>
          </Col>
        </Row>
      </CardTitle>
      <Table responsive hover>
        <tbody>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1WnC-ZR8garcDEAmEyjPpGk61F-4cHUS7J8LsWZS1ESs/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Mutual NDA
              </a>
            </td>
          </tr>
          <tr>
            <td>
              The employment agreement spells out the rules, rights and responsibilities for both the employer and
              the employee, and includes any special obligations undertaken that are unique in a specific hiring
              situation. (This is not legal advice. For informational purposes only).
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1WnC-ZR8garcDEAmEyjPpGk61F-4cHUS7J8LsWZS1ESs/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1mINmOq_kHzEzWc_jz3xMYez8ZYFzcYGzym0kjYtu0Ko/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Simple Joint Marketing Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A joint marketing agreement is a contract pursuant to which one or both of the parties will
              collaborate in order to promote the sale of product and service offerings of the other party. This
              article does not address the terms and conditions of sale of products and services to customers. (This
              is not legal advice. For informational purposes only).

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1mINmOq_kHzEzWc_jz3xMYez8ZYFzcYGzym0kjYtu0Ko/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1NaIzjbtaOS-cd0vifb45bGw4aDNf1dYTVC5qheHciSA/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Simple License Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A licensing agreement is a legal contract between two parties, known as the licensor and the licensee.
              In a typical licensing agreement, the licensor grants the licensee the right to produce and sell
              goods, apply a brand name or trademark, or use patented technology owned by the licensor. (This is not
              legal advice. For informational purposes only).

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1NaIzjbtaOS-cd0vifb45bGw4aDNf1dYTVC5qheHciSA/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1IOhpLrZrJFnGB7AcFMq1Q8YFAGAxOmTFNkx4uG9X_Zs/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Simple Non Compete Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A non-compete agreement is a contract between an employee and an employer in which the employee agrees
              not to enter into competition with the employer during or after employment. These legal contracts
              prevent employees from entering into markets or professions considered to be in direct competition
              with the employer. (This is not legal advice. For informational purposes only).

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1IOhpLrZrJFnGB7AcFMq1Q8YFAGAxOmTFNkx4uG9X_Zs/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1GMIgzREwzgFNikOLer-6ZYkuEfJjIXulKLLh0f5FfhY/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Simple Referral Fee Agreement
              </a>
            </td>
          </tr>
          <tr>
            <td>
              A referral fee agreement is between an affiliate that “refers” sales or services to a company in
              exchange for compensation. The fee paid to the affiliate is commonly a percentage of the total
              sale or a flat fee per transaction. (This is not legal advice. For informational purposes only).

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/document/d/1GMIgzREwzgFNikOLer-6ZYkuEfJjIXulKLLh0f5FfhY/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="primary" className="btn rounded icon mt-4">
                  Open Document
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1evd0UwOr1hgwJj-l30yQtW8eDMndfRt0ZiNYoIdJ0s4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                Strategic Partnership Priority Matrix
              </a>
            </td>
          </tr>
          <tr>
            <td>
              All companies need growth strategies that minimize risk while enhancing their competitive positions.
              This matrix outlines different strategic alliances to fuel your growth.

              {/* eslint-disable-next-line max-len */}
              <a href={isCustomer ? 'https://docs.google.com/spreadsheets/d/1evd0UwOr1hgwJj-l30yQtW8eDMndfRt0ZiNYoIdJ0s4/edit?usp=sharing' : '#'} onClick={checkIsCustomer}>
                <Button color="success" className="btn rounded icon mt-4">
                  Open Spreadsheet
                  <PlusIcon />
                </Button>
              </a>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

PartnershipsTab.PropsTypes = {
  isCustomer: PropTypes.bool.isRequired,
  checkIsCustomer: PropTypes.bool.isRequired,
};

export default PartnershipsTab;
