import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';

class ModalComponent extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
  };

  static defaultProps = {
    displayed: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  goToPricingPage = () => {
    window.open(`${process.env.REACT_APP_DOMAIN_URL}/pricing`, '_blank');
  };

  render() {
    const { modal } = this.state;

    return (
      <div>
        <Modal
          isOpen={modal}
          modalClassName="ltr-support"
          className="modal-dialog--primary modal-dialog--colored"
        >
          <div className="modal__header">
            <span className="lnr lnr-pushpin modal__title-icon" />
            <h4 className="text-modal  modal__title">Upgrade Required</h4>
          </div>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
          <div className="modal__body">
            <p>
              In order to continue using the platform, you need to subscribe to one of our free trial.
            </p>
            <p>
              There is no commitment and you can cancel at anytime.
            </p>
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_ok rounded" outline color="primary" onClick={this.goToPricingPage}>
              Start Free Trial
            </Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default connect()(ModalComponent);
