import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { Card } from '@material-ui/core';
import {
  Alert,
  Button, CardBody, Col, Row,
} from 'reactstrap';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import NotificationSystem from 'rc-notification';
import {
  FullWideNotification, BasicNotification,
} from '../../../shared/components/notifications/Notification';
import StepsBlock from './stepsBlock';
import ModalCreateStartup from './modals/ModalCreateStartup';
import ManagedUserCard from './ManagedUserCard';
import ModalFeedback from '../../../shared/components/modals/wizardFeedbackForm/ModalFeedback';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      managedUsers: [],
      error: null,
      modalCreateStartup: false,
      loading: true,
      loaded: false,
      user: this,
      userId: null,
    };
  }

  componentWillReceiveProps(newProps) {
    const { props } = this;
    // eslint-disable-next-line react/prop-types
    if (props.location.search !== newProps.location.search) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ props: newProps, users: [] }, () => this.getManagedUsers(true));
    }
  }

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.type !== 'broker') {
      window.location = '/';
    }
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);

    this.setState({ user }, () => {
      this.getManagedUsers(false);
    });
  };

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  successRedrawCallback = () => {
    axios.get('/user')
      .then((response) => {
        if (typeof response.data === 'object') {
          this.setState({ user: response.data });
          localStorage.setItem('user', JSON.stringify(response.data));
        }
      });

    this.getManagedUsers();
  }

  getManagedUsers = (scrollToTop) => {
    axios.get('/broker/managed_users').then((response) => {
      const newManagedUsers = response.data.map(managedUser => ({
        ...managedUser, adding: false, pinging: false,
      }));
      this.setState({ managedUsers: newManagedUsers, loading: false, error: null });
      setTimeout(() => this.setState({ loaded: true }), 500);

      const scrollYOffsetInt = parseInt(sessionStorage.getItem('scrollYOffsetInt'), 10);
      const scrollActiveInt = JSON.parse(sessionStorage.getItem('scrollActiveInt'));

      if (scrollToTop) {
        window.scrollTo(0, 0);
      } else if (scrollYOffsetInt > 0 && scrollActiveInt) {
        sessionStorage.setItem('scrollYOffsetInt', 0);
        sessionStorage.setItem('scrollActiveInt', false);

        let x = 0;
        const refreshIntervalId = setInterval(() => {
          x += 1;
          if (x > 30) clearInterval(refreshIntervalId);
          const newScrollYOffset = window.pageYOffset;
          if (newScrollYOffset !== scrollYOffsetInt) {
            window.scrollTo({ top: scrollYOffsetInt, behavior: 'auto' });
            // clearInterval(refreshIntervalId);
          }
        }, 50);
      }
    }).catch(() => {
      this.setState({ loaded: true });
    });
  };

  showNotifWarning = message => showNotification({
    notification: <BasicNotification
      color="warning"
      title="Warning!"
      message={message}
    />,
    position: 'right-up',
  });

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  },
  'ltr');

  renderCreateStartupButton = () => (
    <Button
      className="rounded icon icon--right btn btn-primary float-right m-auto"
      color="black"
      onMouseDown={e => this.toggleCreateStartupModal(e)}
      onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { this.toggleCreateStartupModal(e); } }}
    >
      <p>New Startup <PlusCircleIcon /></p>
    </Button>
  )

  toggleFeedback = (userId) => {
    const { modalFeedback } = this.state;
    this.setState({ modalFeedback: !modalFeedback });
    if (userId) {
      this.setState({ userId });
    }
  };

  toggleCreateStartupModal = () => {
    const { modalCreateStartup } = this.state;
    this.setState({ modalCreateStartup: !modalCreateStartup });
  };

  endTrialNow = () => {
    const { user } = this.state;
    window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/payment/${user.plan}?process=true`;
  };

  render() {
    const {
      loading, loaded, error, user, modalCreateStartup,
      managedUsers, modalFeedback, userId,
    } = this.state;

    let managedUsersElements = null;
    let archivedUsersElements = null;
    if (managedUsers && managedUsers.length > 0) {
      managedUsersElements = managedUsers.filter(
        managedUser => !managedUser.unsubscribed,
      ).map(
        managedUser => (
          (
            <ManagedUserCard
              user={managedUser}
              key={managedUser.id}
              successCallback={this.successRedrawCallback}
              toggleFeedback={this.toggleFeedback}
            />
          )
        ),
      );

      archivedUsersElements = managedUsers.filter(
        managedUser => managedUser.unsubscribed,
      ).map(
        managedUser => (
          (
            <ManagedUserCard
              user={managedUser}
              key={managedUser.id}
              successCallback={this.successRedrawCallback}
            />
          )
        ),
      );
    }

    return (
      <div>
        <Row>
          <Col xs={12} md={3} xl={2}>
            <h3 className="page-title">Startup Management Dashboard</h3>
          </Col>
          <Col xs={12} md={9} lg={7} xl={5}>
            <p className="page-description">
              Access the account of the different companies you manage,
              create new ones and archives the ones that are not raising funds anymore.
            </p>
          </Col>
          { this.renderCreateStartupButton() }
        </Row>
        {user && user.isCustomer && !user.isPayingCustomer && (
          <Row>
            <Alert color="info" className="alert--colored mt-3 mb-2 p-5">
              <p>
                <h4 className="bold-text text-blue">You are under a 7-day trial period.</h4><br />
                While under your 7-day trial period, you and all the startup accounts you manage can browse our list of
                investors and create campaigns.<br />
                <strong>The startup accounts you manage cannot contact investors</strong>, until the end of your
                trial period.
                To start contacting investors and be credited with your pings, you can end your trial and start your
                paid subscription now. Alternatively, you can wait until the end of your trial period.<br /><br />
                <u
                  className="pointer"
                  onClick={this.endTrialNow}
                  role="button"
                  onKeyDown={this.endTrialNow}
                  tabIndex={0}
                >
                  <strong>
                    To end your trial now and start contacting investors immediately, click here.
                  </strong>
                </u>&nbsp;&nbsp;
                <small>(You will be charged.)</small>
              </p>
            </Alert>
          </Row>
        )}
        <Row>
          <Col xs={12} className="text-center">
            <h4 className="mt-5 mb-1">You have {user ? user.pings : 0} pings available</h4>
            <h6 className="text-blue mb-5">
              {user && user.isCustomer && !user.isPayingCustomer
                ? (
                  <div
                    className="pointer"
                    onClick={this.endTrialNow}
                    role="button"
                    onKeyDown={this.endTrialNow}
                    tabIndex={0}
                  >
                    Start your subscription now to credit your account with pings <ChevronRightIcon />
                  </div>
                )
                : <Link to="/ext/pricing">Subscribe to a plan to buy more pings <ChevronRightIcon /></Link>
              }
            </h6>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={{ size: 11, offset: 1 }} className="mb-4 mt-4">
            {!loaded
              && (
                <div className={`load${loading ? '' : ' loaded'} inload`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              )}
            {loaded && !managedUsersElements && !archivedUsersElements && user && user.pings === 0
              && (
                <StepsBlock
                  toggleCreateStartupModal={e => this.toggleCreateStartupModal(e)}
                />
              )
            }
            {loaded && error
              && (
              <Card className="error-results">
                <CardBody>
                  <h5>{error}</h5>
                </CardBody>
              </Card>
              )}
            {loaded && managedUsersElements && managedUsersElements.length > 0
            && (
              <div className="investor-items__wrap">
                <h2 className="mb-4">Active</h2>
                <div className="investor-items">
                  {managedUsersElements}
                </div>
              </div>
            )}
            {loaded && archivedUsersElements && archivedUsersElements.length > 0
            && (
              <div className="investor-items__wrap">
                <h2 className="mb-4">Unlisted</h2>
                <div className="investor-items">
                  {archivedUsersElements}
                </div>
              </div>
            )}
          </Col>
          <ModalCreateStartup
            user={user}
            color="primary"
            displayed={modalCreateStartup}
            toggleModal={this.toggleCreateStartupModal}
            successCallback={this.successCallback}
          />
          <ModalFeedback
            user={user}
            displayed={modalFeedback}
            userId={userId}
            toggleModal={this.toggleFeedback}
          />
        </Row>
      </div>
    );
  }
}


export default withRouter(connect(state => ({
  user: state.user,
}))(List));
