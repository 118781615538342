import React, { useEffect, useState } from 'react';
import {
  useStripe, useElements,
} from '@stripe/react-stripe-js';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import PropTypes from 'prop-types';
import {
  Col, Form, FormGroup, Button,
} from 'reactstrap';
import Alert from '../../../../shared/components/alerts/Alert';

const BillingTab = ({ handleForm }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorStripe, setError] = useState();
  const style = {
    base: {
      display: 'block',
      width: '100%',
      fontFamily: '"Source Sans Pro", Calibri, Helvetica Neue, Helvetica, Arial, sans-serif',
      fontSize: '13px',
      lineHeight: '1.53',
      backgroundColor: '#fff',
      color: '#333',
      '::placeholder': {
        color: '#888',
      },
    },
    invalid: {
      color: '#eb1c26',
    },
  };

  useEffect(() => {
    if (elements) {
      const cardNumber = elements.create('cardNumber', { style });
      cardNumber.mount('#payment_card_number');
      const cardExpiry = elements.create('cardExpiry', { style });
      cardExpiry.mount('#payment_card_expiry');
      const cardCvc = elements.create('cardCvc', { style });
      cardCvc.mount('#payment_card_cvc');
      const postalCode = elements.create('postalCode', { style });
      postalCode.mount('#payment_card_zip');
      return function cleanup() {
        cardNumber.destroy();
        cardExpiry.destroy();
        cardCvc.destroy();
        postalCode.destroy();
      };
    }
    return null;
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement('cardNumber');

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    if (error) {
      setError(error.message);
    } else {
      handleForm(paymentMethod);
    }
  };

  return (
    <span>
      <div className="card__title">
        <h5 className="bold-text">Change Billing Method</h5>
        <h5 className="subhead">These new cards detail will be used for your next subscriptions.</h5>
        <h5 className="subhead">
          <small>All financials information are handled by our payment gateway Stripe.</small>
        </h5>
      </div>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="row">
          <Col lg={6} xs={12}>
            <span>Card number</span>
            <div className="stripe-input" id="payment_card_number" />
          </Col>
          <Col lg={6} xs={12}>
            <span>Zip Code</span>
            <div className="stripe-input" id="payment_card_zip" />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Col lg={6} xs={12}>
            <span>Expiry</span>
            <div className="stripe-input" id="payment_card_expiry" />
          </Col>
          <Col lg={6} xs={12}>
            <span>CVC Code</span>
            <div className="stripe-input" id="payment_card_cvc" />
          </Col>
        </FormGroup>
        {errorStripe && (
          <Alert color="danger" className="alert--colored w-100 mb-5">
            <p className="text-left">
              <span className="bold-text">Error!</span>&nbsp;
              { errorStripe }
            </p>
          </Alert>
        )}
        <Button
          type="submit"
          disabled={!stripe}
          color="primary"
          className="rounded icon icon--right icon--blue mr-5 mt-5 w-100"
        >
          Set Payment Method
          <ChevronRightIcon />
        </Button>
      </Form>
    </span>
  );
};

export default BillingTab;

BillingTab.propTypes = {
  handleForm: PropTypes.func.isRequired,
};
