import React, { Component } from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import ModalSeedLegals from '../modals/ModalSeedLegals';

const logo = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/seedlegals-logo.svg`;

class CardSeedLegals extends Component {
  state = {
    modal: false,
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  render() {
    const { modal } = this.state;
    return (
      <Col md={6} xl={4} sm={12}>
        <Card>
          <CardBody className="dashboard-card dashboard-card--danger" onClick={this.toggleModal}>
            <div className="dashboard-card__body">
              <img className="mt-3 mb-5 w-100" src={logo} alt="" />
              <h3 className="dashboard-card__plan">£100 off</h3>
              <hr />
              <p className="dashboard-card__title">U.K. focused legal platform</p>
              <div className="dashboard-card__desc top-line text-justify mb-5 pb-4">
                <p>
                  SeedLegals automates the contracts startups and small businesses need, dramatically reducing the costs
                  and time associated with traditional lawyers and accountants.
                </p>
                <p>
                  Create team contracts, claim R&D tax credits, manage your cap table, raise funding and more.
                  SeedLegals is the UK&#39;s No. 1 closer of funding rounds, and provider of EMI Option Schemes.
                </p>
              </div>
              <Button className="dashboard-card__button" color="primary">Discover</Button>
            </div>
          </CardBody>
        </Card>
        <ModalSeedLegals
          displayed={modal}
          toggleModal={this.toggleModal}
        />
      </Col>
    );
  }
}

export default CardSeedLegals;
