import React from 'react';
// import { Link } from 'react-router-dom';

const TopbarNav = () => (
  <nav className="topbar__nav">
    {/* <Link className="topbar__nav-link" to="/investors"> */}
    {/*  <span className="topbar__main-link-icon lnr lnr-magnifier" /> */}
    {/*  <p className="topbar__main-link-title">Browse Investors</p> */}
    {/* </Link> */}
  </nav>
);

export default TopbarNav;
