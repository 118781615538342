import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';

class ModalConfirmation extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    confirm: PropTypes.func.isRequired,
    cancel: PropTypes.func,
    confirmBtn: PropTypes.string,
    cancelBtn: PropTypes.string,
    customClass: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
    cancel: null,
    displayed: false,
    confirmBtn: 'Yes',
    cancelBtn: 'No',
    customClass: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  toggle(confirmation) {
    const { confirm, cancel } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (confirmation === true) {
      confirm();
    } else if (!confirmation && cancel !== null) {
      cancel();
    }
  }

  render() {
    const {
      color, title, message, colored, header, confirmBtn, cancelBtn, customClass,
    } = this.props;
    const { modal } = this.state;
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className={`modal-dialog--${color} ${modalClass} ${customClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => this.toggle(false)} />
            <span className="lnr lnr-question-circle modal__title-icon" />
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            {message}
          </div>
          <ButtonToolbar className="modal__footer">
            <Button
              className="modal_cancel btn btn-outline-secondary btn-rounded rounded"
              onClick={() => this.toggle(false)}
            >
              {cancelBtn}
            </Button>
            {' '}
            <Button
              className="modal_ok btn btn-primary btn-rounded text-white rounded"
              onClick={() => this.toggle(true)}
            >
              {confirmBtn}
            </Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default ModalConfirmation;
