import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import StarsIcon from 'mdi-react/StarsIcon';
import PropTypes from 'prop-types';

const FavoriteButton = ({
  entity, removeFavorites, addFavorites, className, displayFavorites, onlyIcon,
}) => {
  if (entity.favorite && displayFavorites && onlyIcon) {
    return (
      <Button
        className={`icon rounded investor-item__icon-centered icon-only ${className}`}
        color="warning"
        id="favorite"
        onMouseDown={e => removeFavorites(e)}
        onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { removeFavorites(e); } }}
      >
        <StarsIcon />
        <UncontrolledTooltip placement="top" target="favorite">
          FAVORITE
        </UncontrolledTooltip>
      </Button>
    );
  } if (entity.favorite && displayFavorites) {
    return (
      <Button
        className={`icon icon--right rounded investor-item__icon-centered ${className}`}
        color="warning"
        outline
        onMouseDown={e => removeFavorites(e)}
        onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { removeFavorites(e); } }}
      >
        <p>FAVORITE <StarsIcon /></p>
      </Button>
    );
  } if (entity.adding && displayFavorites && onlyIcon) {
    return (
      <Button
        className={`icon rounded investor-item__icon-centered icon-only ${className}`}
        color="warning"
        outline
      >
        <LoadingIcon className="mdi-icon-spinning" />
      </Button>
    );
  } if (entity.adding && displayFavorites) {
    return (
      <Button
        className={`icon icon--right rounded investor-item__icon-centered ${className}`}
        color="warning"
        outline
      >
        <p>ADDING <LoadingIcon className="mdi-icon-spinning" /></p>
      </Button>
    );
  } if (displayFavorites && onlyIcon) {
    return (
      <Button
        className={`icon rounded investor-item__icon-centered icon-only ${className}`}
        color="primary"
        id="favorite"
        onMouseDown={e => addFavorites(e)}
        onKeyUp={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            addFavorites(e);
          }
        }}
      >
        <StarsIcon />
        <UncontrolledTooltip placement="top" target="favorite">
          ADD TO FAVORITES
        </UncontrolledTooltip>
      </Button>
    );
  } if (displayFavorites) {
    return (
      <Button
        className={`icon icon--right rounded investor-item__icon-centered ${className}`}
        color="primary"
        onMouseDown={e => addFavorites(e)}
        onKeyUp={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            addFavorites(e);
          }
        }}
      >
        <p>ADD TO FAVORITES <StarsIcon /></p>
      </Button>
    );
  }
  return null;
};

FavoriteButton.propTypes = {
  entity: PropTypes.shape({
    favorite: PropTypes.bool,
    adding: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
  displayFavorites: PropTypes.bool,
  removeFavorites: PropTypes.func.isRequired,
  addFavorites: PropTypes.func.isRequired,
  onlyIcon: PropTypes.bool,
};

FavoriteButton.defaultProps = {
  className: null,
  displayFavorites: false,
  onlyIcon: false,
};

export default FavoriteButton;
