import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const renderQuillEmailField = ({
  input, meta: { error, touched }, dispatched = false,
}) => {
  const [editorValue, setEditorValue] = useState('');

  const modules = useMemo(() => ({
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['link'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
    ],
  }), []);

  useEffect(() => {
    // Initialize the editor value only once, or if the input value changes externally
    if (input.value !== editorValue) {
      setEditorValue(input.value);
    }
  }, [input.value]);

  const handleChange = (value) => {
    setEditorValue(value);
    input.onChange(value);
  };

  return (
    <div className="form__form-group-input-wrap campaign-quill-editor-wrapper">
      <ReactQuill
        value={editorValue}
        onChange={handleChange}
        modules={modules}
        theme="snow"
        className="campaign-quill-editor"
      />
      {(dispatched || touched) && error && <span className="form__form-group-error error-large">{error}</span>}
    </div>
  );
};

renderQuillEmailField.propTypes = {
  input: PropTypes.shape().isRequired,
  dispatched: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

renderQuillEmailField.defaultProps = {
  dispatched: false,
};

export default renderQuillEmailField;
